import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import SimpleAutoCompleteMultiple from '../../components/auto-complete/autocomplete-multiple-create';
import * as Service from "../../services/organizationpatrimony.service";
import { WithContext as ReactTags } from 'react-tag-input';
import './style.css';
import 'react-quill/dist/quill.snow.css'; // ES6
import ReactQuill from 'react-quill'; // ES6
import { PapiroConsole } from '../../utils/papiroConsole';
import ClearIcon from '@mui/icons-material/Clear'
import { DataGrid, GridToolbarFilterButton } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogConfirmation from '../../components/dialog-confirmation'
import SimpleCheckBox from '../../components/checkbox/check'
import { getOrg } from "../../services/organization.service";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DialogAddMultiple from '../../components/dialog-add-multiple';

const KeyCodes = {
  comma: 188,
  enter: 13,
};


const NoDataRows = _ => {
  return (
    <div className="MuiDataGrid-window" style={{ display: 'flex', justifyContent: 'center', top: 56, overflowY: 'auto' }} >Não há registros a serem exibidos</div>
  )
}


const delimiters = [KeyCodes.comma, KeyCodes.enter];

class KnowledgeBaseRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    if(userSession.user.userrole == null){
      Api.logoff();
    }
    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

 
    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      CalledId:  "",
      CalledText: '',
      description : '', 
      PatrimonySubgroupId: 0,
      PatrimonySubgroupText: "",
      PatrimonyGroupId: 0,
      PatrimonyGroupText: "",
      PatrimonyMaterialId: "",
      keeppatrimony: true,
      PatrimonyMaterialText: "",
      title: "",
      onlyinternaluse: false
    };         
    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      CalledList: [],
      PatrimonySubgroupList: [],
      PatrimonyGroupList: [],
      PatrimonyMaterialList: [],
      KeywordsList: [],
      files: [],
      tags: [],
      OrgList: [],
      OrgListSelect: [],
      OrgListIds: "",
      OrgListForDelete: "",
      OrgListForSelect: [],
      openOrgInsertModal: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleTagClick = this.handleTagClick.bind(this);
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
    this.orgDeleteModal =  this.orgDeleteModal.bind(this)
    this.setOrgDelete = this.setOrgDelete.bind(this)
    this.closeOrgModal = this.closeOrgModal.bind(this)
    this.openOrgInsertModal = this.openOrgInsertModal.bind(this)
    this.saveOrgInsertModal = this.saveOrgInsertModal.bind(this)
    this.getOrganizations = this.getOrganizations.bind(this)
  }

  componentDidMount() {
    this.getPatrimonyGroups();
    this.getOrganizations();
  };

  async getOrganizations() {
    this.setState({ loading: true });
    var result = await getOrg();
    if (result.success) {
      this.setState({ loading: false, OrgListForSelect: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }
  }

  
  saveOrgInsertModal = async () => {

    const intl = this.props.intl;

    let organizationIds = ""
    if (this.state.item.OrganizationId && this.state.item.OrganizationId.length > 0) {
      this.state.item.OrganizationId.map(item => {
        if (item && item.id && item.id > 0)
          organizationIds = `${item.id},${organizationIds}`
      })
    }

    this.setState({
      loading: false,
      OrgList: this.state.item.OrganizationId,
      OrgListIds: organizationIds,
      openNotification: true,
      notificationVariant: 'success',
      notificationMessage: intl.formatMessage({ id: "add.success" }),
      openOrgInsertModal: !this.state.openOrgInsertModal,
    })
  }

  openOrgInsertModal = async () => {
    this.setState({
      openOrgInsertModal: !this.state.openOrgInsertModal
    })
  }

  setOrgDelete = async (org) => {
    console.log("entrei aqui")
    this.setState({
      OrgListForDelete: org + "," + this.state.OrgListForDelete
    })
  }

  closeOrgModal = () => this.setState({ openOrgInsertModal: false })


  orgDeleteModal = async () => {
    const intl = this.props.intl;
    if (this.state.OrgListForDelete && this.state.OrgListForDelete.length > 0) {

      this.setState({ loading: true })

      var orgList = [];
      let organizationIds = "";
      var orgDeleteList = this.state.OrgListForDelete.split(",");
      PapiroConsole.log("orgDeleteList")
      PapiroConsole.log(orgDeleteList)

      orgDeleteList.map(organizationDelete => {
        if (organizationDelete != "") {
          let returnok = this.state.OrgList.filter(el => el.id != organizationDelete)
          PapiroConsole.log(returnok)
          returnok.map(item => {
            if (orgList.filter(el => el.id != item.id)) {
              orgList.push(item);
              organizationIds = `${item.id},${organizationIds}`
            }
          })
        }
      })

      this.setState({
        loading: false,
        OrgList: orgList,
        OrgListIds: organizationIds,
        openNotification: true,
        notificationVariant: 'success',
        notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
      })

    } else {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "organization.material.registration.select.organization" })
      })
    }
  }

  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  }

  handleAddition(tag) {
   this.setState(state => ({ tags: [...state.tags, tag] }));
  }

  handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  }

  handleTagClick(index) {
    // console.log('The tag at index ' + index + ' was clicked');
  }

    async getPatrimonySubgroups(patrimonyGroupId) {
      const intl = this.props.intl;
      this.setState({ loading: true });
      var result = await Service.getPatrimonySubgroups(patrimonyGroupId);
      if (result.success) {
        this.setState({ loading: false, PatrimonySubgroupList: result.data });
      } else {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            result.data &&
            result.data.response &&
            result.data.response.data &&
            result.data.response.data.errors &&
            result.data.response.data.errors[0]
              ? result.data.response.data.errors[0]
              : this.props.intl.formatMessage({id:"process.error"}),
        });
        Api.kickoff(result);
      }
    }

    async getPatrimonyGroups() {
      const intl = this.props.intl;
      this.setState({ loading: true });
      var result = await Service.getPatrimonyGroups();
      if (result.success) {
        this.setState({ loading: false, PatrimonyGroupList: result.data });
      } else {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            result.data &&
            result.data.response &&
            result.data.response.data &&
            result.data.response.data.errors &&
            result.data.response.data.errors[0]
              ? result.data.response.data.errors[0]
              : this.props.intl.formatMessage({id:"process.error"}),
        });
        Api.kickoff(result);
      }
    }
    async getPatrimonyMaterials(subgroupid) {
      const intl = this.props.intl;
      this.setState({ loading: true });
      var result = await Service.getPatrimonyMaterials(subgroupid);
      if (result.success) {
        this.setState({ loading: false, PatrimonyMaterialList: result.data });
      } else {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            result.data &&
            result.data.response &&
            result.data.response.data &&
            result.data.response.data.errors &&
            result.data.response.data.errors[0]
              ? result.data.response.data.errors[0]
              : this.props.intl.formatMessage({id:"process.error"}),
        });
        Api.kickoff(result);
      }
    }
  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();

      if (this.isClient) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      }


      for (let key in this.state.item) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else if(key.toString().toLowerCase().indexOf('description') > -1) {
          data.append(key, this.state.item[key] == null || typeof this.state.item[key] === "undefined" ? "" : this.state.item[key]);
        } else {
          data.append(key, this.state.item[key]);
        }
      }
      
      let idsStringOrg = "";
      if (this.state.OrgList.length > 0) {
        this.state.OrgList.forEach(
          (organization) => (idsStringOrg += organization.id + ",")
        );
      }

      var newStr = idsStringOrg.slice(0, -1);
      console.log(newStr)
      console.log("print aqui")
      data.append("Organizations", newStr);

      var word = ""
      for (let key in this.state.tags) {
        word = this.state.tags[key].text+","+word
      }

      var patrimony = ""
      for (let key in this.state.item.PatrimonyMaterialId) {
        patrimony = this.state.item.PatrimonyMaterialId[key].id+","+patrimony
      }

      data.append("words", word);
      data.append("patrimonyid", patrimony);

      for (var pair of data.entries()) {
        // console.log(pair[0]+ ', ' + pair[1]); 
      }

      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      Api.post('/knowledgebases', data, config)

        .then(result => {
          const intl = this.props.intl;
          if (result.data.success) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: this.props.intl.formatMessage({id:"add.success"}),
              item: this.initialState,
              files: [],
              tags: [],
              OrgList: [],
              OrgListSelect: [],
              OrgListIds: "",
              OrgListForDelete: "",
              
            });
          }
          else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          const intl = this.props.intl;
          if(err.response && err.response.data && err.response.data.errors){
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
          }
          else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: this.props.intl.formatMessage({id:"process.error"})
            });
          }
          Api.kickoff(err);
        });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));
    if (stateName == 'selectOrganizations') {
      if (value == true) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            OrganizationId: this.state.OrgListForSelect,
            ["OrganizationText"]: text,
          },
          
        }));
        
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            Organizations: [],
            OrganizationId: [],
            ["OrganizationText"]: text,
           
          },
          
        }));
        
      }
    }

    if (stateName === "PatrimonySubgroupId") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["PatrimonySubgroupText"]: text,
          ["PatrimonyMaterialId"]: "",
        },
      }));

      if (value.id != 0) {
        this.getPatrimonyMaterials(value.id);
      }
    }

    if (stateName === "PatrimonyGroupId") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["PatrimonyGroupText"]: text,
          ["PatrimonySubgroupId"]: "",
          ["PatrimonyMaterialId"]: "",
        },
      }));
      if (value.id != 0) {
        this.getPatrimonySubgroups(value.id);
      }
    }
    if (stateName === "PatrimonyMaterialId") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["PatrimonyMaterialText"]: text,
        },
      }));
    }
 
    this.setState({ loading: false });

  };

  handleChangeEditor = (value) => {
		this.setState(prevState => ({
			item: {
			  ...prevState.item,
			  description: value
			}
		  }));
	}

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
    render() {
    const { classes, headerMenu, actionsHeaderMenu, intl } = this.props;
    const { item } = this.state;

    PapiroConsole.log("PatrimonyMaterialList")
    PapiroConsole.log(this.state.PatrimonyMaterialList)

    PapiroConsole.log("OrgList")
    PapiroConsole.log(this.state.OrgList)
    PapiroConsole.log("this.state.OrgListForDelete")
    PapiroConsole.log(this.state.OrgListForDelete)

    
    

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary'   onClick={browserHistory.goBack}>{this.props.intl.formatMessage({id:"back"})}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {intl.formatMessage({id:"typography.add.item"})}
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <TextField 
                        fullWidth
                        label={intl.formatMessage({id:'title'})} 
                        autoComplete='fname' 
                        variant='outlined' 
                        name='title' 
                        onChange={this.handleInputChange} 
                        value={item.title} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>{intl.formatMessage({ id: "select.organizations" })}*</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2} xs={12} sm={12} >

                          <Grid xs={12} sm={12} style={{ margin: '5px', padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }} >
                            <Grid xs={12} sm={4} >

                            </Grid>
                            <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                              <DialogConfirmation
                                msgButton={intl.formatMessage({ id: "organization.material.registration.remove.organization" })}
                                msg={intl.formatMessage({ id: "user.client.registration.confirm.remove.organization.from.user" })}
                                msgDisagree={intl.formatMessage({ id: "cancel" })}
                                msgAgree={intl.formatMessage({ id: "yes" })}
                                handleConfirmation={e => this.orgDeleteModal()}
                                classes={classes}
                                icon={<DeleteIcon />}
                              />
                            </Grid>
                            <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                              <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                className={classes.button}
                                startIcon={<AddIcon />}
                                onClick={e => this.openOrgInsertModal()} >
                                {intl.formatMessage({ id: "organization.material.registration.add.organization" })}
                              </Button>
                            </Grid>
                          </Grid>

                          <Grid xs={12} sm={12}
                            style={{
                              marginLeft: 5,
                              marginBottom: 5,
                              border: '3px solid #eee'
                            }} >
                            <div style={{ height: 350 }}>
                              <DataGrid
                                style={{ fontSize: 14, color: '#000' }}
                                className="DataGridStyle"
                                components={{
                                  NoRowsOverlay: NoDataRows,
                                  Toolbar: GridToolbarFilterButton,
                                }}
                                rows={this.state.OrgList}
                                rowsPerPageOptions={[5]}
                                checkboxSelection
                                onSelectionModelChange={itm => {
                                  if (itm.length > 0) {
                                    this.setOrgDelete(itm);
                                  } else {
                                    this.setState({
                                      OrgListForDelete: [],
                                    })
                                  }
                                }}
                                columns={[
                                  { headerName: intl.formatMessage({ id: "menu.organization" }), field: 'name', width: 500 },
                                ]}
                                HeadersVisibility="None"
                              />
                            </div>
                          </Grid>
                        </Grid>

                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                    <Grid item xs={12} sm={12} style={{ marginBottom: 50 }}>
                      <Typography>
                        <FormattedMessage id="description" />
                      </Typography>
                      <ReactQuill name="description" value={item.description} style={{ height: 200 }}
                        fullWidth
                        required
                        InputLabelProps={{ shrink: item.description && item.description.length > 0 ? true : false }}
                        variant="outlined"
                        id="outlined-multiline-static"
                        onChange={this.handleChangeEditor} />
                    </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "menu.patrimonygroups" })}
                      options={this.state.PatrimonyGroupList}
                      stateName="PatrimonyGroupId"
                      changeSelect={this.changeValues}
                      selected={item.PatrimonyGroupId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({
                        id: "menu.patrimonysubgroups",
                      })}
                      options={this.state.PatrimonySubgroupList}
                      stateName="PatrimonySubgroupId"
                      changeSelect={this.changeValues}
                      selected={item.PatrimonySubgroupId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoCompleteMultiple
                      label={intl.formatMessage({
                        id: "menu.patrimonymaterials",
                      })}
                      options={this.state.PatrimonyMaterialList}
                      stateName="PatrimonyMaterialId"
                      changeSelect={this.changeValues}
                      selected={item.PatrimonyMaterialId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ReactTags
                      placeholder={intl.formatMessage({ id: "keywords.press.enter" })}
                      tags={this.state.tags}
                      delimiters={delimiters}
                      handleDelete={this.handleDelete}
                      handleAddition={this.handleAddition}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleCheckBox 
                      label="Apenas para uso interno?"
                      name='onlyinternaluse' 
                      stateName='onlyinternaluse' 
                      changeSelect={this.changeValues} 
                      selected={item.onlyinternaluse} />                       
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <section style={{ width: '100%' }}>
                      <div className={classes.dropzoneArea} style={{minHeight:'70px',marginTop:'5px '}} > 
                        <Dropzone onDrop={acceptedFiles => { this.setState({ files: [ ...acceptedFiles, ...this.state.files ] }) }}>
                          {({ getRootProps, getInputProps, acceptedFiles }) => (
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <AttachFileIcon  />
                              <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                                  <FormattedMessage  id="drag.and.drop.files" />
                              </Typography>
                            </div>
                          )}
                        </Dropzone>
                        {this.state.files.map(file => {
                          return (
                            <div className={classes.dropzoneItem} key={file.name}>
                              {file.name}
                              <DeleteForever className={classes.deleteItem} onClick={() => { this.removeItem(file) }} />
                            </div>
                          )
                        })}
                      </div>
                    </section>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={4}>
                            <Button
                                type='submit'
                                fullWidth
                                variant='contained'
                                color='primary'
                                className={classes.submit}
                            >
                                {this.props.intl.formatMessage({id:"save"})}
                            </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
            
            <DialogAddMultiple
              title={intl.formatMessage({ id: "organization.material.registration.new.organizations" })}
              selected={this.state.item.OrganizationId}
              options={this.state.OrgListForSelect}
              checked={this.state.selectOrganizations}
              changeValues={this.changeValues}
              open={this.state.openOrgInsertModal}
              close={this.closeOrgModal}
              save={this.saveOrgInsertModal}
              intl={intl}
              checkboxLabel={intl.formatMessage({ id: "technical.team.edit.select.all.organizations" })}
              checkboxStateName='selectOrganizations'
              autoCompleteLabel={intl.formatMessage({ id: "organization" })}
              autoCompleteStateName="OrganizationId"
            />

          </Container>
        </main>
      </div>
    );
  }
}

KnowledgeBaseRegistration.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});
export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(KnowledgeBaseRegistration)));
