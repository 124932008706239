import React from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import PropTypes from "prop-types";
import { FormattedMessage,injectIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: '100%',
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
        return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {

    return a.filter(value => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a, 1)];
}

function MyCheckList(props) {

  const { label, options, optionsText, stateName, changeSelect, selected, required } = props;
 

  const classes = useStyles();
  const [checked, setChecked] = React.useState(selected ? selected : []);
  const [left, setLeft] = React.useState(options ? options : []);

  React.useEffect(() => {
    setLeft(options ? options : []);
    setChecked(selected ? selected : []);
  }, [options,selected]);


  const handleChange = event => {



  };

  const handleToggle = value => () => {
    console.log("handleToggle")
    console.log(value)
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);

    changeSelect(stateName,newChecked);

  };

  const numberOfChecked = items => intersection(checked, items).length;

  const handleToggleAll = items => () => {
    var arr = [];
    if (numberOfChecked(items) === items.length) {
      arr = not(checked, items);
      setChecked(arr);
    } else {
      arr = union(checked, items);
      setChecked(arr);
    }

    changeSelect(stateName,arr);


  };
  const intl = props.intl;
  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} ${intl.formatMessage({id:"select"})}`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.length > 0 && items.map( (value,i) => {
          const labelId = `transfer-list-all-item-${value}-label`;
          //console.log(value.id);
          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                  onClick={handleChange}
                  value={value}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${optionsText[i]}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );
  //console.log(left);
  
  return (
    <Grid container justify="center" alignItems="center" className={classes.root}>
      <Grid item xs={12} sm={12}>{customList(label+' '+intl.formatMessage({id:"available"}), left)}</Grid>
    </Grid>
  );
}

export default injectIntl(MyCheckList);

MyCheckList.propTypes = {
    label: PropTypes.string,
    defaultData: PropTypes.array,
    options: PropTypes.array,
    stateName: PropTypes.string,
    changeSelect: PropTypes.func,
    selected: PropTypes.array
  };
