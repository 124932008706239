import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { browserHistory } from '../../helpers/history';
import { Link } from 'react-router-dom';
import SimpleSelect from '../../components/select/simple';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import { FormattedMessage,injectIntl } from 'react-intl';
import Title from '../../components/title-name';
import * as Service from '../../services/organizationpatrimony.service';
import * as componentActions from '../../redux/actions/organizationpatrimonies';
import TransitionsModal  from '../../components/modal/modal';
import QRCode from 'qrcode.react';
import CropFreeIcon from '@mui/icons-material/CropFree';
import Footer from '../../components/footer';
import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint from 'react-to-print';
import Qr from './qrcode';
import SimpleDatePicker from '../../components/date-pickers/inline-no-today';
import NumberFormat from 'react-number-format';
import SimpleHelp from '../../components/help';
import SimpleCheckBox from "../../components/checkbox/check";

class OrganizationPatrimonyEdit extends Component {

  constructor(props) {
    super(props);
    const { userSession,organizationpatrimonies, organizations } = this.props;
   
    this.fromProps = false
    if(this.props && this.props.location && this.props.location.state && this.props.location.state.from && this.props.location.state.from.length > 0)
      this.fromProps = true

    this.globalAdm = false;
    this.isClient = true;
    if(userSession.user.userrole == null){
      Api.logoff();
    }

    if(!organizationpatrimonies || (organizationpatrimonies && !organizationpatrimonies.organizationpatrimony)) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/calleds`
    }

    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );
        // console.log(organizationpatrimonies.organizationpatrimony);
    this.initialState = {
      UpdatedUser: userSession.user.id,
       id: organizationpatrimonies.organizationpatrimony.id,
     DepartamentId:  organizationpatrimonies.organizationpatrimony.departament != null ? organizationpatrimonies.organizationpatrimony.departament : 0,
     DepartamentText: organizationpatrimonies.organizationpatrimony.DepartamentText,
     PatrimonySubgroupId:  organizationpatrimonies.organizationpatrimony.patrimonysubgroup != null ? organizationpatrimonies.organizationpatrimony.patrimonysubgroup : 0,
     PatrimonySubgroupText: organizationpatrimonies.organizationpatrimony.PatrimonySubgroupText,
     OrganizationId:  organizationpatrimonies.organizationpatrimony.OrganizationId,
     OrganizationText: organizationpatrimonies.organizationpatrimony.OrganizationText,
     PatrimonyGroupId:  organizationpatrimonies.organizationpatrimony.patrimonygroup != null ? organizationpatrimonies.organizationpatrimony.patrimonygroup : 0,
     PatrimonyGroupText: organizationpatrimonies.organizationpatrimony.PatrimonyGroupText,
     PatrimonyMaterialId: organizationpatrimonies.organizationpatrimony.patrimonymaterial != null ? organizationpatrimonies.organizationpatrimony.patrimonymaterial : 0,
     PatrimonyMaterialText: organizationpatrimonies.organizationpatrimony.PatrimonyMaterialText,
     name : organizationpatrimonies.organizationpatrimony.name == null ? '' : organizationpatrimonies.organizationpatrimony.name , 
     tipping : organizationpatrimonies.organizationpatrimony.tipping == null ? '' : organizationpatrimonies.organizationpatrimony.tipping , 
     qrcode : organizationpatrimonies.organizationpatrimony.qrcode == null ? '' : organizationpatrimonies.organizationpatrimony.qrcode , 
     garanty : organizationpatrimonies.organizationpatrimony.garanty, 
     value : organizationpatrimonies.organizationpatrimony.value == null ? '' : this.mtel(organizationpatrimonies.organizationpatrimony.value) , 
     serialnumber : organizationpatrimonies.organizationpatrimony.serialnumber == null ? '' : organizationpatrimonies.organizationpatrimony.serialnumber , 
     brand : organizationpatrimonies.organizationpatrimony.brand == null ? '' : organizationpatrimonies.organizationpatrimony.brand , 
     model : organizationpatrimonies.organizationpatrimony.model == null ? '' : organizationpatrimonies.organizationpatrimony.model , 
     invoicedate: organizationpatrimonies.organizationpatrimony.invoicedate == null ? '' : moment(organizationpatrimonies.organizationpatrimony.invoicedate).format('YYYY/MM/DD HH:mm:ss') ,
     saveToAllOrganizationsInGroup: false, 
     };     
     //console.log(organizationpatrimonies);    
    this.state = {
      organizationpatrimony: this.initialState,
      organizations: organizations,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      DepartamentList: [],
      PatrimonySubgroupList: [],
      OrganizationList: [],
      PatrimonyGroupList: [],
      PatrimonyMaterialList: [],
      modalopen:false,
  
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.QRCode = this.QRCode.bind(this);
    this.printQRCode = this.printQRCode.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleInputChangeValue = this.handleInputChangeValue.bind(this);
  }

  componentDidMount() {
    if (this.state.organizations && this.state.organizations.organization && this.state.organizations.organization.id) {
      this.getOrganizationsById(this.state.organizations.organization.id);
    } else {
      this.getOrganizations();
    }
    this.getDepartaments(this.state.organizationpatrimony.OrganizationId);
    this.getPatrimonyGroups();
    if(this.state.organizationpatrimony.PatrimonyGroupId != null && this.state.organizationpatrimony.PatrimonyGroupId != 0)
      this.getPatrimonySubgroups(this.state.organizationpatrimony.PatrimonyGroupId.id);
    if(this.state.organizationpatrimony.PatrimonySubgroupId != null && this.state.organizationpatrimony.PatrimonySubgroupId != 0)
      this.getPatrimonyMaterials(this.state.organizationpatrimony.PatrimonySubgroupId.id);
  };
      async getDepartaments(id){
        this.setState({ loading: true });
        var result = await Service.getDepartaments(id);
          if (result.success) {
            
          //console.log(result.data);
            this.setState({ loading: false, DepartamentList: result.data });
                          
          } else {
            const intl = this.props.intl;
            this.setState({
              loading: false, openNotification: true, notificationVariant: 'error',
              notificationMessage: result.response && result.response.data && result.response.data.errors &&  result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
            Api.kickoff(result);  
          }
      };     
      async getPatrimonySubgroups(id){
        const intl = this.props.intl;
        this.setState({ loading: true });
        var result = await Service.getPatrimonySubgroups(id.id);
          if (result.success) {
            this.setState({ loading: false, PatrimonySubgroupList: result.data });
                          
          } else {
            this.setState({
              loading: false, openNotification: true, notificationVariant: 'error',
              notificationMessage: result.response && result.response.data && result.response.data.errors &&  result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
            Api.kickoff(result);  
          } 
      };     
      async getOrganizations(){
        const intl = this.props.intl;
          this.setState({ loading: true });
          var result = await Service.getOrganizations();
            if (result.success) {
              this.setState({ loading: false, OrganizationList: result.data });
                            
            } else {
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors &&  result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
  };
  
  getOrganizationsById = async (id) => {
    const intl = this.props.intl;
    this.setState({ loading: true });
    var result = await Service.getOrganizationsByIdLight(id);
    if (result.success) {
        this.setState({ loading: false, OrganizationList: [result.data] });

    } else {
        this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);
    }
  };

  async getPatrimonyGroups(){
    const intl = this.props.intl;
          this.setState({ loading: true });
          var result = await Service.getPatrimonyGroups();
            if (result.success) {
              this.setState({ loading: false, PatrimonyGroupList: result.data });
                            
            } else {
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors &&  result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
  };     async getPatrimonyMaterials(id){
    const intl = this.props.intl;
          this.setState({ loading: true });
          var result = await Service.getPatrimonyMaterials(id.id);
            if (result.success) {
              this.setState({ loading: false, PatrimonyMaterialList: result.data });
                            
            } else {
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors &&  result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
  };
  
  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange(values.value);
        }}
        thousandSeparator={false}
        decimalSeparator=","
        isNumericString
        decimalScale={2}
        prefix="R$"
      />
    );
  }

  mtel(num){
    num=num.toString().replace(/\D/g,"");
    //
    if(num.toString().length > 5 && num.toString().length < 7){
      num = num.replace(/(\d{1}?)((\d{1})+)$/, "$1.$2");
      num = num.replace(/(\d{3})(?=\d)/g, "$1,");
    }else if (num.toString().length >= 7) {
      num=num.replace(/(\d{1,2})$/, ',$1');  
      num=num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');  
      num=num.replace(/^0+/, '');

    }else if (num.toString().length == 4){
        num = num.replace(/(\d{2})(?=\d)/g, "$1.");
    }else{
        num = num.replace(/(\d{3})(?=\d)/g, "$1,");
    }
    return num;
  }

  handleInputChangeValue(value) {
    // console.log(value);
    this.setState(prevState => ({
      organizationpatrimony: {
        ...prevState.organizationpatrimony,
        ['value']: value
      }
    }));
  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    if(name == 'value'){
      this.setState(prevState => ({ 
        organizationpatrimony: {
          ...prevState.organizationpatrimony,
          [name]: value
        }
      }));
    }else{
      this.setState(prevState => ({
        organizationpatrimony: {
          ...prevState.organizationpatrimony,
          [name]: value
        }
      }));
    }
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
          organizationpatrimony: {
            ...prevState.organizationpatrimony,
          }
        }));
      } else {
        this.setState(prevState => ({
          organizationpatrimony: {
            ...prevState.organizationpatrimony,
          }
        }));
      }


      for (let key in this.state.organizationpatrimony) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.organizationpatrimony[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else if (key.toString().toLowerCase().indexOf('departamentid') > -1 && this.state.organizationpatrimony[key] != "") {
          data.append(key, this.state.organizationpatrimony[key].id);
        }else if (key.toString().toLowerCase().indexOf('patrimonygroupid') > -1 && this.state.organizationpatrimony[key] != "" && typeof this.state.organizationpatrimony[key] !== 'undefined' && this.state.organizationpatrimony[key] !== 'undefined') {
          data.append(key, this.state.organizationpatrimony[key].id);
        }else if (key.toString().toLowerCase().indexOf('patrimonymaterialid') > -1 && this.state.organizationpatrimony[key] != "" && typeof this.state.organizationpatrimony[key] !== 'undefined' && this.state.organizationpatrimony[key] !== 'undefined') {
          console.log(`=== ${key} ===`)
          console.log(this.state.organizationpatrimony[key])
          data.append(key, this.state.organizationpatrimony[key].id);
        }else if (key.toString().toLowerCase().indexOf('patrimonysubgroupid') > -1 && this.state.organizationpatrimony[key] != "" && typeof this.state.organizationpatrimony[key] !== 'undefined' && this.state.organizationpatrimony[key] !== 'undefined') {
          data.append(key, this.state.organizationpatrimony[key].id);
        }else if (key.toString().toLowerCase().indexOf('invoicedate') > -1) {
          data.append(key, moment(this.state.organizationpatrimony[key]).format('YYYY/MM/DD HH:mm:ss'));
        }else {
          data.append(key, this.state.organizationpatrimony[key]);
        }
      }

      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
        Api.put('/organizationpatrimonies', data, config)

        .then(result => {
          const intl = this.props.intl;
          if (result.data.success) {
        const formattedList = result.data.data;
        formattedList.DepartamentId=  formattedList.departament ? formattedList.departament : 0;
        formattedList.DepartamentText=  formattedList.departament ? formattedList.departament.name : '';
        formattedList.PatrimonySubgroupId=  formattedList.patrimonysubgroup ? formattedList.patrimonysubgroup : 0;
        formattedList.PatrimonySubgroupText=  formattedList.patrimonysubgroup ? formattedList.patrimonysubgroup.name : '';
        formattedList.OrganizationId=  formattedList.organization ? formattedList.organization : 0;
        formattedList.OrganizationText=  formattedList.organization ? formattedList.organization.name : '';
        formattedList.PatrimonyGroupId=  formattedList.patrimonygroup ? formattedList.patrimonygroup : 0;
        formattedList.PatrimonyGroupText=  formattedList.patrimonygroup ? formattedList.patrimonygroup.name : '';
        formattedList.PatrimonyMaterialId=  formattedList.patrimonymaterial ? formattedList.patrimonymaterial : 0;
        formattedList.PatrimonyMaterialText=  formattedList.patrimonymaterial ? formattedList.patrimonymaterial.name : '';

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: this.props.intl.formatMessage({id:"edit.success"}),
              files: []
            });
        if(this.props.organizations && this.state.organizations.organization)
          this.props.addCurrentOrganization(formattedList, this.state.organizations.organization);
        else
          this.props.addCurrent(formattedList);
        this.props.addCurrent(formattedList);
        this.componentDidMount();
 
          }
          else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage:  result.data.errors && result.data.errors[0] ? result.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });

          }
        })
        .catch(err => {
          const intl = this.props.intl;
          if(err.response && err.response.data && err.response.data.errors){
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
          }
          else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})+err.response.data
            });
          }
        });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      organizationpatrimony: {
        ...prevState.organizationpatrimony,
        [stateName]: value
      }
    }));
        if(stateName === 'DepartamentId'){
          this.setState(prevState => ({
            organizationpatrimony: {
              ...prevState.organizationpatrimony,
              ['DepartamentText']: text,
            }
          }));
        }
        if(stateName === 'PatrimonySubgroupId'){
          this.setState(prevState => ({
            organizationpatrimony: {
              ...prevState.organizationpatrimony,
              ['PatrimonySubgroupText']: text,
              ['PatrimonyMaterialId'] : "",
            }
          }));
          this.getPatrimonyMaterials(value);
        }
        if(stateName === 'OrganizationId'){
          this.setState(prevState => ({
            organizationpatrimony: {
              ...prevState.organizationpatrimony,
              ['OrganizationText']: text,
              ['DepartamentId'] : ""
            }
          }));
          this.getDepartaments(value);
        }
        if(stateName === 'PatrimonyGroupId'){
          this.setState(prevState => ({
            organizationpatrimony: {
              ...prevState.organizationpatrimony,
              ['PatrimonyGroupText']: text,
              ['PatrimonySubgroupId'] : "",
              ['PatrimonyMaterialId'] : "",
            }
          }));
          this.getPatrimonySubgroups(value);
        }
        if(stateName === 'PatrimonyMaterialId'){
          this.setState(prevState => ({
            organizationpatrimony: {
              ...prevState.organizationpatrimony,
              ['PatrimonyMaterialText']: text,
            }
          }));
        }
 
    this.setState({ loading: false });

  };
  
  printQRCode(){
    window.print();
  }
  QRCode(){
    this.openModal();
  }

  openModal(){
    this.setState({  modalopen:true });

  }
  async closeModal(){
      this.setState({  modalopen:false });

  }

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

    render() {
    const { classes, headerMenu, actionsHeaderMenu, organizationpatrimonies } = this.props;
    const { organizationpatrimony } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    const qrcodevalue = { 
      patrimonyid: this.state.organizationpatrimony.id,
      organizationid: this.state.organizationpatrimony.OrganizationId,
      organizationname: this.state.organizationpatrimony.OrganizationText,
      name:this.state.organizationpatrimony.name,
      tipping:this.state.organizationpatrimony.tipping,
      garanty:this.state.organizationpatrimony.garanty,
      value:this.state.organizationpatrimony.value,
      serialnumber:this.state.organizationpatrimony.serialnumber,
      brand:this.state.organizationpatrimony.brand,
      model:this.state.organizationpatrimony.model,
        
    };
     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary'  onClick={() => {
                      this.fromProps ? this.props.history.push(`/${this.props.match.params.lang}/organization-edit`, { organizations: this.props.organizations, propTab: 9 }) : browserHistory.goBack()}
                    }>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={4} sm={4} ></Grid>
                <Grid item xs={4} sm={4} >
                      <Button variant='outlined' color='primary'  onClick={this.QRCode}><CropFreeIcon/> QRCode</Button>

                      <TransitionsModal isopen={this.state.modalopen} handleOpen={this.openModal} handleClose={this.closeModal}>
                        <Grid container spacing={6}>

                            <Grid item xs={12} sm={12}>
                            <ReactToPrint
          trigger={() => <Button variant='outlined' color='primary'><PrintIcon/></Button>}
          content={() => this.componentRef}
        />
                              <Qr valor={JSON.stringify(qrcodevalue)}   ref={el => (this.componentRef = el)} />
                            </Grid>
                        </Grid>
                      </TransitionsModal>

                </Grid>

              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
              {<FormattedMessage id="edit.item" />}
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>    
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <SimpleSelect label={intl.formatMessage({id:"organization.header"})} name='organizationid'  options={this.state.OrganizationList} stateName='OrganizationId' changeSelect={this.changeValues} selected={organizationpatrimony.OrganizationId} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete label={intl.formatMessage({id:"departament"})} name='departamentid'  options={this.state.DepartamentList} stateName='DepartamentId' changeSelect={this.changeValues} selected={organizationpatrimony.DepartamentId} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete label={intl.formatMessage({id:"menu.patrimonygroups"})} name='patrimonygroupid'  options={this.state.PatrimonyGroupList} stateName='PatrimonyGroupId' changeSelect={this.changeValues} selected={organizationpatrimony.PatrimonyGroupId} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete label={intl.formatMessage({id:"menu.patrimonysubgroups"})} name='patrimonysubgroupid'  options={this.state.PatrimonySubgroupList} stateName='PatrimonySubgroupId' changeSelect={this.changeValues} selected={organizationpatrimony.PatrimonySubgroupId} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete label={intl.formatMessage({id:"menu.patrimonymaterials"})} name='patrimonymaterialid'  options={this.state.PatrimonyMaterialList} stateName='PatrimonyMaterialId' changeSelect={this.changeValues} selected={organizationpatrimony.PatrimonyMaterialId} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"name"})} inputProps={{ maxLength: 250 }} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={organizationpatrimony.name} required />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"tipping"})} inputProps={{ maxLength: 250 }} autoComplete='fname' variant='outlined' name='tipping' onChange={this.handleInputChange} value={organizationpatrimony.tipping} required />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                    <TextField
                      label={this.props.intl.formatMessage({id:"value"})}
                      variant='outlined'
                      value={organizationpatrimony.value}
                      onChange={this.handleInputChangeValue}
                      name="value"
                      id="formatted-numberformat-input"
                      InputProps={{
                        inputComponent: this.NumberFormatCustom,
                      }}
                      autoComplete='fname'
                      required
                      InputLabelProps={{ shrink: true }}
                    />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"serial.number"})} inputProps={{ maxLength: 250 }} autoComplete='fname' variant='outlined' name='serialnumber' onChange={this.handleInputChange} value={organizationpatrimony.serialnumber} required />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"brand"})}  inputProps={{ maxLength: 250 }} autoComplete='fname' variant='outlined' name='brand' onChange={this.handleInputChange} value={organizationpatrimony.brand} required />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"model"})}  inputProps={{ maxLength: 250 }} autoComplete='fname' variant='outlined' name='model' onChange={this.handleInputChange} value={organizationpatrimony.model} required />
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <SimpleDatePicker label={this.props.intl.formatMessage({id:"material.registration.note.date"})} name='invoicedate' stateName='invoicedate' changeDate={this.changeValues} currentDate={organizationpatrimony.invoicedate}  required/>
                    </Grid>
                    <Grid item xs={3} sm={3}></Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField id='filled-number' label={intl.formatMessage({id:"garanty"})} type='number' variant='filled' InputLabelProps={{shrink: true}} name='garanty' onChange={this.handleInputChange} value={organizationpatrimony.garanty} required />
                    </Grid>
                                    <Grid item xs={11} sm={11}>
                                        <SimpleCheckBox
                                        label={intl.formatMessage({id:"apply.changes.to.all.organizations.in.group"})}
                                        name='saveToAllOrganizationsInGroup'
                                        stateName='saveToAllOrganizationsInGroup' 
                                        changeSelect={this.changeValues}
                                        selected={organizationpatrimony.saveToAllOrganizationsInGroup} />
                                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                      <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                        <Grid item xs={12} sm={4}    >
                          <Button 
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                          >
                            {<FormattedMessage id="save" />}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />                    
      </div>
    );
  }
}
OrganizationPatrimonyEdit.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                  actionsHeaderMenu: state.actionsHeaderMenu,
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                    organizations: state.organizations,
                    organizationpatrimonies: state.organizationpatrimonies
                });
                const mapDispatchToProps = dispatch =>
                    bindActionCreators(componentActions, dispatch);

            export default injectIntl(connect(mapStateToProps,mapDispatchToProps)(withStyles(Styles)(OrganizationPatrimonyEdit)));
