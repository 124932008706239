import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import { Styles } from '../../styles/material-styles'
import { AbilityContext } from '../../config/ability-context'
import Base from '../../components/layout-base/base'
import PortalContent from './index.content'
import { injectIntl } from 'react-intl';

class Portal extends Component {

  constructor(props) {
    super(props)
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }
                    
  componentDidMount() {}
                     
  render() {               
    return (           
      <Base props={this.props}>
        <PortalContent />
      </Base>        
    )
  }
}

Portal.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
})

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(Portal)));
Portal.contextType = AbilityContext;