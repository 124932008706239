import React, { PureComponent } from 'react';
import {
  PieChart, Pie, Sector, Cell,
} from 'recharts';

import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Card, CardContent, Grid, Typography, Avatar, CardHeader } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import MoneyIcon from '@mui/icons-material/Money';
import Steps from '@mui/icons-material/Money';
import { FormattedMessage, injectIntl } from 'react-intl';
import Divider from '@mui/material/Divider'




import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// const data = [
//   { name: 'Group A', value: 1 },
//   { name: 'Group B', value: 2 },
//   { name: 'Group D', value: 4 },
// ];

const COLORS = ['#008e7d', '#52c5a9', '#ffe234', '#ffa534', '#db324d'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default class Pizza extends PureComponent {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/c9pL8k61/';



  totalIndex() {
    return this.props.data[0].value + this.props.data[1].value + this.props.data[2].value + this.props.data[3].value + this.props.data[4].value
  }

  getPercent(indexValue) {
    let total = this.totalIndex();

    if (indexValue <= 0) {
      return 0;
    } else {
      return ((indexValue * 100) / total).toFixed(2);
    }
  }

  calculate() {
    /*if (this.props.data[0].value + this.props.data[1].value + this.props.data[2].value === 0) {
      return 0
    }
    if (this.props.data[2].value - this.props.data[0].value == 0) {
      return 0
    }
    let valor = (this.props.data[2].value - this.props.data[0].value) / (this.props.data[0].value + this.props.data[1].value + this.props.data[2].value)

    valor = valor * 100;
    // valor = valor.toFixed(2);
    return Math.round(valor)*/
    var total = this.props.data[0].value + this.props.data[1].value + this.props.data[2].value + this.props.data[3].value + this.props.data[4].value
    var getBestRated = this.props.data[0].value + this.props.data[1].value

    var calculate = 0
    if(getBestRated != 0 && total != 0 ){
      calculate = ((getBestRated)/total)*100
    }
    var result = calculate.toFixed(2);
    return result


  }

  render() {

    console.log("props pizza aqui")
    console.log(this.props)
    let colorBackNPS = 'white';

    if (this.calculate() < 0 || this.calculate() === 0) {
      colorBackNPS = '#E74C3C';
    }

    else if(this.calculate() > 0 && this.calculate() <= 50 ){
      colorBackNPS = '#FFBB28';
    }

    else if(this.calculate() > 50 && this.calculate() <= 75 ){
      colorBackNPS = '#ece637';
    }

    else if(this.calculate() > 75 ){
      colorBackNPS = '#00C49F';
    }

    return (

      <Card>

        <CardHeader
          title="Índice de satisfação do cliente (CSAT)"
        />
        <Divider />
        <CardContent>
          <Grid container >   
          <Grid item xs={12}>
              <Grid item xs={8} style={{ float: 'left' }}>
                <PieChart width={300} height={250}>
                  <Pie
                    data={this.props.data}
                    cx={150}
                    cy={100}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {
                      this.props.data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index]} />)
                    }
                  </Pie>
                </PieChart>
              </Grid>

              <Grid item xs={4} style={{ float: 'right' }}>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" style={{ backgroundColor: '#8884d8', color: 'white', fontSize: '15px' }}><strong>CSAT SCORE</strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" style={{ fontSize: '15px', backgroundColor: 'white', color: 'black'  }}><strong>{this.calculate()}</strong></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            {/*</Grid>
              <Grid item xs={1}  >
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" style={{ backgroundColor: '#8884d8', color: 'white', fontSize: '15px' }}><strong>CSAT SCORE</strong></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" style={{ fontSize: '15px', backgroundColor: 'white', color: 'black'  }}><strong>{this.calculate()}</strong></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

              <Grid item align="center" xs={12} style={{marginTop:'8px', marginBottom:'8px'}} >
                <PieChart width={300} height={250} >
                  <Pie
                    data={this.props.data}
                    cx={150}
                    cy={125}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={120}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {
                      this.props.data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index]} />)
                    }
                  </Pie>
                </PieChart>
                
                
                  </Grid>*/}

             {/* <Grid item xs={4}>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" style={{ backgroundColor: 'DarkGray', color: 'white', fontSize: '15px' }}><strong>CSAT SCORE</strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" style={{ fontSize: '15px', backgroundColor: 'white', color: 'black'  }}><strong>{this.calculate()}</strong></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                  </Grid>*/}


            {/* <Grid item sm={6} md={6}>
              <Grid item sm={6} md={1} style={{ marginBottom: 10 }}>
                <div style={{ backgroundColor: '#E74C3C', padding: '5px', paddingBottom: '0px', textAlign: 'center', borderRadius: '3px' }}> <SentimentVeryDissatisfiedIcon style={{ color: 'white' }} /> </div>
              </Grid>
              <Grid item sm={10} md={11} style={{ fontSize: 16, marginBottom: 10, marginTop: 5 }} > &nbsp;Detrator &nbsp;&nbsp; {this.props.data[0].value}</Grid>

              <Grid item sm={6} md={1} style={{ marginBottom: 10 }}>
                <div style={{ backgroundColor: '#FFBB28', padding: '5px', paddingBottom: '0px', textAlign: 'center', borderRadius: '3px' }}> <SentimentSatisfiedIcon style={{ color: 'white' }} /> </div>
              </Grid>
              <Grid item sm={10} md={11} style={{ fontSize: 16, marginBottom: 10, marginTop: 5 }} > &nbsp;Neutro  &nbsp;&nbsp; {this.props.data[1].value}</Grid>

              <Grid item sm={6} md={1} style={{ marginBottom: 10 }}>
                <div style={{ backgroundColor: '#00C49F', padding: '5px', paddingBottom: '0px', textAlign: 'center', borderRadius: '3px' }}> <SentimentSatisfiedAltIcon style={{ color: 'white' }} /> </div>
              </Grid>
              <Grid item sm={10} md={11} style={{ fontSize: 16, marginBottom: 10, marginTop: 5 }} > &nbsp;Promotor  &nbsp;&nbsp; {this.props.data[2].value}</Grid>

              <Grid item xs={12} style={{ fontSize: 16 }} ><b>Score:</b> {this.calculate()} %</Grid>
            </Grid> */}




            <Grid item xs={12} style={{marginTop:'0px'}} >
              <span>Resultado CSAT</span>
              <TableContainer style={{marginTop:'5px'}}>
                <Table size="small" aria-label="a dense table" >
                  <TableBody style={{ border: 1, borderColor: 'black', borderStyle: 'solid' }}>
                  <TableRow>
                      <TableCell component="th" scope="row" style={{ backgroundColor: '#8884d8', color:'white' , fontWeight:'bold'}}>
                        Legenda
                      </TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid',  backgroundColor: '#8884d8', color:'white', fontWeight:'bold'}}>Situação</TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid',  backgroundColor: '#8884d8', color:'white', fontWeight:'bold'}}>Percentual</TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid',  backgroundColor: '#8884d8', color:'white', fontWeight:'bold' }}>Quantidade</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" style={{ backgroundColor: '#008e7d' }}>
                        
                      </TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>Muito Bom</TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.getPercent(this.props.data[0].value)}%</TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.props.data[0].value}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row" style={{ backgroundColor: '#52c5a9' }}>
                        
                      </TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>Bom</TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.getPercent(this.props.data[1].value)}%</TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.props.data[1].value}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row" style={{ backgroundColor: '#ffe234' }}>
                        
                      </TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>Neutro</TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.getPercent(this.props.data[2].value)}%</TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.props.data[2].value}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row" style={{ backgroundColor: '#ffa534' }}>
                        
                      </TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>Ruim</TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.getPercent(this.props.data[3].value)}%</TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.props.data[3].value}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row" style={{ backgroundColor: '#db324d' }}>
                       
                      </TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>Muito ruim</TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.getPercent(this.props.data[4].value)}%</TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.props.data[4].value}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row" align="center" style={{ backgroundColor: 'darkgrey', color: 'white' }} colSpan={3}>
                        <strong>Total</strong>
                      </TableCell>
                      <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{this.totalIndex()}</TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item sm={2} md={2}>

            </Grid>

            {/*<Grid item sm={5} md={5} style={{ paddingBottom: '10px' }}>
              <span>Legenda</span>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" style={{ backgroundColor: 'DarkGray', color: 'white' }}><strong>Zona</strong></TableCell>
                      <TableCell align="right" style={{ backgroundColor: 'DarkGray', color: 'white' }}><strong>Faixa</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row" style={{ backgroundColor: '#E74C3C', color: 'white' }} align="left">
                        Crítica
                      </TableCell>
                      <TableCell align="right">-100 a 0</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row" style={{ backgroundColor: '#FFBB28', color: 'white' }} align="left">
                        Aperfeiçoamento
                      </TableCell>
                      <TableCell align="right">1 a 50</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row" style={{ backgroundColor: '#ece637', color: 'white' }} align="left">
                        Qualidade
                      </TableCell>
                      <TableCell align="right">51 a 75</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell component="th" scope="row" style={{ backgroundColor: '#00C49F', color: 'white' }} align="left">
                        Excelência
                      </TableCell>
                      <TableCell align="right">76 a 100</TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
              */}
          </Grid>
          



        </CardContent>
      </Card>


    );
  }
}
