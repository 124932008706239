import React, { Component, Fragment } from "react";
import clsx from 'clsx';
import PropTypes from "prop-types";
import moment from "moment";
import { Button, CssBaseline, TextField, Grid, CircularProgress, Container, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { Menu, MenuItem } from '@mui/material';

import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import * as AccompanimentService from '../../services/accompaniment.service';
import * as CalledService from '../../services/called.service';
import CustomizedSnackbars from "../../components/material-snackbars";
import SimpleSelect from "../../components/select/simple";
import SimpleDatePicker from "../../components/date-pickers/inline";
import HeaderMenu from "../../components/header-menu";
import { DeleteForever, AttachFile } from '@mui/icons-material';
import Dropzone from 'react-dropzone'; 
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AccompanimentAction from '../../components/accompaniment-actions';
import * as calledsActions from '../../redux/actions/calleds';
import { Link } from 'react-router-dom';
import ImageList from '@mui/material/ImageList';
import SimpleTreeView from '../../components/tree-view/tree';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import EmailIcon from '@mui/icons-material/Email';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import { getSlaAtendimento, getSlaSolucao } from '../called-list/called.functions';
import SimpleSlider from '../../components/slider/slider';
import { FormattedMessage,injectIntl } from 'react-intl';

import TransitionsModal  from '../../components/modal/modal';
import VideoCall from '../../videocall/src/js/VideoCall';
import PanToolIcon from '@mui/icons-material/PanTool';
//import socket from '../../videocall/src/js/socket';
import DuoIcon from '@mui/icons-material/Duo';

import 'react-quill/dist/quill.snow.css'; // ES6
import ReactQuill from 'react-quill'; // ES6
import { PapiroConsole } from "../../utils/papiroConsole";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Typography from '@mui/material/Typography';

class CalledRegistration extends Component {

    constructor(props) {
        super(props);

        const { userSession, calleds } = this.props;

        window.addEventListener('beforeunload', (event) => {
            //console.log('beforeunload');
            this.cleanCalledVideoCall();
        
        });
        

        //console.log(this.props);
        //console.log(calleds);
        //this.isClient = userSession.user.role.roleType === 3;
        this.globalAdm = false;
        this.AdmOrganization = false;
        this.isCoordenator = false;
        this.isTechinical = false;
        this.isClient = true;
        this.OrganizationCategoryId = null;
        if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
          Api.logoff();
        }
        if(!calleds || (calleds && !calleds.called)) {
            var l = this.props.match.params.lang;
            document.location.href=`/${l}/calleds`
        }

        PapiroConsole.log("=== userSession ===")
        PapiroConsole.log(userSession)

        userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
                this.globalAdm =true;
            }
            if(item.role.roletype ==1){
                this.AdmOrganization =true;
            }
            if(item.role.roletype ==4){
                this.isCoordenator =true;
            }
            if(item.role.roletype ==2){
                this.isTechinical =true;
            }
        });
        this.sametechinical = false;

        if(this.isTechinical && calleds.called.assigneduser && calleds.called.assigneduser.id ==  userSession.user.id){
            this.sametechinical = true;
        } 

        if(calleds.called.requestinguser.id == userSession.user.id){
            var samePerson = true;
        }else{
            var samePerson = false;
        }

        console.log(calleds)
        console.log("calleds aqui")

        this.reactQuillModules = {
            toolbar: [
              [{ 'header': [1, 2, false] }],
              ['bold', 'italic', 'underline','strike', 'blockquote'],
              [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
              ['link', 'image'],
              ['clean']
            ],
        }
        
        this.reactQuillFormats = [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image'
        ]

        this.state = {
            called: {
                id: calleds.called.id,
                requestingUser: calleds.called.requestinguser.id,
                organization: calleds.called.organization.id,
                organizationsettings : calleds.called.organization.organizationsettings,
                type: calleds.called.type && calleds.called.type.id,
                OrganizationCategoryId: calleds.called.organizationcategory ,
                OrganizationCategoryText: calleds.called.organizationcategory && calleds.called.organizationcategory.name,
                parentCategory: calleds.called.organizationcategory.parentorganizationcategory && calleds.called.organizationcategory.parentorganizationcategory.id,
                addressid: calleds.called.address ? calleds.called.address.id : 0,
                dueDate: calleds.called.duedate,
                openingDate: calleds.called.openingdate,
                title: calleds.called.title,
                status: calleds.called.status && calleds.called.status.id,
                description: '',
                originRequest: calleds.called.originrequest && calleds.called.originrequest.id,
                observantUser: calleds.called.observantuser ? calleds.called.observantuser.id : 0,
                assignedUser: calleds.called.assigneduser ? calleds.called.assigneduser.id : 0,
                updatedUser: userSession.user.id ? userSession.user.id : 0,
            },
            action: {
                description: "",
                privacy: "false"
            },
            files: [],
            loading: false,
            openNotification: false,
            notificationVariant: "error",
            notificationMessage: "",
            categoriesList: [],
            categoriesListAll: [],
            technicalUsersList: [],
            organizationAddressesList: [],
            typesList: [],
            statusList: [],
            anchorEl:null,
            FinishCalledId: 0,
            ReopenCalledId: 0,
            samePerson: samePerson,
            reloadBtn: true,
            disablebutton: false,
            modalopen:false,
            user : userSession.user,
            acceptRejectionBool: false,
            declineRejectionBool: false,
            disablebutton: false,
            hirerid: userSession.user.hirerid ? userSession.user.hirerid : 0
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.closeNotification = this.closeNotification.bind(this);
        this.getAccompainmentFile = this.getAccompainmentFile.bind(this);
        this.useStyles = this.useStyles.bind(this);
        this.videoCallClick = this.videoCallClick.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.declineRejection = this.declineRejection.bind(this);
        this.acceptRejection = this.acceptRejection.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.reopenCalledClient = this.reopenCalledClient.bind(this);
        this.finishCalledClient = this.finishCalledClient.bind(this);
        this.getOrganizationAddresses = this.getOrganizationAddresses.bind(this)

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

    };

        async componentWillReceiveProps(nextProps){
            if(nextProps.location.state === "reload"){
                this.componentDidMount();
            }
        }    

     async componentDidMount() {
        //console.log('isTechinical: '+this.isTechinical);
        //console.log('sametechinical: '+this.sametechinical);

        if(this.isTechinical == true){
            if( !this.sametechinical ){
                this.setState({disablebutton: true});
            }
        }
        await this.getCalledDescriptionById();
        await this.refreshAccompaniments();
        //console.log('this.state.disablebutton: '+this.state.disablebutton);
        if (!this.isClient) {
            //console.log(this.state.called.organizationsettings);
            await this.getCategoriesByOrganization(this.state.called.organization);
            await this.getTechnicalUsers();
            await this.getTypes();
            await this.getStatus();
        }else{
            const { intl } = this.props
            await this.getStatus();
            this.state.statusList.map((item, key) => {
                if(item.statustype.id == 5){
                    this.setState({FinishCalledId: item.id});
                }
                if(item.name == intl.formatMessage({id:"dashboard.report.in.progress"})){
                    this.setState({ReopenCalledId: item.id});
                }
                
                if(item.id == this.state.called.status && item.statustype.id == 5){
                    this.setState({ FinishCalled: true });
                }
                if(item.id == this.state.called.status && item.statustype.id == 2){
                    this.setState({ FinishCalled: false });
                }
            });
        }
        this.getOrganizationAddresses(this.state.called.organization)
        
        await this.cleanCalledVideoCall();

    };

    getCalledDescriptionById = async () => {
        const { calleds, intl } = this.props;

        var result = await CalledService.getCalledDescriptionById(calleds.called.id)
        if(result && result.data && result.data.data) {
            this.setState(prevState => ({
                called: {
                    ...prevState.called,
                    description: result.data.data
                }
            }))
        } else {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
              })
        }
    }
    

    handleClick = (event,value) => {
        //console.log(event.currentTarget);
        this.setState({ anchorEl: event.currentTarget });
    };

    declineRejection = () => {
        const { action } = this.state;
        this.setState({
            loading: true,
        });
        
        if(action.description == ""){
            const intl = this.props.intl;
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({id:"erro.action.rejection"})
            });
            return null
        }
        
        this.submitAction("declineRejection");
    }

    acceptRejection = () => {
        this.setState({
            loading: true,
        });
        const { action } = this.state;
        
        if(action.description != ""){
            this.submitAction("acceptRejection");
        }else{
            this.submitCalled("acceptRejection");
        }
    }

    redirectToCalled = () => {

        if(this.props && this.props.match && this.props.match.params.lang ){
            this.props.history.push(`/${this.props.match.params.lang}/called-accompaniment/${this.state.called.id}`);
        }
        
       
    }
    
    reopenCalledClient(data) {
       

        //var data  = { Id: idCalled, status: status, updatedUser: updatedUser   };

        Api.put("/calleds/CalledClientAlter", data)
        .then(result => {
            if (result.data.success) {
                this.setState({ FinishCalled: true });
                this.props.addCurrentCalled(result.data.data);
                this.props.addCalled(result.data.data);
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "success",
                    notificationMessage: intl.formatMessage({id:"edit.called.success"}),
                    files: [],
                    action: {
                        description: "",
                        privacy: "false"
                    }
                });
                document.getElementById('btnAccept').classList.add("Mui-disabled");
                document.getElementById('btnDecline').classList.add("Mui-disabled");
    
            }
        })
        .catch(err => {
            const intl = this.props.intl;
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
        });
        
      
    }

    finishCalledClient(data) {

        //var data  = { Id: idCalled, status: status, updatedUser: updatedUser  };

        Api.put("/calleds/CalledClientAlter", data)
            .then(result => {
                if (result.data.success) {
                    this.setState({ FinishCalled: false });
                    this.props.addCurrentCalled(result.data.data);
                    this.props.addCalled(result.data.data);
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "success",
                        notificationMessage: intl.formatMessage({id:"edit.called.success"}),
                        files: [],
                        action: {
                            description: "",
                            privacy: "false"
                        },
                    });
                    document.getElementById('btnAccept').classList.add("Mui-disabled");
                    document.getElementById('btnDecline').classList.add("Mui-disabled");
        
                }
            })
            .catch(err => {
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                });
            });
            
            //setTimeout(function(){ window.location.reload(); }, 3000);
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    };      

      
    useStyles() {
        return withStyles({
        ImageList: {
          width: "100%",
          height: 100,
        },  
      });        
    }
    async getOrganizationAddresses(organizationId) {
        this.setState({ loading: true });

        const result = await CalledService.getOrganizationAddresses(organizationId);

        if (result.success) {
            this.setState({ loading: false, organizationAddressesList: result.data });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);

        }
    };

    async getCategories() {
        this.setState({ loading: true });

        var result = await CalledService.getAllCategories();

        if (result.success) {
            this.setState({ loading: false, categoriesListAll: result.data });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
            Api.kickoff(result);
        }

        result = await CalledService.getCategories();
        if (result.success) {
            this.setState({ loading: false, categoriesList: result.data });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
            Api.kickoff(result);
        }
    };
    async getCategoriesByOrganization(organizationId) {
        this.setState({ loading: true });
         
        var result = await CalledService.getCategoriesByOrganization(organizationId);
    
        if (result.success) {
          this.setState({ loading: false, categoriesList: result.data });
        } else {
            const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
          });
        }
      };
    
    async getTechnicalUsers() {
        this.setState({ loading: true });

        const result = await CalledService.getTechnicalUsers(this.state.called.OrganizationCategoryId.id,this.state.called.organization);

        if (result.success) {
            this.setState({ loading: false, technicalUsersList: result.data });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
            Api.kickoff(result);
        }
    };

    async getTypes() {
        this.setState({ loading: true });

        const result = await CalledService.getTypes();

        if (result.success) {
            this.setState({ loading: false, typesList: result.data });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
            Api.kickoff(result);
        }
    };

    async getStatus() {
        this.setState({ loading: true });

        const result = await CalledService.getStatus();

        if (result.success) {
            this.setState({ loading: false, statusList: result.data });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
            Api.kickoff(result);
        }
    };



    handleSubmit = async e => {
        e.preventDefault();

        this.setState({ loading: true });

        this.submitAction();
    }
    saveCalled = async e => {
        e.preventDefault();

        this.setState({ loading: true });
        this.submitCalled();

    }

    refreshAccompaniments = async () => {
        this.setState({
            loading: true,
        });
        Api.get(`/accompaniments/called/${this.state.called.id}`)
            .then(result => {
                if (result && result.data && result.data.success && result.data.data) {
                    //alert('ATUALIZAR CHAMADOS');
                    this.props.addAccompanimentsList(result.data.data);
                    this.props.addAccompanimentsListInCalled(result.data.data, this.state.called.id)
                    this.setState({
                        loading: false,
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    this.setState({
                        loading: false,
                    });

                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "error",
                        notificationMessage: intl.formatMessage({ id: "process.error" })
                    });
                    this.setState({
                        loading: false,
                    });

                }
            });

    }

    submitAction(filter = "") {

        const { userSession } = this.props;
        const { action, called } = this.state;

        let data = new FormData();

        data.append('description', action.description);
        data.append('calledTitle', called.title);
        data.append('privacy', action.privacy);
        data.append('called', called.id);
        data.append('userAction', userSession.user.id);

        for (let key in this.state.called) {
            let name = `called${key}`;
            if (key === 'dueDate') {
                data.append(name.toLowerCase(), moment(this.state.called[key]).format("YYYY/MM/DD HH:mm:ss"));
            } 
            else if (key.toString().toLowerCase().indexOf('organizationcategoryid') > -1) { 
                data.append("OrganizationCategory", this.state.called[key].id);
            }
            else {
                data.append(name.toLowerCase(), this.state.called[key]);
            }
        }

        this.state.files.forEach(file => data.append('filesUpload', file));

        let config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };

        Api.post("/accompaniments", data, config)
            .then(result => {
                if (result.data.success) {
                    this.props.addAccompanimentsList(result.data.data);
                    this.props.addAccompanimentsListInCalled(result.data.data, this.state.called.id)
                    if (!this.isClient) {
                        if(filter == "acceptRejection"){
                            this.submitCalled("acceptRejection");
                        }else if(filter == "declineRejection"){
                            this.submitCalled("declineRejection");
                        }else{
                            this.submitCalled();
                        }   
                    } else {
                        const intl = this.props.intl;
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "success",
                            notificationMessage: intl.formatMessage({id:"add.action.success"}),
                            files: [],
                            action: {
                                description: "",
                                privacy: "false"
                            }
                        });
                    }
                }
            })
            .catch(err => {
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                });
            });
    }

    submitCalled(filter = "") {
        let data = new FormData();

        for (let key in this.state.called) {
            if (key === 'dueDate') {
                data.append(key, moment(this.state.called[key]).format("YYYY/MM/DD HH:mm:ss"));
            } 
            else if (key.toString().toLowerCase().indexOf('organizationcategoryid') > -1) { 
                data.append("OrganizationCategory", this.state.called[key].id);
            }
            else if(filter == "acceptRejection" && key.toString().toLowerCase().indexOf('status') > -1){
                var statusAcceptRejection = 0;
                this.state.statusList.map((item, key) => {
                    if(item.id == 5){
                        statusAcceptRejection = item.id;
                    }
                });
    
                data.append('status', statusAcceptRejection);
            }else if(filter == "declineRejection" && key.toString().toLowerCase().indexOf('status') > -1){
                var statusRejection = 0;
                this.state.statusList.map((item, key) => {
                    if(item.id == 4){
                        statusRejection = item.id;
                    }
                });
    
                data.append('status', statusRejection);
            }
            else{
                data.append(key, this.state.called[key]);
            }
        }

        /*
        for (var pair of data.entries()) {
        }*/
        if(filter == "acceptRejection"){
            this.reopenCalledClient(data);
}
        else if(filter == "declineRejection"){
            this.finishCalledClient(data);

        }
        /*
        Api.put("/calleds", data)
            .then(result => {
                if (result.data.success) {
                    //console.log(result.data.data);
                    const intl = this.props.intl;
                    document.getElementById('btnAccept').classList.add("Mui-disabled");
                    document.getElementById('btnDecline').classList.add("Mui-disabled");
                    this.props.addCurrentCalled(result.data.data);
                    this.props.addCalled(result.data.data);
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: "success",
                        notificationMessage: intl.formatMessage({id:"edit.called.success"}),
                        files: [],
                        action: {
                            description: "",
                            privacy: "false"
                        }
                    });
                }
            })
            .catch(err => {
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                });
            });
            */
    }

    handleInputChange(e, origin) {
        const name = e.target.name;
        const value = e.target.value;
        const formName = origin ? 'action' : 'called';

        this.setState(prevState => ({
            [formName]: {
                ...prevState[formName],
                [name]: value
            }
        }));
    };

    changeValues = (stateName, value, text = '') => {
        this.setState(prevState => ({
            called: {
                ...prevState.called,
                [stateName]: value
            }
        }));
        if(stateName === "OrganizationCategoryId"){
            this.OrganizationCategoryId = value;
            this.setState(prevState => ({
              called: {
                ...prevState.called,
                ["OrganizationCategoryText"]: text
              }
            }));
          }
      
    };

    closeNotification() {
        this.setState({ openNotification: false });
    }

    removeItem(file) {
        let array = [...this.state.files];
        let index = array.indexOf(file);
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ files: array });
        }
    }
    videoCallClick(value)  {
        //console.log(value);
        this.setState({ anchorEl: null, modalopen:true });
      };
    openModal(){

    }
    async closeModal(){
        //console.log('close');
        
        //let clientId = localStorage.getItem('clientId');
        //await socket.emit('disconnect', clientId);
        //socket.disconnect(); 
        //socket.removeAllListeners();

        await this.cleanCalledVideoCall();
        this.setState({  modalopen:false });

    }

    async cleanCalledVideoCall() {
        this.setState({
            loading: false,
            openNotification: false,
        });
        /*let data = new FormData();
    
        data.append("Key","#");
        data.append("Active",false);
        data.append("CalledId",this.state.called.id);
        data.append("UserId",this.state.user.id);
        Api.put("/calledvideocalls/updateall", data)
            .then(result => {
                if (result.data.success) {
                    //console.log(result.data.data);
                    this.setState({
                        loading: false,
                        openNotification: false,
                    });
                }
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                });
            });*/
    }
    
    async getCalledFile(fileName,filerepositorymethod) {
		const intl = this.props.intl;
		this.setState({ loading: true });
        
		
		let url = await CalledService.getFile(this.state.hirerid,this.state.called.id,fileName,filerepositorymethod); 
		//alert(JSON.stringify(url.data));
		this.setState({ loading: false });
		if(url &&  url.data && url.data.data && url.data.success){
			window.open(`${url.data.data}`, '_blank');
		}
		else{
			this.setState({
				loading: false, openNotification: true, notificationVariant: "error",
				notificationMessage: intl.formatMessage({ id: "file.not.found" })
			});

		}
    };

    async getAccompainmentFile(fileName, accompanimentId,filerepositorymethod) {
		const intl = this.props.intl;
		this.setState({ loading: true });

		let url = await AccompanimentService.getFile(this.state.hirerid,this.state.called.id,accompanimentId,fileName,filerepositorymethod); 
        this.setState({ loading: false });

		if(url &&  url.data && url.data.data && url.data.success){
			window.open(`${url.data.data}`, '_blank');
		}
		else{
			this.setState({
				loading: false, openNotification: true, notificationVariant: "error",
				notificationMessage: intl.formatMessage({ id: "file.not.found" })
			});

		}
    };

    render() {
        const { classes, headerMenu, actionsHeaderMenu, calleds } = this.props;

        
        const { called, action } = this.state;
        console.log(called)
        console.log("calleds no render")
        const classes2 = this.useStyles();
        const intl = this.props.intl;

        
        if(Api == null) {
            var l = this.props.match.params.lang;
            document.location.href=`/${l}/unavailable`
          }

        //console.log(called.organizationsettings.canassingusercalled ); 
        //console.log(this.isClient ); 
        //console.log(this.isCoordenator ); 
        //var categoriesId="'0',"+this.state.categoriesListAll.map(item => `'${item.id}'`).join(",");  
        //console.log(categoriesId);
        const calledBody = () => {
            return (
                <Fragment>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label={intl.formatMessage({id:"organization"})}
                                name="organizacao"
                                value={calleds.called.Organization}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label={intl.formatMessage({id:"requestuser"})}
                                name="solicitante"
                                value={calleds.called.requestinguser.name}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <SimpleSelect label={intl.formatMessage({id:"assigned"})} options={this.state.technicalUsersList} stateName="assignedUser" changeSelect={this.changeValues} selected={called.assignedUser} 
                            disabled />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    autoComplete="fname"
                                    name="title"
                                    variant="outlined"
                                    fullWidth
                                    id="title"
                                    label={intl.formatMessage({id:"title"})}
                                    value={called.title}
                                    onChange={this.handleInputChange}
                                    disabled
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 40 }}>
                                <ReactQuill name="description" value={called.description} style={{ height: 200 }}
                                    readOnly={true}
                                    modules={this.reactQuillModules}
                                    formats={this.reactQuillFormats}
                                    fullWidth
                                    required
                                    variant="outlined"
                                    id="outlined-multiline-static"
                                    disabled />									
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <SimpleSelect disabled label={intl.formatMessage({id:"type"})} options={this.state.typesList} stateName="type" changeSelect={this.changeValues} selected={called.type} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <SimpleSelect disabled label={intl.formatMessage({id:"menu.statuses"})} options={this.state.statusList} stateName="status" changeSelect={this.changeValues} selected={called.status} disabled/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <SimpleSelect disabled label={intl.formatMessage({ id: "location" })} options={this.state.organizationAddressesList} stateName="addressid" changeSelect={this.changeValues} selected={called.addressid} />
                        </Grid>
                    </Grid>
                </Fragment>
            )
        };

        const actionBody = () => {
            return (
                <Fragment>
                    {calleds.called && calleds.called.attachments && calleds.called.attachments.length > 0 && (
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <div className={classes.attachmentsBox}>
                                    <label>{<FormattedMessage id="attachments" />}</label>
                                    <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                                        <ul>
                                            {calleds.called.attachments.map(file => {
                                                return (<li key={file.path}>
                                                    <AttachFile /> <Button onClick={() => this.getCalledFile(file.path)}>{file.description}</Button>
                                                </li>)
                                            })}
                                        </ul>
                                    </ImageList>
                                </div>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <TextField
                                name="description"
                                autoComplete="fname"
                                label={intl.formatMessage({id:"action"})}
                                multiline
                                rows="8"
                                fullWidth
                                value={action.description}
                                variant="outlined"
                                onChange={(e) => this.handleInputChange(e, "action")}
                            />
							
                        </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                        <section style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}>
                            <div className={classes.dropzoneArea} style={{minHeight:'70px',marginTop:'5px '}}>
                                <Dropzone onDrop={acceptedFiles => { this.setState({ files: [ ...acceptedFiles, ...this.state.files ] }) }}>
                                    {({ getRootProps, getInputProps, acceptedFiles }) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <AttachFileIcon  />
                                            <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                                                <FormattedMessage  id="drag.and.drop.files" />
                                            </Typography>
                                        </div>
                                    )}
                                </Dropzone>
                                {this.state.files.map(file => {
                                    return (
                                        <div className={classes.dropzoneItem} key={file.name}>
                                            {file.name}
                                            <DeleteForever className={classes.deleteItem} onClick={() => { this.removeItem(file) }} />
                                        </div>
                                    )
                                })}
                            </div>
                        </section>
                    </Grid>
                    <Grid container spacing={0}>
                        {/*
                        
                        <Grid item xs={12} sm={8}>
                            {!this.isClient && (
                                <RadioGroup aria-label="privacy" name="privacy" value={action.privacy} onChange={(e) => this.handleInputChange(e, "action")} row>
                                    <FormControlLabel
                                        value="false"
                                        control={<Radio color="primary" />}
                                        label={intl.formatMessage({id:"public.action"})}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="true"
                                        control={<Radio />}
                                        label={intl.formatMessage({id:"private.action"})}
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            )}
                        </Grid>
                        */}
                        {/*
                        <Grid item xs={12} sm={4}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                style={{ float: 'right' }}
                                disabled={this.state.disablebutton} 
                            >
                                {<FormattedMessage id="add.action" />}
                            </Button>
                        </Grid>
                        */}
                        {!this.isClient && this.state.called.status != 5 &&  (
                            <Grid spacing={4} container> 
                                <Grid item xs={3} sm={3} />
                                <Grid item xs={3} sm={3} >
                                    <Button fullWidth id="btnAccept" variant="contained" color="primary" type="button" onClick={this.acceptRejection}> <FormattedMessage id="called.rejected.accept" /> </Button>        
                                </Grid>
                                <Grid item xs={3} sm={3} style={{textAlign: 'right'}}>
                                    <Button fullWidth id="btnDecline" variant="contained" color="primary" type="button" onClick={this.declineRejection} > <FormattedMessage id="called.rejected.decline" /> </Button>
                                </Grid>
                                <Grid item xs={3} sm={3} />
                            </Grid>
                        )}
                        {!this.isClient && this.state.called.status == 5 && 
                                <Grid spacing={4} container> 
                                    <Grid item xs={3} sm={3} />
                                    <Grid item xs={3} sm={3} />
                                    <Grid item xs={3} sm={3} />
                                    <Grid item xs={3} sm={3} >
                                    <Button fullWidth id="btnRedirect" variant="contained" color="primary" type="button" onClick={this.redirectToCalled}> <FormattedMessage id="Abrir chamado" /> </Button>        
                                    </Grid>  
                                </Grid>
                                 
                        }
                        

                    </Grid>
                    <div className={classes.actionItems}>
					  { (calleds.accompaniments && calleds.accompaniments.length > 0) &&
                        <ImageList cellHeight={60} className={classes2.ImageList} cols={1} >
                            {calleds.accompaniments.map((action, i) => {
                                if(this.isClient && action.privacy){

                                }else{
                                    return <AccompanimentAction key={i} action={action} showPrivacy={!this.isClient} attachments={action.accompanimentattachments} getFile={this.getAccompainmentFile} id={action.id} />
                                }
                            })}
                        </ImageList>
						}
				   </div>
                </Fragment>
            );
        };

        const headerContent = () => {
            return (
                <Fragment>
                    <h3>Nº {called.id}</h3>
                    <h2>{called.title}</h2>
                    <p>{<FormattedMessage id="open.call.via" />} {calleds.called.originrequest.name} {<FormattedMessage id="from" />} <b>{calleds.called.requestinguser.email}</b> {<FormattedMessage id="in" />} {moment(calleds.called.openingdate).format("DD/MM/YYYY HH:mm")}</p>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={4} ></Grid>

                        <Grid item xs={12} sm={12}>
                          <SimpleAutoComplete disabled label={intl.formatMessage({id:"category"})} options={this.state.categoriesList} stateName='OrganizationCategoryId' changeSelect={this.changeValues} selected={called.OrganizationCategoryId} 
                          />
                        </Grid>
                        <Grid item xs={12} sm={1} ></Grid>
                    </Grid>
                 </Fragment>
            );
        };

        return (
            <div className={classes.root}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    }, classes.actionsContent, {
                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component="main" maxWidth="md">
                        <CssBaseline />
                        <div>
                            <form name="myForm" className={classes.form} onSubmit={this.handleSubmit}>


                                {!this.isClient && (<div>
                                    <Grid container >

                                        <Grid item xs={12} sm={5}>
                                            <Grid item xs={12} sm={4} >
                                                <Button variant="outlined" color="primary"   onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={1} ></Grid>
                                        <Grid item xs={12} sm={12}>
                                            {headerContent()}
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} sm={12}>
                                            {calledBody()}
                                        </Grid>
                                        <Grid item xs={12} sm={1} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
                                            {actionBody()}
                                        </Grid>
                                </div>)}
                                {this.isClient && this.state.called.status != 5 && (<div className={classes.clientView}>
                                    <Grid container spacing={6}>
                                        <Grid item xs={4} sm={4}>
                                            <Button variant="outlined" color="primary"   onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                                        </Grid>
                                        <Grid item xs={4} sm={4}> </Grid>
                                        
                                        {!this.isClient && this.state.samePerson && this.state.called.status != 5 && (
                                            <Grid item xs={4} sm={4}>
                                                {this.state.FinishCalled ?  
                                                (<Button variant="outlined" color="primary" onClick={this.reopenCalledClient}>{<FormattedMessage id="reopen.called" />}</Button>) 
                                                    : 
                                                (<Button variant="outlined" color="primary" onClick={this.finishCalledClient}>{<FormattedMessage id="close.called" />}</Button>)}
                                            </Grid>)}
                                    </Grid>
                                    <Grid container className={classes.mb50}>
                                        {headerContent()}
                                    </Grid>
                                    <Grid container>
                                        {actionBody()}
                                    </Grid>

                                </div>)}


                            </form>
                        </div>

                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color="secondary" />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />


                    </Container>

                </main>
                <Footer />     
            </div>
        );
    }
}

CalledRegistration.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    calleds: state.calleds,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(calledsActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledRegistration)));
