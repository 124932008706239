import React, { Component } from "react"
import clsx from 'clsx'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from "prop-types"
import { CssBaseline, CircularProgress, Container, Button, Grid } from "@mui/material"
import { withStyles } from "@mui/styles"
import { Styles } from "../../styles/material-styles"
import { Overlay } from "../../styles/global"
import CustomizedSnackbars from "../../components/material-snackbars"
import HeaderMenu from "../../components/header-menu"
import MaterialTable from 'material-table'
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'
import MyMaterialTable from '../../components/table'
import Api from "../../services/api"
import fileDownload from 'js-file-download'
import { getAllSelectionModelWithColorHex } from "../../services/status.service";

import ApiReports from "../../services/apireport";

import * as CalledService from '../../services/called.service'
import * as OrganizationCategoryService from '../../services/organizationcategory.service'
import * as OrganizationFieldService from '../../services/organizationfield.service'
import * as calledsActions from '../../redux/actions/calleds'
import * as TeamsService from '../../services/techinicalteam.service'
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import Footer from '../../components/footer'
import moment from "moment"
import { FormattedMessage } from 'react-intl'
import SimpleCheckBox from "../../components/checkbox/check";
import { injectIntl } from 'react-intl'
import { getSlaAtendimento, getSlaSolucao } from '../called-list/called.functions'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import SimpleDatePicker from '../../components/date-pickers/inline-no-today'
import { saveAs } from 'file-saver'
import SimpleAutoComplete from '../../components/auto-complete/autocomplete'
import SimpleAutoCompleteMultiple from '../../components/auto-complete/autocomplete-multiple'
import SimpleAutoCompleteMultipleRequired from '../../components/auto-complete/autocomplete-multiple-required'

import SimpleAutoCompleteMultipleCategories from '../../components/auto-complete/autocomplete-multiple-create-categories'

import Pagination from '@mui/material/Pagination'
import Search from '@mui/icons-material/Search'
import TextField from '@mui/material/TextField'
import autoTable from 'jspdf-autotable'

import jsPDF from 'jspdf'
import 'jspdf-autotable'
import LogoMarca from '../../images/new-logo-cmtech.png'
import html2canvas from 'html2canvas'
import Divider from '@mui/material/Divider'
import * as ExcelJS from 'exceljs'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PapiroConsole } from "../../utils/papiroConsole"
import * as Service from '../../services/techinicalteamorganizationcategory.service';

class ReopenedCalledsReport extends Component {

  constructor(props) {
    super(props)

    this.isClient = true
    this.isAdm = false
    this.typingTimeout = null
    this.organizationId = null

    const { userSession } = this.props

    if (userSession == null || userSession.user == null || userSession.user.userrole == null)
      Api.logoff()

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3)
        this.isClient = false
      if (item.role.roletype == 0 || item.role.roletype == 1)
        this.isAdm = true
    });

    if (userSession.user.organization)
      this.organizationId = userSession.user.organization.id

    this.state = {
      dateStart: moment().startOf('week').format('YYYY/MM/DD HH:mm:ss'),
      dateEnd: moment().endOf('week').format('YYYY/MM/DD HH:mm:ss'),
      organizationId: this.organizationId,
      OrganizationFilter: 0,
      TechinicalTeamFilter: { id: 0, name: '' },
      location: 0,
      StatusFilter: 0,
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      showGrid: false,
      calledsList: [],
      statusList: [],
      organizationList: [],
      organizationAddressesList: [],
      calledPdfList: [],
      totalPages: 0,
      page: 1,
      pageStart: 0,
      pageEnd: 0,
      count: 0,
      searchValue: '',
      showHeader: false,
      strhtmlModalOpen: false,
      dialogHTMLString: '',
      OrgsList: [],
      TechinicalTeamList: [],
      CategoriesList: [],

      customFieldId: "",

      CategoryFilter: [],
      CustomFields: [],
      columns: [],
      columnsExcell: [],
      OrganizationCategories: [],
    }
  }

  openHTMLRenderModal = (htmlString) => {
    this.setState({ strhtmlModalOpen: !this.state.strhtmlModalOpen, dialogHTMLString: htmlString })
  }

  closeHTMLRenderModal = () => {
    this.setState({ strhtmlModalOpen: !this.state.strhtmlModalOpen, dialogHTMLString: '' })
  }



  changeValues = async (stateName, value, text = '') => {
    this.setState({ loading: true })

    if (stateName === 'OrganizationFilter') {
      this.setState({ [stateName]: value.id });
      await this.getTechinicalTeams(value.id)
      if (!this.state.TechinicalTeamList.find(t => t.id == this.state.TechinicalTeamFilter.id)) {
        this.setState({ TechinicalTeamFilter: { id: 0, name: '' } })
      }
    }
    else if (stateName == 'StatusFilter') {
      this.setState({ [stateName]: value.id });
    }
    else {
      this.setState({ [stateName]: value });
    }
    this.setState({ loading: false })
  };

  Reload = () => {
    const reload = localStorage.getItem('reload')
    if (reload == "true")
      localStorage.setItem('reload', false)
    return null;
  }

  onChangePaginate = (e, page) => {
    this.setState({ page: page })
    this.getCalleds('', page)
  }

  exportPdf = _ => {
    this.getCalledsToPdf()
  }

  async getCategoriesByOrganization(organizationId) {
    this.setState({ loading: true });
    PapiroConsole.log("entrei aqui na função")
    const result = await OrganizationCategoryService.getbyorganization(organizationId);

    if (result.success) {
      PapiroConsole.log(result)
      PapiroConsole.log("result custom aqui")

      this.setState({ loading: false, OrganizationCategories: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };



  setPagination = (count, page) => {
    let totalPage = Math.ceil(count / 10)
    this.setState({ totalPages: totalPage })
    if (count > 0) {
      if (page == 1) {
        if (count < 10)
          this.setState({ pageStart: 1, pageEnd: count })
        else
          this.setState({ pageStart: 1, pageEnd: 10 })
      } else {
        let pageStart = ((page - 1) * 10) + 1
        if (count >= page * 10)
          this.setState({ pageStart: pageStart, pageEnd: page * 10 })
        else
          this.setState({ pageStart: pageStart, pageEnd: count })
      }
    } else
      this.setState({ totalPages: 0, page: 1, count: 0 })
  }



  onFieldChange = event => {
    // Clears the previously set timer.
    clearTimeout(this.typingTimeout)
    // Reset the timer, to make the http call after 475MS (this.callSearch is a method which will call the search API. Don't forget to bind it in constructor.)
    this.typingTimeout = setTimeout(this.callSearch, 475);
    this.setState({ [event.target.name]: event.target.value, page: 1 })
  }

  callSearch = _ => {
    this.getCalleds('', this.state.page)
  }

  getOrganizations = async _ => {
    let data = [];
    const { userSession } = this.props
    if (userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
      data = userSession.user.userorganizations
    } else {
      await Api.get('/organizations/tech')
        .then(result => {
          data = result.data.data
        })
        .catch(err => {
          data = err
        });
    }
    this.setState({ OrgsList: data })
  }

  async getAllStatus() {
    let result = await getAllSelectionModelWithColorHex();
    if (result && result.success) {
      if (result.data != null)
        this.setState({ statusList: result.data })
    } else {
      const intl = this.props.intl
      this.setState({
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
    }
  }


  deleteItem = (data, resolve) => {
    const intl = this.props.intl;
    let abl = this.context.can('Delete', 'Called')
    if (!abl) {
      this.setState({ loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: this.props.intl.formatMessage({ id: "operation.notAllowed" }) })
      resolve()
      return false
    }
    Api.delete(`/logmail/${data.id}`)
      .then(result => {
        if (result.data.success) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "called.list.notification.delete.success" }),
            resultList: []
          })

          this.getCalleds()
        } else {
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          })
          Api.kickoff(result)
        }
        resolve()
      })
      .catch(err => {
        resolve()
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(err)
      })
  }

  async componentDidMount() {
    this.setState({ loading: true })
    await this.getOrganizations()
    await this.getAllStatus()
    await this.getTechinicalTeams()


    this.setState({ loading: false })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "techinicalteamsreport")
      this.getCalleds(this.props.location.state)
    else if (nextProps.location.state === "assignedcalleds")
      this.getCalleds(nextProps.location.state)
    else if (nextProps.location.state === "reload")
      this.componentDidMount()
  }

  getFile = async () => {
    /*this.setState({ loading: true })
        const intl = this.props.intl
        const wb = new ExcelJS.Workbook()

        const ws = wb.addWorksheet()

        await this.getColumnsExcell(this.state.calledsList)

        
   
  
        ws.columns = this.state.columnsExcell

        ws.getCell('A1').style = { font: { bold: true } }
        ws.getCell('B1').style = { font: { bold: true } }
        ws.getCell('C1').style = { font: { bold: true } }
        ws.getCell('D1').style = { font: { bold: true } }
        ws.getCell('E1').style = { font: { bold: true } }
        ws.getCell('F1').style = { font: { bold: true } }
      
        //var count = (this.state.columnsExcell.length - 14);
        
        
        ws.addRows(this.state.calledsList);

        ws.addRow([]);
        const newRowValues = [intl.formatMessage({ id: "export.date" }), moment().format('DD/MM/YYYY HH:mm:ss') ];
        const newRow = ws.addRow(newRowValues);

        const buf = await wb.xlsx.writeBuffer()

        saveAs(new Blob([buf]), 'relatorio_chamadosxorganizacaosintetico.xlsx')
        this.setState({ loading: false })*/
    const intl = this.props.intl;

    this.setState({ loading: true });



    var dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
    var dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')

    if (dateStart > dateEnd) {
      const { intl } = this.props
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "report.audit.initial.date.higher.than.end.date" }),
        loading: false
      });
    }
    else {
      let url = `/report/reopenedcalleds/excell/?org=${this.state.OrganizationFilter}&techteam=${this.state.TechinicalTeamFilter.id}&status=${this.state.StatusFilter}&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}`

      ApiReports.get(url, {
        responseType: 'blob'
      })
        .then((response) => {

          //   let url = window.URL.createObjectURL(new Blob([response.data]));
          //saveAs(url, 'called_list.csv');

          fileDownload(response.data, `reopenedcalleds_${moment(this.state.dateStart).format('YYYY-MM-DD')}_${moment(this.state.dateEnd).format('YYYY-MM-DD')}.zip`);

          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    }




  }

  getMessageBadUseFlag = message => {
    const intl = this.props.intl;
    let writing = ""
    if (message === true)
      writing = intl.formatMessage({ id: "yes" })
    else if (message === false)
      writing = intl.formatMessage({ id: "no" })
    else
      writing = intl.formatMessage({ id: "undefined" })

    return writing
  }

  getColumns(data) {


    const intl = this.props.intl;
    var columns =
      [


        { title: intl.formatMessage({ id: "id" }), field: 'called_id' },
        { title: intl.formatMessage({ id: "reopening.date" }), field: 'reopening_date' },
        { title: intl.formatMessage({ id: "called.list.data.edit.called.title" }), field: 'called_title' },
        { title: intl.formatMessage({ id: "user.who.reopening" }), field: 'user_reopened' },
        { title: intl.formatMessage({ id: "modalRating.called.user" }), field: 'assigned_user' },
        { title: intl.formatMessage({ id: "team" }), field: 'techinical_team' },
        { title: intl.formatMessage({ id: "category" }), field: 'organization_category' },
        { title: intl.formatMessage({ id: "organization" }), field: 'organization' },
        { title: intl.formatMessage({ id: "current.status" }), field: 'current_status' },
        { title: intl.formatMessage({ id: "previous.status.before.reopening" }), field: 'previous_status' },


      ];

    this.setState({ columns: columns })

  }


  async getColumnsExcell(data) {


    const intl = this.props.intl;

    var columns = [


      { header: intl.formatMessage({ id: "organization" }), key: 'organizacao', width: 25 },
      { header: intl.formatMessage({ id: "total.time.seconds" }), key: 'total_segundos', width: 25 },
      { header: intl.formatMessage({ id: "total.time.minutes" }), key: 'total_minutos', width: 25 },
      { header: intl.formatMessage({ id: "total.time.hours" }), key: 'total_horas', width: 25 },
      { header: intl.formatMessage({ id: "total.time" }), key: 'total_time', width: 25 },
      { header: intl.formatMessage({ id: "called.mounth" }), key: 'total_chamados', width: 25 },

    ];



    await this.setState({ columnsExcell: columns })

  }




  getCalleds = async (filter = '', page = 1) => {

    const intl = this.props.intl;

    this.setState({ loading: true });

    PapiroConsole.log(this.state)
    PapiroConsole.log("this.state")

    var dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
    var dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')

    if (dateStart > dateEnd) {
      const { intl } = this.props
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "report.audit.initial.date.higher.than.end.date" }),
        loading: false
      });
    }
    else {
      let url = `/report/reopenedcalleds/paginate/?page=${page}&org=${this.state.OrganizationFilter}&techteam=${this.state.TechinicalTeamFilter.id}&status=${this.state.StatusFilter}&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}`

      this.setState({ showGrid: true })

      var writing = ""

      ApiReports.get(url)
        .then(result => {
          if (result.data.success) {

            let count = result.data.count;
            this.setState({ count: count })
            this.setPagination(count, page)
            PapiroConsole.log("result aqui formatted")
            PapiroConsole.log(result.data)


            const formattedList = result.data.data.map(item => {
              return {
                ...item,

              }
            })



            this.setState({ calledsList: formattedList, loading: false })

            this.getColumns(formattedList)

          } else {
            this.setState({
              loading: false, openNotification: true, notificationVariant: 'error',
              notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
            })
            Api.kickoff(result)
          }
        })
        .catch(err => {

          this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          })
          Api.kickoff(err)
        })

    }


  }

  getMessageExcellBadUseFlag = (message, status) => {
    const intl = this.props.intl;
    let writing = ""
    if (status === intl.formatMessage({ id: "finished" })) {
      if (message === true)
        writing = intl.formatMessage({ id: "yes" })
      else if (message === false)
        writing = intl.formatMessage({ id: "no" })
    } else
      writing = intl.formatMessage({ id: "undefined" })

    return writing
  }


  getCalledsToPdf = (filter = '') => {
    const intl = this.props.intl;
    this.setState({ loading: true })

    let url = `/techinicalteams/getexcell?org=${this.state.OrganizationFilter}}&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}`

    Api.get(url)
      .then(async (result) => {
        if (result.data.success) {
          PapiroConsole.log(result)
          PapiroConsole.log("result")
          await this.setState({ showHeader: true })
          const formattedList = result.data.data.map(item => {

            return {
              ...item,
              Name: item.name,
              TotalUsers: item.total_users,
              Organizacoes: item.organizacoes,
            }
          })

          const intl = this.props.intl
          const doc = new jsPDF("p", "mm", "a4")
          const header = document.getElementById('header-pdf')
          let headerCanvas = await html2canvas(header)
          const headerImg = headerCanvas.toDataURL('image/png')
          doc.addImage(headerImg, 'PNG', 0, 0)

          doc.autoTable({
            body: formattedList,
            margin: { top: 30 },
            bodyStyles: { valign: 'middle' },
            styles: { overflow: 'linebreak', columnWidth: 'wrap', fontSize: 7, halign: 'center' },
            theme: 'striped',
            columns: [
              { header: intl.formatMessage({ id: "name" }), dataKey: 'Name' },
              { header: intl.formatMessage({ id: "total.users" }), dataKey: 'TotalUsers' },
              { header: intl.formatMessage({ id: "menu.organizations" }), dataKey: 'Organizacoes' },


            ],
            columnStyles: {
              Name: {
                columnWidth: 30
              },
              TotalUsers: {
                columnWidth: 30
              },
              Organizacoes: {
                columnWidth: 30
              },

            }
          })

          doc.save("relatorio-times-enviados.pdf")
          this.setState({ loading: false, showHeader: false })
        } else {
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          })
          Api.kickoff(result)
        }
      })
      .catch(err => {
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(err)
      })
  }

  handleSubmit = async e => {
    const intl = this.props.intl;
    e.preventDefault();
    this.setState({ loading: true });
    await this.getCalleds(this.props.location.state)



  }


  closeNotification = _ => {
    this.setState({ openNotification: false });
  }


  async getTechinicalTeams(OrganizationId = 0) {
    var result = null
    if (OrganizationId > 0) {
      result = await Service.getTechinicalTeamsByOrganization(OrganizationId);
    } else {
      result = await Service.getTechinicalTeams()
    }
    if (result.success) {
      this.setState({ TechinicalTeamList: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props
    const intl = this.props.intl

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    const tableList = () => {
      return (
        <>

          <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
            <Accordion >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>{intl.formatMessage({ id: "filters" })}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container style={{ marginBottom: 20 }} spacing={2}>



                  <Grid item xs={12} sm={6} md={4}  >
                    <SimpleDatePicker label={intl.formatMessage({ id: "start.date" })} name='dateStart' stateName='dateStart' changeDate={this.changeValues} currentDate={this.state.dateStart} fullWidth={true} required />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} >
                    <SimpleDatePicker label={intl.formatMessage({ id: "end.date" })} name='dateEnd' stateName='dateEnd' changeDate={this.changeValues} currentDate={this.state.dateEnd} fullWidth={true} required />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3} style={{ marginRight: 5 }}>
                    <SimpleAutoComplete label={intl.formatMessage({ id: "organization" })} options={this.state.OrgsList} stateName='OrganizationFilter' changeSelect={this.changeValues} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} style={{ marginRight: 5 }}>
                    <SimpleAutoComplete label={intl.formatMessage({ id: "productivity.dashboard.techinical.teams" })} options={this.state.TechinicalTeamList} stateName='TechinicalTeamFilter' selected={this.state.TechinicalTeamFilter} changeSelect={this.changeValues} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <SimpleAutoComplete label={intl.formatMessage({ id: "menu.statuses" })} options={this.state.statusList} stateName='StatusFilter' changeSelect={this.changeValues} />
                  </Grid>



                </Grid>
              </AccordionDetails>
            </Accordion>


            <Grid container style={{ marginBottom: 10 }}>
              <Grid item xs={12} sm={2} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='secondary' >
                  {<FormattedMessage id="filter" />}
                </Button>
              </Grid>

              {this.state.showGrid && <Grid item xs={12} sm={5} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                <Button
                  fullWidth
                  variant='contained'
                  color='secondary'
                  onClick={this.getFile} >
                  {<FormattedMessage id="export.plan" />}
                </Button>
              </Grid>}

              {5 == 2 && this.state.showGrid && <Grid item xs={12} sm={5} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                <Button
                  fullWidth
                  variant='contained'
                  color='secondary'
                  onClick={this.getCalledsToPdf} >
                  {<FormattedMessage id="dash.pdf" />}
                </Button>
              </Grid>}
            </Grid>
          </form>

          {
            this.state.showGrid && <>
              <MyMaterialTable
                title=""
                columns={
                  this.state.columns
                }
                data={this.state.calledsList} />

              <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                <Grid item xs={12} md={3}>
                  <Pagination count={this.state.totalPages} page={this.state.page} onChange={this.onChangePaginate} />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                  {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                </Grid>
              </Grid>


            </>
          }
        </>
      );
    }

    return (
      <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })} >
          <div className={classes.drawerHeader} />
          <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft: 0, marginLeft: 0 }}>
            <CssBaseline />


            <Grid container spacing={6}>
              <Grid item xs={12} sm={12}>
                <Card >
                  <CardContent className="background-title-search">
                    <Typography variant="h5">
                      {<FormattedMessage id="reopened.calleds.report" />}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>


            {tableList()}


            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification} />
          </Container>
        </main>
        <Footer />
      </div>
    )
  }
}

ReopenedCalledsReport.propTypes = {
  classes: PropTypes.object.isRequired
}


const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(calledsActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ReopenedCalledsReport)))
ReopenedCalledsReport.contextType = AbilityContext