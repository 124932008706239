
import React, { Component } from "react"
import clsx from 'clsx'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from "prop-types"
import { CssBaseline, CircularProgress, Container, Button, Grid } from "@mui/material"
import { default as MaterialTooltip, tooltipClasses } from '@mui/material/Tooltip'
import { withStyles, styled } from "@mui/styles"
import { Styles } from "../../styles/material-styles"
import { Overlay } from "../../styles/global"
import CustomizedSnackbars from "../../components/material-snackbars"
import { getOrganizationsByRole } from "../../services/called.service";
import Budget from './components/budget';
import ApiReports from "../../services/apireport";

import HeaderMenu from "../../components/header-menu"
import Api from "../../services/api"
import * as CalledService from '../../services/called.service'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as calledsActions from '../../redux/actions/calleds'
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import Footer from '../../components/footer'
import moment from "moment"
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import * as Service from "../../services/user.service";
import SimpleDatePicker from '../../components/date-pickers/inline-no-today';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import CategoryIcon from '@mui/icons-material/Category';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  PieChart, Pie, Sector, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Text
} from 'recharts';

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

import SimpleAutoComplete from '../../components/auto-complete/autocomplete'
import GroupsTab from "./tabs/groupstab"
import OrganizationsTab from "./tabs/organizationstab"
import DepartamentsTab from "./tabs/departamentstab"
import CategoryTab from "./tabs/categorytab"
import * as TeamsService from '../../services/techinicalteamorganizationcategory.service';



class DashboardReport extends Component {

  constructor(props) {
    super(props)

    const intl = this.props.intl;

    this.isClient = true
    this.isAdm = false
    this.typingTimeout = null
    this.organizationId = null
    this.SlaGraphicContent = null
    this.GraphicWithTabs = null
    this.materialGraphic = null
    this.attendanceHours = null
    this.materialArray = null
    this.valueOfAttendance = null
    this.StatusList = [
      { id: 0, name: intl.formatMessage({id:"all"}) },
      { id: 1, name: intl.formatMessage({id:"dashboard.report.new"}) },
      { id: 2, name: intl.formatMessage({id:"dashboard.report.in.progress"}) },
      { id: 4, name: intl.formatMessage({id:"dashboard.report.finished"}) },
      { id: 9, name: intl.formatMessage({id:"dashboard.report.scheduled"}) }
    ]



    const { userSession } = this.props

    if (userSession == null ||  userSession.user == null || userSession.user.userrole == null)
      Api.logoff()

    userSession.user.userrole.map((item, key) => {

      if (item.role.roletype != 3)
        this.isClient = false
      if (item.role.roletype == 0 || item.role.roletype == 1)
        this.isAdm = true
    });

    /*if(userSession.user.organization)
      this.organizationId = userSession.user.organization.id
        */
    this.state = {
      dateStart: moment().format('YYYY/MM/DD HH:mm:ss'),
      dateEnd: moment().format('YYYY/MM/DD HH:mm:ss'),
      showStatistics: false,
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      calledsList: [],
      totalCalled: 0,
      comparation: 0,
      positiveMonth: true,
      OrganizationSelected: 0,
      DepartamentSelected: 0,
      CategorySelected: 0,
      StatusSelected: { id: 0, name: intl.formatMessage({id:"all"}) },
      GroupIdSelected: 0,
      OrganizationByRole: [],
      OrganizationIdSelected: 0,
      TechinicalTeamFilter: { id: 0, name: '' },
      DepartamentIdSelected: 0,
      CategoryIdSelected: 0,
      StatusIdSelected: 0,
      GroupList: [],
      filtered: false,
      OrganizationList: [],
      TechinicalTeamList: [],
      CalledsMaterials: [],
      organizationDepartamentsList: [],
      CategoryList: [],
      SlaCalleds: [],
      filterCalleds: [],
      totalCalleds: [],
      renderGraphic: false

    }

    this.changeValues = this.changeValues.bind(this);
    this.generateDetailsTooltipTotalValue = this.generateDetailsTooltipTotalValue.bind(this)
    this.getCostReportTotal = this.getCostReportTotal.bind(this)
  }

  async componentDidMount() {

    await this.loadItems();

  }




  async loadItems(organizationId = '') {


    //await this.getCalledTotal(organizationId);


    await this.getAllGrous();
    await this.getAllOrganizationsByRole();
    await this.getAllCalledMaterials();

    //await this.FilterSlaCalled();


  }

  async changeValues(stateName, value) {

    var Value = [value]

    console.log(value)

    this.setState({ loading: true });

    if (stateName == "GroupFilter") {

      this.setState({ ["GroupIdSelected"]: value.id, OrganizationIdSelected: 0, OrganizationSelected: 0, DepartamentSelected: 0, CategorySelected: 0 });
      await this.getAllOrganizations(Value);

    }
    else if (stateName == 'OrganizationFilter') {
      this.setState({ ["OrganizationIdSelected"]: value.id, DepartamentSelected: 0, CategorySelected: 0, OrganizationSelected: value });
      await this.getOrganizationDepartaments(value.id);
      await this.getOrganizationCategories(value.id);
      await this.getTechinicalTeams(value.id)
      if (!this.state.TechinicalTeamList.find(t => t.id == this.state.TechinicalTeamFilter.id)) {
        this.setState({ TechinicalTeamFilter: { id: 0, name: '' } })
      }
    }

    else if (stateName == 'DepartamentFilter') {
      this.setState({ ["DepartamentIdSelected"]: value.id });
      //await this.getOrganizationDepartaments(value.id);

    }

    else if (stateName == 'CategoryFilter') {
      this.setState({ ["CategoryIdSelected"]: value.id });
      //await this.getOrganizationDepartaments(value.id);

    }

    else if (stateName == 'StatusFilter') {
      this.setState({ ["StatusIdSelected"]: value.id });
      //await this.getOrganizationDepartaments(value.id);

    }
    else {


      this.setState({ [stateName]: value });

    }
    this.setState({ loading: false });






  };


  customizedAxisTick = ({
    x, y, payload,
  }) => {

    return <Text x={x} y={y} width={70} style={{ fill: '#808080', fontSize: '11px', }} textAnchor="middle" verticalAnchor="start">{payload.value}</Text>
  };


  async getOrganizationDepartaments(organizationId) {
    this.setState({ loading: true });



    var organizationDepartamentsList = null

    const result = await CalledService.GetDepartamentsByOrgAndUser(organizationId);



    if (result.success) {



      if (result.data) {

        organizationDepartamentsList = result.data
        this.setState({ loading: false, organizationDepartamentsList: organizationDepartamentsList });
      }
      else {
        organizationDepartamentsList = []
      }




    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };

  async getOrganizationCategories(organizationId) {
    this.setState({ loading: true });



    var categoryList = null

    const result = await Service.getOrganizationsCategoriesByOrganizationId(organizationId);





    if (result.success) {



      if (result.data) {


        categoryList = result.data

      }
      else {
        categoryList = []
      }




    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }


    this.setState({ loading: false, CategoryList: result.data });
  };

  renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  async getCalledTotal(organizationId = '') {

    this.setState({ loading: true });
    let url = `/report/dashboard/paginate`;
    if (organizationId != null || organizationId != '' || organizationId != 0) {
      url += "/" + organizationId;
    }
    url += `?&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&group=${this.state.GroupIdSelected}&orgId=${this.state.OrganizationIdSelected}&techteam=${this.state.TechinicalTeamFilter.id}&depId=${this.state.DepartamentIdSelected}&catgId=${this.state.CategoryIdSelected}&statusId=${this.state.StatusIdSelected} `;
    this.setState({ showStatistics: true });

    ApiReports.get(url)
      .then(result => {
        if (result.data.success) {
          console.log(result.data)
          console.log("CountSLASolutionOverflow")
          this.setState({
            loading: false,
            filterCalleds: result.data.data.calledsresponse,
            totalCalleds: result.data.data,
            TotalKilometersDisplacement: !isNaN(result.data.data.totalkilometersdisplacement) ? result.data.data.totalkilometersdisplacement.toFixed(2) : result.data.data.totalkilometersdisplacement,
            TotalHoursDisplacement: result.data.data.totalhoursdisplacement,
            TotalHoursInloco: result.data.data.totalhoursinloco,
            TotalHoursRemote: result.data.data.totalhoursremote,
            TotalValueCost: result.data.data.totalvaluecost,
            TotalHoursGeral: result.data.data.totalhoursgeral,
            CountSLASolutionOverflow: result.data.data.countslasolutionoverflow,
            CountSLASolutionNotOverflow: result.data.data.countslasolutionnotoverflow,
            CountSLAServiceOverflow: result.data.data.countslaserviceoverflow,
            CountSLAServiceNotOverflow: result.data.data.countslaservicenotoverflow,
            filtered: true
          });

          this.FilterSlaCalled(organizationId)
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
      });
  }




  async getAllOrganizations(ids) {


    var orgs = null

    if (ids.length > 0) {

      console.log("entrei aqui")
      this.setState({ loading: true });
      let urlids = ids.map((item) => item.id);

      var result = await Service.getAllOrganizationsByGroup(urlids);

      console.log("result aqui")
      console.log(result)

      if (result.success) {

        orgs = result.data.map((org) => {

          return {
            ...org,
            name: `( ${org && org.group ? org.group.name : ""} ) ${org ? org.name : ""}`,
          };
        });

      } else {

        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            result.response && result.response.data && result.response.data.errors
              ? result.response.data.errors[0]
              : intl.formatMessage({ id: "process.error" }),
        });
        Api.kickoff(result);
      }

      this.setState({ loading: false, OrganizationList: orgs });

    }
  }



  async getAllCalledMaterials() {
    this.setState({ loading: true });

    var urlids = null
    if (this.state.OrganizationByRole.length > 0) {
      urlids = this.state.OrganizationByRole.map((item) => item.id);

      var result = await Service.getAllCalledMaterials(urlids);

      console.log(result)

      console.log("linha 410 aqui")

      if (result.success) {

        if (result.data != null) {

          var array = []

          //pegar os materiais dos chamados que houve troca de materiais
          result.data.forEach(element => {

            element.organizationmaterial.quantidade = 0
            //element.organizationmaterial.color = this.gerar_cor_hexadecimal()


            //armazeno num array os materiais por si proprio
            array.push(element.organizationmaterial)
          });




          var newarray = [...array]

          //remover os elementos repetidos caso ocorram 
          newarray.forEach((element, index) => {

            var i = 0

            array.forEach((element2, index2) => {

              //if(element.name == element2.name){

              if (element.name == element2.name && element2.organizationid == element.organizationid) {

                i++

                if (i >= 2) {
                  array.splice(index2, 1);
                }




              }


            });


          });

          console.log(array)
          console.log("resultado matertiais aqui")

          this.setState({ loading: false, CalledsMaterials: array });


        }


      } else {

        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            result.response && result.response.data && result.response.data.errors
              ? result.response.data.errors[0]
              : intl.formatMessage({ id: "process.error" }),
        });
        Api.kickoff(result);
      }


    }




  }

  async getTechinicalTeams(OrganizationId = 0) {
    var result = null
    if (OrganizationId > 0) {
      result = await TeamsService.getTechinicalTeamsByOrganization(OrganizationId);
    } else {
      result = await TeamsService.getTechinicalTeams()
    }
    if (result.success) {
      this.setState({ TechinicalTeamList: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  async getAllOrganizationsByRole() {
    this.setState({ loading: true });
    //var groups = await Service.getOrganizations();
    const { userSession } = this.props
    let orgData = []
    if(userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
      orgData = userSession.user.userorganizations
    } else {
      var result = await getOrganizationsByRole()
      if (result.success) {
        orgData = result.data
      } else {

        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            result.response && result.response.data && result.response.data.errors
              ? result.response.data.errors[0]
              : intl.formatMessage({ id: "process.error" }),
        });
        Api.kickoff(result);
      }
    }

    if(orgData != null) {
      this.setState({ loading: false, OrganizationByRole: orgData });
    }
  }

  getPercent(value, arraytotal) {

    var total = 0
    arraytotal.forEach(element => {
      total += element.value
    });

    if (total == 0) {
      return 0
    }


    return ((value * 100) / total).toFixed(2);

  }


  async getAllGrous() {
    this.setState({ loading: true });
    var result = await Service.getUsersGroups();
    if (result.success) {
      this.setState({ loading: false, GroupList: result.data });
    } else {

      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }

    this.setState({ loading: false });


  }
  gerar_cor_hexadecimal() {
    return '#' + parseInt((Math.random() * 0xFFFFFF))
      .toString(16)
      .padStart(6, '0');
  }


  convertTohoursFormat(minutos) {
    const horas = Math.floor(minutos / 60);
    const min = Math.round(minutos % 60)
    let textoHoras = (`00${horas}`).slice(-2);
    let textoMinutos = (`00${min}`).slice(-2);
    if (horas >= 100) {
      textoHoras = (`00${horas}`).slice(-3);
      let tamanhoHoras = horas.toString().length * -1
      textoHoras = (`00${horas}`).slice(tamanhoHoras);
    }
    return `${textoHoras}:${textoMinutos}`;
  }
  async FilterSlaCalled(organizationId = '') {

    await this.getCostReportTotal(organizationId)

    var SlaCalleds = this.state.filterCalleds;
    const { classes } = this.props
    this.setState({ loading: true, renderGraphic: false, SlaCalleds: SlaCalleds });
    //Graficos
    this.showGraphicWithTabs();
    this.showMaterialGraphic();
    this.showSlaGraphic();
    //Budgets
    this.showBudgets()
    this.setState({ loading: false, renderGraphic: true });
  }

  showGraphicWithTabs() {

    //gráfico com tabs, grupo, organização, departamento e categorias
    this.GraphicWithTabs =
      <Tabs >
        <TabList style={{ borderColor: '#BB8FCE' }} >


          <Tab style={{ fontSize: "14px" }}>< AccountBalanceWalletIcon /> {<FormattedMessage id="menu.groups" />} </Tab>
          <Tab style={{ fontSize: "14px" }}><AccountBalanceIcon /> {<FormattedMessage id="menu.organizations" />}</Tab>
          <Tab style={{ fontSize: "14px" }}><GroupWorkIcon /> {<FormattedMessage id="menu.organizationdepartaments" />}</Tab>
          <Tab style={{ fontSize: "14px" }}><CategoryIcon /> {<FormattedMessage id="menu.categories" />}</Tab>

        </TabList>

        <TabPanel>

          <GroupsTab slacalleds={this.state.totalCalleds} grouplist={this.state.GroupList} />

        </TabPanel>


        <TabPanel>

          <OrganizationsTab slacalleds={this.state.totalCalleds} organizationbyrole={this.state.OrganizationByRole} />

        </TabPanel>

        <TabPanel>

          <DepartamentsTab slacalleds={this.state.totalCalleds} OrganizationByRole={this.state.OrganizationByRole} />

        </TabPanel>

        <TabPanel>

          <CategoryTab slacalleds={this.state.totalCalleds} />

        </TabPanel>


      </Tabs>

  }

  showMaterialGraphic() {

    const { classes } = this.props
    var arrayOfMaterials = this.state.totalCalleds.materialresponse
    var arrayTable = null

    const intl = this.props.intl;

    if (arrayOfMaterials.length > 10) {
      // arrayTable = arrayOfMaterials.slice(0, 9)
      arrayTable = [...arrayOfMaterials]
    }
    else {
      arrayTable = [...arrayOfMaterials]
    }

    //gráfico de materiais
    this.materialGraphic =

      <div>
        <ResponsiveContainer width="95%" height={400}>

          <BarChart
            data={arrayTable}
            margin={{ bottom: 80 }}
            barSize={20}
          >
            <XAxis dataKey="name" tick={<this.customizedAxisTick />} padding={{ left: 10, right: 10 }} interval={0} />
            <YAxis allowDecimals={false} />
            <Tooltip />
            <Legend />
            <CartesianGrid strokeDasharray="3 3" />
            <Bar dataKey="quantidade" fill="#8884d8" background={{ fill: '#eee' }} />
          </BarChart>


        </ResponsiveContainer>
        <Accordion style={{ pointerEvents: "none" }}>
          <AccordionSummary style={{ pointerEvents: "none" }} >
            <Typography className={classes.heading}>Quantidade de materiais substituidos</Typography>
          </AccordionSummary>

        </Accordion>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table" >
            <caption style={{ textAlign: 'right' }}><b>{intl.formatMessage({id:"total.value"})} : {this.state.totalCalleds.totalvalueofallmaterials.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </b> </caption>
            <TableBody style={{ border: 1, borderColor: 'black', borderStyle: 'solid' }}>
              <TableRow>

                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', backgroundColor: '#8884d8', borderStyle: 'solid', color: 'white' }}>{intl.formatMessage({id:"material.name"})}</TableCell>
                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"organization"})}</TableCell>
                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"description"})}</TableCell>
                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"value"})}</TableCell>
                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"amount.x.valor"})}</TableCell>

              </TableRow>
              {
                arrayOfMaterials.map((entry, index) => (
                  <TableRow>

                    <TableCell align="center" style={{ backgroundColor: '#f2f2f2', border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{entry.name}</TableCell>
                    <TableCell align="center" style={{ backgroundColor: '#f2f2f2', border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{entry.organizationname} </TableCell>
                    <TableCell align="center" style={{ backgroundColor: '#f2f2f2', border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{entry.quantidade}</TableCell>
                    <TableCell align="center" style={{ backgroundColor: '#f2f2f2', border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{(entry.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                    <TableCell align="center" style={{ backgroundColor: '#f2f2f2', border: 1, borderColor: 'darkgrey', borderStyle: 'solid' }}>{(entry.totalvalueofmaterial).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>

                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>

  }

  showSlaGraphic() {
    const intl = this.props.intl;
    const { classes } = this.props
    const array = [
      {
        "name": intl.formatMessage({id:"dashboard.report.solution.sla.limit.reached"}),
        "value": this.state.CountSLASolutionOverflow,
        "color": "#FC2361"
      },
      {
        "name": intl.formatMessage({id:"dashboard.report.solution.sla.present"}),
        "value": this.state.CountSLASolutionNotOverflow,
        "color": "#8884d8"
      },
    ]

    const arrayService = [
      {
        "name": intl.formatMessage({id:"dashboard.report.attendance.sla.limit.reached"}),
        "value": this.state.CountSLAServiceOverflow,
        "color": "#FC2361"
      },
      {
        "name": intl.formatMessage({id:"dashboard.report.attendance.sla.present"}),
        "value": this.state.CountSLAServiceNotOverflow,
        "color": "#8884d8"
      }
    ]

    this.SlaGraphicContent =
      <div style={{ marginTop: '49px' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <PieChart width={300} height={250}>
            <Pie data={array} labelLine={false} label={this.renderCustomizedLabel} outerRadius={100} fill="#8884d8" dataKey="value"
            >
              {
                array.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))
              }
            </Pie>
            <Tooltip />
          </PieChart>

          <PieChart width={300} height={250}>
            <Pie data={arrayService} labelLine={false} label={this.renderCustomizedLabel} outerRadius={100} fill="#8884d8" dataKey="value"
            >
              {
                arrayService.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))
              }
            </Pie>
            <Tooltip />
          </PieChart>

        </div>


        <Accordion style={{ pointerEvents: "none" }}>
          <AccordionSummary style={{ pointerEvents: "none" }} >
            <Typography className={classes.heading}>Chamados por SLA</Typography>
          </AccordionSummary>

        </Accordion>
        <TableContainer component={Paper} >
          <Table size="small" aria-label="a dense table" >
            <TableBody style={{ border: 1, borderColor: 'black', borderStyle: 'solid' }}>
              <TableRow>
                <TableCell component="th" scope="row" style={{ backgroundColor: '#8884d8', color: 'white' }}>
                  Chamados
                </TableCell>
                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"name"})}</TableCell>
                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"amount.calleds"})}</TableCell>
                <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"percentage"})}</TableCell>
              </TableRow>
              {
                array.map((entry, index) => (
                  <TableRow>
                    <TableCell component="th" scope="row" style={{ backgroundColor: `${entry.color}` }}>
                    </TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{entry.name}</TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{entry.value}</TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{this.getPercent(entry.value, array)}%</TableCell>
                  </TableRow>
                ))
              }
              {
                arrayService.map((entry, index) => (
                  <TableRow>
                    <TableCell component="th" scope="row" style={{ backgroundColor: `${entry.color}` }}>
                    </TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{entry.name}</TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{entry.value}</TableCell>
                    <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{this.getPercent(entry.value, array)}%</TableCell>
                  </TableRow>
                ))
              }


            </TableBody>
          </Table>
        </TableContainer>
      </div>

  }

  showBudgets() {

    this.TotalKilometersDisplacement =

      <div>


        <Budget titleBudget="total.km.covered" total={this.state.TotalKilometersDisplacement} />

      </div>

    this.displacementHours =

      <div>


        <Budget titleBudget="displacement.hours" total={this.convertTohoursFormat(this.state.TotalHoursDisplacement)} />

      </div>

    this.attendanceHours =

      <div>


        <Budget titleBudget="inloco.attendance" total={this.convertTohoursFormat(this.state.TotalHoursInloco)} />

      </div>

    this.callSolutionHours =

      <div>


        <Budget titleBudget="call.solution.hours" total={this.convertTohoursFormat(this.state.TotalHoursRemote)} />

      </div>


    this.TotalGeral =

      <div>


        <Budget titleBudget="total.hours" total={this.convertTohoursFormat(this.state.TotalHoursGeral)} />

      </div>


    this.TotalValue =

      <div>


        <Budget
          detailsLink
          detailsLinkFunction={this.valueOfAttendance}
          titleBudget="total.value"
          total={(this.state.TotalValueCost).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} />

      </div>


  }

  getCostReportTotal = async (organizationId = '') => {
    const intl = this.props.intl;
    this.setState({ loading: true });

    let url = `/dashboard/costReports`;

    var dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
    var dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')


    if (dateStart > dateEnd) {

      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"report.audit.initial.date.higher.than.end.date"}),
        loading: false
      });
    }
    else {

      if (organizationId != null || organizationId != '' || organizationId != 0) {
        url += "/" + organizationId;
      }

      url += `?&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&group=${this.state.GroupIdSelected}&orgId=${this.state.OrganizationIdSelected}&statusId=${this.state.StatusIdSelected} `;

      this.setState({ showStatistics: true });

      await Api.get(url)
        .then(result => {
          if (result.data.success) {
            this.setState({
              loading: false,
              TotalHoursInloco: result.data.data.totalhoursinloco,
              TotalHoursRemote: result.data.data.totalhoursremote,
              TotalHoursDisplacement: result.data.data.totalhoursdisplacement,
              TotalValueCostDisplacement: result.data.data.totalvaluecostdisplacement,
              TotalValueCostInloco: result.data.data.totalvaluecostinloco,
              TotalValueCostHybrid: result.data.data.totalvaluecosthybrid,
              TotalValueCostRemote: result.data.data.totalvaluecostremote,
              TotalRemoteCalleds: result.data.data.totalremotecalleds,
              TotalInlocoCalleds: result.data.data.totalinlococalleds,
              TotalHybridCalleds: result.data.data.totalhybridcalleds,
              SumAllTotalRemoteCalleds: result.data.data.sumalltotalvaluecostremote,
              SumAllTotalInlocoCalleds: result.data.data.sumalltotalvaluecostinloco,
              SumAllTotalHybridCalleds: result.data.data.sumalltotalvaluecosthybrid,
              TotalValueReplacedMaterialsRemote: result.data.data.totalvaluereplacedmaterialsremote,
              TotalValueReplacedMaterialsInloco: result.data.data.totalvaluereplacedmaterialsinloco,
              TotalValueReplacedMaterialsHybrid: result.data.data.totalvaluereplacedmaterialshybrid,
              TotalValueCostDisplacementRemote: result.data.data.totalvaluecostdisplacementremote,
              TotalValueCostDisplacementHybrid: result.data.data.totalvaluecostdisplacementhybrid,
              TotalValueCostDisplacementInloco: result.data.data.totalvaluecostdisplacementinloco
            }, () => {
              this.showArrayOfValueAttendance()
            });
          }
          else {
            const intl = this.props.intl;
            this.setState({
              loading: false, openNotification: true, notificationVariant: "error",
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
        })
        .catch(err => {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        });

    }
  }

  showArrayOfValueAttendance = _ => {
    const intl = this.props.intl;

    const arrayOfValueAttendance = [
      {
        "name": intl.formatMessage({id:"total.cost.remote.attendance"}),
        "value": this.state.TotalValueCostRemote,
        "sumallvalue": this.state.SumAllTotalRemoteCalleds,
        "color": "#3f50b5",
        "calleds": this.state.TotalRemoteCalleds,
        "materialcalledsvalue": this.state.TotalValueReplacedMaterialsRemote,
        "displacementcalledsvalue": intl.formatMessage({id:"not.applicable"})

      },
      {
        "name": intl.formatMessage({id:"total.cost.on.site"}),
        "value": this.state.TotalValueCostInloco,
        "sumallvalue": this.state.SumAllTotalInlocoCalleds,
        "color": "#FC2361",
        "calleds": this.state.TotalInlocoCalleds,
        "materialcalledsvalue": this.state.TotalValueReplacedMaterialsInloco,
        "displacementcalledsvalue": this.state.TotalValueCostDisplacementInloco

      },
      /* {
         "name": "Custo total de deslocamento",
         "value": this.state.TotalValueCostDisplacement,
         "color": "#F294AF",
         "calleds": this.state.TotalInlocoCalleds
 
       },*/
      {
        "name": intl.formatMessage({id:"total.cost.on.site.remote"}),
        "value": this.state.TotalValueCostHybrid,
        "sumallvalue": this.state.SumAllTotalHybridCalleds,
        "color": "#F294AF",
        "calleds": this.state.TotalHybridCalleds,
        "materialcalledsvalue": this.state.TotalValueReplacedMaterialsHybrid,
        "displacementcalledsvalue": this.state.TotalValueCostDisplacementHybrid
      },
    ]

    var TotalCoustOfCalleds = this.state.SumAllTotalRemoteCalleds + this.state.SumAllTotalInlocoCalleds + this.state.SumAllTotalHybridCalleds

    var totHH = this.state.TotalValueCostRemote + this.state.TotalValueCostInloco + this.state.TotalValueCostHybrid
    var totAllCalleds = this.state.TotalRemoteCalleds + this.state.TotalInlocoCalleds + this.state.TotalHybridCalleds
    var totMaterial = this.state.TotalValueReplacedMaterialsRemote + this.state.TotalValueReplacedMaterialsInloco + this.state.TotalValueReplacedMaterialsHybrid
    var totDisplacement = this.state.TotalValueCostDisplacementRemote + this.state.TotalValueCostDisplacementInloco + this.state.TotalValueCostDisplacementHybrid

    this.valueOfAttendance = <Grid item xs={12} sm={12} md={12} lg={12}>
      <TableContainer component={Paper} >
        <Table size="small" aria-label="a dense table" >
          {/*<caption style={{ textAlign: 'right', alignSelf: 'stretch' }}> Custo total de atendimentos {TotalCoustOfCalleds.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}  </caption>*/}
          <TableBody style={{ border: 1, borderColor: 'black', borderStyle: 'solid' }}>
            <TableRow>
              <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"type"})}</TableCell>
              <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"amount.calleds"})}</TableCell>
              <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"total.personal.value.hh"})}</TableCell>
              <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"total.replaced.materials.value"})}</TableCell>
              <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"total.displacement.value.km"})}</TableCell>
              <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#8884d8', color: 'white' }}>{intl.formatMessage({id:"total.value"})}</TableCell>
            </TableRow>
            {
              arrayOfValueAttendance.map((entry, index) => (
                <TableRow>
                  <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{entry.name}</TableCell>
                  <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{entry.calleds}</TableCell>
                  <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{entry.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
                  <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{entry.materialcalledsvalue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
                  <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{entry.displacementcalledsvalue == intl.formatMessage({id:"not.applicable"}) ? intl.formatMessage({id:"not.applicable"}) : entry.displacementcalledsvalue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
                  <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{entry.sumallvalue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>

                </TableRow>
              ))
            }
            <TableRow>
              <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2', fontWeight: 'bolder' }}>Totais</TableCell>
              <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{totAllCalleds}</TableCell>
              <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{totHH.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
              <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{totMaterial.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
              <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{totDisplacement.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
              <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor: '#f2f2f2' }}>{TotalCoustOfCalleds.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  }

  generateDetailsTooltipTotalValue = _ => {
    const HtmlTooltip = styled(({ className, ...props }) => (
      <MaterialTooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: '#f5f5f9',
          color: 'rgba(0, 0, 0, 0.87)',
          fontSize: theme.typography.pxToRem(12),
          border: '1px solid #dadde9',
      },
    }));

    return <HtmlTooltip
      title={
        <React.Fragment>
          <Typography>Email: eduardlimmadev@gmail.com</Typography>
          <Typography>Telefone: +55 (81)98633-0273</Typography>
        </React.Fragment>
      }></HtmlTooltip>
  }

  handleSubmit = async e => {
    const intl = this.props.intl;
    e.preventDefault();
    this.setState({ loading: true });
    var dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
    var dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')
    if (dateStart > dateEnd) {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"report.audit.initial.date.higher.than.end.date"}),
        loading: false
      });

    }
    else {
      await this.getCalledTotal();
    }


  }

  closeNotification = _ => {
    this.setState({ openNotification: false });
  }



  render() {

    console.log(this.state.GroupList)
    console.log(this.state.OrganizationList)
    console.log(this.state.organizationDepartamentsList)
    console.log(this.state)
    console.log("aqui 12")
    const { classes, headerMenu, actionsHeaderMenu } = this.props
    console.log(classes)
    console.log("classes qui")
    const intl = this.props.intl

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    const HtmlTooltip = styled(({ className, ...props }) => (
      <MaterialTooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: '#f5f5f9',
          color: 'rgba(0, 0, 0, 0.87)',
          fontSize: theme.typography.pxToRem(12),
          border: '1px solid #dadde9',
      },
    }));

    return (
      <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })} >
          <div className={classes.drawerHeader} />
          <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft:0, marginLeft:0}}>
            <CssBaseline />


            <Grid container spacing={6}>
              <Grid item xs={12} sm={12}>
                <Card >
                  <CardContent className="background-title-search">
                    <Typography variant="h5">
                      {<FormattedMessage id="report.dashboard" />}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
              <Accordion defaultExpanded={true} >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>{intl.formatMessage({id:"filters"})}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container style={{ marginBottom: 20 }} spacing={2} >
                    <Grid item xs={12} sm={6} md={3}  >
                      <SimpleDatePicker label={intl.formatMessage({ id: "start.date" })} name='dateStart' stateName='dateStart' changeDate={this.changeValues} currentDate={this.state.dateStart} required />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3} >
                      <SimpleDatePicker label={intl.formatMessage({ id: "end.date" })} name='dateEnd' stateName='dateEnd' changeDate={this.changeValues} currentDate={this.state.dateEnd} required />
                    </Grid>



                    <Grid item xs={12} sm={6} md={3}>
                      <SimpleAutoComplete label={intl.formatMessage({ id: "menu.groups" })} options={this.state.GroupList} stateName='GroupFilter' changeSelect={this.changeValues} />
                    </Grid>




                    <Grid item xs={12} sm={6} md={3}  >
                      <SimpleAutoComplete label={intl.formatMessage({ id: "organization" })} options={this.state.OrganizationList} stateName='OrganizationFilter' changeSelect={this.changeValues} selected={this.state.OrganizationSelected} />
                    </Grid>

                    
                    <Grid item xs={12} sm={6} md={3} >
                      <SimpleAutoComplete label={intl.formatMessage({ id: "productivity.dashboard.techinical.teams" })} options={this.state.TechinicalTeamList} stateName='TechinicalTeamFilter' selected={this.state.TechinicalTeamFilter} changeSelect={this.changeValues} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}  >
                      <SimpleAutoComplete label={intl.formatMessage({ id: "departament" })} options={this.state.organizationDepartamentsList} stateName='DepartamentFilter' changeSelect={this.changeValues} selected={this.state.DepartamentSelected} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <SimpleAutoComplete label={intl.formatMessage({ id: "menu.categories" })} options={this.state.CategoryList} stateName='CategoryFilter' changeSelect={this.changeValues} selected={this.state.CategorySelected} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <SimpleAutoComplete label={intl.formatMessage({ id: "menu.statuses" })} options={this.StatusList} stateName='StatusFilter' changeSelect={this.changeValues} selected={this.state.StatusSelected} />
                    </Grid>





                  </Grid>
                </AccordionDetails>
              </Accordion>


              <Grid container style={{ marginBottom: 10 }} justify="flex-end">



                <Grid item xs={12} sm={2} md={2} style={{ marginRight: 10, marginTop: 10 }} >
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='secondary'
                    disabled={!this.context.can('Add', 'DashboardReport')}
                  >
                    {<FormattedMessage id="filter" />}
                  </Button>
                </Grid>


              </Grid>
            </form>


            {this.state.filtered &&

              <Grid container style={{ marginTop: '30px' }} spacing={2} alignItems="stretch">

                <Grid item xs={12} sm={12} md={6} lg={4} >

                  {this.state.renderGraphic &&
                    <div>
                      {this.TotalKilometersDisplacement}
                    </div>


                  }


                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} >

                  {this.state.renderGraphic &&
                    <div>
                      {this.displacementHours}
                    </div>


                  }


                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} >

                  {this.state.renderGraphic &&
                    <div>
                      {this.attendanceHours}
                    </div>


                  }


                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} >

                  {this.state.renderGraphic &&
                    <div>
                      {this.callSolutionHours}
                    </div>


                  }


                </Grid>


                <Grid item xs={12} sm={12} md={6} lg={4} >

                  {this.state.renderGraphic &&
                    <div>
                      {this.TotalGeral}
                    </div>


                  }


                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} >

                  {this.state.renderGraphic &&
                    <div>
                      {this.TotalValue}
                    </div>


                  }


                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex' }} >
                  <Card style={{ width: '100%' }}>
                    <CardHeader classes={{ title: classes.cardheadercolor }} style={{ backgroundColor: "#8884d8", color: "white" }} title={intl.formatMessage({id:"amount.calleds"})} />
                    <Divider />
                    <CardContent style={{ borderColor: '#8884d8' }}>

                      {this.state.renderGraphic &&
                        <div>
                          {this.GraphicWithTabs}
                        </div>


                      }




                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex' }} >
                  <Card style={{ width: '100%' }}>
                    <CardHeader classes={{ title: classes.cardheadercolor }} style={{ backgroundColor: "#8884d8", color: "white" }} title="Chamados por sla" />
                    <Divider />
                    <CardContent>

                      {this.SlaGraphicContent}




                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={12} style={{ display: 'flex' }}>
                  <Card style={{ width: '100%' }}>
                    <CardHeader classes={{ title: classes.cardheadercolor }} style={{ backgroundColor: "#8884d8", color: "white" }} title="Quantidade de materiais substituídos" />
                    <Divider />
                    <CardContent>

                      {this.state.renderGraphic &&
                        <div>
                          {this.materialGraphic}
                        </div>


                      }






                    </CardContent>
                  </Card>
                </Grid>




              </Grid>





            }












            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification} />
          </Container>
        </main>
        <Footer />
      </div>
    )
  }
}

DashboardReport.propTypes = {
  classes: PropTypes.object.isRequired
}


const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(calledsActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(DashboardReport)))
DashboardReport.contextType = AbilityContext