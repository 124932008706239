import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Api from '../../services/api';
import MaterialTable from 'material-table';
import SimpleSelect from '../../components/select/simple';
import moment from 'moment';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import * as Service from '../../services/scheduleworkhour.service';

import * as componentActions from '../../redux/actions/scheduleworkhours';

import { FormattedMessage,injectIntl } from 'react-intl';

    class ScheduleWorkHours extends Component {

    constructor(props) {
        super(props);

        const { userSession } = this.props;
        this.state = {
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            resultList: [],
            WorkHourList: []
        };

        this.closeNotification = this.closeNotification.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.addItem = this.addItem.bind(this);
        this.timeConvert = this.timeConvert.bind(this);
    }

    
    timeConvert(num)
    { 
        var hms = num;   // your input string
        var a = hms.split(':'); // split it at the colons

        // Hours are worth 60 minutes.
        var minutes = (+a[0]) * 60 + (+a[1]);

        return minutes;
    }

    deleteItem(data, resolve) {
        const intl = this.props.intl;
        
        let abl = this.context.can('Delete', 'ScheduleWorkHour');
        if (abl == false){
            this.setState({loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: this.props.intl.formatMessage({id:"operation.notAllowed"})});
            resolve(); return false;
        }

        Api.delete(`/scheduleworkhours/${data.id}`)
        .then(result => {
            if (result.data.success) {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: 'success',
                    notificationMessage: intl.formatMessage({id:"called.list.notification.delete.success"}),
                    resultList: []
                    });

                this.getItens();
            }
            else{
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(result);
            }
            resolve();
        })
        .catch(err => {
            resolve();
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
            Api.kickoff(err);
        });

    };


    updateItem(data, resolve) {
        const intl = this.props.intl;
            
        for (let key in data) {
            if (key.toString().toLowerCase().indexOf('starthour') > -1 || key.toString().toLowerCase().indexOf('endhour') > -1) {
                data[key] = this.timeConvert(moment(data[key]).format("HH:mm"));
            }
        }

        Api.put('/scheduleworkhours', data)
        .then(result => {
          if (result.data.success) {

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"edit.success"}),
              item: this.initialState,
              files: []
            });

          }
          else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
          }
          else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})+err.response.data
            });
          }
          Api.kickoff(err);
        });
        this.getItens();
    };

    addItem(data, resolve) {
        const intl = this.props.intl;
        // console.log(data);

        if(data.weekday == undefined){
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: this.props.intl.formatMessage({id:"scheduleworkhour.list.empty.fields"}),
                item: this.initialState,
                files: []
            });
        }else if(data.starthour == undefined){
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: this.props.intl.formatMessage({id:"scheduleworkhour.list.empty.fields"}),
                item: this.initialState,
                files: []
            });
        }else if(data.endhour == undefined){
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: this.props.intl.formatMessage({id:"scheduleworkhour.list.empty.fields"}),
                item: this.initialState,
                files: []
            });
        }else if(data.WorkHourId == undefined){
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: this.props.intl.formatMessage({id:"scheduleworkhour.list.empty.fields"}),
                item: this.initialState,
                files: []
            });
        }else{

            for (let key in data) {
                if (key.toString().toLowerCase().indexOf('starthour') > -1 || key.toString().toLowerCase().indexOf('endhour') > -1) {
                    data[key] = this.timeConvert(moment(data[key]).format("HH:mm"));
                }
            }

            Api.post('/scheduleworkhours', data)
            .then(result => {
            if (result.data.success) {

                this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({id:"add.success"}),
                item: this.initialState,
                files: []
                });

            }
            else{
                this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: result.data && result.data.errors ? result.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})

                });
                Api.kickoff(result);
            }

            })
            .catch(err => {
            if(err.response && err.response.data && err.response.data.errors){
                this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                });
            }
            else{
                this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})+err.response.data
                });
            }
            Api.kickoff(err);
            });
            this.getItens();
        }
    };


    componentDidMount() {
        this.getWorkHours();
        this.getItens();
    }

    async getWorkHours(){

        var result = await Service.getWorkHours();
        if (result.success) {

            var formattedList = [];
            result.data.forEach(element => formattedList[element.id] = element.name);

            this.setState({ loading: false, WorkHourList: formattedList });

        } else {
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
            Api.kickoff(result);  
        }
    };

    getItens() {

        Api.get(`/scheduleworkhours`)
            .then(result => {

                if (result.data.success) {

                    const formattedList = result.data.data.map(item => {
                        
                        return {
                            ...item,
                                WorkHourId: item.workhour ? item.workhour.id : 0,
                                WorkHourText: item.workhour ? item.workhour.name : '',
                                starthour: this.getTimeFromMins(item.starthour),
                                endhour: this.getTimeFromMins(item.endhour)
                    }
                    });
                    
                this.setState({ loading: false, resultList: formattedList });
            }else{
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(result);
            }
        })
        .catch(err => {
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
            Api.kickoff(err);

        });
    };

    getTimeFromMins(mins) {
        // do not include the first validation check if you want, for example,
        // getTimeFromMins(1530) to equal getTimeFromMins(90) (i.e. mins rollover)
        if (mins >= 24 * 60 || mins < 0) {
            throw new RangeError("Valid input should be greater than or equal to 0 and less than 1440.");
        }
        var h = mins / 60 | 0,
            m = mins % 60 | 0;
        return moment.utc().hours(h).minutes(m).format("MM-DD-YYYY HH:mm");
    }

    closeNotification() {
        this.setState({ openNotification: false });
    }
    
    render() {
        const { classes, headerMenu, actionsHeaderMenu } = this.props;
        const intl = this.props.intl;

        return (
            <div className={classes.root}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    }, classes.actionsContent, {
                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='xl'>
                        <CssBaseline />
                        <Can I='List' a='ScheduleWorkHour'>
                            <MaterialTable
                                title={this.props.intl.formatMessage({id:"work.schedule"})}
                                columns={[
                                    { title: this.props.intl.formatMessage({id:"week.day"}), field: 'weekday', lookup: {1: this.props.intl.formatMessage({id:"sunday"}), 2: this.props.intl.formatMessage({id:"monday"}), 3: this.props.intl.formatMessage({id:"tueday"}), 4: this.props.intl.formatMessage({id:"wednesday"}), 5:this.props.intl.formatMessage({id:"thursday"}), 6:this.props.intl.formatMessage({id:"friday"}), 7:this.props.intl.formatMessage({id:"saturday"})} },
                                    { title: this.props.intl.formatMessage({id:"start.time"}), field: 'starthour', type: 'time', render: rowData => moment(rowData.starthour).format('HH:mm') },
                                    { title: this.props.intl.formatMessage({id:"end.time"}), field: 'endhour', type: 'time', render: rowData => moment(rowData.endhour).format('HH:mm') },
                                    { title: this.props.intl.formatMessage({id:"scheduleworkhour.list.shift.type"}), field: 'WorkHourId', lookup: this.state.WorkHourList},
                                ]}
                                data={this.state.resultList}
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.addItem(newData,resolve);
                                              }, 600);
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.updateItem(newData,resolve);
                                            }, 600);
                                    }),
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            this.deleteItem(oldData,resolve);
                                        }),
                                }}
                            />
                        </Can>
                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}
        

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
            </div>
        );
    }
}
ScheduleWorkHours.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(ScheduleWorkHours); connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ScheduleWorkHours));

ScheduleWorkHours.contextType = AbilityContext;
