import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';import SimpleSelect from '../../components/select/simple';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history'; 
import Title from '../../components/title-name';
import * as Service from '../../services/techinicalteamuser.service';
import * as componentActions from '../../redux/actions/techinicalteamusers';
import { FormattedMessage,injectIntl } from 'react-intl';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
class TechinicalTeamUserEdit extends Component {

  constructor(props) {
    super(props);
            const { userSession,techinicalteamusers } = this.props;
   

    this.globalAdm = false;
    this.isClient = true;
    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }

    if(!techinicalteamusers || (techinicalteamusers && !techinicalteamusers.techinicalteamuser)) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/calleds`
    }

    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

 
    this.initialState = {
      UpdatedUser: userSession.user.id,
       id: techinicalteamusers.techinicalteamuser.id,
     UserId:  techinicalteamusers.techinicalteamuser.UserId,
     UserText: techinicalteamusers.techinicalteamuser.UserText,
     TechinicalTeamId:  techinicalteamusers.techinicalteamuser.TechinicalTeamId,
     TechinicalTeamText: techinicalteamusers.techinicalteamuser.TechinicalTeamText,

     };         
    this.state = {
      techinicalteamuser: this.initialState,

      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      UserList: [],
      TechinicalTeamList: [],

    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }

  componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

                    componentDidMount() {
        this.getUsers();
        this.getTechinicalTeams();
     };
     async getUsers(){
          this.setState({ loading: true });
          var result = await Service.getUsers();
            if (result.success) {
              var user = result.data.find(c => c.id == this.state.techinicalteamuser.UserId)
              
              this.setState(prevState => ({
                loading: false, 
                UserList: result.data,
                techinicalteamuser: {
                  ...prevState.techinicalteamuser,
                  ["UserId"]: user
                }
              }));
             // this.setState({ loading: false, UserList: result.data});
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors &&  result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
  };     async getTechinicalTeams(){
          this.setState({ loading: true });
          var result = await Service.getTechinicalTeams();
            if (result.success) {
              var team = result.data.find(c => c.id == this.state.techinicalteamuser.TechinicalTeamId)
              
              this.setState(prevState => ({
                loading: false, 
                UserList: result.data,
                techinicalteamuser: {
                  ...prevState.techinicalteamuser,
                  ["TechinicalTeamId"]: team
                }
              }));
              this.setState({ loading: false, TechinicalTeamList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors &&  result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      techinicalteamuser: {
        ...prevState.techinicalteamuser,
        [name]: value
      }
    }));
  };

  validations() {
   
    var returnStatus = true;
    const intl = this.props.intl;
  

    Object.keys(this.state.techinicalteamuser).forEach(element => {
      
      if(element == 'UserId' || element == 'TechinicalTeamId'){

        if (this.state.techinicalteamuser[element] === null || this.state.techinicalteamuser[element].id == 0 ) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: "É necessário o preenchimento de todos os campos",
          })
  
          returnStatus = false;
        }

      }
      
     

    });


    return returnStatus;


  }

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
          techinicalteamuser: {
            ...prevState.techinicalteamuser,
          }
        }));
      } else {
        this.setState(prevState => ({
          techinicalteamuser: {
            ...prevState.techinicalteamuser,
          }
        }));
      }

      if(this.validations()){

        for (let key in this.state.techinicalteamuser) {
          if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
            data.append(key, moment(this.state.techinicalteamuser[key]).format('YYYY/MM/DD HH:mm:ss'));
          }  else if(key.toString().toLowerCase().indexOf('techinicalteamid') > -1 ){
            data.append(key, this.state.techinicalteamuser[key].id);
          }
          else if(key.toString().toLowerCase().indexOf('userid') > -1 ) {
            data.append(key, this.state.techinicalteamuser[key].id);
          }else {
            data.append(key, this.state.techinicalteamuser[key]);
          }
        }
  
        this.state.files.forEach(file => data.append('filesUpload', file));
        let config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        };
          Api.put('/techinicalteamusers', data, config)
  
          .then(result => {
            if (result.data.success) {
          const formattedList = result.data.data;
          formattedList.UserId=  formattedList.user ? formattedList.user.id : 0;
          formattedList.UserText=  formattedList.user ? formattedList.user.name : '';
          formattedList.TechinicalTeamId=  formattedList.techinicalteam ? formattedList.techinicalteam.id : 0;
          formattedList.TechinicalTeamText=  formattedList.techinicalteam ? formattedList.techinicalteam.name : '';
          const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({id:"edit.success"}),
                files: []
              });
          this.props.addCurrent(formattedList);
          this.componentDidMount();
   
            }
            else{
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage:  result.data.errors && result.data.errors[0] ? result.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
              });
  
            }
          })
          .catch(err => {
            if(err.response && err.response.data && err.response.data.errors){
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
              });
            }
            else{
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: this.props.intl.formatMessage({id:"process.error"})
              });
            }
          });

      }
      else{
        this.setState({ loading: false });
      }


      
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      techinicalteamuser: {
        ...prevState.techinicalteamuser,
        [stateName]: value
      }
    }));
        // if(stateName === 'UserId'){
        //   this.setState(prevState => ({
        //     techinicalteamuser: {
        //       ...prevState.techinicalteamuser,
        //       ['UserText']: text,
        //     }
        //   }));
        // }
        // if(stateName === 'TechinicalTeamId'){
        //   this.setState(prevState => ({
        //     techinicalteamuser: {
        //       ...prevState.techinicalteamuser,
        //       ['TechinicalTeamText']: text,
        //     }
        //   }));
        // }
 
    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

    render() {
    const { classes, headerMenu, actionsHeaderMenu, techinicalteamusers } = this.props;
    const { techinicalteamuser } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }
    
     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary'  onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
              {<FormattedMessage id="edit.item" />}
              <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete disabled label={intl.formatMessage({id:"user"})} name='userid'  options={this.state.UserList} stateName='UserId' changeSelect={this.changeValues} selected={techinicalteamuser.UserId} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete disabled label={intl.formatMessage({id:"team"})} name='techinicalteamid'  options={this.state.TechinicalTeamList} stateName='TechinicalTeamId' changeSelect={this.changeValues} selected={techinicalteamuser.TechinicalTeamId} />
                    </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                  <Grid item xs={12} sm={4}    >
                    <Button 
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                      disabled
                    
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                </Grid>
                </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />                    
     </div>
    );
  }
}
TechinicalTeamUserEdit.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                  actionsHeaderMenu: state.actionsHeaderMenu,
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                    techinicalteamusers: state.techinicalteamusers
                });
                const mapDispatchToProps = dispatch =>
                    bindActionCreators(componentActions, dispatch);

            export default injectIntl(connect(mapStateToProps,mapDispatchToProps)(withStyles(Styles)(TechinicalTeamUserEdit)));
