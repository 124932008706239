
import Api2Talk from "../api2talk";

export const DeleteOption = async (id) => { 
  let data = [];
  await Api2Talk.delete(`channelregistrationnumberrequest/${id}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getChannelsAssociations = async (registrationNumberRequestId) => { 
  let data = [];
  await Api2Talk.get(`channelregistrationnumberrequest/getbyregistrationnumberrequestid/${registrationNumberRequestId}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};