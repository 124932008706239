import React from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ImageList from '@mui/material/ImageList';

import Action from "./action";

import { FormattedMessage, injectIntl } from 'react-intl';

export default function CalledReportImagesDialog({ open, handleClose, classes, accompaniments, isClient, getFile, intl, displayAssignedInfoMode, techinicalTeamName }) {
    console.log("CalledReportImagesDialog");
    console.log(intl);
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="called-report-images-dialog"
                aria-describedby={intl.formatMessage({id:"called.report.images.dialog.title.tickets.images"})}
                >
                <DialogTitle id="alert-dialog-title">{intl.formatMessage({id:"called.accompaniments"})}</DialogTitle>
                <DialogContent>
                    <div>
                        <ImageList cellHeight={60} className={"classes2.ImageList"} cols={1} >

                            {accompaniments && accompaniments.map((action, i) => {
                                if (isClient && action.privacy) {
                                    //console.log("entrei aqui")

                                } else {
                                    //console.log("entrei aqui 2")
                                    return <Action displayassignedinfomode={displayAssignedInfoMode} techinicalteamname={techinicalTeamName} key={i} classes={classes} action={action} isClient={isClient} showPrivacy={isClient} id={action.id} intl={intl} attachments={action.accompanimentattachments} getFile={getFile} />
                                }
                            })}

                            {accompaniments.length == 0 &&
                                    <h4>
                                        {intl.formatMessage({id:"called.report.images.dialog.no.accompaniments"})}
                                    </h4>
                                }
                        </ImageList>
                    </div>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" autoFocus>
                            <FormattedMessage id="close"/>
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    )
}