export function addCalledServiceSettingsOrganizationFieldsList(calledservicesettingsorganizationfieldslist) {
  return {
      type: 'ADD_CALLED_SERVICE_SETINGS_ORGANIZATION_FIELD_LIST',
      calledservicesettingsorganizationfieldslist,
  }
}

export function addCalledServiceSettingsOrganizationField(calledservicesettingsorganizationfield) {
  return {
      type: 'ADD_CALLED_SERVICE_SETINGS_ORGANIZATION_FIELD',
      calledservicesettingsorganizationfield,
  }
}