import { AttachFile, Chat, ContactSupportRounded, DeleteForever, LiveHelp, SmsRounded, ThreePRounded } from "@mui/icons-material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import RemoveIcon from '@mui/icons-material/Delete';
import { Button, CircularProgress, Container, Grid, IconButton, ImageList, Radio, Switch, TextField, Tooltip, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import { Component } from "react";
import { SketchPicker } from "react-color";
import Dropzone from "react-dropzone";
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactQuill from "react-quill";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import SimpleAutoComplete from "../../../../components/auto-complete/autocomplete.js";
import DialogGeneral from "../../../../components/dialog-general/index.js";
import CustomizedSnackbars from "../../../../components/material-snackbars/index.js";
import { AbilityContext } from '../../../../config/ability-context';
import * as componentActions from '../../../../redux/actions/calledflows';
import * as WebchatSettings from "../../../../services/2talk/webchat-settings.service.js";
import Api from "../../../../services/api";
import * as FileService from '../../../../services/attachment.service';
import { Overlay } from "../../../../styles/global.js";
import { Styles } from '../../../../styles/material-styles';

const styles = {
  header: {
    marginBottom: 16,
    borderBottom: '2px solid #ccc', // Adapte a cor da borda conforme necessário
    paddingBottom: 8,
    fontWeight: 'bold',
    color: '#777',
    width: '100%',
    marginTop: '10px'
  },
};


class WebchatSetting extends Component {

  constructor(props) {
    super(props);

    const { userSession, webchats } = this.props;
    this.globalAdm = false;
    this.isClient = true;

    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }

    this.itemInitialState = {
      id: null,
      webchatid: webchats.webchat.id,
      workhourid: null,
      welcomemessage: '',
      offlinemessage: '',
      title: '',
      logo: '',
      screeniconposition: '',
      screeniconheight: 60,
      iconmodel: '',
      iconformat: '',
      icontemplate: '',
      fillcolor: '#935858',
      fontcolor: '#935858',
      displayofflinemessageform: false,
      displayforecastmessage: false,
      forecastmessagetype: '',
      displayavataragent: false,
      customerreferencecodefieldtype: '',
      customeremailfieldtype: '',
      customerphonefieldtype: '',
      customercpffieldtype: '',
      displaysatisfactionsurveyafterchatend: false,
      enableinactivitytime: false,
      inactivitymessage: '',
      subtitle: '',
      labelbutton: '',
      attachmentid: null,
      inactivitytimevalue: null,
      notifybeforeinactivate: false,
      notificationleadtime: null,
      inactivationwarningmessage: '',
      iconcustom: '',
      iconcustomattachmentid: null
    }; 

    this.state = {
      item: this.itemInitialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      files: [],
      workhours: [],
      webchatsettingsattachments: [],
      DialogRemoveState: false,
      DialogRemoveIconCustomState: false
    };

    this.reactQuillModules = {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ],
    }
  
    this.reactQuillFormats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image'
    ]

    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleChangeRadio = this.handleChangeRadio.bind(this);
    this.useStyles = this.useStyles.bind(this);
    this.removeLogo = this.removeLogo.bind(this);
    this.removeIconCustom = this.removeIconCustom.bind(this);
    this.handleDeleteIconCustom = this.handleDeleteIconCustom.bind(this);
    this.openOrCloseRemoveModal = this.openOrCloseRemoveModal.bind(this);
    this.handleDeleteLogo = this.handleDeleteLogo.bind(this);
  }

  fetchDataNotSuccess(result) {
    const intl = this.props.intl;
    this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    });
  }

  fetchDataThrowError(err) {
    const intl = this.props.intl;
    this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    });
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  }

  handleValidations = () => {

    const notifyError = (message) => {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: message
      });
    };
  
    if (this.state.item.enableinactivitytime && (!this.state.item.inactivitytimevalue || Number(this.state.item.inactivitytimevalue) < 1)) {
      notifyError('O campo "Valor do tempo de inatividade em minutos" precisa ser maior que zero.');
      return false;
    }
  
    if (this.state.item.enableinactivitytime && !this.state.item.inactivitymessage.replace(/<[^>]*>/g, '').trim().length > 0) {
      notifyError('O campo "Mensagem padrão de encerramento de chat por inatividade" é obrigatório.');
      return false;
    }
  
    if (this.state.item.notifybeforeinactivate && Number(this.state.item.notificationleadtime) <= 0) {
      notifyError('O campo "Tempo de antecedência para notificação de inatividade" precisa ser maior que zero.');
      return false;
    }
  
    if (this.state.item.enableinactivitytime && Number(this.state.item.notificationleadtime) >= Number(this.state.item.inactivitytimevalue)) {
      notifyError('O campo "Tempo de antecedência para notificação de inatividade" precisa ser menor que o tempo de inatividade.');
      return false;
    }
  
    if (this.state.item.notifybeforeinactivate && !this.state.item.inactivationwarningmessage.replace(/<[^>]*>/g, '').trim().length > 0) {
      notifyError('O campo "Mensagem de aviso de inativação da conversa" é obrigatório.');
      return false;
    }
  
    return true;  
  }

  handleSubmit = async e => {
    e.preventDefault();

    let validations = this.handleValidations();

    if (validations) 
    {
      this.setState({ loading: true });

      const data = this.state.item;

      let input = new FormData();

      for (let key in data) {
        if (key === "workhourid") {
          input.append(key, (data[key]?.id ?? null))
        } else if (key === "id") {
          if ((data[key] !== null || data[key] != undefined)) {
            input.append(key, data[key])
          }
        } else if (key === "forecastmessagetype") {
          if (isNaN(parseInt(data[key]))) {
            input.append(key, null);
          } else {
            input.append(key, null);
          }
        } else {
          input.append(key, data[key])
        }
      }

      this.state.files.forEach(({file, fieldname}) => input.append(fieldname, file));

      try {
        let config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }; 

        let output = {};

        if (this.state.item.id && this.state.item.id != null) {
          output = await WebchatSettings.update(input, config);
        } else {
          output = await WebchatSettings.create(input, config);
        }
        
        if (output.success) {
          await this.fetchData();

          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: 'Registro salvo com sucesso'
          });      
        } else {
          this.fetchDataNotSuccess(output);
          Api.kickoff(output);
        }
      } catch (err) {
        this.fetchDataNotSuccess(err);
        Api.kickoff(err);

      } finally {
        this.setState({ loading: false });
      }
    }
  }

  async fetchWorkHours() {
    this.props.setLoadingState(true);

    try {
        const response = await Api.get(`/workhours`);
        this.props.setLoadingState(false);

        if (response.data.success) {
            const workhourslist = response.data.data.map(item => ({ id: item.id, name: item.name }));
            this.setState({
                loading: false,
                workhours: workhourslist
            });
        } else {
            this.fetchDataThrowError(response);
            Api.kickoff(response);
        }
    } catch (err) {
        this.props.setLoadingState(false);
        this.fetchDataThrowError(err);
        Api.kickoff(err);
    }
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  handleChangeSwitch(event) {
    const stateName = event.target.name;
    const value = event.target.checked;
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));
  }

  handleChangeAutoComplete(stateName, value) {  
    let data = value.id > 0 ? value : null ;
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: data
      }
    }));
  }

  handleChangeEditor(value, name) {
    this.setState(prevState => ({
        item: {
            ...prevState.item,
            [name]: value || ""
        }
    }));
  }

  handleChangeComplete = (name, color) => {
    this.setState(prevState => ({
      item: { 
        ...prevState.item,
        [name]: color.hex
      }
    }))
  }

  async fetchData() {
    this.setState({ loading: true });

    try {
      const output = await WebchatSettings.getByWebchatId(this.state.item.webchatid);

      if (output.success) {

        if (output.count > 0) {
          const workhourItemSelected = this.state.workhours.find(item => item.id === output?.data?.workhourid);

          let files = [];

          if (output.data?.attachmentid) {
            const file = {
              id: output.data?.attachmentid,
              description: "logotipo"
            }

            files.push(file);
          }

          if (output.data?.iconcustomattachmentid) {
            const file = {
              id: output.data?.iconcustomattachmentid,
              description: "iconcustom"
            }

            files.push(file);
          }

          this.setState({
            item: {
              ...this.itemInitialState,
              id: output?.data?.id ?? null,
              workhourid: workhourItemSelected ?? null,
              welcomemessage: output?.data?.welcomemessage ?? "",
              offlinemessage: output?.data?.offlinemessage ?? "",
              title: output?.data?.title ?? "",
              screeniconposition: output?.data?.screeniconposition ?? "",
              screeniconheight: output?.data?.screeniconheight ?? 60,
              iconmodel: output?.data?.iconmodel ?? "",
              iconformat: output?.data?.iconformat ?? "",
              icontemplate: output?.data?.icontemplate ?? "",
              fillcolor: output?.data?.fillcolor ?? "#935858",
              fontcolor:  output?.data?.fontcolor ?? "#935858",
              displayofflinemessageform: output?.data?.displayofflinemessageform ?? false,
              displayforecastmessage: output?.data?.displayforecastmessage ?? false,
              forecastmessagetype: output?.data?.forecastmessagetype ?? "",
              displayavataragent: output?.data?.displayavataragent ?? false,
              customerreferencecodefieldtype: output?.data?.customerreferencecodefieldtype ?? "",
              customeremailfieldtype: output?.data?.customeremailfieldtype ?? "",
              customercpffieldtype: output?.data?.customercpffieldtype ?? "",
              customerphonefieldtype:  output?.data?.customerphonefieldtype ?? "",
              displaysatisfactionsurveyafterchatend: output?.data?.displaysatisfactionsurveyafterchatend ?? false,
              enableinactivitytime: output?.data?.enableinactivitytime ?? false,
              inactivitymessage: output?.data?.inactivitymessage ?? "",
              subtitle: output?.data?.subtitle ?? "",
              labelbutton: output?.data?.labelbutton ?? "",
              attachmentid: output?.data?.attachmentid ?? null,
              logo: output?.data?.logo ?? "",
              iconcustom: output?.data?.iconcustom ?? "",
              iconcustomattachmentid: output?.data?.iconcustomattachmentid ?? null,
              inactivitytimevalue: output?.data?.inactivitytimevalue ?? null,
              notifybeforeinactivate: output?.data?.notifybeforeinactivate ?? false,
              notificationleadtime: output?.data?.notificationleadtime ?? null,
              inactivationwarningmessage: output?.data?.inactivationwarningmessage ?? ''
            },
            files: [],
            webchatsettingsattachments: files
          });
        }
      } else {
        this.fetchDataNotSuccess(output);
        Api.kickoff(output);
      }

    } catch (err) {
      this.fetchDataNotSuccess(err);
      Api.kickoff(err);

    } finally {
      this.setState({ loading: false });
    }
  }


  validateImagesAtachments(files, name) {
    const intl = this.props.intl;
    let array = files.filter(file => file?.fieldname === name);

    if (array.length > 1) {
        this.setState({
            evAttachFile: {},
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: intl.formatMessage({ id: "checklist.modal.select.image.error" }),
        })
        return
    }
    else {
      let currentFiles = [...this.state.files];
      let index = currentFiles.findIndex(file => file.fieldname === name);

      if (index !== -1) {
        currentFiles[index] = {fieldname: name, file: files[0]};
      } else {
        currentFiles.push({fieldname: name, file: files[0]});
      }

      this.setState({ files: currentFiles })
    }
  }

  removeItem(file, name) {
    let array = [...this.state.files];
    let index = array.findIndex(file => file.fieldname === name);

    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  handleChangeRadio(e, name){
    var value = e.target.value;
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  }

  useStyles() {
    return withStyles({
      ImageList: {
        width: "100%",
        height: 100,
      },
    });
  };

  async getFile(id) {

    const intl = this.props.intl;
    this.setState({ loading: true });

    const respose = await FileService.getFileById(id);
    this.setState({ loading: false });
    const {data} = respose;

    if (data.success) {
      window.open(`${data.data}`, '_blank');
    }
    else {
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "file.not.found" })
      });
    }
  };

  async removeLogo() {
    this.setState({ DialogRemoveState: true })
  }

  openOrCloseRemoveModal = () => {
    this.setState({ DialogRemoveState: false })
  }

  async removeIconCustom() {
    this.setState({ DialogRemoveIconCustomState: true })
  }
  
  openOrCloseRemoveIconCustomModal = () => {
    this.setState({ DialogRemoveIconCustomState: false })
  }

  async componentDidMount() {
    await this.fetchWorkHours();
    await this.fetchData();
  }

  async handleDeleteLogo(){
    try {
      const id = this.state.item.id;
      await WebchatSettings.removeLogo(id);
      this.openOrCloseRemoveModal();
      this.setState(prev => ({
        ...prev,
        webchatsettingsattachments: [...prev.webchatsettingsattachments.filter(file => file.description !== "logotipo")]
      }));
      await this.fetchData();
    } catch (error) {
       this.setState({
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: "Não foi possível remover a logo.",
      })
    }
  }

  async handleDeleteIconCustom(){
    try {
      const id = this?.state?.item?.id;
      await WebchatSettings.removeIconCustom(id);
      this.openOrCloseRemoveIconCustomModal();
      this.setState(prev => ({
        ...prev,
        webchatsettingsattachments: [...prev.webchatsettingsattachments.filter(file => file.description !== "iconcustom")]
      }));
      await this.fetchData();
    } catch (error) {
      this.setState({
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: "Não foi possível remover o icone personalizado.",
      })
    }
  }

  render() {
    const { classes } = this.props;
   
    const { item } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    const classes2 = this.useStyles();

    return (
      <div style={{ paddingTop: '10px' }}>
          <Container component='main' maxWidth='md'>
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                <Grid container spacing={2}>

                  <Typography variant="h5" style={styles.header}>
                    Design
                  </Typography>

                  <Grid item xs={12} sm={12}>
                    <TextField 
                      inputProps={{ maxLength: 250 }} 
                      fullWidth 
                      label={intl.formatMessage({id:"title"})} 
                      autoComplete='title' 
                      variant='outlined' 
                      name='title' 
                      onChange={this.handleInputChange} 
                      value={item.title} 
                      required 
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField 
                      inputProps={{ maxLength: 250 }} 
                      fullWidth 
                      label={intl.formatMessage({id:"webchatsubtitle"})} 
                      autoComplete='subtitle' 
                      variant='outlined' 
                      name='subtitle' 
                      onChange={this.handleInputChange} 
                      value={item.subtitle} 
                      required 
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField 
                      inputProps={{ maxLength: 250 }} 
                      fullWidth 
                      label={intl.formatMessage({id:"labelbutton"})} 
                      autoComplete='labelbutton' 
                      variant='outlined' 
                      name='labelbutton' 
                      onChange={this.handleInputChange} 
                      value={item.labelbutton} 
                      required 
                    />
                  </Grid>

                  <Grid item xs={12} >
                    <Grid item xs={12} sm={12}>
                        <section style={{ width: '100%' }}>
                            <div className={classes.dropzoneArea} style={{ minHeight: '70px', marginTop: '5px ' }}>
                                <Dropzone onDrop={acceptedFiles => this.validateImagesAtachments([...acceptedFiles, ...this.state.files], 'logoAttachment')}>
                                    {({ getRootProps, getInputProps, acceptedFiles }) => (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <AttachFileIcon />
                                            <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                                                <FormattedMessage id="drag.and.drop.logo.45" />
                                            </Typography>
                                        </div>
                                    )}
                                </Dropzone>
                                {this.state.files.filter(file => file.fieldname === 'logoAttachment').map(({file, fieldname}) => {
                                    return (
                                        <div className={classes.dropzoneItem} key={file.name} style={{display: 'flex', alignItems: 'center'}}>
                                            {file.name}
                                            <DeleteForever 
                                              className={classes.deleteItem} 
                                              onClick={() => { this.removeItem(file, fieldname) }} 
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </section>
                    </Grid>
                  </Grid>

                  {this.state.webchatsettingsattachments.filter(file => file.description === "logotipo").length > 0 && (
                    <Grid item xs={12} style={{ marginTop: 15 }}>
                      <div className={classes.attachmentsBox}>
                        <label>{<FormattedMessage id="attachments" />}</label>
                        <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                          <ul>
                            {this.state.webchatsettingsattachments.filter(file => file.description === "logotipo").map(file => {
                              return (
                              <li key={file.id} style={{display: 'flex', alignItems: 'center'}}>
                                <AttachFile /> 
                                <Button 
                                  onClick={() => this.getFile(file.id)}
                                >
                                  {file.description}
                                </Button>
                                <Tooltip 
                                  title={<h5>Remover arquivo</h5>}
                                >
                                  <IconButton aria-label="textevidence"
                                    onClick={ () => this.removeLogo(file.id)}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </Tooltip>
                              </li>)
                            })}
                          </ul>
                        </ImageList>
                      </div>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={12}>
                    <Typography>
                      <FormattedMessage id="welcomemessage" />
                    </Typography>
                    <ReactQuill
                      name="welcomemessage" 
                      value={item.welcomemessage}
                      style={{ height: 150, marginBottom: 32 }} 
                      modules={this.reactQuillModules} 
                      formats={this.reactQuillFormats}
                      fullWidth
                      variant="outlined"
                      id="welcomemessage"
                      onChange={(value) => this.handleChangeEditor(value, "welcomemessage")}
                    />					  
                  </Grid>

                  <Grid  item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography variant="body1" component="span">
                          {intl.formatMessage({id:"screeniconposition"})}
                          </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4} md={4} lg={4}>
                        <Radio
                        checked={this.state.item.screeniconposition == 1}
                        onChange={(e) => this.handleChangeRadio(e, 'screeniconposition')}
                        value= {1}
                        color="default"
                        name="screeniconposition"
                        inputProps={{ 'aria-label': true }}
                        size="small" /> 
                        <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"aside.left"})}</span>
                      </Grid>
                      
                      <Grid item xs={6} sm={4} md={4} lg={4}>
                        <Radio
                          checked={this.state.item.screeniconposition == 2}
                          onChange={(e) => this.handleChangeRadio(e, 'screeniconposition')}
                          value={2}
                          color="default"
                          name="screeniconposition"
                          inputProps={{ 'aria-label': false }}
                          size="small"
                        />
                        <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"aside.center"})}</span>
                      </Grid>
                      <Grid item xs={6} sm={4} md={4} lg={4}>
                        <Radio
                          checked={this.state.item.screeniconposition == 3}
                          onChange={(e) => this.handleChangeRadio(e, 'screeniconposition')}
                          value={3}
                          color="default"
                          name="screeniconposition"
                          inputProps={{ 'aria-label': false }}
                          size="small"
                        />
                        <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"aside.rigth"})}</span>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField 
                      inputProps={{ maxLength: 250 }} 
                      fullWidth 
                      label={intl.formatMessage({id:"screnniconheight"})} 
                      autoComplete='screeniconheight' 
                      variant='outlined' 
                      name='screeniconheight' 
                      onChange={this.handleInputChange} 
                      value={item.screeniconheight} 
                      required
                      type="number"
                    />
                  </Grid>

                  { /*             
                  <Grid  item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography variant="body1" component="span">
                          {intl.formatMessage({id:"iconmodel"})}
                          </Typography>
                      </Grid>

                      <Grid item xs={6} sm={4} md={4} lg={4}>
                        <Radio
                          checked={this.state.item.iconmodel == 1}
                          onChange={(e) => this.handleChangeRadio(e, 'iconmodel')}
                          value= {1}
                          color="default"
                          name="iconmodel"
                          inputProps={{ 'aria-label': true }}
                          size="small" 
                        /> 
                        <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"iconmodel.expanded"})}</span>
                      </Grid>

                      <Grid item xs={6} sm={4} md={4} lg={4}>
                        <Radio
                          label = {this.props.intl.formatMessage({id:"no"})}
                          checked={this.state.item.iconmodel == 2}
                          onChange={(e) => this.handleChangeRadio(e, 'iconmodel')}
                          value={2}
                          color="default"
                          name="iconmodel"
                          inputProps={{ 'aria-label': false }}
                          size="small"
                        />
                        <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"iconmodel.retracted"})}</span>
                      </Grid>
                    </Grid>
                </Grid>


                <Grid  item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography variant="body1" component="span">
                          {intl.formatMessage({id:"iconformat"})}
                          </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4} md={4} lg={4}>
                        <Radio
                        checked={this.state.item.iconformat == 1}
                        onChange={(e) => this.handleChangeRadio(e, 'iconformat')}
                        value= {1}
                        color="default"
                        name="iconformat"
                        inputProps={{ 'aria-label': true }}
                        size="small" /> 
                        <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"iconformat.simple"})}</span>
                      </Grid>
                      <Grid item xs={6} sm={4} md={4} lg={4}>
                        <Radio
                          checked={this.state.item.iconformat == 2}
                          onChange={(e) => this.handleChangeRadio(e, 'iconformat')}
                          value={2}
                          color="default"
                          name="iconformat"
                          inputProps={{ 'aria-label': false }}
                          size="small"
                        />
                        <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"iconformat.square"})}</span>
                      </Grid>
                      <Grid item xs={6} sm={4} md={4} lg={4}>
                        <Radio
                          checked={this.state.item.iconformat == 3}
                          onChange={(e) => this.handleChangeRadio(e, 'iconformat')}
                          value={3}
                          color="default"
                          name="iconformat"
                          inputProps={{ 'aria-label': false }}
                          size="small"
                        />
                        <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"iconformat.circle"})}</span>
                      </Grid>
                    </Grid>
                </Grid>
                */}

                <Grid  item xs={12} sm={12} md={12} lg={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="body1" component="span">
                        {intl.formatMessage({id:"icontemplate"})}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={2} md={2} lg={2}>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <Radio
                        label = {this.props.intl.formatMessage({id:"yes"})}
                        checked={this.state.item.icontemplate == 1}
                        onChange={(e) => this.handleChangeRadio(e, 'icontemplate')}
                        value= {1}
                        color="default"
                        name="icontemplate"
                        inputProps={{ 'aria-label': true }}
                        size="small" /> 
                        <Chat fontSize="large"/>
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={2} md={2} lg={2}>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <Radio
                          label = {this.props.intl.formatMessage({id:"no"})}
                          checked={this.state.item.icontemplate == 2}
                          onChange={(e) => this.handleChangeRadio(e, 'icontemplate')}
                          value={2}
                          color="default"
                          name="icontemplate"
                          inputProps={{ 'aria-label': false }}
                          size="small"
                        />
                        <ContactSupportRounded fontSize="large"/>
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={2} md={2} lg={2}>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <Radio
                          label = {this.props.intl.formatMessage({id:"no"})}
                          checked={this.state.item.icontemplate == 3}
                          onChange={(e) => this.handleChangeRadio(e, 'icontemplate')}
                          value={3}
                          color="default"
                          name="icontemplate"
                          inputProps={{ 'aria-label': false }}
                          size="small"
                        />
                        <SmsRounded fontSize="large"/>
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={2} md={2} lg={2}>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <Radio
                          label = {this.props.intl.formatMessage({id:"no"})}
                          checked={this.state.item.icontemplate == 4}
                          onChange={(e) => this.handleChangeRadio(e, 'icontemplate')}
                          value={4}
                          color="default"
                          name="icontemplate"
                          inputProps={{ 'aria-label': false }}
                          size="small"
                        />
                        <ThreePRounded fontSize="large"/>
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={2} md={2} lg={2}>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <Radio
                          label = {this.props.intl.formatMessage({id:"no"})}
                          checked={this.state.item.icontemplate == 5}
                          onChange={(e) => this.handleChangeRadio(e, 'icontemplate')}
                          value={5}
                          color="default"
                          name="icontemplate"
                          inputProps={{ 'aria-label': false }}
                          size="small"
                        />
                        <LiveHelp fontSize="large"/>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} >
                  <Grid item xs={12} sm={12}>
                      <label>Icone personalizado</label>
                      <section style={{ width: '100%' }}>
                          <div className={classes.dropzoneArea} style={{ minHeight: '70px', marginTop: '5px ' }}>
                              <Dropzone onDrop={acceptedFiles => this.validateImagesAtachments([...acceptedFiles, ...this.state.files], 'iconCustomAttachment')}>
                                  {({ getRootProps, getInputProps, acceptedFiles }) => (
                                      <div {...getRootProps()}>
                                          <input {...getInputProps()} />
                                          <AttachFileIcon />
                                          <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                                            Clique aqui para selecionar seu ícone personalizado
                                          </Typography>
                                      </div>
                                  )}
                              </Dropzone>
                              {this.state.files.filter(file => file.fieldname === 'iconCustomAttachment').map(({file, fieldname}) => {
                                  return (
                                      <div className={classes.dropzoneItem} key={file.name} style={{display: 'flex', alignItems: 'center'}}>
                                          {file.name}
                                          <DeleteForever 
                                            className={classes.deleteItem} 
                                            onClick={() => { this.removeItem(file, fieldname) }} 
                                          />
                                      </div>
                                  )
                              })}
                          </div>
                      </section>
                  </Grid>
                </Grid>

                {this.state.webchatsettingsattachments.filter(file => file.description === "iconcustom").length > 0 && (
                    <Grid item xs={12} style={{ marginTop: 15 }}>
                      <div className={classes.attachmentsBox}>
                        <label>Icone personalizado</label>
                        <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                          <ul>
                            {this.state.webchatsettingsattachments.filter(file => file.description === "iconcustom").map(file => {
                              return (
                              <li key={file.id} style={{display: 'flex', alignItems: 'center'}}>
                                <AttachFile /> 
                                <Button 
                                  onClick={() => this.getFile(file.id)}
                                >
                                  Icone personalizado
                                </Button>
                                <Tooltip 
                                  title={<h5>Remover arquivo</h5>}
                                >
                                  <IconButton aria-label="textevidence"
                                    onClick={ () => this.removeIconCustom(file.id)}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </Tooltip>
                              </li>)
                            })}
                          </ul>
                        </ImageList>
                      </div>
                    </Grid>
                  )}

                <Grid item xs={12} sm={12}>
                  <Grid item xs={6} sm={4} md={3} lg={2}>
                    <label>
                      {intl.formatMessage({id:"fillcolor"})}
                    </label>
                    <SketchPicker
                      color={ item.fillcolor }
                      onChangeComplete={ (value) => this.handleChangeComplete('fillcolor', value) } 
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Grid item xs={6} sm={4} md={3} lg={2}>
                    <label>
                      {intl.formatMessage({id:"fontcolor"})}
                    </label>
                    <SketchPicker
                      color={ item.fontcolor }
                      onChangeComplete={ (value) => this.handleChangeComplete('fontcolor', value) }  
                    />
                  </Grid>
                </Grid>

                {/** ---------------------------- Horário atendimentos  ------------------------ */}

                <Typography variant="h5" style={styles.header}>
                    Horário de expediente
                  </Typography>

                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete 
                      name="workhourid"
                      label={intl.formatMessage({ id: "workhourid" })}
                      options={this.state.workhours}
                      stateName='workhourid'
                      changeSelect={(stateName, value) => {
                        this.handleChangeAutoComplete(stateName, value)
                      }}
                      selected={item.workhourid}
                      htmlOptions={true}
                    />
                  </Grid>


                {/** ---------------------------- parametros ------------------------ */}

                <Typography variant="h5" style={styles.header}>
                  Parâmetros
                </Typography>
                    
                <Grid item xs={12} sm={12}>
                  <Switch
                    value={item.displayofflinemessageform}
                    checked={item.displayofflinemessageform}
                    name="displayofflinemessageform"
                    onChange={(e) => this.handleChangeSwitch(e)}
                  />
                  <FormattedMessage id="displayofflinemessageform" />
                </Grid> 

                <Grid item xs={12} sm={12}>
                  <Switch
                    value={item.displayforecastmessage}
                    checked={item.displayforecastmessage}
                    name="displayforecastmessage"
                    onChange={(e) => this.handleChangeSwitch(e)}
                  />
                  <FormattedMessage id="displayforecastmessage" />
                </Grid>

                <Grid  item xs={12} sm={12} md={12} lg={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Radio
                      label = {this.props.intl.formatMessage({id:"yes"})}
                      checked={this.state.item.forecastmessagetype == 1}
                      onChange={(e) => this.handleChangeRadio(e, 'forecastmessagetype')}
                      value= {1}
                      color="default"
                      name="forecastmessagetype"
                      inputProps={{ 'aria-label': true }}
                      size="small" /> 
                      <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"forecastmessagetype.1"})}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Radio
                        label = {this.props.intl.formatMessage({id:"no"})}
                        checked={this.state.item.forecastmessagetype == 2}
                        onChange={(e) => this.handleChangeRadio(e, 'forecastmessagetype')}
                        value={2}
                        color="default"
                        name="forecastmessagetype"
                        inputProps={{ 'aria-label': false }}
                        size="small"
                      />
                      <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"forecastmessagetype.2"})}</span>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Switch
                    value={item.displayavataragent}
                    checked={item.displayavataragent}
                    name="displayavataragent"
                    onChange={(e) => this.handleChangeSwitch(e)}
                  />
                  <FormattedMessage id="displayavataragent" />
                </Grid> 


                <Grid  item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography variant="body1" component="span">
                          {intl.formatMessage({id:"customerreferencecodefieldtype"})}
                          </Typography>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Radio
                          checked={this.state.item.customerreferencecodefieldtype == 1}
                          onChange={(e) => this.handleChangeRadio(e, 'customerreferencecodefieldtype')}
                          value= {1}
                          color="default"
                          name="customerreferencecodefieldtype"
                          inputProps={{ 'aria-label': true }}
                          size="small" 
                        /> 
                        <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"customerreferencecodefieldtype.1"})}</span>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Radio
                          label = {this.props.intl.formatMessage({id:"no"})}
                          checked={this.state.item.customerreferencecodefieldtype == 2}
                          onChange={(e) => this.handleChangeRadio(e, 'customerreferencecodefieldtype')}
                          value={2}
                          color="default"
                          name="customerreferencecodefieldtype"
                          inputProps={{ 'aria-label': false }}
                          size="small"
                        />
                        <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"customerreferencecodefieldtype.2"})}</span>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Radio
                          label = {this.props.intl.formatMessage({id:"no"})}
                          checked={this.state.item.customerreferencecodefieldtype == 3}
                          onChange={(e) => this.handleChangeRadio(e, 'customerreferencecodefieldtype')}
                          value={3}
                          color="default"
                          name="customerreferencecodefieldtype"
                          inputProps={{ 'aria-label': false }}
                          size="small"
                        />
                        <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"customerreferencecodefieldtype.3"})}</span>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Radio
                          label = {this.props.intl.formatMessage({id:"no"})}
                          checked={this.state.item.customerreferencecodefieldtype == 4}
                          onChange={(e) => this.handleChangeRadio(e, 'customerreferencecodefieldtype')}
                          value={4}
                          color="default"
                          name="customerreferencecodefieldtype"
                          inputProps={{ 'aria-label': false }}
                          size="small"
                        />
                        <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"customerreferencecodefieldtype.4"})}</span>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Radio
                          label = {this.props.intl.formatMessage({id:"no"})}
                          checked={this.state.item.customerreferencecodefieldtype == 5}
                          onChange={(e) => this.handleChangeRadio(e, 'customerreferencecodefieldtype')}
                          value={5}
                          color="default"
                          name="customerreferencecodefieldtype"
                          inputProps={{ 'aria-label': false }}
                          size="small"
                        />
                        <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"customerreferencecodefieldtype.5"})}</span>
                      </Grid>
                    </Grid>
                </Grid>


                <Grid  item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography variant="body1" component="span">
                          {intl.formatMessage({id:"customerphonefieldtype"})}
                          </Typography>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Radio
                          checked={this.state.item.customerphonefieldtype == 1}
                          onChange={(e) => this.handleChangeRadio(e, 'customerphonefieldtype')}
                          value= {1}
                          color="default"
                          name="customerphonefieldtype"
                          inputProps={{ 'aria-label': true }}
                          size="small" 
                        /> 
                        <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"customerphonefieldtype.1"})}</span>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Radio
                          label = {this.props.intl.formatMessage({id:"no"})}
                          checked={this.state.item.customerphonefieldtype == 2}
                          onChange={(e) => this.handleChangeRadio(e, 'customerphonefieldtype')}
                          value={2}
                          color="default"
                          name="customerphonefieldtype"
                          inputProps={{ 'aria-label': false }}
                          size="small"
                        />
                        <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"customerphonefieldtype.2"})}</span>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Radio
                          label = {this.props.intl.formatMessage({id:"no"})}
                          checked={this.state.item.customerphonefieldtype == 3}
                          onChange={(e) => this.handleChangeRadio(e, 'customerphonefieldtype')}
                          value={3}
                          color="default"
                          name="customerphonefieldtype"
                          inputProps={{ 'aria-label': false }}
                          size="small"
                        />
                        <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"customerphonefieldtype.3"})}</span>
                      </Grid>
                    </Grid>
                </Grid>
                
                {/*
                <Grid  item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography variant="body1" component="span">
                          {intl.formatMessage({id:"customeremailfieldtype"})}
                          </Typography>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Radio
                          checked={this.state.item.customeremailfieldtype == 1}
                          onChange={(e) => this.handleChangeRadio(e, 'customeremailfieldtype')}
                          value= {1}
                          color="default"
                          name="customeremailfieldtype"
                          inputProps={{ 'aria-label': true }}
                          size="small" 
                        /> 
                        <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"customeremailfieldtype.1"})}</span>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Radio
                          label = {this.props.intl.formatMessage({id:"no"})}
                          checked={this.state.item.customeremailfieldtype == 2}
                          onChange={(e) => this.handleChangeRadio(e, 'customeremailfieldtype')}
                          value={2}
                          color="default"
                          name="customeremailfieldtype"
                          inputProps={{ 'aria-label': false }}
                          size="small"
                        />
                        <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"customeremailfieldtype.2"})}</span>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Radio
                          label = {this.props.intl.formatMessage({id:"no"})}
                          checked={this.state.item.customeremailfieldtype == 3}
                          onChange={(e) => this.handleChangeRadio(e, 'customeremailfieldtype')}
                          value={3}
                          color="default"
                          name="customeremailfieldtype"
                          inputProps={{ 'aria-label': false }}
                          size="small"
                        />
                        <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"customeremailfieldtype.3"})}</span>
                      </Grid>
                    </Grid>
                </Grid>
                */}


                <Grid  item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography variant="body1" component="span">
                          {intl.formatMessage({id:"customercpffieldtype"})}
                          </Typography>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Radio
                          checked={this.state.item.customercpffieldtype == 1}
                          onChange={(e) => this.handleChangeRadio(e, 'customercpffieldtype')}
                          value= {1}
                          color="default"
                          name="customercpffieldtype"
                          inputProps={{ 'aria-label': true }}
                          size="small" 
                        /> 
                        <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"customercpffieldtype.1"})}</span>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Radio
                          label = {this.props.intl.formatMessage({id:"no"})}
                          checked={this.state.item.customercpffieldtype == 2}
                          onChange={(e) => this.handleChangeRadio(e, 'customercpffieldtype')}
                          value={2}
                          color="default"
                          name="customercpffieldtype"
                          inputProps={{ 'aria-label': false }}
                          size="small"
                        />
                        <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"customercpffieldtype.2"})}</span>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Radio
                          label = {this.props.intl.formatMessage({id:"no"})}
                          checked={this.state.item.customercpffieldtype == 3}
                          onChange={(e) => this.handleChangeRadio(e, 'customercpffieldtype')}
                          value={3}
                          color="default"
                          name="customercpffieldtype"
                          inputProps={{ 'aria-label': false }}
                          size="small"
                        />
                        <span style={{fontSize: '15px', marginTop: '10px'}}>{intl.formatMessage({id:"customercpffieldtype.3"})}</span>
                      </Grid>
                    </Grid>
                </Grid>


                {/** ---------------------------- outros  ------------------------ */}

                <Typography variant="h5" style={styles.header}>
                  Outros
                </Typography>

                <Grid item xs={12} sm={12}>
                  <Switch
                    value={item.displaysatisfactionsurveyafterchatend}
                    checked={item.displaysatisfactionsurveyafterchatend}
                    name="displaysatisfactionsurveyafterchatend"
                    onChange={(e) => this.handleChangeSwitch(e)}
                  />
                  <FormattedMessage id="displaysatisfactionsurveyafterchatend" />
                </Grid> 
                
                <Grid item xs={12} sm={12}>
                  <Switch
                    value={item.enableinactivitytime}
                    checked={item.enableinactivitytime}
                    name="enableinactivitytime"
                    onChange={(e) => this.handleChangeSwitch(e)}
                  />
                  <FormattedMessage id="enableinactivitytime" />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    type="number"
                    inputProps={{ maxLength: 250 }}
                    fullWidth
                    label={intl.formatMessage({ id: "inactivitytimevalue" })}
                    autoComplete='finactivitytimevalue'
                    variant='outlined'
                    name='inactivitytimevalue'
                    onChange={this.handleInputChange}
                    value={item.inactivitytimevalue}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography>
                    <FormattedMessage id="inactivitymessage" />
                  </Typography>
                  <ReactQuill
                    name="inactivitymessage" 
                    value={item.inactivitymessage}
                    style={{ height: 150, marginBottom: 32 }} 
                    modules={this.reactQuillModules} 
                    formats={this.reactQuillFormats}
                    fullWidth
                    variant="outlined"
                    id="inactivitymessage"
                    onChange={(value) => this.handleChangeEditor(value, "inactivitymessage")}
                  />					  
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Switch
                  value={this.state.item.notifybeforeinactivate}
                  checked={this.state.item.notifybeforeinactivate}
                  name="notifybeforeinactivate"
                  onChange={(e) => this.handleChangeSwitch(e)}
                  />
                  <FormattedMessage id="label_notifybeforeinactivate" />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    type="number"
                    inputProps={{ maxLength: 250 }}
                    fullWidth
                    label={intl.formatMessage({ id: "label_notificationleadtime" })}
                    autoComplete='fnotificationleadtime'
                    variant='outlined'
                    name='notificationleadtime'
                    onChange={this.handleInputChange}
                    value={item.notificationleadtime}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography>
                    <FormattedMessage id="label_inactivationwarningmessage" />
                  </Typography>
                  <ReactQuill
                    name="inactivationwarningmessage"
                    value={item.inactivationwarningmessage}
                    style={{ height: 150, marginBottom: 32 }}
                    modules={this.reactQuillModules}
                    formats={this.reactQuillFormats}
                    fullWidth
                    variant="outlined"
                    id="inactivationwarningmessage"
                    onChange={(value) => this.handleChangeEditor(value, "inactivationwarningmessage")}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Typography>
                    <FormattedMessage id="offlinemessage" />
                  </Typography>
                  <ReactQuill
                    name="offlinemessage" 
                    value={item.offlinemessage}
                    style={{ height: 150, marginBottom: 32 }} 
                    modules={this.reactQuillModules} 
                    formats={this.reactQuillFormats}
                    fullWidth
                    variant="outlined"
                    id="offlinemessage"
                    onChange={(value) => this.handleChangeEditor(value, "offlinemessage")}
                  />					  
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                  <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                    <Grid item xs={12} sm={4}    >
                      <Button 
                        type='submit'
                        fullWidth
                        variant='contained'
                        color='primary'
                        className={classes.submit}
                      >
                        {<FormattedMessage id="save" />}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                </Grid>
              </form>
              <DialogGeneral
                dialogTitle="Remover"
                dialogSubTitle="Você deseja remover esta logo ?"
                open={this.state.DialogRemoveState}
                openOrCloseModalFunction={this.openOrCloseRemoveModal}
                handleConfirmation={this.handleDeleteLogo}
                
              />

              <DialogGeneral
                dialogTitle="Remover"
                dialogSubTitle="Você deseja remover este icone personalizado ?"
                open={this.state.DialogRemoveIconCustomState}
                openOrCloseModalFunction={this.openOrCloseRemoveIconCustomModal}
                handleConfirmation={this.handleDeleteIconCustom}
                
              />
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            {
              this.state.openNotification && (
                <CustomizedSnackbars
                  variant={this.state.notificationVariant}
                  message={this.state.notificationMessage}
                  isOpen={this.state.openNotification}
                  toClose={this.closeNotification}
                />
              )
            }          
          </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  webchats: state.webchats
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(WebchatSetting)));

WebchatSetting.contextType = AbilityContext;