import { ContentCopy, Delete, Help } from "@mui/icons-material";
import { Button, Grid, TextField, Tooltip, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import SimpleAutoComplete from "src/components/auto-complete/autocomplete-dialog-with-z-index";
import SimpleCheckBox from "src/components/checkbox/check";
import DialogConfirmationDeleteProvider from '../../../../components/dialog-confirmation-not-button'
import { PapiroConsole } from "src/utils/papiroConsole";

const EmailReadingConfig = props => {
    
    const { config, classes, intl, orgsList } = props

    return (
        <div>
            <form name='myForm' className={classes.form} onSubmit={props.checkProviderDeletion} encType='multipart/form-data'>
                <Grid container spacing={2} >
                    <Grid item xs={11.5} sm={11.5}>
                        <SimpleCheckBox
                            label={intl.formatMessage({ id: 'usemexxdefaultemailprovider' })}
                            name='usemexxdefaultemailprovider'
                            stateName='usemexxdefaultemailprovider'
                            changeSelect={props.changeValues}
                            selected={config.usemexxdefaultemailprovider}
                        />
                    </Grid>
                    <Grid item xs={0.5} sm={0.5} style={{ textAlign: "middle", alignSelf: "stretch", marginTop: '10px' }} >
                        <Tooltip title={<h5>{intl.formatMessage({ id: "usemexxdefaultemailprovider.tooltip" })}</h5>}>
                            <Help />
                        </Tooltip>
                    </Grid>
                    {config.usemexxdefaultemailprovider &&
                        <Grid item container spacing={2} xs={12} sm={12}>
                            <Grid item xs={11.5} sm={11.5}>
                                <TextField label={intl.formatMessage({ id: "email" })} disabled value={config.frommailreceive} fullWidth />
                            </Grid>
                            <Grid item xs={0.5} sm={0.5} style={{ textAlign: "middle", alignSelf: "stretch", marginTop: '10px' }} >
                                <Tooltip title={<h5>{intl.formatMessage({ id: "copy.emails" })}</h5>} color="secondary" style={{ cursor: "pointer" }}>
                                    <ContentCopy onClick={_ => navigator.clipboard.writeText(config.frommailreceive)} />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={11.5} sm={11.5}>
                                <SimpleAutoComplete
                                    label={intl.formatMessage({ id: "email.org.default" })}
                                    options={orgsList}
                                    stateName='emailorganizationid'
                                    changeSelect={props.changeValues}
                                    selected={config.emailorganizationid}
                                />
                            </Grid>
                            <Grid item xs={0.5} sm={0.5} style={{ textAlign: "middle", alignSelf: "stretch", marginTop: '10px' }} >
                                <Tooltip title={<h5>{intl.formatMessage({ id: "email.org.default.tooltip" })}</h5>}>
                                    <Help />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={11.5} sm={11.5}>
                                <SimpleCheckBox label={intl.formatMessage({ id: 'ResponseEmailIsNewCalled' })} name='responseemailisnewcalled' stateName='responseemailisnewcalled' changeSelect={props.changeValues} selected={config.responseemailisnewcalled} />
                            </Grid>
                            <Grid item xs={0.5} sm={0.5}>
                                <Tooltip title={<h5>{intl.formatMessage({ id: "organization.account.email.registration.email.reply.new.called" })}</h5>}>
                                    <Help />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={11.5} sm={11.5}>
                                <SimpleCheckBox label={intl.formatMessage({ id: 'AccompanimentCalledForResponseEmail' })} name='accompanimentcalledforresponseemail' stateName='accompanimentcalledforresponseemail' changeSelect={props.changeValues} selected={config.accompanimentcalledforresponseemail} />
                            </Grid>
                            <Grid item xs={0.5} sm={0.5}>
                                <Tooltip title={<h5>{intl.formatMessage({ id: "organization.account.email.registration.email.reply.new.accompaniment" })}</h5>}>
                                    <Help />
                                </Tooltip>
                            </Grid>
                        </Grid>}
                    <Grid item xs={12} sm={12} style={{ marginTop: 50, display: 'flex', justifyContent: 'flex-end' }}>
                        <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                            <Grid item xs={12} sm={4}    >
                                <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    className={classes.submit}
                                >
                                    {<FormattedMessage id="save" />}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            {props.openDeleteProviderUserDialog && (
                <DialogConfirmationDeleteProvider
                    msgButton={intl.formatMessage({ id: "confirm" })}
                    msg={intl.formatMessage({ id: "delete.provider.usermail" })}
                    msgDisagree={intl.formatMessage({ id: "cancel" })}
                    msgAgree={intl.formatMessage({ id: "yes" })}
                    handleConfirmation={props.handleSubmit}
                    handleClose={props.updateOpenDeleteProviderUserDialog}
                    classes={classes}
                    disabled={false}
                    icon={<Delete />}
                />
            )}
        </div>
    )
}

export default EmailReadingConfig