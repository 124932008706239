export default function calledservicesettingsvalidations(state = [], action) {
  switch (action.type) {
    case 'ADD_LIST_CALLED_SERVICE_SETINGS_VALIDATION':
      return {
        ...state,
        calledservicesettingsvalidationslist: action.calledservicesettingsvalidationslist,
      }
    case 'ADD_CALLED_SERVICE_SETINGS_VALIDATION':
      return {
        ...state,
        calledservicesettingsvalidation: action.calledservicesettingsvalidation
      }
    default:
      return state;
  }
}