import { PapiroConsole } from "../../utils/papiroConsole"

export default function calledservicesettingscalledfields(state = [], action) {
  PapiroConsole.log("=== calledservicesettingscalledfields reducer ===")
  PapiroConsole.log(action)

  switch (action.type) {
    case 'ADD_LIST':
      return {
        ...state,
        calledservicesettingscalledfieldslist: action.calledservicesettingscalledfieldslist,
      }
    case 'ADD_CALLED_SERVICE_SETINGS_CALLED_FIELD':
      return {
        ...state,
        calledservicesettingscalledfield: action.calledservicesettingscalledfield
      }
    default:
      return state;
  }
}