import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Api from '../../services/api';
import MyMaterialTable from '../../components/table';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context' 
import { FormattedMessage,injectIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';  
import Search from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import SimpleSelect from '../../components/select/simple-search';
import ClearIcon from '@mui/icons-material/Clear';
import { withRouter } from 'react-router-dom';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';


    import * as componentActions from '../../redux/actions/organizationcategories';
import { PapiroConsole } from '../../utils/papiroConsole';
    class OrganizationCategoriesContent extends Component {

    constructor(props) {
        super(props);

        const intl = this.props.intl;

        let fromProps = ""
        if(this.props && this.props.from && this.props.from.length > 0 && this.props.from == 'orgEdit')
            fromProps = this.props.from

        let filters = [
            {name:intl.formatMessage({id:"category"}),id:"category"},
            {name:intl.formatMessage({id:"organization.category.parent"}),id:"parent"},
            {name:intl.formatMessage({id:"departament"}),id:"departament"},
            {name:intl.formatMessage({id:"organization"}),id:"org"},
            {name:intl.formatMessage({id:"organization.category.create.call"}),id:"createcalled"},
            {name:intl.formatMessage({id:"organization.category.service.attendance"}),id:"solution"},
            {name:intl.formatMessage({id:"organization.category.sla"}),id:"sla"},
            {name:intl.formatMessage({id:"organization.category.service.sla"}),id:"service"},
            {name:intl.formatMessage({id:"organization.category.time.sla"}),id:"usesla"},
            {name:intl.formatMessage({id:"organization.category.appears.on.portal"}),id:"appearsonportal"},
            ];

        const { userSession, organizations } = this.props;
        this.typingTimeout = null;
        this.state = {
            organizations: organizations,
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            resultList: [],
            organizationList: [],
            totalPages:0,
            page:1,
            pageStart:0,
            pageEnd:0,
            count:0,
            searchValue: '',
            filterSelected: 0,
            filters:filters,
            from: fromProps,
            OrganizationFilter: 0,
            OrganizationFilterItem: null
        };
        this.changeValues = this.changeValues.bind(this);
        this.callSearch = this.callSearch.bind(this);
                        this.onFieldChange = this.onFieldChange.bind(this);

        this.closeNotification = this.closeNotification.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.clearFields = this.clearFields.bind(this);
    }

    onChangePaginate = (e,page) =>{
        this.setState({ page:page });
        localStorage.setItem('organizationCategoriesTablePage', `${page}`)
        this.getItens(page);
    }

    setPagination(count,page){
                            
                            let totalPage = Math.ceil (count/10);
                            this.setState({ totalPages:totalPage })
                            if(count > 0){
                                if(page == 1){
                                    if(count < 10){
                                        this.setState({pageStart:1,pageEnd:count});
                                    } else{
                                        this.setState({pageStart:1,pageEnd:10});
                                    }
                                } else{
                                    let pageStart = ((page - 1) * 10) + 1;
                                    
                                    
                                    
                                    if(count >= page * 10){
                                        this.setState({pageStart:pageStart,pageEnd:page * 10});
                                    } else{
                                        this.setState({pageStart:pageStart,pageEnd:count});
                                    }
                                }
                            } else {
                                this.setState({totalPages:0,page:1,count:0});
                            }
                            
    }

    onFieldChange(event) {
    
        
        this.setState({ [event.target.name]: event.target.value, page:1 });

    }

    callSearch(){
        if(this.state.filterSelected != 0){
            localStorage.setItem('organizationCategoriesTableFilter', `${this.state.filterSelected}=${this.state.searchValue}`)
            this.getItens(this.state.page);
        }                        
    }

    async clearFields(){
        await   this.setState({ OrganizationFilter: 0, OrganizationFilterItem: null, filterSelected: 0, searchValue: '', page:1})
        localStorage.setItem('organizationCategoriesTableFilter', '')
        localStorage.setItem('organizationCategoryTableOrganizationFilter', '0')
        localStorage.setItem('organizationCategoriesTablePage', '1')
        this.getItens(1);
    }

    async changeValues(stateName, value, text = '') {
        if(stateName === 'OrganizationFilter') {
            this.setState({ [stateName]: value.id })
            this.getItens()
        }else
            this.setState({filterSelected : value})
    }

    deleteItem(data, resolve) {
        const intl = this.props.intl;
        let abl = this.context.can('Delete', 'OrganizationCategory');
        if (abl == false){
            this.setState({loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: intl.formatMessage({id:"operation.notAllowed"})});
            resolve(); return false;
        }

        Api.delete(`/organizationcategories/${data.id}`)
            .then(result => {
                if (result.data.success) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: intl.formatMessage({id:"delete.sucess"}),
                        resultList: []
                        });

                    this.getItens();
                }
                else{
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(result);
                }
                resolve();
            })
            .catch(err => {
                const intl = this.props.intl;
                resolve();
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(err);
            });
    };

    getOrganizations = async _ => { 
        this.setState({ loading: true })
        let data = [];
        const { userSession } = this.props
        PapiroConsole.log("print orgs aqui ")
        PapiroConsole.log(userSession)
        if(userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
            data = userSession.user.userorganizations
        } else {
            await Api.get('/organizations/tech')
                .then(result => {
                    data = result.data.data
                })
                .catch(err => {
                    data = err;
            })
        }
        this.setState({ organizationList: data, loading: false })
    }

    async componentDidMount() {
        await this.getOrganizations()
        let sessionFilter = localStorage.getItem('organizationCategoriesTableFilter')
        if(sessionFilter == null) {
            sessionFilter = [0, '']
            localStorage.setItem('organizationCategoriesTableFilter', '')
        } else {
            if(sessionFilter && sessionFilter.length > 0) {
                let sessionFilterSplit = sessionFilter.split("=")
                if(sessionFilterSplit && sessionFilterSplit.length > 1) {
                    if(!isNaN(sessionFilterSplit[0]))
                        sessionFilter = [parseInt(sessionFilterSplit[0]), sessionFilterSplit[1]]
                    else
                        sessionFilter = [sessionFilterSplit[0], sessionFilterSplit[1]]
                }
            }
        }
        if(isNaN(sessionFilter[0])) {
            let sessionFilterItem = this.state.filters.filter(item => item.id == sessionFilter[0])
            if(sessionFilterItem && sessionFilterItem.length > 0) {
                await this.setState({
                    filterSelected: sessionFilterItem[0]
                }, () => this.setState({ filterSelected: sessionFilter[0], searchValue: sessionFilter[1] }))
            }
        } else {
            await this.setState({ filterSelected: sessionFilter[0], searchValue: sessionFilter[1] })
        }

        let sessionOrgFilter = localStorage.getItem('organizationCategoryTableOrganizationFilter')
        if(sessionOrgFilter == null) {
            sessionOrgFilter = 0
            localStorage.setItem('organizationCategoryTableOrganizationFilter', '0')
        }
        let orgFilterItem = null
        if(!isNaN(sessionOrgFilter) && typeof sessionOrgFilter === 'string') {
            sessionOrgFilter = parseInt(sessionOrgFilter)
            if(sessionOrgFilter > 0 && this.state.organizationList != null) {
                orgFilterItem = this.state.organizationList.filter(item => item.id == sessionOrgFilter)
                if(orgFilterItem && orgFilterItem.length > 0) {
                    
                    await this.setState({
                        OrganizationFilterItem: orgFilterItem[0]
                    })
                }
            }
        }
        await this.setState({ OrganizationFilter: sessionOrgFilter })

        let sessionPage = localStorage.getItem('organizationCategoriesTablePage')
        if(sessionPage == null) {
            sessionPage = 1
            localStorage.setItem('organizationCategoriesTablePage', '1')
        }
        if(!isNaN(sessionPage) && typeof sessionPage === 'string')
            sessionPage = parseInt(sessionPage)
        this.setState({ page: sessionPage })
        this.getItens(sessionPage)
    }

    getItens(page = 1) {

        this.setState({ loading: true });
        const intl = this.props.intl;

        let url = ''

        if (this.props.location && this.props.location.pathname === `/${this.props.match.params.lang}/organizationcategories`) {
            url = `/organizationcategories/list/paginate/new?page=${page}&org=${this.state.OrganizationFilter && this.state.OrganizationFilter > 0 ? this.state.OrganizationFilter : 0}`;

            if (this.state.searchValue != '' && this.state.filterSelected != 0) {
                url += `&search=${this.state.searchValue}&field=${this.state.filterSelected}`
            }
        } else {
            url = `/organizationcategories/organization/list/paginate?page=${page}&id=${this.state.organizations.organization.id}`;
                            
            if (this.state.searchValue != '' && this.state.filterSelected != 0) {
                url += `&search=${this.state.searchValue}&field=${this.state.filterSelected}`
            }
        }

        PapiroConsole.log("url aqui")
        PapiroConsole.log(url)
                    Api.get(url)
                    .then(result => {
                        if (result.data.success) {

                            PapiroConsole.log("resultados aqui")
                            PapiroConsole.log(result)
                           
                            let count = result.data.count;
                            this.setState({count:count});
                            this.setPagination(count,page);

                            const formattedList = result.data.data.map(item => {
                                return {
                                    ...item,
                                        OrganizationId: item.organization ? item.organization.id : 0,
                                        OrganizationText: item.organization ? item.organization.name : '',
                                        parentname : item.parentname? item.parentname : '',
                                        OrganizationSlaId: item.organizationsla ? item.organizationsla.id : 0,
                                        OrganizationSlaText: item.organizationsla ? item.organizationsla.name : '',
                                        CalledFlowId: item.calledflow ? item.calledflow.id : 0,
                                        CalledFlowText: item.calledflow ? item.calledflow.name : '',
                                        SlaCountTypeId: item.slacounttype ? item.slacounttype.id : 0,
                                        SlaCountTypeText: item.slacounttype ? item.slacounttype.name : '',
                                        CategoryId: item.category ? item.category.id : 0,
                                        CategoryText: item.name,
                                        ParentOrganizationCategoryId: item.parentorganizationcategory ? item.parentorganizationcategory.id : 0,
                                        ParentOrganizationCategory: item.parentorganizationcategory ? item.parentorganizationcategory : null ,
                                        ParentOrganizationCategoryText: item.parentorganizationcategory ? item.parentorganizationcategory.name : '',
                                        CanCreateCalled: item.cancreatecalled == true  ? this.props.intl.formatMessage({id:"yes"}) : this.props.intl.formatMessage({id:"no"}), 
                                        UseSlaTime: item.useslatime == true  ? this.props.intl.formatMessage({id:"yes"}) : this.props.intl.formatMessage({id:"no"}), 
                                        ServiceSlaNotification: item.serviceslanotification == true  ? this.props.intl.formatMessage({id:"yes"}) : this.props.intl.formatMessage({id:"no"}), 
                                        IsDefaultCategory: item.isdefaultcategory == true  ? this.props.intl.formatMessage({id:"yes"}) : this.props.intl.formatMessage({id:"no"}),
                                        AppearsOnPortal: item.appearsonportal == true  ? this.props.intl.formatMessage({id:"yes"}) : this.props.intl.formatMessage({id:"no"}), 
                                        active: item.inactive == true ? this.props.intl.formatMessage({id:"no"}) : this.props.intl.formatMessage({id:"yes"})
                            }
                    });
                    this.setState({ loading: false, resultList: formattedList });
                }
                else{
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(err);

            });
    };


    closeNotification() {
        this.setState({ openNotification: false });
    }
        render() {
        const { classes, headerMenu } = this.props;
        const intl = this.props.intl;

        if(Api == null) {
            var l = this.props.match.params.lang;
            document.location.href=`/${l}/unavailable`
          }

        return (
                <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft:0, marginLeft:0}}>
                    <CssBaseline />
                    <Grid container className={classes.mb20}  spacing={0}>
                        <Button
                            variant='contained'
                            color='secondary'
                            size='large'
                            disabled={!this.context.can('Add', 'OrganizationCategory')}
                            className={classes.button}
                            startIcon={<AddIcon />}
                            component={Link} {...{ to: {
                                pathname: `/${this.props.match.params.lang}/organizationcategory-registration`,
                                state: {
                                    internal: this.props.internal,
                                    from: this.state.from
                            }}}} >
                            {<FormattedMessage id="add" />}
                        </Button>
                    </Grid>
                    <Grid container spacing={6}> 
                        <Grid item xs={12} sm={12}>
                            <Card className="background-title-search">
                                <CardContent>
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="h5">
                                                {<FormattedMessage id="organization.category.header" />}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                                            {(this.props.location && this.props.location.pathname === `/${this.props.match.params.lang}/organizationcategories`) && (<SimpleAutoComplete label={intl.formatMessage({id:"organization"})} options={this.state.organizationList} stateName='OrganizationFilter'
                                                            selected={this.state.OrganizationFilterItem}
                                                            changeSelect={(stateName, value, text = '') => {
                                                                    this.setState({
                                                                        OrganizationFilter: value.id,
                                                                        OrganizationFilterItem: value
                                                                    }, () => {
                                                                        if(value && value.id && value.id > 0)
                                                                            localStorage.setItem('organizationCategoryTableOrganizationFilter', `${value.id}`)
                                                                        else
                                                                            localStorage.setItem('organizationCategoryTableOrganizationFilter', '0')
                                                                        this.getItens()
                                                                    })
                                                                }}/>)}
                                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <SimpleSelect label={intl.formatMessage({id:"field"})} options={this.state.filters} stateName='filter' changeSelect={this.changeValues} selected={this.state.filterSelected}/>
                                        </Grid>
                                        <Grid item >
                                            <TextField type="text"
                                            name="searchValue"
                                            value={this.state.searchValue}
                                            placeholder={intl.formatMessage({id:"search"})}
                                            onChange={this.onFieldChange}/>

                                            <Button
                                                variant='contained'
                                                color='secondary'
                                                size='large'
                                                className={classes.button}
                                                style={{marginRight:4,marginLeft:8}}
                                                onClick={this.callSearch} >
                                                <Search />
                                            </Button>

                                            <Button
                                                variant='contained'
                                                color='primary'
                                                size='large'
                                                onClick={this.clearFields}
                                                className={classes.button} >
                                                <ClearIcon />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>                     
                    </Grid>
                    <Can I='List' a='OrganizationCategory'>      
                        <MyMaterialTable
                                            title=""
                                                    rowClick={(event, rowData) => {
                                                    let abl = this.context.can('Edit', 'OrganizationCategory');
                                                        if (abl == false)
                                                        {
                                                            this.setState({ loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: intl.formatMessage({id:"operation.notAllowed"})});
                                                            return false;
                                                        }
                                                        console.log(rowData)
                                                        console.log(this.props.organizations.organization)
                                                        console.log("rowData, this.props.organizations.organization")
                                                        this.props.addCurrentOrganization(rowData, this.props.organizations.organization);
                                                        this.props.history.push({
                                                            pathname: `/${this.props.match.params.lang}/organizationcategory-edit`,
                                                            state: {
                                                                internal: this.props.internal,
                                                                from: this.state.from
                                                            }
                                                        });
                                                }}
                            columns={[
                                        { title: "Id", field: 'id' },
                                        { title: intl.formatMessage({id:"category"}), field: 'CategoryText' },
                                        { title: intl.formatMessage({id:"organization"}), field: 'OrganizationText' },
                                        { title: intl.formatMessage({id:"organization.category.create.call"}), field: 'CanCreateCalled' },
                                        { title: intl.formatMessage({id:"organization.category.parent"}), field: 'parentname' },
                                        { title: intl.formatMessage({id:"organization.category.sla"}), field: 'OrganizationSlaText' },
                                        { title: intl.formatMessage({id:"organization.category.service.sla"}), field: 'servicetime' },
                                        { title: intl.formatMessage({id:"sla.solution"}), field: 'solutiontime' },
                                        { title: intl.formatMessage({id:"organization.category.time.sla"}), field: 'UseSlaTime' },
                                        { title: intl.formatMessage({id:"organization.category.appears.on.portal"}),field: "AppearsOnPortal"},
                                        { title: intl.formatMessage({id:"active"}),field: "active"},


                            ]}
                            data={this.state.resultList}
                            deleteItem={this.deleteItem} 
                        />
                        </Can>

                        <Can I='List' a='OrganizationCategory'>
                                            
                        <Grid container style={{marginBottom:12,marginTop:8}}>
                            <Grid item xs={12} md={3}>
                            <Pagination  count={this.state.totalPages} page={this.state.page}  onChange={this.onChangePaginate} />
                            </Grid>
                            <Grid item xs={12} sm={3} style={{marginTop:6}}>
                            {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                            </Grid>
                        </Grid>

                        </Can> 

                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}
        

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
        );
    }
}
OrganizationCategoriesContent.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    organizations: state.organizations
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(OrganizationCategoriesContent))));
OrganizationCategoriesContent.contextType = AbilityContext;
