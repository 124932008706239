import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleCheckBox from '../../components/checkbox/check';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage, injectIntl } from 'react-intl';
import Title from '../../components/title-name';
import SimpleAutoCompleteMultiple from '../../components/auto-complete/autocomplete-multiple-create';
import * as Service from '../../services/techinicalteam.service';
import * as ServiceOrg from '../../services/techinicalteamorganization.service';
import * as ServiceUser from '../../services/techinicalteamuser.service';
import * as ServiceCategory from '../../services/techinicalteamorganizationcategory.service';
import { DataGrid, GridToolbarFilterButton  } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Tooltip from '@mui/material/Tooltip';
import DialogTitle from '@mui/material/DialogTitle';
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './index.css'
import DialogConfirmation from '../../components/dialog-confirmation'
import { PapiroConsole } from '../../utils/papiroConsole';
import DialogAddMultiple from '../../components/dialog-add-multiple';

const NoDataRows = intl => {
  return (
    <div className="MuiDataGrid-window" style={{ display: 'flex', justifyContent: 'center', top: 56, overflowY: 'auto' }} >{intl.formatMessage({id:"rows.show"})}</div>
  )
}

class TechinicalTeamEdit extends Component {

  constructor(props) {
    super(props);

    const { userSession, techinicalteams } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    if (userSession == null ||  userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    if(!techinicalteams || (techinicalteams && !techinicalteams.techinicalteam)) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/calleds`
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    }
    );

    console.log(techinicalteams)
    console.log("techinicalteams")

    this.initialState = {
      UpdatedUser: userSession.user.id,
      id: techinicalteams.techinicalteam.id,
      name: techinicalteams.techinicalteam.name,
      description: techinicalteams.techinicalteam.description == null ? '' : techinicalteams.techinicalteam.description,
      active: techinicalteams.techinicalteam.active,
      //UserId: userList,
      UserId: [],
      //OrganizationId: orgList,
      OrganizationId: [],
      //CategoryId: categoryList
      CategoryId: [],
      selectCategories: false,
      selectOrganizations: false,
      selectUsers: false,
    };

    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',

      UserListForDelete: [],
      UserListForSelect: [],
      UserList: [],
      UserListSelect: [],

      OrgListForDelete: [],
      OrgListForSelect: [],
      OrgList: [],
      OrgListSelect: [],

      CategoryListForDelete: [],
      CategoryListForSelect: [],
      CategoryList: [],
      CategoryListSelect: [],

      openUserInsertModal: false,
      openCategoryInsertModal: false,
      openOrgInsertModal: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);

    this.getUsers = this.getUsers.bind(this);
    this.getOrganizations = this.getOrganizations.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.getTechinicalById = this.getTechinicalById.bind(this);
    this.closeCategoryInsertModal = this.closeCategoryInsertModal.bind(this);
    this.NoDataRows = this.NoDataRows.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.getUsers();
    await this.getOrganizations();
    //await this.getTechinicalById(this.props.techinicalteams.techinicalteam.id);
    await this.getUsersByTechnicalTeamId(this.props.techinicalteams.techinicalteam.id)
    await this.getOrganizationsByTechnicalTeamId(this.props.techinicalteams.techinicalteam.id)
    await this.getOrganizationCategoriesByTechnicalTeamId(this.props.techinicalteams.techinicalteam.id)
    //await this.getCategories(this.state.OrgList);
    this.setState({ loading: false });
  };

  async getUsers() {
    this.setState({ loading: true });
    var result = await ServiceUser.getUsersWithoutTeamSelectionModel(this.props.techinicalteams.techinicalteam.id);
    if (result.success) {
      this.setState({ loading: false, UserListForSelect: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };

  async getOrganizations() {
    this.setState({ loading: true });
    var result = await ServiceOrg.getAllNotInTechinicalTeamSelectionModel(this.props.techinicalteams.techinicalteam.id);
    if (result.success) {
      this.setState({ loading: false, OrgListForSelect: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };

  NoDataRows = _ => {
    const { intl } = this.props

    return (
      <div className="MuiDataGrid-window" style={{ display: 'flex', justifyContent: 'center', top: 56, overflowY: 'auto' }} >{intl.formatMessage({id:"rows.show"})}</div>
    )
  }

  async getCategories(OrganizationId) {
    let listOrgIds = 0;
    OrganizationId.map((item, key) => {
      listOrgIds = listOrgIds + ',' + item.id
    });
    var result = await ServiceCategory.getAllCategoriesWithParentByOrganizationListNotInTechinicalTeamSelectionModel(listOrgIds, this.props.techinicalteams.techinicalteam.id);
    if (result.success) {
      if (OrganizationId.length > 1) {
        result.data = result.data.map((item) => {
          return {
            ...item,
            parentname: `( ${item.organization.name} ) ${item.parentname ? item.parentname : item.name}`
          }
        })
      }

      PapiroConsole.log("=== getCategories ===")
      PapiroConsole.log(this.state.CategoryList)
      PapiroConsole.log(result.data)

      if (this.state.CategoryList != null && this.state.CategoryList.length > 0) {
        let categoryList = this.state.CategoryList.map(i => {
          return i.id
        })
        let organizationCategoryList = []
        result.data = result.data.filter(function(item) {
          return !categoryList.includes(item.id)
        })
      }

      PapiroConsole.log("=== result.data ===")
      PapiroConsole.log(result.data)

      this.setState({ loading: false, CategoryListForSelect: result.data })

    } else {
      const intl = this.props.intl
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };

  getUsersByTechnicalTeamId = async (id) => {
    this.setState({ loading: true })

    let userList = []

    let result = await ServiceUser.getAllByTechnicalTeamId(id)
    if(result && result.success) {
      if(result.data != null) {
        result.data.map(item => {
          userList.push(item.user)
        });
        this.setState({ UserList: userList })
      }
      PapiroConsole.log("=== getUsersByTechnicalTeamId ===")
      PapiroConsole.log(userList)
      this.setState({ loading: false })
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }

  getOrganizationsByTechnicalTeamId = async (id) => {
    this.setState({ loading: true })

    let orgList = []

    let result = await ServiceOrg.getAllByTechnicalTeamIdSelectionModel(id)
    if(result && result.success) {
      if(result.data != null) {
        orgList = result.data
        this.setState({ OrgList: result.data })
      }
      PapiroConsole.log("=== getOrganizationsByTechnicalTeamId ===")
      PapiroConsole.log(orgList)
      this.setState({ loading: false }, () => this.getCategories(orgList))
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }

  getOrganizationCategoriesByTechnicalTeamId = async (id) => {
    this.setState({ loading: true })

    let categoryList = []

    let result = await ServiceCategory.getAllByTechnicalTeamIdSelectionModel(id)
    if(result && result.success) {
      if(result.data != null) {
        result.data.map(item => {
          item.organizationcategory.ParentNameView = item.organizationcategory.parentname ? item.organizationcategory.parentname : item.organizationcategory.name
          if(item.organization != null ){
            item.organizationcategory.ParentNameView = `(${item.organization.name})  ${item.organizationcategory.ParentNameView} `
          }
          categoryList.push(item.organizationcategory);
        });
        PapiroConsole.log("=== getOrganizationCategoriesByTechnicalTeamId ===")
        PapiroConsole.log(categoryList)
        this.setState({ CategoryList: categoryList })
      }
      this.setState({ loading: false })
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }

  getTechinicalById = async (id) => {

    var userList = []
    var orgList = []
    var categoryList = []
    await Api.get(`/techinicalteams/${id}`)
      .then(result => {
        if (result.data.success) {

          result.data.data.techinicalteamusers.map((item, key) => {
            userList.push(item.user);
          });

          result.data.data.techinicalteamorganizations.map((item, key) => {
            orgList.push(item.organization);
          });
          console.log("result aqui ")
          console.log(result)
          result.data.data.techinicalteamorganizationcategories.map((item, key) => {
            item.organizationcategory.ParentNameView = item.organizationcategory.parentname ? item.organizationcategory.parentname : item.organizationcategory.name
            if(item.organizationcategory.organization != null ){
              item.organizationcategory.ParentNameView = `(${item.organizationcategory.organization.name})  ${item.organizationcategory.ParentNameView} `
            } 
             
           
            categoryList.push(item.organizationcategory);
          });

          this.getCategories(orgList);
          console.log(categoryList)
          this.setState({
            UserList: userList,
            OrgList: orgList,
            CategoryList: categoryList,
          });

        }
      }).catch(err => {

        const intl = this.props.intl;
        this.setState({
          loading: false,
          notificationMessage: intl.formatMessage({id:"error"})
        });
        Api.kickoff(err);

      });

  }
/*
  async getOrganizations() {
    this.setState({ loading: true });
    var result = await ServiceOrg.getOrganizations();
    if (result.success) {
      this.setState({ loading: false, OrgListForSelect: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };

  async getUsers() {
    this.setState({ loading: true });
    var result = await ServiceUser.getUsers();
    if (result.success) {
      this.setState({ loading: false, UserListForSelect: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };
*/


  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();


    if (this.isClient) {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
        }
      }));
    } else {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
        }
      }));
    }


    for (let key in this.state.item) {
      if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
        data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
      } else {
        data.append(key, this.state.item[key]);
      }
    }
    /*
    for (var pair of data['Category'].entries()) {
      console.log(pair[0]+ ', ' + pair[1]); 
    }*/

    this.state.files.forEach(file => data.append('filesUpload', file));
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    Api.put('/techinicalteams', data, config)

      .then(result => {
        if (result.data.success) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "add.success" }),
            //item: this.initialState,
            files: []
          });


        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

          });
          Api.kickoff(result);
        }

      })
      .catch(err => {
        const intl = this.props.intl;
        if (err.response && err.response.data && err.response.data.errors) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: intl.formatMessage({ id: "process.error" })
          });
        }
        Api.kickoff(err);
      });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));
    if (stateName === 'OrganizationId') {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['CategoryId']: []
        }
      }));
      
      this.getCategories(value);
    }

    if (stateName == 'selectCategories') {
      if (value == true) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            ['CategoryId']: this.state.CategoryListForSelect,
          }
        }));
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            ['CategoryId']: [],
          }
        }));
      }
    }
    if (stateName == 'selectOrganizations') {
      if (value == true) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            ['OrganizationId']: this.state.OrgListForSelect,
          }
        }));
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            ['OrganizationId']: [],
          }
        }));
      }
    }
    if (stateName == 'selectUsers') {
      if (value == true) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            ['UserId']: this.state.UserListForSelect,
          }
        }));
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            ['UserId']: [],
          }
        }));
      }
    }
    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  openUserInsertModal = async () => {
    this.setState({
      openUserInsertModal: true
    })
  }

  saveUserInsertModal = async () => {

    if(this.state.item.UserId != null && this.state.item.UserId.length > 0){
      this.setState({
        loading: true
      })
  
      let data = new FormData();
  
      var userids = this.state.item.UserId.map(item => {
        return item.id + ","
      })
  
      data.append("User", userids);
      data.append("TechinicalTeamId", this.state.item.id);
      
      const intl = this.props.intl;
      await Api.post('/techinicalteamusers/multiple', data)
        .then(result => {
          if (result.data.success) {
            var users = [];
            result.data.data.map(item => {
              users.push(item.user)
            })
            this.setState({
              UserList: users,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({ id: "add.success" }),
            })
          }
          else {
            
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
  
            });
            Api.kickoff(result);
          }
  
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: intl.formatMessage({ id: "process.error" })
            });
          }
          Api.kickoff(err);
        });
  
      this.setState({
        openUserInsertModal: false,
        loading: false
      })
    }
    
  }

  userDeleteModal = async () => {
    const intl = this.props.intl;
    if (this.state.UserListForDelete && this.state.UserListForDelete.length > 0) {

      this.setState({ loading: true });

      let data = new FormData();
      data.append("User", this.state.UserListForDelete);
      data.append("TechinicalTeamId", this.state.item.id);
      
      await Api.post('/techinicalteamusers/delete', data)
        .then(result => {

          if (result.data.success) {
            var users = [];
            result.data.data.map(item => {
              users.push(item.user)
            })
            this.setState({
              loading: false,
              UserList: users,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
            })
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: intl.formatMessage({ id: "process.error" })
            });
          }
          Api.kickoff(err);
        });
    } else {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"select.user"})
      })
    }
  }

  orgDeleteModal = async () => {
    const intl = this.props.intl;
    if (this.state.OrgListForDelete && this.state.OrgListForDelete.length > 0) {

      this.setState({ loading: true });

      let data = new FormData();
      data.append("OrganizationId", this.state.OrgListForDelete);
      data.append("TechinicalTeamId", this.state.item.id);

      await Api.post('/techinicalteamorganizations/delete', data)
        .then(result => {
          console.log(result)
          console.log("result")
          if (result.data.success) {
            var organizations = [];
            result.data.data.map(item => {
              organizations.push(item.organization)
            })
            this.setState({
              loading: false,
              OrgList: organizations,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
            })
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: intl.formatMessage({ id: "process.error" })
            });
          }
          Api.kickoff(err);
        });
    } else {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"organization.material.registration.select.organization"})
      })
    }
  }

  closeUserModal = () => this.setState({ openUserInsertModal: false })

  setUserDelete = (user) => {
    this.setState({
      UserListForDelete: user + "," + this.state.UserListForDelete
    })

  }

  openOrgInsertModal = async () => {
    this.setState({
      openOrgInsertModal: true
    })
  }

  saveOrgInsertModal = async () => {
    
    if(this.state.item.OrganizationId != null && this.state.item.OrganizationId.length > 0){
      this.setState({
        loading: true
      })
  
      let data = new FormData();
  
      var orgids = this.state.item.OrganizationId.map(item => {
        return item.id + ","
      })
  
      data.append("OrganizationId", orgids);
      data.append("TechinicalTeamId", this.state.item.id);
  
      const intl = this.props.intl;
      await Api.post('/techinicalteamorganizations/multiple', data)
        .then(result => {
          if (result.data.success) {
            var organizations = [];
            result.data.data.map(item => {
              organizations.push(item.organization)
            })
            this.setState({
              loading: false,
              OrgList: organizations,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({ id: "add.success" }),
            })
            this.getCategories(organizations);
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
  
            });
            Api.kickoff(result);
          }
  
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: intl.formatMessage({ id: "process.error" })
            });
          }
          Api.kickoff(err);
        });
  
      this.setState({
        openOrgInsertModal: false,
        loading: false
      })
    }
  }

  setOrgDelete = async (org) => {
    this.setState({
      OrgListForDelete: org + "," + this.state.OrgListForDelete
    })
  }

  closeOrgModal = () => this.setState({ openOrgInsertModal: false })

  openCategoryInsertModal = async () => {
    this.setState({
      openCategoryInsertModal: true
    })
    this.getCategories(this.state.OrgList)
  }

  closeCategoryInsertModal = _ => {
    this.setState({
      openCategoryInsertModal: false
    })
  }

  saveCategoryInsertModal = async () => {

    if(this.state.item.CategoryId != null && this.state.item.CategoryId.length > 0){
      this.setState({
        loading: true
      })
  
      let data = new FormData();
  
      var categoryids = this.state.item.CategoryId.map(item => {
        return item.id + ","
      })
  
      data.append("OrganizationCategoryId", categoryids);
      data.append("TechinicalTeamId", this.state.item.id);
  
      const intl = this.props.intl;
      await Api.post('/techinicalteamorganizationcategories/multiple', data)
        .then(result => {
          if (result.data.success) {
            console.log("TechinicalTeamId")
            console.log(result.data.data)
  
            var category = [];
            result.data.data.map(item => {
              item.organizationcategory.ParentNameView = item.organizationcategory.parentname ? item.organizationcategory.parentname : item.organizationcategory.name
              category.push(item.organizationcategory)
            })
            this.setState({
              loading: false,
              CategoryList: category,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({ id: "add.success" }),
            })
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
  
            });
            Api.kickoff(result);
          }
  
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: intl.formatMessage({ id: "process.error" })
            });
          }
          Api.kickoff(err);
        });
  
      this.setState({
        openCategoryInsertModal: false,
        loading: false
      })
    }
    
  }

  categoryDeleteModal = async () => {
    if (this.state.CategoryListForDelete && this.state.CategoryListForDelete.length > 0) {

      this.setState({ loading: true });

      let data = new FormData();
      data.append("OrganizationCategoryId", this.state.CategoryListForDelete);
      data.append("TechinicalTeamId", this.state.item.id);

      const intl = this.props.intl;

      await Api.post('/techinicalteamorganizationcategories/delete', data)
        .then(result => {
          
          if (result.data.success) {
            var category = [];
            result.data.data.map(item => {
              item.organizationcategory.ParentNameView = item.organizationcategory.parentname ? item.organizationcategory.parentname : item.organizationcategory.name
              category.push(item.organizationcategory)
            })
            this.setState({
              loading: false,
              CategoryList: category,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
            })
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: intl.formatMessage({ id: "process.error" })
            });
          }
          Api.kickoff(err);
        });
    } else {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: 'Selecione alguma categoria'
      })
    }
  }

  setCategoryDelete = async (category) => {
    this.setState({
      CategoryListForDelete: category + "," + this.state.CategoryListForDelete
    })
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="add.item" />}
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField inputProps={{ maxLength: 250 }} fullWidth label={intl.formatMessage({ id: "name" })} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} required />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField inputProps={{ maxLength: 250 }} fullWidth label={intl.formatMessage({ id: "description" })} autoComplete='fname' variant='outlined' multiline rows="4"
                      name='description' onChange={this.handleInputChange} value={item.description} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                  <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>{intl.formatMessage({id:"menu.users"})}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container xs={12} sm={12} >

                      <Grid xs={12} sm={12} style={{ margin: '5px', padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }} >
                        <Grid xs={12} sm={4} >
                          
                        </Grid>
                        <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                          <DialogConfirmation
                            msgButton={"Remover usuário"}
                            msg={"Você tem certeza que desejar remover este usuário deste time?"}
                            msgDisagree={this.props.intl.formatMessage({id:"cancel"})}
                            msgAgree={this.props.intl.formatMessage({id:"yes"})}
                            handleConfirmation={e => this.userDeleteModal()}
                            classes={classes}
                            icon={<DeleteIcon />}
                          />
                        </Grid>
                        <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            className={classes.button}
                            startIcon={<AddIcon />}
                            onClick={e => this.openUserInsertModal()} >
                            {intl.formatMessage({id:"add.user"})}
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid xs={12} sm={12}
                        style={{
                          border: '3px solid #eee'
                        }} >
                        <div style={{ height: 350 }}>
                          <DataGrid
                            style={{ fontSize: 14, color: '#000' }}
                            className="DataGridStyle"
                            components={{
                              NoRowsOverlay: this.NoDataRows,
                              Toolbar: GridToolbarFilterButton,
                            }}
                            rows={this.state.UserList}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            //onEditCellChangeCommitted={this.updateCalledOrganizationMaterialQuantity}
                            onSelectionModelChange={itm => {
                              if (itm.length > 0) {
                                this.setUserDelete(itm);
                              } else {
                                this.setState({
                                  //materialFinishCalledDeleteButton: 'none',
                                  UserListForDelete: [],
                                })
                              }
                            }}
                            columns={[
                              { headerName: intl.formatMessage({id:"user"}), field: 'name', width: 500 },
                            ]}
                          />
                        </div>
                      </Grid>
                    </Grid>

                  </AccordionDetails>
                </Accordion>
                </Grid>
                <Grid item xs={12} sm={12}>
                <Accordion style={{ marginTop: "10px" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>{intl.formatMessage({id:"organization"})}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container xs={12} sm={12} >

                      <Grid xs={12} sm={12} style={{ margin: '5px', padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }} >
                        <Grid xs={12} sm={4} >

                        </Grid>
                        <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                          <DialogConfirmation
                            msgButton={intl.formatMessage({id:"organization.material.registration.remove.organization"})}
                            msg={intl.formatMessage({id:"technical.team.edit.remove.organization.from.team"})}
                            msgDisagree={this.props.intl.formatMessage({id:"cancel"})}
                            msgAgree={this.props.intl.formatMessage({id:"yes"})}
                            handleConfirmation={e => this.orgDeleteModal()}
                            classes={classes}
                            icon={<DeleteIcon />}
                          />
                        </Grid>
                        <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            className={classes.button}
                            startIcon={<AddIcon />}
                            onClick={e => this.openOrgInsertModal()} >
                            {intl.formatMessage({id:"organization.material.registration.add.organization"})}
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid xs={12} sm={12}
                        style={{
                          marginLeft: 5,
                          marginBottom: 5,
                          border: '3px solid #eee'
                        }} >
                        <div style={{ height: 350 }}>
                          <DataGrid
                            style={{ fontSize: 14, color: '#000' }}
                            className="DataGridStyle"
                            components={{
                              NoRowsOverlay: this.NoDataRows,
                              Toolbar: GridToolbarFilterButton,
                            }}
                            rows={this.state.OrgList}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            //onEditCellChangeCommitted={this.updateCalledOrganizationMaterialQuantity}
                            onSelectionModelChange={itm => {
                              if (itm.length > 0) {
                                this.setOrgDelete(itm);
                              } else {
                                this.setState({
                                  //materialFinishCalledDeleteButton: 'none',
                                  OrgListForDelete: [],
                                })
                              }
                            }}
                            columns={[
                              { headerName: this.props.intl.formatMessage({id:"menu.organization"}), field: 'name', width: 500 },
                            ]}
                          />
                        </div>
                      </Grid>
                    </Grid>

                  </AccordionDetails>
                </Accordion>
                </Grid>
                <Grid item xs={12} sm={12}>
                <Accordion style={{ marginTop: "10px" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>{intl.formatMessage({id:"categories"})}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container xs={12} sm={12} >

                      <Grid xs={12} sm={12} style={{ margin: '5px', padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }} >
                        <Grid xs={12} sm={4} >

                        </Grid>
                        <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                          <DialogConfirmation
                            msgButton={intl.formatMessage({id:"technical.team.edit.remove.categories"})}
                            msg={intl.formatMessage({id:"technical.team.edit.remove.category.from.team"})}
                            msgDisagree={this.props.intl.formatMessage({id:"cancel"})}
                            msgAgree={this.props.intl.formatMessage({id:"yes"})}
                            handleConfirmation={e => this.categoryDeleteModal()}
                            classes={classes}
                            icon={<DeleteIcon />}
                          />
                        </Grid>
                        <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            className={classes.button}
                            startIcon={<AddIcon />}
                            onClick={e => this.openCategoryInsertModal()} >
                            {intl.formatMessage({id:"add.categories"})}
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid xs={12} sm={12}
                        style={{
                          marginLeft: 5,
                          marginBottom: 5,
                          border: '3px solid #eee'
                        }} >
                        <div style={{ height: 350 }}>
                          <DataGrid
                            style={{ fontSize: 14, color: '#000' }}
                            className="DataGridStyle"
                            components={{
                              NoRowsOverlay: this.NoDataRows,
                              Toolbar: GridToolbarFilterButton,
                            }}
                            rows={this.state.CategoryList}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            //onEditCellChangeCommitted={this.updateCalledOrganizationMaterialQuantity}
                            onSelectionModelChange={itm => {
                              if (itm.length > 0) {
                                this.setCategoryDelete(itm);
                              } else {
                                this.setState({
                                  //materialFinishCalledDeleteButton: 'none',
                                  CategoryListForDelete: [],
                                })
                              }
                            }}
                            columns={[
                              { headerName: intl.formatMessage({id:"category"}), field: 'ParentNameView', width: 800,  renderCell: (params) => (
                                <div>
                                  <Tooltip title={params.row.ParentNameView} >
                                    <span className="table-cell-trucate">{params.row.ParentNameView}</span>
                                  </Tooltip>
                                  
                                </div>
                              ) },
                            ]}
                          />
                        </div>
                      </Grid>
                    </Grid>

                  </AccordionDetails>
                </Accordion>
                </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleCheckBox label={intl.formatMessage({ id: "active" })} name='active' stateName='active' changeSelect={this.changeValues} selected={item.active} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                  <Grid item xs={12} sm={4}    >
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}

                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                </Grid>
                </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />

            <Dialog
              fullWidth
              maxWidth="md"
              open={this.state.openUserInsertModal}
              onClose={this.closeUserModal}
              aria-labelledby="form-dialog-title-org-mat"
              PaperProps={{
                style: {
                  overflow: 'hidden'
                }
              }}
            >
              <DialogTitle id="form-dialog-title-org-mat">
                {intl.formatMessage({id:"technical.team.edit.new.technicians"})}
                <ClearIcon onClick={this.closeUserModal} style={{ float: 'right', cursor: 'pointer' }} />
              </DialogTitle>
              <DialogContent>
                <Grid item xs={12} sm={12}>
                  <Grid container spacing={4} style={{ padding:"5px" }}>
                    <Grid item xs={12} sm={12} >
                      <SimpleCheckBox label={intl.formatMessage({id:"technical.team.edit.select.all.technician"})} name='selectUsers' stateName='selectUsers' changeSelect={this.changeValues} selected={item.selectUsers} />
                    </Grid>
                    <Grid item xs={12} sm={12} >
                      <SimpleAutoCompleteMultiple
                        label="Técnicos"
                        options={this.state.UserListForSelect}
                        stateName='UserId'
                        changeSelect={this.changeValues}
                        selected={item.UserId} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid spacing={2} container justifyContent="flex-end" style={{ padding: 15 }}>
                  <Grid item xs={12} sm={4}>
                    <Button
                      type="button"
                      onClick={this.closeUserModal}
                      fullWidth >
                      {this.props.intl.formatMessage({id:"cancel"})}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      onClick={this.saveUserInsertModal}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>

            <DialogAddMultiple
              title={intl.formatMessage({ id: "organization.material.registration.new.organizations" })}
              selected={this.state.item.OrganizationId}
              options={this.state.OrgListForSelect}
              checked={this.state.selectOrganizations}
              changeValues={this.changeValues}
              open={this.state.openOrgInsertModal}
              close={this.closeOrgModal}
              save={this.saveOrgInsertModal}
              intl={intl}
              checkboxLabel={intl.formatMessage({ id: "technical.team.edit.select.all.organizations" })}
              checkboxStateName='selectOrganizations'
              autoCompleteLabel={intl.formatMessage({ id: "organization" })}
              autoCompleteStateName="OrganizationId"
            />

            <Dialog
              fullWidth
              maxWidth="md"
              open={this.state.openCategoryInsertModal}
              onClose={this.closeCategoryInsertModal}
              aria-labelledby="form-dialog-title-org-mat"
              PaperProps={{
                style: {
                  overflowX: 'hidden'
                }
              }} 
            >
              <DialogTitle id="form-dialog-title-org-mat">
                {intl.formatMessage({id:"organization.checklist.edit.new.categories"})}
                <ClearIcon onClick={this.closeCategoryInsertModal} style={{ float: 'right', cursor: 'pointer' }} />
              </DialogTitle>
              <DialogContent>
                <Grid item xs={12} sm={12}>
                  <Grid container spacing={4} style={{ padding:"5px" }}>
                    
                    <Grid item xs={12} sm={12} >
                      <SimpleCheckBox label={intl.formatMessage({id:"select.all.categories"})} name='selectCategories' stateName='selectCategories' changeSelect={this.changeValues} selected={item.selectCategories} />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <SimpleAutoCompleteMultiple
                        label={intl.formatMessage({id:"menu.categories"})}
                        options={this.state.CategoryListForSelect}
                        stateName='CategoryId'
                        changeSelect={this.changeValues}
                        selected={item.CategoryId} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid spacing={2} container justifyContent="flex-end" style={{ padding: 15 }}>
                  <Grid item xs={12} sm={4}>
                    <Button
                      type="button"
                      onClick={this.closeCategoryInsertModal}
                      fullWidth >
                      {this.props.intl.formatMessage({id:"cancel"})}
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      onClick={this.saveCategoryInsertModal}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>

          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}
TechinicalTeamEdit.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  techinicalteams: state.techinicalteams
});
export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(TechinicalTeamEdit)));
