import React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { makeStyles } from '@mui/styles';
import { PapiroConsole } from '../../utils/papiroConsole';
import HelpIcon from "@mui/icons-material/Help";
// import { OptionsDialog } from "./optionsDialog";s
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export function ChatInformations({ createdAt }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  //   const [openOptionsDialog, setOpenOptionsDialog] = React.useState(false);
  const anchorRef = React.useRef(null);

  //   const handleOpenDialog = (event) => {
  //       setOpenOptionsDialog(true)
  //       handleClose(event);
  //   }
  //   const handleCloseDialog = () => {
  //       setOpenOptionsDialog(false);
  //   }

  const handleToggle = (e) => {
    e.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    PapiroConsole.log("entrei aqui 5")
    event.stopPropagation();
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      PapiroConsole.log("entrei aqui 6")
      return;
    }
    setOpen(false);
  };
 
 

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const menuItemStyle = {
    color: 'black', // Altere a cor para a desejada
    fontSize: '15px'
  };


  

  return (
    <div className={classes.root}>
     
      <div style={{ display: "flex", alignItems: "center", paddingLeft:'5px' }}>
        <HelpIcon
       
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          style={{ height: "20px", width: "20px", cursor: "pointer",  color:'black' }}
        />
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition style={{ zIndex: 10000 }}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  
                      <MenuItem style={menuItemStyle}  disabled={true}>Chat iniciado em : {createdAt ? moment(createdAt).format('DD/MM/YYYY HH:mm')  : ''}</MenuItem>
                    
                    
                  </MenuList>
                  
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}