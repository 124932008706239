import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleCheckBox from '../../components/checkbox/check';
import SimpleSelect from '../../components/select/simple';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import Title from '../../components/title-name';
import { SketchPicker } from 'react-color';

class StatusRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    if(userSession.user.userrole == null){
      Api.logoff();
    }
    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

 
    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      name : '', 
      finished : false, 
      counttimesla : false,
      defaultchangestatus: false,
      statustypeId: ''
     };         
    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      statustype: []
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  handleChangeComplete = (color) => {
    this.setState(prevState => ({
      item: { 
        ...prevState.item,
        colorhex: color.hex
      }
    }))
  }

  componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

                    componentDidMount() {
      this.setState({ loading: true });
      
      Api.get(`/statuses/StatusType`).then(result => {

      if (result.data.success) {
      this.setState({ loading: false, statustype: result.data.data  });
      }else{
        const intl = this.props.intl;
        this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);
      }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(err);

      });
     };


  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      }


      for (let key in this.state.item) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else {
          data.append(key, this.state.item[key]);
        }
      }

      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      var jsonP = JSON.stringify(data);
      // console.log(jsonP);
      
        Api.post('/statuses', data, config)

        .then(result => {
          if (result.data.success) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"add.success"}),
              item: this.initialState,
              files: []
            });


          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})+err.response.data
            });
          }
          Api.kickoff(err);
        });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    })); 
    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
    render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary'  onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="add.item" />}
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"name"})} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} required />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleSelect options={this.state.statustype} label={intl.formatMessage({id:"type.status"})} stateName='statustypeId' selected={item.statustypeId} changeSelect={this.changeValues} />
                    </Grid>
                  <Grid item xs={12} sm={12}>
                    <SketchPicker
                      color={ item.colorhex }
                      onChangeComplete={ this.handleChangeComplete } />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleCheckBox label={intl.formatMessage({id:"end.call"})} name='finished' stateName='finished' changeSelect={this.changeValues} selected={item.finished} />
                  </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleCheckBox label={intl.formatMessage({id:"conta.tempo.sla"})} name='counttimesla' stateName='counttimesla' changeSelect={this.changeValues} selected={item.counttimesla} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleCheckBox
                        label={intl.formatMessage({ id: "default.change.status" })}
                        name='defaultchangestatus'
                        stateName='defaultchangestatus'
                        changeSelect={this.changeValues}
                        selected={item.defaultchangestatus} />
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                  <Grid item xs={12} sm={4}    >
                    <Button 
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                  </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />                    
      </div>
    );
  }
}
StatusRegistration.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});
export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(StatusRegistration)));
