import { PapiroConsole } from "./papiroConsole";

export const getContactByMessageContent = content => {
  PapiroConsole.log("=== getContactByMessageContent ===")
  PapiroConsole.log(content)
  

  const regexGetName = /Nome: (\w+)/;
  const regexGetPhones = /Phone \d: ([+\d\s-]+)/g;
  
  const getName = content.match(regexGetName);
  const getPhones = content.match(regexGetPhones);

  PapiroConsole.log(getName)
  PapiroConsole.log(getPhones)

  if (getName) {
    PapiroConsole.log("getName")
    const name = getName[1];
    const phones = getPhones ? getPhones.map(item => item.replace(/Phone \d: |\n/g, '')) : [];
    PapiroConsole.log(name)
    PapiroConsole.log(phones)
    return {
      name: name,
      phones: phones
    }
  } else
    return null
} 