import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import AddIcon from '@mui/icons-material/Add';
import { Link, withRouter } from 'react-router-dom';
import Api from '../../services/api';
import MyMaterialTable from '../../components/table/index-report';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from "moment";
import { getAllByCalledIdWithRequestingUserAndStatusInclude } from '../../services/calledrequestuser.service';
import { PapiroConsole } from '../../utils/papiroConsole';
import { Article, Clear } from '@mui/icons-material';
class CalledOrganization extends Component {

    constructor(props) {
        super(props);

        PapiroConsole.log("=== CalledOrganization ===")
        PapiroConsole.log(this.props)

        const { userSession, calleds } = this.props;
        const { id } = this.props.match.params
        this.urlId = id
        this.isClient = true;

        userSession.user.userrole.map((item, key) => {
          if (item.role.roletype != 3) {
            this.isClient = false;
          }
        });
      
        this.state = {
          calledId: id ? id : calleds.called.id,
          loading: false,
          openNotification: false,
          notificationVariant: 'error',
          notificationMessage: '',
          resultList: [],
          strhtmlModalOpen: false,
          dialogHTMLString: ''
        };

        this.closeNotification = this.closeNotification.bind(this);
        this.openHTMLRenderModal = this.openHTMLRenderModal.bind(this);
        this.closeHTMLRenderModal = this.closeHTMLRenderModal.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    async componentDidMount() {
        await this.getItensNew();
    }

    openHTMLRenderModal = (htmlString) => {
        this.setState({ strhtmlModalOpen: !this.state.strhtmlModalOpen, dialogHTMLString: htmlString })
    }

    closeHTMLRenderModal = () => {
        this.setState({ strhtmlModalOpen: !this.state.strhtmlModalOpen, dialogHTMLString: '' })
    }

    async getItensNew() {
        const { intl } = this.props
        this.setState({ loading: true });
        await Api.get(`/performance/calledorganization/called/${this.state.calledId}/history`)

            .then(result => {
                if (result.data.success) {
                    PapiroConsole.log(result.data.data);
                    PapiroConsole.log("result.data.data");
                    const formattedList = result.data.data.map(item => {
                        return {
                            id: item.id,
                            PreviousOrganizationName: item.previousorganization && item.previousorganization.name ? item.previousorganization.name : '',
                            PreviousOrganizationCategoryName: item.previousorganizationcategory && item.previousorganizationcategory.parentname && item.previousorganizationcategory.parentname.length > 0 ? item.previousorganizationcategory.parentname : item.previousorganizationcategory.name,
                            PreviousAddressName: item.previousaddress && item.previousaddress.name ? item.previousaddress.name : '',
                            PreviousTechinicalTeamName: item.previoustechinicalteam && item.previoustechinicalteam.name ? item.previoustechinicalteam.name : '',
                            PreviousAssignedUserName: item.previousassigneduser && item.previousassigneduser.name ? item.previousassigneduser.name : '',
                            PreviousOrganizationDepartamentName: item.previousorganizationdepartament && item.previousorganizationdepartament.name ? item.previousorganizationdepartament.name : '',
                            NextOrganizationName: item.nextorganization && item.nextorganization.name ? item.nextorganization.name : '',
                            NextOrganizationCategoryName: item.nextorganizationcategory && item.nextorganizationcategory.parentname && item.nextorganizationcategory.parentname.length > 0 ? item.nextorganizationcategory.parentname : item.nextorganizationcategory.name,
                            NextAddressName: item.nextaddress && item.nextaddress.name ? item.nextaddress.name : '',
                            NextTechinicalTeamName: item.nexttechinicalteam && item.nexttechinicalteam.name ? item.nexttechinicalteam.name : '',
                            NextAssignedUserName: item.nextassigneduser && item.nextassigneduser.name ? item.nextassigneduser.name : '',
                            NextOrganizationDepartamentName: item.nextorganizationdepartament && item.nextorganizationdepartament.name ? item.nextorganizationdepartament.name : '',
                            DateCreated: moment(item.createdat).format("DD/MM/YYYY HH:mm:ss"),
                            Message: item.reason && item.reason.length > 0 ? <Tooltip title="vizualizar motivo">
                                <IconButton aria-label="textevidence"
                                onClick={ e => this.openHTMLRenderModal(item.reason) }>
                                    <Article />
                                </IconButton>
                            </Tooltip> : '',
                            CreatedUserName: item.createduserdata ? item.createduserdata.name : "",
                            reason: item.reason ? item.reason : ''
                        }
                    });

                    this.setState({ loading: false, resultList: formattedList });
                }
                else {
                    PapiroConsole.log("entrei aqui")
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                PapiroConsole.log("entrei aqui 2 ") 
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });
    };


    closeNotification() {
        this.setState({ openNotification: false });
    }
    render() {
        const { classes } = this.props;
        const intl = this.props.intl;

        return (
            <>
                <Dialog  open={this.state.strhtmlModalOpen} onClose={this.closeHTMLRenderModal} aria-labelledby="customized-dialog-title" style={{border:'1px solid blue'}} sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "800px",
                    },
                },
                }}>
                    <DialogTitle id="form-dialog-title">
                        Motivo
                        <Clear onClick={this.closeHTMLRenderModal}  style={{ float: 'right', cursor : 'pointer'}}/> 
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} sx={{ marginTop: '20px', padding: '20px' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: '10px', backgroundColor: 'rgba(189, 189, 189, .3)', borderRadius: '10px' }}>
                                { this.state.dialogHTMLString }
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                <MyMaterialTable
                    title={intl.formatMessage({ id: "called.organization.history.header" })}
                    columns={[
                      { title: intl.formatMessage({ id: "previous.organization" }), field: "PreviousOrganizationName" },
                      { title: intl.formatMessage({ id: "previous.organization.category" }), field: "PreviousOrganizationCategoryName" },
                      { title: intl.formatMessage({ id: "previous.address" }), field: "PreviousAddressName" },
                      { title: intl.formatMessage({ id: "previous.techinical.team" }), field: "PreviousTechinicalTeamName" },
                      { title: intl.formatMessage({ id: "previous.assigned.user" }), field: "PreviousAssignedUserName" },
                      { title: intl.formatMessage({ id: "previous.organization.departament" }), field: "PreviousOrganizationDepartamentName" },
                      { title: intl.formatMessage({ id: "next.organization" }), field: "NextOrganizationName" },
                      { title: intl.formatMessage({ id: "next.organization.category" }), field: "NextOrganizationCategoryName" },
                      { title: intl.formatMessage({ id: "next.address" }), field: "NextAddressName" },
                      { title: intl.formatMessage({ id: "next.techinical.team" }), field: "NextTechinicalTeamName" },
                      { title: intl.formatMessage({ id: "next.assigned.user" }), field: "NextAssignedUserName" },
                      { title: intl.formatMessage({ id: "next.organization.departament" }), field: "NextOrganizationDepartamentName" },
                      { title: intl.formatMessage({ id:"reason" }), field: 'Message' },
                      { title: intl.formatMessage({ id: "date" }), field: 'DateCreated' },
                      { title: intl.formatMessage({ id: "user" }), field: 'CreatedUserName' }
                    ]}
                    data={this.state.resultList}
                />

                {this.state.loading && (
                    <Overlay>
                        <CircularProgress color='secondary' />
                    </Overlay>
                )}
            </>
        );
    }
}
CalledOrganization.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    userSession: state.userSession,
    calleds: state.calleds,
});

export default injectIntl(withRouter(connect(mapStateToProps, null)(withStyles(Styles)(CalledOrganization))));

CalledOrganization.contextType = AbilityContext;