import { PapiroConsole } from "src/utils/papiroConsole";

export default function integrationservices(state = [], action) {
  switch (action.type) {
    case 'ADD_CURRENT':
      PapiroConsole.log(state)
      PapiroConsole.log(action)
      PapiroConsole.log("action aqui")
      return {
        ...state,
        integrationservice: action.integrationservice,
      }
    default:
      return state;
  }
}
