import Api from './api';

export const getAllByCalledServiceSettingsFileUploadIdWithAttachmentIncludes = async (calledServiceSettingsOrganizationFieldId) => { 
  let data = []
  await Api.get(`/calledservicesettingsorganizationfieldattachment/all/calledservicesettingsorganizationfield/${calledServiceSettingsOrganizationFieldId}/withattachmentincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const deleteItem = async (id) => { 
  let data = []
  await Api.delete(`/calledservicesettingsorganizationfieldattachment/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}