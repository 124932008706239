import { PapiroConsole } from '../utils/papiroConsole';
import Api from './api';

export const addItem = async (formData) => {
  let data = []
  await Api.post(`/whitelabel`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const editItem = async (formData) => {
  let data = []
  await Api.put(`/whitelabel`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getSystemTheme = async () => {
  let data = []
  await Api.get(`/whitelabel/systemtheme`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const deleteLoginImage = async (whiteLabelConfigId) => {
  let data = []
  await Api.put(`/whitelabel/deleteloginimage/${whiteLabelConfigId}`)
    .then(result => {
      if (result.data.success) {
        data = result.data
      } else {
        data = result.errors ? result.errors[0] : null
      }
    })
    .catch(err => {
      data = err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : err.message ? err.message : null
    })
  return data
}