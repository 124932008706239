import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  IconButton,
  LinearProgress,
  CardActionArea
} from '@mui/material';
import InsertChartIcon from '@mui/icons-material/InsertChartOutlined';
import { FormattedMessage, injectIntl } from 'react-intl';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import FilterListIcon from '@mui/icons-material/FilterList';
import { blue } from '@mui/material/colors';
import CachedIcon from '@mui/icons-material/Cached';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import PanToolIcon from '@mui/icons-material/PanTool';
import { PapiroConsole } from '../../../../utils/papiroConsole';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import ListAltIcon from '@mui/icons-material/ListAlt';


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  progress: {
    marginTop: theme.spacing(3)
  }
}));

const CalledsQuantityCards = props => {
  const { className, item, quantity, clearFilters, icon, origin, callSearch, redirectToCalledListWithFilter } = props;
  const intl = props.intl;



  const classes = useStyles();

  const handleCallSearch = async () => {


    if (origin == "called-list") {
      await clearFilters();
      
      var filters = item.tablecustomfilterfields ? item.tablecustomfilterfields : []

      filters.forEach(element => {
        callSearch(element.field, element.value, 1)
      });

    }
    else if (origin == "dashboard") {

      redirectToCalledListWithFilter(item.tablecustomfilterfields ? item.tablecustomfilterfields : [])

    }















  }


  return (
    <Card
      className={clsx(classes.root, className)}
    >

      <CardActionArea /*disabled={disabledCardActionArea}*/ onClick={() => { handleCallSearch() }}>



        <CardContent>
          <Grid
            container
            justify="space-between"

          >

            <Grid item xs={10} >
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                {item.exhibitionname != null && item.exhibitionname != "" ? item.exhibitionname : item.name}
              </Typography>
              <Typography variant="h3">{quantity}</Typography>
            </Grid>
            <Grid item xs={2} >
              <Avatar className={classes.avatar} sx={{ bgcolor: blue[500] }}>
                {icon == "FilterListIcon" &&
                  <FilterListIcon />
                }



              </Avatar>
            </Grid>




          </Grid>
        </CardContent>
      </CardActionArea>


    </Card>
  );
};

CalledsQuantityCards.propTypes = {
  className: PropTypes.string,
  progress: PropTypes.number
};

export default injectIntl(CalledsQuantityCards);
