import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Typography } from '@mui/material';

export default function AlertDialog(props) {

  const handleConfirmation = () => {
    props.handleClose()
    props.handleConfirmation();
  };
  
  return (
    <div>
      <Dialog
        open={true}
        onClose={() => props.handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.msg}</DialogTitle>
        {props.optionToDelete && <DialogContent>
          <Typography variant='h4'>
            {props.optionToDelete}
          </Typography>
        </DialogContent>}
        <DialogActions>
          <Button onClick={() => props.handleClose()} color="primary">
            {props.msgDisagree}
          </Button>
          <Button variant='contained' onClick={handleConfirmation} color="primary" autoFocus>
            {props.msgAgree}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}