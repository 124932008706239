import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    
    setOpen(true);
    //console.log(props.userListForDelete)

    /*if(!(props.userListForDelete && props.userListForDelete.length > 0) ){

      
      if(props.openErrorNotification != null){
        props.openErrorNotification()
      }
      

    }*/

    
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmation = () => {
    setOpen(false);
    props.handleConfirmation();
  };

  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        className={props.classes.button}
        startIcon={props.icon}
        disabled={props.disabled ? props.disabled : false}
        onClick={handleClickOpen} >
        {props.msgButton}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.msg}</DialogTitle>
        
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {props.msgDisagree}
          </Button>
          <Button onClick={handleConfirmation} color="primary" autoFocus>
            {props.msgAgree}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}