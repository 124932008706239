import React, { Component } from "react"
import clsx from 'clsx'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from "prop-types"
import { CssBaseline, CircularProgress, Container, Button, Grid } from "@mui/material"
import { withStyles } from "@mui/styles"
import { Styles } from "../../styles/material-styles"
import { Overlay } from "../../styles/global"
import CustomizedSnackbars from "../../components/material-snackbars"
import HeaderMenu from "../../components/header-menu"
import MyMaterialTable from '../../components/table'
import Api from "../../services/api"
import Pagination from '@mui/material/Pagination';
import * as calledsActions from '../../redux/actions/calleds'
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import Footer from '../../components/footer'
import moment from "moment"
import { FormattedMessage } from 'react-intl'
import SimpleCheckBox from "../../components/checkbox/check";
import { injectIntl } from 'react-intl'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import SimpleDatePicker from '../../components/date-pickers/inline-no-today'
import SimpleAutoComplete from '../../components/auto-complete/autocomplete'
import 'jspdf-autotable'
import LogoMarca from '../../images/new-logo-cmtech.png'
import Divider from '@mui/material/Divider'
import * as ExcelJS from 'exceljs'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ApiReports from "../../services/apireport";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PapiroConsole } from "../../utils/papiroConsole"
import SimpleAutoCompleteMultiple from "../../components/auto-complete/autocomplete-multiple-create";
import fileDownload from 'js-file-download'
import * as TeamsService from '../../services/techinicalteamorganizationcategory.service';

class HoursReported extends Component {

  constructor(props) {
    super(props)

    this.isClient = true
    this.isTec = false;
    this.isAdm = false
    this.isGlobalAdm = false
    this.typingTimeout = null
    this.organizationId = null

    const { userSession, intl } = this.props


    if (userSession == null || userSession.user == null || userSession.user.userrole == null)
      Api.logoff()

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3)
        this.isClient = false
      if (item.role.roletype == 2) {
        this.isTec = true;
      }
      if (item.role.roletype == 1 || item.role.roletype == 4) {
        this.isAdm = true
      }
      if (item.role.roletype == 0) {
        this.isGlobalAdm = true;
      }
    });

    if (userSession.user.organization)
      this.organizationId = userSession.user.organization.id

    this.state = {

      dateStart: moment().subtract(1, 'week').format('YYYY/MM/DD HH:mm:ss'),
      dateEnd: moment().format('YYYY/MM/DD HH:mm:ss'),
      organizationId: this.organizationId,
      rolesFilter: [],
      OrganizationFilter: [],
      TechinicalTeamFilter: { id: 0, name: '' },
      OrgsList: [],
      TechinicalTeamList: [],

      removingInternalAgents: true,
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      showGrid: false,
      calledsList: [],
      totalPages: 0,
      page: 1,
      pageStart: 0,
      pageEnd: 0,
      count: 0,
      searchValue: '',

      showHeader: false,
      RolesList: [],
      agentsList: [],
      agentsFilter: 0,

      columns: [],
      columnsExcell: [],

    }
  }



  getOrganizations = async _ => {
    const { userSession, intl } = this.props
    let data = []
    if (userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
      data = userSession.user.userorganizations
    } else {
      await Api.get('/organizations/tech')
        .then(result => {
          if (result.data.success) {
            data = result.data.data
          } else {
          this.setState({
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }),
            loading: false
          });
        }
        })
        .catch(err => {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          });
        });
    }
    this.setState({ OrgsList: data })
  }
  changeValues = async (stateName, value, text = '') => {
    this.setState({ loading: true })

    if (stateName === 'agentsFilter') {

      this.setState({ [stateName]: value.id });

    }
    else {
      this.setState({ [stateName]: value });
    }





    this.setState({ loading: false })
  };

  Reload = () => {
    const reload = localStorage.getItem('reload')
    if (reload == "true")
      localStorage.setItem('reload', false)
    return null;
  }

  onChangePaginate = (e, page) => {
    this.setState({ page: page })
    this.getCalleds('', page)
  }

  exportPdf = _ => {
    this.getCalledsToPdf()
  }

  setPagination = (count, page) => {
    let totalPage = Math.ceil(count / 10)
    this.setState({ totalPages: totalPage })
    if (count > 0) {
      if (page == 1) {
        if (count < 10)
          this.setState({ pageStart: 1, pageEnd: count })
        else
          this.setState({ pageStart: 1, pageEnd: 10 })
      } else {
        let pageStart = ((page - 1) * 10) + 1
        if (count >= page * 10)
          this.setState({ pageStart: pageStart, pageEnd: page * 10 })
        else
          this.setState({ pageStart: pageStart, pageEnd: count })
      }
    } else
      this.setState({ totalPages: 0, page: 1, count: 0 })
  }



  onFieldChange = event => {
    // Clears the previously set timer.
    clearTimeout(this.typingTimeout)
    // Reset the timer, to make the http call after 475MS (this.callSearch is a method which will call the search API. Don't forget to bind it in constructor.)
    this.typingTimeout = setTimeout(this.callSearch, 475);
    this.setState({ [event.target.name]: event.target.value, page: 1 })
  }

  callSearch = _ => {
    this.getCalleds('', this.state.page)
  }

  getRoles = async _ => {
    const intl = this.props.intl;
    await Api.get('/roles')
      .then(result => {
        if (result.data.success) {
          PapiroConsole.log(result.data)
          PapiroConsole.log("result.data")
          if(result.data.data && result.data.data.length > 0){
            this.setState({ RolesList: result.data.data.filter(c => c.id != 4) })
          }
          
        } else {
          this.setState({
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }),
            loading: false
          });
        }
      })
      .catch(err => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
        });
      });

  }

  getAgentsList = async _ => {
    const intl = this.props.intl;
    await Api.get('/users/list/agents')
      .then(result => {
        if (result.data.success) {
          this.setState({ agentsList: result.data.data })
        } else {
          this.setState({
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }),
            loading: false
          });
        }
      })
      .catch(err => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
        });
      });

  }



  async componentDidMount() {
    this.setState({ loading: true })
    if (this.isGlobalAdm) {
      await this.getRoles()
    }
    await this.getAgentsList()
    await this.getOrganizations()

    await this.getTeams()

    this.setState({ loading: false })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "techinicalteamsreport")
      this.getCalleds(this.props.location.state)
    else if (nextProps.location.state === "assignedcalleds")
      this.getCalleds(nextProps.location.state)
    else if (nextProps.location.state === "reload")
      this.componentDidMount()
  }

  getFile = async () => {

    this.setState({ loading: true });

    var dateStart = moment(this.state.dateStart, 'YYYY-MM-DD');
    var dateEnd = moment(this.state.dateEnd, 'YYYY-MM-DD');



    if (dateStart > dateEnd) {
      const { intl } = this.props
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "report.audit.initial.date.higher.than.end.date" }),
        loading: false
      });
    }
    else {

      var roles = ""
      var organizations = ""
      if (this.state.rolesFilter != null && this.state.rolesFilter.length > 0) {
        let ids = this.state.rolesFilter.map(c => c.id);
        roles = ids.join(',');
      }
      if (this.state.OrganizationFilter != null && this.state.OrganizationFilter.length > 0) {
        let organizationsIds = this.state.OrganizationFilter.map(c => c.id);
        organizations = organizationsIds.join(',');
      }


      PapiroConsole.log("entrei aqui")


      let url = `/report/hours/excel?organizations=${organizations}&techteam=${this.state.TechinicalTeamFilter.id}&roleids=${roles}&agentid=${this.state.agentsFilter}&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}`

      ApiReports.get(url, {
        responseType: 'blob'
      })
        .then((response) => {

          PapiroConsole.log(response)
          PapiroConsole.log("response")

          //   let url = window.URL.createObjectURL(new Blob([response.data]));
          //saveAs(url, 'called_list.csv');

          fileDownload(response.data, `export_hours_${moment(this.state.dateStart).format('YYYY-MM-DD')}_${moment(this.state.dateEnd).format('YYYY-MM-DD')}.zip`);

          this.setState({ loading: false });
        })
        .catch((error) => {
          PapiroConsole.log(error)
          PapiroConsole.log("response erro")
          this.setState({ loading: false });
        });

    }



  }

  getColumns() {


    const intl = this.props.intl;
    var columns =
      [
        { title: intl.formatMessage({ id: "id" }), field: 'called_id' },
        { title: intl.formatMessage({ id: "assigned" }), field: 'user_called' },
        { title: intl.formatMessage({ id: "user.report" }), field: 'user_report' },
        { title: intl.formatMessage({ id: "menu.organization" }), field: 'organization' },
        { title: intl.formatMessage({ id: "time.entry.date" }), field: 'time_entry_date' },
        { title: intl.formatMessage({ id: "type" }), field: 'type' },
        { title: intl.formatMessage({ id: "activity" }), field: 'activity' },
        { title: intl.formatMessage({ id: "profile.name" }), field: 'perfil' },
        { title: intl.formatMessage({ id: "comment" }), field: 'coment' },
        { title: intl.formatMessage({ id: "hours" }), field: 'hours' },
        { title: intl.formatMessage({ id: "running.service.time" }), field: 'total_tempo_atendimento_corrido' },
        { title: intl.formatMessage({ id: "total.interaction.time" }), field: 'total_tempo_interacao' },
        { title: intl.formatMessage({ id: "running.total.interaction.time" }), field: 'total_tempo_interacao_corrido' },
        { title: intl.formatMessage({ id: "sla.service.time" }), field: 'tempo_sla_atendimento' },
        { title: intl.formatMessage({ id: "sla.solution.time" }), field: 'tempo_sla_solucao' },
        { title: intl.formatMessage({ id: "creation.date" }), field: 'created_at' },
        { title: intl.formatMessage({ id: "service.start.date" }), field: 'service_start_date' },
        { title: intl.formatMessage({ id: "solution.date" }), field: 'solution_date' },



      ];

    this.setState({ columns: columns })

  }


  getCalleds = async (filter = '', page = 1) => {

    const intl = this.props.intl;

    this.setState({ loading: true });



    //var moment = require('moment');

    var dateStart = moment(this.state.dateStart, 'YYYY-MM-DD').format('YYYY-MM-DD')
    var dateEnd = moment(this.state.dateEnd, 'YYYY-MM-DD').format('YYYY-MM-DD')

    //var diferencaDias = dateEnd.diff(dateStart, 'd');

    //console.log(diferencaDias);


    if (dateStart > dateEnd) {
      const { intl } = this.props
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "report.audit.initial.date.higher.than.end.date" }),
        loading: false
      });
    }
    /*else if(diferencaDias > 30  ){
        PapiroConsole.log("mais de 30 dias entre as datas")
        this.setState({
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:"mais de 30 dias entre as datas",
          loading: false
        });
    }*/
    else {
      var roles = ""
      var organizations = ""
      if (this.state.rolesFilter != null && this.state.rolesFilter.length > 0) {
        let ids = this.state.rolesFilter.map(c => c.id);
        roles = ids.join(',');
      }
      if (this.state.OrganizationFilter != null && this.state.OrganizationFilter.length > 0) {
        let organizationsIds = this.state.OrganizationFilter.map(c => c.id);
        organizations = organizationsIds.join(',');
      }
      let url = `/report/hours/paginate?page=${page}&organizations=${organizations}&techteam=${this.state.TechinicalTeamFilter.id}&agentid=${this.state.agentsFilter}&roleids=${roles}&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}`

      this.setState({ showGrid: true })

      var writing = ""

      ApiReports.get(url)
        .then(result => {
          if (result.data.success) {
            PapiroConsole.log("result aqui formatted")
            PapiroConsole.log(result.data)
            let count = result.data.count;
            this.setState({ count: count })
            this.setPagination(count, page)

            const formattedList = result.data.data.map(item => {

              return {
                ...item,

              }
            })
            this.setState({ calledsList: formattedList, loading: false })

            this.getColumns()
          } else {
            this.setState({
              loading: false, openNotification: true, notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
            })
            Api.kickoff(result)
          }
        })
        .catch(err => {

          this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          })
          Api.kickoff(err)
        })

    }

  }



  /*getCalledsToPdf = (filter = '') => {
    const intl = this.props.intl;
    this.setState({ loading: true })



    let url = `/techinicalteams/getexcell?org=${this.state.rolesFilter}}&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}`

    Api.get(url)
      .then(async (result) => {
        if (result.data.success) {
          PapiroConsole.log(result)
          PapiroConsole.log("result")
          await this.setState({ showHeader: true })
          const formattedList = result.data.data.map(item => {

            return {
              ...item,
              Name: item.name,
              TotalUsers: item.total_users,
              Organizacoes: item.organizacoes,
            }
          })

          const intl = this.props.intl
          const doc = new jsPDF("p", "mm", "a4")
          const header = document.getElementById('header-pdf')
          let headerCanvas = await html2canvas(header)
          const headerImg = headerCanvas.toDataURL('image/png')
          doc.addImage(headerImg, 'PNG', 0, 0)

          doc.autoTable({
            body: formattedList,
            margin: { top: 30 },
            bodyStyles: { valign: 'middle' },
            styles: { overflow: 'linebreak', columnWidth: 'wrap', fontSize: 7, halign: 'center' },
            theme: 'striped',
            columns: [
              { header: intl.formatMessage({ id: "name" }), dataKey: 'Name' },
              { header: intl.formatMessage({ id: "total.hours" }), dataKey: 'TotalUsers' },
              { header: intl.formatMessage({ id: "menu.organizations" }), dataKey: 'Organizacoes' },


            ],
            columnStyles: {
              Name: {
                columnWidth: 30
              },
              TotalUsers: {
                columnWidth: 30
              },
              Organizacoes: {
                columnWidth: 30
              },

            }
          })

          doc.save("relatorio-times-enviados.pdf")
          this.setState({ loading: false, showHeader: false })
        } else {
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          })
          Api.kickoff(result)
        }
      })
      .catch(err => {
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(err)
      })
  }*/

  handleSubmit = async e => {
    const intl = this.props.intl;
    e.preventDefault();
    this.setState({ loading: true });
    await this.getCalleds(this.props.location.state)
  }

  async getTeams(OrganizationId = 0) {
    var result = null
    if (OrganizationId > 0) {
      result = await TeamsService.getTechinicalTeamsByOrganization(OrganizationId);
    } else {
      result = await TeamsService.getTechinicalTeams()
    }
    if (result.success) {
      this.setState({ TechinicalTeamList: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  closeNotification = _ => {
    this.setState({ openNotification: false });
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props
    const intl = this.props.intl

    PapiroConsole.log(this.state.agentsFilter)
    PapiroConsole.log("this.state.agentsFilter")
    PapiroConsole.log(this.isTec)
    PapiroConsole.log(this.isAdm)

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    const tableList = () => {
      return (
        <>

          <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
            <Accordion >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>{intl.formatMessage({ id: "filters" })}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container style={{ marginBottom: 20 }} spacing={2}>



                  <Grid item xs={12} sm={6} md={4} >
                    <SimpleDatePicker label={intl.formatMessage({ id: "creation.date.initial" })} name='dateStart' stateName='dateStart' changeDate={this.changeValues} currentDate={this.state.dateStart} fullWidth={true} required />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} >
                    <SimpleDatePicker label={intl.formatMessage({ id: "creation.date.end" })} name='dateEnd' stateName='dateEnd' changeDate={this.changeValues} currentDate={this.state.dateEnd} fullWidth={true} required />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} >
                    <SimpleAutoCompleteMultiple label={intl.formatMessage({ id: "organization" })} options={this.state.OrgsList} stateName='OrganizationFilter' changeSelect={this.changeValues} />
                  </Grid>
                  {this.isGlobalAdm &&
                    <Grid item xs={12} sm={6} md={4} >
                      <SimpleAutoCompleteMultiple label={intl.formatMessage({ id: "profile.name" })} options={this.state.RolesList} stateName='rolesFilter' changeSelect={this.changeValues} />
                    </Grid>
                  }

                  <Grid item xs={12} sm={6} md={4} >
                    <SimpleAutoComplete label={intl.formatMessage({ id: "productivity.dashboard.techinical.teams" })} options={this.state.TechinicalTeamList} stateName='TechinicalTeamFilter' selected={this.state.TechinicalTeamFilter} changeSelect={this.changeValues} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} >
                    <SimpleAutoComplete label={intl.formatMessage({ id: "menu.users-agents" })} options={this.state.agentsList} stateName='agentsFilter' changeSelect={this.changeValues} />
                  </Grid>


                  {/*<Grid item xs={12} sm={6} md={4}>
                    <SimpleCheckBox label={intl.formatMessage({ id: "removing.internal.agents" })} name='removingInternalAgents' stateName='removingInternalAgents' changeSelect={this.changeValues} selected={this.state.removingInternalAgents} />
      </Grid>*/}
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Grid container style={{ marginBottom: 10 }}>
              <Grid item xs={12} sm={2} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='secondary' >
                  {<FormattedMessage id="filter" />}
                </Button>
              </Grid>

              {this.state.showGrid && <Grid item xs={12} sm={5} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                <Button
                  fullWidth
                  variant='contained'
                  color='secondary'
                  onClick={this.getFile} >
                  {<FormattedMessage id="export.plan" />}
                </Button>
              </Grid>}

              {5 == 2 && this.state.showGrid && <Grid item xs={12} sm={5} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                <Button
                  fullWidth
                  variant='contained'
                  color='secondary'
                  onClick={this.getCalledsToPdf} >
                  {<FormattedMessage id="dash.pdf" />}
                </Button>
              </Grid>}
            </Grid>
          </form>

          {
            this.state.showGrid && <>
              <MyMaterialTable
                title=""
                columns={
                  this.state.columns
                }
                data={this.state.calledsList} />

              <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                <Grid item xs={12} md={3}>
                  <Pagination count={this.state.totalPages} page={this.state.page} onChange={this.onChangePaginate} />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                  {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                </Grid>
              </Grid>


            </>
          }
        </>
      );
    }

    return (
      <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })} >
          <div className={classes.drawerHeader} />
          <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft: 0, marginLeft: 0 }}>
            <CssBaseline />
            {this.state.showHeader &&
              <Grid container>
                <Grid id="header-pdf" item lg={4} sm={12} xl={this.state.grid} xs={12} style={{ padding: '2px', minWidth: 600 }}>
                  <Card >
                    <CardContent>
                      <img src={LogoMarca} style={{ width: "70px" }} /> <span style={{ marginLeft: 25, fontSize: 18 }}> {<FormattedMessage id="calleds.origin.categories.report" />}</span>
                      <Divider />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            }

            <Grid container spacing={6}>
              <Grid item xs={12} sm={12}>
                <Card >
                  <CardContent className="background-title-search">
                    <Typography variant="h5">
                      {<FormattedMessage id="hours.reported" />}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>


            {tableList()}


            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification} />
          </Container>
        </main>
        <Footer />
      </div>
    )
  }
}

HoursReported.propTypes = {
  classes: PropTypes.object.isRequired
}


const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(calledsActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(HoursReported)))
HoursReported.contextType = AbilityContext