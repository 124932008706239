import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import {  Grid } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormattedMessage,injectIntl } from 'react-intl';

import SimpleAutoComplete from '../../components/auto-complete/autocomplete'
import { PapiroConsole } from '../../utils/papiroConsole';



function QuickCalledDialog(props) {

  const intl = props.intl;

  const [organizationFilter, setOrganizationFilter] = React.useState(null);
  const [organizationList, setOrganizationList] = React.useState(props.orgList ? props.orgList : [] );
  

  useEffect(() => {
    if(organizationList.length == 1){
      setOrganizationFilter(organizationList[0])
    }
  }, [organizationList]);

  const changeValues = (stateName, value, text = '') => {


    setOrganizationFilter(value)

    
  };

  return (
    <div>
      <Dialog
        open={props.QuickCalledModalState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{intl.formatMessage({id:"quick.called.dialog.title"})}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {intl.formatMessage({id:"quick.called.dialog.info"})}
          </DialogContentText>

          <Grid item xs={12} style={{marginTop:'15px'}}>
              <SimpleAutoComplete label={intl.formatMessage({ id: "organization" })} options={organizationList} stateName='organizationFilter' changeSelect={changeValues} selected={organizationFilter} />
          </Grid>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.openOrCloseModalFunction()} color="primary" >
            {intl.formatMessage({id:"cancel"})}
          </Button>
          <Button onClick={() => { props.handleConfirmation(organizationFilter) }} variant="contained" color="primary" autoFocus>
            {intl.formatMessage({id:"confirm"})}
          </Button>
        </DialogActions>
      </Dialog>
      
    </div>
  );
}

export default injectIntl(QuickCalledDialog);