import Api from './api';
export const getWorkHours = async () => { 
  let data = [];
  await Api.get('/workhours')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};
