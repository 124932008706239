import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { withStyles } from '@mui/styles'
import { Card, CardContent, Dialog, DialogContent, DialogTitle } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { Styles } from '../../../styles/material-styles'
import MiniMap from '../../../components/minimap/external-state';

function AccompanimentLocationDialog(props) {

  const { close, label } = props;

  return (
    <Dialog
      open={props.open}
      onClose={close}
      aria-labelledby="form-dialog-title"
      maxWidth={705} >
      <DialogTitle id="form-dialog-title">
        {label}
        <ClearIcon onClick={close} style={{ float: 'right', cursor: 'pointer' }} />
      </DialogTitle>
      <DialogContent>
        <Card style={{
          width: 705, minWidth: 705, maxWidth: 705,
          height: 400, minHeight: 400, maxHeight: 400
        }}>
          <CardContent>
            <MiniMap
              style={{
                width: 705, minWidth: 705, maxWidth: 705,
                height: 400, minHeight: 400, maxHeight: 400
              }}
              lat={props.positionAccompCalled[0]}
              long={props.positionAccompCalled[1]}
              address={props.addressAccompCalled} />
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  )
}

AccompanimentLocationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  positionAccompCalled: PropTypes.array.isRequired,
  addressAccompCalled: PropTypes.string.isRequired,
}

export default injectIntl(withStyles(Styles)(AccompanimentLocationDialog))