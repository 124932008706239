import React from 'react'
import * as componentActions from '../../../../redux/actions/chats' // trocar pelo redux que criarei no chat
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Styles } from '../../../../styles/material-styles'
import { makeStyles, withStyles } from '@mui/styles'
import './styles.css'
import { Button, Grid } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import SimpleSelectWithColor from './simple-with-color'
import StopIcon from '@mui/icons-material/Stop'
import ReplyIcon from '@mui/icons-material/Reply'
import SimpleAutoCompleteSearch from '../../../../components/auto-complete/autocomplete-search-api';
import Api from "../../../../services/api";
import ChatHeaderUserActionbar from './header-user-action-bar'
import clsx from 'clsx';
import { PapiroConsole } from '../../../../utils/papiroConsole'
import SimpleSelectWithConfirmationColor from './simple-with-confirmation-color'
import ChatIcon from '@mui/icons-material/Chat';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const useStyles = makeStyles((theme) => ({
  chatInfo: {
    width: '70%',
    '@media (max-width:599.9px)': {
      width: '50%',
    },
    '@media (min-width:600px)': {
      width: '70%',
    }
  }
}));

function ChatHeader(props) {
  const classes = useStyles();
  PapiroConsole.log("props aqui 25")
  PapiroConsole.log(props)
  const { intl } = props;
  return (<div className="omnichannel-chat-header">
    <Grid container spacing={0} >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div className="omnichannel-chat-header-chat-info-root">
          {(props.chats && props.chats.chat && props.chats.chat.id && props.chats.chatscount > 0) && <div className={clsx('omnichannel-chat-header-chat-info', classes.chatInfo)}>
            <AccountCircleIcon className='omnichannel-chat-header-chat-info-icon' sx={{ width: '50px', height: '50px' }} />&nbsp; {props.chats.chat?.customer?.name}&nbsp; {props.chats.chat?.organizationdepartament?.name && <span>&nbsp;|&nbsp;</span>} {props.chats.chat?.organizationdepartament?.name ?? ''} &nbsp;
            <Stack direction="row" spacing={1}>
              <Chip icon={<ChatIcon sx={{ fontSize: 16 }} />} label={"Id: #" + props.chats.chat.id} sx={{
                borderRadius: '4px', // Define a borda retangular
              }} color="primary" />

            </Stack>
          </div>}
          <div className="omnichannel-chat-header-user-action-bar">
            {(props.chats && props.chats.chat && props.chats.chat.id) && <ChatHeaderUserActionbar />}
            {(props.chats.chat?.messageproviderid == 2) && <WhatsAppIcon sx={{ color: '#59BC6B' }} />}
            {(props.chats.chat?.messageproviderid == 3) && <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
              <radialGradient id="yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1" cx="19.38" cy="42.035" r="44.899" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fd5"></stop><stop offset=".328" stop-color="#ff543f"></stop><stop offset=".348" stop-color="#fc5245"></stop><stop offset=".504" stop-color="#e64771"></stop><stop offset=".643" stop-color="#d53e91"></stop><stop offset=".761" stop-color="#cc39a4"></stop><stop offset=".841" stop-color="#c837ab"></stop></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><radialGradient id="yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2" cx="11.786" cy="5.54" r="29.813" gradientTransform="matrix(1 0 0 .6663 0 1.849)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#4168c9"></stop><stop offset=".999" stop-color="#4168c9" stop-opacity="0"></stop></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><path fill="#fff" d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"></path><circle cx="31.5" cy="16.5" r="1.5" fill="#fff"></circle><path fill="#fff" d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"></path>
            </svg>}
            {(props.chats.chat?.messageproviderid == 4) && <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
              <path fill="#448AFF" d="M24,4C13.5,4,5,12.1,5,22c0,5.2,2.3,9.8,6,13.1V44l7.8-4.7c1.6,0.4,3.4,0.7,5.2,0.7c10.5,0,19-8.1,19-18C43,12.1,34.5,4,24,4z"></path><path fill="#FFF" d="M12 28L22 17 27 22 36 17 26 28 21 23z"></path>
            </svg>}
            {(props.chats.chat?.messageproviderid == 5) && <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
              <path fill="#29b6f6" d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z"></path><path fill="#fff" d="M33.95,15l-3.746,19.126c0,0-0.161,0.874-1.245,0.874c-0.576,0-0.873-0.274-0.873-0.274l-8.114-6.733 l-3.97-2.001l-5.095-1.355c0,0-0.907-0.262-0.907-1.012c0-0.625,0.933-0.923,0.933-0.923l21.316-8.468 c-0.001-0.001,0.651-0.235,1.126-0.234C33.667,14,34,14.125,34,14.5C34,14.75,33.95,15,33.95,15z"></path><path fill="#b0bec5" d="M23,30.505l-3.426,3.374c0,0-0.149,0.115-0.348,0.12c-0.069,0.002-0.143-0.009-0.219-0.043 l0.964-5.965L23,30.505z"></path><path fill="#cfd8dc" d="M29.897,18.196c-0.169-0.22-0.481-0.26-0.701-0.093L16,26c0,0,2.106,5.892,2.427,6.912 c0.322,1.021,0.58,1.045,0.58,1.045l0.964-5.965l9.832-9.096C30.023,18.729,30.064,18.416,29.897,18.196z"></path>
            </svg>}
            {(props.chats.chat?.messageproviderid == 6) && <ChatIcon sx={{ color: '#277afe' }} />}
            {/* (props.chats && props.chats.chat == null) && <div style={{width:'100%'}} className="omnichannel-chat-header-chat-info"><AccountCircleIcon sx={{ width: '50px', height: '50px' }} />
                &nbsp; <SimpleAutoCompleteSearch sendfrom2talk={true} style={{width:'100%'}} label={ intl.formatMessage({ id: "keypress.search.instruction" })+" "+intl.formatMessage({ id: "requestuser" })+" (min 5 caracteres)"} options={props.customerList} stateName="customerid" 
                changeSelect={props.changeSelect} freesolo={true} selectedText={props.customerid} loading={props.loadingcustomerid} urlapi={Api.apiurlcone()+'api/customer/name/phone'}  />
              </div>*/}
          </div>
        </div>
      </Grid>
      {props.isGlobalAdm ? <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="omnichannel-chat-header-attendance-info">
        <div className='omnichannel-chat-header-attendance-info-container' >
          {(props.chats && props.chats.chat && props.chats.chat.id) && <SimpleSelectWithConfirmationColor
            label=""
            options={props.statusList}
            changeSelect={props.changeSelect}
            newStatusValue={props.newStatusValue}
            stateName="statusupdate"
            selected={props.chats.chat && props.chats.chat.chatstatusid ? props.chats.chat.chatstatusid : 0} />}
        </div>
        <div className='omnichannel-chat-header-attendance-info-buttons-container'>
          {(props.chats && props.chats.chat && props.chats.chat.id) && <Button
            onClick={() => props.openOrCloseTransferChatModal()}
            variant="text"
            sx={{ color: '#000', textTransform: 'none', border: '1px solid rgba(0, 0, 0, .4)' }}
            startIcon={<ReplyIcon sx={{ color: '#32B847', transform: 'rotate(180deg)' }} />} >Transferir</Button>}
          {(props.chats && props.chats.chat && props.chats.chat.id) && <Button
            onClick={() => props.openOrCloseEndChatModal()}
            variant="text"
            sx={{ color: '#000', textTransform: 'none', marginLeft: '15px', border: '1px solid rgba(0, 0, 0, .4)' }}
            startIcon={<StopIcon sx={{ color: '#CE1B1B' }} />} >Finalizar</Button>}
        </div>
      </Grid>
        : null

      }

    </Grid>
  </div>)
}

ChatHeader.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  chats: state.chats,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ChatHeader)))