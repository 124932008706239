import React, { Component } from 'react';
import clsx from 'clsx';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever, AttachFile, CompassCalibrationOutlined } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Checkbox from '@mui/material/Checkbox';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleTreeView from '../../components/tree-view/tree';
import SimpleCheckBox from '../../components/checkbox/check';
import * as Service from '../../services/category.service';
import * as componentActions from '../../redux/actions/categories';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import Title from '../../components/title-name';
import * as FileService from '../../services/attachment.service';
import AttachFileIcon from '@mui/icons-material/AttachFile';


class CategoryEdit extends Component {

  constructor(props) {
    super(props);
    const { userSession, categories } = this.props;

   

    this.globalAdm = false;
    this.isClient = true;
    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }

    if(!categories || (categories && !categories.category)) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/calleds`
    }

    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

 
    this.initialState = {
      updatedUser: userSession.user.id,
      id: categories.category.id,
      parentCategoryId: categories.category.parentCategoryId,
      parentCategoryText: categories.category.parentCategoryText,
      name: categories.category.name,
      cancreatecalled: categories.category.cancreatecalled,
      imageicon: categories.category.imageicon,
    };
    // console.log(this.initialState);
    this.state = {
      category: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      categoriesList: [],
      
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }

  componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

                    componentDidMount() {
    this.getCategories();
  };

  async getCategories() {
    this.setState({ loading: true });

    var result = await Service.getAllCategories();

    if (result.success) {
        this.setState({ loading: false, categoriesListAll: result.data });
    } else {
      const intl = this.props.intl;
        this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
    }
    
    result = await Service.getCategories();

    if (result.success) {
      this.setState({ loading: false, categoriesList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
      });
    }
  };


  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      category: {
        ...prevState.category,
        [name]: value
      }
    }));
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

  

    let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
          category: {
            ...prevState.category,
          }
        }));
      } else {
        this.setState(prevState => ({
          category: {
            ...prevState.category,
          }
        }));
      }

      this.state.files.forEach(file => data.append('filesUpload', file));

      for (let key in this.state.category) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.category[key]).format("YYYY/MM/DD HH:mm:ss"));
        } else {
          data.append(key, this.state.category[key]);
        }
      }

      let config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };

      Api.put("/categories", data, config)
        .then(result => {
          if (result.data.success) {
            const formattedList = result.data.data;
            formattedList.parentCategoryText= formattedList.parentcategory ? formattedList.parentcategory.name : "";
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "success",
              notificationMessage: intl.formatMessage({id:"edit.success"}),
              files: []
            });
            this.props.addCurrent(formattedList);
            this.componentDidMount();
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage:  result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({id:"process.error"})
            });

          }
        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] :intl.formatMessage({id:"process.error"}) +":"+JSON.stringify(err)
            });
          }
        });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      category: {
        ...prevState.category,
        [stateName]: value
      }
    }));

    if(stateName === "parentCategoryId"){
      this.setState(prevState => ({
        category: {
          ...prevState.category,
          ["parentCategoryText"]: text,
        }
      }));
    }
    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  getFileName(fileName){
    var file= fileName.substring(fileName.lastIndexOf('/')+1);
    return file;
  }

  async getFile(hirerid, fileName,filerepositorymethod) {
    //window.open(`${Api.apiurl()}/file/download/${this.getFileName(fileName)}/icons/${this.state.category.id}`, '_blank');


	const intl = this.props.intl;
	this.setState({ loading: true });
	
	
	let url = await FileService.getFileByPath(hirerid,fileName,filerepositorymethod); 
	//alert(JSON.stringify(url.data));
	this.setState({ loading: false });
	if(url &&  url.data && url.data.data && url.data.success){
		window.open(`${url.data.data}`, '_blank');
	}
	else{
		this.setState({
			loading: false, openNotification: true, notificationVariant: "error",
			notificationMessage: intl.formatMessage({ id: "file.not.found" })
		});

	}	



  };

  render() {
    const { classes, headerMenu, actionsHeaderMenu, categories } = this.props;
    const { category } = this.state;
    
    const intl = this.props.intl;
    //console.log(this.state.categoriesList);

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    if(this.state.categoriesListAll){
      var categoriesId="'0',"+this.state.categoriesListAll.map(item => `'${item.id}'`).join(",");  
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant="outlined" color="primary" onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component="h1" variant="h5">
                {<FormattedMessage id="edit.item" />}
                <Title />
              </Typography>
              <form name="myForm" className={classes.form} onSubmit={this.handleSubmit} encType="multipart/form-data">
                <Grid container spacing={2}>
                {!this.isClient && (<>
                    <Grid item xs={12} sm={12}>
                        <SimpleTreeView rootId={categoriesId} allowSelect="true" label={intl.formatMessage({id:"menu.categories"})} parentLabel={intl.formatMessage({id:"select.category.parent"})} options={this.state.categoriesList} stateName="parentCategoryId" changeSelect={this.changeValues} selected={category.parentCategoryId} selectedText={category.parentCategoryText} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                            <TextField
                              name="categoria"
                              variant="outlined"
                              fullWidth
                              id="categoria"
                              label={intl.formatMessage({id:"select.category.above"})}
                              value={category.parentCategoryText}
                              required
                              readyonly="true"
                              disabled
                            />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        autoComplete="fname"
                        name="name"
                        variant="outlined"
                        fullWidth
                        id="name"
                        label={intl.formatMessage({id:"name"})}
                        onChange={this.handleInputChange}
                        value={category.name}
                        required
                      />
                    </Grid>
                </>)}
                  <Grid item xs={12} sm={12}>
                    <SimpleCheckBox label={intl.formatMessage({id:"organization.category.create.call"})}  stateName="cancreatecalled" changeSelect={this.changeValues} selected={category.cancreatecalled} />
                  </Grid>
                <Grid item xs={12} sm={12}>
                  <section style={{ width: '100%' }}>
                    <div className={classes.dropzoneArea} style={{minHeight:'70px',marginTop:'5px '}}>
                      <Dropzone maxFiles={1} onDrop={acceptedFiles => { this.setState({ files: [ ...acceptedFiles, ...this.state.files ] }) }}>
                        {({ getRootProps, getInputProps, acceptedFiles }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <AttachFileIcon  />
                            <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                                <FormattedMessage  id="drag.and.drop" />
                            </Typography>
                          </div>
                        )}
                      </Dropzone>
                      {this.state.files.map(file => {
                        return (
                          <div className={classes.dropzoneItem} key={file.name}>
                            {file.name}
                            <DeleteForever className={classes.deleteItem} onClick={() => { this.removeItem(file) }} />
                          </div>
                        )
                      })}
                    </div>
                  </section>
                </Grid>
                {categories.category.imageicon != null && categories.category.imageicon != "null" && categories.category.imageicon != "undefined" && (
                            <Grid item xs={12}>
                                    <label>ícone</label>
                                        <ul>
                                                <li key={categories.category.imageicon}>
                                                    <AttachFile /> <Button onClick={() => this.getFile(categories.category.hirerid,categories.category.imageicon,categories.category.filerepositorymethod)}>{this.getFileName(categories.category.imageicon)}</Button>
                                                </li>
                                        </ul>
                            </Grid>
                    )}
                <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={4}    >
                        <Button 
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit}
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />                    
     </div>
    );
  }
}

CategoryEdit.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  categories: state.categories

});

const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps,mapDispatchToProps)(withStyles(Styles)(CategoryEdit)));
