import React, { useState, useEffect } from "react";
import "./editor.css";
import "tui-image-editor/dist/tui-image-editor.css";
import ImageEditor from "@toast-ui/react-image-editor";
import {  Button} from '@mui/material';
import  { locale_pt_BR } from "./locale-pt-br";
import  { whiteTheme } from "./white-theme";
import PropTypes from "prop-types";
import Image from '../../images/blank.png';
import { Grid } from '@mui/material';
import Api from "../../services/api";
const icona = require("tui-image-editor/dist/svg/icon-a.svg");
const iconb = require("tui-image-editor/dist/svg/icon-b.svg");
const iconc = require("tui-image-editor/dist/svg/icon-c.svg");
const icond = require("tui-image-editor/dist/svg/icon-d.svg");
const download = require("downloadjs");
//whiteTheme
const myTheme = {
  "menu.backgroundColor": "white",
  "common.backgroundColor": "#151515",
  "downloadButton.backgroundColor": "white",
  "downloadButton.borderColor": "white",
  "downloadButton.color": "black",
  "menu.normalIcon.path": icond,
  "menu.activeIcon.path": iconb,
  "menu.disabledIcon.path": icona,
  "menu.hoverIcon.path": iconc,
  'common.backgroundColor': 'transparent',
  'range.value.backgroundColor': 'transparent',
  'range.disabledBar.color': '#fff',

};
function Editor(props) {
  const intl = props.intl;
  
  const [imageSrc, setImageSrc] = useState(props.image != null ? props.image : '');
  const imageEditor = React.createRef();
  //console.log(props);



  React.useEffect(() => { 
        //console.log('aqui100');
        //console.log(props.image);
        if(props.image !=  null && props.image !=  ''){
            setImageSrc(props.image);

            //console.log(props.image);
            //imageEditor.loadImageFromFile(file).then(result => {

            const imageEditorInst = imageEditor.current.imageEditorInst;

            imageEditorInst.loadImageFromURL = (function() {
                var cached_function = imageEditorInst.loadImageFromURL;
                function waitUntilImageEditorIsUnlocked(imageEditorInst) {
                    return new Promise((resolve,reject)=>{
                        const interval = setInterval(()=>{
                            if (!imageEditorInst._invoker._isLocked) {
                                clearInterval(interval);
                                resolve();
                            }
                        }, 100);
                    })
                }
                return function() {
                    return waitUntilImageEditorIsUnlocked(imageEditorInst).then(()=>cached_function.apply(this, arguments));
                };
             })();
            

            //imageEditorInst.loadImageFromURL(props.image,'image').then(result => {
                //console.log('old : ' + result.oldWidth + ', ' + result.oldHeight);
                //console.log('new : ' + result.newWidth + ', ' + result.newHeight);
                
            //}); 
            
            imageEditorInst.loadImageFromURL(props.image, "image").then(result=>{
                imageEditorInst.ui.resizeEditor({
                    imageSize: {oldWidth: result.oldWidth, oldHeight: result.oldHeight, newWidth: result.newWidth, newHeight: result.newHeight},
                });
            }).catch(err=>{
                console.error("Something went wrong:", err);
            });            

        }

  },[props.image ]);

  const saveImageToDisk = () => {
    props.loadingOpen();
    const imageEditorInst = imageEditor.current.imageEditorInst;
    const data = imageEditorInst.toDataURL();
    if (data) {
      //download(data, `image.${extension}`, mimeType);
      let dataSet = new FormData();
      dataSet.append('CalledId', props.calledId);
      dataSet.append('UserActionId', props.userId);
      dataSet.append('FilesUpload', dataURItoBlob(data), "image.jpg");

      let config = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
      };
      
      Api.post("/accompaniments/accompanimentEditorImage", dataSet, config)
      .then(result => {
          if (result.data.success) {
            props.loadingClose();
          }
      })
      .catch(err => {
        
          this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
          });
      });
    }
  };
  
  const dataURItoBlob = (dataURI) => {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
  }

  const closeHandler  = (e) => {
    if (typeof props.close === 'function') {
        //console.log('close1');
        props.close();
    }
  }
  const loadingOpenHandler  = (e) => {
    if (typeof props.close === 'function') {
        //console.log('close1');
        props.loadingOpen();
    }
  }
  const loadingCloseHandler  = (e) => {
    if (typeof props.close === 'function') {
        //console.log('close1');
        props.loadingClose();
    }
  }

  return (
    <Grid container>
      <Grid item xs={11}>
    <div className="home-page">
      <div>
        <h1>Edição de Imagem</h1>
        <Button style={{ marginLeft: "auto" }} onClick={saveImageToDisk}>Salvar imagem no chamado</Button>
        <Button onClick={closeHandler}>{intl.formatMessage({id:"close"})}</Button>
      </div>
      <ImageEditor
        includeUI={{
          loadImage: {
            path: Image,
            name: "image",
          },
          locale: locale_pt_BR,
          theme: whiteTheme,
          menu: ["crop", "flip", "rotate", "draw", 
          "shape","icon", "text", "filter"],
          initMenu: "",
          uiSize: {
            height: `calc(100vh - 160px)`,
          },
          menuBarPosition: "bottom",
        }}
        cssMaxHeight={window.innerHeight}
        cssMaxWidth={window.innerWidth}
        selectionStyle={{
          cornerSize: 20,
          rotatingPointOffset: 70,
        }}
        usageStatistics={false}
        ref={imageEditor}
      />
    </div>
    </Grid>
    </Grid>
  );
}

Editor.propTypes = {
    close: PropTypes.func.isRequired,
    loadingOpen: PropTypes.func.isRequired,
    loadingClose: PropTypes.func.isRequired,
};
  
  
export default Editor;