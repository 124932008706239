import React, { Component, Fragment } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as componentActions from '../../redux/actions/calledorganizationchecklists';
import { bindActionCreators } from 'redux';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
} from "@mui/material";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import CustomizedSnackbars from "../../components/material-snackbars";
import { connect } from "react-redux";
import AssignmentIcon from '@mui/icons-material/Assignment';

class ExternalCalledReport extends Component {
  constructor(props) {
    super(props);
    this.state = {

      calledReport: {
        token : "",
        calledId : "",
        email : ""
      },
      
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      userLogged: false,
    };

    const { history } = this.props;
    this.history = history

    this.handleInputChange = this.handleInputChange.bind(this);
  
    this.closeNotification = this.closeNotification.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    
    //this.getCalled = this.getCalled.bind(this);
  }

  /*componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }*/

  async componentDidMount() {
    /*if (this.props.match.params.token) {
      this.getUser(this.props.match.params.token);
    }*/

    //await this.getCalled()
    //await this.getCalled()
    //console.log("result")
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

      this.setState((prevState) => ({
        calledReport: {
          ...prevState.calledReport,
          [name]: value,
        },
      }));
  }

 /* async getCalled() {
    // console.log(token);
    this.setState({ loading: true });
    await Api.get(`/calleds/reportCalled/51661/8817`)
      .then((result) => {
        if (result.data.success) {
          
        } else {
        }
      })
      .catch((err) => {
        // console.log(err);
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            err.response && err.response.data && err.response.data.errors
              ? err.response.data.errors[0]
              : this.props.intl.formatMessage({id:"process.error"}),
        });
       
        // console.log("entrei aqui");
      });

      this.setState({ loading: false });
  }
*/
  

  handleSubmit = async (e) => {
    const intl = this.props.intl;
    e.preventDefault();

    this.setState({ loading: true });

    const { calledId, token, email } = this.state.calledReport;
    var Id = parseInt(calledId)

    Api.post("users/reportCalled", { Id, token, email })
        .then((result) => {
          if (result.data && result.data.success && result.data.data) {
            if(result.data.data.length == 0){
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({id:"incorrect.informations"})
              });
            }else{
              result.data.data.email = email
              
              this.setState({
                loading: false,
              });

              this.props.history.push({
                pathname: `/pt/called-report-information`,
                state: result.data.data
              })
            }
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : this.props.intl.formatMessage({id:"process.error"}),
          });
        });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  render() {
    const { classes } = this.props;
    const { calledReport } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            {" "}
            <AssignmentIcon />{" "}
          </Avatar>
          <Typography component="h1" variant="h5">
            {" "}{intl.formatMessage({id:"calleds.report"})}{" "}
          </Typography>
          <form className={classes.form} onSubmit={this.handleSubmit}>

          <TextField
              margin="normal"
              variant="outlined"
              required
              fullWidth
              id="email"
              placeholder="Email"
              name="email"
              value={calledReport.email}
              onChange={this.handleInputChange}
        
           
            />
            <TextField
              margin="normal"
              variant="outlined"
              required
              fullWidth
              id="calledId"
              placeholder={intl.formatMessage({id:"tab.called.report.called.number"})}
              name="calledId"
              value={calledReport.calledId}
              onChange={this.handleInputChange}
        
           
            />
            <TextField
              margin="normal"
              variant="outlined"
              required
              fullWidth
              name="token"
              placeholder={intl.formatMessage({id:"access.key"})}
              id="token"
              inputProps={{ maxLength: 5 }}
              value={calledReport.token}
              onChange={this.handleInputChange}
            />
            
           
        
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              id="submit"
            >
              {this.props.intl.formatMessage({id:"confirm"})}
            </Button>
          </form>
        </div>

        

        {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}

        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification}
        />
      </Container>
    );
  }
}

ExternalCalledReport.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userSession: state.userSession,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);;

//export default connect(mapStateToProps)(withStyles(Styles)(ExternalCalledReport));
export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ExternalCalledReport))));