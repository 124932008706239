import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import { Grid, CircularProgress } from '@mui/material';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
  Typography
} from '@mui/material';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import RefreshIcon from '@mui/icons-material/Refresh';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import { FormattedMessage, injectIntl } from 'react-intl';
import { colors } from '@mui/material';
import {
  PieChart, Pie, Sector, Cell,
} from 'recharts';
import { PapiroConsole } from '../../../../utils/papiroConsole';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '250px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));

const COLORS = [, '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#16A5A5', '#009CE0', '#AEA1FF', '#FDA1FF', '#D33115', '#7B64FF', '#FA28FF', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#00a1e4', '#653294', '#AB149E'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" fontSize={outerRadius > 150 ? 24 : 12}>
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const UsersByDevice = props => {
  const { className, chart, status, dataValues, dataNames, dataColors, expand } = props;

  const classes = useStyles();
  const theme = useTheme();

  const data = {
    datasets: [
      {
        data: dataValues,
        backgroundColor: [
          theme.palette.primary.main,
          theme.palette.secondary.main,
          colors.brown[600],
          theme.palette.success.main,
          theme.palette.error.main,
          theme.palette.warning.main,
          theme.palette.black,
          colors.amber[600],
          colors.teal[600],
          colors.purple[600]
        ],
        borderWidth: 8,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white
      }
    ],
    labels: dataNames
  };

  const options = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary
    }
  };

  const intl = props.intl;


  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title={intl.formatMessage({ id: "called.status" })}
      />
      <Divider />
      <CardContent>

        <div className={classes.stats}>
          <Grid container justifyContent='center'>
            <Grid item xs={9} container justifyContent={expand ? 'center' : 'start'}>
              <PieChart width={expand ? 800 : 300} height={expand ? 750 : 250}>
                <Pie
                  data={chart}
                  cx={expand ? 400 : 150}
                  cy={expand ? 375 : 100}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={expand ? 300 : 100}
                  fill="#8884d8"
                  dataKey="value" >
                  {
                    chart.map((entry, index) => <Cell key={`cell-${index}`} fill={dataColors[index]} />)
                  }
                </Pie>
              </PieChart>
            </Grid>
            {expand ?
              <Grid item container xs={3} md={3}>
                {status.map(status => (
                  <Grid item xs={4}>
                    <div
                      className={classes.device}
                      key={status.statusname}
                    >
                      <Typography variant="body1">{status.statusname}</Typography>
                      <Typography
                        style={{ color: theme.palette.primary.main }}
                        variant="h3"
                      >
                        {status.percent}%
                      </Typography>
                    </div>
                  </Grid>
                ))}
              </Grid>
              :
              status.map(status => (
                <Grid item xs={6} md={4}>
                  <div
                    className={classes.device}
                    key={status.statusname}
                  >
                    <Typography variant="body1">{status.statusname}</Typography>
                    <Typography
                      style={{ color: theme.palette.primary.main }}
                      variant="h3"
                    >
                      {status.percent}%
                    </Typography>
                  </div>
                </Grid>
              ))}
          </Grid>


        </div>
      </CardContent>
    </Card>
  );
};

UsersByDevice.propTypes = {
  className: PropTypes.string,
  status: PropTypes.array,
  dataValues: PropTypes.array,
  dataNames: PropTypes.array
};

export default injectIntl(UsersByDevice);
