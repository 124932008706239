import React, { useRef } from 'react';

import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import BoltIcon from '@mui/icons-material/Bolt';
import ClearIcon from '@mui/icons-material/Clear';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import { Box, Dialog, DialogTitle, Grid, IconButton, InputAdornment, Menu, MenuItem, TextField, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import mime from 'mime';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from "styled-components";
import { v4 as uuidv4 } from 'uuid';
import EmojiPicker from '../../../../components/emoji-picker';
import * as componentActions from '../../../../redux/actions/chats'; // trocar pelo redux que criarei no chat
import { Styles } from '../../../../styles/material-styles';
import { PapiroConsole } from '../../../../utils/papiroConsole';
import ChatQuickResponse from './chat-quick-response';
import QuickResponseModal from './quick-response-modal';
import './styles.css';

const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & .MuiOutlinedInput-notchedOutline legend {
    display: none;
  }
  & .MuiOutlinedInput-notchedOutline:hover {
    border: none;
  }
  & .MuiInputLabel-root {
    color: #FFF;
  }
  & .MuiOutlinedInput-input {
    padding: 7.5px 10px;
    color: #FFF;
  }
  & .MuiOutlinedInput-root {
    background-color: #3F51B4;
    border: none;
    border-radius: 20px;
  }
  & .MuiOutlinedInput-root .Mui-focused {
    border: none;
  }
  & .MuiInputBase-colorPrimary {
    border: none;
  }
`;

function ChatSendBar(props) {
  const { userSession, sendMessage, sendMessageAttachment, setExecScrollToBottom } = props
  const fileInputRef = useRef(null);

  
  
  const [openModalQuickResponse, setOpenModalQuickResponse] = useState(false);
  const [currentQuickResponseSeledted, setCurrentQuickResponseSeledted] = useState(null);
  

  const [message, setMessage] = useState('')


  /* MENU MORE ACTIONS START */
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenuMoreActions = Boolean(anchorEl);

  const handleClickMenuMoreActions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenuMoreActions = () => {
    setAnchorEl(null);
  };


  const handleClickMenuItemQuickResponse = () => {
    handleCloseMenuMoreActions();
    setOpenModalQuickResponse(true);
  }
  /* MENU MORE ACTIONS END */

  const handleChangeText = (e) => {
    const { name, value } = e.target;
    setMessage(value)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      sendInternalMessage(event);
    } else if (event.ctrlKey && event.code === 'Space') {
      event.preventDefault(); 
      setOpenModalQuickResponse(true);
    }
  }

  const handleEmojiClick = (emojiObject) => {
    setMessage((prevMensagem) => prevMensagem + emojiObject.emoji);
  }

  const sendAttachments = async (event) => {
    PapiroConsole.log("entrei aqui 23")
    event.preventDefault()
    
    const files = event.target.files;
    var arrayAttachments = [...files];
    PapiroConsole.log(files)
    PapiroConsole.log(arrayAttachments)
    PapiroConsole.log("entrada aqui")
    var guidList = [];

    if (files.length > 0) {
      let chat = props.chats.chat;
      var chatid = null;
      //alert(JSON.stringify(chat))
      if ((props.phonenumberid && props.phonenumberid.phonenumberid > 0) || (chat && chat.customerid && chat.customerid > 0)) {
        //alert(JSON.stringify(props.phonenumberid))
        //console.error(JSON.stringify(props.phonenumberid));
        //return false;
        if ((props.customerid && props.customerid > 0) || (props.customerid.id > 0) || (chat && chat.customerid && chat.customerid > 0)) {
          
          //alert(guid);
          //return;
          
          
          var msg = {
            //content: message,
            //guid: guid,
            to: props.customerid,
            userid: userSession.user.id,
            createdat: moment().format('YYYY/MM/DD HH:mm:ss'),
            createtime: moment().format('HH:mm'),
            icon: 'request',
            channelid: { id: props.phonenumberid.channelid },
            techinicalteamid: { id: props.phonenumberid.techinicalteamid },
            originrequestid: props.phonenumberid.originrequestid,

          };
          if (props.customerid.id > 0) {
            msg.to = props.customerid.phone;
            msg.customerid = props.customerid.id;
          }
          if (chat && chat.customerid && chat.customerid > 0) {
            chatid = chat.id;
            msg.channelid = { id: chat.channelid };
            msg.techinicalteamid = { id: chat.techinicalteamid };
            msg.to = chat.customer.phone;
            msg.messageproviderid = chat.messageproviderid;
            msg.messageproviderconfigid = chat.messageproviderconfigid;
            msg.customerid = chat.customerid;
            msg.originrequestid = chat.originrequestid;
            msg.phonenumberid = {
              id: chat.registrationnumberrequestid
              , messageproviderid: chat.messageproviderid
              , messageproviderconfigid: chat.messageproviderconfigid
              , organizationid: chat.organizationid
              , organizationname: chat.organizationname
              , requeststatusid: chat.chatstatusid
              , originrequestid: chat.originrequestid
            };
          }

          var error = false;
          var errorMessage = "";
          // alert(JSON.stringify(msg));
          arrayAttachments.forEach(element => {

            let guid = uuidv4();

            PapiroConsole.log(element)
            PapiroConsole.log("element aqui")
            if (chat && chat.chatmessages) {

              var attachmentType = getAttachmentType(element);
              if(attachmentType == "unknown"){
                error = true;
                errorMessage = `Arquivo ${element.name} não é suportado pelo sistema`;

              }
              var m = {
                channelid: msg.channelid.id,
                chatid: chatid,
                content: element.name,
                createdat: msg.createdat,
                delivered: false,
                read: false,
                request: true,
                senderuserid: msg.userid,
                sent: false,
                guid: guid,
                sendername: userSession.user.name,
                messagetype : attachmentType,
                messageorigin : 1
  
              };

              chat.chatmessages.push(m)
              guidList.push(guid)
              
            }
            
          });
          
          if(!error){
            PapiroConsole.log(chat.chatmessages)
            PapiroConsole.log("chat.chatmessages")
            await props.addCurrentChat(chat)
            
          }

          setMessage('');
          sendMessageAttachment(msg, chatid, arrayAttachments, guidList, error,errorMessage );
        }
        else {
          props.setNotificationMessage("Selecione a destinatário da mensagem.");
          props.setNotificationVariant("info");
          props.setOpenNotification(true);
        }
      }
      else {
        props.setNotificationMessage("Selecione a origem da conversa.");
        props.setNotificationVariant("info");
        props.setOpenNotification(true);
      }
    }

    fileInputRef.current.value = null;
    event.target.files = null;

    
    
  }



  const getAttachmentType = (file) => {
    const mimeType = mime.getType(file.name);
  
    if (mimeType === 'image/jpeg' ||
        mimeType === 'image/jpg' ||
        mimeType === 'image/png' ||
        mimeType === 'image/gif' ||
        mimeType === 'image/webp') {
      return 'image';
    } else if (mimeType === 'video/mp4' ||
               mimeType === 'video/3gpp') {
      return 'video';
    } else if (mimeType === 'application/pdf' ||
               mimeType === 'application/msword' ||
               mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
               mimeType === 'application/vnd.ms-excel' ||
               mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return 'document';
    } else {
      return 'unknown';
    }
  };

  const sendInternalMessage = async e => {
    e.preventDefault()
    PapiroConsole.log("=== sendInternalMessage ===")
    if (message.length > 0) {
      //alert(JSON.stringify(props.phonenumberid));
      let chat = props.chats.chat;
      var chatid = null;
      //alert(JSON.stringify(chat))
      if ((props.phonenumberid && props.phonenumberid.phonenumberid > 0) || (chat && chat.customerid && chat.customerid > 0)) {
        //alert(JSON.stringify(props.phonenumberid))
        //console.error(JSON.stringify(props.phonenumberid));
        //return false;
        if ((props.customerid && props.customerid > 0) || (props.customerid.id > 0) || (chat && chat.customerid && chat.customerid > 0)) {
          let guid = uuidv4();
          //alert(guid);
          //return;
          var msg = {
            content: message,
            guid: guid,
            to: props.customerid,
            userid: userSession.user.id,
            createdat: moment().format('YYYY/MM/DD HH:mm:ss'),
            createtime: moment().format('HH:mm'),
            icon: 'request',
            channelid: { id: props.phonenumberid.channelid },
            techinicalteamid: { id: props.phonenumberid.techinicalteamid },
            originrequestid: props.phonenumberid.originrequestid,

          };
          if (props.customerid.id > 0) {
            msg.to = props.customerid.phone;
            msg.customerid = props.customerid.id;
          }
          if (chat && chat.customerid && chat.customerid > 0) {
            chatid = chat.id;
            msg.channelid = { id: chat.channelid };
            msg.techinicalteamid = { id: chat.techinicalteamid };
            msg.to = chat.customer.phone;
            msg.messageproviderid = chat.messageproviderid;
            msg.messageproviderconfigid = chat.messageproviderconfigid;
            msg.customerid = chat.customerid;
            msg.originrequestid = chat.originrequestid;
            msg.phonenumberid = {
              id: chat.registrationnumberrequestid
              , messageproviderid: chat.messageproviderid
              , messageproviderconfigid: chat.messageproviderconfigid
              , organizationid: chat.organizationid
              , organizationname: chat.organizationname
              , requeststatusid: chat.chatstatusid
              , originrequestid: chat.originrequestid
            };
          }
          // alert(JSON.stringify(msg));
          if (chat && chat.chatmessages) {
            var m = {
              channelid: msg.channelid.id,
              chatid: chatid,
              content: msg.content,
              createdat: msg.createdat,
              delivered: false,
              read: false,
              request: true,
              senderuserid: msg.userid,
              sent: false,
              guid: guid,
              sendername: userSession.user.name,
              messageorigin : 1

            };
            chat.chatmessages.push(m)
            await props.addCurrentChat(chat)
          }

          setMessage('');
          sendMessage(msg, chatid);
        }
        else {
          props.setNotificationMessage("Selecione a destinatário da mensagem.");
          props.setNotificationVariant("info");
          props.setOpenNotification(true);
        }
      }
      else {
        props.setNotificationMessage("Selecione a origem da conversa.");
        props.setNotificationVariant("info");
        props.setOpenNotification(true);
      }
    }
  }

  const closeModalQuickResponse = () => {
    setCurrentQuickResponseSeledted(null);
    setOpenModalQuickResponse(false);
  }

  return (<div className="omnichannel-chat-message">
    <ChatQuickResponse
      setMessage={setMessage}
      customerid={props.customerid} 
      phonenumberid={props.phonenumberid} 
      sendMessage={props.sendMessage} 
      setExecScrollToBottom={setExecScrollToBottom}
      onSetCurrentQuickResponseSeledted={setCurrentQuickResponseSeledted}
      onSetOpenModal={setOpenModalQuickResponse}
    />
    <Grid container spacing={0} sx={{ paddingLeft: '2%' }} >
      {(props.chats && props.chats.chat && props.chats.chat.id) && <Grid item xs={9} sm={9} md={10} lg={11} xl={11} >
        <StyledTextField
          disabled={props.chats.chat && props.chats.chat.chatstatusid == 1 || props.chats.chat.chatstatusid == 2 ? false : true}
          fullWidth
          label=""
          inputProps={{ 
            maxLength: 255,
            list: "quickResponses"
          }}
          autoComplete='off'
          variant='outlined'
          name='searchChat'
          placeholder="Mensagem, Pressione Ctrl + Espaço para adicionar uma resposta rápida."
          onChange={handleChangeText}
          onKeyDown={handleKeyDown}
          value={message}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ display: 'flex', alignItems: 'center', gap: '1px' }}>
                <EmojiPicker
                  onEmojiClick={handleEmojiClick}
                  button={<SentimentSatisfiedAltIcon sx={{ color: '#FFF' }} />} 
                />

                <>
                  <Tooltip title="Mais ações">
                    <IconButton
                      onClick={handleClickMenuMoreActions}
                      size="small"
                      aria-controls={openMenuMoreActions ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMenuMoreActions ? 'true' : undefined}
                      variant="contained"
                      sx={{
                        transition: "transform 0.3s ease-in-out", 
                        transform: openMenuMoreActions ? "rotate(45deg)" : "rotate(0deg)",
                      }}
                    >
                      <AddIcon sx={{ color: '#FFF' }}/>
                    </IconButton>
                  </Tooltip>


                  <Menu
                    anchorEl={anchorEl}
                    open={openMenuMoreActions}
                    onClose={handleCloseMenuMoreActions}
                    anchorOrigin={{
                      vertical: "top", 
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <MenuItem 
                      onClick={handleClickMenuItemQuickResponse} 
                      style={{display: 'flex', alignItems: 'center', gap: '4px'}}
                    >
                      <BoltIcon sx={{ color: '#FFD700' }}/>
                      <span>Mensagens Rápidas</span>
                    </MenuItem>
                  </Menu>
                </>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start" sx={{ cursor: 'pointer', '&:hover': { color: '#ccc' } }}>
                <label htmlFor="attachment-input">
                  <IconButton component="span">
                    <AttachFileIcon sx={{ color: '#FFF', transform: 'rotate(40deg)' }} />
                  </IconButton>
                  <input
                    ref={fileInputRef}
                    id="attachment-input"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={sendAttachments}
                    multiple
                  />
                </label>
              </InputAdornment>
            ),
          }} />
          
      </Grid>}
      {(props.chats && props.chats.chat && props.chats.chat.id) && (
        <>
          <Grid item xs={3} sm={3} md={2} lg={1} xl={1} sx={{ display: 'flex', justifyContent: 'center' }} >
            <IconButton aria-label="Example" onClick={sendInternalMessage}>
              <SendOutlinedIcon sx={{ color: '#5667c4' }} />
            </IconButton>
        </Grid>
        </>
      )}
    </Grid>

    <Dialog 
      open={openModalQuickResponse} 
      onClose={closeModalQuickResponse} 
      aria-labelledby="form-dialog-title"  
      fullWidth
      maxWidth="lg" 
      PaperProps={{
          style: { width: "100%", maxWidth: "900px", minHeight: '400px' } 
      }}
    >
      <DialogTitle id="form-dialog-title">
          {currentQuickResponseSeledted?.title ?? "Respostas rápidas"}
          <ClearIcon 
              onClick={closeModalQuickResponse} 
              style={{ float: 'right', cursor : 'pointer'}}
          /> 
      </DialogTitle>
      <Box>
          <div style={{width: '100%', padding: '24px'}}>
              <QuickResponseModal
                quickResponse={currentQuickResponseSeledted}
                onSentMessage={setMessage}
                onSetOpenModal={setOpenModalQuickResponse}
                onSetCurrentQuickResponseSeledted={setCurrentQuickResponseSeledted}
                customerid={props.customerid} 
                phonenumberid={props.phonenumberid} 
                onSendMessage={props.sendMessage} 
                onSendMessageAttachment={sendMessageAttachment}
                onGetAttachmentType={getAttachmentType}
              />
          </div>
      </Box>     
  </Dialog>
  </div>)
}

ChatSendBar.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  chats: state.chats,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ChatSendBar)))