import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'

import { Styles } from '../../../../styles/material-styles'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper } from '@mui/material'
import { AccessTime, AttachMoney, ExpandMore } from '@mui/icons-material'

function CalledCosts(props) {

  const [expanded, setExpanded] = useState(true)
  const [calledTime, setCalledTime] = useState('R$ 0,00')
  const [calledValue, setCalledValue] = useState('00:00:00')

  const handleChangeExpand = _ => {
    setExpanded(!expanded)
  }

  useEffect(() => {
    if (props.calledData != null && props.calledData.calledcost != null) {
      let itemCalledTime = 0
      let { calledcost } = props.calledData
      if (calledcost.totalhoursdisplacement != null && !isNaN(calledcost.totalhoursdisplacement) && calledcost.totalhoursdisplacement > 0)
        itemCalledTime += calledcost.totalhoursdisplacement
      if (calledcost.totalhoursinloco != null && !isNaN(calledcost.totalhoursinloco) && calledcost.totalhoursinloco > 0)
        itemCalledTime += calledcost.totalhoursinloco
      if (calledcost.totalhoursremote != null && !isNaN(calledcost.totalhoursremote) && calledcost.totalhoursremote > 0)
        itemCalledTime += calledcost.totalhoursremote

      if(itemCalledTime && itemCalledTime > 0) {
        let splitCalledHoutLoc = [
          (itemCalledTime > 0) ? Math.floor(itemCalledTime) : Math.ceil(itemCalledTime),
          itemCalledTime % 1
        ]
        let nDate = new Date(0,0)
        nDate.setSeconds(+splitCalledHoutLoc[1] * 60 * 60)
        if(nDate.getSeconds() >= 58 ) {
          nDate.setSeconds(0)
          nDate.setMinutes(nDate.getMinutes() + 1)
        }
        let calledHourLocResult = nDate.toTimeString().slice(2, 8)
        calledHourLocResult = splitCalledHoutLoc[0] == 0 ? "00" + calledHourLocResult : splitCalledHoutLoc[0] < 10 ? "0" + splitCalledHoutLoc[0] + calledHourLocResult : splitCalledHoutLoc[0] + calledHourLocResult
        itemCalledTime = calledHourLocResult

        if(itemCalledTime == '0')
          itemCalledTime = "00:00:00"
      } else if(itemCalledTime == '0')
        itemCalledTime = "00:00:00"

      let calledValueLoc = `R$0,00`
      if(calledcost.totalvaluecost != null && calledcost.totalvaluecost > 0) {
        calledValueLoc = `R$${calledcost.totalvaluecost.toFixed(2)}`
        calledValueLoc = calledValueLoc.replaceAll(".", ",")
      }

      setCalledTime(itemCalledTime)
      setCalledValue(calledValueLoc)
    }
  }, [props.calledData])

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChangeExpand}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="called-costs-content"
        id="called-costs-header">
        <h4 style={{ color: "#3F51B4", fill: "#3F51B4", margin: 0, fontWeight: 600, display: 'flex', alignItems: 'flex-end' }}><AttachMoney />&nbsp;<FormattedMessage id="costs" /></h4>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} sx={{ paddingLeft: '5%' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: '15px', border: '1px solid rgba(0, 0, 0, .3)', borderRadius: '4px' }}>
            <h6 style={{ color: "#58595B", fill: "#58595B", margin: 0, display: 'flex', justifyItems: 'center' }}><AttachMoney sx={{ color: "#3F51B4", fill: "#3F51B4", fontSize: '1.2em' }} />&nbsp;<FormattedMessage id="called.total.cost" /></h6>
            <h3 style={{ color: "#58595B", fill: "#58595B", margin: '5px 0 0 0', fontWeight: '600' }}>{calledValue}</h3>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginTop: '10px', padding: '15px', border: '1px solid rgba(0, 0, 0, .3)', borderRadius: '4px' }}>
            <h6 style={{ color: "#58595B", fill: "#58595B", margin: 0, display: 'flex', justifyItems: 'center' }}><AccessTime sx={{ color: "#3F51B4", fill: "#3F51B4", fontSize: '1.2em' }} />&nbsp;<FormattedMessage id="service.time" /></h6>
            <h3 style={{ color: "#58595B", fill: "#58595B", margin: '5px 0 0 0', fontWeight: '600' }}>{calledTime}</h3>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

CalledCosts.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
})

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(CalledCosts)))