import { dia, shapes } from '@joint/plus';
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ShapeTypesEnum } from '../../../joint-plus/shapes/app.shapes';
import { State } from '../../../redux/reducer';
import './Inspector.scss';
import LabelInspector from './LabelInspector';
import LinkInspector from './LinkInspector';
import MessageInspector from './MessageInspector';

import { CircularProgress } from '@mui/material';
import { useIntl } from 'react-intl';
import CustomizedSnackbars from 'src/components/material-snackbars';
import { Overlay } from 'src/styles/global';

import * as ChatbotFlowService from "../../../../../../../../services/2talk/chatbot-flow.service.js";
import AvaliationInspector from './AvaliationInspector';
import CreateTicketInspector from './CreateTicketInspector';
import FlowInspector from './FlowInspector';
import ResponseOrientationInspector from './ResponseOrientationInspector';
import ResponseParamInspector from './ResponseParamInspector';
import ServiceInspector from './ServiceInspector';
import TalkAttendantInspector from './TalkAttendantInspector';


const Inspector = ({chatbotId, chatbotFlowId, json} : {chatbotFlowId: string, chatbotId: string, json : any}): ReactElement => {
    const intl = useIntl();

    const [cell, setCell] = useState<dia.Cell>(null);

    const selection = useSelector((state: State) => state.selection);

    const [statePage, setStatePage] = useState({
        loading: false,
        openNotification: false,
        notificationVariant: "error",
        notificationMessage: ""
    });

    const closeNotification = () => {
        setStatePage(prevState => ({...prevState, openNotification: false}));
    };

    const saveJson = async (json: string | null | any) => {
        setStatePage(prevState => ({...prevState, loading: true}));

        try {
            const input = {
                id: chatbotFlowId,
                jsonflow: json,
            }

            const output = await ChatbotFlowService.addJsonFlow(input);

            if (!output?.success) {
                throw new Error("Não foi possível salvar o fluxo");
            }

            setStatePage({
                loading: false, 
                openNotification: true, 
                notificationVariant: "success",
                notificationMessage: "Fluxo salvo com sucesso"
            });
            
        } catch (err) {
            setStatePage({
                loading: false, 
                openNotification: true, 
                notificationVariant: "error",
                notificationMessage: "Não foi possível salvar o fluxo"
            });
        } finally {
            setStatePage(prevState => ({...prevState, loading: false}));
        }
    }

    const handleSubmitSaveJson = async () => {
        if (!json || json?.cells?.length <= 0) {
            json = null
        } else {
            json = JSON.stringify(json);
        }

        await saveJson(json);
    }

    const chooseInspector = (): ReactElement => {
        switch (cell.get('type')) {
            case ShapeTypesEnum.MESSAGE:
                return <MessageInspector cell={cell as shapes.app.Message} chatbotFlowId={chatbotFlowId}/>;
            case ShapeTypesEnum.LINK:
                return <LinkInspector cell={cell as dia.Link}/>;
            case ShapeTypesEnum.FLOWCHART_START:
                return <LabelInspector cell={cell}/>;
            case ShapeTypesEnum.FLOWCHART_END:
                return <LabelInspector cell={cell}/>;
            case ShapeTypesEnum.RESPONSE_ORIENTATION:
                return <ResponseOrientationInspector cell={cell as shapes.app.ResponseOrientation} chatbotFlowId={chatbotFlowId}/>;
            case ShapeTypesEnum.TALK_ATTENDANT:
                return <TalkAttendantInspector cell={cell as shapes.app.TalkAttendant}/>;
            case ShapeTypesEnum.RESPONSE_PARAM:
                return <ResponseParamInspector cell={cell as shapes.app.ResponseParam} chatbotFlowId={chatbotFlowId}/>;
            case ShapeTypesEnum.SERVICE:
                return <ServiceInspector cell={cell as shapes.app.Service} chatbotFlowId={chatbotFlowId}/>;
            case ShapeTypesEnum.CREATE_TICKET:
                return <CreateTicketInspector cell={cell as shapes.app.CreateTicket} chatbotFlowId={chatbotFlowId}/>;
            case ShapeTypesEnum.AVALIATION:
                return <AvaliationInspector cell={cell as shapes.app.Avaliation} chatbotFlowId={chatbotFlowId}/>;
            case ShapeTypesEnum.FLOW:
                return <FlowInspector cell={cell as shapes.app.Flow} chatbotId={chatbotId} chatbotFlowId={chatbotFlowId}/>;
            default:
                return;
        }
    };

    const emptyInspector = (): ReactElement => {
        return (
            <>
                <h1>Componente Selecionado</h1>
                <label>Label
                    <input disabled/>
                </label>
            </>
        );
    };

    useEffect(() => {
        const [selectedCell = null] = selection as dia.Cell[];
        setCell(selectedCell);
    }, [selection]);

    return (
        <>
            <div className={'chatbot-inspector ' + (!cell ? 'disabled-chatbot-inspector' : '')}>
                {
                    cell ? chooseInspector() : emptyInspector()
                }
                <button className='button-save' onClick={handleSubmitSaveJson}>Salvar fluxo</button>
            </div>

            {statePage.loading && (
                <Overlay>
                    <CircularProgress color='secondary' />
                </Overlay>
            )}

            <CustomizedSnackbars
                variant={statePage.notificationVariant}
                message={statePage.notificationMessage}
                isOpen={statePage.openNotification}
                toClose={closeNotification}
            />
        </>

    );
};

export default Inspector;
