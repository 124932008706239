export default function calledorganizationchecklists(state = [], action) {
  switch (action.type) {
      case 'ADD_CURRENT':
          return {
              ...state,
              organizationchecklist: action.organizationchecklist,
          }
      default:
          return state;
  }
}