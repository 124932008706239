import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage,injectIntl } from 'react-intl';
import * as Service from '../../services/user.service';
import * as ItemService from '../../services/additionalcalledcostitems.service'
import * as TypeService from '../../services/additionalcalledcostitemtypes.service'
import * as ReasonService from '../../services/additionalcalledcostitemreasons.service'
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import Title from '../../components/title-name';
import SimpleDatePicker from '../../components/date-pickers/inline-no-today';
import SimpleSelect from '../../components/select/simple';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import NumberFormat from 'react-number-format';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import { PapiroConsole } from '../../utils/papiroConsole';
import AttachFileIcon from '@mui/icons-material/AttachFile';

class AdditionalCalledCostRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession,calleds  } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
        if(item.role.roletype !=3){
            this.isClient = false;
        }
        if(item.role.roletype ==0){
          this.globalAdm =true;
        }
    }
    );

    var defaultvalue = {id:0, name:''};
    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      name: '',
      observation: '',
      value: 0,
      hours : '1',
      calledId : calleds.called.id,
      calledName: calleds.called.title,
      AdditionalCalledCostItemId: 0,
      AdditionalCalledCostItemTypeId: 0,
      AdditionalCalledCostItemReasonId: 0,
    };

    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      AdditionalCalledCostItemList: [],
      AdditionalCalledCostTypeList: [],
      AdditionalCalledCostReasonList: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.location.state === "reload"){
        this.componentDidMount();
    }
  }    

  async componentDidMount() {
    await this.getAllItemsActive()
    await this.getAllActiveTypesByItemId()
    await this.getAllActiveReasonsByItemId()
  };

  async getActivities(){
    this.setState({ loading: true });
    
    var result = await Service.GetAllActivities();

    console.log(result )
  
      if (result.success) {

        console.log("result aqui")
        console.log(result)
        
        

        this.setState(prevState => ({
          item: {
            ...prevState.item,
            ["activities"]: result.data
          }, 
          loading : false
        }));
       // this.setState({ loading: false, users: result.data });
      
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);
      }
};    

  async getUsers(){
    this.setState({ loading: true });
    console.log("entrei aqui")
    console.log()
    var result = await Service.GetUsersTechs(this.state.item.organizationCategoryId,this.state.item.organizationId);

    console.log(result )
  
      if (result.success) {

        console.log("result aqui")
        console.log(result)
        
        

        this.setState(prevState => ({
          item: {
            ...prevState.item,
            ["users"]: result.data
          }, 
          loading : false
        }));
       // this.setState({ loading: false, users: result.data });
      
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);
      }
};    

  handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if(name == "hours"){
      if(e.target.value <= 0 ){
        e.target.value = 1
      }
      else{
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            [name]: value
          }
        }));
      }
    }
    else{
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [name]: value
        }
      }));
    }
  }

  handleSubmit = async e => {
    const intl = this.props.intl;
    e.preventDefault();

    if (this.state.item.AdditionalCalledCostItemId == null || this.state.item.AdditionalCalledCostItemId == 0 || (this.state.item.AdditionalCalledCostItemId && this.state.item.AdditionalCalledCostItemId.id && this.state.item.AdditionalCalledCostItemId.id == 0)) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"additional.called.cost.edit.missing.service.item.field"})
      });
      return false
    }
    if (this.state.item.AdditionalCalledCostItemTypeId == null || this.state.item.AdditionalCalledCostItemTypeId == 0 || (this.state.item.AdditionalCalledCostItemTypeId && this.state.item.AdditionalCalledCostItemTypeId.id && this.state.item.AdditionalCalledCostItemTypeId.id == 0)) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"additional.called.cost.edit.missing.service.type.field"})
      });
      return false
    }
    if (this.state.item.AdditionalCalledCostItemReasonId == null || this.state.item.AdditionalCalledCostItemReasonId == 0 || (this.state.item.AdditionalCalledCostItemReasonId && this.state.item.AdditionalCalledCostItemReasonId.id && this.state.item.AdditionalCalledCostItemReasonId.id == 0)) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"additional.called.cost.edit.missing.service.reason.field"})
      });
      return false
    }

    this.setState({ loading: true });

    var returnReponse = this.checkValidations();
    if(returnReponse == true){
        let data = new FormData();

        if (this.isClient) {
          this.setState(prevState => ({
            item: {
              ...prevState.item,
            }
          }));
        } else {
          this.setState(prevState => ({
            item: {
              ...prevState.item,
            }
          }));
        }

        PapiroConsole.log("=== this.state.item ===")
        PapiroConsole.log(this.state.item)

        for (let key in this.state.item) {
          if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
            data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
          } else if (key.toString().toLowerCase().indexOf('additionalcalledcostitemid') > -1 || key.toString().toLowerCase().indexOf('additionalcalledcostitemtypeid') > -1 || key.toString().toLowerCase().indexOf('additionalcalledcostitemreasonid') > -1) {
            if (typeof this.state.item[key].id === 'undefined')
              data.append(key, this.state.item[key]);
            else
              data.append(key, this.state.item[key].id);
          } else {
            data.append(key, this.state.item[key]);
          }
        }
        this.state.files.forEach(file => data.append('filesUpload', file));

        let config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        };
          Api.post('/additionalcalledcosts', data, config)
          .then(result => {
            if (result.data.success) {
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({id:"add.success"}),
                item: this.initialState,
                files: []
              });
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);
            }          
          })
          .catch(err => {
            if(err.response && err.response.data && err.response.data.errors) {
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
            }
          });
      }
  };

  checkValidations() {
    const intl = this.props.intl;

    if(this.state.item.name == ''){
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"missing.name.field"})

      });

      return false

    }
    if(this.state.item.value == ''){
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"additional.called.cost.edit.missing.value.field"})

      });

      return false

    }

    if(this.state.item.value <= 0){
        this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"additional.called.cost.edit.value.zero"})

      });

      return false

    }

    return true

    
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={false}
        decimalSeparator=","
        isNumericString
        decimalScale={2}
        prefix="R$"
      />
    );
  }

  getAllItemsActive = async () => {
    const { calleds } = this.props

    PapiroConsole.log("=== calleds ===")
    PapiroConsole.log(calleds)

    var result = await ItemService.getActiveByOrganizationId(calleds.called.organizationid)
    if (result.success) {
      this.setState({ loading: false, AdditionalCalledCostItemList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  getAllActiveTypesByItemId = async () => {
    const { calleds } = this.props

    PapiroConsole.log("=== getAllActiveTypesByItemId ===")
    PapiroConsole.log(calleds)

    var result = await TypeService.getActiveByOrganizationIdWithoutIncludes(calleds.called.organizationid)
    if (result.success) {
      this.setState({ loading: false, AdditionalCalledCostTypeList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  getAllActiveReasonsByItemId = async () => {
    const { calleds } = this.props

    var result = await ReasonService.getActiveByOrganizationIdWithoutIncludes(calleds.called.organizationid)
    if (result.success) {
      this.setState({ loading: false, AdditionalCalledCostReasonList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  changeValues = (stateName, value, text = '') => {
    this.setState({ loading: true });

    if(stateName == "dateStart")
    {
      console.log(value)
      console.log("valor aqui ")
      console.log( moment(value).format('YYYY/MM/DD HH:mm:ss'))
      this.setState(prevState => ({
        
        item: {
          ...prevState.item,
          [stateName]:  moment(value).format('YYYY/MM/DD')
        }
      }));
    }
    else if (stateName == "AdditionalCalledCostItemId") {
      //await this.getAllActiveTypesByItemId(value.id)
      //await this.getAllActiveReasonsByItemId(value.id)
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [stateName]: value,
        }
      })); 
    }
    else if (stateName == "AdditionalCalledCostItemTypeId") {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [stateName]: value,
        }
      })); 
    }
    else if (stateName == "AdditionalCalledCostItemReasonId") {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [stateName]: value,
          name: value && value.name ? value.name : prevState.item.name
        }
      })); 
    }
    else{
      if(stateName == "UserId" ){
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            [stateName]: value.id,
            ["User"]: value
          }
        })); 
      }
      else if(stateName == "ActivityId" ){
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            [stateName]: value.id,
            ["Activity"]: value
            
          }
        }))
      }
    }
    this.setState({ loading: false });
  };

  handleBack = () => {
    if (this.props.calleds.called) {
      const id = this.props.calleds.called.id
      this.props.history.push(`/${this.props.match.params.lang}/called-accompaniment/${id}`, { propTab: 9 })
    } else {
      browserHistory.goBack()
    }
  }

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
    render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary'   onClick={this.handleBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <AccountBalanceWalletIcon />
              </Avatar>
              <Typography component='h1' variant='h5'>
              {<FormattedMessage id="add.item" />} {<FormattedMessage id="additional.called.costs" />}
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "called" })}
                      disabled
                      variant="outlined"
                      name="calledName"
                      value={item.calledName}
                      required />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "additional.called.cost.item" })}
                      options={this.state.AdditionalCalledCostItemList}
                      stateName='AdditionalCalledCostItemId'
                      changeSelect={this.changeValues}
                      selected={item.AdditionalCalledCostItemId}
                      withOrg={true}
                      isRequired
                      required  />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "additional.called.cost.type" })}
                      options={this.state.AdditionalCalledCostTypeList}
                      stateName='AdditionalCalledCostItemTypeId'
                      changeSelect={this.changeValues}
                      selected={item.AdditionalCalledCostItemTypeId}
                      withOrg={true}
                      isRequired
                      required  />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "additional.called.cost.reason" })}
                      options={this.state.AdditionalCalledCostReasonList}
                      stateName='AdditionalCalledCostItemReasonId'
                      changeSelect={this.changeValues}
                      selected={item.AdditionalCalledCostItemReasonId}
                      withOrg={true}
                      isRequired
                      required  />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "name" })}
                      inputProps={{ maxLength: 255 }}
                      variant="outlined"
                      name="name"
                      onChange={this.handleInputChange}
                      value={item.name }
                      required />
                  </Grid>
                  <Grid item xs={5} sm={5}>
                    <TextField
                      label={intl.formatMessage({id:"value"})}
                      variant='outlined'
                      value={item.value}
                      onChange={this.handleInputChange}
                      name="value"
                      id="formatted-numberformat-input"
                      InputProps={{
                        inputComponent: this.NumberFormatCustom,
                      }}
                      InputLabelProps={{ shrink: true }}
                      autoComplete='fname'
                      required />  
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      name="observation"
                      autoComplete="fname"
                      label={intl.formatMessage({ id: "observation" })}
                      multiline
                      rows="4"
                      fullWidth
                      value={item.observation}
                      variant="outlined"
                      onChange={this.handleInputChange}
                      inputProps={{ maxLength: 255 }}
                    />
                  </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <section style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}>
                    <div className={classes.dropzoneArea} style={{minHeight:'70px',marginTop:'5px '}}>
                      <Dropzone onDrop={acceptedFiles => { this.setState({ files: [ ...acceptedFiles, ...this.state.files ] })}}>
                        {({ getRootProps, getInputProps, acceptedFiles }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <AttachFileIcon  />
                            <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                                <FormattedMessage  id="drag.and.drop.files" />
                            </Typography>
                          </div>
                        )}
                      </Dropzone>
                      {this.state.files.map(file => {
                        return (
                          <div className={classes.dropzoneItem} key={file.name}>
                            {file.name}
                            <DeleteForever className={classes.deleteItem} onClick={() => { this.removeItem(file) }} />
                          </div>
                        )
                      })}
                    </div>
                  </section>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                    <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={4} >
                          <Button
                              type='submit'
                              fullWidth
                              variant='contained'
                              color='primary'
                              className={classes.submit}
                          >
                              {<FormattedMessage id="save" />}
                          </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
      </div>
    );
  }
}

AdditionalCalledCostRegistration.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  calleds: state.calleds,
});
export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(AdditionalCalledCostRegistration)));
