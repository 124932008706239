import React, { Component, Fragment } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as componentActions from '../../redux/actions/calledorganizationchecklists';
import { bindActionCreators } from 'redux';
import moment from "moment";
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CalledReportImagesDialog from "../../components/called-report-images-dialog";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MiniMap from '../../components/minimap/fixed'
import { PapiroConsole } from '../../utils/papiroConsole';
import { getByCalledIdAndHistoryCheckType, getAllAccompanimentByCalledIdOrderByDesc, getWithUserByCalledIdAndType, getAllByCalledIdGroupByAttendance } from '../../services/called.service'
import { getById } from '../../services/accompaniment.service'
import If from '../../components/conditionals/if'
import FirstElement from "../../components/first-element";

import {
  Avatar,
  Button,
  CssBaseline,
  Grid,
  Typography,
  CircularProgress,
  Container,
} from "@mui/material";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import CustomizedSnackbars from "../../components/material-snackbars";
import { connect } from "react-redux";
import AssignmentIcon from '@mui/icons-material/Assignment';
import LogoSmall2 from "../../components/logo/logo-small-2";

const flexStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}

class TabHistoryLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      creationCalled: null,
      checkInCalled: null,
      checkInDisplacementCalled: null,
      checkOutCalled: null,
      checkOutDisplacementCalled: null,
      signatureLocation: null,
      accompaniments: [],
      attendanceList: []
    };

    const { history } = this.props;
    this.history = history
    this.addresses = null
    this.listItems = null
    this.teste = null

    this.getData = this.getData.bind(this);
    this.getCalledCreationLocation = this.getCalledCreationLocation.bind(this)
    this.getCalledCheckInLocation = this.getCalledCheckInLocation.bind(this)
    this.getCalledCheckOutLocation = this.getCalledCheckOutLocation.bind(this)
    this.getCalledDisplacementCheckInLocation = this.getCalledDisplacementCheckInLocation.bind(this)
    this.getCalledDisplacementCheckOutLocation = this.getCalledDisplacementCheckOutLocation.bind(this)
    this.getCalledSignatureLocation = this.getCalledSignatureLocation.bind(this)
    this.closeNotification = this.closeNotification.bind(this);
    this.getAccompainmentFile = this.getAccompainmentFile.bind(this);
    this.getAllByCalledIdGroupByAttendance = this.getAllByCalledIdGroupByAttendance.bind(this)
  }

  async componentDidMount() {

    const { calleds } = this.props;
    this.setState({
      calledId: calleds.called.id,
    });
    await this.getCalledCreationLocation()
    await this.getAllByCalledIdGroupByAttendance()
  }

  async getCalledCreationLocation() {
    const { calleds, intl } = this.props;
    this.setState({ loading: true });
    let result = await getWithUserByCalledIdAndType(calleds.called.id, 1)
    if(result && result.data && result.data.data) {
      if(result.data.data.historychecktypeid && result.data.data.latitude && result.data.data.latitude.length > 0) {
        this.setState({
          creationCalled: result.data.data,
          loading: false
        })
      }else {
        this.setState({
          loading: false
        })
      }
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
    }
  }

  async getAllByCalledIdGroupByAttendance() {
    const { calleds, intl } = this.props;
    this.setState({ loading: true });
    let result = await getAllByCalledIdGroupByAttendance(calleds.called.id)
    if(result && result.data && result.data.data) {
      await this.setState({
        attendanceList: result.data.data.length > 0 ? result.data.data : [],
        loading: false
      })
    }else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
    }
  }

  async getCalledCheckInLocation() {
    const { calleds, intl } = this.props;
    this.setState({ loading: true });
    let result = await getWithUserByCalledIdAndType(calleds.called.id, 3)
    if(result && result.data && result.data.data) {
      if(result.data.data.historychecktypeid && result.data.data.latitude && result.data.data.latitude.length > 0) {
        this.setState({
          checkInCalled: result.data.data,
          loading: false
        })
      } else {
        this.setState({
          loading: false
        })
      }
    }else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
    }
  }

  async getCalledCheckOutLocation() {
    const { calleds, intl } = this.props;
    this.setState({ loading: true });
    let result = await getWithUserByCalledIdAndType(calleds.called.id, 4)
    if(result && result.data && result.data.data) {
      if(result.data.data.historychecktypeid && result.data.data.latitude && result.data.data.latitude.length > 0) {
        this.setState({
          checkOutCalled: result.data.data,
          loading: false
        })
      } else {
        this.setState({
          loading: false
        })
      }
    }else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
    }
  }

  async getCalledDisplacementCheckInLocation() {
    const { calleds, intl } = this.props;
    this.setState({ loading: true });
    let result = await getWithUserByCalledIdAndType(calleds.called.id, 7)
    if(result && result.data && result.data.data) {
      if(result.data.data.historychecktypeid && result.data.data.latitude && result.data.data.latitude.length > 0) {
        this.setState({
          checkInDisplacementCalled: result.data.data,
          loading: false
        })
      } else {
        this.setState({
          loading: false
        })
      }
    }else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
    }
  }

  async getCalledDisplacementCheckOutLocation() {
    const { calleds, intl } = this.props;
    //this.setState({ loading: true });
    let result = await getWithUserByCalledIdAndType(calleds.called.id, 8)
    if(result && result.data && result.data.data) {
      if(result.data.data.historychecktypeid && result.data.data.latitude && result.data.data.latitude.length > 0) {
        this.setState({
          checkOutDisplacementCalled: result.data.data,
          loading: false
        })
      } else {
        this.setState({
          loading: false
        })
      }
    }else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
    }
  }

  async getCalledSignatureLocation() {
    const { calleds, intl } = this.props;
    this.setState({ loading: true });
    let result = await getWithUserByCalledIdAndType(calleds.called.id, 5)
    if(result && result.data && result.data.data) {
      if(result.data.data.historychecktypeid && result.data.data.latitude && result.data.data.latitude.length > 0) {
        this.setState({
          signatureLocation: result.data.data,
          loading: false
        })
      } else {
        this.setState({
          loading: false
        })
      }
    }else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
    }
  }

  async getCalledAccompanimentLocation() {
    const { calleds, intl } = this.props;
    this.setState({ loading: true });
    let result = await getAllAccompanimentByCalledIdOrderByDesc(calleds.called.id)
    if(result && result.data && result.data.data) {
      if(result.data.data.length > 0) {
        this.setState({
          accompaniments: result.data.data,
          loading: false
        })
      }else {
        this.setState({
          loading: false
        })
      }
    }else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
    }
  }

  async getAccompanimentById(id) {
    const { intl } = this.props;
    this.setState({ loading: true });
    let result = await getById(id);
    if(result && result.data && result.data.data) {
      this.setState({ loading: false });
      return result.data.data
    }else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      return null
    }
  }

  getAccompainmentFile(fileName, accompanimentId) {
    window.open(`${Api.apiurlbase()}/${fileName}`, '_blank');
  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      calledReport: {
        ...prevState.calledReport,
        [name]: value,
      },
    }));
  }

  async getData(props){
    const { calleds } = this.props;
    const mystyle = {
      subtitleFont : {
        marginTop:'4px',
        fontSize :"14px",
        
      },
    }
    await this.getCalledCreationLocation()
    //await this.getCalledCheckInLocation()
    //await this.getCalledCheckOutLocation()
    //await this.getCalledSignatureLocation()
    //await this.getCalledAccompanimentLocation()
    //await this.getCalledDisplacementCheckInLocation()
    //await this.getCalledDisplacementCheckOutLocation()
    await this.getAllByCalledIdGroupByAttendance()
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  render() {

    const intl = this.props.intl;
    const { classes, userSession, calleds } = this.props;

    var openingDate = moment(calleds.called.openingDate).endOf('day').format("DD/MM/YYYY")
    var dueData = moment(calleds.called.dueData).endOf('day').format("DD/MM/YYYY")
    //PapiroConsole.log(calleds)
  
    const mystyle = {
      containerBorder : {
        fontSize :"20px",
        minHeight: '100px', 
        borderRight:'1px solid #e6e6e6'
      },
      subtitleFont : {
        marginTop:'4px',
        fontSize :"14px",
      },
        dataFont : {
        fontSize :"16px",
        color:"black",
      },
      menuRoot: {
        flexGrow: 1,
      },
      menuButton: {
        marginRight: '8px',
      },
      titleMenu: {
        flexGrow: 1,
      }
     }

    return (
      <Container component="main" maxWidth="md"
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                border: "1px solid rgba(0, 0, 0, .3)"
              }}>
          <CssBaseline />
          <div className={mystyle.menuRoot}
              style={{
                paddingLeft: "24px",
                paddingRight: "24px",
                marginBottom: "15px"
              }}>
              {
                (this.state.creationCalled == null && this.state.attendanceList.length === 0 && this.state.checkInCalled == null && this.state.checkOutCalled == null && this.state.checkOutDisplacementCalled == null && this.state.signatureLocation == null && this.state.accompaniments.length === 0) && (
                  <div>
                    <FirstElement
                      title = {intl.formatMessage({id:"geolocation.history"})}
                      subtitle = {intl.formatMessage({id:"no.info"})}
                      icon = {<LocationOnIcon style={{fontSize: "45px"}} />} />
                  </div>
                )
              }
              { this.state.creationCalled && (
              <div style={{marginTop :'30px', marginBottom: '40px'}}>
                <h4>{intl.formatMessage({id:"called.creation"})}</h4>
                <Divider />
                <Grid container spacing={3}  style={{marginTop: '20px', border: '1px solid #e6e6e6', borderRadius:'10px', paddingBottom: '10px'}}>
                  { this.state.creationCalled.useraction && (
                  <Grid item xs={12}>
                    <h4 style={mystyle.subtitleFont}><span style={{ fontWeight: 'bolder' }}>{this.state.creationCalled.useraction.name}</span> &nbsp;&nbsp;<small className="date">{moment(this.state.creationCalled.createdat).format("DD/MM/YYYY HH:mm")}</small></h4>
                  </Grid>
                  )}
                  <Grid item xs={12}>
                    <h4 style={mystyle.subtitleFont}>
                      ({this.state.creationCalled.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}, {this.state.creationCalled.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}) - {this.state.creationCalled.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                    </h4>
                  </Grid>
                  <Grid item xs={6}>
                    <Card style={{
                      width: 350, minWidth: 350, maxWidth: 350,
                      height:250, minHeight: 250, maxHeight: 250
                      }}>
                      <CardContent>
                        <MiniMap
                          style={{
                            width: 350, minWidth: 350, maxWidth: 350,
                            height:250, minHeight: 250, maxHeight: 250
                          }}
                          lat={this.state.creationCalled.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                          long={this.state.creationCalled.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                          address={this.state.creationCalled.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </div>
              )}
              { this.state.attendanceList.map((item, idx) => {
                if ((item.displacementcheckin && item.displacementcheckin.latitude) || (item.checkin && item.checkin.latitude)) {
                return <Accordion style={{marginTop:  "10px"}} defaultExpanded={idx === 0}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`attendance${item.attendanceid}-content`}
                    id={`attendance${item.attendanceid}-header`} >
                    <Typography className={classes.heading}>Fluxo de Atendimento #{item.attendanceid}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                  <Grid container xs={12} sm={12} >
                    {item.displacementcheckin && item.displacementcheckin.latitude && (
                        <Grid xs={12} sm={12} style={{marginTop :'30px', marginBottom: '40px'}}>
                          <h4>{intl.formatMessage({id:"displacement.check.in"})}</h4>
                          <Divider />
                          <Grid container spacing={3}  style={{marginTop: '20px', border: '1px solid #e6e6e6', borderRadius:'10px', paddingBottom: '10px'}}>
                            { item.displacementcheckin.useraction && (
                            <Grid item xs={12}>
                              <h4 style={mystyle.subtitleFont}><span style={{ fontWeight: 'bolder' }}>{item.displacementcheckin.useraction.name}</span> &nbsp;&nbsp;<small className="date">{moment(item.displacementcheckin.createdat).format("DD/MM/YYYY HH:mm")}</small></h4>
                            </Grid>
                            )}
                            <Grid item xs={12}>
                              <h4 style={mystyle.subtitleFont}>
                                ({item.displacementcheckin.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}, {item.displacementcheckin.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}) - {item.displacementcheckin.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                              </h4>
                            </Grid>
                            <Grid item xs={6}>
                              <Card style={{
                                width: 350, minWidth: 350, maxWidth: 350,
                                height:250, minHeight: 250, maxHeight: 250
                                }}>
                                <CardContent>
                                  <MiniMap
                                    style={{
                                      width: 350, minWidth: 350, maxWidth: 350,
                                      height:250, minHeight: 250, maxHeight: 250
                                    }}
                                    lat={item.displacementcheckin.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                                    long={item.displacementcheckin.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                                    address={item.displacementcheckin.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                                  />
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {item.checkin && item.checkin.latitude && (
                        <Grid xs={12} sm={12} style={{marginTop :'30px', marginBottom: '40px'}}>
                          <h4>{intl.formatMessage({id:"on.field.check.in"})}</h4>
                          <Divider />
                          <Grid container spacing={3}  style={{marginTop: '20px', border: '1px solid #e6e6e6', borderRadius:'10px', paddingBottom: '10px'}}>
                            { item.checkin.useraction && (
                            <Grid item xs={12}>
                              <h4 style={mystyle.subtitleFont}><span style={{ fontWeight: 'bolder' }}>{item.checkin.useraction.name}</span> &nbsp;&nbsp;<small className="date">{moment(item.checkin.createdat).format("DD/MM/YYYY HH:mm")}</small></h4>
                            </Grid>
                            )}
                            <Grid item xs={12}>
                              <h4 style={mystyle.subtitleFont}>
                                ({item.checkin.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}, {item.checkin.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}) - {item.checkin.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                              </h4>
                            </Grid>
                            <Grid item xs={6}>
                              <Card style={{
                                width: 350, minWidth: 350, maxWidth: 350,
                                height:250, minHeight: 250, maxHeight: 250
                                }}>
                                <CardContent>
                                  <MiniMap
                                    style={{
                                      width: 350, minWidth: 350, maxWidth: 350,
                                      height:250, minHeight: 250, maxHeight: 250
                                    }}
                                    lat={item.checkin.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                                    long={item.checkin.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                                    address={item.checkin.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                                  />
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {item.checkout && item.checkout.latitude && (
                        <Grid xs={12} sm={12} style={{marginTop :'30px', marginBottom: '40px'}}>
                          <h4>{intl.formatMessage({id:"on.field.checkout"})}</h4>
                          <Divider />
                          <Grid container spacing={3}  style={{marginTop: '20px', border: '1px solid #e6e6e6', borderRadius:'10px', paddingBottom: '10px'}}>
                            { item.checkout.useraction && (
                            <Grid item xs={12}>
                              <h4 style={mystyle.subtitleFont}><span style={{ fontWeight: 'bolder' }}>{item.checkout.useraction.name}</span> &nbsp;&nbsp;<small className="date">{moment(item.checkout.createdat).format("DD/MM/YYYY HH:mm")}</small></h4>
                            </Grid>
                            )}
                            <Grid item xs={12}>
                              <h4 style={mystyle.subtitleFont}>
                                ({item.checkout.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}, {item.checkout.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}) - {item.checkout.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                              </h4>
                            </Grid>
                            <Grid item xs={6}>
                              <Card style={{
                                width: 350, minWidth: 350, maxWidth: 350,
                                height:250, minHeight: 250, maxHeight: 250
                                }}>
                                <CardContent>
                                  <MiniMap
                                    style={{
                                      width: 350, minWidth: 350, maxWidth: 350,
                                      height:250, minHeight: 250, maxHeight: 250
                                    }}
                                    lat={item.checkout.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                                    long={item.checkout.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                                    address={item.checkout.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                                  />
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {item.signature && item.signature.latitude && (
                        <Grid xs={12} sm={12} style={{marginTop :'30px', marginBottom: '40px'}}>
                          <h4>{intl.formatMessage({id:"called.signature"})}</h4>
                          <Divider />
                          <Grid container spacing={3}  style={{marginTop: '20px', border: '1px solid #e6e6e6', borderRadius:'10px', paddingBottom: '10px'}}>
                            { item.signature.useraction && (
                            <Grid item xs={12}>
                              <h4 style={mystyle.subtitleFont}><span style={{ fontWeight: 'bolder' }}>{item.signature.useraction.name}</span> &nbsp;&nbsp;<small className="date">{moment(item.signature.createdat).format("DD/MM/YYYY HH:mm")}</small></h4>
                            </Grid>
                            )}
                            <Grid item xs={12}>
                              <h4 style={mystyle.subtitleFont}>
                                ({item.signature.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}, {item.signature.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}) - {item.signature.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                              </h4>
                            </Grid>
                            <Grid item xs={6}>
                              <Card style={{
                                width: 350, minWidth: 350, maxWidth: 350,
                                height:250, minHeight: 250, maxHeight: 250
                                }}>
                                <CardContent>
                                  <MiniMap
                                    style={{
                                      width: 350, minWidth: 350, maxWidth: 350,
                                      height:250, minHeight: 250, maxHeight: 250
                                    }}
                                    lat={item.signature.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                                    long={item.signature.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                                    address={item.signature.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                                  />
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {item.displacementcheckout && item.displacementcheckout.latitude && (
                          <Grid xs={12} sm={12} style={{marginTop :'30px', marginBottom: '40px'}}>
                            <h4>{intl.formatMessage({id:"displacement.checkout"})}</h4>
                            <Divider />
                            <Grid container spacing={3}  style={{marginTop: '20px', border: '1px solid #e6e6e6', borderRadius:'10px', paddingBottom: '10px'}}>
                              { item.displacementcheckout && item.displacementcheckout.useraction && (
                              <Grid item xs={12}>
                                <h4 style={mystyle.subtitleFont}><span style={{ fontWeight: 'bolder' }}>{item.displacementcheckout.useraction.name}</span> &nbsp;&nbsp;<small className="date">{moment(item.displacementcheckout.createdat).format("DD/MM/YYYY HH:mm")}</small></h4>
                              </Grid>
                              )}
                              <Grid item xs={12}>
                                <h4 style={mystyle.subtitleFont}>
                                  ({item.displacementcheckout.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}, {item.displacementcheckout.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}) - {item.displacementcheckout.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                                </h4>
                              </Grid>
                              <Grid item xs={6}>
                                <Card style={{
                                  width: 350, minWidth: 350, maxWidth: 350,
                                  height:250, minHeight: 250, maxHeight: 250
                                  }}>
                                  <CardContent>
                                    <MiniMap
                                      style={{
                                        width: 350, minWidth: 350, maxWidth: 350,
                                        height:250, minHeight: 250, maxHeight: 250
                                      }}
                                      lat={item.displacementcheckout.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                                      long={item.displacementcheckout.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                                      address={item.displacementcheckout.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                                    />
                                  </CardContent>
                                </Card>
                              </Grid>
                            </Grid>
                          </Grid>
                      )}
                      {item.accompanimentsdescorder != null && (
                        <Grid xs={12} sm={12} style={{marginTop :'30px', marginBottom: '40px'}}>
                          <h4>Acompanhamentos</h4>
                          <Divider />
                          { item.accompanimentsdescorder.map((action, i) => {
                            return (
                              <Grid container spacing={3} key={i} style={{ marginTop: '20px', border: '1px solid #e6e6e6', borderRadius:'10px', paddingBottom: '10px' }}>
                                <Grid item xs={12} style={{ paddingBottom: 0 }}>
                                  <h4 style={mystyle.subtitleFont}>
                                    <span style={{ fontWeight: 'bolder' }}>{action.accompaniment.useraction.name}</span> &nbsp;&nbsp;<small className="date">{moment(action.accompaniment.actiontime).format("DD/MM/YYYY HH:mm")}</small>
                                  </h4>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: 0 }}>
                                  <h4 style={mystyle.subtitleFont}>
                                    {action.accompaniment.description}
                                  </h4>
                                </Grid>
                                <Grid item xs={12}>
                                  <h4 style={mystyle.subtitleFont}>
                                    ({action.latitude}, {action.longitude}) - {action.addresscomplete}
                                  </h4>
                                </Grid>
                                <Grid item xs={6}>
                                  <Card style={{
                                    width: 350, minWidth: 350, maxWidth: 350,
                                    height:250, minHeight: 250, maxHeight: 250
                                    }}>
                                    <CardContent>
                                      <MiniMap
                                        style={{
                                          width: 350, minWidth: 350, maxWidth: 350,
                                          height:250, minHeight: 250, maxHeight: 250
                                        }}
                                        lat={action.latitude}
                                        long={action.longitude}
                                        address={action.addresscomplete}
                                      />
                                    </CardContent>
                                  </Card>
                                </Grid>
                              </Grid>)
                          }) }
                        </Grid>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                }
              }) }
              { this.state.checkInCalled && (
              <div style={{marginTop :'30px', marginBottom: '40px'}}>
                <h4>{intl.formatMessage({id:"on.field.check.in"})}</h4>
                <Divider />
                <Grid container spacing={3}  style={{marginTop: '20px', border: '1px solid #e6e6e6', borderRadius:'10px', paddingBottom: '10px'}}>
                  { this.state.checkInCalled.useraction && (
                  <Grid item xs={12}>
                    <h4 style={mystyle.subtitleFont}><span style={{ fontWeight: 'bolder' }}>{this.state.checkInCalled.useraction.name}</span> &nbsp;&nbsp;<small className="date">{moment(this.state.checkInCalled.createdat).format("DD/MM/YYYY HH:mm")}</small></h4>
                  </Grid>
                  )}
                  <Grid item xs={12}>
                    <h4 style={mystyle.subtitleFont}>
                      ({this.state.checkInCalled.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}, {this.state.checkInCalled.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}) - {this.state.checkInCalled.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                    </h4>
                  </Grid>
                  <Grid item xs={6}>
                    <Card style={{
                      width: 350, minWidth: 350, maxWidth: 350,
                      height:250, minHeight: 250, maxHeight: 250
                      }}>
                      <CardContent>
                        <MiniMap
                          style={{
                            width: 350, minWidth: 350, maxWidth: 350,
                            height:250, minHeight: 250, maxHeight: 250
                          }}
                          lat={this.state.checkInCalled.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                          long={this.state.checkInCalled.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                          address={this.state.checkInCalled.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </div>
              )}
              { this.state.checkOutCalled && (
              <div style={{marginTop :'30px', marginBottom: '40px'}}>
                <h4>{intl.formatMessage({id:"on.field.checkout"})}</h4>
                <Divider />
                <Grid container spacing={3}  style={{marginTop: '20px', border: '1px solid #e6e6e6', borderRadius:'10px', paddingBottom: '10px'}}>
                  { this.state.checkOutCalled.useraction && (
                  <Grid item xs={12}>
                    <h4 style={mystyle.subtitleFont}><span style={{ fontWeight: 'bolder' }}>{this.state.checkOutCalled.useraction.name}</span> &nbsp;&nbsp;<small className="date">{moment(this.state.checkOutCalled.createdat).format("DD/MM/YYYY HH:mm")}</small></h4>
                  </Grid>
                  )}
                  <Grid item xs={12}>
                    <h4 style={mystyle.subtitleFont}>
                      ({this.state.checkOutCalled.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}, {this.state.checkOutCalled.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}) - {this.state.checkOutCalled.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                    </h4>
                  </Grid>
                  <Grid item xs={6}>
                    <Card style={{
                      width: 350, minWidth: 350, maxWidth: 350,
                      height:250, minHeight: 250, maxHeight: 250
                      }}>
                      <CardContent>
                        <MiniMap
                          style={{
                            width: 350, minWidth: 350, maxWidth: 350,
                            height:250, minHeight: 250, maxHeight: 250
                          }}
                          lat={this.state.checkOutCalled.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                          long={this.state.checkOutCalled.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                          address={this.state.checkOutCalled.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </div>
              )}
              
              { this.state.checkOutDisplacementCalled && (
              <div style={{marginTop :'30px', marginBottom: '40px'}}>
                <h4>{intl.formatMessage({id:"displacement.checkout"})}</h4>
                <Divider />
                <Grid container spacing={3}  style={{marginTop: '20px', border: '1px solid #e6e6e6', borderRadius:'10px', paddingBottom: '10px'}}>
                  { this.state.checkOutDisplacementCalled.useraction && (
                  <Grid item xs={12}>
                    <h4 style={mystyle.subtitleFont}><span style={{ fontWeight: 'bolder' }}>{this.state.checkOutDisplacementCalled.useraction.name}</span> &nbsp;&nbsp;<small className="date">{moment(this.state.checkOutDisplacementCalled.createdat).format("DD/MM/YYYY HH:mm")}</small></h4>
                  </Grid>
                  )}
                  <Grid item xs={12}>
                    <h4 style={mystyle.subtitleFont}>
                      ({this.state.checkOutDisplacementCalled.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}, {this.state.checkOutDisplacementCalled.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}) - {this.state.checkOutDisplacementCalled.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                    </h4>
                  </Grid>
                  <Grid item xs={6}>
                    <Card style={{
                      width: 350, minWidth: 350, maxWidth: 350,
                      height:250, minHeight: 250, maxHeight: 250
                      }}>
                      <CardContent>
                        <MiniMap
                          style={{
                            width: 350, minWidth: 350, maxWidth: 350,
                            height:250, minHeight: 250, maxHeight: 250
                          }}
                          lat={this.state.checkOutDisplacementCalled.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                          long={this.state.checkOutDisplacementCalled.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                          address={this.state.checkOutDisplacementCalled.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </div>
              )}
              { this.state.signatureLocation && (
              <div style={{marginTop :'30px', marginBottom: '40px'}}>
                <h4>{intl.formatMessage({id:"called.signature"})}</h4>
                <Divider />
                <Grid container spacing={3}  style={{marginTop: '20px', border: '1px solid #e6e6e6', borderRadius:'10px', paddingBottom: '10px'}}>
                  { this.state.signatureLocation.useraction && (
                  <Grid item xs={12}>
                    <h4 style={mystyle.subtitleFont}><span style={{ fontWeight: 'bolder' }}>{this.state.signatureLocation.useraction.name}</span> &nbsp;&nbsp;<small className="date">{moment(this.state.signatureLocation.createdat).format("DD/MM/YYYY HH:mm")}</small></h4>
                  </Grid>
                  )}
                  <Grid item xs={12}>
                    <h4 style={mystyle.subtitleFont}>
                      ({this.state.signatureLocation.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}, {this.state.signatureLocation.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}) - {this.state.signatureLocation.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                    </h4>
                  </Grid>
                  <Grid item xs={6}>
                    <Card style={{
                      width: 350, minWidth: 350, maxWidth: 350,
                      height:250, minHeight: 250, maxHeight: 250
                      }}>
                      <CardContent>
                        <MiniMap
                          style={{
                            width: 350, minWidth: 350, maxWidth: 350,
                            height:250, minHeight: 250, maxHeight: 250
                          }}
                          lat={this.state.signatureLocation.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                          long={this.state.signatureLocation.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                          address={this.state.signatureLocation.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '')}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </div>
              )}
              {this.state.accompaniments.length > 0 && (
              <div style={{marginTop :'30px', marginBottom: '40px'}}>
                <h4>Acompanhamentos</h4>
                <Divider />
                { this.state.accompaniments.map((action, i) => {
                  return (
                <Grid container spacing={3} key={i} style={{ marginTop: '20px', border: '1px solid #e6e6e6', borderRadius:'10px', paddingBottom: '10px' }}>
                  <Grid item xs={12} style={{ paddingBottom: 0 }}>
                    <h4 style={mystyle.subtitleFont}>
                      <span style={{ fontWeight: 'bolder' }}>{action.accompaniment.useraction.name}</span> &nbsp;&nbsp;<small className="date">{moment(action.accompaniment.actiontime).format("DD/MM/YYYY HH:mm")}</small>
                    </h4>
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <h4 style={mystyle.subtitleFont}>
                      {action.accompaniment.description}
                    </h4>
                  </Grid>
                  <Grid item xs={12}>
                    <h4 style={mystyle.subtitleFont}>
                      ({action.latitude}, {action.longitude}) - {action.addresscomplete}
                    </h4>
                  </Grid>
                  <Grid item xs={6}>
                    <Card style={{
                      width: 350, minWidth: 350, maxWidth: 350,
                      height:250, minHeight: 250, maxHeight: 250
                      }}>
                      <CardContent>
                        <MiniMap
                          style={{
                            width: 350, minWidth: 350, maxWidth: 350,
                            height:250, minHeight: 250, maxHeight: 250
                          }}
                          lat={action.latitude}
                          long={action.longitude}
                          address={action.addresscomplete}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>)
                })}
              </div>
              )}
          </div>
          {this.state.loading && (
            <Overlay>
              <CircularProgress color="secondary" />
            </Overlay>
          )}

          <CustomizedSnackbars
            variant={this.state.notificationVariant}
            message={this.state.notificationMessage}
            isOpen={this.state.openNotification}
            toClose={this.closeNotification}
          />
        </Container>
    );
  }
}

TabHistoryLocation.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    calleds: state.calleds,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);;

//export default connect(mapStateToProps)(withStyles(Styles)(CalledReportInformation));
export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(TabHistoryLocation))));