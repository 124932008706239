import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress'
import ClearIcon from '@mui/icons-material/Clear'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, FormGroup, FormControlLabel, Grid, IconButton, Switch, TextField, Typography, DialogContentText } from '@mui/material'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ImageList from '@mui/material/ImageList'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit';
import * as componentActions from '../../../../redux/actions/calledservicesettingsorganizationfields'
import * as validationComponentActions from '../../../../redux/actions/calledservicesettingsorganizationfieldvalidations'
import Api from '../../../../services/api'
import { editItem } from '../../../../services/calledservicesettingsorganizationfield.service'
import { Styles } from '../../../../styles/material-styles'
import { Overlay } from '../../../../styles/global';
import CustomizedSnackbars from '../../../../components/material-snackbars'
import { PapiroConsole } from '../../../../utils/papiroConsole'
import SimpleAutoComplete from '../../../../components/auto-complete/autocomplete'
import Tooltip from '@mui/material/Tooltip'
import HelpIcon from '@mui/icons-material/Help'
import { DeleteForever, AttachFile } from '@mui/icons-material'
import Dropzone from 'react-dropzone'
import CalledServiceSettingsOrganizationFieldValidationItemDialog from './validationDialog'
import { isInvalidFileType } from '../../../../utils/fileUtils'
import { getAllActiveByOrganizationIdWithoutIncludes, getAllActiveByOrganizationIdAndCalledServiceSettingIdAndCalledServiceSettingsOrganizationFieldIdWithoutIncludes } from '../../../../services/organizationfield.service'
import * as CalledServiceSettingsOrganizationFieldAttachmentService from '../../../../services/calledservicesettingsorganizationfieldattachment.service'
import * as CalledServiceSettingsOrganizationFieldValidationService from '../../../../services/calledservicesettingsorganizationfieldvalidation.service'
import AttachFileIcon from '@mui/icons-material/AttachFile';

function UpdateCalledServiceSettingsOrganizationFieldItemDialog(props) {

  const { classes, intl, userId, hirerId } = props

  const [open, setOpen] = useState(props.open)
  const [loading, setLoading] = useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const [notificationVariant, setNotificationVariant] = useState('error')
  const [notificationMessage, setNotificationMessage] = useState('')

  const [id, setId] = useState(0)
  const [organizationFieldId, setOrganizationFieldId] = useState(null)
  const [label, setLabel] = useState('')
  const [order, setOrder] = useState(null)
  const [isRequired, setIsRequired] = useState(false)
  const [requiredFieldMessage, setRequiredFieldMessage] = useState('')
  const [files, setFiles] = useState([])

  const [calledServiceSettingsOrganizationFieldAttachments, setCalledServiceSettingsOrganizationFieldAttachments] = useState([])
  const [calledServiceSettingsOrganizationFieldValidations, setCalledServiceSettingsOrganizationFieldValidations] = useState([])

  const [orderList, setOrderList] = useState([])
  const [organizationFieldList, setOrganizationFieldList] = useState([])

  const [calledServiceSettingOrganizationFieldValidationDialogOpen, setCalledServiceSettingOrganizationFieldValidationDialogOpen] = useState(false)

  const [openDeleteValidation, setOpenDeleteValidation] = useState(false)
  const [deleteValidationItemId, setDeleteValidationItemId] = useState(null)

  useEffect(() => {
    PapiroConsole.log("=== props.calledservicesettingsorganizationfields ===")
    PapiroConsole.log(props.calledservicesettingsorganizationfields)
    if (props.calledservicesettingsorganizationfields && props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfield != null) {
      setId(props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfield.id)
      let itemFind = organizationFieldList.find(item => item.id == props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfield.organizationfieldid)
      if (itemFind != null && typeof itemFind !== 'undefined')
        setOrganizationFieldId(itemFind)
      setLabel(props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfield.label)
      setOrder({ id: props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfield.order, name: `${props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfield.order}` })
      setIsRequired(props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfield.isrequired)
      setRequiredFieldMessage(props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfield.requiredfieldmessage)
    }
  }, [props.calledservicesettingsorganizationfields])

  useEffect(() => {
    PapiroConsole.log("=== props.open ===")
    PapiroConsole.log(props.open)
    setOpen(props.open)
    props.handleOpen(props.open)

    if (props.open) {
      setFiles([])
      getAllOrganizationFieldsByOrganization(props.organizationId)
      getAllCalledServiceSettingsOrganizationFieldAttachments(props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfield.id)
      getAllCalledServiceSettingsOrganizationFieldValidations(props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfield.id)
    }
  }, [props.open])

  useEffect(() => {
    if (props.itemsCount > 0) {
      let orderItemsList = []

      let totalOrderItems = props.itemsCount

      if (order && !isNaN(order))
        setOrder({ id: order, name: `${order}` })
      else
        setOrder({ id: totalOrderItems, name: `${totalOrderItems}` })

      for (let orderItem = 1; orderItem <= totalOrderItems; orderItem++) {
        orderItemsList.push({ id: orderItem, name: `${orderItem}` })
      }

      setOrderList(orderItemsList)
    } else {
      setOrderList([{ id: 1, name: '1' }])
      setOrder({ id: 1, name: '1' })
    }
  }, [props.itemsCount])

  const showMessageTypeName = (messageType) => {
    switch (messageType) {
      case 1:
        return 'Sucesso'
      case 2:
        return 'Informação'
      case 3:
        return 'Aviso'
      case 4:
        return 'Erro'
      default:
        return 'N/A'
    }
  }

  const close = () => {
    setOpen(false)
    props.handleOpen(false)
    setOrganizationFieldId(null)
    setId(0)
    props.addCalledServiceSettingsOrganizationField(null)
  }

  const closeDeleteValidation = () => {
    setOpenDeleteValidation(false)
    setDeleteValidationItemId(null)
  }

  const setMessage = (open, variant, message) => {
    setOpenNotification(open)
    setNotificationVariant(variant)
    setNotificationMessage(message)
  }

  const closeNotification = _ => {
    setOpenNotification(false)
    setNotificationVariant('error')
    setNotificationMessage('')
  }

  const classes2 = () => {
    return withStyles({
      ImageList: {
        width: "100%",
        height: 100,
      }
    });
  }

  const changeValues = (stateName, value) => {
    if (stateName === 'order')
      setOrder(value)
    if (stateName === 'organizationField')
      setOrganizationFieldId(value)
  }

  const validateImagesAtachments = (filesList) => {
    if (typeof filesList === 'object') {
      let count = 0
      let fileList = []
      Object.values(filesList).forEach(val => {
        ++count
        if (isInvalidFileType(val.type, "image")) {
          setFiles([])
          setMessage(true, 'error', "Tipo de arquivo inválido. São aceitos arquivos dos tipos: BMP, GIf, JPG / JPEG, PNG, TIFF")
        } else
          fileList.push(val)
      })
      setFiles(fileList)
      PapiroConsole.log(files.length)
      PapiroConsole.log(calledServiceSettingsOrganizationFieldAttachments.length)
      if (fileList.length > 2 || ((fileList.length + calledServiceSettingsOrganizationFieldAttachments.length) > 2)) {
        setFiles([])
        setMessage(true, 'error', "você pode inserir no máximo 2 arquivos")
        return
      }
    } else {
      let fileList = []
      filesList.foreach((item, idx) => {
        if (!isInvalidFileType(item.type, "image")) {
          setFiles([])
          setMessage(true, 'error', "Tipo de arquivo inválido. São aceitos arquivos dos tipos: BMP, GIf, JPG / JPEG, PNG, TIFF")
          return
        } else
          fileList.push(item)
      })
      setFiles(fileList)
      if (fileList.length > 2 || ((fileList.length + calledServiceSettingsOrganizationFieldAttachments.length) > 2)) {
        setFiles([])
        setMessage(true, 'error', "você pode inserir no máximo 2 arquivos")
        return
      }
    }
  }

  const removeItem = (file) => {
    let array = [...files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      setFiles(array)
    }
  }

  const openLinkFile = url => {
    window.open(`${url}`, '_blank');
  }

  const validateForm = _ => {
    if (label == null || (label != null && label.length === 0)) {
      setLoading(false)
      setMessage(true, 'error', "O campo 'Nome' é obrigatório")
      return false
    } else if (organizationFieldId == null || (organizationFieldId != null && organizationFieldId === 0)) {
      setLoading(false)
      setMessage(true, 'error', "O campo 'Campo personalizado' é obrigatório")
      return false
    } else if (order == null || (order != null && order === 0)) {
      setLoading(false)
      setMessage(true, 'error', "O campo 'Ordem' é obrigatório")
      return false
    }

    return true
  }

  const handleOpenCalledServiceSettingOrganizationFieldValidationDialog = isOpen => {
    setCalledServiceSettingOrganizationFieldValidationDialogOpen(isOpen)
  }

  const handleSetCalledServiceSettingOrganizationFieldValidationList = itemList => setCalledServiceSettingsOrganizationFieldValidations(itemList)

  const updateItem = async _ => {
    setLoading(true)

    let formIsValid = validateForm()
    if (formIsValid) {
      var data = new FormData()

      data.append("Id", id)
      data.append("Label", label)
      data.append("Order", order.id)
      data.append("IsRequired", isRequired)
      data.append("RequiredFieldMessage", requiredFieldMessage)
      if (organizationFieldId) {
        data.append("OrganizationFieldId", organizationFieldId.id)
      }
      data.append("CalledServiceSettingsId", props.calledServiceSettingsId)
      data.append("UpdatedUser", userId)
      data.append("UpdatedAt", moment().format('YYYY/MM/DD HH:mm:ss'))

      if (typeof files === 'object') {
        Object.values(files).forEach(val => {
          data.append("FilesUpload", val)
        })
      } else {
        if (files && files.length > 0)
          files.foreach(item => data.append("FilesUpload", item))
      }

      let response = await editItem(data)
      if (response && response.success) {
        setLoading(false)
        setMessage(true, 'success', intl.formatMessage({ id: "edit.success" }))
        setFiles([])
        if (response.data != null) {
          PapiroConsole.log("=== updateItem ===")
          PapiroConsole.log(props.calledservicesettingsorganizationfields)
          let oldItemsList = props.calledservicesettingsorganizationfields && props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfieldslist && props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfieldslist.length > 0 ? [...props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfieldslist] : []
          oldItemsList = oldItemsList.filter(item => item.id != response.data.id)
          PapiroConsole.log("=== oldItemsList ===")
          PapiroConsole.log(oldItemsList)
          if (props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfield && props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfield.order != null && props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfield.order != order.id) {
            let orderFilter = [...oldItemsList]
            PapiroConsole.log("=== orderFilter ===")
            PapiroConsole.log(orderFilter)
            if (orderFilter && orderFilter.length > 0) {
              let oldOrder = props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfield && props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfield.order
              let newOrder = order.id
              let lastOrder = props.calledservicesettingsorganizationfields && props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfieldslist && props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfieldslist.length > 0 ? props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfieldslist.length : 1
              PapiroConsole.log(`=== oldOrder -> ${oldOrder} ===`)
              PapiroConsole.log(`=== newOrder -> ${newOrder} ===`)
              PapiroConsole.log(`=== lastOrder -> ${lastOrder} ===`)
              let newOrderFilterList = orderFilter.map(item => {
                let orderData = item.order

                if (oldOrder == lastOrder && item.order >= newOrder)
                  orderData = item.order + 1
                else if (oldOrder == 1 && item.order <= newOrder)
                  orderData = item.order - 1
                else {
                  if (newOrder - oldOrder > 0) {
                    if (item.order > oldOrder && item.order <= newOrder)
                      orderData = item.order - 1
                  }
                  else {
                    if (item.order < newOrder)
                      orderData = item.order + 1
                  }
                }

                return {
                  ...item,
                  order: orderData
                }
              })
              let newOrderFilterListIds = orderFilter.map(item => item.id)
              PapiroConsole.log("=== newOrderFilterListIds ===")
              PapiroConsole.log(newOrderFilterList)

              let notOrderFilterList = oldItemsList.filter(item => !newOrderFilterListIds.includes(item.id))
              PapiroConsole.log("=== notOrderFilterList ===")
              PapiroConsole.log(notOrderFilterList)
              oldItemsList = [...notOrderFilterList, ...newOrderFilterList]
              PapiroConsole.log("=== oldItemsList ===")
              PapiroConsole.log(oldItemsList)

            }
          }
          oldItemsList = oldItemsList.filter(item => item.id != response.data.id)
          let itemList = [...oldItemsList, response.data]
          itemList = itemList.sort((a, b) => a.id - b.id)
          PapiroConsole.log("=== itemList ===")
          PapiroConsole.log(itemList)

          props.addCalledServiceSettingsOrganizationFieldsList(itemList)
        }
        close()
        //getAllCalledServiceSettingsOrganizationFieldAttachments(id, true)
      } else {
        setLoading(false)
        setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
        Api.kickoff(response)
      }
    }
  }

  const deleteOrganizationFieldAttachmentItem = async itemId => {
    setLoading(true)

    let response = await CalledServiceSettingsOrganizationFieldAttachmentService.deleteItem(itemId)
    if (response && response.success) {
      setLoading(false)
      let notItemList = calledServiceSettingsOrganizationFieldAttachments.filter(item => item.id != itemId)
      setCalledServiceSettingsOrganizationFieldAttachments(notItemList)
    } else {
      setLoading(false)
      setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
      Api.kickoff(response)
    }
  }

  const getAllOrganizationFieldsByOrganization = async organizationId => {
    setLoading(true)

    let response = await getAllActiveByOrganizationIdAndCalledServiceSettingIdAndCalledServiceSettingsOrganizationFieldIdWithoutIncludes(organizationId, props.calledServiceSettingsId, id)
    if (response && response.success) {
      //setLoading(false)
      if (response.data != null) {
        setOrganizationFieldList(response.data)
        PapiroConsole.log("=== getAllOrganizationFieldsByOrganization ===")
        if (props.calledservicesettingsorganizationfields && props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfield != null) {
          PapiroConsole.log(props.calledservicesettingsorganizationfields)
          let itemFind = response.data.find(item => item.id == props.calledservicesettingsorganizationfields.calledservicesettingsorganizationfield.organizationfieldid)
          PapiroConsole.log(itemFind)
          if (itemFind != null && typeof itemFind !== 'undefined')
            setOrganizationFieldId(itemFind)
        }
      }
    } else {
      setLoading(false)
      setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
      Api.kickoff(response)
    }
  }

  const getAllCalledServiceSettingsOrganizationFieldAttachments = async (id, handleLoading = false) => {
    if (handleLoading)
      setLoading(true)

    let response = await CalledServiceSettingsOrganizationFieldAttachmentService.getAllByCalledServiceSettingsFileUploadIdWithAttachmentIncludes(id)
    if (response && response.success) {
      if (handleLoading)
        setLoading(false)
      if (response.data != null)
        setCalledServiceSettingsOrganizationFieldAttachments(response.data)
    } else {
      setLoading(false)
      setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
      Api.kickoff(response)
    }
  }

  const getAllCalledServiceSettingsOrganizationFieldValidations = async (id) => {

    let response = await CalledServiceSettingsOrganizationFieldValidationService.getAllByCalledServiceSettingsOrganizationFieldIdWithOrganizationFieldOptionsValuesIncludes(id)
    if (response && response.success) {
      setLoading(false)
      if (response.data != null)
        setCalledServiceSettingsOrganizationFieldValidations(response.data)
    } else {
      setLoading(false)
      setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
      Api.kickoff(response)
    }
  }

  const deleteValidationItem = async _ => {
    setLoading(true)

    let response = await CalledServiceSettingsOrganizationFieldValidationService.deleteItem(deleteValidationItemId)
    if (response && response.success) {
      setLoading(false)
      if (response.data != null)
        setCalledServiceSettingsOrganizationFieldValidations([])

      setDeleteValidationItemId(null)
      setOpenDeleteValidation(false)
    } else {
      setLoading(false)
      setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
      Api.kickoff(response)
    }
  }

  return (
    <>
      <Dialog
        open={openDeleteValidation}
        fullWidth={true}
        maxWidth="sm"
        onClose={closeDeleteValidation}
        scroll="body"
        aria-labelledby="form-delete-called-service-setting-organization-field-validation-dialog-title" >
        <DialogTitle id="form-delete-called-service-setting-organization-field-validation-dialog-title">
          Excluir validação
          <ClearIcon onClick={closeDeleteValidation} style={{ float: 'right', cursor: 'pointer' }} />
        </DialogTitle>
        <DialogContentText style={{ paddingLeft: '5%' }}>
          Você tem certeza que desejar excluir esta validação?
        </DialogContentText>
        <DialogActions>
          <Button
            variant='contained'
            type="button"
            onClick={closeDeleteValidation} >
            {intl.formatMessage({ id: "cancel" })}
          </Button>
          {id > 0 && <Button
            type='submit'
            variant='contained'
            color='primary'
            onClick={deleteValidationItem} >
            {intl.formatMessage({ id: "delete" })}
          </Button>}
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="lg"
        onClose={close}
        scroll="body"
        aria-labelledby="form-edit-called-service-setting-organization-field-dialog-title" >
        <DialogTitle id="form-edit-called-service-setting-organization-field-dialog-title">
          {intl.formatMessage({ id: "edit.organization.field" })}
          <ClearIcon onClick={close} style={{ float: 'right', cursor: 'pointer' }} />
        </DialogTitle>
        <DialogContent dividers={true}>
          {id > 0 ? (<Grid container spacing={2} style={{ padding: 10 }} >
            <Grid item container xs={12} sm={12} alignItems='center' spacing={2}>
              <Grid item xs={11} sm={11} md={11} lg={8} >
                <TextField
                  name="label"
                  variant="outlined"
                  fullWidth
                  id="label"
                  label={intl.formatMessage({ id: "name" })}
                  value={label}
                  required
                  onChange={(e) => setLabel(e.target.value)} />
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={4} style={{ textAlign: 'left' }}>
                <Tooltip title={<h5>Nome do campo no formulário</h5>}>
                  <HelpIcon />
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={12} alignItems='center' spacing={2}>
              <Grid item xs={11} sm={11} md={11} lg={8} >
                <SimpleAutoComplete
                  label="Campo personalizado"
                  options={organizationFieldList}
                  stateName='organizationField'
                  changeSelect={(stateName, value) => setOrganizationFieldId(value)}
                  selected={organizationFieldId} />
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={4} style={{ textAlign: 'left' }}>
                <Tooltip title={<h5>Campo personalizado que aparecerá no formulário</h5>}>
                  <HelpIcon />
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item container xs={12} sm={12} alignItems='center' spacing={2}>
              <Grid item xs={11} sm={11} md={11} lg={4} >
                <SimpleAutoComplete
                  label={`${intl.formatMessage({ id: "item.order" })}*`}
                  options={orderList}
                  stateName='order'
                  changeSelect={(stateName, value) => setOrder(value)}
                  selected={order} />
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={8} style={{ textAlign: 'left' }}>
                <Tooltip title={<h5>Ordem de exibição do campo personalizado</h5>}>
                  <HelpIcon />
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={10} sm={10} md={11} lg={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isRequired}
                      onChange={(e) => setIsRequired(e.target.checked)}
                      name="isRequired" />
                  }
                  label="Campo obrigatório?*" />
              </FormGroup>
            </Grid>
            <Grid item xs={2} sm={2} md={1} lg={6} />
            {isRequired && <Grid item xs={12} sm={12} md={12} lg={8} >
              <TextField
                name="requiredFieldMessage"
                variant="outlined"
                multiline
                rows="4"
                fullWidth
                id="requiredFieldMessage"
                label="Mensagem de erro"
                value={requiredFieldMessage}
                required
                onChange={(e) => setRequiredFieldMessage(e.target.value)} />
            </Grid>}
            {isRequired && <Grid item xs={1} sm={1} md={1} lg={4} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>Mensagem de erro, caso o campo seja obrigatório, e o usuário tente enviar o formulário sem preencher</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>}
            <Grid item xs={11} sm={11} md={11} lg={8} >
              <Typography style={{ marginBottom: '0', color: 'rgba(0, 0, 0, 0.54)' }}>Validação</Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} />
            <Grid item xs={11} sm={11} md={11} lg={8} >
              {calledServiceSettingsOrganizationFieldValidations.length > 0 && <Grid container spacing={2}>
                {calledServiceSettingsOrganizationFieldValidations.map(item => {
                  return (
                    <Grid key={item.id} item xs={12} sm={12} md={12} lg={12}>
                      <div style={{
                        border: '1px dotted rgba(0, 0, 0, .2)',
                        borderRadius: 15,
                        padding: 15,
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: 'rgba(63, 81, 180, .2)'
                      }}>
                        <div>
                          <div>
                            <strong>Quando o selecionar a opção: </strong> {item.organizationfieldoptionsvalues && item.organizationfieldoptionsvalues.name ? item.organizationfieldoptionsvalues.name : ""}
                          </div>
                          <div>
                            <strong>Exibir mensagem de </strong> {showMessageTypeName(item.messagetype)}
                          </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <IconButton
                            color="primary"
                            aria-label="edit-validation"
                            style={{ marginRight: 10 }}
                            onClick={() => {
                              props.addCalledServiceSettingsOrganizationFieldValidation(item)
                              handleOpenCalledServiceSettingOrganizationFieldValidationDialog(true)
                            }} >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            color="error"
                            aria-label="delete-validation"
                            onClick={() => {
                              setDeleteValidationItemId(item.id)
                              setOpenDeleteValidation(true)
                            }} >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </div>
                    </Grid>
                  )
                })}
              </Grid>}
              {(calledServiceSettingsOrganizationFieldValidations.length == 0 && organizationFieldId && organizationFieldId.organizationfieldtype && (organizationFieldId.organizationfieldtype == 2 || organizationFieldId.organizationfieldtype == 4)) && <Button
                variant='contained'
                type="button"
                startIcon={<AddIcon />}
                onClick={() => handleOpenCalledServiceSettingOrganizationFieldValidationDialog(true)} >
                Adicionar validação
              </Button>}
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} />
            <Grid item xs={11} sm={11} md={11} lg={8} style={{ marginBottom: '0' }}>
              <Typography style={{ marginBottom: '0', color: 'rgba(0, 0, 0, 0.54)' }}>Imagen informativas (Máximo de 2 arquivos)</Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} />
            {calledServiceSettingsOrganizationFieldAttachments.length < 2 && (<Grid item xs={11} sm={11} md={11} lg={8}>
              <section style={{ width: '100%' }}>
                <div className={props.classes.dropzoneArea} style={{ minHeight: '70px', marginTop: '5px ' }}>
                  <Dropzone maxFiles={2} onDrop={acceptedFiles => validateImagesAtachments([...acceptedFiles, ...files])}>
                    {({ getRootProps, getInputProps, acceptedFiles }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <AttachFileIcon />
                        <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                          <FormattedMessage id="drag.and.drop" />
                        </Typography>
                      </div>
                    )}
                  </Dropzone>
                  {files.map(file => {
                    return (
                      <div className={props.classes.dropzoneItem} key={file.name}>
                        {file.name}
                        <DeleteForever className={props.classes.deleteItem} onClick={() => { removeItem(file) }} />
                      </div>
                    )
                  })}
                </div>
              </section>
            </Grid>)}
            {calledServiceSettingsOrganizationFieldAttachments.length < 2 && <Grid item xs={1} sm={1} md={1} lg={4} />}
            <Grid item xs={11} sm={11} md={11} lg={8}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className={props.classes.attachmentsBox}>
                  <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                    <ul>
                      {calledServiceSettingsOrganizationFieldAttachments.map(item => {
                        return (
                          <li key={item.attachment.path}>
                            <AttachFile /> <Button onClick={() => openLinkFile(item.attachment.url)}>{item.attachment.description}</Button>
                            <DeleteForever onClick={() => deleteOrganizationFieldAttachmentItem(item.id)} style={{ cursor: "pointer" }} />
                          </li>
                        )
                      })}
                    </ul>
                  </ImageList>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} />
          </Grid>) :
            (
              <Grid container spacing={2} style={{ padding: 10 }} >
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }} >
                  <CircularProgress color='secondary' />
                </Grid>
              </Grid>
            )}
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            type="button"
            onClick={close} >
            {intl.formatMessage({ id: "cancel" })}
          </Button>
          {id > 0 && <Button
            type='submit'
            variant='contained'
            color='primary'
            onClick={updateItem} >
            {intl.formatMessage({ id: "save" })}
          </Button>}
        </DialogActions>
      </Dialog>

      <CalledServiceSettingsOrganizationFieldValidationItemDialog
        open={calledServiceSettingOrganizationFieldValidationDialogOpen}
        handleOpen={handleOpenCalledServiceSettingOrganizationFieldValidationDialog}
        handleSetItemList={handleSetCalledServiceSettingOrganizationFieldValidationList}
        userId={userId}
        hirerId={hirerId}
        calledServiceSettingsOrganizationFieldId={id}
        organizationField={organizationFieldId} />

      {loading && (
        <Overlay>
          <CircularProgress color='secondary' />
        </Overlay>
      )}

      <CustomizedSnackbars
        variant={notificationVariant}
        message={notificationMessage}
        isOpen={openNotification}
        toClose={closeNotification} />
    </>
  )

}

UpdateCalledServiceSettingsOrganizationFieldItemDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  itemsCount: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  hirerId: PropTypes.number.isRequired,
  calledServiceSettingsId: PropTypes.number.isRequired,
  organizationId: PropTypes.number.isRequired,
  itemData: PropTypes.object
}

const mapStateToProps = state => ({
  calledservicesettingsorganizationfields: state.calledservicesettingsorganizationfields
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(Object.assign({}, componentActions, validationComponentActions), dispatch)

//props.addCalledServiceSettingsOrganizationFieldValidation(itemToEdit)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(UpdateCalledServiceSettingsOrganizationFieldItemDialog)))