export function addCurrent(additionalcalledcostitemtype) {
  return {
      type: 'ADD_CURRENT',
      additionalcalledcostitemtype,
  }
}

export function addCurrentOrganization(additionalcalledcostitemtype,organization) {
  return {
      type: 'ADD_CURRENT_ORGANIZATION',
      additionalcalledcostitemtype,
      organization,
      }
}