import React, { Component, Fragment } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Typography,
  CircularProgress,
  Container,
  createTheme,
} from "@mui/material";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import CustomizedSnackbars from "../../components/material-snackbars";
import { connect } from "react-redux";
import { ThemeProvider } from '@mui/material/styles';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { injectIntl } from "react-intl";
import PasswordPolicy from '../../components/passwordPolicy/index';

class PasswordReset extends Component {
  constructor(props) {
    super(props);

    this.ButtonExpired = null

    this.state = {
      passwordResetForm: {
        email: "",
        password: "",
        confirmPassword: "",
        phone: "",
        confirmPhone: "",
        active: true,
        configPasswordPolicy: null
      },
      disabledResendButton: false,
      buttonRender: false,
      emailExpired: null,
      loading: false,
      disabledButtons: true,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      userLogged: false,
      colorTheme: Api != null ? Api.getPrimaryColorTheme() : "#3F51B4",
      appName: Api != null ? Api.getAppName() : "CMTECH - Mexx 2DO",
      secondaryColorTheme: Api != null ? Api.getSecondaryColorTheme() : "#277afe",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this)
    this.handleChangeConfirmPhone = this.handleChangeConfirmPhone.bind(this)
    this.validatePassword = this.validatePassword.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.resendEmail = this.resendEmail.bind(this);
  }

  handleChangePhone(e) {
    this.setState(prevState => ({
      passwordResetForm: {
        ...prevState.passwordResetForm,
        ['phone']: e
      }
    }));
  }

  handleChangeConfirmPhone(e) {
    this.setState(prevState => ({
      passwordResetForm: {
        ...prevState.passwordResetForm,
        ['confirmPhone']: e
      }
    }));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {
    document.title = this.state.appName;

    if (this.props.match.params.token) {
      this.getUser(this.props.match.params.token);
    }
  }

  mtel(v) {
	if(v != null){
		v = v.replace(/\D/g, "");
		v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
		v = v.replace(/(\d)(\d{4})$/, "$1-$2");
	}
	else{
		v="";
	}
    return v;
  }

  getUser(token) {
    // console.log(token);
    this.setState({ loading: true });

    let config = {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    };

    config.headers['Authorization'] = `Bearer ${this.props.location.search.substr(1)}`;

    Api.get(`/users/account-confirmation/${token}`, config)
      .then((result) => {
        const intl = this.props.intl;
        if (result.data.success) {
          const { email, phone, configpasswordpolicy } = result.data.data;
          this.setState((prevState) => ({
            loading: false,
            disabledButtons: false,
            passwordResetForm: {
              ...prevState.passwordResetForm,
              email,
              phone: this.mtel(phone),
            },
            configPasswordPolicy: configpasswordpolicy
          }));
        } else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: intl.formatMessage({id:"password.reset.invalid.token"})
          });
          document.getElementById("submit").classList.add("Mui-disabled");
        }
      })
      .catch((err) => {
        const intl = this.props.intl;
        // console.log(err);
        this.setState({
          loading: false,

          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            err.response && err.response.data && err.response.data.errors
              ? err.response.data.errors[0]
              : this.props.intl.formatMessage({id:"process.error"}),
        });
        //document.getElementById("submit").classList.add("Mui-disabled");

        if (err.response && err.response.data && err.response.data.errors && err.response.data.errors[0]) {

          if (err.response.data.errors[0] == intl.formatMessage({id:"link.expired.resend.activation.link"})) {

            this.addButtonExpiredToken()
          }
        }
        // console.log("entrei aqui");
      });
  }

  addButtonExpiredToken() {

    const intl = this.props.intl;

    this.setState({ loading: true });
    Api.get(`/users/get-user-by-token/${this.props.match.params.token}`)
      .then((result) => {
        console.log(result)
        if (result.data.success) {

          this.setState({
            emailExpired: result.data.data.email,
            passwordResetForm: {
              email: result.data.data.email,
            },
          });

        }
      })
      .catch((err) => {

        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            err.response && err.response.data && err.response.data.errors
              ? err.response.data.errors[0]
              : this.props.intl.formatMessage({id:"process.error"}),
        });

      });

    const { classes } = this.props;
    this.ButtonExpired =
      <div className={classes.form} >
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={this.resendEmail}


        >
          {intl.formatMessage({id:"resend.activation.link"})}
        </Button>
      </div>

    this.setState({ buttonRender: true, loading: false });



  }

  resendEmail() {
    const intl = this.props.intl;
    console.log(this.state)

    this.setState({ loading: true });
    let data = new FormData();
    data.append("email", this.state.emailExpired);
    data.append("token", this.props.match.params.token);

    Api.post("/users/resendExpiredEmail", data)
      .then(result => {
        if (result.data.success) {
          this.setState((prevState) => ({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: this.props.intl.formatMessage({id:"email.sent"}),
            disabledResendButton: true

          }));
        }
      })
      .catch(err => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          disabledResendButton: true,
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        });
      });

  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    if (e.target.name === "phone") {
      this.setState((prevState) => ({
        passwordResetForm: {
          ...prevState.passwordResetForm,
          ["phone"]: this.mtel(value),
        },
      }));
    } else if (e.target.name === "confirmPhone") {
      this.setState((prevState) => ({
        passwordResetForm: {
          ...prevState.passwordResetForm,
          ["confirmPhone"]: this.mtel(value),
        },
      }));
    } else {
      this.setState((prevState) => ({
        passwordResetForm: {
          ...prevState.passwordResetForm,
          [name]: value,
        },
      }));
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const intl = this.props.intl;

    if (this.validatePassword() && this.validatePhone()) {
      this.setState((prevState) => ({
        loading: true,
        passwordResetForm: {
          ...prevState.passwordResetForm,
          phone: phone.replace(/\D/gim, ""),
        },
      }));

      const { email, password, phone, active } = this.state.passwordResetForm;

      const ConfirmationGuid = this.props.match.params.token;

      let config = {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      };
  
      config.headers['Authorization'] = `Bearer ${this.props.location.search.substr(1)}`;

      Api.post("/users/change-password", { email, password, phone, active, ConfirmationGuid }, config)
        .then((result) => {
          if (result.data.success) {
            this.setState((prevState) => ({
              loading: false,
              openNotification: true,
              notificationVariant: "success",
              notificationMessage: intl.formatMessage({id:"password.reset.password.registered"}),
              passwordResetForm: {
                ...prevState.passwordResetForm,
                phone: this.mtel(phone),
              },
            }));
            setTimeout(() => {
              this.props.history.push("/");
            }, 1500);
          }else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"password.reset.invalid.token"})
            });
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : this.props.intl.formatMessage({id:"process.error"}),
          });
        });
    }
  };

  validatePassword() {
    const intl = this.props.intl;
    const { passwordResetForm } = this.state;
    if (passwordResetForm.password !== passwordResetForm.confirmPassword) {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"password.reset.passwords.dont.match"}),
      });
      return false;
    } else if (this.state.configPasswordPolicy != null && document.querySelector('[data-testid="CloseIcon"]')) {
      
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"passwordpolicy.errorPolicy"}),
      });
      return false;
      
    } else {
      this.closeNotification();
      return true;
    }
  }

  validatePhone() {
    const intl = this.props.intl;
    const { passwordResetForm } = this.state;
    if (passwordResetForm.phone.replace(' ', '').replace('(', '').replace(')', '').replace('-', '') !== passwordResetForm.confirmPhone.replace(' ', '').replace('(', '').replace(')', '').replace('-', '')) {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"phones.dont.match"}),
      });
      return false;
    } else {
      this.closeNotification();
      return true;
    }
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  render() {
    const { classes } = this.props;
    const { passwordResetForm, userLogged } = this.state;
    console.log(this.state.disabledResendButton)
    console.log("resend aqui")
    const intl = this.props.intl;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    const theme = createTheme({
      palette: {
        primary: {
          main: this.state.colorTheme
        },
        secondary: {
          main: this.state.secondaryColorTheme
        }
      }
    });

    // console.log(passwordResetForm);
    // console.log(classes);
    // console.log(userLogged);

    return (
      <ThemeProvider theme={theme}>
        <div style={{ background: "white", height: '100%' }}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            {" "}
            <CompareArrowsIcon />{" "}
          </Avatar>
          <Typography component="h1" variant="h5">
            {" "}
            {intl.formatMessage({id:"reset.password"})}{" "}
          </Typography>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <TextField
              margin="normal"
              variant="outlined"
              required
              fullWidth
              id="email"
              label={this.props.intl.formatMessage({id:"email"})}
              name="email"
              autoComplete="email"
              value={passwordResetForm.email}
              disabled
            />
            <TextField
              margin="normal"
              variant="outlined"
              required
              fullWidth
              name="password"
              label={intl.formatMessage({id:"password"})}
              type="password"
              id="password"
              autoComplete="current-password"
              inputProps={{ maxLength: 32, minLength: 6 }}
              value={passwordResetForm.password}
              onChange={this.handleInputChange}
              disabled={this.state.disabledButtons}
            />
            <TextField
              margin="normal"
              variant="outlined"
              required
              fullWidth
              name="confirmPassword"
              label={this.props.intl.formatMessage({id:"confirm.password"})}
              type="password"
              id="confirmPassword"
              autoComplete="current-confirmPassword"
              inputProps={{ maxLength: 32, minLength: 6 }}
              value={passwordResetForm.confirmPassword}
              onChange={this.handleInputChange}
              disabled={this.state.disabledButtons}
            />
            <PasswordPolicy
              password={passwordResetForm.password ?? ""}
              configPasswordPolicy={this.state.configPasswordPolicy}
            />
            {/*<TextField
              margin="normal"
              variant="outlined"
              required
              fullWidth
              name="phone"
              label={intl.formatMessage({id:"phone"})}
              type=""
              inputProps={{ maxLength: 15 }}
              id="celphone"
              value={passwordResetForm.phone}
              onChange={this.handleInputChange}
            />
            <TextField
              margin="normal"
              variant="outlined"
              required
              fullWidth
              inputProps={{ maxLength: 15 }}
              name="confirmPhone"
              label={intl.formatMessage({id:"phone.confirmation"})}
              type=""
              id="confirmcelphone"
              value={passwordResetForm.confirmPhone}
              onChange={this.handleInputChange}
            />*/}
            <PhoneInput
              inputStyle={{
                padding: '23.5px 14px 23.5px 58px',
                width: '100%'
              }}
              style={{
                marginTop: 16,
                marginBottom: 8
              }}
              margin="normal"
              id="phone"
              name="phone"
              country={'br'}
              value={passwordResetForm.phone}
              onChange={this.handleChangePhone}
              InputLabel={intl.formatMessage({id:"phone"})}
              specialLabel={intl.formatMessage({id:"phone"})}
              disabled={this.state.disabledButtons}
              inputProps={{
                name: intl.formatMessage({id:"phone"})
              }} />
            <PhoneInput
              inputStyle={{
                padding: '23.5px 14px 23.5px 58px',
                width: '100%'
              }}
              style={{
                marginTop: 16,
                marginBottom: 8
              }}
              margin="normal"
              id="confirmcelphone"
              name="confirmPhone"
              country={'br'}
              value={passwordResetForm.confirmPhone}
              onChange={this.handleChangeConfirmPhone}
              InputLabel={intl.formatMessage({id:"phone.confirmation"})}
              specialLabel={intl.formatMessage({id:"phone.confirmation"})}
              disabled={this.state.disabledButtons}
              inputProps={{
                name: intl.formatMessage({id:"phone.confirmation"})
              }} />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              id="submit"
              disabled={this.state.disabledButtons}
            >
              {this.props.intl.formatMessage({id:"confirm"})}
            </Button>


          </form>
          {!this.state.disabledResendButton && (

            <>
              {this.ButtonExpired}
            </>

          )}


        </div>

        {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}

        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification}
        />
      </Container>
      </div>
      </ThemeProvider>
    );
  }
}

PasswordReset.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userSession: state.userSession,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(PasswordReset)));
