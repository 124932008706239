import Api from './api';

export const getByConfigId = async (configId) => {
    let data = [];
    await Api.get('/configmailbox/configid/'+ configId)
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
}

export const getById = async (id) => {
    let data = [];
    await Api.get('/configmailbox/id/'+ id)
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
}

export const deleteById = async (id) => {
    let data = [];
    await Api.delete('/configmailbox/'+ id)
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
}