import React, { useEffect, useState } from 'react'
import DescriptionIcon from '@mui/icons-material/Description'
import PropTypes from 'prop-types'
import ClearIcon from '@mui/icons-material/Clear'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import ReactHtmlParser from 'react-html-parser'
import { FormattedMessage, injectIntl } from 'react-intl'
import { withStyles } from '@mui/styles'
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, ImageList } from '@mui/material'
import { AttachFile } from '@mui/icons-material'
import { Styles } from '../../../styles/material-styles'
import MiniMap from '../../../components/minimap/external-state'
import SimpleAutoComplete from '../../../components/auto-complete/autocomplete'

const useStyles = _ => {
  return withStyles({
    ImageList: {
      width: "100%",
      height: 100,
    },
    icon: {
      height: 32,
      width: 32
    }
  })
}

function KnowledgebaseDetailsDialog(props) {

  const { classes, close, copyFileLink, getFile, intl, label } = props;
  const classes2 = useStyles();

  if (props.knowledgeBaseItem != null) {
    return (
      <Dialog
        maxWidth="md"
        scroll={'body'}
        open={props.open}
        onClose={close}
        aria-labelledby="knowledgebase-dialog-title" >
        <DialogTitle id="knowledgebase-dialog-title">
          {label}
          <ClearIcon onClick={close} style={{ float: 'right', cursor: 'pointer' }} />
        </DialogTitle>
        <DialogContent className="knowledgebase-dialog-client-details">
          <Grid container spacing={0} style={{ marginTop: 15 }}>
            <Grid item xs={12} style={{ marginBottom: 15 }}>
              <h5><FormattedMessage id="description" />:</h5>
              <div style={{ padding: '5px 10px', border: '1px solid rgba(0, 0, 0, 0.5)', borderRadius: 10 }} >{ReactHtmlParser(props.knowledgeBaseItem.description)}</div>
            </Grid>
            { (props.knowledgeBaseItem.knowledgebaseknowledgebasekeywords && props.knowledgeBaseItem.knowledgebaseknowledgebasekeywords.length > 0) && <Grid item xs={12} style={{ marginBottom: 15 }}>
              <h5><FormattedMessage id="keywords" />:</h5>
              <div className='kb-keys' style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                {props.knowledgeBaseItem.knowledgebaseknowledgebasekeywords.map(item => {
                  return <div className='kb-key' style={{ marginTop: 15 }}>{item.knowledgebasekeyword.word}</div>
                })}
              </div>
            </Grid>}
            {props.knowledgeBaseItem.knowledgebaseattachments.length > 0 && (
            <Grid item xs={12}>
              <div className={classes.attachmentsBox}>
                <label>{<FormattedMessage id="attachments" />}</label>
                <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                  <ul>
                  {props.knowledgeBaseItem.knowledgebaseattachments.map(file => {
                    return (<li key={file.attachment.id}>
                    <AttachFile /> <Button onClick={() => getFile(file.attachment)}>{file.attachment.description}</Button>
                    <Tooltip title={<h5>Copiar link de download</h5>}>
                      <IconButton aria-label="textevidence"
                        onClick={ () => copyFileLink(file.attachment)}>
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                    </li>)
                  })}
                  </ul>
                </ImageList>
              </div>
            </Grid>
          )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={4} justify="flex-end" style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 15 }}>
            <Grid item xs={12} sm={4}>
              <Button
                variant='contained'
                type="button"
                onClick={close}
                fullWidth >
                {intl.formatMessage({id:"close"})}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  } else
    return (<React.Fragment></React.Fragment>)
}

KnowledgebaseDetailsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  knowledgeBaseItem: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  copyFileLink: PropTypes.func.isRequired,
  getFile: PropTypes.func.isRequired,
}

export default injectIntl(withStyles(Styles)(KnowledgebaseDetailsDialog))