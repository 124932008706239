import Api from './api';
import Api2Talk from './api2talk';
export const getUsers = async () => { 
  let data = [];
  await Api.get('/users/techinical')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};
export const getUsersWithoutTeam = async (techinicalteamid) => { 
	let data = [];
	await Api.get(`/users/techinical/${techinicalteamid}`)
		.then(result => {
			data = result.data
		 })
		 .catch(err => {
			data = err;
	});
	return data;
}

export const getUsersWithoutTeamSelectionModel = async (techinicalteamid) => { 
	let data = [];
	await Api.get(`/users/techinical/${techinicalteamid}/selectionmodel`)
		.then(result => {
			data = result.data
		 })
		 .catch(err => {
			data = err;
	});
	return data;
};
  
export const getTechinicalTeams = async () => { 
  let data = [];
  await Api.get('/techinicalteams')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getAllByTechnicalTeamId = async (id) => {
  let data = []
  await Api.get(`/techinicalteamusers/technicalteam/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getAllTechByTeamAndEnableUser2talk = async (techinicalteamid) => { 
	let data = [];
	await Api2Talk.get(`/users/techinicalteamid/enabled/${techinicalteamid}`)
		.then(result => {
			data = result.data
		 })
		 .catch(err => {
			data = err;
	});
	return data;
};