import { HttpTransportType, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import IntlProviderConfigured from './_i18n/intlproviderconfigured';
import { useChatHubContext } from './contexts/chatHubContext';
import { browserHistory } from './helpers/history';
import './paginate.css';
import { persistor } from './redux/store';
import Routes from './routes';
import { baseURL } from "./services/api";
import theme from './styles/theme';
import { PapiroConsole } from './utils/papiroConsole';

import { useMessageHub2TalkContext } from './contexts/MessageHub2TalkContext';
import { baseURLApiSignalrHub, baseURLCone } from "./services/api";


function App() {
    const { 
      setChatHubConnection,
      setOpenChatListInitialValue,
      setClosedChatListInitialValue,
      setAllNotifications,
      
      setNewNotification,
      setNewPrivateMessage,
      setNotificationSound,
      setOpenClientChat,
      loadChatMessages,
      handleNewChat,
      UpdateChatInTheList,
      closeChat,
      ShowError,
      Ok,
      sendAccompanimentNotification,
    } = useChatHubContext();

    const { 
      setChatHubConnection2Talk,
      setNotificationSound2Talk,
      CloseChat2Talk,
      ShowError2Talk,
      InitChat2Talk,
      ReceiveNotification,
      ReceiveMessage,
      ReceiveNewChat,
      MessageSent,
      NewItemNotification,
      setAllNotificationsBy2TalkHub

    } = useMessageHub2TalkContext();

    const userSession = useSelector((state) => state.userSession);

    const isClient = (userSession) => {
      let isClient = true;
      userSession.user.userrole.map(item => {
        if (item.role.roletype != 3) {
            isClient = false
        }
      })
      return isClient
    };
    
    async function start2Talk(chatHub2Talk) {
      //console.log(`@log >> ${JSON.stringify(chatHub2Talk, null, 2)}`);
      try {
        await chatHub2Talk.start().then(function (item) {

          setChatHubConnection2Talk(chatHub2Talk);

    
        
           console.log("SignalR Connected.");
           

        }).catch(function (err) {
            return console.error(err.toString());
        });
      } catch (err) {
          console.log(err);
          //setTimeout(start, 5000);
      }
    };

    const removeTrailingSlash = (url) => {
      if (url.endsWith('/')) return url.slice(0, -1);
      return url;
    }


    const handleHubConnection2Talk = async (accessToken, userId) => {
      try {
        //PapiroConsole.log("=== handleHubConnection => accessToken ===")
        //PapiroConsole.log(accessToken)
        let chatHub2Talk = null; 
        //alert(baseURLApiSignalrHub);
        //alert(baseURLCone);

        if(baseURLApiSignalrHub != null){

            let baseUrlHub = removeTrailingSlash(baseURLApiSignalrHub);
           //alert(baseUrlHub);
           chatHub2Talk = new HubConnectionBuilder()
           .withUrl(`${baseUrlHub}/hub/notification`, { accessTokenFactory: () => accessToken,
             withCredentials: true,
             skipNegotiation: true,logMessageContent: true ,
            // eslint-disable-next-line no-undef
                transport: HttpTransportType.WebSockets,
                }).configureLogging(LogLevel.Information)
                .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000, 40500, 60500, 80500, 90500, 100000])
                .build();
    
        }
        else{
          chatHub2Talk = new HubConnectionBuilder().withUrl(`${baseURLCone}api/omnichannel`, { accessTokenFactory: () => accessToken, skipNegotiation: true,
        // eslint-disable-next-line no-undef
            transport: HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000, 40500, 60500, 80500, 90500, 100000])
            .build();
        }





  
        chatHub2Talk.onreconnecting((error) => {
          
          PapiroConsole.log("reconectando")
          
        });
    
        chatHub2Talk.onreconnected((connectionId) => {
  
          PapiroConsole.log("reconexão feita")
        });
        chatHub2Talk.onclose(async () => {
          //await start2Talk(chatHub2Talk);
        });
        chatHub2Talk.on("Log", (message, log) => {
          PapiroConsole.log("=== conversations.js - chatHub2Talk => message, log ===")
          PapiroConsole.log(message)
          PapiroConsole.log(log)
          alert(message);
        });
        chatHub2Talk.on("OnConnected", (connectionid, userid, message) => {
          PapiroConsole.log("=== conversations.js - hub connected ===")
          userSession.connectionid= connectionid;
          //userSession.addUserSession(userSession)

          //alert(connectionid);
          localStorage.removeItem('connectionid2talk')
          //localStorage.setItem('connectionid2talk', `${connectionid}`)
  
        });

        chatHub2Talk.on("ShowError", ShowError2Talk);
    
        chatHub2Talk.on("InitChat", InitChat2Talk)
  
        chatHub2Talk.on("PlayNotificationSound", setNotificationSound2Talk);
  
        chatHub2Talk.on("CloseChat", CloseChat2Talk);
        chatHub2Talk.on("ReceiveNotification", ReceiveNotification);
        chatHub2Talk.on("ReceiveMessage", ReceiveMessage);
        
        chatHub2Talk.on("ReceiveNewChat", ReceiveNewChat);
        chatHub2Talk.on("MessageSent", MessageSent);
        chatHub2Talk.on("NewItemNotification", NewItemNotification);
        
        chatHub2Talk.on("ListNotifications", setAllNotificationsBy2TalkHub);
        
        chatHub2Talk.on("RegisterConnectionId", (ok, connections) => {
        });
  
        await start2Talk(chatHub2Talk);
        
      
        if(userSession && userSession.user){
          //await chatHub2Talk.invoke("ListClientOpenChats", userId);  
  
        }
        
      } catch(err) {
          PapiroConsole.log("=== handleHubConnection -> error === ")
          console.error(err);
          //alert(err.message);
      }
    }
  
    const handleHubConnection = async (accessToken, userId) => {
        try {
          //PapiroConsole.log("=== handleHubConnection => accessToken ===")
          //PapiroConsole.log(accessToken)
          PapiroConsole.log(baseURL)
          PapiroConsole.log("baseURL")
          PapiroConsole.log("accessToken")
          PapiroConsole.log(accessToken)
          PapiroConsole.log(userId)
          const chatHub = new HubConnectionBuilder()
          .withUrl(`${baseURL}/chat`,  { accessTokenFactory: () => accessToken, skipNegotiation: true,
            UseDefaultCredentials: true,
          // eslint-disable-next-line no-undef
          transport: HttpTransportType.WebSockets })
          .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000, 40500, 60500, 80500, 90500, 100000])
          .build();

          chatHub.onreconnecting((error) => {
            
            PapiroConsole.log("reconectando")
            
          });

          chatHub.onreconnected((connectionId) => {
		
            PapiroConsole.log("reconexão feita")
          });
          
          
          await chatHub.start();

          setChatHubConnection(chatHub);
  
          chatHub.on("Log", (message, log) => {
            PapiroConsole.log("=== APP.js - chatHub => message, log ===")
            PapiroConsole.log(message)
            PapiroConsole.log(log)
          });

          PapiroConsole.log("entrei aqui no chathub")
          chatHub.on("ShowError", ShowError);

          chatHub.on("Ok", Ok);

          chatHub.on("InitChat", handleNewChat)

          chatHub.on("InitChatByTech", handleNewChat)
  
          chatHub.on("SendPrivateMessage", setNewPrivateMessage);

          chatHub.on("PlayNotificationSound", setNotificationSound);

          

          //chatHub.on("SendAttachment", setNewPrivateMessage);


  
          chatHub.on("ListOpenChats", setOpenChatListInitialValue)
          
          chatHub.on("ListClosedChats", setClosedChatListInitialValue)

          chatHub.on("ListNotifications", setAllNotifications)

          chatHub.on("NewNotification", setNewNotification)


          

          

          chatHub.on("ListClientOpenChats", setOpenChatListInitialValue)

          chatHub.on("ClientOpenChat", setOpenClientChat)
          
          chatHub.on("LoadChatMessages", loadChatMessages);

          chatHub.on("SendAccompanimentNotification", sendAccompanimentNotification);

          

          chatHub.on("ResetNotSeenMessages", UpdateChatInTheList);
          
          chatHub.on("CloseChat", closeChat);
          
          chatHub.on("RegisterConnectionId", (ok, connections) => {
            //PapiroConsole.log(ok)
            //PapiroConsole.log(connections)
          });

          PapiroConsole.log("estou aqui")

          PapiroConsole.log(userSession)
          // Lista chat de técnico ou chat de cliente
          if(userSession && userSession.user){
            PapiroConsole.log("entrei aqui")
            isClient(userSession) ? await chatHub.invoke("ListClientOpenChats", userId) : await chatHub.invoke("ListOpenChats", userId);  
            await chatHub.invoke("ListClosedChats", userId)
            await chatHub.invoke("ListNotifications", userId)

          }
          
        } catch(err) {
          PapiroConsole.log("=== handleHubConnection -> error === ")
          PapiroConsole.log(err);
        }
    }

    useEffect(() => {
      try {
      
        if (userSession && userSession.user) {
          
          const { id, accesstoken,accesstoken2talk } = userSession.user;

          var available = false 
          if(userSession.user && userSession.user.productsavailables){
            var productsavailables = userSession.user.productsavailables;
            productsavailables.forEach(element => {
              if (element.id == 2) {
                available = true
              }
            });
          }
          //alert(available);
          //alert(accesstoken2talk);
          if(accesstoken2talk){
            handleHubConnection2Talk(accesstoken2talk, id);
          }

          axios.get(`${baseURL}/users/verifyToken`, {
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': "Bearer "+accesstoken },
          }).then(result => {
              if(result.data.success){
            //    console.log(userSession)
          //console.log("userSession")
          PapiroConsole.log("entrei no hub connection")
                handleHubConnection(accesstoken, id);
              }
          //console.log("verifyToken")
          //console.log(result)            
          })
          .catch(err => {
              
          console.log("verifyToken")
          console.log(err)
          });

          
        }
      } catch(err) {
        throw err;
      }
    }, []);

    return (
        <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
                <Router history={browserHistory}>
                    <IntlProviderConfigured>
                        <Routes>
                    
                        </Routes>
                    </IntlProviderConfigured>                            
                </Router>
            </ThemeProvider>
        </PersistGate>

    );
}

export default App;