import { colors } from '@mui/material';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: '#3f51b4',
    main: '#3f51b4',
    light: '#3f51b4'
  },
  secondary: {
    contrastText: white,
    dark: '#277afe',
    main: '#277afe',
    light: '#277afe'
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    title: white,
    link: colors.blue[600]
  },
  background: {
    default: '#FFF',
    paper: white
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
  admin: {
    contrastText: white,
    dark: colors.grey[200],
    main: colors.grey[200],
    light: colors.grey[200]
  },
};
