import Api from './api';

export const getContract = async () => { 
  let data = [];
  await Api.get('/hirer/contract')
      .then(result => {
          data = result
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getByLoggedUser = async () => { 
  let data = [];
  await Api.get(`/hirer/get`)
      .then(result => {
          data = result
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getById = async (id) => { 
  let data = [];
  await Api.get(`/hirer/${id}`)
      .then(result => {
          data = result
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const update = async (formData) => { 
  let data = [];

  let config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  await Api.put(`/hirer/contract`, formData, config)
      .then(result => {
          data = result
       })
       .catch(err => {
          data = err;
  });
  return data;
};