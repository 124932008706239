import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'

import { Styles } from '../../../../styles/material-styles'
import { Grid } from '@mui/material'
import moment from 'moment'
import Attachment from '../attachment'
import Api from '../../../../services/api'
import { renderToStaticMarkup } from "react-dom/server"
import parse from "html-react-parser"
import { AttachFile } from '@mui/icons-material'
import { getFileDownloadLink } from '../../../../services/attachment.service'
import Link from "@mui/material/Link"
import { PapiroConsole } from '../../../../utils/papiroConsole'
import DOMPurify from 'dompurify'

function CalledActions(props) {

  const { userSession } = props
  const [isClient, setIsClient] = useState(true)

  useEffect(() => {
    if (userSession == null ||  userSession.user == null || userSession.user.userrole == null)
      Api.logoff()

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3)
        setIsClient(false)
    })
  }, [])

  const getFile = async attachment => {
		props.setLoading(true)
		
		let url = await getFileDownloadLink(attachment.id)
		if(url &&  url.data && url.data.data && url.data.success){
      props.setLoading(false)
			window.open(`${url.data.data}`, '_blank');
		} else{
        props.setMessage(true, 'error', props.intl.formatMessage({ id: "file.not.found" }))
        props.setLoading(false)
    }
  }

  const configureDescription = action => {
    PapiroConsole.log("=== configureDescription ===")
    let reg = new RegExp(/\@\@{.*\|\@\|.*\}$$/gm)
    let testReg = reg.test(action.description.trim())

    if(action.description.trim().startsWith("<p> &lt;h") || action.description.trim().startsWith("<p>&lt;")) {
      PapiroConsole.log("Erro -> 1")
      PapiroConsole.log(action.description)
      let actionDescription = action.description
      actionDescription = DOMPurify.sanitize(actionDescription)
      return renderToStaticMarkup(actionDescription)
    } else if(action.description.trim().startsWith("<div style=\"") || action.description.trim().startsWith("<div dir=") || action.description.trim().startsWith("<p>") || action.description.trim().startsWith("<h1>") || action.description.trim().startsWith("<h2>") || action.description.trim().startsWith("<h3>")) {
      PapiroConsole.log("Erro -> 2")
      PapiroConsole.log(action.description)
      let actionDescription = action.description
      actionDescription = DOMPurify.sanitize(actionDescription)
      return actionDescription
    } else if(action.description.trim().endsWith(".webm") || action.description.trim().endsWith(".webm}")) {
      let descriptionSplice = action.description.split(" : ");
      if(descriptionSplice.length > 0) {
        if(descriptionSplice[0] && descriptionSplice[1]) {      
          let newDescription = <p> {descriptionSplice[0].replace(props.intl.formatMessage({id:"message.sent"}), props.intl.formatMessage({id:"video.sent"}))} <br/> <video key={'downloadVideo0'} id={'downloadVideo0'} style={{ width: '310px', height: '310px' }} playsInline controls><source src={descriptionSplice[1]} type='video/webm'/></video> </p>
          PapiroConsole.log("Erro -> 3")
          PapiroConsole.log(newDescription)
          newDescription = DOMPurify.sanitize(newDescription)
          return renderToStaticMarkup(newDescription)
        }
      }
    } else if(testReg) {
      let descriptionSplice = action.description.split(" : ")
      if(descriptionSplice.length > 0) {
        if(descriptionSplice[0] && descriptionSplice[1]) {
          let newAction = descriptionSplice[1].slice(3, -1)
          let newActionSplice = newAction.split("|@|")
          if(newActionSplice.length > 0) {
            if(newActionSplice[0] && newActionSplice[1]) {
              let title = newActionSplice[1]
              if(title.trim().endsWith("}"))
                title = title.slice(0, -1)
							if(action.description.trim().endsWith(".pdf") || action.description.trim().endsWith(".pdf}")) {	
								let newDescription = <p> {descriptionSplice[0].replace(props.intl.formatMessage({id:"message.sent"}), props.intl.formatMessage({id:"document.sent"}))} <br/> <a href={newActionSplice[0]}  target="_blank"> {title} </a> </p>
                PapiroConsole.log("Erro -> 4")
                PapiroConsole.log(newDescription)
                newDescription = DOMPurify.sanitize(newDescription)
								return renderToStaticMarkup(newDescription)
							} else {
								let newDescription = <p> {descriptionSplice[0].replace(props.intl.formatMessage({id:"message.sent"}), props.intl.formatMessage({id:"video.sent"}))} <br/> <a href={newActionSplice[0]}  target="_blank"> {title} </a> </p>
                PapiroConsole.log("Erro -> 5")
                PapiroConsole.log(newDescription)
                newDescription = DOMPurify.sanitize(newDescription)
								return renderToStaticMarkup(newDescription)
							}
            }
          }
        }
      }
    } else if(action.description.trim().endsWith(".pdf") || action.description.trim().endsWith(".pdf}")) {
			let descriptionSplice = action.description.split(" : ")
      if(descriptionSplice.length > 0){
        if(descriptionSplice[0] && descriptionSplice[1]){
          let newDescription = <p> {descriptionSplice[0].replace(props.intl.formatMessage({id:"message.sent"}), props.intl.formatMessage({id:"document.sent"}))} <br/> <a href={descriptionSplice[1]}  target="_blank"> {descriptionSplice[1]} </a> </p>
          PapiroConsole.log("Erro -> 6")
          PapiroConsole.log(newDescription)
          newDescription = DOMPurify.sanitize(newDescription)
          return renderToStaticMarkup(newDescription)
        }
      }   
    } else if(action.description.trim().endsWith(".jpg") || action.description.trim().endsWith(".png") || action.description.trim().endsWith(".jpeg") || action.description.trim().endsWith(".jpg}") || action.description.trim().endsWith(".png}") || action.description.trim().endsWith(".jpeg}")) {
      let descriptionSplice = action.description.split(" : ");
      if(descriptionSplice.length > 0) {
        if(descriptionSplice[0] && descriptionSplice[1]) {
          let newDescription = <p> {descriptionSplice[0]} <br/> <a href={descriptionSplice[1]}  target="_blank"> {descriptionSplice[1]} </a> </p>
          PapiroConsole.log("Erro -> 7")
          PapiroConsole.log(newDescription)
          newDescription = DOMPurify.sanitize(newDescription)
          return renderToStaticMarkup(newDescription)
        }
      }    
    } else if(action.description.trim().startsWith("<div>")) {
      let actionDescription = action.description
      actionDescription = DOMPurify.sanitize(actionDescription)
      return actionDescription
    }

    let actionDescription = action.description
    actionDescription = DOMPurify.sanitize(actionDescription)
    return renderToStaticMarkup(<p>{actionDescription}</p>)
  }

  if (props != null && props.calledData != null && props.calledData.accompaniments != null && props.calledData.accompaniments.length > 0) {
    return (
      <Grid container spacing={0} sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
        {props.calledData.accompaniments.map(item => {
          if (!isClient || (isClient && item.privacy)) {
            return <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ paddingTop: '5px', paddingBottom: '5px' }}>
              <div style={{ padding: 10, backgroundColor: 'rgba(189, 189, 189, .3)', borderRadius: 4, display: 'flex' }}>
                <div style={{ marginRight: 4, minWidth: 4, width: 4, height: 'inherit', borderRadius: 4, backgroundColor: !item.privacy ? '#3F51B4' : '#F3202A' }} ></div>
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0, display: 'inline', fontWeight: '600' }}>{item && item.createduserdata && item.createduserdata.name}</h5>
                    <h6 style={{ color: "#58595B", fill: "#58595B", margin: 0, marginLeft: 10, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}><FormattedMessage id="date" />: {item && item.createdat && moment(item.createdat).format("DD/MM/YYYY HH:mm")}</h6>
                    {!isClient && <span style={{ marginLeft: 10, fontSize: 11, fontWeight: "normal", fontStyle: "italic", marginLeft: 10 }}>{item.privacy ? props.intl.formatMessage({id:"private.action"}) : props.intl.formatMessage({id:"public.action"})}</span>}
                  </div>
                  <div style={{ marginTop: 20, fontWeight: 'normal' }}>
                    {parse(configureDescription(item))}
                  </div>
                  {(item.accompanimentattachments != null && item.accompanimentattachments.length > 0) && <div className={props.classes.attachmentsBox} style={{border: 0}}>
                    <ul>
                      {item.accompanimentattachments.map(item => {
                        return (<li key={item.attachment.path}>
                          <AttachFile /> <Link onClick={() => getFile(item.attachment)}>{item.attachment.description}</Link>
                        </li>)
                      })}
                    </ul>
                  </div>}
                </div>
              </div>
            </Grid>
          }
      })}
      </Grid>
    )
  } else {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center', paddingTop: '20px' }}>
          <FormattedMessage id="no.data" />
        </Grid>
      </Grid>
    )
  }
}

CalledActions.propTypes = {
  classes: PropTypes.object.isRequired,
  setMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession
})

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(CalledActions)))