import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleCheckBox from '../../components/checkbox/check';
import MaterialTable from 'material-table';
import { Can } from '../../config/ability-context'
import * as Service from '../../services/scheduleworkhour.service';
import * as componentActions from '../../redux/actions/workhours';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import * as CalledService from '../../services/called.service';

import Title from '../../components/title-name';
import SimpleAutoCompleteMultiple from '../../components/auto-complete/autocomplete-multiple';
import CustomTimePicker from '../../components/custom-time-picker';
import { PapiroConsole } from '../../utils/papiroConsole';

class WorkHourEdit extends Component {

  constructor(props) {
    super(props);
            const { userSession,workhours } = this.props;
   

    this.globalAdm = false;
    this.isClient = true;
    if(userSession.user.userrole == null){
      Api.logoff();
    }

    if(!workhours || (workhours && !workhours.workhour)) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/calleds`
    }

    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

    var organizations = []
    if(workhours.workhour.organizationworkhours != null) {
      workhours.workhour.organizationworkhours.map(item => {
        if (item.organization && typeof item.organization.id !== undefined)
          organizations.push(item.organization)
      })
    }
    
    this.initialState = {
      UpdatedUser: userSession.user.id,
      id: workhours.workhour.id,
      name : workhours.workhour.name == null ? '' : workhours.workhour.name , 
      comments : workhours.workhour.comments == null ? '' : workhours.workhour.comments , 
      active : workhours.workhour.active,
      organization: organizations ? organizations : []
    };  
 
          
    this.state = {
      workhour: this.initialState,
      resultList: [],
      WorkHourList: [],
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      organizationsList: [],
      allOrgs: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.addItem = this.addItem.bind(this);
    this.timeConvert = this.timeConvert.bind(this);
    this.changeValuesCheck = this.changeValuesCheck.bind(this);
  }

  timeConvert(num)
    { 
      
      
        var hms = num;   // your input string
        var a = hms.split(':'); // split it at the colons

        // Hours are worth 60 minutes.
        var minutes = (+a[0]) * 60 + (+a[1]);

        return minutes;
    }

    deleteItem(data, resolve) {
      
        this.setState({
          loading: true,
        });
        /*
        let abl = this.context.can('Delete', 'ScheduleWorkHour');
        if (abl == false){
          const intl = this.props.intl;
            this.setState({loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: intl.formatMessage({id:"operation.notAllowed"})});
            resolve(); return false;
        }*/

        Api.delete(`/scheduleworkhours/${data.id}`)
        .then(result => {
            if (result.data.success) {
              const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: 'success',
                    notificationMessage: intl.formatMessage({id:"delete.sucess"}),
                    resultList: []
                    });

                this.getItens();
            }
            else{
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(result);
            }
            resolve();
        })
        .catch(err => {
            resolve();
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
            Api.kickoff(err);
        });

    };

    async getOrganizations() {
      this.setState({ loading: true });
      const { userSession } = this.props
      let orgData = []
      if(userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
        orgData = userSession.user.userorganizations
      } else {
        const result = await CalledService.getOrganizations();
        if (result.success) {
          orgData = result.data
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
          });
          Api.kickoff(result);
    
        }
      }

      if(orgData != null) {
        this.setState({ loading: false, organizationsList: orgData });
      }
    };

    updateItem(data, resolve) {
        /*    
        for (let key in data) {
            if (key.toString().toLowerCase().indexOf('starthour') > -1 || key.toString().toLowerCase().indexOf('endhour') > -1) {
                data[key] = this.timeConvert(moment(data[key]).format("HH:mm"));
            }
        }
*/  
       
        data['workhourid'] = this.state.workhour.id;

        Api.put('/scheduleworkhours', data)
        .then(result => {
          if (result.data.success) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"edit.success"}),
              item: this.initialState,
              files: []
            });
            this.getItens();
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})+err.response.data
            });
          }
          Api.kickoff(err);
        });
    };

    addItem(data, resolve) {

      PapiroConsole.log("entrei aqui ")
      PapiroConsole.log("data ")
      PapiroConsole.log(data)

            /*for (let key in data) {
                if (key.toString().toLowerCase().indexOf('starthour') > -1 || key.toString().toLowerCase().indexOf('endhour') > -1) {
                  console.log("entrei aqui")
                  console.log(data[key])
                    data[key] = this.timeConvert(moment(data[key]).format("HH:mm"));
                    console.log(data[key])
                   
                }
            }*/

            data['workhourid'] = this.state.workhour.id;

            Api.post('/scheduleworkhours', data)
            .then(result => {
            if (result.data.success) {
                const intl = this.props.intl;
                this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({id:"add.success"}),
                item: this.initialState,
                files: []
                });
                this.getItens();
            }
            else{
                const intl = this.props.intl;
                this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})

                });
                Api.kickoff(result);
            }

            })
            .catch(err => {
            if(err.response && err.response.data && err.response.data.errors){
              const intl = this.props.intl;
                this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                });
            }
            else{
              const intl = this.props.intl;
                this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})+err.response.data
                });
            }
            Api.kickoff(err);
            });
    };


  componentDidMount() {
    this.getWorkHours();
    this.getItens();
    this.getOrganizations();
  };
    
     async getWorkHours(){

      var result = await Service.getWorkHours();
      if (result.success) {

          var formattedList = [];
          result.data.forEach(element => formattedList[element.id] = element.name);

          this.setState({ loading: false, WorkHourList: formattedList });

      } else {
        const intl = this.props.intl;
          this.setState({
              loading: false, openNotification: true, notificationVariant: 'error',
              notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
          });
          Api.kickoff(result);  
      }
  };


  getItens() {
      
      Api.get(`/scheduleworkhours/workHours/${this.state.workhour.id}`)
          .then(result => {

              if (result.data.success) {

                  const formattedList = result.data.data.map(item => {
                      
                      return {
                          ...item,
                              WorkHourId: item.workhour ? item.workhour.id : 0,
                              WorkHourText: item.workhour ? item.workhour.name : '',
                              //starthour: this.getTimeFromMins(item.starthour),
                              //endhour: this.getTimeFromMins(item.endhour)
                              starthour: item.starthour,
                              endhour: item.endhour
                  }
                  });

                  PapiroConsole.log(formattedList)
                  PapiroConsole.log("formattedList")
                  
              this.setState({ loading: false, resultList: formattedList });

			  console.log(formattedList);
          }else{
            const intl = this.props.intl;
              this.setState({
                  loading: false, openNotification: true, notificationVariant: 'error',
                  notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);
          }
      })
      .catch(err => {
        const intl = this.props.intl;
          this.setState({
              loading: false, openNotification: true, notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
          });
          Api.kickoff(err);

      });
  };

  getTimeFromMins(mins) {
      // do not include the first validation check if you want, for example,
      // getTimeFromMins(1530) to equal getTimeFromMins(90) (i.e. mins rollover)
      if (mins >= 24 * 60 || mins < 0) {
          throw new RangeError("Valid input should be greater than or equal to 0 and less than 1440.");
      }
      var h = mins / 60 | 0,
          m = mins % 60 | 0;
      return moment.utc().hours(h).minutes(m).format("HH:mm");
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      workhour: {
        ...prevState.workhour,
        [name]: value
      }
    }));
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
          workhour: {
            ...prevState.workhour,
          }
        }));
      } else {
        this.setState(prevState => ({
          workhour: {
            ...prevState.workhour,
          }
        }));
      }


      for (let key in this.state.workhour) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.workhour[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else if (key.toString().toLowerCase().indexOf('organization') > -1) {

          if(this.state.workhour[key] && this.state.workhour[key].length > 0){
            let orgs = ""
            for (let value in this.state.workhour['organization']) {
              orgs = this.state.workhour['organization'][value].id + "," + orgs
            }
            data.append('organizationids', orgs);
          }else{
            data.append('organizationids', '');
          }

        } else {
          data.append(key, this.state.workhour[key]);
        }
      }

      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
        Api.put('/workhours', data, config)

        .then(result => {
          if (result.data.success) {
            const intl = this.props.intl;
            const formattedList = result.data.data;

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"edit.success"}),
              files: []
            });
        this.props.addCurrent(formattedList);
        this.componentDidMount();
 
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage:  result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({id:"process.error"})
            });

          }
        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})+err.response.data
            });
          }
        });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValuesCheck = (stateName, value, text = '') => {
    if (value === true) {
      this.setState(prevState => ({
        workhour: {
          ...prevState.workhour,
          organization: this.state.organizationsList
        },
        [stateName]: value
      }))
    } else {
      this.setState({ [stateName]: value })
    }
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    if (stateName === "organization") {
      if (this.state.organizationsList.length == this.state.workhour.organization.length)
        this.setState({ allOrgs: true })
      else
        this.setState({ allOrgs: false })
    }

    this.setState(prevState => ({
      workhour: {
        ...prevState.workhour,
        [stateName]: value
      }
    }));
 
    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
   timeOptions(){
	var result = {
		0:"00:00",
		30:"00:30",
		60:"01:00",
		90:"01:30",
		120:"02:00",
		150:"02:30",
		180:"03:00",
		240:"03:30",
		210:"04:00",
		270:"04:30",
		300:"05:00",
		330:"05:30",
		360:"06:00",
		390:"06:30",
		420:"07:00",
		450:"07:30",
		480:"08:00",
		510:"08:30",
		540:"09:00",
		570:"09:30",
		600:"10:00",
		630:"10:30",
		660:"11:00",
		690:"11:30",
		720:"12:00",
		750:"12:30",
		780:"13:00",
		810:"13:30",
		840:"14:00",
		870:"14:30",
		900:"15:00",
		930:"15:30",
		960:"16:00",
		990:"16:30",
		1020:"17:00",
		1050:"17:30",
		1080:"18:00",
		1110:"18:30",
		1130:"19:00",
		1170:"19:30",
		1200:"20:00",
		1230:"20:30",
		1260:"21:00",
		1290:"21:30",
		1320:"22:00",
		1350:"22:30",
		1380:"23:00",
		1410:"23:30",
	};

	for(var i=0; i<24; i++){
		var value = "0"
		

	}
	return result;

   }
   render() {
      const intl = this.props.intl;

      if(Api == null) {
        var l = this.props.match.params.lang;
        document.location.href=`/${l}/unavailable`
      }
    
      const bodyListWork = () => {
		var startOptions = this.timeOptions();
		var endOptions = this.timeOptions();

    console.log(startOptions)
    console.log(endOptions)
    console.log("testando aqui")
		
        return (
         
                  <Container component='main' maxWidth='xl'>
                      <CssBaseline />
                      <Can I='List' a='ScheduleWorkHour'>
                          <MaterialTable
                              title={intl.formatMessage({id:"work.schedule"})}
                              columns={[
								{ title: intl.formatMessage({id:"week.day"}), field: 'weekday', lookup: {0: 'Domingo', 1: 'Segunda', 2: 'Terça', 3: 'Quarta', 4:'Quinta', 5:'Sexta', 6:'Sábado'} },
								{ title: intl.formatMessage({id:"start.time"}), field: 'starthour', lookup: startOptions },
								{ title: intl.formatMessage({id:"end.time"}), field: 'endhour', lookup: endOptions },
								//{ title: intl.formatMessage({id:"start.time"}), field: 'starthour', editComponent: props => <CustomTimePicker type={'time'} value={props.value} onChange={props.onChange} /> },
								//{ title: intl.formatMessage({id:"end.time"}), field: 'endhour', editComponent: props => <CustomTimePicker type={'time'} value={props.value} onChange={props.onChange} /> },
								
							]}
                              data={this.state.resultList}
                              editable={{
                                  onRowAdd: newData =>
                                      new Promise(resolve => {
                                          setTimeout(() => {
                                              resolve();
                                              this.addItem(newData,resolve);
                                            }, 600);
                                      }),
                                  onRowUpdate: (newData, oldData) => 
								  		
                                      new Promise(resolve => {
										
                                          setTimeout(() => {
                                              resolve();
                                              this.updateItem(newData,resolve);
                                          }, 600);
										
                                  }),
                                  onRowDelete: oldData =>
                                      new Promise(resolve => {
                                          this.deleteItem(oldData,resolve);
                                      }),
                              }}
                              localization={{
                                grouping: {
                                  groupedBy: intl.formatMessage({id:"grouped.by"}),
                                  placeholder: intl.formatMessage({id:"headers.drap"})
                                },
                                pagination: {
                                  labelDisplayedRows: '{from}-{to} '+intl.formatMessage({id:"of"}) +' {count}',
                                  labelRowsPerPage: intl.formatMessage({id:"recordsPerPage"}),
                                  labelRowsSelect: intl.formatMessage({id:"records"})
                                },
                                toolbar: {
                                    addRemoveColumns: intl.formatMessage({id:"add.remove"}),
                                    nRowsSelected: '{0}' + intl.formatMessage({id:"rows.select"}),
                                    showColumnsTitle: intl.formatMessage({id:"show.columns"}),
                                    showColumnsAriaLabel: intl.formatMessage({id:"show.columns"}),
                                    exportTitle: intl.formatMessage({id:"export"}),
                                    exportAriaLabel: intl.formatMessage({id:"export"}),
                                    exportName: intl.formatMessage({id:"export.csv"}),
                                    searchTooltip: intl.formatMessage({id:"search"}),
                                    searchPlaceholder: intl.formatMessage({id:"search"})
                                },
                                header: {
                                  actions: intl.formatMessage({id:"action"})          
                                },
                                body: {
                                  emptyDataSourceMessage: intl.formatMessage({id:"rows.show"}),
                                  filterRow: {},
                                  editRow: {
                                    saveTooltip: intl.formatMessage({id:"save"}),
                                    cancelTooltip: intl.formatMessage({id:"cancel"}),
                                    deleteText: intl.formatMessage({id:"sure.delete"})
                                  },
                                  addTooltip: intl.formatMessage({id:"add"}),
                                  deleteTooltip: intl.formatMessage({id:"delete"}),
                                  editTooltip: intl.formatMessage({id:"update"})
                                }
                              }} 
                          />
                      </Can>
                      {this.state.loading && (
                          <Overlay>
                              <CircularProgress color='secondary' />
                          </Overlay>
                      )}
      

                      <CustomizedSnackbars
                          variant={this.state.notificationVariant}
                          message={this.state.notificationMessage}
                          isOpen={this.state.openNotification}
                          toClose={this.closeNotification}
                      />
                  </Container>

      );
    }

    const { classes, headerMenu, actionsHeaderMenu, workhours } = this.props;
    const { workhour } = this.state;
    //intl = this.props.intl;
     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary'  onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
              {<FormattedMessage id="edit.item" />}
              <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"name"})} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={workhour.name} required />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoCompleteMultiple label={intl.formatMessage({id:"menu.organizations"})} options={this.state.organizationsList} stateName="organization" changeSelect={this.changeValues} selected={workhour.organization} required />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleCheckBox
                      label={intl.formatMessage({id:"technical.team.edit.select.all.organizations"})}
                      name='allOrgs'
                      stateName='allOrgs' 
                      changeSelect={this.changeValuesCheck}
                      selected={this.state.allOrgs} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"comment"})} multiline rows={5} autoComplete='fname' variant='outlined' name='comments' onChange={this.handleInputChange} value={workhour.comments} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleCheckBox label={intl.formatMessage({id:"active"})} name='active' stateName='active' changeSelect={this.changeValues} selected={workhour.active} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {bodyListWork()}
                    </Grid>
                <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                  <Grid item xs={12} sm={4}    >
                    <Button 
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                </Grid>
                </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
      </div>
    );
  }
}
WorkHourEdit.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                actionsHeaderMenu: state.actionsHeaderMenu,
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                    workhours: state.workhours
                });
                const mapDispatchToProps = dispatch =>
                    bindActionCreators(componentActions, dispatch);

            export default injectIntl(connect(mapStateToProps,mapDispatchToProps)(withStyles(Styles)(WorkHourEdit)));
