import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CssBaseline, Container, CircularProgress, Grid, Typography, TextField, Button, Paper, FormLabel, FormControl, RadioGroup, FormControlLabel, Radio, Link } from '@mui/material'
import { withStyles } from '@mui/styles'
import { Styles } from '../../styles/material-styles'
import { Overlay } from '../../styles/global'
import Api from '../../services/api'
import CustomizedSnackbars from '../../components/material-snackbars'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import { isEmail } from '../../utils/mask/emailTestMask'
import Logo from '../../components/logo/logo'
import moment from 'moment'
import Dropzone from 'react-dropzone'
import { DeleteForever } from '@mui/icons-material'
import Footer from '../../components/footer'
import { cpfMask, validateCpf } from '../../utils/cpfUtils'
import SimpleSelect from "../../components/select/simple";
import { getAllWithoutIncludesAnonymous } from '../../services/unit.service'
import DoneIcon from '@mui/icons-material/Done';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { PapiroConsole } from '../../utils/papiroConsole'
import { getCanCreateClientUsersExternal } from '../../services/config.service';
import { createClientExternalUser } from '../../services/user.service'
import { browserHistory } from "../../helpers/history";
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import { getAll as getStates } from '../../services/states.service.'

class ExternalUserRegistration extends Component {
  constructor(props) {
    super(props)

    const { intl } = this.props

    this.itemInitialState = {
      name: '',
      document: '',
      email: '',
      confirmEmail: '',
      hirerId: 0,
      unitId: 0,
      gender: null,
      state: 0,
      firstNumber: '',
      phoneNumber: '',
    }

    this.state = {
      loading: false,
      item: this.itemInitialState,
      itemError: {
        name: '',
        document: '',
        email: '',
        confirmEmail: '',
        unitId: '',
        gender: '',
        state: '',
        firstNumber: '',
        phoneNumber: '',
      },
      sumChapta: {
        firstNumber: 0,
        secondNumber: 0,
        sum: 0
      },
      unitList: [],
      genderList: [
        { id: 1, name: intl.formatMessage({ id: "gender.male" }) },
        { id: 2, name: intl.formatMessage({ id: "gender.female" }) }
      ],
      statesList: [],
      notificationVariant: 'success',
      notificationMessage: '',
      openNotification: false,
      logo: Api != null ? Api.getLoginImage() : null,
    }

    this.changeValues = this.changeValues.bind(this)
    this.closeNotification = this.closeNotification.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.compareEmails = this.compareEmails.bind(this)
    this.notEventChange = this.notEventChange.bind(this)
    this.getUnitList = this.getUnitList.bind(this)
    this.generateSumCaptcha = this.generateSumCaptcha.bind(this)
    this.handleChangePhone = this.handleChangePhone.bind(this)
    this.getCanCreateClientUsersExternal = this.getCanCreateClientUsersExternal.bind()
  }

  closeNotification = _ => this.setState({ openNotification: false })

  async componentDidMount() {
    if (Api === null || typeof Api === 'undefined') {
      let { lang } = this.props.match.params;
      document.location.href=`/${lang}/unavailable`
    }
    await this.getCanCreateClientUsersExternal()
    this.generateSumCaptcha()
    await this.getUnitList()
    this.getStates();
  }

  async getStates() {
    let result = await getStates()
    if (result && result.success) {
      if (result.data && result.data.length > 0)
        this.setState({ statesList: result.data })
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }
  }

  generateSumCaptcha = () => {
    let firstNumber = Math.floor(Math.random() * 10) + 1
    let secondNumber = Math.floor(Math.random() * 10) + 1
    let sum = firstNumber + secondNumber

    this.setState({
      sumChapta: {
        firstNumber: firstNumber,
        secondNumber: secondNumber,
        sum: sum
      }
    })
  }

  getCanCreateClientUsersExternal = async () => {
    let result = await getCanCreateClientUsersExternal()
    if (result && result.success && result.data != null) {
      if (result.data != null) {
        if (result.data.cancreateclientusersexternal == false) {
          if (Api === null || typeof Api === 'undefined') {
            let { lang } = this.props.match.params;
            document.location.href=`/${lang}/`
          }
        }

        this.setState(prevState => ({
          item: {
            ...prevState.item,
            hirerId: result.data.hirerid
          }
        }))
      }
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      })
      Api.kickoff(result)
    }
  }

  getUnitList = async () => {
    let result = await getAllWithoutIncludesAnonymous()
    if (result && result.success) {
      if (result.data && result.data.length > 0)
        this.setState({ unitList: result.data })
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      })
    }
  }

  handleChangePhone = (e) => {
    PapiroConsole.log(`=== handleChangePhone ===`)
    PapiroConsole.log(e)

    e.length === 0 ? this.setState(prevState => ({ itemError: { ...prevState.itemError, phoneNumber: 'Campo obrigatório' } })) : e.length < 13 ? this.setState(prevState => ({ itemError: { ...prevState.itemError, phoneNumber: 'Número inválido' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, phoneNumber: '' } }))
    
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        ['phoneNumber']: e
      }
    }));
  }

  compareEmails = value => {
    value !== this.state.item.email ? this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: 'E-mails não coincidem' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: '' } }))
  }

  handleChange = (event) => {
    let { name, value } = event.target

    if (name === 'name') {
      value.length === 0 ? this.setState(prevState => ({ itemError: { ...prevState.itemError, name: 'Campo obrigatório' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, name: '' } }))
    } else if (name === 'document') {
      value = cpfMask(value)
      value.length === 0 ? this.setState(prevState => ({ itemError: { ...prevState.itemError, document: 'Campo obrigatório' } })) : (value.length !== 14 || !validateCpf(value)) ? this.setState(prevState => ({ itemError: { ...prevState.itemError, document: 'CPF inválido' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, document: '' } }))
    } else if (name === 'email') {
      value.length === 0 ? this.setState(prevState => ({ itemError: { ...prevState.itemError, email: 'Campo obrigatório' } })) : !isEmail(value) ? this.setState(prevState => ({ itemError: { ...prevState.itemError, email: 'Email inválido' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, email: '' } }))
      this.compareEmails(value)
    } else if (name === 'confirmEmail') {
      value.length === 0 ? this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: 'Campo obrigatório' } })) : value !== this.state.item.email ? this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: 'E-mails não coincidem' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: '' } }))
    }

    if (name === 'firstNumber') {
      const regex = /^[0-9\b]+$/;

      !regex.test(value) ? this.setState(prevState => ({ itemError: { ...prevState.itemError, firstNumber: 'Campo obrigatório' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, firstNumber: '' } }))

      if (value === '' || regex.test(value))
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            [name]: value
          }
        }));
    } else if (name !== 'name' || (name === 'name' && value.length <= 255))
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [name]: value
        }
      }));
    }

  changeValues = (stateName, value, text = "") => {

    if (value === 0) {
      this.setState((prevState) => ({
        itemError: {
          ...prevState.itemError,
          [stateName]: 'Campo obrigatório',
        },
      }));
    } else {
      this.setState((prevState) => ({
        itemError: {
          ...prevState.itemError,
          [stateName]: '',
        },
      }));
    }

    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        [stateName]: value,
      },
    }));
  }

  validateForm = () => {
    let { item, itemError } = this.state
    let hasError = false

    let erros = {
      name: false,
      document: false,
      email: false,
      confirmEmail: false,
      unitId: false,
      gender: false,
      state: false,
      firstNumber: false,
      phoneNumber: false
    }

    // valida nome
    if (item.name.length === 0) {
      erros.name = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, name: 'Campo obrigatório' } }))
    } else {
      erros.name = false
      this.setState(prevState => ({ itemError: { ...prevState.itemError, name: '' } }))
    }

    // valida unidade
    if (item.unitId === 0 || item.unitId === null || (item.unitId && item.unitId.id && item.unitId.id == 0)) {
      erros.unitId = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, unitId: 'Campo obrigatório' } }))
    } else {
      erros.unitId = false
      this.setState(prevState => ({ itemError: { ...prevState.itemError, unitId: '' } }))
    }

    // valida estado
    if (item.state === 0 || item.state === null || (item.state && item.state.id && item.state.id == 0)) {
      erros.state = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, state: 'Campo obrigatório' } }))
    } else {
      erros.state = false
      this.setState(prevState => ({ itemError: { ...prevState.itemError, state: '' } }))
    }
/*
    // valida gênero
    PapiroConsole.log("valida gênero")
    PapiroConsole.log(item.gender)
    if (item.gender === 0 || item.gender === null || (item.gender && item.gender.id && item.gender.id == 0)) {
      erros.gender = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, gender: 'Campo obrigatório' } }))
    } else {
      erros.gender = false
      this.setState(prevState => ({ itemError: { ...prevState.itemError, gender: '' } }))
    }*/

    // valida CPF
    item.document = cpfMask(item.document)
    if (item.document.length === 0) {
      erros.document = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, document: 'Campo obrigatório' } }))
    } else if (item.document.length !== 14 || !validateCpf(item.document)) {
      erros.document = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, document: 'CPF inválido' } }))
    } else {
      erros.document = false
      this.setState(prevState => ({ itemError: { ...prevState.itemError, document: '' } }))
    }

    // valida telefone
    if (item.phoneNumber.length === 0) {
      erros.phoneNumber = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, phoneNumber: 'Campo obrigatório' } }))
    } else if (item.phoneNumber.length < 13) {
      erros.phoneNumber = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, phoneNumber: 'Número inválido' } }))
    } else {
      erros.phoneNumber = false
      this.setState(prevState => ({ itemError: { ...prevState.itemError, phoneNumber: '' } }))
    }

    // valida Email
    if (item.email.length === 0) {
      erros.email = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, email: 'Campo obrigatório' } }))
    } else if (!isEmail(item.email)) {
      erros.email = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, email: 'Email inválido' } }))
    } else {
      erros.email = false
      this.setState(prevState => ({ itemError: { ...prevState.itemError, email: '' } }))
    }
    
    // valida Confirmação de Email
    if (item.confirmEmail.length === 0) {
      erros.confirmEmail = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: 'Campo obrigatório' } }))
    } else if (item.confirmEmail !== this.state.item.email) {
      erros.confirmEmail = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: 'E-mails não coincidem' } }))
    } else {
      erros.confirmEmail = false
      this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: '' } }))
    }

    // valida CAPTCHA
    PapiroConsole.log("valida CAPTCHA")
    PapiroConsole.log(item.firstNumber, item.firstNumber.length, this.state.sumChapta.firstNumber)
    if(item.firstNumber.length === 0) {
      erros.firstNumber = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, firstNumber: 'Campo obrigatório' } }))
    } else if(item.firstNumber != this.state.sumChapta.firstNumber) {
      erros.firstNumber = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, firstNumber: 'CAPTCHA inválido' } }))
    } else if (item.firstNumber == this.state.sumChapta.firstNumber) {
      erros.firstNumber = false
      this.setState(prevState => ({ itemError: { ...prevState.itemError, firstNumber: '' } }))
    } else {
      erros.firstNumber = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, firstNumber: 'Campo obrigatório' } }))
    }
    
    Object.values(erros).forEach(val => {
      if (val == true)
        hasError = true
    });

    return hasError
  }

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true })

    let validateForm = await this.validateForm()
    if (validateForm === false) {
      let { item } = this.state

      let data = new FormData()
      data.append('hirerid', item.hirerId)
      data.append('name', item.name)
      data.append('unitid', item.unitId.id)
      data.append('state', item.state.id)
      data.append('gender', 0)
      data.append('document', item.document)
      data.append('phone', item.phoneNumber)
      data.append('email', item.email)

      let response = await createClientExternalUser(data)
      if (response && response.success) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'success',
          notificationMessage: 'Solicitação enviada com sucesso',
          item: this.itemInitialState,
        })
        setTimeout(() => {
          if (response.data != null) {
            this.props.history.push({
              pathname: `/${this.props.match.params.lang}/`
            })
          }
        }, 3000);
      } else {
        const intl = this.props.intl
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            response.response && response.response.data && response.response.data.errors
              ? response.response.data.errors[0]
              : intl.formatMessage({ id: "process.error" }),
        })
      }
    } else {
      this.generateSumCaptcha()
      this.setState({
        loading: false,
        notificationVariant: 'error',
        notificationMessage: 'Você precisa preencher os campos obrigatórios para enviar a solicitação',
        openNotification: true
      })
    }
  }

  notEventChange = (e) => {
    e.preventDefault()
  }

  render() {
    const { classes, intl } = this.props;
    const { item, itemError } = this.state

    return (
    <Grid container spacing={0}>
      <Grid container spacing={0} style={{ backgroundColor: '#F8F8F8', minHeight: 'calc(100vh - 225px)' }}>
        <Grid container spacing={0}>
        <Grid item sm={12} md={12} lg={2} />
          <Grid item sm={12} md={12} lg={8} style={{ display: 'flex', justifyContent: 'center' }}>
            <Logo src={this.state.logo} key="groups-fieldservice-awrar6aw15" />
          </Grid>
          <Grid item sm={12} md={12} lg={2} />
          <Grid item sm={12} md={1} lg={2} />
          <Grid item sm={12} md={10} lg={8} style={{ width: '100%', boxSizing: 'border-box' }} >
            <Paper elevation={3} style={{ padding: '4% 5.6%', width: '100%', boxSizing: 'border-box', marginBottom: 30 }}>
              <Grid container spacing={2}>
                <Grid item sm={2} md={2} lg={2}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={browserHistory.goBack} >
                    {<FormattedMessage id="back" />}
                  </Button>
                </Grid>
                <Grid item sm={10} md={10} lg={10} style={{ marginBottom: 30, display: 'flex', justifyContent: 'center' }}>
                  <Typography variant="h2" className={this.props.class} style={{ color: '#1351b4' }}>
                    Solicitação de Acesso
                  </Typography>
                </Grid>
                <Grid item sm={12} md={12} lg={12} style={{ width: '100%', boxSizing: 'border-box' }}>
                  <form name="myForm" onSubmit={this.handleSubmit}>  
                    <Grid container spacing={2}>
                      <Grid item sm={12} md={12} lg={10} >
                        <TextField
                          name="name"
                          label="Nome*"
                          fullWidth
                          value={item.name}
                          onChange={this.handleChange}
                          InputLabelProps={{ shrink: true }}
                          error={itemError.name?.length > 0}
                          helperText={itemError.name}
                          inputProps={{ maxLength: 255 }} />
                      </Grid>
                      <Grid item sm={12} md={12} lg={2} />
                      <Grid item sm={12} md={12} lg={10} >
                        <SimpleAutoComplete
                          label={`Unidade do solicitante*`}
                          name="unitId"
                          options={this.state.unitList}
                          stateName="unitId"
                          changeSelect={this.changeValues}
                          selected={item.unitId}
                          hasError={(itemError.unitId && itemError.unitId.length > 0)}
                          errorMessage={itemError.unitId} />
                        { (itemError.unitId && itemError.unitId.length > 0) && <p style={{ color: '#E53935', fontSize: '11px', letterSpacing: '0.33px', lineHeight: '13px', fontFamily: '"Roboto","Helvetica","Arial",sans-serif', fontWeight: 400, textAlign: 'left', marginTop: '3px', marginRight: '14px', marginBottom: 0, marginLeft: '14px' }}>{itemError.unitId}</p>}
                      </Grid>
                      <Grid item sm={12} md={12} lg={2} />
                      <Grid item sm={12} md={12} lg={6} >
                        <SimpleAutoComplete
                          label={`Localidade (estado)*`}
                          name="state"
                          options={this.state.statesList}
                          stateName="state"
                          changeSelect={this.changeValues}
                          selected={item.state}
                          hasError={(itemError.state && itemError.state.length > 0)}
                          errorMessage={itemError.state} />
                        { (itemError.state && itemError.state.length > 0) && <p style={{ color: '#E53935', fontSize: '11px', letterSpacing: '0.33px', lineHeight: '13px', fontFamily: '"Roboto","Helvetica","Arial",sans-serif', fontWeight: 400, textAlign: 'left', marginTop: '3px', marginRight: '14px', marginBottom: 0, marginLeft: '14px' }}>{itemError.state}</p>}
                      </Grid>
                      <Grid item sm={12} md={12} lg={6} />
                      <Grid item sm={12} md={12} lg={6} >
                        <TextField
                          name="document"
                          label="CPF*"
                          fullWidth
                          value={item.document}
                          onChange={this.handleChange}
                          InputLabelProps={{ shrink: true }}
                          error={itemError.document?.length > 0}
                          helperText={itemError.document} />
                      </Grid>
                      <Grid item sm={12} md={12} lg={6}  />
                      <Grid item sm={12} md={12} lg={6} >
                        <PhoneInput
                          inputStyle={{
                            padding: '23.5px 14px 23.5px 58px',
                            width: '100%'
                          }}
                          style={{
                              marginTop: 16,
                              marginBottom: 8
                          }}
                          id="phoneNumber"
                          name="phoneNumber"
                          country={'br'}
                          value={item.phoneNumber}
                          onChange={this.handleChangePhone}
                          InputLabel={intl.formatMessage({id:"phone"})}
                          specialLabel={intl.formatMessage({id:"phone"})+"*"}
                          inputProps={{
                            name: intl.formatMessage({ id: "cellphone" })
                          }} />
                          { (itemError.phoneNumber && itemError.phoneNumber.length > 0) && <p style={{ color: '#E53935', fontSize: '11px', letterSpacing: '0.33px', lineHeight: '13px', fontFamily: '"Roboto","Helvetica","Arial",sans-serif', fontWeight: 400, textAlign: 'left', marginTop: '3px', marginRight: '14px', marginBottom: 0, marginLeft: '14px' }}>{itemError.phoneNumber}</p>}
                      </Grid>
                      <Grid item sm={12} md={12} lg={6}  />
                      <Grid item sm={12} md={12} lg={10} >
                        <TextField
                          name="email"
                          label="E-mail*"
                          fullWidth
                          value={item.email}
                          onChange={this.handleChange}
                          onCut={this.notEventChange}
                          onCopy={this.notEventChange}
                          onPaste={this.notEventChange}
                          InputLabelProps={{ shrink: true }}
                          error={itemError.email?.length > 0}
                          helperText={itemError.email} />
                      </Grid>
                      <Grid item sm={12} md={12} lg={2} />
                      <Grid item sm={12} md={12} lg={10} >
                        <TextField
                          name="confirmEmail"
                          label="Confirmar E-mail*"
                          fullWidth
                          autoComplete="new-password"
                          value={item.confirmEmail}
                          onChange={this.handleChange}
                          onCut={this.notEventChange}
                          onCopy={this.notEventChange}
                          onPaste={this.notEventChange}
                          InputLabelProps={{ shrink: true }}
                          error={itemError.confirmEmail?.length > 0}
                          helperText={itemError.confirmEmail} />
                      </Grid>
                      <Grid item sm={12} md={12} lg={2} />
                      <Grid item sm={12} md={12} lg={10} >
                        <TextField
                          name="email"
                          label="Login (Utilizar E-mail)*"
                          fullWidth
                          value={item.email}
                          onChange={this.handleChange}
                          onCut={this.notEventChange}
                          onCopy={this.notEventChange}
                          onPaste={this.notEventChange}
                          InputLabelProps={{ shrink: true }}
                          disabled />
                      </Grid>
                      <Grid item sm={12} md={12} lg={2} />
                      <Grid item sm={12} md={12} lg={10}>
                        <Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold' }}>
                          Complete a operação*
                        </Typography>
                      </Grid>
                      <Grid item sm={12} md={12} lg={2} />
                      <Grid item sm={12} md={12} lg={10}>
                        <Grid container spacing={0}>
                          <Grid item sm={2} md={2} lg={2}>
                            <TextField
                              name="firstNumber"
                              label=""
                              fullWidth
                              value={item.firstNumber}
                              onChange={this.handleChange}
                              error={itemError.firstNumber?.length > 0}
                              helperText={itemError.firstNumber}
                              InputLabelProps={{ shrink: true }} />
                          </Grid>
                          <Grid item sm={10} md={10} lg={10} style={{ display: 'flex', alignItems: 'center' }}>
                              &nbsp;+ {this.state.sumChapta.secondNumber} = {this.state.sumChapta.sum}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={12} md={12} lg={2} />
                      <Grid item sm={12} md={12} lg={12} style={{ paddingTop: 40, display: 'flex', justifyContent: 'center' }}>
                        <Button
                          startIcon={<DoneIcon />}
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{ color: '#fff', textTransform: 'captalize!important' }} >
                          Solicitar Acesso
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item sm={12} md={1} lg={2} />
        </Grid>
        {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}

        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification} />
      </Grid>
    </Grid>
    )
  }
}

ExternalUserRegistration.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(ExternalUserRegistration)));