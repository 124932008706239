import React, { useEffect, useState } from 'react'
import DescriptionIcon from '@mui/icons-material/Description'
import PropTypes from 'prop-types'
import ClearIcon from '@mui/icons-material/Clear'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { FormattedMessage, injectIntl } from 'react-intl'
import { withStyles } from '@mui/styles'
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import { Styles } from '../../../styles/material-styles'
import MiniMap from '../../../components/minimap/external-state'
import SimpleAutoComplete from '../../../components/auto-complete/autocomplete'

function KnowledgebaseDialog(props) {

  const { changeValues, close, insertKnowledgebase, intl, knowledgeBaseDetails, label } = props;

  return (
    <Dialog
      open={props.open}
      onClose={close}
      aria-labelledby="knowledgebase-dialog-title"
      maxWidth={705} >
      <DialogTitle id="knowledgebase-dialog-title">
        {label}
        <ClearIcon onClick={close} style={{ float: 'right', cursor: 'pointer' }} />
      </DialogTitle>
      <DialogContent style={{ width: "800px" }} >
        <Grid container spacing={0} style={{ marginTop: 15 }}>
          <Grid item xs={10}>
            <SimpleAutoComplete
              label={intl.formatMessage({ id: "knowledgebase" })}
              options={props.knowledgeBaseList}
              stateName='knowledgebase'
              changeSelect={changeValues}
              selected={props.knowledgebase} />
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
            {(props.knowledgebase != null && props.knowledgebase.id != null && props.knowledgebase.id > 0) && <Tooltip title={<h5>Detalhes</h5>}>
              <IconButton aria-label="knowledge-base"
                onClick={() => knowledgeBaseDetails()}
                style={{
                  marginBottom: '30px',
                  backgroundColor: '#277afe',
                  color: '#FFF'
                }}>
                <DescriptionIcon style={{ fontSize: '2rem' }} />
              </IconButton>
            </Tooltip>}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} variant="contained" color="secondary" autoFocus >
          <FormattedMessage id="cancel" />
        </Button>
        <Button variant="contained" onClick={insertKnowledgebase} color="primary" >
          <FormattedMessage id="insert" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

KnowledgebaseDialog.propTypes = {
  changeValues: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  insertKnowledgebase: PropTypes.func.isRequired,
  knowledgebase: PropTypes.object.isRequired,
  knowledgeBaseDetails: PropTypes.func.isRequired,
  knowledgeBaseList: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
}

export default injectIntl(withStyles(Styles)(KnowledgebaseDialog))