import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Api from '../../services/api';
import MyMaterialTable from '../../components/table';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Search from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import SimpleSelect from '../../components/select/simple-search';
import ClearIcon from '@mui/icons-material/Clear';
import InputIcon from '@mui/icons-material/Input';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FirstElement from "../../components/first-element";
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import * as Service from '../../services/calledlistdata.service'
import ViewListIcon from '@mui/icons-material/ViewList';
import * as componentActions from '../../redux/actions/calledlistdatas';
import { PapiroConsole } from '../../utils/papiroConsole';
class CalledListDatasContent extends Component {

    constructor(props) {
        super(props);

        this.typingTimeout = null;

        const intl = this.props.intl;
        this.emptyArray = null;

        let filters = [{ name: intl.formatMessage({ id: "profile.name" }), id: "role" }]

        let fromProps = ""
        if (this.props && this.props.from && this.props.from.length > 0 && this.props.from == 'orgEdit') {
            fromProps = this.props.from
        }

        const { userSession, organizations } = this.props;
        this.state = {
            organizations: organizations,
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            resultList: [],
            organizationList: [],
            totalPages: 0,
            page: 1,
            pageStart: 0,
            pageEnd: 0,
            count: 0,
            searchValue: '',
            filterSelected: 0,
            filters: filters,
            from: fromProps,
            OrganizationFilter: 0,
            OrganizationFilterItem: null
        };

        this.changeValues = this.changeValues.bind(this);
        this.callSearch = this.callSearch.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);

        this.closeNotification = this.closeNotification.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.clearFields = this.clearFields.bind(this);
    }

    getOrganizations = async _ => {
        this.setState({ loading: true })
        let data = [];
        const { userSession } = this.props
        if (userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
            data = userSession.user.userorganizations
        } else {
            await Api.get('/organizations/tech')
                .then(result => {
                    data = result.data.data
                })
                .catch(err => {
                    data = err;
                })
        }
        this.setState({ organizationList: data, loading: false })
    }

    onChangePaginate = (e, page) => {
        this.setState({ page: page });
        localStorage.setItem('calledlistdatasTablePage', `${page}`)
        this.getItens(page);
    }

    setPagination(count, page) {

        let totalPage = Math.ceil(count / 10);
        this.setState({ totalPages: totalPage })
        if (count > 0) {
            if (page == 1) {
                if (count < 10) {
                    this.setState({ pageStart: 1, pageEnd: count });
                } else {
                    this.setState({ pageStart: 1, pageEnd: 10 });
                }
            } else {
                let pageStart = ((page - 1) * 10) + 1;
                // console.log(count)
                // console.log('pagina')
                // console.log(page * 10)
                if (count >= page * 10) {
                    this.setState({ pageStart: pageStart, pageEnd: page * 10 });
                } else {
                    this.setState({ pageStart: pageStart, pageEnd: count });
                }
            }
        } else {
            this.setState({ totalPages: 0, page: 1, count: 0 });
        }

    }

    onFieldChange(event) {


        this.setState({ [event.target.name]: event.target.value, page: 1 });

    }

    callSearch() {
        if (this.state.filterSelected != 0) {
            localStorage.setItem('calledlistdatasTableFilter', `${this.state.filterSelected}=${this.state.searchValue}`)
            this.getItens(this.state.page);
        }
    }

    async clearFields() {
        await this.setState({ filterSelected: 0, searchValue: '', page: 1 })
        localStorage.setItem('calledlistdatasTableFilter', '')
        localStorage.setItem('calledlistdatasTablePage', '1')
        this.getItens(1);
    }

    async changeValues(stateName, value, text = '') {
        if (stateName === 'OrganizationFilter') {
            this.setState({ [stateName]: value.id })
            this.getItens()
        } else
            this.setState({ filterSelected: value })
    }


    deleteItem = async (data, resolve) => {
        let response = await Service.deleteItem(data.id)
        if (response && response.success) {
            const intl = this.props.intl;
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
                resultList: []
            });
            resolve();
            await this.getItens();
        } else {
            resolve();
            const intl = this.props.intl
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
            Api.kickoff(response)
        }
    };


    componentWillReceiveProps(nextProps) {
        if (nextProps.location && nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    async componentDidMount() {
        this.getOrganizations()
        let sessionFilter = localStorage.getItem('calledlistdatasTableFilter')
        if (sessionFilter == null) {
            sessionFilter = [0, '']
            localStorage.setItem('calledlistdatasTableFilter', '')
        } else {
            if (sessionFilter && sessionFilter.length > 0) {
                let sessionFilterSplit = sessionFilter.split("=")
                if (sessionFilterSplit && sessionFilterSplit.length > 1) {
                    if (!isNaN(sessionFilterSplit[0]))
                        sessionFilter = [parseInt(sessionFilterSplit[0]), sessionFilterSplit[1]]
                    else
                        sessionFilter = [sessionFilterSplit[0], sessionFilterSplit[1]]
                }
            }
        }
        if (isNaN(sessionFilter[0])) {
            let sessionFilterItem = this.state.filters.filter(item => item.id == sessionFilter[0])
            if (sessionFilterItem && sessionFilterItem.length > 0) {
                await this.setState({
                    filterSelected: sessionFilterItem[0]
                }, () => this.setState({ filterSelected: sessionFilter[0], searchValue: sessionFilter[1] }))
            }
        } else {
            await this.setState({ filterSelected: sessionFilter[0], searchValue: sessionFilter[1] })
        }

        let sessionOrgFilter = localStorage.getItem('calledlistdatasTableOrganizationFilter')
        if (sessionOrgFilter == null) {
            sessionOrgFilter = 0
            localStorage.setItem('calledlistdatasTableOrganizationFilter', '0')
        }
        let orgFilterItem = null
        if (!isNaN(sessionOrgFilter) && typeof sessionOrgFilter === 'string') {
            sessionOrgFilter = parseInt(sessionOrgFilter)
            if (sessionOrgFilter > 0 && this.state.organizationList != null) {
                orgFilterItem = this.state.organizationList.filter(item => item.id == sessionOrgFilter)
                if (orgFilterItem && orgFilterItem.length > 0) {
                    await this.setState({
                        OrganizationFilterItem: orgFilterItem[0]
                    })
                }
            }
        }
        await this.setState({ OrganizationFilter: sessionOrgFilter, OrganizationFilterItem: orgFilterItem })

        let sessionPage = localStorage.getItem('calledlistdatasTablePage')
        if (sessionPage == null) {
            sessionPage = 1
            localStorage.setItem('calledlistdatasTablePage', '1')
        }
        if (!isNaN(sessionPage) && typeof sessionPage === 'string')
            sessionPage = parseInt(sessionPage)
        await this.setState({ page: sessionPage })
        await this.getItens(sessionPage)
    }

    getItens = async (page = 1) => {
        this.setState({ loading: true });

        PapiroConsole.log("=== this.props.location.pathname ===")
        PapiroConsole.log(this.props.location.pathname)

        let response = (this.props.location && (this.props.location.pathname === `/${this.props.match.params.lang}/calledlistdatas` || this.props.location.pathname === `/calledlistdatas`)) ? await Service.paginate(page, this.state.OrganizationFilter, this.state.searchValue, this.state.filterSelected) : await Service.paginateByOrganization(page, this.state.organizations.organization.id, this.state.searchValue, this.state.filterSelected)

        if (response && response.success) {
            let count = response.count;
            PapiroConsole.log("=== getItens ===")
            PapiroConsole.log(count)
            this.setState({ count: count });
            this.setPagination(count, page);
            if (response.data.length === 0 && this.state.filterSelected == 0) {
                this.emptyArray = true
            } else {
                this.emptyArray = false
            }

            const formattedList = response.data.map(item => {

                let roles = ''
                if (item && item.calledlistdataroles && item.calledlistdataroles.length > 0) {
                    const roleFilter = item.calledlistdataroles.map(i => {
                        if (i && i.role && i.role.displayname)
                            return i.role.displayname
                    })
                    if (roleFilter && roleFilter.length > 0)
                        roles = roleFilter.join(' ,')
                }

                return {
                    ...item,
                    OrganizationId: item.organization ? item.organization.id : 0,
                    OrganizationText: item.organization ? item.organization.name : '',
                    Roles: roles,
                }
            });
            this.setState({ loading: false, resultList: formattedList });
        } else {
            const intl = this.props.intl
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
            Api.kickoff(response)
        }
    };


    closeNotification() {
        this.setState({ openNotification: false });
    }
    render() {
        const { classes, headerMenu } = this.props;
        const intl = this.props.intl;
        return (
            <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft: 0, marginLeft: 0 }}>
                {!this.emptyArray ? (
                    <div>
                        <CssBaseline />
                        <Grid container className={classes.mb20}>
                            <Button
                                variant='contained'
                                color='secondary'
                                size='large'
                                style={{ marginBottom: '20px' }}
                                className={classes.button}
                                startIcon={<AddIcon />}
                                //component={Link} {... { to: '/OrganizationField-registration' }}
                                component={Link} {... {
                                    to: {
                                        pathname: `/${this.props.match.params.lang}/calledlistdata-registration`,
                                        state: {
                                            internal: this.props.internal,
                                            from: this.state.from
                                        }
                                    }
                                }}
                            >
                                {<FormattedMessage id="add" />}
                            </Button>
                        </Grid>

                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={12}>


                                <Card className="background-title-search">
                                    <CardContent>

                                        <Grid container spacing={1} alignItems="flex-end">
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="h5">
                                                    {<FormattedMessage id={intl.formatMessage({ id: "called.list.data.list.called.list.config" })} />}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={3}>
                                                {(this.props.location && this.props.location.pathname === `/${this.props.match.params.lang}/calledlistdatas`) && (<SimpleAutoComplete label={intl.formatMessage({ id: "organization" })} options={this.state.organizationList} stateName='OrganizationFilter'
                                                    selected={this.state.OrganizationFilterItem}
                                                    changeSelect={(stateName, value, text = '') => {
                                                        this.setState({
                                                            OrganizationFilter: value.id,
                                                            OrganizationFilterItem: value
                                                        }, () => {
                                                            if (value && value.id && value.id > 0)
                                                                localStorage.setItem('calledlistdatasTableOrganizationFilter', `${value.id}`)
                                                            else
                                                                localStorage.setItem('calledlistdatasTableOrganizationFilter', '0')
                                                            this.getItens()
                                                        })
                                                    }} />)}
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <SimpleSelect label={intl.formatMessage({ id: "field" })} options={this.state.filters} stateName='filter' changeSelect={this.changeValues} selected={this.state.filterSelected} />
                                            </Grid>
                                            <Grid item >
                                                <TextField type="text"
                                                    name="searchValue"
                                                    value={this.state.searchValue}
                                                    placeholder={intl.formatMessage({ id: "search" })}
                                                    onChange={this.onFieldChange} />

                                                <Button
                                                    variant='contained'
                                                    color='secondary'
                                                    size='large'
                                                    className={classes.button}
                                                    style={{ marginRight: 4, marginLeft: 8 }}
                                                    onClick={this.callSearch}
                                                >
                                                    <Search />
                                                </Button>

                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    size='large'
                                                    onClick={this.clearFields}
                                                    className={classes.button}

                                                >
                                                    <ClearIcon />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>

                            </Grid>

                        </Grid>
                        <MyMaterialTable
                            title=""
                            rowClick={(event, rowData) => {
                                this.props.addCurrentOrganization(rowData, this.props.organizations.organization);

                                this.props.history.push({
                                    pathname: `/${this.props.match.params.lang}/calledlistdata-edit`,
                                    state: {
                                        internal: this.props.internal,
                                        from: this.state.from
                                    }
                                });

                            }}
                            columns={[
                                { title: intl.formatMessage({ id: "organization" }), field: 'OrganizationText' },
                                { title: 'Perfis', field: 'Roles' }

                            ]}
                            data={this.state.resultList}
                            deleteItem={this.deleteItem}
                        />


                        <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                            <Grid item xs={12} md={3}>
                                <Pagination count={this.state.totalPages} page={this.state.page} onChange={this.onChangePaginate} />
                            </Grid>
                            <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                                {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                            </Grid>
                        </Grid>


                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}


                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </div>)
                    : (
                        <div>
                            <FirstElement
                                title={intl.formatMessage({ id: "called.list.data.list.create.called.list.config" })}
                                subtitle={intl.formatMessage({ id: "called.list.data.list.create.called.list.config.subtitle" })}
                                buttonText=
                                {
                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        size='large'
                                        style={{ marginBottom: '20px' }}
                                        className={classes.button}
                                        startIcon={<AddIcon />}
                                        component={Link} {... {
                                            to: {
                                                pathname: `/${this.props.match.params.lang}/calledlistdata-registration`,
                                                state: {
                                                    internal: this.props.internal,
                                                    from: this.state.from
                                                }
                                            }
                                        }}
                                    >
                                        {<FormattedMessage id="add" />}
                                    </Button>
                                }

                                icon={<ViewListIcon style={{ fontSize: "45px" }} />}

                            />
                        </div>
                    )}

                {this.state.loading && (
                    <Overlay>
                        <CircularProgress color='secondary' />
                    </Overlay>
                )}


                <CustomizedSnackbars
                    variant={this.state.notificationVariant}
                    message={this.state.notificationMessage}
                    isOpen={this.state.openNotification}
                    toClose={this.closeNotification}
                />


            </Container>

        );
    }
}
CalledListDatasContent.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    organizations: state.organizations,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledListDatasContent))));
CalledListDatasContent.contextType = AbilityContext;