import axios from 'axios';
import { PapiroConsole } from '../utils/papiroConsole';

let saasError = false
try {
  const urlSaaS = process.env.REACT_APP_API+"/hirerconfig/geturl/"+window.location.host+"/1";

  var xhttp = new XMLHttpRequest();
  xhttp.open("GET", urlSaaS, false);
  xhttp.send();
} catch(e) {
  if(e.name == "NetworkError") {
    if(e.message.indexOf("Failed to execute 'send' on 'XMLHttpRequest'") !== -1) {
      saasError = true;
      PapiroConsole.log(e.message)
    }else if(e.message.indexOf("A network error occurred.") !== -1) {
      saasError = true;
      PapiroConsole.log("entrou em A network error occurred.")
    }else {
      PapiroConsole.log("Outro erro")
      PapiroConsole.log(e.name)
      PapiroConsole.log(e.message)
    }
  }
}

let getEvents = null
let ApiPostWebhook = null
let ApiGetWebhook = null
let ApiPostWebhookToken = null

if(!saasError) {
  var baseUrl = JSON.parse(xhttp.responseText);
  if(baseUrl && baseUrl.data && baseUrl.data.urlapiwebhook) {
    const url = "https://"+baseUrl.data.urlapiwebhook+"/api";

    getEvents = async () => {
      
      let dataReturn = [];

      await axios.get('/webhookevents', {
        headers: { 
          'Content-Type': 'application/json', 
          'Access-Control-Allow-Origin': '*',
        },
        baseURL: url
      }).then(data => {
        PapiroConsole.log("responsePOST")
        PapiroConsole.log(data)
        dataReturn = data;
      }).catch(err => {
        PapiroConsole.log("responsePOST")
        PapiroConsole.log(err)
        dataReturn = err;
      })

      return dataReturn;

    };

    ApiPostWebhook = async (dataSet, urlData, token = null) => {

      let dataReturn = [];

      PapiroConsole.log("info");
      PapiroConsole.log(dataSet);
      PapiroConsole.log(urlData);
      PapiroConsole.log(url);
      
      await axios.post(urlData, dataSet, {
        headers: { 
          'Content-Type': 'application/json', 
          'Access-Control-Allow-Origin': '*',  
          'Authorization': `Bearer ${token}` 
        },
        baseURL: url
      }).then(data => {
        PapiroConsole.log("responsePOST")
        PapiroConsole.log(data)
        dataReturn = data;
      }).catch(err => {
        PapiroConsole.log("responsePOST")
        PapiroConsole.log(err)
        dataReturn = err;
      })

      return dataReturn;

    };

    ApiGetWebhook = async (urlData, token = null) => {

      let dataReturn = [];
      
      await axios.get(urlData, {
        headers: { 
          'Content-Type': 'application/json', 
          'Access-Control-Allow-Origin': '*',  
          'Authorization': `Bearer ${token}` 
        },
        baseURL: url
      }).then(data => {
        PapiroConsole.log("responsePOST")
        PapiroConsole.log(data)
        dataReturn = data;
      }).catch(err => {
        PapiroConsole.log("responsePOST")
        PapiroConsole.log(err)
        dataReturn = err;
      })

      return dataReturn;

    };

    ApiPostWebhookToken = async (dataSet, urlData, token = null) => {

      let dataReturn = [];

      PapiroConsole.log("info");
      PapiroConsole.log(dataSet);
      PapiroConsole.log(urlData);
      PapiroConsole.log(url);
      
      await axios.post(urlData, dataSet, {
        headers: { 
          'Content-Type': 'application/json', 
          'Access-Control-Allow-Origin': '*',  
          'Authorization': `Bearer ${token}` 
        },
        baseURL: url
      }).then(data => {
        PapiroConsole.log("responsePOST")
        PapiroConsole.log(data)
        dataReturn = data;
      }).catch(err => {
        PapiroConsole.log("responsePOST")
        PapiroConsole.log(err)
        dataReturn = err;
      })

      return dataReturn;

    };
  }else {
    getEvents = null
    ApiPostWebhook = null
    ApiGetWebhook = null
    ApiPostWebhookToken = null
  }
} else {
  getEvents = null
  ApiPostWebhook = null
  ApiGetWebhook = null
  ApiPostWebhookToken = null
}

export { getEvents, ApiPostWebhook, ApiGetWebhook, ApiPostWebhookToken }