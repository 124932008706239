import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleSelect from '../../components/select/simple';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import { browserHistory } from '../../helpers/history';
import Title from '../../components/title-name';
import * as Service from '../../services/organizationsla.service';
import * as componentActions from '../../redux/actions/organizationslas';
import { FormattedMessage, injectIntl } from 'react-intl';
import SimpleHelp from '../../components/help';
import SimpleCheckBox from '../../components/checkbox/check';
import { PapiroConsole } from '../../utils/papiroConsole';
import { getSlaCountTypes, applySLACountType } from '../../services/organizationcategory.service';

class OrganizationSlaEdit extends Component {

  constructor(props) {
    super(props);
    const { userSession, organizationslas, organizations } = this.props;

    this.fromProps = false
    if(this.props && this.props.location && this.props.location.state && this.props.location.state.from && this.props.location.state.from.length > 0)
      this.fromProps = true

    this.globalAdm = false;
    this.isClient = true;
    if (userSession.user.userrole == null) {
      Api.logoff();
    }

    if (!organizationslas || (organizationslas && !organizationslas.organizationsla)) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/calleds`
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    }
    );


    this.initialState = {
      UpdatedUser: userSession.user.id,
      id: organizationslas.organizationsla.id,
      OrganizationId: organizationslas.organizationsla.organization,
      OrganizationText: organizationslas.organizationsla.OrganizationText,
      DistributionStrategyId: organizationslas.organizationsla.DistributionStrategyId,
      DistributionStrategyText: organizationslas.organizationsla.DistributionStrategyText,
      WorkHourId: organizationslas.organizationsla.WorkHourId,
      WorkHourText: organizationslas.organizationsla.WorkHourText,
      name: organizationslas.organizationsla.name == null ? '' : organizationslas.organizationsla.name,
      comments: organizationslas.organizationsla.comments == null ? '' : organizationslas.organizationsla.comments,
      servicetime: organizationslas.organizationsla.servicetime == null ? '' : organizationslas.organizationsla.servicetime,
      solutiontime: organizationslas.organizationsla.solutiontime == null ? '' : organizationslas.organizationsla.solutiontime,
      saveToAllOrganizationsInGroup: false,
    };
    this.state = {
      organizationsla: this.initialState,
      organizations: organizations,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      OrganizationList: [],
      DistributionStrategyList: [],
      WorkHourList: [],
      SlaCountTypeId: [],
      SlaCountTypeList: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.getOrganizationsById = this.getOrganizationsById.bind(this)
    this.getSlaCountTypes = this.getSlaCountTypes.bind(this)
    this.applySLACountTypeInOrganizationCategories = this.applySLACountTypeInOrganizationCategories.bind(this)
  }

  async componentDidMount() {
    if (this.state.organizations && this.state.organizations.organization && this.state.organizations.organization.id) {
      this.getOrganizationsById(this.state.organizations.organization.id);
    } else {
      this.getOrganizations();
    }
    this.getDistributionStrategies();
    this.getSlaCountTypes()
    this.getWorkHours(this.state.organizationsla.OrganizationId.id);
  }

  async getSlaCountTypes(){
    this.setState({ loading: true });
    let result = await getSlaCountTypes();
    if (result.success) {
      this.setState({ SlaCountTypeList: result.data });              
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
      });
      Api.kickoff(result);  
    }
  }

  async getOrganizations() {
    this.setState({ loading: true });
    var result = await Service.getOrganizations();
    if (result.success) {
      this.setState({ loading: false, OrganizationList: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };

  async getOrganizationsById(id) {
    this.setState({ loading: true });
    var result = await Service.getOrganizationsByIdLight(id);
    if (result.success) {
        this.setState({ loading: false, OrganizationList: [result.data] });

    } else {
        this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);
    }
  };
  
  async getDistributionStrategies() {
    this.setState({ loading: true });
    var result = await Service.getDistributionStrategies();
    if (result.success) {
      this.setState({ loading: false, DistributionStrategyList: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }; async getWorkHours(orgId = 0) {
    this.setState({ loading: true });
    var result = await Service.getWorkHours(orgId);
    if (result.success) {
      this.setState({ loading: false, WorkHourList: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };

  getWorkHoursByOrgId = async (ids) => {
    this.setState({ loading: true });

    let data = new FormData();
    data.append('organizationids', `${ids}`)

    var result = await Service.getWorkHoursListByOrgs(data);
    if (result.success) {
      this.setState({ loading: false, WorkHoursList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'servicetime' || name === 'solutiontime') {
      const re = /^[0-9\b]+$/;
      if (re.test(value)) {
        this.setState(prevState => ({
          organizationsla: {
            ...prevState.organizationsla,
            [name]: value
          }
        }));
      }
    } else {
      this.setState(prevState => ({
        organizationsla: {
          ...prevState.organizationsla,
          [name]: value
        }
      }));

    }
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });
    PapiroConsole.log("=== handleSubmit ===")
    PapiroConsole.log(this.state.organizationsla.WorkHourId)
    if (this.state.organizationsla.WorkHourId == 0 || this.state.organizationsla.WorkHourId == null || typeof this.state.organizationsla.WorkHourId === 'undefined' || (this.state.organizationsla.WorkHourId != null && this.state.organizationsla.WorkHourId.id != null && this.state.organizationsla.WorkHourId.id == 0)) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: this.props.intl.formatMessage({id:"workhour.sla.required.message"})
      });
      return false;
    } else {

    let data = new FormData();


    if (this.isClient) {
      this.setState(prevState => ({
        organizationsla: {
          ...prevState.organizationsla,
        }
      }));
    } else {
      this.setState(prevState => ({
        organizationsla: {
          ...prevState.organizationsla,
        }
      }));
    }


    for (let key in this.state.organizationsla) {
      if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
        data.append(key, moment(this.state.organizationsla[key]).format('YYYY/MM/DD HH:mm:ss'));
      } else {
        data.append(key, this.state.organizationsla[key].id ? this.state.organizationsla[key].id : this.state.organizationsla[key]);
      }
    }

    this.state.files.forEach(file => data.append('filesUpload', file));
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    Api.put('/organizationslas', data, config)

      .then(result => {
        if (result.data.success) {
          const formattedList = result.data.data;
          formattedList.OrganizationId = formattedList.organization ? formattedList.organization.id : 0;
          formattedList.OrganizationText = formattedList.organization ? formattedList.organization.name : '';
          formattedList.DistributionStrategyId = formattedList.distributionstrategy ? formattedList.distributionstrategy.id : 0;
          formattedList.DistributionStrategyText = formattedList.distributionstrategy ? formattedList.distributionstrategy.name : '';
          formattedList.WorkHourId = formattedList.workhour ? formattedList.workhour.id : 0;
          formattedList.WorkHourText = formattedList.workhour ? formattedList.workhour.name : '';
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "edit.success" }),
            files: []
          });
          if(this.props.organizations && this.state.organizations.organization)
            this.props.addCurrentOrganization(formattedList, this.state.organizations.organization);
          else
            this.props.addCurrent(formattedList);
          this.componentDidMount();

        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });

        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }) + err.response.data
          });
        }
      });
    }
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    if (stateName === 'OrganizationId') {
      this.setState(prevState => ({
        organizationsla: {
          ...prevState.organizationsla,
          ['OrganizationText']: text,
        }
      }));
      this.getWorkHours(value.id);
    } else if (stateName === 'DistributionStrategyId') {
      this.setState(prevState => ({
        organizationsla: {
          ...prevState.organizationsla,
          ['DistributionStrategyText']: text,
        }
      }));
    } else if (stateName === 'WorkHourId') {
      this.setState(prevState => ({
        organizationsla: {
          ...prevState.organizationsla,
          ['WorkHourText']: text,
        }
      }));
    } else if (stateName === 'SlaCountTypeId') {
      this.setState({ [stateName]: value })
    } else {
      this.setState(prevState => ({
        organizationsla: {
          ...prevState.organizationsla,
          [stateName]: value
        }
      }));
    }

    this.setState(prevState => ({
      organizationsla: {
        ...prevState.organizationsla,
        [stateName]: value
      }
    }));

    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  applySLACountTypeInOrganizationCategories = async () => {
    let { id, OrganizationId } = this.state.organizationsla

    PapiroConsole.log("=== applySLACountTypeInOrganizationCategories ===")
    PapiroConsole.log(id)
    PapiroConsole.log(OrganizationId)
    PapiroConsole.log(this.state.SlaCountTypeId)

    if (id != null && (OrganizationId && OrganizationId.id && OrganizationId.id > 0) && (this.state.SlaCountTypeId && this.state.SlaCountTypeId > 0)) {
      this.setState({ loading: true })

      let data = new FormData();
      data.append("OrganizationSLAId", id)
      data.append("OrganizationId", OrganizationId.id)
      data.append("SlaCountTypeId", this.state.SlaCountTypeId)
      data.append("UserAction", this.props.userSession.user.id)

      let result = await applySLACountType(data)
      if (result && result.data != null && result.data) {
        this.setState({
          openNotification: true,
          notificationVariant: 'success',
          notificationMessage: 'Categorias da organização atualizadas com sucesso',
          loading: false
        })
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);
      }
    }
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu, organizationslas } = this.props;
    const { organizationsla } = this.state;
    const intl = this.props.intl;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary' onClick={() => {
                    this.fromProps ? this.props.history.push(`/${this.props.match.params.lang}/organization-edit`, { organizations: this.props.organizations, propTab: 6 }) : browserHistory.goBack()}
                  }>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="edit.item" />}
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                <Grid container spacing={2}>
                  <Grid item xs={11} sm={11}>
                    <SimpleAutoComplete label={intl.formatMessage({ id: "organization" })} name='organizationid' options={this.state.OrganizationList} stateName='OrganizationId' changeSelect={this.changeValues} selected={organizationsla.OrganizationId} />
                  </Grid>
                  <Grid item xs={11} sm={11} style={{ display: 'none' }}>
                    <SimpleSelect label={intl.formatMessage({ id: "menu.distributionstrategies" })} name='distributionstrategyid' options={this.state.DistributionStrategyList} stateName='DistributionStrategyId' changeSelect={this.changeValues} selected={organizationsla.DistributionStrategyId} />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleSelect label={intl.formatMessage({ id: "workhour.Sla" })} name='workhourid' options={this.state.WorkHourList} stateName='WorkHourId' changeSelect={this.changeValues} selected={organizationsla.WorkHourId} />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <SimpleHelp message={this.props.intl.formatMessage({id:"organization.sla.edit.sla.calculation.base.standard.values"})} />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <TextField fullWidth inputProps={{ maxLength: 255 }} label={intl.formatMessage({ id: "name" })} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={organizationsla.name} required />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <TextField multiline rows="5" inputProps={{ maxLength: 255 }} fullWidth label={intl.formatMessage({ id: "comment" })} autoComplete='fname' variant='outlined' name='comments' onChange={this.handleInputChange} value={organizationsla.comments} />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <TextField fullWidth inputProps={{ maxLength: 15 }} label={intl.formatMessage({ id: "organization.category.service.sla" }) + " " + intl.formatMessage({ id: "in.minutes" })} autoComplete='fname' variant='outlined' name='servicetime' onChange={this.handleInputChange} value={organizationsla.servicetime} required />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <SimpleHelp message={this.props.intl.formatMessage({id:"organization.sla.edit.minutes.for.called.start"})} />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <TextField fullWidth inputProps={{ maxLength: 15 }} label={intl.formatMessage({ id: "sla.solution" }) + " " + intl.formatMessage({ id: "in.minutes" })} autoComplete='fname' variant='outlined' name='solutiontime' onChange={this.handleInputChange} value={organizationsla.solutiontime} required />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <SimpleHelp message={this.props.intl.formatMessage({id:"organization.sla.edit.minutes.for.called.solution"})} />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleCheckBox
                    label={intl.formatMessage({id:"apply.changes.to.all.organizations.in.group"})}
                    name='saveToAllOrganizationsInGroup'
                    stateName='saveToAllOrganizationsInGroup' 
                    changeSelect={this.changeValues}
                    selected={organizationsla.saveToAllOrganizationsInGroup} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography component='h1' variant='h5'>
                      Alterar tipo de contagem das categorias que utilizam este SLA
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sm={8}>
                    <SimpleSelect label={intl.formatMessage({id:"organization.category.count.type"})} options={this.state.SlaCountTypeList} stateName='SlaCountTypeId' changeSelect={this.changeValues} selected={this.state.SlaCountTypeId} />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <Button
                      onClick={this.applySLACountTypeInOrganizationCategories}
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit} >
                      {<FormattedMessage id="apply" />}
                    </Button>
                  </Grid>
                  <Grid item xs={11} sm={11} md={11} lg={11} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                    <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={4}    >
                        <Button
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit}
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
      </div>
    );
  }
}
OrganizationSlaEdit.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  organizations: state.organizations,
  organizationslas: state.organizationslas
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(OrganizationSlaEdit)));
