export default function whitelabelconfigs(state = [], action) {
  switch (action.type) {
    case 'ADD_CURRENT_WHITE_LABEL_CONFIG':
      return {
        ...state,
        whitelabelconfig: action.whitelabelconfig,
    }
    default:
      return state;
  }
}
