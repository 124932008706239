export function addCurrent(calledlistdata) {
  return {
      type: 'ADD_CURRENT',
      calledlistdata,
  }
}

export function addCurrentOrganization(calledlistdata,organization) {
  return {
      type: 'ADD_CURRENT_ORGANIZATION',
      calledlistdata,
      organization,
      }
}