import Api from './api';
export const getOrganizations = async () => { 
  let data = [];
  await Api.get('/organizations')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getOrganizationsById = async (id) => { 
  let data = [];
  await Api.get('/organizations/'+id)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getOrganizationsByIdLight = async (id) => { 
  let data = [];
  await Api.get('/organizations/notincludes/'+id)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getDistributionStrategies = async () => { 
  let data = [];
  await Api.get('/distributionstrategies')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getWorkHours = async (orgId = 0) => { 
  let data = [];

  if(orgId == 0){
    await Api.get('/workhours')
    .then(result => {
        data = result.data
      })
      .catch(err => {
        data = err;
    });
  }else{
    await Api.get(`/workhours/organization/${orgId}`)
      .then(result => {
          data = result.data
        })
        .catch(err => {
          data = err;
    });
  }
  
  return data;
};


export const getSLA = async (id = 0) => { 
  let data = [];
  if(id != 0){
    await Api.get(`/organizationslas/organizations/${id}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
    });
  }else{
    await Api.get('/slas')
    .then(result => {
        data = result.data
     })
     .catch(err => {
        data = err;
    });
  }
  
  return data;
};

export const getWorkHoursListByOrgs = async (dataForm) => {
  let data = [];
  await Api.post('/workhours/list/orgs', dataForm)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
}