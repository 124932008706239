export function addCurrent(organizationfieldreportblock) {
  return {
    type: 'ADD_CURRENT',
    organizationfieldreportblock,
  }
}

export function addCurrentOrganization(organizationfieldreportblock,organization) {
  return {
    type: 'ADD_CURRENT_ORGANIZATION',
    organizationfieldreportblock,
    organization,
  }
}