export function addCalledServiceSettingsCalledFieldsList(calledservicesettingscalledfieldslist) {
  return {
      type: 'ADD_LIST',
      calledservicesettingscalledfieldslist,
  }
}

export function addCalledServiceSettingsCalledField(calledservicesettingscalledfield) {
  return {
      type: 'ADD_CALLED_SERVICE_SETINGS_CALLED_FIELD',
      calledservicesettingscalledfield,
  }
}