export function addCurrent(organizationchecklist) {
  return {
      type: 'ADD_CURRENT',
      organizationchecklist,
  }
}
export function addCurrentOrganization(organizationchecklist,organization) {
  return {
      type: 'ADD_CURRENT_ORGANIZATION',
      organizationchecklist,
      organization,
      }
}