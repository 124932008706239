export default function holidays(state = [], action) {
    switch (action.type) {
        case 'ADD_CURRENT':
            return {
                ...state,
                holiday: action.holiday,
            }
        default:
            return state;
    }
}
