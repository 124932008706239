import { PapiroConsole } from "../../utils/papiroConsole";

let lastCalledsQueueMaxSize = localStorage.getItem("LAST_CALLEDS_QUEUE_MAX_SIZE")
if (lastCalledsQueueMaxSize == null){
  localStorage.setItem("LAST_CALLEDS_QUEUE_MAX_SIZE", 10)
  lastCalledsQueueMaxSize = localStorage.getItem("LAST_CALLEDS_QUEUE_MAX_SIZE")
}

const initialState = {
  items: {},
  headIndex: 0,
  tailIndex: 0,
  maxSize: lastCalledsQueueMaxSize != null && !isNaN(lastCalledsQueueMaxSize) && lastCalledsQueueMaxSize > 0 ? parseInt(lastCalledsQueueMaxSize) : 10
};

export default function lastCalledsQueue(state = initialState, action) {
  
  const equeue = (oldData, currentAction) => {
    PapiroConsole.log("=== equeuelastCalledsQueue -> redux ===")
    PapiroConsole.log(oldData)
    PapiroConsole.log(currentAction)
    let queue = oldData.items;
    let headIndex = oldData.headIndex;
    let tailIndex = oldData.tailIndex;

    if (!existsCalledInQueue(oldData, currentAction)) {
      if (oldData.maxSize > 0 && oldData.maxSize === (tailIndex - headIndex)) {
        delete queue[headIndex];
        headIndex = headIndex + 1;
      }
      queue[tailIndex] = currentAction.element;
      tailIndex = tailIndex + 1;
    } else {
      const oldQueue = queue;
      queue = initialState.items;
      headIndex = initialState.headIndex;
      tailIndex = initialState.tailIndex;
      const values = Object.values(oldQueue);

      queue[tailIndex] = currentAction.element;
      tailIndex = tailIndex + 1;

      values.forEach(item => {
        if (item !== null && item.id !== null && !isNaN(item.id) && currentAction !== null && currentAction.element !== null && currentAction.element.id !== null && !isNaN(currentAction.element.id) && item.id != currentAction.element.id) {
          queue[tailIndex] = item;
          tailIndex = tailIndex + 1;
        }
      }); 
    }
    
    let retItem = {
      ...oldData,
      items: queue,
      tailIndex: tailIndex,
      headIndex: headIndex
    };
    return retItem;
  };

  const existsCalledInQueue = (oldData, currentAction) => {
    let existsCalled = false;
    const values = Object.values(oldData.items);
    values.forEach(item => {
      if (item !== null && item.id !== null && !isNaN(item.id) && currentAction !== null && currentAction.element !== null && currentAction.element.id !== null && !isNaN(currentAction.element.id) && item.id === currentAction.element.id) {
        existsCalled = true;
      }
    }); 

    return existsCalled;
  };

  switch (action.type) {
    case 'EQUEUE_LAST_CALLEDS_QUEUE':
      return equeue(state, action);
    case 'CLEAR_LAST_CALLEDS_QUEUE':
      return initialState;
    default:
      return state;
  }
}