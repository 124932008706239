import * as SatisfactionSurveyService from '../../../../../../services/satisfactionsurvey.service';

export const getSatisfactionSurveys = async () => {
  var result = await SatisfactionSurveyService.getAll();

  if (result.success && result?.data?.length > 0) {
    let satisfactionSurveyList = result?.data?.map(item => ({id: item?.id, name: item?.name}));
    return satisfactionSurveyList;              
  } 

  return [];
};