import React, { Component } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, IconButton, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';

class InfoAccordion extends Component {
  render() {

    const phoneNumber = this.props.phoneNumber;
    const phoneNumberId = this.props.phoneNumberId;
    const accountId = this.props.phoneNumber;
    return (
      <Accordion sx={{backgroundColor: '#f9f9f9', width: 'fit-content', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px', border: '1px solid #ccc', transition: 'box-shadow 0.3s' }}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ backgroundColor: '#f4f4f4', borderRadius: '8px 8px 0 0', alignItems: 'center', '&:hover': { backgroundColor: '#e0e0e0' } }}
        >
          <Tooltip title="Mostrar Informações" arrow>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <InfoIcon sx={{ color: '#3f51b5', marginRight: 1 }} />
              <Typography variant="body1" sx={{ color: '#333' }}>Informações</Typography>
            </div>
          </Tooltip>
          <IconButton
            sx={{ marginLeft: 'auto', padding: '8px', backgroundColor: 'transparent', borderRadius: '50%', '&:hover': { backgroundColor: 'transparent' } }}
            aria-label="expand"
            aria-expanded={false}
          >
            <ExpandMoreIcon sx={{ color: '#333' }} />
          </IconButton>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: '#fff', borderRadius: '0 0 8px 8px', padding: '16px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', borderTop: '1px solid #ccc' }}>
        {phoneNumber &&
              <Typography variant="body2" gutterBottom>

                <strong>Número de Telefone:</strong> <br/> {phoneNumber}
              </Typography>
            }

            {accountId &&
               <Typography variant="body2" gutterBottom>
               <strong>Número da Conta:</strong> <br/>{accountId}
             </Typography>
            }

            {phoneNumberId &&
                  <Typography variant="body2">
                  <strong>ID do Telefone:</strong> <br/>{phoneNumberId}
                </Typography>
            }
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default InfoAccordion;
