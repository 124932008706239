import { Component } from "react";
import { bindActionCreators } from "redux";
import PropTypes from 'prop-types';
import * as componentActions from '../../redux/actions/companies';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { AbilityContext } from '../../config/ability-context';
import HeaderMenu from "../../components/header-menu";
import Api from "../../services/api";
import clsx from 'clsx';
import { Button, CircularProgress, Container, CssBaseline, Grid, Avatar, TextField, Typography, ImageList, Tooltip, IconButton } from "@mui/material";
import { browserHistory } from '../../helpers/history';
import Api2Talk from "../../services/api2talk";
import { Overlay } from "../../styles/global.js";
import CustomizedSnackbars from "../../components/material-snackbars/index.js"; 
import PeopleIcon from '@mui/icons-material/People';
import { isValidCnpj } from "../../utils/validations/cnpj.validation.js";
import { cnpjMask } from "../../utils/mask/cnpj.mask.js";
import PhoneInput from "react-phone-input-2";
import Dropzone from "react-dropzone";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { AttachFile, DeleteForever } from "@mui/icons-material";
import RemoveIcon from '@mui/icons-material/Delete';
import * as FileService from '../../services/attachment.service';
import DialogGeneral from "../../components/dialog-general/index.js";


class CompanyEdit extends Component {

  constructor(props) {
    super(props);

    const { userSession, companies } = this.props;
    this.globalAdm = false;
    this.isClient = true;

    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }

    if (!companies || (companies && !companies.company)) {
      var lang = this.props.match.params.lang;
      document.location.href = `/${lang}/companies`
    }

    this.formDataInitialState = {
      createduser: userSession.user.id,
      id: companies.company.id,
      name: companies.company.name,
      identificationnumber: '',
      address: '',
      website: '',
      mainactivity: '',
      phone: '',
      attachmentid: ''
    }; 

    this.state = {
      itemError: {
        identificationnumber: '',
      },
      formData: this.formDataInitialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      companyattachments: [],
      files: [],
      DialogRemoveState: false,
    };

    this.openOrCloseRemoveModal = this.openOrCloseRemoveModal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.useStyles = this.useStyles.bind(this);
    this.removeLogo = this.removeLogo.bind(this);
    this.handleDeleteLogo = this.handleDeleteLogo.bind(this);
    this.validateImagesAtachments = this.validateImagesAtachments.bind(this);
  }

  fetchDataNotSuccess(result) {
    const intl = this.props.intl;
    this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    });
  }

  fetchDataThrowError(err) {
    const intl = this.props.intl;
    this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    });
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        [name]: value
      }
    }));

    if (name === 'identificationnumber') {
      let valor = 0;
    
      valor = cnpjMask(value);
      
      if ((value.length !== 14 && value.length > 0) || !isValidCnpj(value)) {
        this.setState(prevState => ({ itemError: { ...prevState.itemError, identificationnumber: 'CNPJ inválido' } }));
      } else {
        this.setState(prevState => ({ itemError: { ...prevState.itemError, identificationnumber: '' } }));
      }
      
      // Atualiza o estado do usuário com o valor formatado
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          [name]: valor
        }
      }));
    }
  };

  handleSubmit = async e => {
    e.preventDefault();

    let data = new FormData();
   
    let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    }; 

    const formData = this.state.formData;

    this.setState({ loading: true });

    if(!isValidCnpj(formData.identificationnumber)){
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "O campo CNPJ não foi validado" 
      });
      return;
    }

    if (this.state.files.length == 0 && this.state.companyattachments.length == 0) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "Você precisa informar um logotipo" 
      });
      return;
    }

    for (let key in this.state.formData) {
      if (this.state.formData[key] != undefined) {
        data.append(key, this.state.formData[key])
      }
    }

    if (this.state.files.length > 0) {
      this.state.files.forEach(file => data.append('FilesUpload', file));
    }

    Api2Talk.put(`/company`, data, config)
      .then(result => {
          if (result.data.success) {
              this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'success',
                  notificationMessage: 'Registro atualizado com sucesso'
              });
          } else {
              this.setState({ loading: false });
              this.fetchDataNotSuccess(result);
              Api.kickoff(result);
          }
      })
      .catch(err => {
          this.setState({ loading: false });
          this.fetchDataThrowError(err);
          Api.kickoff(err);
      });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  fetchCompanyData = async () => {
    this.setState({ loading: true });
    const {companies} = this.props;
    try {
      const response = await Api2Talk.get(`/company/${companies.company.id}`);
      const { data } = response;

      if (data.success) {

          if (data.data?.attachmentid) {
            const file = {
              id: data.data?.attachmentid,
              description: data.data?.attachmentdescription
            }

            this.setState({
              companyattachments: [...this.state.companyattachments, file]
            })
          }

          this.setState({
              loading: false,
              formData: {
                  ...this.state.formData,
                  identificationnumber: cnpjMask(data.data.identificationnumber),
                  address: data.data?.address,
                  website: data.data?.website,
                  mainactivity: data.data?.mainactivity,
                  phone: data.data?.phone,
                  attachmentid: data.data?.attachmentid
              }
          });
      } else {
          this.setState({ loading: false });
          this.fetchDataNotSuccess(data);
          Api.kickoff(data);
      }
    } catch (error) {
        this.setState({ loading: false });
        this.fetchDataThrowError(error);
        Api.kickoff(error);
    }

      
  }

  async componentDidMount() {
    await this.fetchCompanyData();
  }

  handleChangePhone(e) {
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        'phone': e
      }
    }));
  }

  useStyles() {
    return withStyles({
      ImageList: {
        width: "100%",
        height: 100,
      },
    });
  };

  async getFile(id) {

    const intl = this.props.intl;
    this.setState({ loading: true });

    const respose = await FileService.getFileById(id);
    this.setState({ loading: false });
    const {data} = respose;

    if (data.success) {
      window.open(`${data.data}`, '_blank');
    }
    else {
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "file.not.found" })
      });
    }
  };

  openOrCloseRemoveModal = () => {
    this.setState({ DialogRemoveState: false })
  }

  async removeLogo() {
    this.setState({ DialogRemoveState: true })
  }

  async handleDeleteLogo(){
    try {
      const id = this.state.formData.id;
      const response = await Api2Talk.delete(`/company/removelogo/${id}`);
      this.openOrCloseRemoveModal();
      this.setState({
        companyattachments: []
      })
      console.log("@log::", response)
    } catch (error) {
      console.log("@log::", error)
    }
  } 

  validateImagesAtachments(files) {
    if (this.state.companyattachments.length > 0) {
      this.setState({
          evAttachFile: {},
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: "Você precisa remover sua logo atual se quiser adicionar uma nova",
      })
      return
  }

    const intl = this.props.intl;
    if (files.length > 1) {
        this.setState({
            evAttachFile: {},
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: intl.formatMessage({ id: "checklist.modal.select.image.error" }),
        })
        return
    }
    else {
        this.setState({ files: files })
    }
  }


  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;

    const classes2 = this.useStyles();
   
    const { formData, itemError } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
         <HeaderMenu />
         <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
         >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary' onClick={browserHistory.goBack}><FormattedMessage id="back" /></Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar mt={7} style={{ backgroundColor: '#303f9f', color: 'white' }}>
                <PeopleIcon />
              </Avatar>
              <Typography component='h1' variant='h5' mt={2}>
                {intl.formatMessage({ id: "company.edit" })}
              </Typography>

              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={12}>
                      <TextField 
                        inputProps={{ maxLength: 250 }} 
                        fullWidth 
                        label={intl.formatMessage({id:"name"})} 
                        autoComplete='fname' 
                        variant='outlined' 
                        name='name' 
                        onChange={this.handleInputChange} 
                        value={formData.name} 
                        required 
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField
                        fullWidth
                        label={intl.formatMessage({ id: "identificationnumber" })}
                        autoComplete="fidentificationnumber"
                        inputProps={{ maxLength: 255 }}
                        name="identificationnumber"
                        onChange={this.handleInputChange}
                        value={formData.identificationnumber}
                        variant='outlined'
                        error={itemError.identificationnumber?.length > 0}
                        helperText={itemError.identificationnumber} 
                        required
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField 
                        inputProps={{ maxLength: 250 }} 
                        fullWidth 
                        label={intl.formatMessage({id:"address"})} 
                        autoComplete='faddress' 
                        variant='outlined' 
                        name='address' 
                        onChange={this.handleInputChange} 
                        value={formData.address} 
                        required 
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField 
                        inputProps={{ maxLength: 250 }} 
                        fullWidth 
                        label={intl.formatMessage({id:"website"})} 
                        autoComplete='fwebsite' 
                        variant='outlined' 
                        name='website' 
                        onChange={this.handleInputChange} 
                        value={formData.website} 
                        required 
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField 
                        inputProps={{ maxLength: 250 }} 
                        fullWidth 
                        label={intl.formatMessage({id:"mainactivity"})} 
                        autoComplete='fmainactivity' 
                        variant='outlined' 
                        name='mainactivity' 
                        onChange={this.handleInputChange} 
                        value={formData.mainactivity} 
                        required 
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <PhoneInput
                        required
                        inputStyle={{
                          padding: '23.5px 14px 23.5px 58px',
                          width: '100%'
                        }}
                        id="phone"
                        name="Phone"
                        country={'br'}
                        InputLabel={intl.formatMessage({ id: "phone" })}
                        specialLabel={intl.formatMessage({ id: "phone" })}
                        inputProps={{
                          name: intl.formatMessage({ id: "phone" })
                        }} 
                        onChange={this.handleChangePhone} 
                        value={formData.phone} 
                      />
                    </Grid>

                    <Grid item xs={12} >
                        {/*<TextField label="Logo" autoComplete='fname' variant='outlined' name='logo' onChange={this.handleInputChangeCompanyRegistration} value={companyRegistration.logo} required fullWidth />*/}
                        <Grid item xs={12} sm={12}>
                            <section style={{ width: '100%' }}>
                                <div 
                                  className={classes.dropzoneArea} 
                                  style={{ minHeight: '70px', marginTop: '5px ' }}
                                >
                                    <Dropzone 
                                      onDrop={acceptedFiles => this.validateImagesAtachments([...acceptedFiles, ...this.state.files])}
                                    >
                                        {({ getRootProps, getInputProps, acceptedFiles }) => (
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <AttachFileIcon />
                                                <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                                                    <FormattedMessage id="drag.and.drop.logo" />
                                                </Typography>
                                            </div>
                                        )}
                                    </Dropzone>
                                    {this.state.files.map(file => {
                                        return (
                                            <div className={classes.dropzoneItem} key={file.name}>
                                                {file.name}
                                                <DeleteForever className={classes.deleteItem} onClick={() => { this.removeItem(file) }} />
                                            </div>
                                        )
                                    })}
                                </div>
                            </section>
                        </Grid>
                    </Grid>

                    {this.state.companyattachments.length > 0 && (
                    <Grid item xs={12} style={{ marginTop: 15 }}>
                      <div className={classes.attachmentsBox}>
                        <label>{<FormattedMessage id="attachments" />}</label>
                        <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                          <ul>
                            {this.state.companyattachments.map(file => {
                              return (
                              <li key={file.id}>
                                <AttachFile /> 
                                <Button 
                                  onClick={() => this.getFile(file.id)}
                                >
                                  {file.description}
                                </Button>
                                <Tooltip 
                                  title={<h5>Remover arquivo</h5>}
                                >
                                  <IconButton aria-label="textevidence"
                                    onClick={ () => this.removeLogo(file.id)}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </Tooltip>
                              </li>)
                            })}
                          </ul>
                        </ImageList>
                      </div>
                    </Grid>
                  )}
                    
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                      <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                        <Grid item xs={12} sm={4}    >
                          <Button 
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                          >
                            {<FormattedMessage id="save" />}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                </Grid>
              </form>
              <DialogGeneral
                dialogTitle="Remover"
                dialogSubTitle="Você deseja remover esta logo ?"
                open={this.state.DialogRemoveState}
                openOrCloseModalFunction={this.openOrCloseRemoveModal}
                handleConfirmation={this.handleDeleteLogo}
                
              />
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            {
              this.state.openNotification && (
                <CustomizedSnackbars
                  variant={this.state.notificationVariant}
                  message={this.state.notificationMessage}
                  isOpen={this.state.openNotification}
                  toClose={this.closeNotification}
                />
              )
            }          
          </Container>
         </main>
      </div>
    )
  }
}

CompanyEdit.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  companies: state.companies
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CompanyEdit)));

CompanyEdit.contextType = AbilityContext;