import React, { Component } from 'react';
import { Container, Typography, Box, Grid, Button, IconButton, Paper, Stack } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

class BackupCodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codes: props.codes ? props.codes : []
    };
  }

  handleCopyCodes = () => {
    const codesText = this.state.codes.join('\n');
    navigator.clipboard.writeText(codesText);
    
  };

  handleDownloadCodes = () => {
    const element = document.createElement("a");
    const file = new Blob([this.state.codes.join('\n')], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = "backup-codes.txt";
    document.body.appendChild(element);
    element.click();
  };

  handlePrintCodes = () => {
    window.print();
  };

  handleBackToLogin = () => {
   this.props.handleBackToLogin();
  };

  render() {
    return (
      <Container maxWidth="sm" sx={{ mt: 5, textAlign: 'center' }}>
        <Typography variant="h4" color="primary" fontWeight="bold" gutterBottom>
          Autenticação
        </Typography>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          Revise e salve seus códigos de recuperação de autenticação de dois fatores
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
          Se você não conseguir acessar sua conta, poderá restaurar seu acesso com qualquer um dos seguintes códigos de uso único. É importante guardá-los agora e mantê-los em um local seguro.
        </Typography>
        
        <Paper variant="outlined" sx={{ p: 3, mb: 3 }}>
          <Grid container spacing={2}>
            {this.state.codes.map((code, index) => (
              <Grid item xs={6} key={index} sx={{ textAlign: 'center' }}>
                <Typography variant="body1" fontWeight="bold">
                  {code}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Paper>
        
        <Stack direction="row" spacing={2} justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            startIcon={<FileCopyIcon />}
            onClick={this.handleCopyCodes}
          >
            Copiar Códigos
          </Button>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<DownloadIcon />}
            onClick={this.handleDownloadCodes}
          >
            Download
          </Button>
          <IconButton
            color="primary"
            onClick={this.handlePrintCodes}
          >
            <PrintIcon />
          </IconButton>
        </Stack>
        
        {/* Botão de voltar à página de login */}
        <Box mt={4} display="flex" justifyContent="flex-end" >
          <Button
            color="secondary"
            startIcon={<ArrowBackIcon />}
            onClick={this.handleBackToLogin}
          >
            Voltar à Página de Login
          </Button>
        </Box>
      </Container>
    );
  }
}

export default BackupCodes;
