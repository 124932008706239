import React, { Component } from "react";
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import { CssBaseline, CircularProgress, Container, Button, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import CustomizedSnackbars from "../../components/material-snackbars";
import HeaderMenu from "../../components/header-menu";
import MaterialTable from 'material-table';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import MyMaterialTable from '../../components/table/index-report';
import Api from "../../services/api";
import * as calledsActions from '../../redux/actions/calleds';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import Footer from '../../components/footer';
import moment from "moment";
import * as CalledService from '../../services/called.service';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { getSlaAtendimento, getSlaSolucao } from '../called-list/called.functions';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import SimpleDatePicker from '../../components/date-pickers/inline-no-today';
import { saveAs } from 'file-saver';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {
    PieChart, Pie, Sector, Cell,BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
  } from 'recharts';
import LogoMarca from '../../images/new-logo-cmtech.png';
import html2canvas from 'html2canvas';
import Divider from '@mui/material/Divider'
import * as ExcelJS from 'exceljs'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import {  Dialog } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Budget from '../checklist-report/components/budget';
import ApiReports from "../../services/apireport";
import { PapiroConsole } from "../../utils/papiroConsole";
import fileDownload from 'js-file-download'

class MaterialReports extends Component {

    constructor(props) {
        super(props);
        this.Reload = this.Reload.bind(this);
        this.getFile = this.getFile.bind(this);
        this.Reload();

        const { userSession } = this.props;

        this.isClient = true;
        this.isAdm = false;

        const intl = this.props.intl;

        if (userSession == null ||  userSession.user == null || userSession.user.userrole == null) {
            Api.logoff();
        }

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false;
            }
            if (item.role.roletype == 0 || item.role.roletype == 1) {
                this.isAdm = true;
            }
        }
        );
        this.organizationId = null;
        if (userSession.user.organization) {
            this.organizationId = userSession.user.organization.id;
        }

        this.fcrOptions = [
            { name: this.props.intl.formatMessage({id:"yes"}), value: true },
            { name: this.props.intl.formatMessage({id:"no"}), value: false },
            { name: this.props.intl.formatMessage({id:"all"}), value: null },
        ];
        this.COLORS = null

        this.state = {
            dateStart: moment().format('YYYY/MM/DD HH:mm:ss'),
            dateEnd: moment().format('YYYY/MM/DD HH:mm:ss'),
            organizationId: this.organizationId,
            OrganizationFilter: 0,
            location: 0,
            loading: false,
            openNotification: false,
            notificationVariant: "error",
            notificationMessage: "",
            organizationAddressesList: [],
            showGrid: false,
            calledsList: [],
            organizationList: [],
            showHeader: false,
            inFCR: { name: this.props.intl.formatMessage({id:"all"}), value: null },
            openOrCloseGraphic: false,
            arrayCalledList: [
                {
                  "name": intl.formatMessage({id:"fcr.report.called.in.fcr"}),
                  "value" : 0
                },
                {
                  "name": intl.formatMessage({id:"fcr.report.called.out.fcr"}),
                  "value" : 0
                },
            ],
            arrayCalledListCards: [
                {
                  "name": intl.formatMessage({id:"fcr.report.called.in.fcr"}),
                  "value" : 0
                },
                {
                  "name": intl.formatMessage({id:"fcr.report.called.out.fcr"}),
                  "value" : 0
                },
                {
                    "name": intl.formatMessage({id:"fcr.report.total.calleds"}),
                    "value" : 0
                },
            ],
            
        };
        this.closeNotification = this.closeNotification.bind(this);
        this.changeValues = this.changeValues.bind(this);
        this.exportPdf = this.exportPdf.bind(this);
        this.getExcel = this.getExcel.bind(this)
        this.convertHtmlOption = this.convertHtmlOption.bind(this)
    }

    convertHtmlOption = value => {
        let body = value
        let temp = document.createElement("div")
        temp.innerHTML = body
        let sanitized = temp.textContent || temp.innerText
    
        return sanitized
    }

    openOrCloseGraphic = () => {
        this.setState({ openOrCloseGraphic: !this.state.openOrCloseGraphic });
    }

    async changeValues(stateName, value, text = '') {
        this.setState({ loading: true });
        console.log(stateName)
        console.log(value)
        if (stateName === 'location') {
            this.setState({ [stateName]: value.id });
        }
        else if (stateName === 'OrganizationFilter') {
            this.setState({ [stateName]: value.id });
            await this.getOrganizationAddresses(value.id);
        } else if (stateName === 'inFCR') {
            this.setState({ [stateName]: value.value });
        } else {
            this.setState({ [stateName]: value });
        }
        this.setState({ loading: false });
    };

    async exportPdf() {

        this.setState({
            openNotification: true,
            notificationVariant: 'info',
            notificationMessage: this.props.intl.formatMessage({id:"routine.in.maintenance"})
        })

        /*const data = this.state.calledsList;
        const intl = this.props.intl;
        await this.setState({ loading: true, showHeader: true });

        const doc = new jsPDF("p", "mm", "a4");

        const header = document.getElementById('header-pdf');
        let headerCanvas = await html2canvas(header);
        const headerImg = headerCanvas.toDataURL('image/png');
        doc.addImage(headerImg, 'PNG', 0, 0);

        var width = doc.internal.pageSize.getWidth();

        doc.setLineWidth(width);
        doc.autoTable({
            body: data,
            margin: { top: 30 },
            bodyStyles: { valign: 'middle' },
            styles: { overflow: 'linebreak', columnWidth: 'wrap', fontSize: 7, halign: 'center' },
            theme: 'striped',
            columns: [
                { header: intl.formatMessage({id:"id"}), key: 'id', width: 25 },
                { header: intl.formatMessage({ id: "category" }), dataKey: 'OrganizationCategory' },
                { header: intl.formatMessage({ id: "last.move.date" }), dataKey: 'lastAction' },
                { header: intl.formatMessage({ id: "called.Accom.date" }), dataKey: 'lastAction' },
                { header: intl.formatMessage({ id: "open.date" }), dataKey: 'openingdate' },
                { header: intl.formatMessage({ id: "title" }), dataKey: 'title' },
                { header: intl.formatMessage({ id: "assigned" }), dataKey: 'assignedUser' },
                { header: intl.formatMessage({ id: "Status" }), dataKey: 'statusName' },
                { header: intl.formatMessage({ id: "organization" }), dataKey: 'Organization' },
                { header: intl.formatMessage({ id: "departament" }), dataKey: 'userdepartament' },
                { header: intl.formatMessage({ id: "menu.techinicalteams" }), dataKey: 'tecnteams' },
                { header: intl.formatMessage({ id: "location" }), dataKey: 'location' },
                { header: intl.formatMessage({ id: "sla.solution" }), dataKey: 'slaSolucao' },
                { header: intl.formatMessage({ id: "sla.service" }), dataKey: 'slaAtendimento' },
                { header: intl.formatMessage({ id: "wasbaduse" }), dataKey: 'baduse' },

            ],
            columnStyles: {
                id: {
                    columnWidth: 9
                },
                annotation: {
                    columnWidth: 25,
                },
                lastAction: {
                    columnWidth: 28,
                },
                Organization: {
                    columnWidth: 19,
                },
                userDepartment: {
                    columnWidth: 20,
                },
                materials: {
                    columnWidth: 20,
                },
                reams: {
                    columnWidth: 20,
                },
                patrimonys: {
                    columnWidth: 19,
                },
                location: {
                    columnWidth: 19,
                },
                baduse: {
                    columnWidth: 15,
                },
            },
        })
        doc.save("relatorio-fcr.pdf");
        await this.setState({ loading: false, showHeader: false });*/

    }

    async getOrganizationAddresses(organizationId) {
        this.setState({ loading: true });

        const result = await CalledService.getOrganizationAddresses(organizationId);

        if (result.success) {
            if (this.isTec == false) {
                if (result.data.length < 1) {
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: this.props.intl.formatMessage({id:"called.accompaniment.notification.organization.no.registered.address"})
                    });
                }
            }

            this.setState({ loading: false, organizationAddressesList: result.data });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);

        }
    };

    Reload = () => {
        const reload = localStorage.getItem('reload');
        if (reload == "true") {
            localStorage.setItem('reload', false);
        }
        return null;
    }

    async getOrganizations() {
        let data = [];
        const { userSession } = this.props
        if(userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
            data = userSession.user.userorganizations
        } else {
            await Api.get('/organizations/tech')
                .then(result => {
                    data = result.data.data
                })
                .catch(err => {
                    data = err;
                });
        }
        this.setState({ organizationList: data })
    };

    async componentDidMount() {
        this.setState({ loading: true })
        await this.getOrganizations();
        this.setState({ loading: false })

        //await this.getAllCalleds()
    }
    componentWillReceiveProps(nextProps) {

        if (nextProps.location.state === "calledReports") {
            this.getCalleds();
        }

        if (nextProps.location.state === "materialReports") {
            this.getCalleds();
        }

        else if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    async getExcel() {

        this.setState({ loading: true });

        const intl = this.props.intl;
        var dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
        var dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')
        if(dateStart > dateEnd){
        
            this.setState({
                openNotification: true,
                notificationVariant: "error",
                notificationMessage:intl.formatMessage({id:"report.audit.initial.date.higher.than.end.date"}),
                loading :false
                });
        }
        else{
            var url = '/report/fcr/getexcell';

            url += `?dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&loc=${this.state.location}&org=${this.state.OrganizationFilter}&inFCR=${this.state.inFCR}`;
            var writing = ""
    
            ApiReports.get(url, {
                responseType: 'blob'
              })
                .then((response) => {
          
                  fileDownload(response.data, `export_reportfcr_${moment(this.state.dateStart).format('YYYY-MM-DD')}_${moment(this.state.dateEnd).format('YYYY-MM-DD')}.zip`);
          
                  this.setState({ loading: false });
                })
                .catch((error) => {
                  this.setState({ loading: false });
                });
        }
     

        /*this.setState({
            openNotification: true,
            notificationVariant: 'info',
            notificationMessage: this.props.intl.formatMessage({id:"routine.in.maintenance"})
        })*/

        /*const intl = this.props.intl;
        const wb = new ExcelJS.Workbook()

        const ws = wb.addWorksheet()

        // console.log(this.state.calledsList)
        ws.columns = [
            { header: intl.formatMessage({id:"id"}), key: 'id', width: 25 },
            { header: intl.formatMessage({ id: "category" }), key: 'OrganizationCategory', width: 25 },
            { header: intl.formatMessage({ id: "called.Accom.date" }), key: 'lastAction', width: 25 },
            { header: intl.formatMessage({ id: "open.date" }), key: 'openingdate', width: 25 },
            { header: intl.formatMessage({ id: "title" }), key: 'title', width: 25 },
            { header: intl.formatMessage({ id: "assigned" }), key: 'assignedUser', width: 25 },
            { header: intl.formatMessage({ id: "Status" }), key: 'statusName', width: 25 },
            { header: intl.formatMessage({ id: "organization" }), key: 'Organization', width: 25 },
            { header: intl.formatMessage({ id: "departament" }), key: 'userdepartament', width: 25 },
            { header: intl.formatMessage({ id: "menu.techinicalteams" }), key: 'tecnteams', width: 25 },
            { header: intl.formatMessage({ id: "location" }), key: 'location', width: 25 },
            { header: intl.formatMessage({ id: "sla.solution" }), key: 'slaSolucao', width: 25 },
            { header: intl.formatMessage({ id: "sla.service" }), key: 'slaAtendimento', width: 25 },
            { header: intl.formatMessage({ id: "wasbaduse" }), key: 'baduse', width: 25 },

        ];

        ws.getCell('A1').style = { font: { bold: true } }
        ws.getCell('B1').style = { font: { bold: true } }
        ws.getCell('C1').style = { font: { bold: true } }
        ws.getCell('D1').style = { font: { bold: true } }
        ws.getCell('E1').style = { font: { bold: true } }
        ws.getCell('F1').style = { font: { bold: true } }
        ws.getCell('G1').style = { font: { bold: true } }
        ws.getCell('H1').style = { font: { bold: true } }
        ws.getCell('I1').style = { font: { bold: true } }
        ws.getCell('J1').style = { font: { bold: true } }
        ws.getCell('K1').style = { font: { bold: true } }
        ws.getCell('L1').style = { font: { bold: true } }
        ws.getCell('M1').style = { font: { bold: true } }
        ws.getCell('K1').style = { font: { bold: true } }

        ws.addRows(this.state.calledsList);

        const buf = await wb.xlsx.writeBuffer()

        saveAs(new Blob([buf]), 'relatorio_chamados.xlsx')*/
    }
    getFile() {

        this.setState({ loading: true });
        var url = '/materialreports/getfile';
        url = url + '?filter=' + this.props.location.state;

        url += `&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&loc=${this.state.location}&org=${this.state.OrganizationFilter}`;

        Api.get(url, {
            responseType: 'blob'
        })
            .then((response) => {

                let url = window.URL.createObjectURL(new Blob([response.data]));
                saveAs(url, 'relatorio_chamados.xlsx');
                this.setState({ loading: false });
            });
    }

    getMessageBadUseFlag(message) {
        let writing = ""
        if (message === true) {
            writing = this.props.intl.formatMessage({id:"yes"})
        }
        else if (message === false) {
            writing = this.props.intl.formatMessage({id:"no"})
        }
        else {
            writing = this.props.intl.formatMessage({id:"undefined"})
        }

        return writing
    }




    getCalleds(filter = '') {
        const intl = this.props.intl;
        this.setState({ loading: true });

        var dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
        var dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')
        if(dateStart > dateEnd){
        
            this.setState({
                openNotification: true,
                notificationVariant: "error",
                notificationMessage:intl.formatMessage({id:"report.audit.initial.date.higher.than.end.date"}),
                loading :false
                });
        }
        else{

            var url = '/report/fcr/paginate';

        url += `?dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&loc=${this.state.location}&org=${this.state.OrganizationFilter}&inFCR=${this.state.inFCR}`;
        var writing = ""
        ApiReports.get(url)
            .then(result => {
                if (result.data.success) {
                    PapiroConsole.log(result.data)
          PapiroConsole.log("result.data")
                    const formattedList = result.data.data.calleds.map(item => {
                        let lastAction = "";
                        let tecteams = "";

                        if (item.organizationcategory.techinicalteamorganizationcategories != null && item.organizationcategory.techinicalteamorganizationcategories.length > 0)
                            item.organizationcategory.techinicalteamorganizationcategories.forEach((element, index) => {
                                if (element.organizationid === item.organization.id && item.organizationcategory.id === element.organizationcategoryid) {
                                    tecteams += element.techinicalteam.name + ', '
                                }

                            });
                        tecteams = tecteams.slice(0, -1);

                        if (item.accompaniments.length > 0) {
                            lastAction = item.accompaniments[item.accompaniments.length - 1].actiontime;
                        } else {
                            lastAction = item.openingdate;
                        }

                        writing = this.getMessageBadUseFlag(item.baduse)

                        return {
                            ...item,
                            baduse: writing,
                            typeName: item.type.name,
                            annotation: item.accompaniments.length > 0 ? this.convertHtmlOption(item.accompaniments[item.accompaniments.length - 1].description) : "",
                            openingdate: moment(item.openingdate).format('DD/MM/YYYY HH:mm'),
                            originRequestName: item.originrequest.name,
                            statusName: item.status.name,
                            OrganizationCategory: item.organizationcategory.parentname ? item.organizationcategory.parentname : item.organizationcategory.name,
                            Organization: item.organization.name,
                            slaAtendimento: getSlaAtendimento(item, this.props.userSession) + "%",
                            slaSolucao: getSlaSolucao(item, this.props.userSession) + "%",
                            assignedUser: item.assigneduser ? item.assigneduser.name : "",
                            requestingUser: item.requestinguser ? item.requestinguser.name : "",
                            lastAction: moment(lastAction).format('DD/MM/YYYY HH:mm'),
                            tecnteams: tecteams,
                            userdepartament: item.organizationdepartament ? item.organizationdepartament.name : "",
                            location: item.address.name
                        }
                        
                    });

                    this.COLORS = ['#0088FE', '#00C49F', '#FFBB28'];
                    

                    var arrayCalledList = [
                        {
                          "name": intl.formatMessage({id:"fcr.report.called.in.fcr"}),
                          "value" : result.data.data.numbertotalinsidefcr,
                        },
                        {
                          "name": intl.formatMessage({id:"fcr.report.called.out.fcr"}),
                          "value" : result.data.data.numbertotaloutsidefcr,
                        }
                    ]

                    var arrayCalledListCards = [
                        {
                          "name": intl.formatMessage({id:"fcr.report.called.in.fcr"}),
                          "value" : result.data.data.numbertotalinsidefcr,
                        },
                        {
                          "name": intl.formatMessage({id:"fcr.report.called.out.fcr"}),
                          "value" : result.data.data.numbertotaloutsidefcr,
                        },
                        {
                            "name": intl.formatMessage({id:"fcr.report.total.calleds"}),
                            "value" : result.data.data.numbertotal,
                        },
                    ]

                    this.setState({
                        calledsList: formattedList,
                        loading: false,
                        showGrid: true,
                        arrayCalledList: arrayCalledList,
                        arrayCalledListCards: arrayCalledListCards,
                    });
                }
                else {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                // console.log(err);
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(err);

            });

        }
        
    };

    async getAllCalleds() {

        Api.get('/calleds')
            .then(result => {
                if (result.data.success) {
                    // console.log(result.data)
                }
                else {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(err);

            });

        //this.setState({ organizationList: data })
    };

    onActiveIndexEnter = (data, index) => {
        this.setState({activeIndex: index});
    };

    renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';
      
        return (
          <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
              {payload.name}
            </text>
            <Sector
              cx={cx}
              cy={cy}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              startAngle={startAngle}
              endAngle={endAngle}
              fill={fill}
            />
            <Sector
              cx={cx}
              cy={cy}
              startAngle={startAngle}
              endAngle={endAngle}
              innerRadius={outerRadius + 6}
              outerRadius={outerRadius + 10}
              fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
              {`( ${(percent * 100).toFixed(2)}%)`}
            </text>
          </g>
        );
      };

    handleSubmit = async e => {
        e.preventDefault();

        this.setState({ loading: true });
        this.getCalleds(this.props.location.state);
    };

    closeNotification() {
        this.setState({ openNotification: false });
    }

    render() {
        const { classes, userSession, headerMenu, actionsHeaderMenu } = this.props;
        var link = `/${this.props.match.params.lang}/called-registration`;
        if (this.isClient) {
            link = `/${this.props.match.params.lang}/called-registration-client`;
        }

        if (Api == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/unavailable`
        }

        PapiroConsole.log(this.state.arrayCalledListCards)
        PapiroConsole.log("this.state.arrayCalledListCards")
        const intl = this.props.intl;
        const tableList = () => {
            return (
                <div>
                    <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>

                        {this.state.showHeader &&
                            <Grid container >
                                <Grid id="header-pdf" item lg={4} sm={12} xl={this.state.grid} xs={12} style={{ padding: '2px' }} style={{ minWidth: 600 }}>
                                    <Card>
                                        <CardContent>
                                            <img src={LogoMarca} style={{ width: "70px" }} /> <span style={{ marginLeft: 25, fontSize: 18 }}> {<FormattedMessage id="material.report.header" />}</span>
                                            <Divider />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>}
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>{intl.formatMessage({id:"filters"})}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container style={{ marginBottom: 20 }} spacing={2}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <SimpleDatePicker label={intl.formatMessage({ id: "start.date" })} name='dateStart' stateName='dateStart' changeDate={this.changeValues} currentDate={this.state.dateStart} required />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3}>
                                        <SimpleDatePicker label={intl.formatMessage({ id: "end.date" })} name='dateEnd' stateName='dateEnd' changeDate={this.changeValues} currentDate={this.state.dateEnd} required />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3} >
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "organization" })} options={this.state.organizationList} stateName='OrganizationFilter' changeSelect={this.changeValues} />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3}>
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "location" })} options={this.state.organizationAddressesList} stateName='location' changeSelect={this.changeValues} />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3}>
                                        <SimpleAutoComplete label={intl.formatMessage({id:"fcr.report.calleds.solved.first.contact"})} options={this.fcrOptions} stateName='inFCR' changeSelect={this.changeValues} />
                                    </Grid>

                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Grid container style={{ marginBottom: 10 }}>
                            <Grid item xs={12} sm={2} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                                <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    color='secondary'
                                >
                                    {<FormattedMessage id="filter" />}
                                </Button>
                            </Grid>
                            {this.state.showGrid && <Grid item xs={6} sm={5} md={2} style={{ marginTop: 10, marginRight: 10 }}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    onClick={this.getExcel}
                                >
                                    {<FormattedMessage id="export.plan" />}
                                </Button>
                            </Grid>}

                            {this.state.showGrid && <Grid item xs={12} sm={5} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='secondary'
                                    onClick={this.exportPdf}

                                >
                                    {<FormattedMessage id="dash.pdf" />}
                                </Button>
                            </Grid>}

                            {this.state.showGrid &&
                                <Grid item xs={12} sm={5} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                                    <Button
                                        fullWidth
                                        variant='contained'
                                        color='secondary'
                                        onClick={this.openOrCloseGraphic}
                                    >
                                        {<FormattedMessage id="display.chart" />}
                                    </Button>
                                </Grid>}
                        </Grid>
                    </form>

                    {this.state.showGrid &&
                        <>
                            <MyMaterialTable
                                title=""
                                columns={[{ title: intl.formatMessage({id:"id"}), field: 'id' },
                                { title: intl.formatMessage({ id: "category" }), field: 'OrganizationCategory' }, { title: intl.formatMessage({ id: "called.Accom" }), field: 'annotation' }
                                    , { title: intl.formatMessage({ id: "last.move.date" }), field: 'lastAction' }, { title: intl.formatMessage({ id: "open.date" }), field: 'openingdate' }, { title: intl.formatMessage({ id: "title" }), field: 'title' }, { title: intl.formatMessage({ id: "assigned" }), field: 'assignedUser' }, { title: intl.formatMessage({id:"menu.statuses"}), field: 'statusName' }, { title: intl.formatMessage({ id: "organization" }), field: 'Organization' }, { title: intl.formatMessage({ id: "departament" }), field: 'userdepartament' }, { title: intl.formatMessage({ id: "menu.techinicalteams" }), field: 'tecnteams' },
                                { title: intl.formatMessage({ id: "location" }), field: 'location' }, { title: intl.formatMessage({ id: "sla.service" }), field: 'slaAtendimento' }, { title: intl.formatMessage({ id: "sla.solution" }), field: 'slaSolucao' }, { title: intl.formatMessage({ id: "wasbaduse" }), field: 'baduse' }]}
                                data={this.state.calledsList}

                            />
                        </>
                    }

                    {this.state.openOrCloseGraphic &&
                        <div>
                            <Dialog
                            open={this.state.openOrCloseGraphic}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            
                            fullWidth 
                            >
                            <DialogTitle id="alert-dialog-title"><span> First Call Resolution (FCR) </span></DialogTitle>
                            <Grid container >

                                <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <PieChart width={600} height={500} >
                                            <Pie
                                                activeIndex={this.state.activeIndex}
                                                activeShape={this.renderActiveShape}
                                                data={this.state.arrayCalledList}
                                                innerRadius={100}
                                                outerRadius={135}
                                                fill="#8884d8"
                                                onMouseEnter={this.onActiveIndexEnter}
                                            >
                                                {this.state.arrayCalledList.map((entry, index) =>
                                                    <Cell key={index} fill={this.COLORS[index]} />
                                                )}
                                            </Pie>
                                    </PieChart>
                                </Grid>
                                <Grid item xs={12}>
                                    {this.state.arrayCalledListCards.map((entry, index) =>
                                        <Grid item xs={12}  >
                                            <Budget titleBudget={entry.name} total={(entry.value)} color={this.COLORS[index]} />
                                        </Grid>
                                    )}

                                </Grid>
                            </Grid>
                            <DialogActions>
                                
                            <Button onClick={this.openOrCloseGraphic} color="secondary">
                                            {intl.formatMessage({id:"close"})}
                                        </Button>
                            </DialogActions>
                            </Dialog>
                        
                      </div>
                    }

                </div>
            );
        }

        return (
            <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    }, classes.actionsContent, {
                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft:0, marginLeft:0}}>
                        <CssBaseline />
                        <Grid container className={classes.mb20} spacing={0}>
                        </Grid>
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={12}>
                                <Card >
                                    <CardContent className="background-title-search">
                                        <Typography variant="h5">
                                            {intl.formatMessage({id:"fcr.report.reports"})}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                        </Grid>
                        <Can I="List" a="Called">
                            {tableList()}
                        </Can>
                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color="secondary" />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
                <Footer />
            </div>
        );
    }
}

MaterialReports.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
    actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(calledsActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(MaterialReports)));
MaterialReports.contextType = AbilityContext;