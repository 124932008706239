import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Grid, TextField } from '@mui/material';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Api from '../../../services/api';
import * as calledsActions from '../../../redux/actions/calleds';
import SimpleAutoComplete from '../../../components/auto-complete/autocomplete';
import { Styles } from "../../../styles/material-styles";
import { Overlay } from '../../../styles/global';
import CustomizedSnackbars from '../../../components/material-snackbars';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

const SLAOverflowStep = props => {

  const intl = props.intl
  const classes = props.classes
  const called = props.called
  let [openNotification, setOpenNotification] = useState(false)
  let [notificationVariant, setNotificationVariant] = useState("error")
  let [notificationMessage, setNotificationMessage] = useState("")
  let [loading, setLoading] = useState(false)
  let [reasonsList, setReasonsList] = useState([])
  let [calledReasonId, setCalledReasonId] = useState(null)
  let [reasonOther, setReasonOther] = useState("")
  let [reasonOtherIsVisible, setReasonOtherIsVisible] = useState(false)
  const [updateRadio, setUpdateRadio] = useState(true)

  const getReasons = async _ => {
    setLoading(true)
    await Api.get(`/reasons`)
      .then(result => {
        if (result && result.data && result.data.data) {
          setReasonsList(result.data.data)
          setLoading(false)
        } else
          setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        setOpenNotification(true)
        setNotificationVariant("error")
        setNotificationMessage(err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
        Api.kickoff(err);
      });
  }

  const saveCalledReason = async e => {
    e.preventDefault()
    const { userSession } = props

    setLoading(true)

    if(calledReasonId == null) {
      setLoading(false)
      setOpenNotification(true)
      setNotificationVariant("error")
      setNotificationMessage(intl.formatMessage({id:"called.accompaniment.select.reason"}))
      return
    }

    if (calledReasonId.id == 4) {
      if (reasonOther.length == 0) {
        setLoading(false)
        setOpenNotification(true)
        setNotificationVariant("error")
        setNotificationMessage(intl.formatMessage({ id: "other.select" }))
        return
      }
    }

    let data = new FormData();
    data.append('calledid', called.id)
    data.append('reasonid', calledReasonId.id)
    data.append('description', reasonOther)
    data.append('createduser', userSession.user.id)

    let config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };

    await Api.post("/calledreasons", data, config)
      .then(result => {
        if (result.data.success) {
          setLoading(false)
          //setOpenNotification(true)
          //setNotificationVariant("success")
          //setNotificationMessage("Motivo salvo com sucesso.")
          props.setFinishStep(1)
          props.setMessage("success", intl.formatMessage({id:"called.accompaniment.reason.saved"}), true)
          props.setNavValue("action")
          props.nextStep()
        }
      }).catch(err => {
        setLoading(false)
        setOpenNotification(true)
        setNotificationVariant("error")
        setNotificationMessage(err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      })
  }

  const closeNotification = _ => {
    setOpenNotification(false)
  }

  useEffect(() => {
    getReasons()
  }, [])

  return (
    <form name='CalledReasonForm' onSubmit={saveCalledReason} encType='multipart/form-data' className={classes.form} >
      <Grid container spacing={2} style={{ marginTop: 5 }}>
        <Grid item xs={12} sm={12}>
          <FormattedMessage id="sla.overflow" />
        </Grid>
        <Grid item xs={12} sm={12}>
          <SimpleAutoComplete
            label={intl.formatMessage({ id: "reason" })}
            options={reasonsList}
            stateName='calledReasonId'
            changeSelect={(stateName, value) => {
              setCalledReasonId(value)
              if(value && value.name && (value.name.toLowerCase() == intl.formatMessage({id: "other"})))
                setReasonOtherIsVisible(true)
              else
                setReasonOtherIsVisible(false)
            }}
            selected={calledReasonId} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            autoComplete="fname"
            id="outlined-multiline-static"
            label={intl.formatMessage({ id: "others" })}
            name="reasonOther"
            multiline
            rows="8"
            fullWidth
            value={reasonOther}
            variant="outlined"
            onChange={(e) => setReasonOther(e.target.value)} />
        </Grid>
        <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid item xs={6} sm={6} onClick={_ => setUpdateRadio(!updateRadio)}>
              {props.actionRadio()}
          </Grid>
          <Grid container item xs={6} sm={6} justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
            <Grid item xs={6} sm={6}>
              <Button
                variant='contained'
                type="button"
                onClick={props.closeModal}
                fullWidth >
                {intl.formatMessage({id:"cancel"})}
              </Button>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Button
                type='button'
                variant='contained'
                color='primary'
                onClick={saveCalledReason}
                fullWidth >
                {intl.formatMessage({id:"next"})}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <CustomizedSnackbars
        variant={notificationVariant}
        message={notificationMessage}
        isOpen={openNotification}
        toClose={closeNotification} />

      {loading && (
        <Overlay>
          <CircularProgress color="secondary" />
        </Overlay>
      )}
    </form>
  )
}

SLAOverflowStep.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userSession: state.userSession,
  calleds: state.calleds,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(calledsActions, dispatch);

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(SLAOverflowStep))));