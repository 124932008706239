import React, { useState, useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';

const { forwardRef, useImperativeHandle } = React;

const getButtonClass = (icon, enabled) => classnames(`btn-action fa ${icon}`, { disable: !enabled });
/*
function clearphoto() {
  var context = canvas.getContext('2d');
  context.fillStyle = "#AAA";
  context.fillRect(0, 0, canvas.width, canvas.height);

  var data = canvas.toDataURL('image/png');
  photo.setAttribute('src', data);
}
function takepicture() {
  var context = canvas.getContext('2d');
  if (width && height) {
    canvas.width = width;
    canvas.height = height;
    context.drawImage(video, 0, 0, width, height);
  
    var data = canvas.toDataURL('image/png');
    photo.setAttribute('src', data);
  } else {
    clearphoto();
  }
}

function gotMedia(mediaStream) {
  // Extract video track.
  videoDevice = mediaStream.getVideoTracks()[0];
  // Check if this device supports a picture mode...
  let captureDevice = new ImageCapture(videoDevice);
  if (captureDevice) {
    captureDevice.takePhoto().then(processPhoto).catch(stopCamera);
    captureDevice.grabFrame().then(processFrame).catch(stopCamera);
  }
}
 
function processPhoto(blob) {
  photo.src = window.URL.createObjectURL(blob);
}
 
function processFrame(imageBitmap) {
  canvas.width = imageBitmap.width;
  canvas.height = imageBitmap.height;
  canvas.getContext('2d').drawImage(imageBitmap, 0, 0);
}
 
function stopCamera(error) {
  console.error(error);
  if (videoDevice) videoDevice.stop();  // turn off the camera
}
 
photo.addEventListener('load', function () {
  // After the image loads, discard the image object to release the memory
  window.URL.revokeObjectURL(this.src);
});
*/
var imageCapture = null;

//function CallWindow({ peerSrc, localSrc, config, mediaDevice, status, endCall,showEditor }) {
const CallWindow = forwardRef((props, ref) => {
  const { peerSrc, localSrc, config, mediaDevice, status, endCall,showEditor } = props;
  const peerVideo = useRef(null);
  const localVideo = useRef(null);
  const [video, setVideo] = useState(config.video);
  const [audio, setAudio] = useState(config.audio);
  const intl = this.props.intl;

  useEffect(() => {
    if (peerVideo.current && peerSrc) peerVideo.current.srcObject = peerSrc;
    if (localVideo.current && localSrc) localVideo.current.srcObject = localSrc;

    if (peerVideo.current && peerSrc) {
      const track = peerSrc.getVideoTracks()[0];
      imageCapture = new ImageCapture(track);    
    }
    else{ 
      if (localVideo.current && localSrc) {
        const track = localSrc.getVideoTracks()[0];
        imageCapture = new ImageCapture(track);    
      }
    }
  });

  useEffect(() => {
    if (mediaDevice) {
      mediaDevice.toggle('Video', video);
      mediaDevice.toggle('Audio', audio);
    }
  });
  const openEditor  = (show, image = '') => {
    if (typeof props.showEditor === 'function') {
        props.showEditor(show, image);
    }
  }
  //const openEditor = (show, image = null) => {
  //  console.log(image);
  //  return () => showEditor(show, image);
  //};



  /**
   * Turn on/off a media device
   * @param {String} deviceType - Type of the device eg: Video, Audio
   */
  const toggleMediaDevice = (deviceType) => {
    if (deviceType === 'video') {
      setVideo(!video);
      mediaDevice.toggle('Video');
    }
    if (deviceType === 'audio') {
      setAudio(!audio);
      mediaDevice.toggle('Audio');
    }
  };

  const captureImage = () => {
    //var rightVideo = document.getElementById('localVideo');
    //var stream = rightVideo.captureStream();
    //console.log(stream);    
      if(imageCapture == null){
        alert(intl.formatMessage({id:"callwindow.camera.error"}));
        return false;
      }
      
      if (imageCapture) {

          imageCapture.takePhoto()
          .then(blob => createImageBitmap(blob))
          .then( async (bitmap)  =>  {
            //const canvas = document.querySelector('#takePhotoCanvas');
            //canvas.width = bitmap.width; 
            //canvas.height = bitmap.height;
            //await  drawCanvas(canvas, bitmap);
            //var dataURL = await canvas.toDataURL();
            //console.log(canvas);
            //openEditor(true,dataURL);

            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d');
            canvas.width = bitmap.width; 
            canvas.height = bitmap.height;

            context.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height);
            return canvas.toDataURL();


          }).then((src) => {
            // do something with the source
            //console.log(src)
            openEditor(true,src);
        }).catch(error => {
            
      
          // console.log('error: '+error);
          imageCapture.grabFrame().then((bitmap) => {
              // console.log(bitmap);
              let canvas = document.createElement('canvas');
              let context = canvas.getContext('2d');
              canvas.width = bitmap.width; 
              canvas.height = bitmap.height;
  
              context.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height);
              return canvas.toDataURL();
          }).then((src) => {
              // do something with the source
              //console.log(src)
              openEditor(true,src);
          }).catch(console.error);
  

        });  
          
        /*
          (async () =>{
          const stream = await navigator.mediaDevices.getUserMedia({video:true, audio:false});
          if(!stream.getVideoTracks().length) throw new Error('no webcam');
          const capture = new ImageCapture(stream.getVideoTracks()[0]);
          const photoBlob = await capture.takePhoto();
        })()
       */



      }

  };

  async function drawCanvas(canvas, img) {
    canvas.width = getComputedStyle(canvas).width.split('px')[0];
    canvas.height = getComputedStyle(canvas).height.split('px')[0];
    let ratio  = Math.min(canvas.width / img.width, canvas.height / img.height);
    let x = (canvas.width - img.width * ratio) / 2;
    let y = (canvas.height - img.height * ratio) / 2;
    canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
    canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height,
        x, y, img.width * ratio, img.height * ratio);
    return canvas;
  }
  
  return (
    <div className={classnames('call-window', status)}>
      <video id="peerVideo" ref={peerVideo} autoPlay />
      <video id="localVideo" ref={localVideo} autoPlay muted />
      <canvas id="takePhotoCanvas" style={{ visibility:'hidden'}}></canvas>
      <div className="video-control">
        <button
          type="button"  title={intl.formatMessage({id:"callwindow.camera.take.photo"})}
          className={getButtonClass('fa-picture-o', true)}
          onClick={() => captureImage(true)}
        />
        <button
          key="btnVideo"
          type="button" title={intl.formatMessage({id:"callwindow.camera.enable.disable.video"})}
          className={getButtonClass('fa-video-camera', video)}
          onClick={() => toggleMediaDevice('video')}
        />
        <button
          key="btnAudio"
          type="button"  title={intl.formatMessage({id:"callwindow.camera.enable.disable.audio"})}
          className={getButtonClass('fa-microphone', audio)}
          onClick={() => toggleMediaDevice('audio')}
        />
        <button
          type="button" title={intl.formatMessage({id:"callwindow.camera.end.call"})}
          className="btn-action hangup fa fa-phone"
          onClick={() => endCall(true)}
        />
      </div>
    </div>
  );
});

CallWindow.propTypes = {
  status: PropTypes.string.isRequired,
  localSrc: PropTypes.object, // eslint-disable-line
  peerSrc: PropTypes.object, // eslint-disable-line
  config: PropTypes.shape({
    audio: PropTypes.bool.isRequired,
    video: PropTypes.bool.isRequired
  }).isRequired,
  mediaDevice: PropTypes.object, // eslint-disable-line
  endCall: PropTypes.func.isRequired,
  showEditor: PropTypes.func.isRequired,
};

export default CallWindow;
