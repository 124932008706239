import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress'
import ClearIcon from '@mui/icons-material/Clear'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, FormGroup, FormControlLabel, Grid, IconButton, Switch, TextField, Typography, DialogContentText } from '@mui/material'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ImageList from '@mui/material/ImageList'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit';
import * as componentActions from '../../../../redux/actions/calledservicesettingscalledfields'
import Api from '../../../../services/api'
import { editItem } from '../../../../services/calledservicesettingscalledfield.service'
import { Styles } from '../../../../styles/material-styles'
import { Overlay } from '../../../../styles/global';
import CustomizedSnackbars from '../../../../components/material-snackbars'
import { PapiroConsole } from '../../../../utils/papiroConsole'
import SimpleAutoComplete from '../../../../components/auto-complete/autocomplete'
import Tooltip from '@mui/material/Tooltip'
import HelpIcon from '@mui/icons-material/Help'
import { DeleteForever, AttachFile } from '@mui/icons-material'
import Dropzone from 'react-dropzone'

function UpdateCalledServiceSettingsCalledFieldItemDialog(props) {

  const { classes, intl, userId, hirerId } = props

  const [open, setOpen] = useState(props.open)
  const [loading, setLoading] = useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const [notificationVariant, setNotificationVariant] = useState('error')
  const [notificationMessage, setNotificationMessage] = useState('')

  const [id, setId] = useState(0)
  const [label, setLabel] = useState('')
  const [order, setOrder] = useState(null)
  const [fieldType, setFieldType] = useState(null)
  const [isRequired, setIsRequired] = useState(false)
  const [disableIsRequired, setDisableIsRequired] = useState(false)
  const [requiredFieldMessage, setRequiredFieldMessage] = useState('')

  const [orderList, setOrderList] = useState([])
  const [fieldTypeList, setFieldTypeList] = useState([])

  useEffect(() => {
    PapiroConsole.log("=== props.calledservicesettingscalledfields ===")
    PapiroConsole.log(props.calledservicesettingscalledfields)
    if (props.calledservicesettingscalledfields && props.calledservicesettingscalledfields.calledservicesettingscalledfield != null) {
      setId(props.calledservicesettingscalledfields.calledservicesettingscalledfield.id)
      setLabel(props.calledservicesettingscalledfields.calledservicesettingscalledfield.label)
      setOrder({ id: props.calledservicesettingscalledfields.calledservicesettingscalledfield.order, name: `${props.calledservicesettingscalledfields.calledservicesettingscalledfield.order}` })

      let fieldTypeName = { id: 0, name: '' }
          switch(props.calledservicesettingscalledfields.calledservicesettingscalledfield.fieldtype) {
            case 1:
              fieldTypeName = { id: 1, name: 'Título do chamado' }
              break
            case 2:
              fieldTypeName = { id: 2, name: 'Descrição do chamado' }
              break
            case 3:
              fieldTypeName = { id: 3, name: 'Categoria do chamado' }
              break
            case 4:
              fieldTypeName = { id: 4, name: 'Nome do usuário' }
              break
            case 5:
              fieldTypeName = { id: 5, name: 'Email do usuário' }
              break
            case 6:
              fieldTypeName = { id: 6, name: 'Telefone do usuário' }
              break
            case 7:
              fieldTypeName = { id: 7, name: 'Documento do usuário' }
              break
            default:
              fieldTypeName = { id: 0, name: '' }
              break
          }
      setFieldType(fieldTypeName)
      setIsRequired(props.calledservicesettingscalledfields.calledservicesettingscalledfield.isrequired)
      setRequiredFieldMessage(props.calledservicesettingscalledfields.calledservicesettingscalledfield.requiredfieldmessage)
    }
  }, [props.calledservicesettingscalledfields])

  useEffect(() => {
    PapiroConsole.log("=== props.open ===")
    PapiroConsole.log(props.open)
    setOpen(props.open)
    props.handleOpen(props.open)
  }, [props.open])

  useEffect(() => {
    PapiroConsole.log("=== props.fieldTypeList ===")
    if (props.fieldTypeList != null) {
      let fTypeList = props.fieldTypeList
      if (fieldType != null && fieldType.id != null && fieldType.id > 0 && props.calledservicesettingscalledfields != null && props.calledservicesettingscalledfields.calledservicesettingscalledfield != null && fieldType.id == props.calledservicesettingscalledfields.calledservicesettingscalledfield.fieldtype) {
        fTypeList.push(fieldType)
        fTypeList = fTypeList.sort((a, b) => a.id - b.id)
      }
      setFieldTypeList(fTypeList)
    } else
      setFieldTypeList(props.fieldTypeList)
  }, [props.fieldTypeList, fieldType])

  useEffect(() => {
    if (props.itemsCount > 0) {
      let orderItemsList = []

      let totalOrderItems = props.itemsCount

      if (order && !isNaN(order))
        setOrder({ id: order, name: `${order}` })
      else
        setOrder({ id: totalOrderItems, name: `${totalOrderItems}` })
      
      for (let orderItem = 1; orderItem <= totalOrderItems; orderItem++) {
        orderItemsList.push({ id: orderItem, name: `${orderItem}` })
      }

      setOrderList(orderItemsList)
    } else {
      setOrderList([ { id: 1, name: '1' } ])
      setOrder({ id: 1, name: '1' })
    }
  }, [props.itemsCount])

  const showMessageTypeName = (messageType) => {
    switch (messageType) {
      case 1:
        return 'Sucesso'
      case 2:
        return 'Informação'
      case 3:
        return 'Aviso'
      case 4:
        return 'Erro'
      default:
        return 'N/A'
    }
  }

  const close = () => {
    setOpen(false)
    props.handleOpen(false)
    setId(0)
    setFieldTypeList([])
    props.addCalledServiceSettingsCalledField(null)
  }

  const setMessage = (open, variant, message) => {
    setOpenNotification(open)
    setNotificationVariant(variant)
    setNotificationMessage(message)
  }

  const closeNotification = _ => {
    setOpenNotification(false)
    setNotificationVariant('error')
    setNotificationMessage('')
  }

  const classes2 = () => {
    return withStyles({
      ImageList: {
        width: "100%",
        height: 100,
      }
    });
  }

  const changeValues = (stateName, value) => {
    if (stateName === 'order')
      setOrder(value)
    if (stateName === 'fieldType') {
      if (value != null && value.id != null && value.id > 0) {
        if (value.id == 1 || value.id == 2 || value.id == 3 || value.id == 4 || value.id == 5) {
          setIsRequired(true)
          setDisableIsRequired(true)
        } else
          setDisableIsRequired(false)
      } else
        setDisableIsRequired(false)
      setFieldType(value)
    }
  }

  const validateForm = _ => {
    if (label == null || (label != null && label.length === 0)) {
      setLoading(false)
      setMessage(true, 'error', "O campo 'Nome' é obrigatório")
      return false
    } else if (order == null || (order != null && order === 0)) {
      setLoading(false)
      setMessage(true, 'error', "O campo 'Ordem' é obrigatório")
      return false
    } else if (fieldType == null || (fieldType != null && fieldType.id == null) || (fieldType != null && fieldType.id != null && fieldType.id == 0)) {
      setLoading(false)
      setMessage(true, 'error', "O campo 'Tipo do campo' é obrigatório")
    }

    return true
  }

  const updateItem = async _ => {
    setLoading(true)

    let formIsValid = validateForm()
    if (formIsValid) {
      var data = new FormData()

      data.append("Id", id)
      data.append("Label", label)
      data.append("Order", order.id)
      data.append("IsRequired", isRequired)
      data.append("RequiredFieldMessage", requiredFieldMessage)
      data.append("FieldType", fieldType.id)
      data.append("CalledServiceSettingsId", props.calledServiceSettingsId)
      data.append("UpdatedUser", userId)
      data.append("UpdatedAt", moment().format('YYYY/MM/DD HH:mm:ss'))

      let response = await editItem(data)
      if (response && response.success) {
        setLoading(false)
        setMessage(true, 'success', intl.formatMessage({ id: "edit.success" }))
        if (response.data != null) {
          PapiroConsole.log("=== updateItem ===")
          PapiroConsole.log(props.calledservicesettingscalledfields)
          let oldItemsList = props.calledservicesettingscalledfields && props.calledservicesettingscalledfields.calledservicesettingscalledfieldslist && props.calledservicesettingscalledfields.calledservicesettingscalledfieldslist.length > 0 ? [...props.calledservicesettingscalledfields.calledservicesettingscalledfieldslist] : []
          oldItemsList = oldItemsList.filter(item => item.id != response.data.id)
          PapiroConsole.log("=== oldItemsList ===")
          PapiroConsole.log(oldItemsList)
          if (props.calledservicesettingscalledfields.calledservicesettingscalledfield && props.calledservicesettingscalledfields.calledservicesettingscalledfield.order != null && props.calledservicesettingscalledfields.calledservicesettingscalledfield.order != order.id) {
            let orderFilter = [...oldItemsList]
            PapiroConsole.log("=== orderFilter ===")
            PapiroConsole.log(orderFilter)
            if (orderFilter && orderFilter.length > 0) {
              let oldOrder = props.calledservicesettingscalledfields.calledservicesettingscalledfield && props.calledservicesettingscalledfields.calledservicesettingscalledfield.order
              let newOrder = order.id
              let lastOrder = props.calledservicesettingscalledfields && props.calledservicesettingscalledfields.calledservicesettingscalledfieldslist && props.calledservicesettingscalledfields.calledservicesettingscalledfieldslist.length > 0 ? props.calledservicesettingscalledfields.calledservicesettingscalledfieldslist.length : 1
              PapiroConsole.log(`=== oldOrder -> ${oldOrder} ===`)
              PapiroConsole.log(`=== newOrder -> ${newOrder} ===`)
              PapiroConsole.log(`=== lastOrder -> ${lastOrder} ===`)
              let newOrderFilterList = orderFilter.map(item => {
                let orderData = item.order

                if (oldOrder == lastOrder && item.order >= newOrder)
                  orderData = item.order + 1
                else if (oldOrder == 1 && item.order <= newOrder)
                  orderData = item.order - 1
                else
                {
                  if (newOrder - oldOrder > 0)
                  {
                    if (item.order > oldOrder && item.order <= newOrder)
                      orderData = item.order - 1
                  }
                  else
                  {
                    if (item.order < newOrder)
                      orderData = item.order + 1
                  }
                }
                
                return {
                  ...item,
                  order: orderData
                }
              })
              let newOrderFilterListIds = orderFilter.map(item => item.id)
              PapiroConsole.log("=== newOrderFilterListIds ===")
              PapiroConsole.log(newOrderFilterList)

              let notOrderFilterList = oldItemsList.filter(item => !newOrderFilterListIds.includes(item.id))
              PapiroConsole.log("=== notOrderFilterList ===")
              PapiroConsole.log(notOrderFilterList)
              oldItemsList = [...notOrderFilterList, ...newOrderFilterList]
              PapiroConsole.log("=== oldItemsList ===")
              PapiroConsole.log(oldItemsList)

            }
          }
          oldItemsList = oldItemsList.filter(item => item.id != response.data.id)

          let formattedResponse = response.data
          let fieldTypeName = ''
          switch(formattedResponse.fieldtype) {
            case 1:
              fieldTypeName = 'Título do chamado'
              break
            case 2:
              fieldTypeName = 'Descrição do chamado'
              break
            case 3:
              fieldTypeName = 'Categoria do chamado'
              break
            case 4:
              fieldTypeName = 'Nome do usuário'
              break
            case 5:
              fieldTypeName = 'Email do usuário'
              break
            case 6:
              fieldTypeName = 'Telefone do usuário'
              break
            case 7:
              fieldTypeName = 'Documento do usuário'
              break
            default:
              fieldTypeName = ''
              break
          }
          formattedResponse.fieldTypeName = fieldTypeName

          let itemList = [...oldItemsList, formattedResponse]
          itemList = itemList.sort((a, b) => a.id - b.id)
          PapiroConsole.log("=== itemList ===")
          PapiroConsole.log(itemList)

          props.addCalledServiceSettingsCalledFieldsList(itemList)
        }
        close()
      } else {
        setLoading(false)
        setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
      Api.kickoff(response)
      }
    }
  }

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="lg"
        onClose={close}
        scroll="body"
        aria-labelledby="form-edit-called-service-setting-called-field-dialog-title" >
        <DialogTitle id="form-edit-called-service-setting-called-field-dialog-title">
          { intl.formatMessage({ id: "edit.field" }) }
          <ClearIcon onClick={close}  style={{ float: 'right', cursor : 'pointer'}}/> 
        </DialogTitle>
        <DialogContent dividers={true}>
          { id > 0 ? (<Grid container spacing={2} style={{ padding: 10 }} >
            <Grid item xs={11} sm={11} md={11} lg={8} >
              <TextField
                name="label"
                variant="outlined"
                fullWidth
                id="label"
                label={intl.formatMessage({ id: "name" })}
                value={label}
                required
                onChange={(e) => setLabel(e.target.value)} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>Nome do campo no formulário</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={4} >
              <SimpleAutoComplete
                label={`${intl.formatMessage({ id: "item.order" })}*`}
                options={orderList}
                stateName='order'
                changeSelect={(stateName, value) => setOrder(value)}
                selected={order} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={8} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>Ordem de exibição do campo</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={4} >
              <SimpleAutoComplete
                label="Tipo do campo*"
                options={fieldTypeList}
                stateName='fieldType'
                changeSelect={changeValues}
                selected={fieldType} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={8} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>Tipo do do campo</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={10} sm={10} md={11} lg={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={isRequired}
                      onChange={(e) => setIsRequired(e.target.checked)}
                      name="isRequired" />
                  }
                  label="Campo obrigatório?*" />
              </FormGroup>
            </Grid>
            <Grid item xs={2} sm={2} md={1} lg={6} />
          </Grid>) :
          (
            <Grid container spacing={2} style={{ padding: 10 }} >
              <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }} >
                <CircularProgress color='secondary' />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            type="button"
            onClick={close} >
            {intl.formatMessage({id:"cancel"})}
          </Button>
          {id > 0 && <Button 
            type='submit'
            variant='contained'
            color='primary'
            onClick={updateItem} >
            {intl.formatMessage({id:"save"})}
          </Button>}
        </DialogActions>
      </Dialog>

      {loading && (
        <Overlay>
          <CircularProgress color='secondary' />
        </Overlay>
      )}

      <CustomizedSnackbars
        variant={notificationVariant}
        message={notificationMessage}
        isOpen={openNotification}
        toClose={closeNotification} />
    </>
  )

}

UpdateCalledServiceSettingsCalledFieldItemDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  itemsCount: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  hirerId: PropTypes.number.isRequired,
  calledServiceSettingsId: PropTypes.number.isRequired,
  organizationId: PropTypes.number.isRequired,
  itemData: PropTypes.object,
  fieldTypeList: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  calledservicesettingscalledfields: state.calledservicesettingscalledfields
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)


export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(UpdateCalledServiceSettingsCalledFieldItemDialog)))