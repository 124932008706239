import React, { Component } from 'react';
                import clsx from 'clsx';
                import { connect } from 'react-redux';
                import { bindActionCreators } from 'redux';
                import PropTypes from 'prop-types';
                import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
                import { withStyles } from '@mui/styles';
                import { Styles } from '../../styles/material-styles';
                import { Overlay } from '../../styles/global';
                import CustomizedSnackbars from '../../components/material-snackbars';
                import HeaderMenu from '../../components/header-menu';
                import AddIcon from '@mui/icons-material/Add';
                import { Link } from 'react-router-dom';
                import Api from '../../services/api';
                import MyMaterialTable from '../../components/table';
                import { Can } from '../../config/ability-context'
                import { AbilityContext } from '../../config/ability-context'
                import { FormattedMessage,injectIntl } from 'react-intl';
                import Card from '@mui/material/Card';
                import CardContent from '@mui/material/CardContent';
                import Typography from '@mui/material/Typography';
                import SimpleSelect from '../../components/select/simple-search';
                import Pagination from '@mui/material/Pagination';  
                import Search from '@mui/icons-material/Search';
                import TextField from '@mui/material/TextField';
                import ClearIcon from '@mui/icons-material/Clear';
                import FirstElement from "../../components/first-element";
                import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
                import { withRouter } from 'react-router-dom';
                import SimpleAutoComplete from '../../components/auto-complete/autocomplete';

                
                    import * as componentActions from '../../redux/actions/organizationslas';
                    class OrganizationSlasContent extends Component {

                    constructor(props) {
                        super(props);

                        this.typingTimeout = null;
                        this.emptyArray = null;

                        let fromProps = ""
                        if(this.props && this.props.from && this.props.from.length > 0 && this.props.from == 'orgEdit')
                            fromProps = this.props.from


                        const intl = this.props.intl;

                        let filters = [
                            {name:intl.formatMessage({id:"name"}),id:"name"},
                            {name:intl.formatMessage({id:"comment"}),id:"comment"},
                            {name:intl.formatMessage({id:"menu.workhours"}),id:"work"},
                            {name:intl.formatMessage({id:"menu.distributionstrategies"}),id:"dist"},
                            {name:intl.formatMessage({id:"organization.category.service.sla"}),id:"service"},
                            {name:intl.formatMessage({id:"sla.solution"}),id:"solution"}
                            ];

                        const { userSession, organizations } = this.props;
                        this.state = {
                            organizations: organizations,
                            dataFilter: false,
                            loading: false,
                            openNotification: false,
                            notificationVariant: 'error',
                            notificationMessage: '',
                            resultList: [],
                            organizationList: [],
                            totalPages:0,
                            page:1,
                            pageStart:0,
                            pageEnd:0,
                            count:0,
                            searchValue: '',
                            filterSelected: 0,
                            filters:filters,
                            from: fromProps,
                            OrganizationFilter: 0,
                            OrganizationFilterItem: null
                        };

                        this.callSearch = this.callSearch.bind(this);
                        this.onFieldChange = this.onFieldChange.bind(this);
                        this.changeValues = this.changeValues.bind(this);

                        this.closeNotification = this.closeNotification.bind(this);
                        this.deleteItem = this.deleteItem.bind(this);
                        this.clearFields = this.clearFields.bind(this);
                    }

                    onChangePaginate = (e,page) =>{
                        this.setState({ page:page });
                        localStorage.setItem('organizationSLATablePage', `${page}`)
                        this.getItens(page);
                    }
                
                    setPagination(count,page){
                                            
                                            let totalPage = Math.ceil (count/10);
                                            this.setState({ totalPages:totalPage })
                                            if(count > 0){
                                                if(page == 1){
                                                    if(count < 10){
                                                        this.setState({pageStart:1,pageEnd:count});
                                                    } else{
                                                        this.setState({pageStart:1,pageEnd:10});
                                                    }
                                                } else{
                                                    let pageStart = ((page - 1) * 10) + 1;
                                                    
                                                    
                                                    
                                                    if(count >= page * 10){
                                                        this.setState({pageStart:pageStart,pageEnd:page * 10});
                                                    } else{
                                                        this.setState({pageStart:pageStart,pageEnd:count});
                                                    }
                                                }
                                            } else {
                                                this.setState({totalPages:0,page:1,count:0});
                                            }
                                            
                    }

                    getOrganizations = async _ => { 
                        this.setState({ loading: true })
                        let data = [];
                        const { userSession } = this.props
                        if(userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
                            data = userSession.user.userorganizations
                        } else {
                            await Api.get('/organizations/tech')
                                .then(result => {
                                    data = result.data.data
                                })
                                .catch(err => {
                                    data = err;
                            })
                        }
                        this.setState({ organizationList: data, loading: false })
                    }
                
                    onFieldChange(event) {
    
        
                        this.setState({ [event.target.name]: event.target.value, page:1 });
                
                    }
                
                    callSearch(){
                        if(this.state.filterSelected != 0){
                            localStorage.setItem('organizationSLATableFilter', `${this.state.filterSelected}=${this.state.searchValue}`)
                            this.getItens(this.state.page);
                        }                        
                    }
                
                    async clearFields(){
                        await   this.setState({ OrganizationFilter: 0, OrganizationFilterItem: null, filterSelected: 0, searchValue: '', page:1,loading: true })
                        localStorage.setItem('organizationSLATableFilter', '')
                        localStorage.setItem('organizationSLATableOrganizationFilter', '0')
                        localStorage.setItem('organizationSLATablePage', '1')
                        this.getItens(1);
                    }

                    async changeValues(stateName, value, text = '') {
                        if(stateName === 'OrganizationFilter') {
                            this.setState({ [stateName]: value.id })
                            this.getItens()
                        }else
                            this.setState({filterSelected : value})
                    }


                    deleteItem(data, resolve) {
                        let abl = this.context.can('Delete', 'OrganizationSla');
                        if (abl == false){
                            const intl = this.props.intl;
                            this.setState({loading: false, openNotification: true, notificationVariant: 'error',notificationMessage:intl.formatMessage({id:"operation.notAllowed"})});
                            resolve(); return false;
                        }

                    Api.delete(`/organizationslas/${data.id}`)
    
                            .then(result => {
                                if (result.data.success) {
                                    const intl = this.props.intl;
                                    this.setState({
                                        loading: false,
                                        openNotification: true,
                                        notificationVariant: 'success',
                                        notificationMessage: intl.formatMessage({id:"delete.sucess"}),
                                        resultList: []
                                      });
          
                                    this.getItens();
                                }
                                else{
                                    const intl = this.props.intl;
                                    this.setState({
                                        loading: false, openNotification: true, notificationVariant: 'error',
                                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                                    });
                                    Api.kickoff(result);
                                }
                                resolve();
                            })
                            .catch(err => {
                                resolve();
                                const intl = this.props.intl;
                                this.setState({
                                    loading: false, openNotification: true, notificationVariant: 'error',
                                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                                });
                                Api.kickoff(err);
                            });
    

                    };
    
                    componentWillReceiveProps(nextProps) {
                        if (nextProps.location && nextProps.location.state === "reload") {
                            this.componentDidMount();
                        }
                    }

                    async componentDidMount() {
                        await this.getOrganizations()
                        let sessionFilter = localStorage.getItem('organizationSLATableFilter')
                        if(sessionFilter == null) {
                            sessionFilter = [0, '']
                            localStorage.setItem('organizationSLATableFilter', '')
                        } else {
                            if(sessionFilter && sessionFilter.length > 0) {
                                let sessionFilterSplit = sessionFilter.split("=")
                                if(sessionFilterSplit && sessionFilterSplit.length > 1) {
                                    if(!isNaN(sessionFilterSplit[0]))
                                        sessionFilter = [parseInt(sessionFilterSplit[0]), sessionFilterSplit[1]]
                                    else
                                        sessionFilter = [sessionFilterSplit[0], sessionFilterSplit[1]]
                                }
                            }
                        }
                        if(isNaN(sessionFilter[0])) {
                            let sessionFilterItem = this.state.filters.filter(item => item.id == sessionFilter[0])
                            if(sessionFilterItem && sessionFilterItem.length > 0) {
                                await this.setState({
                                    filterSelected: sessionFilterItem[0]
                                }, () => this.setState({ filterSelected: sessionFilter[0], searchValue: sessionFilter[1] }))
                            }
                        } else {
                            await this.setState({ filterSelected: sessionFilter[0], searchValue: sessionFilter[1] })
                        }

                        let sessionOrgFilter = localStorage.getItem('organizationSLATableOrganizationFilter')
                        if(sessionOrgFilter == null) {
                            sessionOrgFilter = 0
                            localStorage.setItem('organizationSLATableOrganizationFilter', '0')
                        }
                        let orgFilterItem = null
                        if(!isNaN(sessionOrgFilter) && typeof sessionOrgFilter === 'string') {
                            sessionOrgFilter = parseInt(sessionOrgFilter)
                            if(sessionOrgFilter > 0 && this.state.organizationList != null) {
                                orgFilterItem = this.state.organizationList.filter(item => item.id == sessionOrgFilter)
                                if(orgFilterItem && orgFilterItem.length > 0) {
                                    await this.setState({
                                        OrganizationFilterItem: orgFilterItem[0]
                                    })
                                }
                            }
                        }
                        await this.setState({ OrganizationFilter: sessionOrgFilter })

                        let sessionPage = localStorage.getItem('organizationSLATablePage')
                        if(sessionPage == null) {
                            sessionPage = 1
                            localStorage.setItem('organizationSLATablePage', '1')
                        }
                        if(!isNaN(sessionPage) && typeof sessionPage === 'string')
                            sessionPage = parseInt(sessionPage)
                        this.setState({ page: sessionPage })

                        this.getItens(sessionPage);
                    }

                    getItens(page = 1) {
                        this.setState({ loading: true });

                        let url = ''

                        if (this.props.location && this.props.location.pathname === `/${this.props.match.params.lang}/organizationslas`) {
                            url = `/organizationslas/paginate?page=${page}&org=${this.state.OrganizationFilter && this.state.OrganizationFilter > 0 ? this.state.OrganizationFilter : 0}`;

                            if (this.state.searchValue != '' && this.state.filterSelected != 0) {
                                url += `&search=${this.state.searchValue}&field=${this.state.filterSelected}`
                            }
                        } else {
                            url = `/organizationslas/organization/paginate?page=${page}&id=${this.state.organizations.organization.id}`;
                            
                            if (this.state.searchValue != '' && this.state.filterSelected != 0) {
                                url += `&search=${this.state.searchValue}&field=${this.state.filterSelected}`
                            }
                        }

                        
                        Api.get(url)
                                    .then(result => {
                                        if (result.data.success) {
                                            let count = result.data.count;
                                            this.setState({count:count});
                                            this.setPagination(count,page);
                                            if(result.data.data.length === 0)
                                            {
                                                this.emptyArray = true 
                                            }
                                            else
                                            {
                                                this.emptyArray = false
                                            }

                                            const formattedList = result.data.data.map(item => {
                                                return {
                                                    ...item,
                                                     OrganizationId: item.organization ? item.organization.id : 0,
                                                     OrganizationText: item.organization ? item.organization.name : '',
                                                     DistributionStrategyId: item.distributionstrategy ? item.distributionstrategy.id : 0,
                                                     DistributionStrategyText: item.distributionstrategy ? item.distributionstrategy.name : '',
                                                     WorkHourId: item.workhour ? item.workhour.id : 0,
                                                     WorkHourText: item.workhour ? item.workhour.name : '',

                                          }
                                    });
                                    this.setState({ loading: false, resultList: formattedList });
                                }
                                else{
                                    const intl = this.props.intl;
                                    this.setState({
                                        loading: false, openNotification: true, notificationVariant: 'error',
                                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                                    });
                                    Api.kickoff(result);
                                }
                            })
                            .catch(err => {
                                const intl = this.props.intl;
                                this.setState({
                                    loading: false, openNotification: true, notificationVariant: 'error',
                                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
                                });
                                Api.kickoff(err);
            
                            });
                    };


                    closeNotification() {
                        this.setState({ openNotification: false });
                    }
                     render() {
                        const { classes, headerMenu } = this.props;
                        const intl = this.props.intl;

                        if(Api == null) {
                            var l = this.props.match.params.lang;
                            document.location.href=`/${l}/unavailable`
                          }

                        return (
                                    <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft:0, marginLeft:0}}>
                                    {(!this.emptyArray  || this.state.dataFilter) ? (
                                        <div>
                                            <CssBaseline />
                                            <Grid container className={classes.mb20}  spacing={0}>
                                                    <Button
                                                        variant='contained'
                                                        disabled={!this.context.can('Add', 'OrganizationSla')}
                                                        color='secondary'
                                                        size='large'
                                                        style={{marginBottom:'20px'}}
                                                        className={classes.button}
                                                        startIcon={<AddIcon />}
                                                        component={Link} {...{
                                                            to: {
                                                                pathname: `/${this.props.match.params.lang}/organizationsla-registration`,
                                                                state: {
                                                                    internal: this.props.internal,
                                                                    from: this.state.from
                                                            }}
                                                        }} >
                                                        {<FormattedMessage id="add" />}
                                                    </Button>
                                            </Grid>
                                                <Grid container spacing={6}> 
                                                <Grid item xs={12} sm={12}>

                                                <Card className="background-title-search">
                                                <CardContent>
                                                    
                                                    <Grid container spacing={1} alignItems="flex-end">
                                                            <Grid item xs={12} md={4}>
                                                            <Typography variant="h5">
                                                                {<FormattedMessage id="menu.organization.sla" />}
                                                            </Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={3}>
                                                            {(this.props.location && this.props.location.pathname === `/${this.props.match.params.lang}/organizationslas`) && (<SimpleAutoComplete label={intl.formatMessage({id:"organization"})} options={this.state.organizationList} stateName='OrganizationFilter'
                                                            selected={this.state.OrganizationFilterItem}
                                                            changeSelect={(stateName, value, text = '') => {
                                                                    this.setState({
                                                                        OrganizationFilter: value.id,
                                                                        OrganizationFilterItem: value
                                                                    }, () => {
                                                                        if(value && value.id && value.id > 0)
                                                                            localStorage.setItem('organizationSLATableOrganizationFilter', `${value.id}`)
                                                                        else
                                                                            localStorage.setItem('organizationSLATableOrganizationFilter', '0')
                                                                        this.getItens()
                                                                    })
                                                                }}/>)}
                                                        </Grid>
                                                                <Grid item xs={12} md={2}>
                                                                    <SimpleSelect label={intl.formatMessage({id:"field"})} options={this.state.filters} stateName='filter' changeSelect={this.changeValues} selected={this.state.filterSelected}/>
                                                                    </Grid>
                                                                <Grid item >
                                                                    <TextField type="text"
                                                                    name="searchValue"
                                                                    value={this.state.searchValue}
                                                                    placeholder={intl.formatMessage({id:"search"})}
                                                                    onChange={this.onFieldChange}/>

                                                    <Button
                                                            variant='contained'
                                                            color='secondary'
                                                            size='large'
                                                            className={classes.button}
                                                            style={{marginRight:4,marginLeft:8}}
                                                            onClick={this.callSearch}
                                                        >
                                                            <Search />
                                                    </Button>

                                                    <Button
                                                            variant='contained'
                                                            color='primary'
                                                            size='large'
                                                            onClick={this.clearFields}
                                                            className={classes.button}
                                                
                                                        >
                                                            <ClearIcon />
                                                    </Button>
                                                                </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>

                                                </Grid>
                                                                
                                            </Grid>
                                            <Can I='List' a='OrganizationSla'>      
                                            <MyMaterialTable
                                                            title=""
                                                                    rowClick={(event, rowData) => {
                                                                    let abl = this.context.can('Edit', 'OrganizationSla');
                                                                        if (abl == false)
                                                                        {
                                                                            this.setState({ loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: intl.formatMessage({id:"operation.notAllowed"})});
                                                                            return false;
                                                                        }
                                                                        this.props.addCurrentOrganization(rowData, this.props.organizations.organization);
                                                                    this.props.history.push({
                                                                        pathname: `/${this.props.match.params.lang}/organizationsla-edit`,
                                                                        state: {
                                                                            internal: this.props.internal,
                                                                            from: this.state.from
                                                                        }
                                                                    });
                                                                }}
                                                columns={[
                                                        { title: intl.formatMessage({id:"name"}), field: 'name' },
                                                        { title: intl.formatMessage({id:"comment"}), field: 'comments' },
                                                        { title: intl.formatMessage({id:"organization.category.service.sla"}) + " " + intl.formatMessage({id:"in.minutes"}), field: 'servicetime' },
                                                        { title: intl.formatMessage({id:"sla.solution"}) + " " + intl.formatMessage({id:"in.minutes"}), field: 'solutiontime' },
                                                        { title: intl.formatMessage({id:"organization"}), field: 'OrganizationText' },
                                                        { title: intl.formatMessage({id:"menu.workhours"}), field: 'WorkHourText' },
                                                        { title: intl.formatMessage({id:"menu.distributionstrategies"}), field: 'DistributionStrategyText' },


                                                ]}
                                                data={this.state.resultList}
                                                deleteItem={this.deleteItem} 
                                            />
                                            </Can>

                                            <Can I='List' a='OrganizationSla'>
                                                
                                                <Grid container style={{marginBottom:12,marginTop:8}}>
                                                    <Grid item xs={12} md={3}>
                                                    <Pagination  count={this.state.totalPages} page={this.state.page}  onChange={this.onChangePaginate} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={3} style={{marginTop:6}}>
                                                    {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                                                    </Grid>
                                                </Grid>

                                                </Can> 

                                            
                                        </div>)
                                         :(
                                            <div>
                                               <FirstElement
                                               title = "Crie sua primeira sla da organização"
                                               subtitle = "Você poderá criar suas slas das organizações"
                                               //buttonText = "Novo Chamado"
                                               buttonText = 
                                               {
                                                <Button
                                                        variant='contained'
                                                        color='secondary'
                                                        size='large'
                                                        className={classes.button}
                                                        startIcon={<AddIcon />}
                                                component={Link} {...{
                                                    to:{
                                                        pathname: `/${this.props.match.params.lang}/organizationsla-registration`,
                                                        state: {
                                                            internal: this.props.internal,
                                                            from: this.state.from
                                                        }
                                                    }
                                                }} >
                                                        {<FormattedMessage id="add" />}
                                                    </Button>
                                               }
                                            
                                               icon = {<AccountBalanceIcon style={{fontSize: "45px"}} />}
                                               
                                               />
                                            </div>
                                    )}

                                            {this.state.loading && (
                                                <Overlay>
                                                    <CircularProgress color='secondary' />
                                                </Overlay>
                                            )}
                        

                                            <CustomizedSnackbars
                                                variant={this.state.notificationVariant}
                                                message={this.state.notificationMessage}
                                                isOpen={this.state.openNotification}
                                                toClose={this.closeNotification}
                                            />
                                       
                                    </Container>
                        );
                    }
                }
OrganizationSlasContent.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                    organizations: state.organizations
                });
                const mapDispatchToProps = dispatch =>
                    bindActionCreators(componentActions, dispatch);
            export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(OrganizationSlasContent))));
            OrganizationSlasContent.contextType = AbilityContext;
