import { Avatar, Button, CircularProgress, Container, CssBaseline, Grid, TextField, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import CustomizedSnackbars from "../../components/material-snackbars/index.js";
import { AbilityContext } from '../../config/ability-context.js';
import { Overlay } from "../../styles/global.js";
import { Styles } from '../../styles/material-styles.js';
//import {decode as base64_decode, encode as base64_encode} from 'base-64';

var gapi = null;
var google = null;
/* exported gapiLoaded */
/* exported gisLoaded */
/* exported handleAuthClick */
/* exported handleSignoutClick */

// TODO(developer): Set to client ID and API key from the Developer Console
const CLIENT_ID = '457475788861-49q3pp471r06rpv07i9liv73f4tgsnjr.apps.googleusercontent.com';
const API_KEY = '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';

// Discovery doc URL for APIs used by the quickstart
const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest';

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES = 'https://www.googleapis.com/auth/gmail.readonly';

let tokenClient;
let gapiInited = false;
let gisInited = false;


class AuthorizeExample extends Component {

  constructor(props) {
    super(props);

    const { match } = this.props;

    this.itemInitialState = {
    }; 

    this.state = {
      item: this.itemInitialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
    };

    this.closeNotification = this.closeNotification.bind(this);
  }




      /**
       * Callback after the API client is loaded. Loads the
       * discovery doc to initialize the API.
       */
      async initializeGapiClient() {
        if(!gapi){ return }
        alert('initializeGapiClient');
        await gapi.client.init({
          apiKey: API_KEY,
          discoveryDocs: [DISCOVERY_DOC],
        });
        gapiInited = true;
        this.maybeEnableButtons();
      }

      
      /**
       * Enables user interaction after all libraries are loaded.
       */
      maybeEnableButtons() {
        if (gapiInited && gisInited) {
          document.getElementById('authorize_button').style.visibility = 'visible';
        }
      }

      /**
       *  Sign in the user upon button click.
       */
      handleAuthClick() {
        if(!tokenClient){ return }
        tokenClient.callback = async (resp) => {
          if (resp.error !== undefined) {
            throw (resp);
          }
          document.getElementById('signout_button').style.visibility = 'visible';
          document.getElementById('authorize_button').innerText = 'Refresh';
          await this.listLabels();
        };

        if (gapi.client.getToken() === null) {
          // Prompt the user to select a Google Account and ask for consent to share their data
          // when establishing a new session.
          tokenClient.requestAccessToken({prompt: 'consent'});
        } else {
          // Skip display of account chooser and consent dialog for an existing session.
          tokenClient.requestAccessToken({prompt: ''});
        }
      }

      /**
       *  Sign out the user upon button click.
       */
      handleSignoutClick() {
        if(!gapi){ return }
        const token = gapi.client.getToken();
        if (token !== null) {
          google.accounts.oauth2.revoke(token.access_token);
          gapi.client.setToken('');
          document.getElementById('content').innerText = '';
          document.getElementById('authorize_button').innerText = 'Authorize';
          document.getElementById('signout_button').style.visibility = 'hidden';
        }
      }

      /**
       * Print all Labels in the authorized user's inbox. If no labels
       * are found an appropriate message is printed.
       */
       async listLabels() {
        let response;
        try {
          response = await gapi.client.gmail.users.labels.list({
            'userId': 'me',
          });
        } catch (err) {
          document.getElementById('content').innerText = err.message;
          return;
        }
        const labels = response.result.labels;
        if (!labels || labels.length == 0) {
          document.getElementById('content').innerText = 'No labels found.';
          return;
        }
        // Flatten to string to display
        const output = labels.reduce(
            (str, label) => `${str}${label.name}\n`,
            'Labels:\n');
        document.getElementById('content').innerText = output;
      }      

  closeNotification() {
    this.setState({ openNotification: false });
  }
      /**
       * Callback after api.js is loaded.
       */
   gapiLoaded() {
        if(!gapi){ return }
        alert('gapiLoaded');
        gapi.load('client', this.initializeGapiClient);
   }
/**
       * Callback after Google Identity Services are loaded.
       */
  gisLoaded() {
    if(!google){ return }
    alert('gisLoaded -> '+tokenClient);
    tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: '', // defined later
    });
    gisInited = true;
    this.maybeEnableButtons();
  }

  async componentDidMount() {

    const script = document.createElement("script");

    script.src = "https://apis.google.com/js/api.js";
    script.onload=this.gapiLoaded;
    script.async = true;

    const script2 = document.createElement("script");

    script2.src = "https://accounts.google.com/gsi/client";
    script2.onload=this.gisLoaded;
    script2.async = true;

    document.body.appendChild(script);    
    document.body.appendChild(script2);    
    document.getElementById('authorize_button').style.visibility = 'hidden';
    document.getElementById('signout_button').style.visibility = 'hidden';
    alert('componentDidMount');

  }

  
  render() {


    return (
      <div>
         <main>
          <Container component='main' maxWidth='md'>


                <p>Gmail API Quickstart</p>

                <button id="authorize_button" onClick={this.handleAuthClick()}>Authorize</button>
                <button id="signout_button" onClick={this.handleSignoutClick()}>Sign Out</button>

                <pre id="content" style={{whiteSpace: "pre-wrap"}}></pre>



            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            {
              this.state.openNotification && (
                <CustomizedSnackbars
                  variant={this.state.notificationVariant}
                  message={this.state.notificationMessage}
                  isOpen={this.state.openNotification}
                  toClose={this.closeNotification}
                />
              )
            }          
          </Container>
         </main>
      </div>
    )
  }
}

const mapStateToProps = state => ({
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(AuthorizeExample)));

AuthorizeExample.contextType = AbilityContext;
