import axios from 'axios';
import * as userSessionActions from '../redux/actions/user-session';
import { useHistory } from "react-router-dom";
import { PapiroConsole } from '../utils/papiroConsole';

//PapiroConsole.log("dev env")

let saasError = false
try {
  const urlSaaS = process.env.REACT_APP_API+"/hirerconfig/geturl/"+window.location.host+"/1";
  
  var xhttp = new XMLHttpRequest();
  xhttp.open("GET", urlSaaS, false);
  xhttp.send();
} catch(e) {
  if(e.name == "NetworkError") {
    PapiroConsole.log("entrou NetworkError")
    if(e.message.indexOf("Failed to execute 'send' on 'XMLHttpRequest'") !== -1) {
      saasError = true;
      PapiroConsole.log("entrou Failed 'send' 'XMLHttpRequest'")
    }else if(e.message.indexOf("A network error occurred.") !== -1) {
      saasError = true;
      PapiroConsole.log("entrou em A network error occurred.")
    }else {
      PapiroConsole.log("Outro erro")
      PapiroConsole.log(e.name)
      PapiroConsole.log(e.message)
    }
  }
}


let ApiReports = {}


let baseURLApiCubeReports = null


let acceptLanguage = 'pt-BR'
let language = window.location.pathname.split('/')[1]

if (language === 'en')
  acceptLanguage = 'en-US'
else if (language === 'es')
  acceptLanguage = 'es-ES'

if(!saasError) {
  let baseUrlResponse = JSON.parse(xhttp.responseText);

 
  
  if (baseUrlResponse && baseUrlResponse.data && baseUrlResponse.data.urlapicubereports) {
    baseURLApiCubeReports = `https://${baseUrlResponse.data.urlapicubereports}/api`
  }

  let token = localStorage.getItem('accesstokenreports');
  

    ApiReports = axios.create({
      baseURL: baseURLApiCubeReports,
    });

    

    ApiReports.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    ApiReports.defaults.headers.post['Accept'] = 'application/json';
    ApiReports.defaults.headers.post['Content-Type'] = 'application/json';
    ApiReports.defaults.headers.common['Accept-Language'] = acceptLanguage

    ApiReports.defaults.headers.common['Authorization'] = 'Bearer ' + token;

}


export default ApiReports;
