import Api from './api';

export const mergeItem = async (formData) => { 
  let data = []
  await Api.put(`/configs/configpasswordpolicy`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}