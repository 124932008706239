import React from 'react';
import PhoneIcon from '../../images/me-feedback/phone_icon.png';

const MEPhoneIcon = props => (
    <div style={{ display: 'inline-block' }}>
        <img
            src={props.src && props.src.length > 0 ? props.src : PhoneIcon}
            style={props.style != null ? props.style : {  }}
            title="FeedbackPhoneIcon"
            className='App-phone-icon' />
    </div>
);

export default MEPhoneIcon;
