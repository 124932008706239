import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { browserHistory } from '../../helpers/history';
import { Link } from 'react-router-dom';import SimpleSelect from '../../components/select/simple';
import { FormattedMessage,injectIntl } from 'react-intl';
import SimpleCheckBox from '../../components/checkbox/check';
import Title from '../../components/title-name';
import * as Service from '../../services/organizationmaterial.service';
import * as componentActions from '../../redux/actions/organizationmaterials';
import SimpleDatePicker from '../../components/date-pickers/inline-no-today';
import NumberFormat from 'react-number-format';

class OrganizationMaterialEdit extends Component {

  constructor(props) {
    super(props);
    const { userSession,organizationmaterials, organizations } = this.props;
   
    this.fromProps = false
    if(this.props && this.props.location && this.props.location.state && this.props.location.state.from && this.props.location.state.from.length > 0)
      this.fromProps = true

    this.globalAdm = false;
    this.isClient = true;
    if(userSession.user.userrole == null){
      Api.logoff();
    }

    if(!organizationmaterials || (organizationmaterials && !organizationmaterials.organizationmaterial)) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/calleds`
    }

    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

    let value = organizationmaterials.organizationmaterial.value == null ? null : organizationmaterials.organizationmaterial.value
    if(value) {
      let oldVSplit = value.toString().split('.')
      let oldV = oldVSplit[0]
      if(oldV.length > 0) {
        oldV = oldV.replaceAll(',', '.')
        if('1' in oldVSplit)
          oldV += `.${oldVSplit[1]}`
      }
      value = oldV
    }

 
    this.initialState = {
      UpdatedUser: userSession.user.id,
       id: organizationmaterials.organizationmaterial.id,
     OrganizationId:  organizationmaterials.organizationmaterial.OrganizationId,
     OrganizationText: organizationmaterials.organizationmaterial.OrganizationText,
     MaterialId:  organizationmaterials.organizationmaterial.MaterialId,
     MaterialText: organizationmaterials.organizationmaterial.MaterialText,
     name : organizationmaterials.organizationmaterial.name == null ? '' : organizationmaterials.organizationmaterial.name , 
     garanty : organizationmaterials.organizationmaterial.garanty, 
     value : value, 
     invoicedate: organizationmaterials.organizationmaterial.invoicedate == null ? '' : moment(organizationmaterials.organizationmaterial.invoicedate).format('YYYY/MM/DD HH:mm:ss') ,
     saveToAllOrganizationsInGroup: false
     };         
    this.state = {
      organizationmaterial: this.initialState,
      organizations: organizations,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      OrganizationList: [],
      MaterialList: [],

    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.handleInputChangeValue = this.handleInputChangeValue.bind(this);
  }

  componentDidMount() {
    if (this.state.organizations && this.state.organizations.organization && this.state.organizations.organization.id) {
      this.getOrganizationsById(this.state.organizations.organization.id);
    } else {
      this.getOrganizations();
    }
        this.getMaterials();
     };
     async getOrganizations(){
        const intl = this.props.intl;
          this.setState({ loading: true });
          var result = await Service.getOrganizations();
            if (result.success) {
              this.setState({ loading: false, OrganizationList: result.data });
                            
            } else {
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors &&  result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
  };

  getOrganizationsById = async (id) => {
    const intl = this.props.intl;
    this.setState({ loading: true });
    var result = await Service.getOrganizationsByIdLight(id);
    if (result.success) {
        this.setState({ loading: false, OrganizationList: [result.data] });

    } else {
        this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);
    }
  };
  
  async getMaterials(){
    const intl = this.props.intl;
          this.setState({ loading: true });
          var result = await Service.getMaterials();
            if (result.success) {
              this.setState({ loading: false, MaterialList: result.data });
                            
            } else {
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors &&  result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    if(name == 'value'){
      this.setState(prevState => ({
        organizationmaterial: {
          ...prevState.organizationmaterial,
          ['value']: value
        }
      }));
    }else{
      this.setState(prevState => ({
        organizationmaterial: {
          ...prevState.organizationmaterial,
          [name]: value
        }
      }));
    }
  };

  handleInputChangeValue(value) {
    // console.log(value);
    this.setState(prevState => ({
      organizationmaterial: {
        ...prevState.organizationmaterial,
        ['value']: value
      }
    }));
  };

  handleSubmit = async e => {
    e.preventDefault();
    const intl = this.props.intl;
    this.setState({ loading: true });

    let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
          organizationmaterial: {
            ...prevState.organizationmaterial,
          }
        }));
      } else {
        this.setState(prevState => ({
          organizationmaterial: {
            ...prevState.organizationmaterial,
          }
        }));
      }

      var regex = /^[0-9]+$/;
      if(!regex.test(this.state.organizationmaterial.garanty)) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: this.props.intl.formatMessage({id:"organization.material.edit.warranty.field.invalid"})
        });
        return false
      }

      for (let key in this.state.organizationmaterial) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.organizationmaterial[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else if (key.toString().toLowerCase().indexOf('invoicedate') > -1) {
          data.append(key, moment(this.state.organizationmaterial[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else {
          data.append(key, this.state.organizationmaterial[key]);
        }
      }

      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
        Api.put('/organizationmaterials', data, config)

        .then(result => {
          const intl = this.props.intl;
          if (result.data.success) {
        const formattedList = result.data.data;
        formattedList.OrganizationId=  formattedList.organization ? formattedList.organization.id : 0;
        formattedList.OrganizationText=  formattedList.organization ? formattedList.organization.name : '';
        formattedList.MaterialId=  formattedList.material ? formattedList.material.id : 0;
        formattedList.MaterialText=  formattedList.material ? formattedList.material.name : '';

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: this.props.intl.formatMessage({id:"edit.success"}),
              files: []
            });
        if(this.props.organizations && this.state.organizations.organization)
          this.props.addCurrentOrganization(formattedList, this.state.organizations.organization);
        else
          this.props.addCurrent(formattedList);
        this.componentDidMount();
 
          }
          else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage:  result.data.errors && result.data.errors[0] ? result.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });

          }
        })
        .catch(err => {
          const intl = this.props.intl;
          if(err.response && err.response.data && err.response.data.errors){
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
          }
          else{
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})+err.response.data
            });
          }
        });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  mtel(num){
    num=num.toString().replace(/\D/g,"");
    //
    if(num.toString().length > 5 && num.toString().length < 7){
      num = num.replace(/(\d{1}?)((\d{1})+)$/, "$1.$2");
      num = num.replace(/(\d{3})(?=\d)/g, "$1,");
    }else if (num.toString().length >= 7) {
      num=num.replace(/(\d{1,2})$/, ',$1');  
      num=num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');  
      num=num.replace(/^0+/, '');

    }else if (num.toString().length == 4){
        num = num.replace(/(\d{2})(?=\d)/g, "$1.");
    }else{
        num = num.replace(/(\d{3})(?=\d)/g, "$1,");
    }
    return num;
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      organizationmaterial: {
        ...prevState.organizationmaterial,
        [stateName]: value
      }
    }));
        if(stateName === 'OrganizationId'){
          this.setState(prevState => ({
            organizationmaterial: {
              ...prevState.organizationmaterial,
              ['OrganizationText']: text,
            }
          }));
        }
        if(stateName === 'MaterialId'){
          this.setState(prevState => ({
            organizationmaterial: {
              ...prevState.organizationmaterial,
              ['MaterialText']: text,
            }
          }));
        }
 
    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange(values.value);
        }}
        thousandSeparator={false}
        decimalSeparator=","
        isNumericString
        decimalScale={2}
        prefix="R$"
      />
    );
  }
  

    render() {

    const { classes, headerMenu, actionsHeaderMenu, organizationmaterials } = this.props;
    const { organizationmaterial } = this.state;

    console.log(this.state)
    console.log("render aqui")
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }
    
     return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary'  onClick={() => {
                      this.fromProps ? this.props.history.push(`/${this.props.match.params.lang}/organization-edit`, { organizations: this.props.organizations, propTab: 10 }) : browserHistory.goBack()}
                    }>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="edit.item" />} 
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <SimpleSelect label={intl.formatMessage({id:"organization"})} name='organizationid'  options={this.state.OrganizationList} stateName='OrganizationId' changeSelect={this.changeValues} selected={organizationmaterial.OrganizationId} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField fullWidth label={intl.formatMessage({id:"name"})}  inputProps={{ maxLength: 250 }} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={organizationmaterial.name} required />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                    <TextField
                      label={this.props.intl.formatMessage({id:"value"})}
                      variant='outlined'
                      value={organizationmaterial.value}
                      onChange={this.handleInputChangeValue}
                      name="value"
                      id="formatted-numberformat-input"
                      InputProps={{
                        inputComponent: this.NumberFormatCustom,
                      }}
                      autoComplete='fname'
                      required
                      InputLabelProps={{ shrink: true }}
                    />
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <SimpleDatePicker label={this.props.intl.formatMessage({id:"material.registration.note.date"})} name='invoicedate' stateName='invoicedate' changeDate={this.changeValues} currentDate={organizationmaterial.invoicedate}  required/>
                    </Grid>
                    <Grid item xs={3} sm={3}></Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField id='filled-number' label={intl.formatMessage({id:"garanty"})} type='number' variant='filled' InputLabelProps={{shrink: true}} name='garanty' onChange={this.handleInputChange} value={organizationmaterial.garanty} required />
                    </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleCheckBox
                    label={intl.formatMessage({id:"apply.changes.to.all.organizations.in.group"})}
                    name='saveToAllOrganizationsInGroup'
                    stateName='saveToAllOrganizationsInGroup' 
                    changeSelect={this.changeValues}
                    selected={organizationmaterial.saveToAllOrganizationsInGroup} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                    <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={4}    >
                        <Button 
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit}
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
      </div>
    );
  }
}
OrganizationMaterialEdit.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                  actionsHeaderMenu: state.actionsHeaderMenu,
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                    organizations: state.organizations,
                    organizationmaterials: state.organizationmaterials
                });
                const mapDispatchToProps = dispatch =>
                    bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps,mapDispatchToProps)(withStyles(Styles)(OrganizationMaterialEdit)));
