import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'

import { AbilityContext } from '../../config/ability-context'
import Base from '../../components/layout-base/base'
import { Styles } from '../../styles/material-styles'
import { withRouter } from 'react-router-dom'
import { Button, CircularProgress, Container, Grid, Paper } from '@mui/material'
import CalledData from './components/called-data'
import { Overlay } from '../../styles/global'
import CustomizedSnackbars from '../../components/material-snackbars'
import { consultById } from '../../services/called.service'
import { PapiroConsole } from '../../utils/papiroConsole'
import CalledCosts from './components/widgets/called-costs'
import CalledSla from './components/widgets/called-sla'
import CalledHistory from './components/widgets/called-history'
import { browserHistory } from '../../helpers/history';
import Api from '../../services/api'

function CalledView(props) {

  if (!(props && props.match && props.match.params && props.match.params.id && !isNaN(props.match.params.id) && props.match.params.id > 0))
    props.history.push(`/${props.match.params.lang}/calleds`)

  const { intl, classes, userSession } = props

  const [id, setId] = useState(props.match.params.id)
  const [isClient, setIsClient] = useState(true)
  const [isTechinical, setIsTechinical] = useState(false)
  const [isCoordenator, setIsCoordenator] = useState(false)
  const [isAdmOrganization, setIsAdmOrganization] = useState(false)
  const [isGlobalAdm, setIsGlobalAdm] = useState(false)

  const [loading, setLoading] = useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const [notificationVariant, setNotificationVariant] = useState('error')
  const [notificationMessage, setNotificationMessage] = useState('')

  const [calledData, setCalledData] = useState(null)

  useEffect(() => {
    if (userSession == null ||  userSession.user == null || userSession.user.userrole == null)
      Api.logoff()

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3)
        setIsClient(false)
      if (item.role.roletype == 0)
        setIsGlobalAdm(true)
      if (item.role.roletype == 1)
        setIsAdmOrganization(true)
      if (item.role.roletype == 4)
        setIsCoordenator(true)
      if (item.role.roletype == 2)
        setIsTechinical(true)
    })

    getData(id)
  }, [])

  useEffect(() => {
    if (id != props.match.params.id) {
      setId(props.match.params.id)
      getData(props.match.params.id)
    }
  }, [props.match.params.id])

  const setMessage = (open, variant, message) => {
    setNotificationVariant(variant)
    setNotificationMessage(message)
    setOpenNotification(open)
  }

  const closeNotification = _ => {
    setNotificationVariant('error')
    setNotificationMessage('')
    setOpenNotification(false)
  }

  const getData = async id => {
    setLoading(true)
    let response = await consultById(id)
    if (response != null && response.success != null && response.success && response.data != null) {
      setLoading(false)
      setCalledData(response.data)
      PapiroConsole.log("=== getData ===")
      PapiroConsole.log(response.data)
    } else {
      setLoading(false)
      setMessage(true, 'error', response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      //Api.kickoff(response)
    }
  }

  return (
    <Base props={props}>
      <Grid container spacing={1} sx={{ paddingRight: '15px' }} >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button variant='outlined' color='primary'
            onClick={() => browserHistory.goBack()} >
            <FormattedMessage id="back" />
          </Button>
        </Grid>
        {calledData != null && <Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
          <CalledData 
            id={id}
            calledData={calledData}
            classes={props.classes}
            isClient={isClient}
            setLoading={setLoading}
            setMessage={setMessage} />
        </Grid>}
        {calledData != null && <Grid item xs={12} sm={12} md={4} lg={3} xl={3} sx={{ marginTop: '16px' }}>
          { (isCoordenator || isAdmOrganization || isGlobalAdm) && <CalledCosts
            calledData={calledData} />}
          <CalledSla
            calledData={calledData} />
          <CalledHistory
            calledData={calledData} />
        </Grid>}
      </Grid>
      <Container style={{ position: 'relative' }} component="main" maxWidth="md">
        
      </Container>

      {loading && (
        <Overlay>
          <CircularProgress color='secondary' />
        </Overlay>
      )}

      <CustomizedSnackbars
        variant={notificationVariant}
        message={notificationMessage}
        isOpen={openNotification}
        toClose={closeNotification} />
    </Base>
  )
}

CalledView.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
})

export default injectIntl(withRouter(connect(mapStateToProps)(withStyles(Styles)(CalledView))))