import React from 'react';
import UserIcon from '../../images/me-feedback/user_icon.png';

const MEUserIcon = props => (
    <div style={{ display: 'inline-block' }}>
        <img
            src={props.src && props.src.length > 0 ? props.src : UserIcon}
            style={props.style != null ? props.style : {  }}
            title="FeedbackUserIcon"
            className='App-User-icon' />
    </div>
);

export default MEUserIcon;
