import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { QueuePlayNext, DeleteForever, Girl } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import SimpleCheckBox from '../../components/checkbox/check';
import Footer from '../../components/footer';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import * as Service from '../../services/user.service';
import * as componentActions from '../../redux/actions/users';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as userSessionActions from '../../redux/actions/user-session';
import { PapiroConsole } from '../../utils/papiroConsole';
import DialogGeneral from 'src/components/dialog-general';

import { Checkbox, FormControlLabel  } from '@mui/material';

class UserEdit extends Component {


  constructor(props) {
    super(props);
    const { userSession, users } = this.props;


    this.globalAdm = false;
    this.isClient = true;
    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    }
    );

    this.state = {
      user: {
        UpdatedUser: "",
        id: "",
        name: "",
        email: "",
        document: "",
        active: false,
        phone: "",
        twoFactorAuthenticationCode: "",
        twoFactorAuthenticationActive: false,
      },
      email: "",
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      OrganizationList: [],
      RoleList: [],
      RoleListUser: [],
      tabValue: 0,
      twoFactorAuthenticationQrcode: "",
      enableTwoFactor: false,
      canUserEditEmail: false,
      changeEmailConfirmDialogOpen: false,
      confirmPassword: "",
      dialogState : false,
      current2faValue : null
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.getItens = this.getItens.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getCanUserEditEmailByHIrerId = this.getCanUserEditEmailByHIrerId.bind(this)
    this.openChangeEmailConfirmDialog = this.openChangeEmailConfirmDialog.bind(this)
    this.closeChangeEmailConfirmDialog = this.closeChangeEmailConfirmDialog.bind(this)
    this.handleConfirmchangeEmailConfirm = this.handleConfirmchangeEmailConfirm.bind(this)
    this.closeModal = this.closeModal.bind(this);
    this.activateOrDeactivate2FA = this.activateOrDeactivate2FA.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  async componentDidMount() {
    await this.getItens();
    await this.getCanUserEditEmailByHIrerId()
  };

  handleChangePhone(e) {
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        ['phone']: e
      }
    }));
  }

  openChangeEmailConfirmDialog = _ => this.setState({ changeEmailConfirmDialogOpen: true, confirmPassword: "" })

  closeChangeEmailConfirmDialog = _ => this.setState({ changeEmailConfirmDialogOpen: false, confirmPassword: "" })
  
  handleConfirmchangeEmailConfirm = e => {
    const { intl } = this.props

    if (this.state.confirmPassword.length > 0) {
      this.setState({ changeEmailConfirmDialogOpen: false }, () => this.handleSubmit())
    } else {
      this.setState({
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: "O campo 'Senha atual' é obrigatório!"
      })
    }
  }

  async getOrganizations() {
    this.setState({ loading: true });
    var result = await Service.getOrganizations();
    if (result.success) {
      this.setState({ loading: false, OrganizationList: result.data });

    } else {
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };

  getCanUserEditEmailByHIrerId = () => {
    Api.get(`/configs/canusereditemail`)
      .then(result => {
        if (result != null && result.data != null && result.data.success != null && result.data.success && result.data.data != null) {
          this.setState({ loading: false, canUserEditEmail: result.data.data });
        }
        else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          });
          Api.kickoff(result);
        }
      })
      .catch(err => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(err);
      });
  }

  getItens() {
    this.setState({ loading: true });
    const { userSession } = this.props;

    Api.get(`/users/${userSession.user.id}`)
      .then(result => {
        if (result.data.success) {
          const formattedList = {
            UpdatedUser: result.data.data.id,
            id: result.data.data.id,
            name: result.data.data.name ? result.data.data.name : "",
            email: result.data.data.email ? result.data.data.email : "",
            document: result.data.data.document ? result.data.data.document : "",
            active: result.data.data.active ? result.data.data.active : false,
            phone: result.data.data.phone ? result.data.data.phone : "",
            twoFactorAuthenticationActive: result.data.data.twofactorauthenticationactive ? result.data.data.twofactorauthenticationactive : false,
          }

          if(result.data.data.twofactorauthenticationactive){
            this.setState({ enableTwoFactor: true, twoFactorAuthenticationQrcode: result.data.data.twofactorauthenticationqrcode ?? "" });  
          }

          this.setState({ user: formattedList, email: result.data.data.email ? result.data.data.email : "" });
        }
        else {
          this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          });
          Api.kickoff(result);
        }
      })
      .catch(err => {
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(err);
      });

  };
  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      user: {
        ...prevState.user,
        [name]: value
      }
    }));
  };

  closeModal = () => {

    this.setState({ dialogState: false, current2faValue : null })

    

  };

  handleConfirmSubmitForm = e => {
    e.preventDefault()

    if(this.state.user.email == null || (this.state.user.email == null && this.state.user.email.length == 0)) {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "O campo 'Email' é obrigatório!"
      })
    }

    if(this.state.user.email != this.state.email)
      this.openChangeEmailConfirmDialog()
    else
      this.handleSubmit()
  }

  handleSubmit = async _ => {
    const intl = this.props.intl;
    
    this.setState({ loading: true });

    let data = new FormData();


    if (this.isClient) {
      this.setState(prevState => ({
        user: {
          ...prevState.user,
        }
      }));
    } else {
      this.setState(prevState => ({
        user: {
          ...prevState.user,
        }
      }));
    }

    for (let key in this.state.user) {
      if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1)
        data.append(key, moment(this.state.user[key]).format('YYYY/MM/DD HH:mm:ss'))
      else if (key.toString().toLowerCase().indexOf("document") > -1)
        data.append(key, typeof this.state.user[key] !== 'undefined' || this.state.user[key] !== null ? this.state.user[key] : "")
      else
        data.append(key, this.state.user[key])
    }
    data.append("changeEmail", this.state.user.email != this.state.email ? true : false)
    if (this.state.user.email != this.state.email)
      data.append("currentPassword", this.state.confirmPassword)

    this.state.files.forEach(file => data.append('filesUpload', file));
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    Api.put('/users/userdata', data, config)

      .then(result => {
        if (result.data.success) {
          const formattedList = result.data.data.user;
          formattedList.passwordhash = null;
          formattedList.passwordrecoveryguid = null;
          formattedList.passwordsalt = null;


          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "edit.success" }),
            files: [],
            confirmPassword: ""
          });
          if (this.props.userSession && this.props.userSession.user) {
            let user = this.props.userSession.user
            user.name = formattedList.name
            user.phone = formattedList.phone
            this.props.addUserSession(user)
          }
          this.props.addCurrent(formattedList);
          let changeEmail = result.data.data.changeemail
          if (changeEmail != null && changeEmail == true) {
            userSessionActions.removeUserSession();

            let language = window.location.pathname.split('/')[1]
            if (language != "pt" && language != "es" && language != "en")
              language = "pt"

            Api.get("/users/removeClaims")
              .then(result => {
                if(Api && Api.resetToken)
                  Api.resetToken()
                this.props.history.push(`/${language}/`)
              })
              .catch(err => {
                if(Api && Api.resetToken)
                  Api.resetToken()
                this.props.history.push(`/${language}/`)
            })
          } else
            this.componentDidMount()

        }
        else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: result.data.errors && result.data.errors[0] ? result.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          });

        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          });
        }
        else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })

          });
        }
      });
  };

  setTwoFactorAuthenticationQrCode = async () => {
    const intl = this.props.intl;

    this.setState({ loading: true });

    Api.get('/users/twoFactorAuthenticationQRCode/'+this.state.user.email)

      .then(result => {
        if (result.data.success) {

          this.closeModal();
          this.setState({
            loading: false,
            twoFactorAuthenticationQrcode: result.data.data
          });
          this.setState(prevState => ({
            user: {
              ...prevState.user,
              twoFactorAuthenticationActive: false
            }
          }));
        }
        else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: result.data.errors && result.data.errors[0] ? result.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          });

        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          });
        }
        else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })

          });
        }
      });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  handleChange(value) {
    this.setState({
      tabValue: value,
    });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      user: {
        ...prevState.user,
        [stateName]: value
      }
    }));
    if (stateName === 'OrganizationId') {
      this.setState(prevState => ({
        user: {
          ...prevState.user,
          ['OrganizationText']: text,
        }
      }));
    }

    this.setState({ loading: false });

  };

  activeAuthenticationTwoFactory = async (event, value) => {

    var message = "" ;
    if(value == true){
      message = this.props.intl.formatMessage({ id: "do.you.want.to.enable.two-factor.authentication" })
    }
    else{
      message = this.props.intl.formatMessage({ id: "do.you.want.to.disable.two-factor.authentication" })
    }
     this.setState({
      dialogState: true,
      dialogSubtitleMessage : message,
      current2faValue : value

    });
    
  }

  activateOrDeactivate2FA() {

    this.setState({
      enableTwoFactor: this.state.current2faValue
    });
    
    this.setTwoFactorAuthenticationQrCode();
    
  }

  handleError(err) {
    console.error(err);
  }

  enableTwoFactorAuthentication = () => {
    const intl = this.props.intl;

    let data = new FormData();

    data.append('email', this.state.user['email']);
    data.append('code', this.state.user['twoFactorAuthenticationCode']);

    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    this.setState({ loading: true });

    Api.put('/users/enableTwoFactorAuthentication', data, config)

      .then(result => {
        if (result.data.success) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: this.props.intl.formatMessage({ id: "twoFactorAuth.enableSuccess" })
          });
          this.setState(prevState => ({
            user: {
              ...prevState.user,
              twoFactorAuthenticationActive: true
            }
          }));
        }
        else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: result.data.errors && result.data.errors[0] ? result.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          });

        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          });
        }
        else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })

          });
        }
      });
  }

  handleInputChangePassword = e => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu, users, intl } = this.props;
    const { user } = this.state;

    PapiroConsole.log("props 12 ")
    PapiroConsole.log(this.props)

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={this.state.changeEmailConfirmDialogOpen}
                onClose={this.closeChangeEmailConfirmDialog}
                aria-labelledby="confirm.association.of.techinical.teams" >
                <DialogTitle id="confirm.association.of.techinical.teams">
                  Confirmar alteração de e-mail?
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} sm={12} md={12}>
                      Após alterar o e-mail, o usuário será inativado, sendo necessário confirmar o novo e-mail cadastrando uma nova senha. Deseja continuar?
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="confirmPassword"
                        label="Insira sua senha atual"
                        type="password"
                        id="confirmPassword"
                        autoComplete="current-password"
                        value={this.state.confirmPassword}
                        onChange={this.handleInputChangePassword} />
                    </Grid>
                  </Grid>   
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={this.closeChangeEmailConfirmDialog}
                    variant='outlined'
                    color="primary">
                    {intl.formatMessage({ id: "cancel" })}
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={this.handleConfirmchangeEmailConfirm}
                    type="submit" >
                    {intl.formatMessage({ id: "confirm" })}
                  </Button>
                </DialogActions>
            </Dialog>
          <Container component='main' maxWidth='xl'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {intl.formatMessage({ id: "organizations.called.edit.item.edit" })}
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleConfirmSubmitForm} encType='multipart/form-data'>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Tabs selectedIndex={this.state.tabValue} onSelect={this.handleChange}>
                      <TabList>
                        <Tab>{intl.formatMessage({ id: "my.data" })}</Tab>
                        <Tab>{intl.formatMessage({ id: "twoFactorAuth.name" })}</Tab>
                      </TabList>
                      <TabPanel>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12}>
                            <TextField
                            inputProps={{ maxLength: 255 }}
                            fullWidth label={intl.formatMessage({ id: "name" })}
                            autoComplete='fname' variant='outlined'
                            name='name'
                            onChange={this.handleInputChange}
                            value={user.name}
                            required />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <TextField
                              disabled={!this.state.canUserEditEmail}
                              fullWidth
                              label={intl.formatMessage({ id: "email" })}
                              autoComplete='fname'
                              variant='outlined'
                              name='email'
                              onChange={this.handleInputChange}
                              value={user.email}
                              required />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <TextField disabled fullWidth label={intl.formatMessage({ id: "document" })} autoComplete='fname' variant='outlined' name='document' onChange={this.handleInputChange} value={user.document} />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <PhoneInput
                              inputStyle={{
                                padding: '23.5px 14px 23.5px 58px',
                                width: '100%'
                              }}
                              id="phone"
                              name="phone"
                              country={'br'}
                              value={user.phone}
                              onChange={this.handleChangePhone}
                              InputLabel={intl.formatMessage({ id: "phone" })}
                              specialLabel={intl.formatMessage({ id: "phone" })}
                              inputProps={{
                                name: intl.formatMessage({ id: "phone" })
                              }} />
                          </Grid>
                          <Grid item xs={12} sm={4} align-items-xs-center={'true'} justify-xs-flex-end={'true'}>
                            <Button
                              type='submit'
                              fullWidth
                              variant='contained'
                              color='primary'
                              className={classes.submit}
                            >
                              {intl.formatMessage({ id: "save" })}
                            </Button>
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel >
                        <Grid item xs={12} sm={12}>
                          
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.enableTwoFactor}
                                onChange={this.activeAuthenticationTwoFactory}
                              />
                            }
                            label={intl.formatMessage({ id: "twoFactorAuth.enable" })}
                          />
                         
                        </Grid>
                        {(this.state.enableTwoFactor && this.state.twoFactorAuthenticationQrcode != '') && (
                          <Grid container>
                            <Grid item xs={12} sm={12}>
                              <i>{intl.formatMessage({ id: "twoFactorAuth.nowScanCode" })}</i>
                            </Grid>
                            <Grid container>
                              <Grid xs={12} sm={4}>
                                <img src={this.state.twoFactorAuthenticationQrcode} width={300} height={300} />
                              </Grid>
                              {!(this.state.user.twoFactorAuthenticationActive) && (
                                <Grid item style={{marginTop: '30px'}} xs={12} sm={6}>
                                  <Grid item xs={12} sm={6}>
                                    <TextField 
                                      inputProps={{ maxLength: 6 }} 
                                      fullWidth 
                                      label={intl.formatMessage({ id: "access.code" })}
                                      variant='outlined' 
                                      name='twoFactorAuthenticationCode' 
                                      onChange={this.handleInputChange} 
                                      value={user.twoFactorAuthenticationCode} />
                                  </Grid>
                                  <Grid style={{marginTop: '20px'}} item xs={12} sm={6}>
                                    <Button
                                      fullWidth
                                      variant='contained'
                                      color='primary'
                                      className={classes.submit}
                                      onClick={this.enableTwoFactorAuthentication}
                                    >
                                      {intl.formatMessage({ id: "enable" })}
                                    </Button>
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        )}
                      </TabPanel>
                    </Tabs>
                  </Grid>
                </Grid>
              </form>
            </div>

            <DialogGeneral
              dialogTitle="Autenticação de dois fatores"
              dialogSubTitle= {this.state.dialogSubtitleMessage}
              open={this.state.dialogState}
              openOrCloseModalFunction={this.closeModal}
              handleConfirmation={this.activateOrDeactivate2FA}
            />

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}

UserEdit.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  users: state.users
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(Object.assign({}, componentActions, userSessionActions), dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(UserEdit)));
