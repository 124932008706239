import React, { Component } from 'react';
import { Grid } from "@mui/material";

import Rating from '@mui/material/Rating';
import { PapiroConsole } from '../../../../utils/papiroConsole';
class StarScale extends Component {
  constructor(props) {
    super(props);

    // Inicialize o estado do componente
    PapiroConsole.log(this.props.indcsatisfiedserviceindex)
    PapiroConsole.log("this.props.indcsatisfiedserviceindex")

    PapiroConsole.log(this.props.starRating)
    PapiroConsole.log("this.props.starRating new")
    this.state = {
      starRating: this.props.indcsatisfiedserviceindex ? this.props.starRating :  0,
      colors : [
        '#EC475C',
        '#EC475C',
        'linear-gradient(90deg, #EC475C 0%, #FB8A31 100%)',
        '#FB8A31',
        '#FB8A31',
        'linear-gradient(90deg, #FB8A31 0%, #FFE44B 100%)',
        '#FFE44B',
        '#FFE44B',
        'linear-gradient(90deg, #FFE44B 0%, #10A9FD 100%)',
        '#10A9FD',
        '#10A9FD'

      ]
    };
    
  }

  updateChildState = (newState) => {
    PapiroConsole.log("ENTREI AQUI 3")

    this.setState({
      starRating: 0
    });
  }



  render() {
    return (
      <>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={3} >
          <p style={{ color: '#09316F' }}><strong>0{this.props.disabledCallRejectionFlag ? this.props.index+1 : this.props.index+2}.</strong> {this.props.title}</p>
        </Grid>
        <Grid item xs={12} sm={12} container alignItems="center" justifyContent="center" direction="row"  >
          <Rating 
            style={{fontSize: "45px"}}
            name="rating"
            required
            value={this.state.starRating}
            max = {this.props.end}
            onChange={(event, newValue) => {
              this.setState({
                starRating: newValue
              });
              this.props.changeValuesSurveyQuestions(this.props.index,newValue, this.props.indcsatisfiedserviceindex )
            }}
          />
          </Grid>

      </>
    );
  }
}

export default StarScale;