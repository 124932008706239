import React from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import PropTypes from "prop-types";
import { AssignmentReturnOutlined } from '@mui/icons-material';
import { FormattedMessage,injectIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: '100%',
    height: 300,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
        return a.filter(value => b.indexOf(value) === -1);
}

function getIds(items){
  const idList = items.map(item => {
    return item.id
  });
  return idList;

};

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

function MyTransferList(props) {

  const { label, options, defaultData, stateName, changeSelect, selected, required } = props;
 
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);

  const [right, setRight] = React.useState([]);
  const [match, setMatch] = React.useState(window.matchMedia("(max-width: 599px)").matches);

  

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  
  React.useEffect(() => {
    //console.log("mount");
    const handler = e => setMatch(e.matches);
    window.matchMedia("(max-width: 660px)").addListener(handler);
    
    setLeft(options);
    setRight(selected);
},[options],[selected]);    

          

  const handleChange = event => {
    //setOption(event.target.value);
    //changeSelect(stateName, event.target.value);
  };

  const handleToggle = value => () => {
   
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    //console.log(newChecked);
  };

  const numberOfChecked = items => intersection(checked, items).length;

  const handleToggleAll = items => () => {
    //console.log(numberOfChecked(items)+" = "+items.length);
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      let all = union(checked, items);
      //console.log(checked);
      //console.log(all);
      setChecked(all);
    }
  };

  const handleCheckedRight = () => {
    var r =right.concat(leftChecked);
    setRight(r);
    let m = not(left, leftChecked);

    setLeft(m);

    setChecked(not(checked, leftChecked));
    changeSelect(stateName,r);

  };


  
  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    var r = not(right, rightChecked);
    setRight(r);
    setChecked(not(checked, rightChecked));
    
    changeSelect(stateName,r);

  };


  const intl = props.intl;
  const customList = (title, items, di) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} ${intl.formatMessage({id:"select"})}`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.length > 0 && items.map(value => {
          const labelId = `transfer-list-all-item-${value.id}-label`;
          return (
            <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.displayname ? value.displayname: value.name}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );
  //console.log(left);
  
  return (
    <Grid container justify="center" alignItems="center" className={classes.root}>
      <Grid item sm={4} xs={12}>{customList(label+' '+intl.formatMessage({id:"available"}), left,1)}</Grid>
        <Grid item xs={12} sm={3}>
        <Grid container direction="column" alignItems="center">
          <Grid item sm={2} xs={12}>
          <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                {
                  !match && <> &gt; </>
                }

                {
                  match && <> &gt; </>
                }
              </Button>
          </Grid>
          <Grid item sm={2} xs={12}>
          <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                {
                  !match && <> &lt; </>
                }

                {
                  match && <> 	&lt; </>
                }
              </Button>
          </Grid>
        </Grid>
        </Grid>
      <Grid item sm={4} xs={12}>
      {customList(label+" "+intl.formatMessage({id:"select"}), right,2)}
      </Grid>

    </Grid>
  );
}

export default injectIntl(MyTransferList);
MyTransferList.propTypes = {
    label: PropTypes.string,
    defaultData: PropTypes.array,
    options: PropTypes.array,
    stateName: PropTypes.string,
    changeSelect: PropTypes.func,
    selected: PropTypes.array
  };
