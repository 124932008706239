import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  IconButton,
  LinearProgress,
  CardActionArea
} from '@mui/material';
import InsertChartIcon from '@mui/icons-material/InsertChartOutlined';
import { FormattedMessage, injectIntl } from 'react-intl';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PendingIcon from '@mui/icons-material/Pending';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import CallIcon from '@mui/icons-material/Call';
import { blue } from '@mui/material/colors';
import CachedIcon from '@mui/icons-material/Cached';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import PanToolIcon from '@mui/icons-material/PanTool';
import { PapiroConsole } from '../../../../utils/papiroConsole';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import ListAltIcon from '@mui/icons-material/ListAlt';


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  progress: {
    marginTop: theme.spacing(3)
  }
}));

const CalledsQuantityCards = props => {
  const { className, message, quantity, icon, refresh, filter, callSearch,redirectToCalledListWithFilter, clearFilters, disabledCardActionArea, origin,tooltipCard  } = props;
  const intl = props.intl;

 

  const classes = useStyles();

  const handleCallSearch = async () => {

    
    // caso a origem seja do dashboard ele deve direcionar pra página de chamados e colocar o filtro novo  
    if(origin == "dashboard"){

      if(message == "expired.calleds.total"){
        redirectToCalledListWithFilter("calledcards", intl.formatMessage({id:"expired.calleds.total.filter"}), 1) 
      }
      else if (message == "expired.calleds.today"){
        redirectToCalledListWithFilter("calledcards", intl.formatMessage({id:"expired.calleds.today.filter"}), 1)
      }
      else if(message == "general.open.calleds"){
        redirectToCalledListWithFilter("calledcards", intl.formatMessage({id:"general.open.calleds"}), 1)
      }
      else if(message == "today.pending.calleds"){
        redirectToCalledListWithFilter("calledcards", intl.formatMessage({id:"today.pending.calleds"}), 1)
      }

      else if(message == "calleds.without.assigned"){
        redirectToCalledListWithFilter("calledcards", intl.formatMessage({id:"calleds.without.assigned"}), 1)
      }

      else if(message == "today.open.calleds"){
        redirectToCalledListWithFilter("calledcards", intl.formatMessage({id:"today.open.calleds"}), 1)
      }

     


    }
    // caso a origem seja do called-list ele deve aplicar o filtro novo de acordo com o card selecionado
    else  if( origin == "called-list"){

      await clearFilters();

      if(message == "expired.calleds.total"){
        callSearch("calledcards", intl.formatMessage({id:"expired.calleds.total.filter"}), 1) 
      }
      else if (message == "expired.calleds.today"){
        callSearch("calledcards", intl.formatMessage({id:"expired.calleds.today.filter"}), 1)
      }
      else if(message == "general.open.calleds"){
        callSearch("calledcards", intl.formatMessage({id:"general.open.calleds"}), 1)
      }
      else if(message == "today.pending.calleds"){
        callSearch("calledcards", intl.formatMessage({id:"today.pending.calleds"}), 1)
      }

      else if(message == "calleds.without.assigned"){
        callSearch("calledcards", intl.formatMessage({id:"calleds.without.assigned"}), 1)
      }

      else if(message == "today.open.calleds"){
        callSearch("calledcards", intl.formatMessage({id:"today.open.calleds"}), 1)
      }

      

    }

    
    

    

  }


  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <Tooltip title={intl.formatMessage({id:tooltipCard})}>
        <CardActionArea /*disabled={disabledCardActionArea}*/ onClick={() => { handleCallSearch() }}>



          <CardContent>
            <Grid
              container
              justify="space-between"

            >

              <Grid item xs={10} >
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                  variant="body2"
                >
                  {<FormattedMessage id={message} />}
                </Typography>
                <Typography variant="h3">{quantity}</Typography>
              </Grid>
              <Grid item xs={2} >
                <Avatar className={classes.avatar} sx={{ bgcolor: blue[500] }}>
                  {icon == "expiredGeral" &&
                    <PendingActionsIcon />
                  }
                  {icon == "expiredToday" &&
                    <PendingIcon />
                  }
                  {icon == "openGeral" &&
                    <CallIcon />
                  }
                  {icon == "pendingToday" &&
                 
                    <ListAltIcon />
                  }
                  {icon == "WithoutAssignedUser" &&
                    <PersonOffIcon />
                  }

                  {icon == "openToday" &&
                      <AddIcCallIcon />
                  }


                </Avatar>
              </Grid>



              {/*refresh == true &&

                <Grid item xs={1}   >
                  <Box display="flex" justifyContent="flex-end">
                    <IconButton color="inherit"
                      aria-label="open drawer"
                      onClick={props.getCalledsToCard}
                      edge="start"
                    >
                      <CachedIcon />
                    </IconButton>
                  </Box>


                </Grid>

                */}
            </Grid>
          </CardContent>
        </CardActionArea>
      </Tooltip>

    </Card>
  );
};

CalledsQuantityCards.propTypes = {
  className: PropTypes.string,
  progress: PropTypes.number
};

export default injectIntl(CalledsQuantityCards);
