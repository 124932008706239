import axios from 'axios';
import { PapiroConsole } from '../utils/papiroConsole';
import * as userSessionActions from '../redux/actions/user-session';

let saasError = false
try {
  const urlSaaS = process.env.REACT_APP_API + "/hirerconfig/geturl/" + window.location.host + "/1";
  PapiroConsole.log(urlSaaS)
  var xhttp = new XMLHttpRequest();
  xhttp.open("GET", urlSaaS, false);
  xhttp.send();
  PapiroConsole.log(JSON.parse(xhttp.responseText));
} catch (e) {
  PapiroConsole.log(e)
  PapiroConsole.log("urlSaaS")
  if (e.name == "NetworkError") {
    PapiroConsole.log("entrou NetworkError")
    if (e.message.indexOf("Failed to execute 'send' on 'XMLHttpRequest'") !== -1) {
      saasError = true;
      PapiroConsole.log("entrou Failed 'send' 'XMLHttpRequest'")
    } else if (e.message.indexOf("A network error occurred.") !== -1) {
      saasError = true;
      PapiroConsole.log("entrou em A network error occurred.")
    } else {
      PapiroConsole.log("Outro erro")
      PapiroConsole.log(e.name)
      PapiroConsole.log(e.message)
    }
  }
}

let Api2Talk = {};
let baseURLBack = {};


if (!saasError) {

  let baseUrlResponse = JSON.parse(xhttp.responseText);

  if (baseUrlResponse && baseUrlResponse.data && (baseUrlResponse.data.urlapicone || baseUrlResponse.data.urlapi2talkservice)) {
    let baseURL = "";
    if (baseUrlResponse.data.urlapicone) {
      baseURL = "https://" + baseUrlResponse.data.urlapicone + "/api";
    }
    else {
      baseURL = "https://" + baseUrlResponse.data.urlapi2talkservice + "/api";
    }
    const token = localStorage.getItem('accesstoken2talk');
    baseURLBack = baseURL;

    Api2Talk = axios.create({
      baseURL: baseURL,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${token}`
      },
    });

    Api2Talk.kickoff = function (result) {
      PapiroConsole.log("=== Api.kickoff ===");
      PapiroConsole.log(result);
      if ((result && result.response && result.response.data && result.response.data.errors && result.response.data.errors[0])) {

        if ((result.response.data.errors[0].indexOf("invalid_token") > -1)) {
          userSessionActions.removeUserSession();
         /* const whitelabelsession = JSON.parse(localStorage.getItem("whitelabel"))
          if (whitelabelsession != null) {
            localStorage.removeItem('whitelabel');
            primarycolorTheme = "#3F51B4"
            secondarycolorTheme = "#277afe"
            appName = "CMTECH - Mexx 2DO"
            footerText = "CMTECH - Mexx 2DO"
            loginImage = null
            menuImage = null
            externalUserRegisterImage = null
            loginTitle = "Atendimento Inteligente ao Cliente"
            showfooterusetermslink = true
          }*/
          localStorage.clear();
          document.location.href = '/?sessão expirada';
        }

        if ((result.response.data.errors[0].indexOf("ContractInvalid") > -1)) {

          document.location.href = '/pt/my-plan?message';
        }
      }
      if ((result && result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0])) {

        if ((result.data.response.data.errors[0].indexOf("invalid_token") > -1)) {
          userSessionActions.removeUserSession();
          /*const whitelabelsession = JSON.parse(localStorage.getItem("whitelabel"))
          if (whitelabelsession != null) {
            localStorage.removeItem('whitelabel');
            primarycolorTheme = "#3F51B4"
            secondarycolorTheme = "#277afe"
            appName = "CMTECH - Mexx 2DO"
            footerText = "CMTECH - Mexx 2DO"
            loginImage = null
            menuImage = null
            externalUserRegisterImage = null
            loginTitle = "Atendimento Inteligente ao Cliente"
            showfooterusetermslink = true
          }*/
          localStorage.clear();
          document.location.href = '/?sessão expirada';
        }

        if ((result.data.response.data.errors[0].indexOf("ContractInvalid") > -1)) {

          document.location.href = '/pt/my-plan?message';
        }
      }

      if (result && result.errors && result.errors[0]) {
        if (result.errors[0] === "Error: invalid_token") {
          userSessionActions.removeUserSession();
          /*const whitelabelsession = JSON.parse(localStorage.getItem("whitelabel"))
          if (whitelabelsession != null) {
            localStorage.removeItem('whitelabel');
            primarycolorTheme = "#3F51B4"
            secondarycolorTheme = "#277afe"
            appName = "CMTECH - Mexx 2DO"
            footerText = "CMTECH - Mexx 2DO"
            loginImage = null
            menuImage = null
            externalUserRegisterImage = null
            loginTitle = "Atendimento Inteligente ao Cliente"
            showfooterusetermslink = true
          }*/
          localStorage.clear();
          document.location.href = '/?sessão expirada';
        }

        if (result.errors[0] === "Error: ContractInvalid") {
          document.location.href = '/pt/my-plan?message';
        }
      }


      if (result && result.data && result.data.errors && result.data.errors[0]) {
        if ((result.data.errors[0] === "Error: invalid_token")) {
          userSessionActions.removeUserSession();
          /*const whitelabelsession = JSON.parse(localStorage.getItem("whitelabel"))
          if (whitelabelsession != null) {
            localStorage.removeItem('whitelabel');
            primarycolorTheme = "#3F51B4"
            secondarycolorTheme = "#277afe"
            appName = "CMTECH - Mexx 2DO"
            footerText = "CMTECH - Mexx 2DO"
            loginImage = null
            menuImage = null
            externalUserRegisterImage = null
            loginTitle = "Atendimento Inteligente ao Cliente"
            showfooterusetermslink = true
          }*/
          localStorage.clear();
          document.location.href = '/?sessão expirada';
        }

        if ((result.data.errors[0] === "Error: ContractInvalid")) {
          document.location.href = '/pt/my-plan?message';
        }
      }
    }


  }
}

Api2Talk.apiurlbase = function () {
  return baseURLBack;
}


export default Api2Talk;
