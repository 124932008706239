import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Grid } from '@mui/material'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@mui/styles'

import Base from '../../components/layout-base/base'
import { browserHistory } from '../../helpers/history'
import CalledData from './components/called-data'
import CustomizedSnackbars from '../../components/material-snackbars'
import { Styles } from '../../styles/material-styles'
import { Overlay } from '../../styles/global'
import { Api } from '@mui/icons-material'
import { consultToChangeOrganizationById } from '../../services/called.service'
import CalledEdit from './components/called-edit'

function CalledEditOrganization(props) {

  const { intl, userSession, history, match } = props

  const [id, setId] = useState(props.match.params.id)
  const [calledData, setCalledData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const [notificationVariant, setNotificationVariant] = useState('error')
  const [notificationMessage, setNotificationMessage] = useState('')

  useEffect(() => {
    if (userSession == null ||  userSession.user == null || userSession.user.userrole == null)
      Api.logoff()

    const getData = async (id) => {
      setLoading(true)
      let response = await consultToChangeOrganizationById(id)
      if (response != null && response.success != null && response.success && response.data != null) {
        setCalledData(response.data)
        setLoading(false)
    
      } else {
        setLoading(false)
        setMessage(true, 'error', response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
        Api.kickoff(response)
      }
    }

    getData(id)
  }, [])

  const setMessage = (open, variant, message) => {
    setNotificationVariant(variant)
    setNotificationMessage(message)
    setOpenNotification(open)
  }

  const closeNotification = _ => {
    setNotificationVariant('error')
    setNotificationMessage('')
    setOpenNotification(false)
  }

  return(<Base props={props}>
    <Grid container spacing={1} sx={{ paddingRight: '15px' }} >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Button variant='outlined' color='primary'
          onClick={() => browserHistory.goBack()} >
          <FormattedMessage id="back" />
        </Button>
      </Grid>
      {calledData != null && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ paddingLeft: '5%!important', paddingRight: '5%!important' }}>
        <CalledData
          id={id}
          calledData={calledData}
          classes={props.classes}
          setLoading={setLoading}
          setMessage={setMessage} />
      </Grid>}
      {calledData != null && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ paddingLeft: '5%!important', paddingRight: '5%!important' }}>
        <CalledEdit
          id={id}
          calledData={calledData}
          classes={props.classes}
          setLoading={setLoading}
          setMessage={setMessage} 
          history={props.history}
          match={props.match} />
      </Grid>}
    </Grid>

    {loading && (
        <Overlay>
          <CircularProgress color='secondary' />
        </Overlay>
      )}

      <CustomizedSnackbars
        variant={notificationVariant}
        message={notificationMessage}
        isOpen={openNotification}
        toClose={closeNotification} />
  </Base>)
}

CalledEditOrganization.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
})

export default injectIntl(withRouter(connect(mapStateToProps)(withStyles(Styles)(CalledEditOrganization))))