export function equeuelastCalledsQueue(element) {
  return {
    type: 'EQUEUE_LAST_CALLEDS_QUEUE',
    element
  }
}

export function clearlastCalledsQueue() {
  return {
    type: 'CLEAR_LAST_CALLEDS_QUEUE'
  }
}