import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'; import SimpleSelect from '../../components/select/simple';
import SimpleCheckBox from '../../components/checkbox/check';
import SimpleDatePicker from '../../components/date-pickers/inline';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import Title from '../../components/title-name';
import * as Service from '../../services/organizationchecklist.service';
import { getOrganizationCategoryDepartamentsByOrganizationCategory, getDepartamentsByOrganizationCategoryId } from '../../services/organizationcategory.service';
import { getByOrganizationWithoutIncludes } from '../../services/organizationsetting.service'
import * as componentActions from '../../redux/actions/organizationchecklists';
import * as CalledService from '../../services/called.service';
import { FormattedMessage, injectIntl } from 'react-intl';

import { DataGrid, GridToolbarFilterButton  } from '@mui/x-data-grid';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogConfirmation from '../../components/dialog-confirmation'

import SimpleAutoCompleteMultiple from "../../components/auto-complete/autocomplete-multiple-create";
import { PapiroConsole } from '../../utils/papiroConsole';

const NoDataRows = _ => {
  return (
    <div className="MuiDataGrid-window" style={{ display: 'flex', justifyContent: 'center', top: 56, overflowY: 'auto' }} >Não há registros a serem exibidos</div>
  )
}

class OrganizationChecklistEdit extends Component {

    constructor(props) {
        super(props);
        const { userSession, organizationchecklists, organizations } = this.props;
        const intl = props.intl;

        this.fromProps = false
        if(this.props && this.props.location && this.props.location.state && this.props.location.state.from && this.props.location.state.from.length > 0)
            this.fromProps = true

        this.globalAdm = false;
        this.isClient = true;
        if (userSession.user.userrole == null) {
            Api.logoff();
        }

        if(!organizationchecklists || (organizationchecklists && !organizationchecklists.organizationchecklist)) {
            var l = this.props.match.params.lang;
            document.location.href=`/${l}/calleds`
        }

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false;
            }
            if (item.role.roletype == 0) {
                this.globalAdm = true;
            }
        });

        this.initialState = {
            UpdatedUser: userSession.user.id,
            id: organizationchecklists.organizationchecklist.id,
            OrganizationId: organizationchecklists.organizationchecklist.OrganizationId,
            OrganizationCategoryId: 0,
            CategoryText: '',
            OrganizationText: organizationchecklists.organizationchecklist.OrganizationText,
            title: organizationchecklists.organizationchecklist.title == null ? '' : organizationchecklists.organizationchecklist.title,
            description: organizationchecklists.organizationchecklist.description == null ? '' : organizationchecklists.organizationchecklist.description,
            active: organizationchecklists.organizationchecklist.active,
            saveToAllOrganizationsInGroup: false,
            type: organizationchecklists.organizationchecklist.type,
            Category: 0,
            CategoryId: [],
        };

        this.state = {
            userid: userSession.user.id,
            organizationchecklist: this.initialState,
            organizations: organizations,
            files: [],
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            OrganizationList: [],
            OrganizationCategoriesList: [],
            displayTypes: organizations && organizations.organization && organizations.organization.caninforminitialchecklist != null ? organizations.organization.caninforminitialchecklist : false,
            TypeList: [
                { id: 1, name: this.props.intl.formatMessage({id:"initial"}) },
                { id: 2, name: this.props.intl.formatMessage({id:"final"}) },
                { id: 3, name: this.props.intl.formatMessage({id:"initial.and.final"}) }
            ],

            CategoryListForDelete: "",
            CategoryListForSelect: [],
            CategoryList: organizationchecklists.organizationchecklist.OrganizationCategories,
            CategoryListSelect: [],
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.closeNotification = this.closeNotification.bind(this);
        this.changeValues = this.changeValues.bind(this);
        this.getByOrganizationWithoutIncludes = this.getByOrganizationWithoutIncludes.bind(this)
        this.getOrganizationCategories = this.getOrganizationCategories.bind(this)
        this.categoryDeleteModal = this.categoryDeleteModal.bind(this)
        this.openCategoryInsertModal = this.openCategoryInsertModal.bind(this)
        this.saveCategoryInsertModal = this.saveCategoryInsertModal.bind(this)
        this.setCategoryDelete = this.setCategoryDelete.bind(this)
        this.closeOrgModal = this.closeOrgModal.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    async componentDidMount() {
        if (this.state.organizations && this.state.organizations.organization && this.state.organizations.organization.id) {
            this.getOrganizationsById(this.state.organizations.organization.id);
            this.getOrganizationCategories(this.state.organizations.organization.id);
            //this.getDepartamentsByOrganizationCategoryId(this.state.organizationchecklist.OrganizationCategoryId)
        }
        else {
            this.getOrganizations();
            this.getOrganizationCategories(this.state.organizations.organization.id);
            //this.getDepartamentsByOrganizationCategoryId(this.state.organizationchecklist.OrganizationCategoryId)
        }
    };
    async getOrganizations() {
        const intl = this.props.intl;
        this.setState({ loading: true });
        var result = await Service.getOrganizations();
        if (result.success) {
            this.setState({ loading: false, OrganizationList: result.data });

        } else {
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
            Api.kickoff(result);
        }
    };
    async getOrganizationsById(id) {
        const intl = this.props.intl;
        this.setState({ loading: true });
        var result = await Service.getOrganizationsByIdLight(id);
        if (result.success) {
            this.setState({ loading: false, OrganizationList: [result.data] });
            await this.updateOrganization(id);
        } else {
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
            Api.kickoff(result);
        }
    };

    async updateOrganization(id) {
        await this.getByOrganizationWithoutIncludes(id)
        await this.getOrganizationCategories(id)
    }

    async getByOrganizationWithoutIncludes(id) {
        this.setState({ loading: true });

        const intl = this.props.intl;
        var result = await getByOrganizationWithoutIncludes(id);

        if (result.success) {
            if (result.data != null) {
                this.setState({
                    loading: false,
                    displayTypes: result.data.caninforminitialchecklist != null ? result.data.caninforminitialchecklist : false
                });
            }
        } else {
            const { intl } = this.props
            
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
        }
    }

    async getOrganizationCategories(id) {
        const intl = this.props.intl;

        this.setState({ loading: true });

        var result = await CalledService.getCategoriesByOrganization(id);

        if (result.success) {
            if(this.isTec == false){
                if(result.data.length < 1 && id.toString().indexOf(",") == -1 ){
                    if(this.AdmOrganization){
                        this.setState({
                            loading: false, openNotification: true, notificationVariant: "error",
                            notificationMessage: this.props.intl.formatMessage({id:"called.accompaniment.notification.missing.organization.categories"}),
                            notificationLinkMove: "organizationcategories"
                        });
                    }else{
                        this.setState({
                            loading: false, openNotification: true, notificationVariant: "error",
                            notificationMessage: this.props.intl.formatMessage({id:"called.accompaniment.notification.missing.organization.categories"})
                        });
                    }
                }
                
            }
            this.setState({ loading: false, OrganizationCategoriesList: result.data, CategoryListForSelect: result.data });
        }
    };

    async getDepartamentsByOrganizationCategoryId(id) {
        this.setState({ loading: true });
          var result = await getDepartamentsByOrganizationCategoryId(id);
            if (result.success) {
              this.setState({ loading: false, DepartamentList: result.data });
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);  
            }
      }

    handleInputChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        this.setState(prevState => ({
            organizationchecklist: {
                ...prevState.organizationchecklist,
                [name]: value
            }
        }));
    };

    handleSubmit = async e => {
        e.preventDefault();

        this.setState({ loading: true });
        const { userSession } = this.props;

        let data = new FormData();


        if (this.isClient) {
            this.setState(prevState => ({
                organizationchecklist: {
                    ...prevState.organizationchecklist,
                }
            }));
        } else {
            this.setState(prevState => ({
                organizationchecklist: {
                    ...prevState.organizationchecklist,
                }
            }));
        }

        let idsStringCategory = "";
        if (this.state.CategoryList.length > 0) {
            this.state.CategoryList.forEach(category => idsStringCategory += `${category.id},`)
            idsStringCategory = idsStringCategory.slice(0, -1);
        }
        data.append("organizationcategoriesid", idsStringCategory);


        for (let key in this.state.organizationchecklist) {
            if(key.toString().toLowerCase().indexOf('id') > -1) {
                data.append(key, this.state.organizationchecklist[key]);
            }else if(key.toString().toLowerCase().indexOf('organizationid') > -1) {
                data.append(key, this.state.organizationchecklist[key]);
            }else if(key.toString().toLowerCase().indexOf('organizationcategoryid') > -1) {
                
            }else if(key.toString().toLowerCase().indexOf('UpdatedUser') > -1) {
                data.append(key, this.state.userid);
            }else if(key.toString().toLowerCase().indexOf('title') > -1) {
                data.append(key, this.state.organizationchecklist[key]);
            }else if(key.toString().toLowerCase().indexOf('description') > -1) {
                data.append(key, this.state.organizationchecklist[key]);
            }else if(key.toString().toLowerCase().indexOf('type') > -1) {
                data.append(key, this.state.organizationchecklist[key]);
            }else if(key.toString().toLowerCase().indexOf('active') > -1) {
                data.append(key, this.state.organizationchecklist[key]);
            }else if(key.toString().toLowerCase().indexOf('savetoallorganizationsingroup') > -1) {
                data.append(key, this.state.organizationchecklist[key]);
            }
        }

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };
        Api.put('/organizationchecklists', data, config)

            .then(result => {
                const intl = this.props.intl;
                if (result.data.success) {
                    const formattedList = result.data.data;
                    formattedList.OrganizationId = formattedList.organization ? formattedList.organization.id : 0;
                    formattedList.OrganizationText = formattedList.organization ? formattedList.organization.name : '';

                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: this.props.intl.formatMessage({id:"edit.success"}),
                        files: []
                    });
                    this.props.addCurrentOrganization(formattedList, this.state.organizations.organization);
                    this.componentDidMount();

                }
                else {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: result.data.errors && result.data.errors[0] ? result.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });

                }
            })
            .catch(err => {
                const intl = this.props.intl;
                if (err.response && err.response.data && err.response.data.errors) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                }
                else {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"}) + err.response.data
                    });
                }
            });
    };

    closeNotification() {
        this.setState({ openNotification: false });
    }

    changeValues(stateName, value, text = '') {
        this.setState({ loading: true });

        this.setState(prevState => ({
            organizationchecklist: {
                ...prevState.organizationchecklist,
                [stateName]: value
            }
        }));
        if (stateName === 'OrganizationId') {
            this.updateOrganization(value)
            this.setState(prevState => ({
                organizationchecklist: {
                    ...prevState.organizationchecklist,
                    ['OrganizationText']: text,
                }
            }));
        }else if(stateName === "CategoryId"){
            this.setState(prevState => ({
              called: {
                ...prevState.called,
                ["CategoryText"]: text
              }
            }));
        }else if(stateName === "OrganizationCategoryId") {
            this.setState(prevState => ({
                organizationchecklist: {
                    ...prevState.organizationchecklist,
                    ['OrganizationCategoryId']: value.id
                }
            }))
        }

        this.setState({ loading: false });

    };

    removeItem(file) {
        let array = [...this.state.files];
        let index = array.indexOf(file)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ files: array });
        }
    }

    categoryDeleteModal = async () => {
        if (this.state.CategoryListForDelete && this.state.CategoryListForDelete.length > 0) {
          this.setState({ loading: true });

          var categoryDeleteList = this.state.CategoryListForDelete.split(",")
    
          let data = new FormData();
          data.append("organizationcategoriesid", categoryDeleteList);
          data.append("organizationchecklistid", this.state.organizationchecklist.id);
    
          const intl = this.props.intl;
          await Api.post('/organizationchecklistorganizationcategory/deleteinorganizationchecklist', data)
            .then(result => {
    
              if (result.data.success) {
                var categories = [];
      
                result.data.data.map(item => {
                  item.organizationcategory.name = item.organizationcategory.parentname && item.organizationcategory.parentname.length > 0 ? item.organizationcategory.parentname : item.organizationcategory.name;
                  categories.push(item.organizationcategory)
                })
    
                this.setState({
                  loading: false,
                  CategoryList: categories,
                  openNotification: true,
                  notificationVariant: 'success',
                  notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
                })
              }
              else {
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                  notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
    
                });
                Api.kickoff(result);
              }
    
            })
            .catch(err => {
              if (err.response && err.response.data && err.response.data.errors) {
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                  notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
              }
              else {
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                  notificationMessage: intl.formatMessage({ id: "process.error" })
                });
              }
              Api.kickoff(err);
            });
        } else {
          this.setState({
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: this.props.intl.formatMessage({id:"select.location"})
          })
        }
      }
    
      openCategoryInsertModal = async () => {
        this.setState({
          openCategoryInsertModal: !this.state.openCategoryInsertModal
        })
      }
    
      saveCategoryInsertModal = async () => {
    
        if(this.state.organizationchecklist.CategoryId != null && this.state.organizationchecklist.CategoryId.length > 0){
          
          this.setState({
            loading: true
          })
      
          let data = new FormData();
      
          var categoryIds = this.state.organizationchecklist.CategoryId.map(item => {
            return item.id + ","
          })
      
          data.append("organizationcategoriesid", categoryIds);
          data.append("organizationchecklistid", this.state.organizationchecklist.id);
      
          const intl = this.props.intl;
          await Api.post('/organizationchecklistorganizationcategory/addinorganizationchecklist', data)
            .then(result => {
              if (result.data.success) {
                var categories = [];
      
                result.data.data.map(item => {
                  item.organizationcategory.name = item.organizationcategory.parentname && item.organizationcategory.parentname.length > 0 ? item.organizationcategory.parentname : item.organizationcategory.name;
                  categories.push(item.organizationcategory)
                })
      
                this.setState({
                  loading: false,
                  CategoryList: categories,
                  openNotification: true,
                  notificationVariant: 'success',
                  notificationMessage: intl.formatMessage({ id: "add.success" }),
                })
                
              }
              else {
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                  notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      
                });
                Api.kickoff(result);
              }
      
            })
            .catch(err => {
              if (err.response && err.response.data && err.response.data.errors) {
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                  notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
              }
              else {
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                  notificationMessage: intl.formatMessage({ id: "process.error" })
                });
              }
              Api.kickoff(err);
            });
      
          this.setState({
            openCategoryInsertModal: !this.state.openCategoryInsertModal,
            loading: false
          })
        }
      }
    
      setCategoryDelete = async (org) => {
        this.setState({
            CategoryListForDelete: org + "," + this.state.CategoryListForDelete
        })
      }
    
      closeOrgModal = () => this.setState({ openCategoryInsertModal: !this.state.openCategoryInsertModal })

    render() {
        const { classes, headerMenu, actionsHeaderMenu, organizationchecklists } = this.props;
        const { organizationchecklist } = this.state;
        const intl = this.props.intl;

        if(Api == null) {
            var l = this.props.match.params.lang;
            document.location.href=`/${l}/unavailable`
          }

        return (
            <div className={classes.root}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    }, classes.actionsContent, {
                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='md'>
                    <Dialog
                        fullWidth
                        maxWidth="md"
                        open={this.state.openCategoryInsertModal}
                        onClose={this.closeCategoryModal}
                        aria-labelledby="form-dialog-title-org-mat"
                        PaperProps={{
                            style: {
                            overflow: 'hidden'
                            }
                        }} >
                        <DialogTitle id="form-dialog-title-org-mat">
                            {this.props.intl.formatMessage({id:"organization.checklist.edit.new.categories"})}
                            <ClearIcon onClick={this.openCategoryInsertModal} style={{ float: 'right', cursor: 'pointer' }} />
                        </DialogTitle>
                        <DialogContent>
                            <Grid item xs={12} sm={12} >
                                <Grid container spacing={2} style={{ padding:"5px" }}>
                                <Grid item xs={12} sm={12}>
                                    < SimpleAutoCompleteMultiple
                                    label={this.props.intl.formatMessage({id:"categories"})}
                                    options={this.state.CategoryListForSelect}
                                    stateName="CategoryId"
                                    changeSelect={this.changeValues}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} style={{ justifyContent: 'flex-end' }}>
                                    <Grid spacing={2} container justify="flex-end" style={{ padding: 15, justifyContent: 'flex-end' }}>
                                        <Grid item xs={12} sm={4}>
                                            <Button
                                                type="button"
                                                onClick={this.openCategoryInsertModal}
                                                fullWidth >
                                                {this.props.intl.formatMessage({id:"cancel"})}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Button
                                                type='submit'
                                                fullWidth
                                                variant='contained'
                                                color='primary'
                                                onClick={this.saveCategoryInsertModal}
                                            >
                                                {<FormattedMessage id="save" />}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        </Dialog>

                        <CssBaseline />
                        <div className={classes.paper} style={{ marginTop: 0 }}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={4}>
                                    <Button variant='outlined' color='primary' onClick={() => {
                                        this.fromProps ? this.props.history.push(`/${this.props.match.params.lang}/organization-edit`, { organizations: this.props.organizations, propTab: 5 }) : browserHistory.goBack()}
                                    }>{this.props.intl.formatMessage({id:"back"})}</Button>
                                </Grid>
                                <Grid item xs={12} sm={8} />
                            </Grid>
                            <Avatar className={classes.avatar}>
                                <QueuePlayNext />
                            </Avatar>
                            <Typography component='h1' variant='h5'>
                                {<FormattedMessage id="edit.item.checklist" />}
                                <Title />
                            </Typography>
                            <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <SimpleSelect label={this.props.intl.formatMessage({id:"organization"})+':'} name='organizationid' options={this.state.OrganizationList} stateName='OrganizationId' changeSelect={this.changeValues} selected={organizationchecklist.OrganizationId} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                    <Accordion style={{marginTop: "6px", marginBottom: "10px"}}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography className={classes.heading}>{intl.formatMessage({id:"categories"})}*</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2} xs={12} sm={12} >

                                                <Grid xs={12} sm={12} style={{ margin: '5px', padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }} >
                                                    <Grid xs={12} sm={4} >

                                                    </Grid>
                                                    <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                                                    <DialogConfirmation
                                                        msgButton={"Remover categoria"}
                                                        msg={this.props.intl.formatMessage({id:"organization.checklist.edit.remove.category.checklist"})}
                                                        msgDisagree={this.props.intl.formatMessage({id:"cancel"})}
                                                        msgAgree={this.props.intl.formatMessage({id:"yes"})}
                                                        handleConfirmation={e => this.categoryDeleteModal()}
                                                        classes={classes}
                                                        icon={<DeleteIcon />}
                                                    />
                                                    </Grid>
                                                    <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        className={classes.button}
                                                        startIcon={<AddIcon />}
                                                        onClick={e => this.openCategoryInsertModal()} >
                                                        {this.props.intl.formatMessage({id:"add.categories"})}
                                                    </Button>
                                                    </Grid>
                                                </Grid>

                                                <Grid xs={12} sm={12}
                                                    style={{
                                                    marginLeft: 5,
                                                    marginBottom: 5,
                                                    border: '3px solid #eee'
                                                    }} >
                                                    <div style={{ height: 350 }}>
                                                    <DataGrid
                                                        style={{ fontSize: 14, color: '#000' }}
                                                        className="DataGridStyle"
                                                        components={{
                                                        NoRowsOverlay: NoDataRows,
                                                        Toolbar: GridToolbarFilterButton,
                                                        }}
                                                        rows={this.state.CategoryList}
                                                        rowsPerPageOptions={[5]}
                                                        checkboxSelection
                                                        onSelectionModelChange={itm => {
                                                        if (itm.length > 0) {
                                                            this.setCategoryDelete(itm);
                                                        } else {
                                                            this.setState({
                                                            CategoryListForDelete: [],
                                                            })
                                                        }
                                                        }}
                                                        columns={[
                                                        { headerName: this.props.intl.formatMessage({id:"menu.categories"}), field: 'name', width: 500 },
                                                        ]}
                                                        HeadersVisibility="None"
                                                    />
                                                    </div>
                                                </Grid>
                                                </Grid>

                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    {this.state.displayTypes === true ? (
                                    <Grid item xs={12} sm={12}>
                                        <SimpleSelect label='Tipo:' name='type' options={this.state.TypeList} stateName='type' changeSelect={this.changeValues} selected={organizationchecklist.type} />
                                    </Grid>
                                    ) :
                                    (
                                    <Grid item xs={12} sm={12} style={{ display: 'none' }}>

                                    </Grid>
                                    )}
                                    <Grid item xs={12} sm={12}>
                                        <TextField inputProps={{ maxLength: 255 }} fullWidth label={this.props.intl.formatMessage({id:"title"})} autoComplete='fname' variant='outlined' name='title' onChange={this.handleInputChange} value={organizationchecklist.title} required />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                    <TextField inputProps={{ maxLength: 1000 }} fullWidth label={this.props.intl.formatMessage({id:"description"})} autoComplete='fname' variant='outlined' name='description' onChange={this.handleInputChange} value={organizationchecklist.description} multiline='true' rows='4' />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <SimpleCheckBox label={this.props.intl.formatMessage({id:"active"})} name='active' stateName='active' changeSelect={this.changeValues} selected={organizationchecklist.active} />
                                    </Grid>
                                    <Grid item xs={11} sm={11}>
                                        <SimpleCheckBox
                                        label={intl.formatMessage({id:"apply.changes.to.all.organizations.in.group"})}
                                        name='saveToAllOrganizationsInGroup'
                                        stateName='saveToAllOrganizationsInGroup' 
                                        changeSelect={this.changeValues}
                                        selected={organizationchecklist.saveToAllOrganizationsInGroup} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                                        <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                                            <Grid item xs={12} sm={4}    >
                                                <Button 
                                                type='submit'
                                                fullWidth
                                                variant='contained'
                                                color='primary'
                                                className={classes.submit}
                                                >
                                                {<FormattedMessage id="save" />}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>

                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
                <Footer />
            </div>
        );
    }
}
OrganizationChecklistEdit.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    organizations: state.organizations,
    organizationchecklists: state.organizationchecklists
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(OrganizationChecklistEdit)));
