import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import { useChatHubContext } from '../../contexts/chatHubContext';
import Badge from '@mui/material/Badge';
import { Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "./styles.scss";
import { PapiroConsole } from '../../utils/papiroConsole';
import CustomizedTooltips from '../customized-tooltip';
import { ChatInformations } from '../chat-informations';

export default function ChatListOptionsInProgress() {
  const userSession = useSelector((state) => state.userSession);
  const {
    openChatList,
    chatHub,
    currentTechnicianChat,
    currentClientChat
  } = useChatHubContext();
  
  
  const [selectedItem, setSelectedItem] = React.useState(openChatList && openChatList.length == 1 ? openChatList[0].id : null);

  const updateCurrentChat = async (chat) => {
    PapiroConsole.log(openChatList)
    PapiroConsole.log(chat)
    PapiroConsole.log("chat updateCurrentChat")

    if (chat) {
      await chatHub.invoke("ResetNotSeenMessages", chat.id, userSession.user.id);
      await chatHub.invoke("LoadChatMessages", chat.id);

    }
  };

  const standardChat = async (chatId) => {

    if (chatId) {
      await chatHub.invoke("ResetNotSeenMessages", chatId, userSession.user.id);
      await chatHub.invoke("LoadChatMessages", chatId);

    }
  };


  useEffect(() => {
    if(selectedItem){
      standardChat(selectedItem)
    }
  }, [selectedItem]);

  const currentChatItemStyle = (chatId, list) => {


    if ((currentTechnicianChat && chatId === currentTechnicianChat.id) || (currentClientChat && chatId == currentClientChat.id)) {
      return "current-chat-style"
    }

    else {
      return ""
    }
  }

  return (


    <List>
      {openChatList && openChatList.length == 1 && openChatList.map((chat) => (
        <Grid container >
          <ListItem className={`chat-list-item ${currentChatItemStyle(chat.id, openChatList)}`} key={chat.id}  selected={selectedItem === chat.id} onClick={() => updateCurrentChat(chat)} >
            <ListItemIcon>
              {/* <Avatar> */}
              <Badge badgeContent={userSession.user.id != chat.assignedUserId ? chat.notSeenByRequestingUser : chat.notSeenByAssignedUser} color="primary">
                <AccountCircleIcon />
              </Badge>
              {/* </Avatar> */}
            </ListItemIcon>
            {/* //TODO colocar a última mensagem em secondary */}
            <ListItemText style={{ overflow: 'hidden', color:'black' }} primary={userSession.user.id != chat.assignedUserId ? chat.assignedUser.name + ` (#${chat.id})` : chat.requestingUser.name + ` (#${chat.id})`} />
            {/* <ListItemText primary={ chat.requestingUser.name } secondary={ "last message" } /> */}
            <ChatInformations createdAt={chat.createdAt} />
          </ListItem>

        </Grid>





      ))}
      {openChatList && openChatList.length > 1 && openChatList.map((chat) => (
        <Grid container >
          <ListItem className={`chat-list-item ${currentChatItemStyle(chat.id, openChatList)}`} key={chat.id} onClick={() => updateCurrentChat(chat)} >
            <ListItemIcon>
              {/* <Avatar> */}
              <Badge badgeContent={userSession.user.id != chat.assignedUserId ? chat.notSeenByRequestingUser : chat.notSeenByAssignedUser} color="primary">
                <AccountCircleIcon />
              </Badge>
              {/* </Avatar> */}
            </ListItemIcon>
            {/* //TODO colocar a última mensagem em secondary */}
            <ListItemText style={{ overflow: 'hidden', color:'black' }} primary={userSession.user.id != chat.assignedUserId ? chat.assignedUser.name + ` (#${chat.id})` : chat.requestingUser.name + ` (#${chat.id})`} />
            {/* <ListItemText primary={ chat.requestingUser.name } secondary={ "last message" } /> */}
            <ChatInformations createdAt={chat.createdAt} />
          </ListItem>

        </Grid>





      ))}


      {openChatList && openChatList.length == 0 &&
        <Grid style={{ marginLeft: '5px' }}>
          <FormattedMessage id="Não há conversas a serem exibidas" />
        </Grid>


      }

    </List>



  );
}