import React, { Component } from "react"
import clsx from 'clsx'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from "prop-types"
import { CssBaseline, CircularProgress, Container, Button, Grid } from "@mui/material"
import { withStyles } from "@mui/styles"
import { Styles } from "../../styles/material-styles"
import { Overlay } from "../../styles/global"
import CustomizedSnackbars from "../../components/material-snackbars"
import HeaderMenu from "../../components/header-menu"
import MyMaterialTable from '../../components/table'
import Api from "../../services/api"
import Pagination from '@mui/material/Pagination';
import * as calledsActions from '../../redux/actions/calleds'
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import Footer from '../../components/footer'
import moment from "moment"
import { FormattedMessage } from 'react-intl'
import SimpleCheckBox from "../../components/checkbox/check";
import { injectIntl } from 'react-intl'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import SimpleDatePicker from '../../components/date-pickers/inline-no-today'
import SimpleAutoComplete from '../../components/auto-complete/autocomplete'
import SimpleAutoCompleteOrganizationCategories from '../../components/auto-complete/autocomplete organization-categories'

import 'jspdf-autotable'
import LogoMarca from '../../images/new-logo-cmtech.png'
import Divider from '@mui/material/Divider'
import * as ExcelJS from 'exceljs'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ApiReports from "../../services/apireport";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PapiroConsole } from "../../utils/papiroConsole"
import SimpleAutoCompleteMultiple from "../../components/auto-complete/autocomplete-multiple-create";
import fileDownload from 'js-file-download'
import * as TeamsService from '../../services/techinicalteamorganizationcategory.service';

class OrganizationsCategoriesReport extends Component {

  constructor(props) {
    super(props)

    this.isClient = true
    this.isAdm = false
    this.typingTimeout = null
    this.organizationId = null

    const { userSession, intl } = this.props


    if (userSession == null || userSession.user == null || userSession.user.userrole == null)
      Api.logoff()

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3)
        this.isClient = false
      if (item.role.roletype == 0 || item.role.roletype == 1)
        this.isAdm = true
    });

    if (userSession.user.organization)
      this.organizationId = userSession.user.organization.id

    this.state = {

      dateStart: moment().subtract(1, 'months').format('YYYY/MM/DD HH:mm:ss'),
      dateEnd: moment().format('DD/MM/YYYY HH:mm:ss'),
      organizationId: this.organizationId,
      rolesFilter: [],
      activeFilter: 0,
      removingInternalAgents: true,
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      showGrid: false,
      calledsList: [],
      OrgsList: [],
      TechinicalTeamList: [],
      OrganizationFilter: [],
      TechinicalTeamFilter: { id: 0, name: '' },
      totalPages: 0,
      page: 1,
      pageStart: 0,
      pageEnd: 0,
      count: 0,
      searchValue: '',
      organizationCategoryParent :  0,
      order : 0, 

      showHeader: false,
      RolesList: [],
      orderList: [{ id: 1, name: intl.formatMessage({ id: "name" }) },
      { id: 2, name: intl.formatMessage({ id: "organization.category.parent.name" }) },
      { id: 3, name: intl.formatMessage({ id: "category.parent.id" }) }
      ],

      OrganizationCategoryParentList : [],


      columns: [],
      columnsExcell: [],

    }
  }




  changeValues = async (stateName, value, text = '') => {
    this.setState({ loading: true })


    if (stateName === 'order') {

      this.setState({ [stateName]: value.id });

    }

    else if (stateName === 'organizationCategoryParent') {

      this.setState({ [stateName]: value.id });

    }
    else {
      PapiroConsole.log(value)
      PapiroConsole.log("value")
      this.setState({ [stateName]: value });
    }


    this.setState({ loading: false })
  };

  Reload = () => {
    const reload = localStorage.getItem('reload')
    if (reload == "true")
      localStorage.setItem('reload', false)
    return null;
  }

  getOrganizations = async _ => {
    let data = [];
    const { userSession } = this.props
    if (userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
      data = userSession.user.userorganizations
    } else {
      await Api.get('/organizations/tech')
        .then(result => {
          data = result.data.data
        })
        .catch(err => {
          data = err
        });
    }
    this.setState({ OrgsList: data })
  }

  getOrganizationCategories = async _ => {
    let data = [];
    
    
      await Api.get('/organizationcategories/GetAllOrganizationCategories')
        .then(result => {
          data = result.data.data
        })
        .catch(err => {
          data = err
        });
    
    this.setState({ OrganizationCategoryParentList: data })
  }


  

  onChangePaginate = (e, page) => {
    this.setState({ page: page })
    this.getCalleds('', page)
  }

  exportPdf = _ => {
    this.getCalledsToPdf()
  }

  setPagination = (count, page) => {
    let totalPage = Math.ceil(count / 10)
    this.setState({ totalPages: totalPage })
    if (count > 0) {
      if (page == 1) {
        if (count < 10)
          this.setState({ pageStart: 1, pageEnd: count })
        else
          this.setState({ pageStart: 1, pageEnd: 10 })
      } else {
        let pageStart = ((page - 1) * 10) + 1
        if (count >= page * 10)
          this.setState({ pageStart: pageStart, pageEnd: page * 10 })
        else
          this.setState({ pageStart: pageStart, pageEnd: count })
      }
    } else
      this.setState({ totalPages: 0, page: 1, count: 0 })
  }



  onFieldChange = event => {
    // Clears the previously set timer.
    clearTimeout(this.typingTimeout)
    // Reset the timer, to make the http call after 475MS (this.callSearch is a method which will call the search API. Don't forget to bind it in constructor.)
    this.typingTimeout = setTimeout(this.callSearch, 475);
    this.setState({ [event.target.name]: event.target.value, page: 1 })
  }

  callSearch = _ => {
    this.getCalleds('', this.state.page)
  }

  getRoles = async _ => {
    let data = [];

    await Api.get('/roles')
      .then(result => {
        data = result.data.data

        data = data.filter(c => c.id != 4);

      })
      .catch(err => {
        data = err
      });

    this.setState({ RolesList: data })
  }

  async componentDidMount() {
    this.setState({ loading: true })

    await this.getOrganizations()

    await this.getOrganizationCategories()

    await this.getTeams()

    this.setState({ loading: false })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "techinicalteamsreport")
      this.getCalleds(this.props.location.state)
    else if (nextProps.location.state === "assignedcalleds")
      this.getCalleds(nextProps.location.state)
    else if (nextProps.location.state === "reload")
      this.componentDidMount()
  }

  async getTeams(OrganizationId = 0) {
    var result = null
    if (OrganizationId > 0) {
      result = await TeamsService.getTechinicalTeamsByOrganization(OrganizationId);
    } else {
      result = await TeamsService.getTechinicalTeams()
    }
    if (result.success) {
      this.setState({ TechinicalTeamList: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  getFile = async () => {

    const intl = this.props.intl;

    this.setState({ loading: true });

    var dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
    var dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')


    var organizations = ""
    if (this.state.OrganizationFilter != null && this.state.OrganizationFilter.length > 0) {
      let organizationsIds = this.state.OrganizationFilter.map(c => c.id);
      organizations = organizationsIds.join(',');
    }
    let url = `/report/organizationscategories/excel?organizations=${organizations}&techteam=${this.state.TechinicalTeamFilter.id}&organizationcategoryparent=${this.state.organizationCategoryParent}&order=${this.state.order}`


      ApiReports.get(url, {
        responseType: 'blob'
      })
        .then((response) => {

          //   let url = window.URL.createObjectURL(new Blob([response.data]));
          //saveAs(url, 'called_list.csv');

          fileDownload(response.data, `export_organizations_categories.zip`);

          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
        });

    



  }

  getColumns() {


    const intl = this.props.intl;
    var columns =
      [
        { title: intl.formatMessage({ id: "id" }), field: 'id' },
        { title: intl.formatMessage({ id: "name" }), field: 'name' },
        { title: intl.formatMessage({ id: "category.parent.name.report" }), field: 'parent_name_category' },
        { title: intl.formatMessage({ id: "organization" }), field: 'organization' },
        
        { title: intl.formatMessage({ id: "organization.category.sla" }), field: 'sla_name' },
        { title: intl.formatMessage({ id: "organization.category.appears.on.portal" }), field: 'appears_on_portal' },
        { title: intl.formatMessage({ id: "category.parent.id" }), field: 'parent_id' },
        { title: intl.formatMessage({ id: "organization.category.parent" }), field: 'parent_name' },


        
      ];

    this.setState({ columns: columns })

  }


  getCalleds = async (filter = '', page = 1) => {

    const intl = this.props.intl;

    this.setState({ loading: true });

    var dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
    var dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')


    var organizations = ""
    if (this.state.OrganizationFilter != null && this.state.OrganizationFilter.length > 0) {
      let organizationsIds = this.state.OrganizationFilter.map(c => c.id);
      organizations = organizationsIds.join(',');
    }
    let url = `/report/organizationscategories/paginate?page=${page}&organizations=${organizations}&techteam=${this.state.TechinicalTeamFilter.id}&organizationcategoryparent=${this.state.organizationCategoryParent}&order=${this.state.order}`

    this.setState({ showGrid: true })

    var writing = ""

    ApiReports.get(url)
      .then(result => {
        if (result.data.success) {
          PapiroConsole.log("result aqui formatted")
          PapiroConsole.log(result)
          let count = result.data.count;
          this.setState({ count: count })
          this.setPagination(count, page)

          const formattedList = result.data.data.map(item => {
            /*if (item.active == true) {
              item.active = this.props.intl.formatMessage({ id: "yes" })
            }
            else {
              item.active = this.props.intl.formatMessage({ id: "no" })
            }*/
            return {

              ...item,

            }
          })
          this.setState({ calledsList: formattedList, loading: false })

          this.getColumns()
        } else {
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          })
          Api.kickoff(result)
        }
      })
      .catch(err => {

        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(err)
      })



  }



  /*getCalledsToPdf = (filter = '') => {
    const intl = this.props.intl;
    this.setState({ loading: true })



    let url = `/techinicalteams/getexcell?org=${this.state.rolesFilter}}&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}`

    Api.get(url)
      .then(async (result) => {
        if (result.data.success) {
          PapiroConsole.log(result)
          PapiroConsole.log("result")
          await this.setState({ showHeader: true })
          const formattedList = result.data.data.map(item => {

            return {
              ...item,
              Name: item.name,
              TotalUsers: item.total_users,
              Organizacoes: item.organizacoes,
            }
          })

          const intl = this.props.intl
          const doc = new jsPDF("p", "mm", "a4")
          const header = document.getElementById('header-pdf')
          let headerCanvas = await html2canvas(header)
          const headerImg = headerCanvas.toDataURL('image/png')
          doc.addImage(headerImg, 'PNG', 0, 0)

          doc.autoTable({
            body: formattedList,
            margin: { top: 30 },
            bodyStyles: { valign: 'middle' },
            styles: { overflow: 'linebreak', columnWidth: 'wrap', fontSize: 7, halign: 'center' },
            theme: 'striped',
            columns: [
              { header: intl.formatMessage({ id: "name" }), dataKey: 'Name' },
              { header: intl.formatMessage({ id: "total.users" }), dataKey: 'TotalUsers' },
              { header: intl.formatMessage({ id: "menu.organizations" }), dataKey: 'Organizacoes' },


            ],
            columnStyles: {
              Name: {
                columnWidth: 30
              },
              TotalUsers: {
                columnWidth: 30
              },
              Organizacoes: {
                columnWidth: 30
              },

            }
          })

          doc.save("relatorio-times-enviados.pdf")
          this.setState({ loading: false, showHeader: false })
        } else {
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          })
          Api.kickoff(result)
        }
      })
      .catch(err => {
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(err)
      })
  }*/

  handleSubmit = async e => {
    const intl = this.props.intl;
    e.preventDefault();
    this.setState({ loading: true });
    await this.getCalleds(this.props.location.state)
  }


  closeNotification = _ => {
    this.setState({ openNotification: false });
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props
    const intl = this.props.intl

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    const tableList = () => {
      return (
        <>

          <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
            <Accordion >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>{intl.formatMessage({ id: "filters" })}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container style={{ marginBottom: 20 }} spacing={2}>



                  <Grid item xs={12} sm={6} md={3} >
                    <SimpleAutoCompleteMultiple label={intl.formatMessage({ id: "organization" })} options={this.state.OrgsList} stateName='OrganizationFilter' changeSelect={this.changeValues} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3} >
                    <SimpleAutoCompleteOrganizationCategories label={intl.formatMessage({ id: "organization.category.parent" })} options={this.state.OrganizationCategoryParentList} stateName='organizationCategoryParent' changeSelect={this.changeValues} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3} >
                    <SimpleAutoComplete label={intl.formatMessage({ id: "productivity.dashboard.techinical.teams" })} options={this.state.TechinicalTeamList} stateName='TechinicalTeamFilter' selected={this.state.TechinicalTeamFilter} changeSelect={this.changeValues} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3} >
                    <SimpleAutoComplete label={intl.formatMessage({ id: "order.by" })} options={this.state.orderList} stateName='order' changeSelect={this.changeValues} />
                  </Grid>

                  
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Grid container style={{ marginBottom: 10 }}>
              <Grid item xs={12} sm={2} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='secondary' >
                  {<FormattedMessage id="filter" />}
                </Button>
              </Grid>

              {this.state.showGrid && <Grid item xs={12} sm={5} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                <Button
                  fullWidth
                  variant='contained'
                  color='secondary'
                  onClick={this.getFile} >
                  {<FormattedMessage id="export.plan" />}
                </Button>
              </Grid>}

              {5 == 2 && this.state.showGrid && <Grid item xs={12} sm={5} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                <Button
                  fullWidth
                  variant='contained'
                  color='secondary'
                  onClick={this.getCalledsToPdf} >
                  {<FormattedMessage id="dash.pdf" />}
                </Button>
              </Grid>}
            </Grid>
          </form>

          {
            this.state.showGrid && <>
              <MyMaterialTable
                title=""
                columns={
                  this.state.columns
                }
                data={this.state.calledsList} />

              <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                <Grid item xs={12} md={3}>
                  <Pagination count={this.state.totalPages} page={this.state.page} onChange={this.onChangePaginate} />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                  {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                </Grid>
              </Grid>


            </>
          }
        </>
      );
    }

    return (
      <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })} >
          <div className={classes.drawerHeader} />
          <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft: 0, marginLeft: 0 }}>
            <CssBaseline />
            {this.state.showHeader &&
              <Grid container>
                <Grid id="header-pdf" item lg={4} sm={12} xl={this.state.grid} xs={12} style={{ padding: '2px', minWidth: 600 }}>
                  <Card >
                    <CardContent>
                      <img src={LogoMarca} style={{ width: "70px" }} /> <span style={{ marginLeft: 25, fontSize: 18 }}> {<FormattedMessage id="calleds.origin.categories.report" />}</span>
                      <Divider />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            }

            <Grid container spacing={6}>
              <Grid item xs={12} sm={12}>
                <Card >
                  <CardContent className="background-title-search">
                    <Typography variant="h5">
                      {<FormattedMessage id="organizations.categories.report" />}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>


            {tableList()}


            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification} />
          </Container>
        </main>
        <Footer />
      </div>
    )
  }
}

OrganizationsCategoriesReport.propTypes = {
  classes: PropTypes.object.isRequired
}


const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(calledsActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(OrganizationsCategoriesReport)))
OrganizationsCategoriesReport.contextType = AbilityContext