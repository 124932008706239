import AddIcon from '@mui/icons-material/Add';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { Button, Card, CardContent, CircularProgress, Container, CssBaseline, Grid, Pagination, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import FirstElement from '../../components/first-element';
import Footer from '../../components/footer';
import HeaderMenu from '../../components/header-menu';
import CustomizedSnackbars from '../../components/material-snackbars';
import MyMaterialTable from '../../components/table';
import { AbilityContext } from '../../config/ability-context';
import * as componentActions from '../../redux/actions/integration-user-variables.js';
import * as IntegrationUserVariableService from "../../services/integrationuservariable.service.js";
import Api from '../../services/api';
import { Overlay } from '../../styles/global';
import { Styles } from '../../styles/material-styles';

const useStyles = makeStyles(Styles);

const IntegrationUserVariableList = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { lang } = useParams();

  const [loading, setLoading] = useState(false);

  const [openNotification, setOpenNotification] = useState(false);
  const [notificationVariant, setNotificationVariant] = useState('error');
  const [notificationMessage, setNotificationMessage] = useState('');

  const [resultList, setResultList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [pageStart, setPageStart] = useState(0);
  const [pageEnd, setPageEnd] = useState(0);
  const [count, setCount] = useState(0);

  const [searchValue, setSearchValue] = useState('');
  const [dataFilter, setDataFilter] = useState(false);

  const headerMenu = useSelector(state => state.headerMenu);
  const actionsHeaderMenu = useSelector(state => state.actionsHeaderMenu);
  const userSession = useSelector(state => state.userSession);
  const abilityContext = useContext(AbilityContext);

  useEffect(() => {
    if (location.state === "reload") {
      getItens();
    }
  }, [location.state]);

  useEffect(() => {
    getItens();
  }, []);

  const getItens = async (page = 1) => {
    setLoading(true);

    try {
      const output = await IntegrationUserVariableService.getAllPaginate(page);

      if (output.success) {
        const isEmptyArray = output.data.length === 0;
        const itemCount = output.count;
        setCount(itemCount);
        setPagination(itemCount, page);
        const formattedList = output.data.map(item => ({ ...item }));
        setResultList(formattedList);
        setDataFilter(isEmptyArray);

      } else {
        fetchDataNotSuccess(output);
        Api.kickoff(output);
      }

    } catch (err) {
      fetchDataThrowError(err);
      Api.kickoff(err);

    } finally {
      setLoading(false);
    }
  };

  const fetchDataNotSuccess = (result) => {
    setLoading(false);
    setOpenNotification(true);
    setNotificationVariant("error");
    setNotificationMessage(result.data?.response?.data?.errors?.[0] || intl.formatMessage({ id: "process.error" }));
  };

  const fetchDataThrowError = (err) => {
    setLoading(false);
    setOpenNotification(true);
    setNotificationVariant("error");
    setNotificationMessage(err.response?.data?.errors?.[0] || intl.formatMessage({ id: "process.error" }));
  };

  const setPagination = (count, page) => {
    const totalPage = Math.ceil(count / 10);
    setTotalPages(totalPage);

    if (count > 0) {
      if (page === 1) {
        setPageStart(1);
        setPageEnd(10);
      } else {
        const start = ((page - 1) * 10) + 1;
        setPageStart(start);
        setPageEnd(count >= page * 10 ? page * 10 : count);
      }
    } else {
      setTotalPages(0);
      setPage(1);
      setCount(0);
    }
  };

  const onChangePaginate = (e, page) => {
    setPage(page);
    getItens(page);
  };

  const callSearch = () => {
    if (searchValue !== '') getItens(page);
  };

  const onFieldChange = (event) => {
    setSearchValue(event.target.value);
    setPage(1);
  };

  const closeNotification = () => {
    setOpenNotification(false);
  };

  const deleteItem = async (data, resolve) => {
    try {
      const output = await IntegrationUserVariableService.deleteById(data.id);

      if (output.success) {
        setNotificationVariant('success');
        setNotificationMessage(intl.formatMessage({ id: "delete.success" }));
        setResultList([]);
        await getItens();
      } else {
        fetchDataNotSuccess(output);
        Api.kickoff(output);
      }
      resolve();

    } catch (err) {
      resolve();
      fetchDataThrowError(err);
      Api.kickoff(err);
    }
  };

  const clearFields = () => {
    setSearchValue('');
    setPage(1);
    setLoading(true);
    getItens(1);
  };

  if (Api == null) {
    document.location.href = `/${lang}/unavailable`;
  }

  return (
    <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
      <HeaderMenu />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: headerMenu.open,
        }, classes.actionsContent, {
          [classes.actionscontentShift]: actionsHeaderMenu.open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Container component='main' maxWidth='false' style={{ paddingLeft: 0, marginLeft: 0 }}>
          {loading && (
            <Overlay>
              <CircularProgress color='secondary' />
            </Overlay>
          )}

          {(!dataFilter) ? (
            <div>
              <CssBaseline />
              <Grid container className={classes.mb20} spacing={0}>
                <Button
                  variant='contained'
                  color='secondary'
                  size='large'
                  className={classes.button}
                  startIcon={<AddIcon />}
                  disabled={!abilityContext.can('Add', 'Group')}
                  component={Link} 
                  {
                    ...{ 
                      to: `/${lang}/variable-registration` 
                    }
                  }
                >
                  <FormattedMessage id="add" />
                </Button>
              </Grid>

              <Grid container spacing={6} alignItems="center">
                <Grid item xs={12} sm={12}>
                  <Card className="background-title-search">
                    <CardContent>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} sm={5} md={3} lg={6}>
                          <Typography variant="h5">
                            <FormattedMessage id="integration.user.variable.header" />
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <MyMaterialTable
                title=""
                rowClick={(event, rowData) => {
                  dispatch(componentActions.addCurrent(rowData));
                  history.push(`/${lang}/variable-edit`);
                }}
                columns={[
                  { title: intl.formatMessage({ id: "id" }), field: 'id' },
                  { title: intl.formatMessage({ id: "name" }), field: 'name' },
                  { title: "", field: "" },
                  { title: "", field: "" },
                  { title: "", field: "" },
                  { title: "", field: "" },
                ]}
                data={resultList}
                deleteItem={deleteItem}
              />

              <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                <Grid item xs={12} md={3}>
                  <Pagination count={totalPages} page={page} onChange={onChangePaginate} />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                  {pageStart} &nbsp;
                  <FormattedMessage id="to" /> &nbsp;
                  {pageEnd} &nbsp;
                  <FormattedMessage id="of" /> &nbsp;
                  {count} &nbsp;
                  <FormattedMessage id="register" />
                </Grid>
              </Grid>

            </div>
          ) : (
            <div>
              <FirstElement
                title={intl.formatMessage({ id: "integration.user.variable.list.create.first.integration.user.variable" })}
                subtitle={intl.formatMessage({ id: "integration.user.variable.list.create.integration.user.variable" })}
                buttonText={
                  <Button
                    variant='contained'
                    color='secondary'
                    size='large'
                    className={classes.button}
                    startIcon={<AddIcon />}
                    component={Link} {...{ to: `/${lang}/variable-registration` }}
                  >
                    <FormattedMessage id="add" />
                  </Button>
                }
                icon={<SmartToyIcon style={{ fontSize: "45px" }} />}
              />
            </div>
          )}

          {loading && (
            <Overlay>
              <CircularProgress color='secondary' />
            </Overlay>
          )}

          <CustomizedSnackbars
            variant={notificationVariant}
            message={notificationMessage}
            isOpen={openNotification}
            toClose={closeNotification}
          />
        </Container>
      </main>
      <Footer />
    </div>
  );
};

IntegrationUserVariableList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default IntegrationUserVariableList;
