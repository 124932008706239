import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../../../../styles/material-styles';
import { Overlay } from '../../../../../styles/global';
import Api from '../../../../../services/api';
import CustomizedSnackbars from '../../../../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../../../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleSelect from '../../../../../components/select/simple';
import SimpleCheckBox from '../../../../../components/checkbox/check';
import SimpleAutoComplete from '../../../../../components/auto-complete/autocomplete-org';
import MyCheckList from "../../../../../components/checklist/checklist";
import SimpleDatePicker from '../../../../../components/date-pickers/inline';
import * as Service from '../../../../../services/additionalcalledcostitemtypes.service';
import * as AdditionalCalledCostItemService from '../../../../../services/additionalcalledcostitems.service'
import * as OrganizationChecklistService from '../../../../../services/organizationchecklist.service';
import { parseJSON } from 'date-fns';
import Footer from '../../../../../components/footer';
import { browserHistory } from '../../../../../helpers/history';
import { FormattedMessage, injectIntl } from 'react-intl';
import Title from '../../../../../components/title-name';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import { PapiroConsole } from '../../../../../utils/papiroConsole';

class AdditionalCalledCostItemTypeRegistration extends Component {

    constructor(props) {
        super(props);
        const { userSession, organizations } = this.props;

        this.fromProps = false
        if(this.props && this.props.location && this.props.location.state && this.props.location.state.from && this.props.location.state.from.length > 0)
            this.fromProps = true

        this.globalAdm = false;
        this.isClient = true;

        if (userSession.user.userrole == null) {
            Api.logoff();
        }

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype !== 3) {
                this.isClient = false;
            }
            if (item.role.roletype === 0) {
                this.globalAdm = true;
            }
        }
        );

        this.initialState = {
            createdUser: userSession.user.id,
            createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
            OrganizationId: 0,
            OrganizationText: '',
            mame: '',
            active: false,
            saveToAllOrganizationsInGroup: false,
        };

        this.state = {
            item: this.initialState,
            organizations: organizations,
            files: [],
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            OrganizationList: [],
            AdditionalCalledCostItemList: [],
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.closeNotification = this.closeNotification.bind(this);
        this.changeValues = this.changeValues.bind(this);

    } // Fim - construtor

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    async componentDidMount() {
        if (this.state.organizations && this.state.organizations.organization && this.state.organizations.organization.id && this.fromProps){
          this.getOrganizationsById(this.state.organizations.organization.id);
        }
        else {
          this.getOrganizations();
        }
    };

    onChange = (selected) => {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          selected: selected
        }
      }))
    }

    getOrganizations = async _ => {
        this.setState({ loading: true });
        var result = await OrganizationChecklistService.getOrganizationsSimple();

        if (result.success) {
            this.setState({ loading: false, OrganizationList: result.data });

        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    };

    getOrganizationsById = async id => {

        this.setState({ loading: true });
        var result = await OrganizationChecklistService.getOrganizationsByIdLight(id);
        if (result.success) {
            this.setState({ loading: false, OrganizationList: [result.data] });
            await this.updateOrganization(id);
        } else {
            const intl = this.props.intl;

            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });

            Api.kickoff(result);
        }
    };

    async updateOrganization(id) {
      //await this.getOrganizationCategories(id)
    }

    handleInputChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        this.setState(prevState => ({
            item: {
                ...prevState.item,
                [name]: value
            }
        }));
    };

    handleSubmit = async e => {
        const intl = this.props.intl;
        e.preventDefault();

        PapiroConsole.log("=== SUBMIT ===")
        PapiroConsole.log(this.state)

        if(this.state.item.OrganizationId == null || this.state.item.OrganizationId == 0 || (this.state.item.OrganizationId && this.state.item.OrganizationId.id && this.state.item.OrganizationId.id == 0)) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({id:"organization.calendar.registration.missing.organization.field"})
            });
            return false
        }
        if(this.state.item.name == null || this.state.item.name.length == 0) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({id:"organization.checklist.registration.missing.title"})
            });
            return false
        }

        this.setState({ loading: true });

        let data = new FormData();
        
        if (this.state.item.AdditionalCalledCostItemId === 0) {

            const intl = this.props.intl;
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({id:"additional.called.cost.edit.missing.service.item.field"}),
               
            });

        } else {
            if (this.isClient) {
                this.setState(prevState => ({
                    item: {
                        ...prevState.item,
                    }
                }));
            } else {
                this.setState(prevState => ({
                    item: {
                        ...prevState.item,
                    }
                }));
            }

            for (let key in this.state.item) {
                if(key.toString().toLowerCase().indexOf('organizationid') > -1) {
                  data.append(key, this.state.item[key].id);
                }else if(key.toString().toLowerCase().indexOf('createduser') > -1) {
                  data.append(key, this.state.item[key]);
                }else if(key.toString().toLowerCase().indexOf('createdat') > -1) {
                  data.append(key, this.state.item[key]);
                }else if(key.toString().toLowerCase().indexOf('savetoallorganizationsingroup') > -1) {
                  data.append(key, this.state.item[key]);
                }else if(key.toString().toLowerCase().indexOf('name') > -1) {
                  data.append(key, this.state.item[key]);
                }else if(key.toString().toLowerCase().indexOf('active') > -1) {
                    data.append(key, this.state.item[key]);
                }
            }

            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };

            let response = await Service.addItem(data)
            if (response && response.success) {
              const intl = this.props.intl;
              this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'success',
                  notificationMessage: intl.formatMessage({ id: "add.success" }),
                  item: this.initialState,
                  files: []
              });
            } else {
              const intl = this.props.intl
              this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                  notificationMessage: response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
              })
              Api.kickoff(response)
            }
        }



    };

    closeNotification() {
        this.setState({ openNotification: false });
    }

    changeValues(stateName, value, text = '') {
        this.setState({ loading: true });

        this.setState(prevState => ({
            item: {
                ...prevState.item,
                [stateName]: value
            }
        }));

        if (stateName === 'AdditionalCalledCostItemId') {
            this.updateOrganization(value)
            this.setState(prevState => ({
                item: {
                    ...prevState.item,
                    ['AdditionalCalledCostItemText']: text,
                }
            }));
        }

        this.setState({ loading: false });
    };

    render() {
        const { classes, headerMenu, actionsHeaderMenu } = this.props;
        const { item } = this.state;
        const intl = this.props.intl;

        if(Api == null) {
            var l = this.props.match.params.lang;
            document.location.href=`/${l}/unavailable`
          }

        return (
            <div className={classes.root}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    }, classes.actionsContent, {
                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                    })}
                >

                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='md'>
                        <CssBaseline />
                        <div className={classes.paper} style={{ marginTop: 0 }}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={4}>
                                    <Button variant='outlined' color='primary' onClick={() => {
                                        this.fromProps ? this.props.history.push(`/${this.props.match.params.lang}/organization-edit`, { organizations: this.props.organizations, propTab: 12, propServiceTab: 1 }) : this.props.history.push(`/${this.props.match.params.lang}/additional-service-config`, { propServiceTab: 1 })}
                                    }>{<FormattedMessage id="back" />}</Button>
                                </Grid>
                                <Grid item xs={12} sm={8} />
                            </Grid>
                            <Avatar className={classes.avatar}>
                                <QueuePlayNext />
                            </Avatar>
                            <Typography component='h1' variant='h5'>
                                {<FormattedMessage id="additional.called.cost.type.add" />}
                                <Title />
                            </Typography>
                            <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={12}>
                                      <SimpleAutoComplete
                                        label={intl.formatMessage({ id: "organization" })}
                                        options={this.state.OrganizationList}
                                        stateName='OrganizationId'
                                        changeSelect={this.changeValues}
                                        selected={item.OrganizationId}
                                        isRequired required  />
                                  </Grid>
                                  <Grid item xs={12} sm={12}>
                                    <TextField
                                      inputProps={{ maxLength: 255 }}
                                      fullWidth
                                      label={intl.formatMessage({ id: "title" })}
                                      autoComplete='fname'
                                      variant='outlined'
                                      name='name'
                                      onChange={this.handleInputChange}
                                      value={item.name}
                                      required  />
                                  </Grid>
                                  <Grid item xs={12} sm={12}>
                                    <SimpleCheckBox
                                      label={intl.formatMessage({ id: "active" })}
                                      name='active'
                                      stateName='active'
                                      changeSelect={this.changeValues}
                                      selected={item.active} />
                                  </Grid>
                                  <Grid item xs={11} sm={11}>
                                    <SimpleCheckBox
                                      label={intl.formatMessage({id:"save.to.all.organizations.in.group"})}
                                      name='saveToAllOrganizationsInGroup'
                                      stateName='saveToAllOrganizationsInGroup' 
                                      changeSelect={this.changeValues}
                                      selected={item.saveToAllOrganizationsInGroup} />
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                                        <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                                            <Grid item xs={12} sm={4}    >
                                                <Button 
                                                type='submit'
                                                fullWidth
                                                variant='contained'
                                                color='primary'
                                                className={classes.submit}
                                                >
                                                {<FormattedMessage id="save" />}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>

                        </div>
                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>

                </main>
            </div>
        )
    }
}

AdditionalCalledCostItemTypeRegistration.propTypes = { classes: PropTypes.object.isRequired };

const mapStateToProps = state => ({
    actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    organizations: state.organizations,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(AdditionalCalledCostItemTypeRegistration)));