import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { AbilityContext } from '../../config/ability-context'
import Base from '../../components/layout-base/base';
import OrganizationFieldsContent from './index.content';



import * as componentActions from '../../redux/actions/organizationfields';
class OrganizationFields extends Component {

    constructor(props) {
        super(props);


    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    componentDidMount() {
    }


    render() {


        return (
            <Base props={this.props}>
                <OrganizationFieldsContent props={this.props} />

            </Base>
        );
    }
}
OrganizationFields.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(OrganizationFields)); OrganizationFields.contextType = AbilityContext;
