
import React, { Component } from "react";
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import { CssBaseline, CircularProgress, Container, Button, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import CustomizedSnackbars from "../../components/material-snackbars";
import HeaderMenu from "../../components/header-menu";
import MaterialTable from 'material-table';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import MyMaterialTable from '../../components/table';
import Api from "../../services/api";
import * as CalledService from '../../services/called.service';
import * as calledsActions from '../../redux/actions/calleds';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import Footer from '../../components/footer';
import moment from "moment";
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { getSlaAtendimento, getSlaSolucao } from '../called-list/called.functions';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import SimpleDatePicker from '../../components/date-pickers/inline-no-today';
import { saveAs } from 'file-saver';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import Pagination from '@mui/material/Pagination';
import Search from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import LogoMarca from '../../images/new-logo-cmtech.png';
import html2canvas from 'html2canvas';
import Divider from '@mui/material/Divider'
import * as ExcelJS from 'exceljs'
import { convertDoubleToHours } from "../../utils/hourUtils";
import { getAllSelectionModelWithColorHex } from "../../services/status.service";
import { PapiroConsole } from "../../utils/papiroConsole";
import { getExcelColumnsByIndex } from "../../utils/excelUtils";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import ApiReports from "../../services/apireport";
import fileDownload from 'js-file-download'
import * as Service from '../../services/techinicalteamorganizationcategory.service';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            minWidth: 'max-content',
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
        [`& .${tooltipClasses.popper}`]: {
            position: 'absolute',
            inset: '0 auto auto 0',
            margin: 0,
            left: '-37%',
            transform: 'translate(1147px, 286px)'
        }
    }))

class CalledReports extends Component {

    constructor(props) {
        super(props);
        this.Reload = this.Reload.bind(this);
        this.getFile = this.getFile.bind(this);
        this.Reload();

        this.typingTimeout = null;

        const { userSession, intl } = this.props;

        this.isClient = true;
        //this.isAdm = false;
        this.isAdmOrCoord = false;
        this.isTechinical = false;
        
        this.slaOptions = [
            { id: 1, name: intl.formatMessage({id:"all"}) },
            { id: 2, name: 'Sla Estourado' },
            { id: 3, name: 'Sla Vigente' }
        ]

        if (userSession == null ||  userSession.user == null || userSession.user.userrole == null) {
            Api.logoff();
        }

        /*userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false;
            }
            if (item.role.roletype == 0 || item.role.roletype == 1) {
                this.isAdm = true;
            }
        }
        );*/

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false;
            }
            if (item.role.roletype == 0 || item.role.roletype == 1 || item.role.roletype == 4 ) {
                this.isAdmOrCoord = true;
            }
            
            if (item.role.roletype == 2) {
                this.isTechinical = true;
            }
        });
        this.organizationId = null;
        if (userSession.user.organization) {
            this.organizationId = userSession.user.organization.id;
        }

        this.tableColumnsInitialState = [
            { title: intl.formatMessage({id:"id"}), field: 'id' },
            { title: intl.formatMessage({ id: "category" }), field: 'OrganizationCategory' },
            { title: intl.formatMessage({ id: "last.move.date" }), field: 'lastAction' },
            { title: intl.formatMessage({ id: "open.date" }), field: 'openingdate' },
			{ title: intl.formatMessage({ id: "solution.date" }), field: 'solutiondate' },
            { title: intl.formatMessage({ id: "title" }), field: 'title' },
            { title: intl.formatMessage({ id: "requesting.user.name" }), field: 'requestingusername' },
            { title: intl.formatMessage({ id: "requesting.user.email" }), field: 'requestinguseremail' },
            { title: intl.formatMessage({ id: "requesting.user.document" }), field: 'requestinguserdocument' },
            { title: intl.formatMessage({ id: "assigned" }), field: 'assignedUser' },
            { title: intl.formatMessage({id:"menu.statuses"}), field: 'statusName' },
            { title: intl.formatMessage({ id: "organization" }), field: 'Organization' },
            { title: intl.formatMessage({ id: "departament" }), field: 'userdepartament' },
            { title: intl.formatMessage({ id: "menu.techinicalteams" }), field: 'tecnteams' },
            { title: intl.formatMessage({ id: "location" }), field: 'location' },
            { title: intl.formatMessage({ id: "called.time.entries.registration.attendance.time" }), field: 'calledTime' },
            { title: intl.formatMessage({ id: "sla.service" }), field: 'slaAtendimento' },
            { title: intl.formatMessage({ id: "sla.solution" }), field: 'slaSolucao' },
            { title: intl.formatMessage({ id: "wasbaduse" }), field: 'baduse' },
            { title: intl.formatMessage({ id: "calledclosingstatus" }), field: 'calledClosingStatus' },
        ]

        this.state = {
            tableColumns: this.tableColumnsInitialState,
            dateStart: moment().format('YYYY/MM/DD HH:mm:ss'),
            dateEnd: moment().format('YYYY/MM/DD HH:mm:ss'),
            organizationId: this.organizationId,
            OrganizationFilter: 0,
            TechinicalTeamFilter: { id: 0, name: '' },
            filterByDate: { id: 1, name: 'Data de abertura' },
            StatusFilter: 0,
            location: 0,
            slafiltersolution: 0,
            slafilterservice: 0,
            techuser: { id: 0, name: '' },
            departament: 0,
            loading: false,
            openNotification: false,
            notificationVariant: "error",
            notificationMessage: "",
            showGrid: false,
            calledsList: [],
            organizationList: [],
            statusList: [],
            organizationAddressesList: [],
            techUsersList: [],
            organizationDepartamentsList: [],
            calledPdfList: [],
            filterByDateList: [
                { id: 1, name: 'Data de abertura' },
                { id: 2, name: 'Data da solução'}
            ],
            totalPages: 0,
            page: 1,
            pageStart: 0,
            pageEnd: 0,
            count: 0,
            searchValue: '',
            showHeader: false,
            TechinicalTeamList: [],
        };
        this.closeNotification = this.closeNotification.bind(this);
        this.changeValues = this.changeValues.bind(this);

        this.callSearch = this.callSearch.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.exportPdf = this.exportPdf.bind(this);
        this.getCalledsToPdf = this.getCalledsToPdf.bind(this);
        this.clear = this.clear.bind(this);
        this.getTechinicalTeams = this.getTechinicalTeams.bind(this);
    }

    async changeValues(stateName, value, text = '') {
        this.setState({ loading: true });
        PapiroConsole.log(stateName)
        PapiroConsole.log(value)
        if (stateName === 'location' || stateName === 'departament' || stateName == 'slafiltersolution' || stateName == 'slafilterservice' || stateName == 'StatusFilter') {
            this.setState({ [stateName]: value.id });
        }

        else if (stateName === 'OrganizationFilter') {
            this.setState({ [stateName]: value.id });
            await this.getOrganizationAddresses(value.id);
            await this.getOrganizationDepartaments(value.id);
            await this.GetUsersTechList(value.id)
            if (!this.state.techUsersList.find(t => t.id == this.state.techuser)) {
              this.setState({ techuser: { id: 0, name: '' } })
            }
            
            await this.getTechinicalTeams(value.id)
            if (!this.state.TechinicalTeamList.find(t => t.id == this.state.TechinicalTeamFilter.id)) {
              this.setState({ TechinicalTeamFilter: { id: 0, name: '' } })
            }

        } else if (stateName === 'TechinicalTeamFilter') {
            this.setState({ [stateName]: value });
            if (value.id > 0) {
                await this.GetUsersTechList(this.state.OrganizationFilter, value.id)
                if (!this.state.techUsersList.find(u => u.id == this.state.techuser)) {
                  this.setState({ techuser: { id: 0, name: '' } })
                }
            } else {
                this.setState({ techuser: { id: 0, name: '' }, techUsersList: [] })
                await this.GetUsersTechList(this.state.OrganizationFilter)
            }
        } else {
            this.setState({ [stateName]: value });
        }
        this.setState({ loading: false });


    };

    Reload = () => {
        const reload = localStorage.getItem('reload');
        if (reload == "true") {
            localStorage.setItem('reload', false);


        }
        return null;
    }

    onChangePaginate = (e, page) => {
        this.setState({ page: page });
        this.getCalledsNew('', page);
    }

    exportPdf() {
        this.getCalledsToPdf();
    }

    setPagination(count, page) {

        let totalPage = Math.ceil(count / 10);
        this.setState({ totalPages: totalPage })
        if (count > 0) {
            if (page == 1) {
                if (count < 10) {
                    this.setState({ pageStart: 1, pageEnd: count });
                } else {
                    this.setState({ pageStart: 1, pageEnd: 10 });
                }
            } else {
                let pageStart = ((page - 1) * 10) + 1;
                if (count >= page * 10) {
                    this.setState({ pageStart: pageStart, pageEnd: page * 10 });
                } else {
                    this.setState({ pageStart: pageStart, pageEnd: count });
                }
            }
        } else {
            this.setState({ totalPages: 0, page: 1, count: 0 });
        }

    }

    async getOrganizationAddresses(organizationId) {
        this.setState({ loading: true });

        const result = await CalledService.getOrganizationAddresses(organizationId);

        if (result.success) {
            if (this.isTec == false) {
                if (result.data.length < 1) {
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: this.props.intl.formatMessage({id:"called.accompaniment.notification.organization.no.registered.address"})
                    });
                }
            }

            this.setState({ loading: false, organizationAddressesList: result.data });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);

        }
    };

    async GetUsersTechList(organizationId, techinicalTeamId = 0) {
        this.setState({ loading: true });

        var result = {}
        if (techinicalTeamId == 0) {
            result = await CalledService.UsersTechReportListNew(organizationId);
        } else {
            result = await CalledService.GetUsersByOrgAndTeam(organizationId, techinicalTeamId)
        }

        if (result.success) {
            this.setState({ loading: false, techUsersList: result.data });
        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);

        }
    };

    async getOrganizationDepartaments(organizationId) {
        this.setState({ loading: true });

        var organizationDepartamentsList = null

        const result = await CalledService.getOrganizationDepartaments(organizationId);



        if (result.success) {



            if (result.data) {

                organizationDepartamentsList = result.data
                this.setState({ loading: false, organizationDepartamentsList: organizationDepartamentsList });
            }
            else {
                organizationDepartamentsList = []
            }




        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);

        }
    };

    onFieldChange(event) {
        // Clears the previously set timer.
        clearTimeout(this.typingTimeout);

        // Reset the timer, to make the http call after 475MS (this.callSearch is a method which will call the search API. Don't forget to bind it in constructor.)
        this.typingTimeout = setTimeout(this.callSearch, 475);


        this.setState({ [event.target.name]: event.target.value, page: 1 });

    }

    callSearch() {
        this.getCalledsNew('', this.state.page);
    }

    async getOrganizations() {
        let data = [];
        const { userSession } = this.props
        if(userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
            data = userSession.user.userorganizations
        } else {
            await Api.get('/organizations/tech')
                .then(result => {
                    data = result.data.data
                })
                .catch(err => {
                    data = err;
                });
        }
        this.setState({ organizationList: data })
    };

    async getAllStatus() {
        let result = await getAllSelectionModelWithColorHex();
        if (result && result.success) {
            if (result.data != null)
                this.setState({ statusList: result.data })
        } else {
            const intl = this.props.intl
            this.setState({
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
        }
    }

    deleteItem(data, resolve) {
        const intl = this.props.intl;
        let abl = this.context.can('Delete', 'Called');
        if (abl == false) {
            this.setState({ loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: this.props.intl.formatMessage({id:"operation.notAllowed"}) });
            resolve(); return false;
        }
        Api.delete(`/calledreports/${data.id}`)

            .then(result => {
                if (result.data.success) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: intl.formatMessage({id:"called.list.notification.delete.success"}),
                        resultList: []
                    });

                    this.getCalledsNew();

                }
                else {
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(result);
                }
                resolve();
            })
            .catch(err => {
                resolve();
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(err);
            });


    };



    async componentDidMount() {
        this.setState({ loading: true })
        await this.getOrganizations();
        await this.getAllStatus()
        await this.getTechinicalTeams()
        if(this.isAdmOrCoord){
            await this.GetUsersTechList(0);
        }
        
        this.setState({ loading: false })


    }
    componentWillReceiveProps(nextProps) {

        //if (nextProps.location.search.indexOf("assignedcalleds") > -1) {
        if (nextProps.location.state === "calledReports") {
            // do stuffs
            this.getCalledsNew(this.props.location.state);
        }
        else if (nextProps.location.state === "assignedcalleds") {
            this.getCalledsNew(nextProps.location.state);
        }
        else if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }
    async getFile() {

        this.setState({ loading: true });

        let dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
        let dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')


        if (dateStart > dateEnd) {
            const { intl } = this.props
            this.setState({
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({id:"report.audit.initial.date.higher.than.end.date"}),
                loading: false
            });
        } else {

                let url = `/report/calleds/excel?filterByDate=${this.state.filterByDate && this.state.filterByDate.id ? this.state.filterByDate.id : 1}&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&org=${this.state.OrganizationFilter}&techteam=${this.state.TechinicalTeamFilter.id}&status=${this.state.StatusFilter}&loc=${this.state.location}&dep=${this.state.departament}&slasolution=${this.state.slafiltersolution}&slaservice=${this.state.slafilterservice}&techuser=${this.state.techuser.id}`

                ApiReports.get(url, {
                responseType: 'blob'
                })
                .then((response) => {

                    //   let url = window.URL.createObjectURL(new Blob([response.data]));
                    //saveAs(url, 'called_list.csv');

                    fileDownload(response.data, `relatorio_chamados_${moment(this.state.dateStart).format('YYYY-MM-DD')}_${moment(this.state.dateEnd).format('YYYY-MM-DD')}.zip`);

                    this.setState({ loading: false });
                })
                .catch((error) => {
                    this.setState({ loading: false });
                });

    }

        /*this.setState({
            openNotification: true,
            notificationVariant: 'info',
            notificationMessage: this.props.intl.formatMessage({id:"routine.in.maintenance"})
        })*/

        /*this.setState({ loading: true });
        let url = `/calledreports/getpdforexcell`;
        if (this.state.searchValue != '') {
            url += `?search=${this.state.searchValue}`
        }

        url += `?filterByDate=${this.state.filterByDate && this.state.filterByDate.id ? this.state.filterByDate.id : 1}&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&org=${this.state.OrganizationFilter}&status=${this.state.StatusFilter}&loc=${this.state.location}&dep=${this.state.departament}&slasolution=${this.state.slafiltersolution}&slaservice=${this.state.slafilterservice}&techuser=${this.state.techuser}`;

        if (this.state.filterByDate == null || typeof this.state.filterByDate === 'undefined')
            this.setState({ filterByDate: { id: 1, name: 'Data de abertura' } })

        Api.get(url)
            .then(async (response) => {
                PapiroConsole.log(response)
                PapiroConsole.log("response")
                let orgFiledsMenu = []
                const formattedList = response.data.data.map(item => {

                    let lastAction = "";
                    let tecteams = "";

                    let orgFields = {}
                    if (item.calledorganizationfields != null) {
                        item.calledorganizationfields.forEach((calledOrgField, idx) => {
                            let id = calledOrgField && calledOrgField.organizationfield && calledOrgField.organizationfield.id ? calledOrgField.organizationfield.id: 0
                            let name = ""
                            let value = ""

                            if (calledOrgField && calledOrgField.value != null)
                                value = calledOrgField.value
                            if (calledOrgField && calledOrgField.organizationfield && calledOrgField.organizationfield.name != null)
                                name = calledOrgField.organizationfield.name

                            let existsItemMeu = orgFiledsMenu.find(menuItem => menuItem.header == name)
                            if (existsItemMeu == null || typeof existsItemMeu === 'undefined')
                                orgFiledsMenu.push({ header: name, key: `orgfield${id}`, width: 25 })
                            orgFields[`orgfield${id}`] = value
                        })
                    }

                    if (item.techinicalteam != null && item.techinicalteam.name != null)
                        tecteams = item.techinicalteam.name

                    if (item.accompaniments && item.accompaniments.length > 0) {
                        lastAction = item.accompaniments[item.accompaniments.length - 1].actiontime;
                    } else {
                        lastAction = item.openingdate;
                    }
					if(item.lastaction){
						lastAction=item.lastaction;
					}


                    let writing = ""

                    writing = this.getMessageExcellBadUseFlag(item.baduse, item.status == null ? 0 : item.status.finished)

                    return {
                        ...item,
                        baduse: writing,
                        typeName: item.type == null ? 0 : item.type.name,
                        openingdate: moment(item.openingdate).format('DD/MM/YYYY'),
                        openinghour: moment(item.openingdate).format('HH:mm'),
                        solutiondate: moment(item.solutiondate).format('DD/MM/YYYY HH:mm'),
                        originRequestName: item.originrequest == null ? 0 :  item.originrequest.name,
                        statusName: item.status == null ? 0 : item.status.name,
                        OrganizationCategory: item.organizationcategory ? item.organizationcategory.name : "",
                        Organization: item.organization == null ? "" : item.organization.name,
                        slaAtendimento: item.slaservice != null ? item.slaservice.toFixed(2)+ "%" : getSlaAtendimento(item, this.props.userSession) + "%",
                        slaSolucao: item.slasolution != null ? item.slasolution.toFixed(2)+ "%" : getSlaSolucao(item, this.props.userSession) + "%",
                        assignedUser: item.assigneduser ? item.assigneduser.name : "",
                        requestingUser: item.requestinguser ? item.requestinguser.name : "",
                        lastAction: moment(lastAction).format('DD/MM/YYYY'),
                        lastActionHour: moment(lastAction).format('HH:mm'),
                        tecnteams: tecteams,
                        userdepartament: item.requestinguser && item.requestinguser.organizationdepartament ? item.requestinguser.organizationdepartament.name : "",
                        location: item.address== null ? "" : item.address.name,
                        calledTime: item.calledtime && !isNaN(item.calledtime) ? convertDoubleToHours(item.calledtime) : '00:00:00',
                        ...orgFields
                    }
                });

                const intl = this.props.intl;
                const wb = new ExcelJS.Workbook()

                const ws = wb.addWorksheet()
                //relatório de chamados
                ws.columns = [
                    { header: intl.formatMessage({id:"id"}), key: 'id', width: 25 },
                    { header: intl.formatMessage({ id: "category" }), key: 'OrganizationCategory', width: 25 },
                    { header: intl.formatMessage({ id: "last.move.date" }), key: 'lastAction', width: 25 },
                    { header: intl.formatMessage({ id: "last.move.hour" }), key: 'lastActionHour', width: 25 },
                    { header: intl.formatMessage({ id: "open.date" }), key: 'openingdate', width: 25 },
                    { header: intl.formatMessage({ id: "open.hour" }), key: 'openinghour', width: 25 },
                    { header: intl.formatMessage({ id: "solution.date" }), key: 'solutiondate', width: 25 },
					{ header: intl.formatMessage({ id: "title" }), key: 'title', width: 25 },
                    { header: intl.formatMessage({ id: "requesting.user.name" }), key: 'requestingusername', width: 25 },
                    { header: intl.formatMessage({ id: "requesting.user.email" }), key: 'requestinguseremail', width: 25 },
                    { header: intl.formatMessage({ id: "requesting.user.document" }), key: 'requestinguserdocument', width: 25 },
                    { header: intl.formatMessage({ id: "assigned" }), key: 'assignedUser', width: 25 },
                    { header: intl.formatMessage({ id: "Status" }), key: 'statusName', width: 25 },
                    { header: intl.formatMessage({ id: "organization" }), key: 'Organization', width: 25 },
                    { header: intl.formatMessage({ id: "departament" }), key: 'userdepartament', width: 25 },
                    { header: intl.formatMessage({ id: "menu.techinicalteams" }), key: 'tecnteams', width: 25 },
                    { header: intl.formatMessage({ id: "location" }), key: 'location', width: 25 },
                    { header: intl.formatMessage({ id: "called.time.entries.registration.attendance.time" }), key: 'calledTime', width: 25 },
                    { header: intl.formatMessage({ id: "sla.solution" }), key: 'slaSolucao', width: 25 },
                    { header: intl.formatMessage({ id: "sla.service" }), key: 'slaAtendimento', width: 25 },
                    { header: intl.formatMessage({ id: "wasbaduse" }), key: 'baduse', width: 25 },
                    ...orgFiledsMenu
                ];

                ws.getCell('A1').style = { font: { bold: true } }
                ws.getCell('B1').style = { font: { bold: true } }
                ws.getCell('C1').style = { font: { bold: true } }
                ws.getCell('D1').style = { font: { bold: true } }
                ws.getCell('E1').style = { font: { bold: true } }
                ws.getCell('F1').style = { font: { bold: true } }
                ws.getCell('G1').style = { font: { bold: true } }
                ws.getCell('H1').style = { font: { bold: true } }
                ws.getCell('I1').style = { font: { bold: true } }
                ws.getCell('J1').style = { font: { bold: true } }
                ws.getCell('K1').style = { font: { bold: true } }
                ws.getCell('L1').style = { font: { bold: true } }
                ws.getCell('M1').style = { font: { bold: true } }
                ws.getCell('N1').style = { font: { bold: true } }
                ws.getCell('O1').style = { font: { bold: true } }
                ws.getCell('P1').style = { font: { bold: true } }
                ws.getCell('Q1').style = { font: { bold: true } }
                ws.getCell('R1').style = { font: { bold: true } }
                ws.getCell('S1').style = { font: { bold: true } }
                ws.getCell('T1').style = { font: { bold: true } }
                ws.getCell('U1').style = { font: { bold: true } }
                let cellIndex = 21
                orgFiledsMenu.forEach(element => {
                    ws.getCell(getExcelColumnsByIndex(cellIndex, '1')).style = { font: { bold: true } }
                    cellIndex++
                });

                ws.addRows(formattedList);

                ws.addRow([]);
                const newRowValues = [intl.formatMessage({ id: "export.date" }), moment().format('DD/MM/YYYY HH:mm:ss') ];
                const newRow = ws.addRow(newRowValues);

                const buf = await wb.xlsx.writeBuffer()

                saveAs(new Blob([buf]), 'relatorio_chamados.xlsx')



                this.setState({ loading: false });
            })
            .catch(err => {
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(err);
            });
			;*/
    }

    getMessageBadUseFlag(message) {
        let writing = ""
        if (message === true) {
            writing = this.props.intl.formatMessage({id:"yes"})
        }
        else if (message === false) {
            writing = this.props.intl.formatMessage({id:"no"})
        }
        else {
            writing = this.props.intl.formatMessage({id:"undefined"})
        }

        return writing
    }

    async getTechinicalTeams(OrganizationId = 0) {
        var result = null
        if (OrganizationId > 0) {
            result = await Service.getTechinicalTeamsByOrganization(OrganizationId);
        } else {
            result = await Service.getTechinicalTeams()
        }
        if (result.success) {
            this.setState({ TechinicalTeamList: result.data });
    
        } else {
            const intl = this.props.intl;
            this.setState({
                openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    }


   /* async getCalledsOld(filter = '', page = 1) {
        this.setState({ loading: true });

        let dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
        let dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')


        if (dateStart > dateEnd) {
            const { intl } = this.props
            this.setState({
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({id:"report.audit.initial.date.higher.than.end.date"}),
                loading: false
            });
        } else {
            this.setState({ calledsList: [] })
            let url = `/calledreports/paginate?page=${page}`;
            if (this.isClient) {
                url = `/calledreports/paginate/${this.state.organizationId}`;
            }
            if (this.state.searchValue != '') {
                url += `&search=${this.state.searchValue}`
            }
            if (filter != '') {
                url = url + '&filter=' + filter;

            }

            url += `&filterByDate=${this.state.filterByDate && this.state.filterByDate.id ? this.state.filterByDate.id : 1}&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&org=${this.state.OrganizationFilter}&status=${this.state.StatusFilter}&loc=${this.state.location}&dep=${this.state.departament}&slasolution=${this.state.slafiltersolution}&slaservice=${this.state.slafilterservice}&techuser=${this.state.techuser}`;

            this.setState({ showGrid: true });

            if (this.state.filterByDate == null || typeof this.state.filterByDate === 'undefined')
                this.setState({ filterByDate: { id: 1, name: 'Data de abertura' } })

            let writing = ""



            Api.get(url)
                .then(result => {
                    if (result.data.success) {
                       
                        let count = result.data.count;
                        this.setState({ count: count });
                        this.setPagination(count, page);

                        let orgFiledsMenu = []
                        const formattedList = result.data.data.map(item => {
                            let lastAction = "";
                            let tecteams = "";

                           

                            if (item.techinicalteam != null && item.techinicalteam.name != null)
                                tecteams = item.techinicalteam.name

                            if (item.accompaniments && item.accompaniments.length > 0) {
                                lastAction = item.accompaniments[item.accompaniments.length - 1].actiontime;
                            } else {
                                lastAction = item.openingdate;
                            }
							if(item.lastaction){
								lastAction=item.lastaction;
							}
                            writing = this.getMessageBadUseFlag(item.baduse)

                            return {
                                ...item,
                                baduse: writing,
                                typeName: item.type.name,
                                openingdate: moment(item.openingdate).format('DD/MM/YYYY HH:mm'),
                                solutiondate: item.solutiondate ? moment(item.solutiondate).format('DD/MM/YYYY HH:mm') : "",
                                originRequestName: item.originrequest.name,
                                statusName: item.status.name,
                                OrganizationCategory: item.organizationcategory ? item.organizationcategory.name : "",
                                Organization: item.organization.name,
                                slaAtendimento: item.slaservice ? item.slaservice.toFixed(2) + "%" : getSlaAtendimento(item, this.props.userSession) + "%",
                                slaSolucao: item.slasolution ? item.slasolution.toFixed(2) + "%" : getSlaSolucao(item, this.props.userSession) + "%",
                                assignedUser: item.assigneduser ? item.assigneduser.name : "",
                                requestingUser: item.requestinguser ? item.requestinguser.name : "",
                                lastAction: moment(lastAction).format('DD/MM/YYYY HH:mm'),
                                tecnteams: tecteams,
                                userdepartament: item.organizationdepartament ? item.organizationdepartament.name : "",
                                location: item.address.name,
                                calledTime: item.calledtime && !isNaN(item.calledtime) ? convertDoubleToHours(item.calledtime) : '00:00:00',
                                //organizationFieldsPopup: organizationFieldsPopup,
                            }
                        });

                        PapiroConsole.log("=== orgFiledsMenu ===")
                        PapiroConsole.log(orgFiledsMenu)
                        
                        this.setState({
                            calledsList: formattedList,
                            loading: false,
                            //tableColumns: [...this.tableColumnsInitialState, ...orgFiledsMenu]
                            });
                    }
                    else {
                        this.setState({
                            loading: false, openNotification: true, notificationVariant: 'error',
                            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                        });
                        Api.kickoff(result);
                    }
                })
                .catch(err => {
                    
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(err);

                });

        }




    };*/


    async getCalledsNew(filter = '', page = 1) {
        this.setState({ loading: true });

        let dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
        let dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')


        if (dateStart > dateEnd) {
            const { intl } = this.props
            this.setState({
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({id:"report.audit.initial.date.higher.than.end.date"}),
                loading: false
            });
        } else {
            this.setState({ calledsList: [] })
            let url = `/report/calleds/paginate?page=${page}`;
            if (this.isClient) {
                url = `/calledreports/paginate/${this.state.organizationId}`;
            }
            if (this.state.searchValue != '') {
                url += `&search=${this.state.searchValue}`
            }
            if (filter != '') {
                url = url + '&filter=' + filter;

            }

            url += `&filterByDate=${this.state.filterByDate && this.state.filterByDate.id ? this.state.filterByDate.id : 1}&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&org=${this.state.OrganizationFilter}&techteam=${this.state.TechinicalTeamFilter.id}&status=${this.state.StatusFilter}&loc=${this.state.location}&dep=${this.state.departament}&slasolution=${this.state.slafiltersolution}&slaservice=${this.state.slafilterservice}&techuser=${this.state.techuser.id}`;

            this.setState({ showGrid: true });

            if (this.state.filterByDate == null || typeof this.state.filterByDate === 'undefined')
                this.setState({ filterByDate: { id: 1, name: 'Data de abertura' } })

            let writing = ""



            ApiReports.get(url)
                .then(result => {
                    PapiroConsole.log(result)
                    PapiroConsole.log("resultado entregue aqui")
                    if (result.data.success) {
                       
                        let count = result.data.count;
                        this.setState({ count: count });
                        this.setPagination(count, page);
                        const formattedList = result.data.data.map(item => {
                            
                        writing = this.getMessageBadUseFlag(item.baduse)

                            return {
                                ...item,
                                baduse: writing,
                                //typeName: item.type.name,
                                openingdate: item.opening_date? moment(item.opening_date).format('DD/MM/YYYY HH:mm') : '',
                                solutiondate: item.solution_date ? moment(item.solution_date).format('DD/MM/YYYY HH:mm') : "",
                                originRequestName: item.origin? item.origin : '' ,
                                statusName: item.status? item.status :'' ,
                                OrganizationCategory: item.category ? item.category : '' ,
                                requestingusername: item.requesting_user_name ? item.requesting_user_name : '' ,
                                requestinguseremail: item.requesting_user_email ? item.requesting_user_email : '' ,
                                requestinguserdocument: item.requesting_user_document ? item.requesting_user_document : '' ,
                                Organization: item.organization ? item.organization : '',
                                slaAtendimento: item.sla_service ? item.sla_service.toFixed(2) + "%" : 0,
                                slaSolucao: item.sla_solution ? item.sla_solution.toFixed(2) + "%" : 0,
                                assignedUser: item.assigned ? item.assigned : "",
                                lastAction:item.last_action_date ? moment(item.last_action_date).format('DD/MM/YYYY HH:mm') : '',
                                tecnteams: item.team ? item.team : '' ,
                                userdepartament: item.organization_departament ? item.organization_departament : "",
                                location: item.location ?  item.location : '' ,
                                calledTime: item.attendance_time != null && item.attendance_time != '' ? item.attendance_time   : '00:00:00',
                                calledClosingStatus: item.called_closing_status ? item.called_closing_status : "",
                                
                            }
                        });

                      
                        
                        this.setState({
                            calledsList: formattedList,
                            loading: false,
                            //tableColumns: [...this.tableColumnsInitialState, ...orgFiledsMenu]
                            });
                    }
                    else {
                        this.setState({
                            loading: false, openNotification: true, notificationVariant: 'error',
                            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                        });
                        Api.kickoff(result);
                    }
                })
                .catch(err => {
                    
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(err);

                });

        }




    };

    getMessageExcellBadUseFlag(message, status) {
        let writing = ""
        if (status) {
            if (message === true) {
                writing = this.props.intl.formatMessage({id:"yes"})
            }
            else if (message === false) {
                writing = this.props.intl.formatMessage({id:"no"})
            }
        }
        else {
            writing = this.props.intl.formatMessage({id:"undefined"})

        }


        return writing
    }

    clear(){
		//this.setState({ calledsList: [], loading: false, });
		document.location.reload();

	}
    getCalledsToPdf(filter = '') {

        this.setState({
            openNotification: true,
            notificationVariant: 'info',
            notificationMessage: this.props.intl.formatMessage({id:"routine.in.maintenance"})
        })

        /*this.setState({ loading: true });
        let url = `/calledreports/getpdforexcell`;
        if (this.state.searchValue != '') {
            url += `?search=${this.state.searchValue}`
        }

        url += `?filterByDate=${this.state.filterByDate && this.state.filterByDate.id ? this.state.filterByDate.id : 1}&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&org=${this.state.OrganizationFilter}&status=${this.state.StatusFilter}&loc=${this.state.location}&dep=${this.state.departament}&slasolution=${this.state.slafiltersolution}&slaservice=${this.state.slafilterservice}&techuser=${this.state.techuser}`;

        if (this.state.filterByDate == null || typeof this.state.filterByDate === 'undefined')
            this.setState({ filterByDate: { id: 1, name: 'Data de abertura' } })

        Api.get(url)
            .then(async (result) => {
                if (result.data.success) {


                    let columnStylesData = {
                        id: {
                            columnWidth: 10,
                        },
                        OrganizationCategory: {
                            columnWidth: 26,
                        },
                        lastAction: {
                            columnWidth: 17,
                        },
                        lastActionHour: {
                            columnWidth: 17,
                        },
                        openingdate: {
                            columnWidth: 16,
                        },
                        solutiondate: {
                            columnWidth: 16,
                        },						
                        title: {
                            columnWidth: 45,
                        },
                        requestingusername: {
                            columnWidth: 19,
                        },
                        requestinguseremail: {
                            columnWidth: 19,
                        },
                        requestinguserdocument: {
                            columnWidth: 19,
                        },
                        assignedUser: {
                            columnWidth: 19,
                        },
                        statusName: {
                            columnWidth: 16,
                        },
                        Organization: {
                            columnWidth: 18,
                        },
                        userdepartament: {
                            columnWidth: 20,
                        },
                        tecnteams: {
                            columnWidth: 16,
                        },
                        calledTime: {
                            columnWidth: 19,
                        },
                        slaAtendimento: {
                            columnWidth: 16,
                        },
                        slaSolucao: {
                            columnWidth: 16,
                        },
                        location: {
                            columnWidth: 18,
                        },
                        baduse: {
                            columnWidth: 15,
                        }
                    }

                    await this.setState({ showHeader: true })
                    let orgFiledsMenu = []
                    const formattedList = result.data.data.map(item => {
                        let lastAction = "";
                        let tecteams = "";

                        let orgFields = {}
                        if (item.calledorganizationfields != null) {
                            item.calledorganizationfields.forEach((calledOrgField, idx) => {
                                let id = calledOrgField && calledOrgField.organizationfield && calledOrgField.organizationfield.id ? calledOrgField.organizationfield.id: 0
                                let name = ""
                                let value = ""

                                if (calledOrgField && calledOrgField.value != null)
                                    value = calledOrgField.value
                                if (calledOrgField && calledOrgField.organizationfield && calledOrgField.organizationfield.name != null)
                                    name = calledOrgField.organizationfield.name

                                let existsItemMeu = orgFiledsMenu.find(menuItem => menuItem.header == name)
                                if (existsItemMeu == null || typeof existsItemMeu === 'undefined')
                                    orgFiledsMenu.push({ header: name, dataKey: `orgfield${id}` })
                                if (!columnStylesData.hasOwnProperty(`orgfield${id}`))
                                    columnStylesData[`orgfield${id}`] = { columnWidth: 19 }
                                orgFields[`orgfield${id}`] = value
                            })
                        }

                        if (item.techinicalteam != null && item.techinicalteam.name != null)
                            tecteams = item.techinicalteam.name

                        if (item.accompaniments && item.accompaniments.length > 0) {
                            lastAction = item.accompaniments[item.accompaniments.length - 1].actiontime;
                            
                        } else {
                            lastAction = item.openingdate;
                        }
						if(item.lastaction){
							lastAction=item.lastaction;
						}
                        let writing = ""
                        writing = this.getMessageBadUseFlag(item.baduse)

                        return {
                            ...item,
                            baduse: writing,
                            typeName: item.type.name,
                            openingdate: moment(item.openingdate).format('DD/MM/YYYY HH:mm'),
                            solutiondate: item.solutiondate ? moment(item.solutiondate).format('DD/MM/YYYY HH:mm') : "",
                            originRequestName: item.originrequest.name,
                            statusName: item.status.name,
                            OrganizationCategory: item.organizationcategory ? item.organizationcategory.name : "",
                            Organization: item.organization.name,
                            slaAtendimento: item.slaservice != null ? item.slaservice.toFixed(2) + "%" : getSlaAtendimento(item, this.props.userSession) + "%",
                            slaSolucao: item.slasolution != null ? item.slasolution.toFixed(2) + "%" : getSlaSolucao(item, this.props.userSession) + "%",
                            assignedUser: item.assigneduser ? item.assigneduser.name : "",
                            requestingUser: item.requestinguser ? item.requestinguser.name : "",
                            lastAction: moment(lastAction).format('DD/MM/YYYY'),
                            lastActionHour: moment(lastAction).format('HH:mm'),
                            tecnteams: tecteams,
                            userdepartament: item.organizationdepartament ? item.organizationdepartament.name : "",
                            location: item.address.name,
                            calledTime: item.calledtime && !isNaN(item.calledtime) ? convertDoubleToHours(item.calledtime) : '00:00:00',
                            ...orgFields
                        }
                    });

                    const intl = this.props.intl;
                    const doc = new jsPDF("l", "mm", "a0");
                    const header = document.getElementById('header-pdf');


                    let headerCanvas = await html2canvas(header);

                    const headerImg = headerCanvas.toDataURL('image/png');
                    doc.addImage(headerImg, 'PNG', 0, 0);



                    doc.autoTable({
                        body: formattedList,
                        margin: { top: 30, left: 1 },

                        bodyStyles: { valign: 'middle' },
                        styles: { overflow: 'linebreak', columnWidth: 'wrap', fontSize: 7, halign: 'center' },
                        theme: 'striped',
                        columns: [
                            { header: intl.formatMessage({id:"id"}), dataKey: 'id' },
                            { header: intl.formatMessage({ id: "category" }), dataKey: 'OrganizationCategory' },
                            { header: intl.formatMessage({ id: "last.move.date" }), dataKey: 'lastAction' },
                            { header: intl.formatMessage({ id: "last.move.hour" }), dataKey: 'lastActionHour' },
                            { header: intl.formatMessage({ id: "open.date" }), dataKey: 'openingdate' },
                            { header: intl.formatMessage({ id: "solution.date" }), dataKey: 'solutiondate' },
                            { header: intl.formatMessage({ id: "title" }), dataKey: 'title' },
                            { title: intl.formatMessage({ id: "requesting.user.name" }), field: 'requestingusername' },
                            { title: intl.formatMessage({ id: "requesting.user.email" }), field: 'requestinguseremail' },
                            { title: intl.formatMessage({ id: "requesting.user.document" }), field: 'requestinguserdocument' },
                            { header: intl.formatMessage({ id: "assigned" }), dataKey: 'assignedUser' },
                            { header: intl.formatMessage({id:"menu.statuses"}), dataKey: 'statusName' },
                            { header: intl.formatMessage({ id: "organization" }), dataKey: 'Organization' },
                            { header: intl.formatMessage({ id: "departament" }), dataKey: 'userdepartament' },
                            { header: intl.formatMessage({ id: "menu.techinicalteams" }), dataKey: 'tecnteams' },
                            { header: intl.formatMessage({ id: "called.time.entries.registration.attendance.time" }), dataKey: 'calledTime'},
                            { header: intl.formatMessage({ id: "sla.service" }), dataKey: 'slaAtendimento' },
                            { header: intl.formatMessage({ id: "sla.solution" }), dataKey: 'slaSolucao' },
                            { header: intl.formatMessage({ id: "location" }), dataKey: 'location' },
                            { header: intl.formatMessage({ id: "wasbaduse" }), dataKey: 'baduse' },
                            ...orgFiledsMenu
                        ]
                    })

                    const textContent = `${intl.formatMessage({ id: "export.date" })} : ${moment().format('DD/MM/YYYY HH:mm:ss')}`;

                    // Calculate the text position
                    const textX = 10;
                    const textY = doc.internal.pageSize.getHeight() - 10;

                    // Add text to the PDF document   
                    doc.setFontSize(12);
                    doc.text(textContent, textX, textY, { align: 'left', baseline: 'bottom' });

                    doc.save("relatorio-chamados.pdf");
                    this.setState({ loading: false, showHeader: false });
                }
                else {

                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {





                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(err);

            });*/
    };

    handleSubmit = async e => {
        e.preventDefault();
        this.setState({ loading: true });

        await this.getCalledsNew(this.props.location.state);

        /*let url = `/report/calleds/paginate`

        ApiReports.get(url)
                .then(result => {
                    if (result.data.success) {
                       
                       PapiroConsole.log("entrei aqui no result sucess")
                       PapiroConsole.log(result)
                    }
                    else {
                        PapiroConsole.log("entrei aqui no else")
                        PapiroConsole.log(result)
                        this.setState({
                            loading: false, openNotification: true, notificationVariant: 'error',
                            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                        });
                        Api.kickoff(result);
                    }
                })
                .catch(err => {
                    PapiroConsole.log("catch")
                   
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(err);

                });*/


    };

    closeNotification() {
        this.setState({ openNotification: false });
    }

    render() {
        const { classes, userSession, headerMenu, actionsHeaderMenu } = this.props;
        PapiroConsole.log(this.state.OrganizationFilter)
        PapiroConsole.log("this.state.OrganizationFilter")
        PapiroConsole.log(this.state.location)
        PapiroConsole.log("this.state.location")

        PapiroConsole.log(this.state.departament)
        PapiroConsole.log("this.state.location")
        
        var link = `/${this.props.match.params.lang}/called-registration`;
        if (this.isClient) {
            link = `/${this.props.match.params.lang}/called-registration-client`;
        }
        const intl = this.props.intl;

        if (Api == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/unavailable`
        }

        const tableList = () => {
            return (
                <>






                    <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>{intl.formatMessage({id:"filters"})}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container style={{ marginBottom: 20 }} spacing={2} >
                                    <Grid item xs={12} sm={6} md={4}  >
                                        <SimpleAutoComplete label={`Filtro por data*`} options={this.state.filterByDateList} stateName='filterByDate' changeSelect={this.changeValues} selected={this.state.filterByDate} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}  >
                                        <SimpleDatePicker label={intl.formatMessage({ id: "start.date" })} name='dateStart' stateName='dateStart' changeDate={this.changeValues} currentDate={this.state.dateStart} fullWidth={true} required />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} >
                                        <SimpleDatePicker label={intl.formatMessage({ id: "end.date" })} name='dateEnd' stateName='dateEnd' changeDate={this.changeValues} currentDate={this.state.dateEnd} fullWidth={true} required />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3}>
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "menu.statuses" })} options={this.state.statusList} stateName='StatusFilter' changeSelect={this.changeValues} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}  >
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "sla.solution" })} options={this.slaOptions} stateName='slafiltersolution' changeSelect={this.changeValues} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}  >
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "sla.service" })} options={this.slaOptions} stateName='slafilterservice' changeSelect={this.changeValues} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "organization" })} options={this.state.organizationList} stateName='OrganizationFilter' changeSelect={this.changeValues} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "productivity.dashboard.techinical.teams" })} options={this.state.TechinicalTeamList} stateName='TechinicalTeamFilter' selected={this.state.TechinicalTeamFilter} changeSelect={this.changeValues} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "menu.users" })} disabled={(this.isTechinical && !this.isAdmOrCoord)} options={this.state.techUsersList} selected={this.state.techuser} stateName='techuser' changeSelect={this.changeValues} />
                                    </Grid>

                                    {/*<Grid item xs={12} sm={6}>
                                    
                                        <SimpleAutoCompleteSearch label={ intl.formatMessage({ id: "keypress.search.instruction" })+" "+intl.formatMessage({ id: "requestuser" })} options={this.state.clientUsersList} stateName="requestingUser" changeSelect={this.changeValues} selected={called.requestinguser} loading={this.state.loadingRequestingUsers} urlapi={Api.apiurlbase()+'api/users/search/'+(this.state.called && this.state.called.organization ? this.state.called.organization.id : 0)+'/'+this.state.called.AddressId.id} required />
                                        <Link href="#"  underline="none"  onClick={this.openNewUserBaseDialog}>Cadastrar/associar novo solicitante?</Link>
            </Grid>*/}

                                   
                                    <Grid item xs={12} sm={6} md={3}>
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "location" })} options={this.state.organizationAddressesList} stateName='location' changeSelect={this.changeValues} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3} >
                                        <SimpleAutoComplete label={intl.formatMessage({ id: "departament" })} options={this.state.organizationDepartamentsList} stateName='departament' changeSelect={this.changeValues} />
                                    </Grid>
                                    
                                    {/*
                                    <Grid item xs={12} sm={12} md={3}>
                                    <SimpleAutoComplete label={intl.formatMessage({id:"choose.sla"})} options={this.state.organizationDepartamentsList} stateName='departaments' changeSelect={this.changeValues} />
                                    </Grid>*/}

                                </Grid>
                            </AccordionDetails>
                        </Accordion>


                        <Grid container style={{ marginBottom: 10 }}>
                            <Grid item xs={12} sm={2} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                                <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    color='secondary'

                                >
                                    {<FormattedMessage id="filter" />}
                                </Button>
                            </Grid>

                            {this.state.showGrid && <Grid item xs={12} sm={5} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='secondary'
                                    onClick={this.getFile}

                                >
                                    {<FormattedMessage id="export.plan" />}
                                </Button>
                            </Grid>}

                            {this.state.showGrid && <Grid item xs={12} sm={5} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='secondary'
                                    onClick={this.getCalledsToPdf}

                                >
                                    {<FormattedMessage id="dash.pdf" />}
                                </Button>
                            </Grid>}
                            <Grid item xs={12} sm={2} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                                <Button
                                    type='button'
                                    fullWidth
                                    variant='contained'
                                    color='secondary'
									onClick={this.clear}

                                >
                                    {<FormattedMessage id="clear" />}
                                </Button>
                            </Grid>

                        </Grid>
                    </form>

                    {

                        this.state.showGrid && <>
                            <MyMaterialTable
                                title=""
                                columns={[
                                    { title: intl.formatMessage({id:"id"}), field: 'id' },
                                    { title: intl.formatMessage({ id: "category" }), field: 'OrganizationCategory' },
                                    { title: intl.formatMessage({ id: "last.move.date" }), field: 'lastAction' },
                                    { title: intl.formatMessage({ id: "open.date" }), field: 'openingdate' },
                                    { title: intl.formatMessage({ id: "solution.date" }), field: 'solutiondate' },
                                    { title: intl.formatMessage({ id: "title" }), field: 'title' },
                                    { title: intl.formatMessage({ id: "requesting.user.name" }), field: 'requestingusername' },
                                    { title: intl.formatMessage({ id: "requesting.user.email" }), field: 'requestinguseremail' },
                                    { title: intl.formatMessage({ id: "requesting.user.document" }), field: 'requestinguserdocument' },
                                    { title: intl.formatMessage({ id: "assigned" }), field: 'assignedUser' },
                                    { title: intl.formatMessage({id:"menu.statuses"}), field: 'statusName' },
                                    { title: intl.formatMessage({ id: "organization" }), field: 'Organization' },
                                    { title: intl.formatMessage({ id: "departament" }), field: 'userdepartament' },
                                    { title: intl.formatMessage({ id: "menu.techinicalteams" }), field: 'tecnteams' },
                                    { title: intl.formatMessage({ id: "location" }), field: 'location' },
                                    { title: intl.formatMessage({ id: "called.time.entries.registration.attendance.time" }), field: 'calledTime' },
                                    { title: intl.formatMessage({ id: "sla.service" }), field: 'slaAtendimento' },
                                    { title: intl.formatMessage({ id: "sla.solution" }), field: 'slaSolucao' },
                                    { title: intl.formatMessage({ id: "wasbaduse" }), field: 'baduse' },
                                    { title: intl.formatMessage({ id: "calledclosingstatus" }), field: 'calledClosingStatus' },
                                    //{ title: intl.formatMessage({ id: 'organization.custom.fields'}) , field: 'organizationFieldsPopup' }
                                ]}
                                data={this.state.calledsList}

                            />

                            <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                                <Grid item xs={12} md={3}>
                                    <Pagination count={this.state.totalPages} page={this.state.page} onChange={this.onChangePaginate} />
                                </Grid>
                                <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                                    {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                                </Grid>
                            </Grid>
                        </>
                    }



                </>
            );
        }

        return (
            <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    }, classes.actionsContent, {
                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft:0, marginLeft:0}}>
                        <CssBaseline />
                        {this.state.showHeader &&
                            <Grid container>
                                <Grid id="header-pdf" item lg={4} sm={12} xl={this.state.grid} xs={12} style={{ padding: '2px', minWidth: 600 }}>
                                    <Card  >

                                        <CardContent>

                                            <img src={LogoMarca} style={{ width: "70px" }} /> <span style={{ fontSize: 18 }}> {<FormattedMessage id="called.report.header" />}</span>
                                            <Divider />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>}
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={12}>

                                <Card >
                                    <CardContent className="background-title-search">
                                        <Typography variant="h5">
                                            {<FormattedMessage id="called.report.header" />}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                        </Grid>
                        <Can I="List" a="Called">
                            {tableList()}
                        </Can>


                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color="secondary" />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
                <Footer />
            </div>
        );
    }
}

CalledReports.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
    actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(calledsActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledReports)));
CalledReports.contextType = AbilityContext;