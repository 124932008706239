import React, { Component } from 'react';
                import clsx from 'clsx';
                import { connect } from 'react-redux';
                import PropTypes from 'prop-types';
                import { withStyles } from '@mui/styles';
                import { Styles } from '../../styles/material-styles';
                import HeaderMenu from '../../components/header-menu';
                import { AbilityContext } from '../../config/ability-context'
                    import Footer from '../../components/footer';
                    import { withRouter } from 'react-router-dom';
                    import { ThemeProvider } from '@mui/material/styles'
                    import { createTheme } from '@mui/material'
                    import Api from '../../services/api';
import { PapiroConsole } from '../../utils/papiroConsole';

class Base extends Component {

                    constructor(props) {
                        super(props);
                        PapiroConsole.log("=== Base props ===")
                        PapiroConsole.log(props)
                        
                        this.state = {
                            colorTheme: Api != null ? Api.getPrimaryColorTheme() : "#3F51B4",
                            secondaryColorTheme: Api != null ? Api.getSecondaryColorTheme() : "#277afe",
                        }
                    }

                

                    componentWillReceiveProps(nextProps){
                    }    

                    async componentDidMount() {
                    }

                     render() {
                        const { classes, headerMenu, actionsHeaderMenu, children } = this.props;
                        PapiroConsole.log("=== BASE ===")
                        PapiroConsole.log(this.props.customStyle)
                        PapiroConsole.log(this.props)
                        const theme = createTheme({
                            palette: {
                              primary: {
                                main: this.state.colorTheme
                              },
                              secondary: {
                                main: this.state.secondaryColorTheme
                              }
                            }
                        })

                        return (
                            <ThemeProvider theme={theme}>   
                            <div className={classes.root}>
                                <HeaderMenu />
                                <main style={(this.props && this.props.customStyle) ? this.props.customStyle : {}}
                                    className={clsx(classes.content, {
                                        [classes.contentShift]: headerMenu.open,
                                    }, classes.actionsContent, {
                                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                                    })}
                                >
                                    <div className={classes.drawerHeader} />

                                     {children}

                                </main>
                                <Footer fabPosition={this.props.footerFabPosition} />   
                            </div>
                            </ThemeProvider>
                        );
                    }
                }
Base.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    actionsHeaderMenu: state.actionsHeaderMenu,
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                });

            export default withRouter(connect(mapStateToProps)(withStyles(Styles)(Base)));

            Base.contextType = AbilityContext;                                    