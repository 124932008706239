import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress'
import ClearIcon from '@mui/icons-material/Clear'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, FormGroup, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as componentActions from '../../../../redux/actions/calledservicesettingsfileuploads'
import Api from '../../../../services/api'
import { addItem } from '../../../../services/calledservicesettingsfileupload.service'
import { Styles } from '../../../../styles/material-styles'
import { Overlay } from '../../../../styles/global';
import CustomizedSnackbars from '../../../../components/material-snackbars'
import { PapiroConsole } from '../../../../utils/papiroConsole'
import SimpleAutoComplete from '../../../../components/auto-complete/autocomplete'
import Tooltip from '@mui/material/Tooltip'
import HelpIcon from '@mui/icons-material/Help'
import { DeleteForever, AttachFile } from '@mui/icons-material'
import Dropzone from 'react-dropzone'
import { isInvalidFileType } from '../../../../utils/fileUtils'
import 'react-quill/dist/quill.snow.css'; // ES6
import ReactQuill from 'react-quill'; // ES6
import AttachFileIcon from '@mui/icons-material/AttachFile';

function AddCalledServiceSettingsFileUploadItemDialog(props) {

  const { intl } = props

  const [open, setOpen] = useState(props.open)
  const [loading, setLoading] = useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const [notificationVariant, setNotificationVariant] = useState('error')
  const [notificationMessage, setNotificationMessage] = useState('')

  const [organizationFieldId, setOrganizationFieldId] = useState(null)
  const [label, setLabel] = useState('')
  const [order, setOrder] = useState(null)
  const [description, setDescription] = useState('')
  const [fileLimit, setFileLimit] = useState(0)
  const [isRequired, setIsRequired] = useState(false)
  const [files, setFiles] = useState([])

  const [orderList, setOrderList] = useState([])

  useEffect(() => {
    setOpen(props.open)
    props.handleOpen(props.open)
    if (props.open)
      setFiles([])
  }, [props.open])

  useEffect(() => {
    if (props.itemsCount > 0) {
      let orderItemsList = []

      let totalOrderItems = props.itemsCount + 1
      setOrder({ id: totalOrderItems, name: `${totalOrderItems}` })
      PapiroConsole.log("=== props.itemsCount ===")
      PapiroConsole.log(totalOrderItems)
      for (let orderItem = 1; orderItem <= totalOrderItems; orderItem++) {
        PapiroConsole.log(orderItem)
        orderItemsList.push({ id: orderItem, name: `${orderItem}` })
      }

      setOrderList(orderItemsList)
    } else {
      setOrderList([ { id: 1, name: '1' } ])
      setOrder({ id: 1, name: '1' })
    }
  }, [props.itemsCount])

  const setInitialState = () => {
    setOrganizationFieldId(null)
    setLabel('')
    setOrder(null)
    setDescription('')
    setFileLimit(0)
    setIsRequired(false)
    setFiles([])
  }

  const close = () => {
    setOpen(false)
    props.handleOpen(false)
  }

  const setMessage = (open, variant, message) => {
    setOpenNotification(open)
    setNotificationVariant(variant)
    setNotificationMessage(message)
  }

  const closeNotification = _ => {
    setOpenNotification(false)
    setNotificationVariant('error')
    setNotificationMessage('')
  }

  const changeValues = (stateName, value) => {
    if (stateName === 'order')
      setOrder(value)
    if (stateName === 'organizationField')
      setOrganizationFieldId(value)
  }

  const validateImagesAtachments = (filesList) => {
    if (typeof filesList === 'object') {
      let count = 0
      let fileList = []
      Object.values(filesList).forEach(val => {
        ++count
        if (isInvalidFileType(val.type, "image")) {
          setFiles([])
          setMessage(true, 'error', "Tipo de arquivo inválido. São aceitos arquivos dos tipos: BMP, GIf, JPG / JPEG, PNG, TIFF")
        } else
          fileList.push(val)
      })
      setFiles(fileList)
      if(files.length > 2) {
        setFiles([])
        setMessage(true, 'error', "você pode inserir no máximo 2 arquivos")
        return
      }
    } else {
      let fileList = []
      filesList.foreach((item, idx) => {
        if (!isInvalidFileType(item.type, "image")) {
          setFiles([])
          setMessage(true, 'error', "Tipo de arquivo inválido. São aceitos arquivos dos tipos: BMP, GIf, JPG / JPEG, PNG, TIFF")
          return
        } else
          fileList.push(item)
      })
      setFiles(fileList)
      if(files.length > 2) {
        setFiles([])
        setMessage(true, 'error', "você pode inserir no máximo 2 arquivos")
        return
      }
    }
  }

  const removeItem = (file) => {
    let array = [...files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      setFiles(array)
    }
  }

  const validateForm = _ => {
    if (label == null || (label != null && label.length === 0)) {
      setLoading(false)
      setMessage(true, 'error', "O campo 'Nome' é obrigatório")
      return false
    } else if (order == null || (order != null && order.id && order.id === 0)) {
      setLoading(false)
      setMessage(true, 'error', "O campo 'Ordem' é obrigatório")
      return false
    } else if (fileLimit == null || (fileLimit != null && fileLimit === 0)) {
      setLoading(false)
      setMessage(true, 'error', "O campo 'Limite de arquivos' é obrigatório")
      return false
    }

    return true
  }

  const createItem = async _ => {
    setLoading(true)

    let formIsValid = validateForm()
    if (formIsValid) {
      var data = new FormData()

      data.append("Label", label)
      data.append("Order", order.id)
      data.append("Description", description)
      data.append("FileLimit", fileLimit)
      data.append("IsRequired", isRequired)
      data.append("CalledServiceSettingsId", props.calledServiceSettingsId)
      data.append("CreatedUser", props.userId)
      data.append("CreatedAt", moment().format('YYYY/MM/DD HH:mm:ss'))

      if (typeof files === 'object') {
        Object.values(files).forEach(val => {
          data.append("FilesUpload", val)
        })
      } else {
        if (files && files.length > 0)
          files.foreach(item => data.append("FilesUpload", item))
      }

      let response = await addItem(data)
      if (response && response.success) {
        setLoading(false)
        setMessage(true, 'success', intl.formatMessage({ id: "add.success" }))
        if (response.data != null) {
          PapiroConsole.log("=== createItem ===")
          PapiroConsole.log(props.calledservicesettingsfileuploads)
          let oldItemsList = props.calledservicesettingsfileuploads && props.calledservicesettingsfileuploads.calledservicesettingsfileuploadslist && props.calledservicesettingsfileuploads.calledservicesettingsfileuploadslist.length > 0 ? [...props.calledservicesettingsfileuploads.calledservicesettingsfileuploadslist] : []
          PapiroConsole.log(oldItemsList)
          let orderFilter = oldItemsList.filter(item => item.order >= order.id)
          PapiroConsole.log(orderFilter)
          if (orderFilter && orderFilter.length > 0) {
            let newOrderFilterList = orderFilter.map(item => {
              return {
                ...item,
                order: (item.order + 1)
              }
            })
            let newOrderFilterListIds = orderFilter.map(item => item.id)
            PapiroConsole.log(newOrderFilterList)

            let notOrderFilterList = oldItemsList.filter(item => !newOrderFilterListIds.includes(item.id))
            PapiroConsole.log(notOrderFilterList)
            oldItemsList = [...notOrderFilterList, ...newOrderFilterList]
            PapiroConsole.log(oldItemsList)

          }
          let itemList = [...oldItemsList, response.data]
          itemList = itemList.sort((a, b) => a.id - b.id)
          PapiroConsole.log(itemList)

          props.addCalledServiceSettingsFileUploadsList(itemList)
        }
        setInitialState()
        close()
      } else {
        setLoading(false)
        setMessage(true, 'error', intl.formatMessage({ id: "process.error" }))
      Api.kickoff(response)
      }
    }
  }

  const reactQuillModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  }

  const reactQuillFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="lg"
        onClose={close}
        scroll="body"
        aria-labelledby="form-add-called-service-setting-file-upload-dialog-title" >
        <DialogTitle id="form-add-called-service-setting-file-upload-dialog-title">
          Adicionar campo de upload de arquivos
          <ClearIcon onClick={close}  style={{ float: 'right', cursor : 'pointer'}}/> 
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={2} style={{ padding: 10 }} >
            <Grid item xs={11} sm={11} md={11} lg={8} >
              <TextField
                name="label"
                variant="outlined"
                fullWidth
                id="label"
                label={`${intl.formatMessage({ id: "name" })}`}
                value={label}
                required
                onChange={(e) => setLabel(e.target.value)} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>Nome do campo no formulário</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={4} >
              <SimpleAutoComplete
                label={`${intl.formatMessage({ id: "item.order" })}*`}
                options={orderList}
                stateName='order'
                changeSelect={changeValues}
                selected={order} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={8} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>Ordem de exibição do campo personalizado</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} style={{ marginBottom: 50 }}>
              <Typography>
                Descrição
              </Typography>
              <ReactQuill
                id="description"
                name="description"
                modules={reactQuillModules}
                formats={reactQuillFormats}
                value={description}
                style={{ height: 200 }}
                fullWidth
                required
                InputLabelProps={{ shrink: description && description.length > 0 ? true : false }}
                variant="outlined"
                onChange={(value) => setDescription(value)} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
            <Tooltip title={<h5>Descrição do campo, que será exibida no formulário</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={6} >
              <TextField
                type="number"
                name="fileLimit"
                variant="outlined"
                fullWidth
                id="fileLimit"
                label={`Limite de arquivos`}
                value={fileLimit}
                required
                onChange={(e) => setFileLimit(e.target.value)} />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={6} style={{ textAlign: 'left', alignSelf: 'stretch' }}>
              <Tooltip title={<h5>Limite total de arquivos que podem ser enviados no campo</h5>}>
                <HelpIcon />
              </Tooltip>
            </Grid>
            <Grid item xs={10} sm={10} md={11} lg={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch 
                      checked={isRequired}
                      onChange={(e) => setIsRequired(e.target.checked)}
                      name="isRequired" />
                  }
                  label="Campo obrigatório?*" />
              </FormGroup>
            </Grid>
            <Grid item xs={2} sm={2} md={1} lg={6} />
            <Grid item xs={11} sm={11} md={11} lg={8} style={{ marginBottom: '0' }}>
              <Typography style={{ marginBottom: '0', color: 'rgba(0, 0, 0, 0.54)' }}>Imagen informativas (Máximo de 2 arquivos)</Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} />
            <Grid item xs={11} sm={11} md={11} lg={8}>
              <section style={{ width: '100%' }}>
                <div className={props.classes.dropzoneArea} style={{minHeight:'70px',marginTop:'5px '}}>
                  <Dropzone maxFiles={2} onDrop={acceptedFiles =>  validateImagesAtachments([ ...acceptedFiles, ...files ])}>
                    {({ getRootProps, getInputProps, acceptedFiles }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <AttachFileIcon  />
                      <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                          <FormattedMessage  id="drag.and.drop.files" />
                      </Typography>
                    </div>
                    )}
                  </Dropzone>
                  {files.map(file => {
                  return (
                    <div className={props.classes.dropzoneItem} key={file.name}>
                      {file.name}
                      <DeleteForever className={props.classes.deleteItem} onClick={() => { removeItem(file) }} />
                    </div>
                    )
                  })}
                </div>
              </section>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={4} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            type="button"
            onClick={close} >
            {intl.formatMessage({id:"cancel"})}
          </Button>
          <Button 
            type='submit'
            variant='contained'
            color='primary'
            onClick={createItem} >
            {intl.formatMessage({id:"save"})}
          </Button>
        </DialogActions>
      </Dialog>

      {loading && (
        <Overlay>
          <CircularProgress color='secondary' />
        </Overlay>
      )}

      <CustomizedSnackbars
        variant={notificationVariant}
        message={notificationMessage}
        isOpen={openNotification}
        toClose={closeNotification} />
    </>
  )

}

AddCalledServiceSettingsFileUploadItemDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
  itemsCount: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  hirerId: PropTypes.number.isRequired,
  calledServiceSettingsId: PropTypes.number.isRequired
}

const mapStateToProps = state => ({
  calledservicesettingsfileuploads: state.calledservicesettingsfileuploads,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(AddCalledServiceSettingsFileUploadItemDialog)))