import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../../styles/material-styles';
import { Overlay } from '../../../styles/global';
import Api from '../../../services/api';
import CustomizedSnackbars from '../../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleSelect from '../../../components/select/simple';
import SimpleCheckBox from '../../../components/checkbox/check';
import { browserHistory } from '../../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import Title from '../../../components/title-name';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import * as Service from '../../../services/organizationsetting.service';
import * as ServiceRegistrationPhoneNumber from '../../../services/2talk/registrationphonenumber';
import Footer from '../../../components/footer';
import { PapiroConsole } from '../../../utils/papiroConsole';
import SimpleAutoComplete from '../../../components/auto-complete/autocomplete';
import 'react-quill/dist/quill.snow.css'; // ES6
import ReactQuill from 'react-quill'; // ES6
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './tab.css'
import * as TechnicalTeamService from '../../../services/techinicalteamuser.service'
import { TimeScale } from 'chart.js';
import { Autocomplete } from '@mui/material';
import * as TwoTalkRegistrationNumberRequestService from '../../../services/twotalk.registration.number.request.service'
import * as TwoTalkTemplateMessageService from '../../../services/twotalk.template.message.service'
import templateMessageList from 'src/pages/template-message-list';

class OrganizationSettingRegistration extends Component {

  constructor(props) {
    super(props);
    console.log(props)

    const { userSession, organizationid } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    this.arrayLenght = 0;
    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff(); 
    }

    userSession.user.userrole.map((item, key) => {
      if(item.role.roletype !=3){
        this.isClient = false;
      }
      if(item.role.roletype ==0){
        this.globalAdm =true;
      }
      }
    );

      this.displayAssignedInfoModeOptions = [
        {id: 1, name: props.intl.formatMessage({ id: "displayAssignedInfoMode.1"})},
        {id: 2, name: props.intl.formatMessage({ id: "displayAssignedInfoMode.2"})},
        {id: 3, name: props.intl.formatMessage({ id: "displayAssignedInfoMode.3"})},
        {id: 4, name: props.intl.formatMessage({ id: "displayAssignedInfoMode.4"})},
      ]

      this.displayAssignedInfoModeOptionsHelp = [
        "O cliente e o administrador verão o nome do técnico",
        "O cliente verá o nome do time e o administrador verão o nome do técnico",
        "Não será exibido nem o nome do técnico nem o nome do time",
        "O cliente e o administrador verão o nome do time",
      ]

    this.reactQuillModules = {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ],
    }
    
    this.reactQuillFormats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image'
    ]



    this.initialState = {
    createdUser: userSession.user.id,
    createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
    OrganizationId:  organizationid,
    OrganizationText: '',
    TypeId: 0,
    TypeText: '',
    OriginRequestId:  0,
    OriginRequestText: '',
    TechinicalTeamId: 0,
    DefaultTechinicalId: 0,
    TechinicalTeamText: '',
    StatusId: 0,
    displayAssignedInfoMode: 0,
    StatusText: '',
    informativetext: '',
    canviewcalledfromothertechnical : false, 
    roleclientcanselectcategory : false, 
    canassingusercalled : false,
    estimatedanwserhours: 24,
    canselectpatrimonyincalled: false,
    canselectdepartamentincalled: false,
    canselectparentcalledincalled: false,
    canselectdisplacementincalled: false,
    canselecthourcostorcalledcostincalled: false,
    caninformcalledclosingstatus: false,
    canselectorganizationcategoriesbylevels: false,
    canclientselectpatrimonyincalled: false,
    canclientselecttypeincalled: false,
    canclientviewslametricsincalled: false,
    caninformzeroincalledtechnicalcost: false,
    canfinalchecklistisrequired: false,
    caninitialchecklistisrequired: false,
    caninforminitialchecklist: false,
    automaticallycreatecalledwhenreadingqr: false,
    allowscreateanonymouscalledsbyemail: false,
    allowattendanceviachat: false,
    finalizationexternalstatus: false,
    requireattachedimage: false,
    canclientfinishcalled: false,
    canclientreopencalled: false,
    canclientautomaticchangestatusaftersendaccompaniment: false,
    canarchivecalleds : true,
    disablecallrejectionbyrequester : false,
    allowclientinteractiononclosedcalleds  : false,
    restrictviewcalleddepartament: false,
    defaultaccompaniment: false,
    automaticchangestatusaftermobilefinish: false,
    cantechnicalopencustomerreviewlinkonmobile: false,
    caninformutilizedmaterialsincalledfinish: false,
    canselectoriginrequestincalledcreation: false,
    isrequiredselectteamincalledfinish: false,
    caneditaccompanimentinactivecalled: false,
    actionrequiredwhenstatusorteamorassignedchanges : false,
    enablesendingsatisfactionsurveythroughmessagingproviders : false,

    
    canstartcallcenter: false,
    allowchangeoforganizationofcalledbyadministration: false,
    redirecttheuseraftercompletingthecalled: false,
    organizationkey: "",
    enable2talk: false,
    savetoallorganizationsingroup: false,
    registrationnumberrequestid : null,
    templatemessageid : null,
    
    
     };         
    this.state = {
      item: this.initialState,
      files: [],
      loading: true,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      OrganizationList: [],
      TypeList: [],
      OriginRequestList: [],
      enableFlagSendingSatisfactionSurveyThroughMessagingProviders : false,
      StatusList: [],
      disabledvertex: true,
      enable2talk: false,
      TechinicalTeamList: [],
      DefaultTechnicalList: [],
      disabledvertex:true,
      displayAssignedInfoModeOptions: this.displayAssignedInfoModeOptions,
      registrationNumberRequestList : [],
      enableSendingSatisfactionSurveyThroughMessagingProvidersDisabled : false,
      templateMessageList : []
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.getTechinicalTeams = this.getTechinicalTeams.bind(this);
    this.handleChangeEditor = this.handleChangeEditor.bind(this)
    this.getTechsByTeam = this.getTechsByTeam.bind(this)
  }

  handleChangeEditor = (value) => this.setState(prevState => ({ item: {
    ...prevState.item,
    informativetext: value || ""
  }
  }))

  async getTechinicalTeams() {
    this.setState({ loading: true });
    var result = await Service.getTechinicalTeamsByOrganizationId(this.state.item.OrganizationId);
    if (result.success) {
      this.setState({ loading: false, TechinicalTeamList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }

  async organizationHasRegistrationNumberRequestActive() {
    
    var result = await ServiceRegistrationPhoneNumber.organizationHasRegistrationNumberRequestActive(this.state.item.OrganizationId);
    if (result.success) {
      if(result.data && result.data.length > 0){
        this.setState({enableFlagSendingSatisfactionSurveyThroughMessagingProviders : true})
      }
    } else {
      PapiroConsole.log("entrei aqui 2")
      
    }
  }

    async componentDidMount() {
        await this.getOrganizations();
        await this.getTypes();
        await this.getOriginRequests();
        await this.getStatuses();
        await this.getDados();
        await this.productsAvaliable();
        await this.getTechinicalTeams();
        await this.getRegistrationsNumbersActivesByOrganization();
     };

     async getRegistrationsNumbersActivesByOrganization(){
      
      const intl = this.props.intl;
      const { organizationid } = this.props;
      this.setState({ loading: true });
      var result = await TwoTalkRegistrationNumberRequestService.getByOrganizationId(organizationid);
      //console.log(result.data);
        if (result.success) {
          if(result.data.registrationnumberrequestlist ){
            const initialOption = result.data.registrationnumberrequestlist.find(
              option => option.id === this.state.item.registrationnumberrequestid,
            );
  
            this.setState(prevState => ({
              item: {
                ...prevState.item,
                registrationnumberrequestid :initialOption ? initialOption : {id : 0, phonenumber:''}
              },
              registrationNumberRequestList : result.data,
              loading: false
              
            }));

            if(initialOption){
              this.getTemplateOptions(initialOption);
            }
            var enableSendingSatisfactionSurveyThroughMessagingProvidersDisabled = false
            if(result.data.enablesendingsatisfactionsurveythroughmessagingproviders && result.data.registrationnumberrequestid){
              enableSendingSatisfactionSurveyThroughMessagingProvidersDisabled = true
            }
            this.setState({ enableSendingSatisfactionSurveyThroughMessagingProvidersDisabled : enableSendingSatisfactionSurveyThroughMessagingProvidersDisabled, registrationNumberRequestList: result.data && result.data.registrationnumberrequestlist ? result.data.registrationnumberrequestlist : []  });
          }
          else{
            this.setState({ enableSendingSatisfactionSurveyThroughMessagingProvidersDisabled : true, loading : false });
          }
          
        
                        
        } else {
          this.setState({
            loading : false,openNotification: true, notificationVariant: 'error',
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
          })
          Api.kickoff(result);
        }
  };

  async getTemplateOptions(value){
    
    PapiroConsole.log("value")
    PapiroConsole.log(value)
    if(value && value.id){
      const intl = this.props.intl;
      const { organizationid } = this.props;
      this.setState({ loading: true });
      var result = await TwoTalkTemplateMessageService.getByRegistrationNumberRequestId(value.id);
      //console.log(result.data);
        if (result.success) {
          if(result.data ){

            PapiroConsole.log("this.state.item.templatemessageid")
            PapiroConsole.log(this.state.item.templatemessageid)
            const initialOption = result.data.find(
              option => option.id === this.state.item.templatemessageid,
            );

            PapiroConsole.log("initialOption")
            PapiroConsole.log(initialOption)
  
            this.setState(prevState => ({
              item: {
                ...prevState.item,
                templatemessageid :initialOption ? initialOption : {id : 0, name:''}
              },
              templateMessageList : result.data,
              loading: false
            }));
            
          }
          else{
            this.setState({ loading : false });
          }
          
        
                        
        } else {
          this.setState({
            loading : false,openNotification: true, notificationVariant: 'error',
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
          })
          Api.kickoff(result);
        }
    }
    else{

      this.setState(prevState => ({
        item: {
          ...prevState.item,
          templatemessageid : {id : 0, name:''}
        },
        templateMessageList : [],
        loading: false
      }));

    }
    
};

 
    async productsAvaliable(){
        var data = await Api.ProductsAvaliable();
        var isVertexAvaliable = false;
        var isTalkAvaliable = false;
        if(data.constructor === Array && data.length > 0){
            data.forEach(element => {
              if(element.id == 3){
                isVertexAvaliable=true;
              }
              if(element.id == 2){
                isTalkAvaliable=true;
              }
            });
        }

        //alert(result);
        this.setState({  disabledvertex: !isVertexAvaliable, enable2talk: isTalkAvaliable });

    }
    
    async getOrganizations(){
          this.setState({ loading: true });
          var result = await Service.getOrganizations();
            if (result.success) {
              this.setState({  OrganizationList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);
            }
  }; 
  async getDados(){
    this.setState({ loading: true });
    PapiroConsole.log("result.data")
    const { organizationid } = this.props;
       
    var result = await Service.getOrganizations(organizationid);
      if (result.success) {
        this.arrayLenght = result.data.length;
        if(result.data.length > 0){

          PapiroConsole.log("result.data")
          PapiroConsole.log(result.data)
       
          let resultData = result.data[0];
          
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['id']: resultData.id,
              ['TypeId']:  resultData.type.id,
              ['displayAssignedInfoMode']: resultData.displayassignedinfomode,
              ['TypeText']: resultData.type.name,
              ['OriginRequestId']:  resultData.originrequest.id,
              ['OriginRequestText']: resultData.originrequest.name,
              ['StatusId']: resultData.status.id,
              ['TechinicalTeamId']: resultData.techinicalteam ? resultData.techinicalteam.id : 0,
              ['DefaultTechinicalId']: resultData.defaulttechinicalid ? resultData.defaulttechinicalid : 0,
              ['canviewcalledfromothertechnical'] : resultData.canviewcalledfromothertechnical, 
              ['roleclientcanselectcategory'] : resultData.roleclientcanselectcategory, 
              ['canassingusercalled'] : resultData.canassingusercalled,
              ['estimatedanwserhours']: resultData.estimatedanwserhours,
              ['canselectpatrimonyincalled']: resultData.canselectpatrimonyincalled,
              ['canselectdepartamentincalled']: resultData.canselectdepartamentincalled,
              ['canselectparentcalledincalled']: resultData.canselectparentcalledincalled,
              ['canselectdisplacementincalled']: resultData.canselectdisplacementincalled,
              ['canselecthourcostorcalledcostincalled']: resultData.canselecthourcostorcalledcostincalled,
              ['caninformcalledclosingstatus']: resultData.caninformcalledclosingstatus,
              ['caninformzeroincalledtechnicalcost']: resultData.caninformzeroincalledtechnicalcost,
              ['canfinalchecklistisrequired']: resultData.canfinalchecklistisrequired,
              ['caninitialchecklistisrequired']: resultData.caninitialchecklistisrequired,
              ['caninforminitialchecklist']: resultData.caninforminitialchecklist,
              ['canselectorganizationcategoriesbylevels']: resultData.canselectorganizationcategoriesbylevels,
              ['canclientselectpatrimonyincalled']: resultData.canclientselectpatrimonyincalled,
              ['canclientselecttypeincalled']: resultData.canclientselecttypeincalled,
              ['canclientviewslametricsincalled']: resultData.canclientviewslametricsincalled,
              ['automaticallycreatecalledwhenreadingqr']: resultData.automaticallycreatecalledwhenreadingqr,
              ['allowscreateanonymouscalledsbyemail']: resultData.allowscreateanonymouscalledsbyemail,
              ['clientvisualization']:resultData.clientvisualization,
              ['requiredratingcalleds']:resultData.requiredratingcalleds,
              ['allowattendanceviachat']:resultData.allowattendanceviachat,
              ['finalizationexternalstatus']: resultData.finalizationexternalstatus,
              ['restrictviewcalleddepartament']: resultData.restrictviewcalleddepartament,
              ['defaultaccompaniment']: resultData.defaultaccompaniment,
              ['automaticchangestatusaftermobilefinish']: resultData.automaticchangestatusaftermobilefinish,
              ['cantechnicalopencustomerreviewlinkonmobile']: resultData.cantechnicalopencustomerreviewlinkonmobile,
              ['caninformutilizedmaterialsincalledfinish']: resultData.caninformutilizedmaterialsincalledfinish,
              ['canselectoriginrequestincalledcreation']: resultData.canselectoriginrequestincalledcreation,
              ['isrequiredselectteamincalledfinish']: resultData.isrequiredselectteamincalledfinish,
              ['requireattachedimage']:resultData.requireattachedimage,
              ['canclientfinishcalled']: resultData.canclientfinishcalled,
              ['canclientreopencalled']: resultData.canclientreopencalled,
              ['canclientautomaticchangestatusaftersendaccompaniment']: resultData.canclientautomaticchangestatusaftersendaccompaniment,
              ['canarchivecalleds'] : resultData.canarchivecalleds,
              ['disablecallrejectionbyrequester'] : resultData.disablecallrejectionbyrequester,     
              ['allowclientinteractiononclosedcalleds'] : resultData.allowclientinteractiononclosedcalleds,
              ['organizationkey']:resultData.organizationkey,
              ['canstartcallcenter']:resultData.canstartcallcenter,
              ['enable2talk']:resultData.enable2talk,
              ['caneditaccompanimentinactivecalled']: resultData.caneditaccompanimentinactivecalled,
              ['actionrequiredwhenstatusorteamorassignedchanges'] : resultData.actionrequiredwhenstatusorteamorassignedchanges,
              ['enablesendingsatisfactionsurveythroughmessagingproviders'] : resultData.enablesendingsatisfactionsurveythroughmessagingproviders,
              ['registrationnumberrequestid'] : resultData.registrationnumberrequestid,
              ['templatemessageid'] : resultData.templatemessageid,
              ['allowchangeoforganizationofcalledbyadministration']: resultData.allowchangeoforganizationofcalledbyadministration,
              ['redirecttheuseraftercompletingthecalled']: resultData.redirecttheuseraftercompletingthecalled,
              ['informativetext']: resultData.informativetext != null ? resultData.informativetext : ""
            }
          }));
          if (resultData.techinicalteam && resultData.techinicalteam.id && resultData.techinicalteam.id > 0) {
            await this.getTechsByTeam(resultData.techinicalteam.id)
            }
          }
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);
      }
    this.setState({ loading: false });
  }; 
  async getTypes(){
          this.setState({ loading: true });
          var result = await Service.getTypes();
            if (result.success) {
              this.setState({  TypeList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);
            }
  };     async getOriginRequests(){
          this.setState({ loading: true });
          var result = await Service.getOriginRequests();
            if (result.success) {
              this.setState({  OriginRequestList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);
            }
  };     async getStatuses(){
          this.setState({ loading: true });
          var result = await Service.getStatuses();
            if (result.success) {
              this.setState({ loading: false, StatusList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);
            }
  };

  async getTechsByTeam(teamId) {
    this.setState({ loading: true })
    var list = []
    const result = await TechnicalTeamService.getAllByTechnicalTeamId(teamId)
    if (result.success) {
      list = result.data.map(tu => ({id: tu.user.id, name: tu.user.name}))
      this.setState({ loading: false, DefaultTechnicalList: list})
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
      });
      Api.kickoff(result);
    }
    return list
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  };


  checkEmptyFields(){
    const intl = this.props.intl;
    if(this.state.item != null){

      //condição para o tipo  
      if(this.state.item.TypeId == 0){
        this.setState({
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:intl.formatMessage({id:"wizard.pages.default.called.type.field.missing"}),
          loading :false
        });

        return true
      }
      else if(this.state.item.OriginRequestId == 0){
        this.setState({
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:intl.formatMessage({id:"wizard.pages.default.called.origin.field.missing"}),
          loading :false
        });

        return true
      }

      else if(this.state.item.StatusId == 0){
        this.setState({
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:intl.formatMessage({id:"wizard.pages.default.status.type.field.missing"}),
          loading :false
        });

        return true
      }
      else if(this.state.item.displayAssignedInfoMode == 0){
        this.setState({
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:intl.formatMessage({id:"wizard.pages.default.which.assigned.show.to.client.missing"}),
          loading :false
        });

        return true
      }
      else{
        return false
      }

    }

  }

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });


    var checkEmptyFields = this.checkEmptyFields()

    if(!checkEmptyFields){

      let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      }

      if(!this.state.item.canselectpatrimonyincalled){
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            automaticallycreatecalledwhenreadingqr: false,
            canclientselectpatrimonyincalled: false
          }
        }));
      }


      for (let key in this.state.item) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        } 
        else if (key.toString().toLowerCase().indexOf('roleclientcanselectcategory') > -1) {
          if(this.state.item[key] == true || this.state.item[key] === true){
            data.append(key, 1);
          }
          else{
            data.append(key, 0);
          }
        }
        else if (key.toString().toLowerCase().indexOf('canassingusercalled') > -1) {
          if(this.state.item[key] == true || this.state.item[key] === true){
            data.append(key, true);
          }
          else{
            data.append(key, false);
          }
        }
        else if (key.toString().toLowerCase().indexOf('canviewcalledfromothertechnical') > -1) {
          if(this.state.item[key] == true || this.state.item[key] === true){
            data.append(key, true);
          }
          else{
            data.append(key, false);
          }
        }
        else if(key.toString().toLowerCase().indexOf("registrationnumberrequestid") > -1 || key.toString().toLowerCase().indexOf("templatemessageid") > -1 ) {
          data.append(key, this.state.item[key] ? this.state.item[key].id : 0)
        }
        else {
          data.append(key, this.state.item[key]);
        }
      }
      //this.setState({ loading: false });
      //return false;
      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

      if(this.arrayLenght > 0)
      {
        Api.put('/organizationsettings', data, config)
        .then(result => {
          const intl = this.props.intl;
          if (result.data.success) {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({id:"add.success"}),
                item: this.initialState,
                files: []
              });
              this.props.handleComplete()
              this.props.next();
          }
          else{

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
                            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);

          }
        })
        .catch(err => {
          const intl = this.props.intl;
          if(err.response && err.response.data && err.response.data.errors){
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
                            notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
          }
          Api.kickoff(err);

        });
      }
      else
      {
        Api.post('/organizationsettings', data, config)

        .then(result => {
          const intl = this.props.intl;
          if (result.data.success) {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({id:"add.success"}),
                item: this.initialState,
                files: []
              });
              this.getOrganizations();
              this.getTypes();
              this.getOriginRequests();
              this.getStatuses();

              this.props.handleComplete();
              this.props.handleNextBarProgress()
          }
          else{

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
                            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);

          }
        })
        .catch(err => {
          const intl = this.props.intl;
          if(err.response && err.response.data && err.response.data.errors){
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
                            notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
          }
          Api.kickoff(err);

        });

      }

    }


    
    
        


  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    console.log(stateName)
    console.log(value)
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));
        if(stateName === 'OrganizationId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['OrganizationText']: text,
            }
          }));
        }
        if(stateName === 'TypeId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['TypeText']: text,
            }
          }));
        }
        if(stateName === 'OriginRequestId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['OriginRequestText']: text,
            }
          }));
        }
        if(stateName === 'StatusId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['StatusText']: text,
            }
          }));
        }
        if (stateName === "TechinicalTeamId") {
          this.setState((prevState) => ({
            item: {
              ...prevState.item,
              ["TechinicalTeamText"]: text,
              ["DefaultTechnical"] : {id: 0, name: ""}
            },
          }));
          this.getTechsByTeam(value)
        }

        if(stateName == "registrationnumberrequestid"){
          this.getTemplateOptions(value);
        }
 
    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
    render() {
    const { classes, headerMenu, back } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;


   

   // console.log(this.state.item);
     return (
      <div className={classes.root}>
        
        
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                
                <Grid item xs={12} sm={8} />
              </Grid>
              
              <Typography component='h1' variant='h5'>
              {<FormattedMessage id="add.item" />}
              <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <SimpleSelect label={intl.formatMessage({id:"organization.settings.type.call"})} options={this.state.TypeList} stateName='TypeId' changeSelect={this.changeValues} selected={item.TypeId} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleSelect label={intl.formatMessage({id:"organization.settings.called.origin"})} options={this.state.OriginRequestList} stateName='OriginRequestId' changeSelect={this.changeValues} selected={item.OriginRequestId} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleSelect label={intl.formatMessage({id:"default.type"})} options={this.state.StatusList} stateName='StatusId' changeSelect={this.changeValues} selected={item.StatusId} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleSelect label={intl.formatMessage({id:"attendance.team"})} options={this.state.TechinicalTeamList} stateName='TechinicalTeamId' changeSelect={this.changeValues} selected={item.TechinicalTeamId} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleSelect label={intl.formatMessage({id:"default.techinical"})} options={this.state.DefaultTechnicalList} stateName='DefaultTechinicalId' changeSelect={this.changeValues} selected={item.DefaultTechinicalId} />
                    </Grid>
                    <Grid item xs={11.5} sm={11.5}>
                      <SimpleCheckBox label={intl.formatMessage({id:"save.to.all.organizations.in.group"})} changeSelect={this.changeValues}  selected={item.savetoallorganizationsingroup} stateName='savetoallorganizationsingroup'/>
                    </Grid>
                    <Grid item xs={0.5} sm={0.5} style={{ textAlign: 'right', alignSelf: 'stretch', marginTop:'13px' } }>
                      <Tooltip title={intl.formatMessage({id:"save.team.to.all.orgs.in.group.tooltip"})}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={11.5} sm={11.5}>
                      <TextField id='filled-number' label={intl.formatMessage({id:"organization.settings.expire"})} type='number' variant='filled' InputLabelProps={{shrink: true}} fullWidth name='estimatedanwserhours' onChange={this.handleInputChange} value={item.estimatedanwserhours} required />
                    </Grid>

                    <Grid item xs={0.5} sm={0.5} style={{ textAlign: 'right', alignSelf: 'stretch', marginTop:'13px' } }>
                    <Tooltip title={intl.formatMessage({id:"organization.settings.expire.tooltip"})}>
                      <HelpIcon />
                    </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={11.5}>
                      <SimpleSelect label={intl.formatMessage({id:"which.assigned.show.to.client"})} options={this.state.displayAssignedInfoModeOptions} stateName='displayAssignedInfoMode' changeSelect={this.changeValues} selected={item.displayAssignedInfoMode} />
                    </Grid>
                    <Grid item xs={0.5} sm={0.5} style={{ textAlign: 'right', alignSelf: 'stretch', marginTop:'13px' } }>
                      <Tooltip title={<pre>{intl.formatMessage({ id: "displayAssignedInfoMode.help" })}</pre>}>
                        <HelpIcon />
                      </Tooltip>
                    </Grid>
                  <Grid item xs={12} sm={12}>
                  <Typography>
                    Campo informativo
                  </Typography>
                  <ReactQuill
                    name="description"
                    value={item.informativetext || ''}
                    style={{ minHeight: 200 }}
                    fullWidth
                    modules={this.reactQuillModules}
                    formats={this.reactQuillFormats}
                    required
                    InputLabelProps={{ shrink: item.informativetext && item.informativetext.length > 0 ? true : false }}
                    variant="outlined"
                    id="outlined-multiline-static"
                    onChange={this.handleChangeEditor} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                  <Tabs >
                    <TabList style={{ borderColor: '#BB8FCE' }}>
                      <Tab style={{ fontSize: "14px", display: 'inline-flex' }}>Geral (Chamados)</Tab>
                      <Tab style={{ fontSize: "14px", display: 'inline-flex' }}>Cliente</Tab>
                      <Tab style={{ fontSize: "14px", display: 'inline-flex' }}>Responsável (Técnico/Agente)</Tab>
                      <Tab style={{ fontSize: "14px", display: 'inline-flex' }}>Exibição de campos</Tab>
                      <Tab style={{ fontSize: "14px", display: 'inline-flex' }}>Administração</Tab>
                      <Tab style={{ fontSize: "14px", display: 'inline-flex' }}>Categorias</Tab>
                      <Tab style={{ fontSize: "14px", display: 'inline-flex' }}>2MOVE</Tab>
                      <Tab style={{ fontSize: "14px", display: 'inline-flex' }}>Integrações</Tab>
                    </TabList>
                    <TabPanel>
                      <Grid container spaging={2}>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({id:"organization.setting.edit.obligatory.called.evaluation"})} name='requiredratingcalleds' stateName='requiredratingcalleds' changeSelect={this.changeValues} selected={item.requiredratingcalleds} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({id:"organization.setting.edit.obligatory.called.evaluation.title"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({id:"restrict.view.called.departament"})} name='restrictviewcalleddepartament' stateName='restrictviewcalleddepartament' changeSelect={this.changeValues} selected={item.restrictviewcalleddepartament} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({id:"organization.setting.registration.user.views.only.calleds.from.own.departament"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({id:"organizations.settings.isrequiredselectteamincalledfinish"})} name='isrequiredselectteamincalledfinish' stateName='isrequiredselectteamincalledfinish' changeSelect={this.changeValues} selected={item.isrequiredselectteamincalledfinish} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({id:"organizations.settings.isrequiredselectteamincalledfinish.info"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({id:"organization.setting.edit.obligatory.image.attachments"})} name='requireattachedimage' stateName='requireattachedimage' changeSelect={this.changeValues} selected={item.requireattachedimage} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({id:"organization.setting.registration.obligatory.accompaniment.image.attachment"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={this.props.intl.formatMessage({id:"can.organization.archive.closed.calleds"})} name='canarchivecalleds' stateName='canarchivecalleds' changeSelect={this.changeValues} selected={item.canarchivecalleds} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.can.organization.archive.closed.calleds"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={this.props.intl.formatMessage({id:"organizations.settings.disablecallrejectionbyrequester"})} name='disablecallrejectionbyrequester' stateName='disablecallrejectionbyrequester' changeSelect={this.changeValues} selected={item.disablecallrejectionbyrequester} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={this.props.intl.formatMessage({id:"organizations.settings.disablecallrejectionbyrequester.info"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={this.props.intl.formatMessage({id:"organizations.settings.caneditaccompanimentinactivecalled"})} name='caneditaccompanimentinactivecalled' stateName='caneditaccompanimentinactivecalled' changeSelect={this.changeValues} selected={item.caneditaccompanimentinactivecalled} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={this.props.intl.formatMessage({id:"organizations.settings.caneditaccompanimentinactivecalled.info"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={this.props.intl.formatMessage({id:"organizations.settings.actionrequiredwhenstatusorteamorassignedchanges"})} name='actionrequiredwhenstatusorteamorassignedchanges' stateName='actionrequiredwhenstatusorteamorassignedchanges' changeSelect={this.changeValues} selected={item.actionrequiredwhenstatusorteamorassignedchanges} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={this.props.intl.formatMessage({id:"organizations.settings.actionrequiredwhenstatusorteamorassignedchanges.info"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox disabled={this.state.enableSendingSatisfactionSurveyThroughMessagingProvidersDisabled} label={this.props.intl.formatMessage({id:"enable.sending.satisfaction.survey.through.messaging.providers"})} name='enablesendingsatisfactionsurveythroughmessagingproviders' stateName='enablesendingsatisfactionsurveythroughmessagingproviders' changeSelect={this.changeValues} selected={item.enablesendingsatisfactionsurveythroughmessagingproviders} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={this.props.intl.formatMessage({id:"this.feature.enables.sending.satisfaction.survey.at.the.end.of.a.ticket.via.messaging.providers"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        {/* {item.enablesendingsatisfactionsurveythroughmessagingproviders == true &&
                        <Grid item xs={1} style={{marginTop:'13px'}}/>
                         
                        } */}
                        {item.enablesendingsatisfactionsurveythroughmessagingproviders == true && this.state.enableSendingSatisfactionSurveyThroughMessagingProvidersDisabled == false &&
                          <Grid item xs={11} style={{marginTop:'13px'}} >
                            <Autocomplete
                              options={this.state.registrationNumberRequestList}
                              
                              getOptionLabel={(option) => option.phonenumber}
                              onChange={(event, value) => this.changeValues('registrationnumberrequestid', value)}
                              renderInput={(params) => <TextField {...params} label="Selecione o telefone" variant="outlined" />}
                              style={{ width: 300 }}
                               value={item.registrationnumberrequestid}
                          />
                          </Grid>
                        }
                         {item.enablesendingsatisfactionsurveythroughmessagingproviders == true && this.state.enableSendingSatisfactionSurveyThroughMessagingProvidersDisabled == false &&
                          <Grid item xs={11} style={{marginTop:'13px'}} >
                            <Autocomplete
                              options={this.state.templateMessageList}
                              
                              getOptionLabel={(option) => option.name}
                              onChange={(event, value) => this.changeValues('templatemessageid', value)}
                              renderInput={(params) => <TextField {...params} label="Selecione o modelo" variant="outlined" />}
                              style={{ width: 300 }}
                               value={item.templatemessageid}
                          />
                          </Grid>
                        }
                      </Grid>
                    </TabPanel>
                    <TabPanel>
                      <Grid container spaging={2}>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({id:"organization.settings.cliente.categoria"})} name='roleclientcanselectcategory' stateName='roleclientcanselectcategory' changeSelect={this.changeValues} selected={item.roleclientcanselectcategory} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({id:"organization.setting.edit.functionality.client.category"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox 
                            label={this.props.intl.formatMessage({id:"organization.edit.2talk.config"})} 
                            name='enable2talk' 
                            stateName='enable2talk' 
                            changeSelect={this.changeValues}
                            disabled={!this.state.enable2talk}
                            selected={item.enable2talk} 
                          />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={this.props.intl.formatMessage({id:"organization.edit.2talk.config.info"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({ id: "organizations.settings.canclientselecttypeincalled" })} name='canclientselecttypeincalled' stateName='canclientselecttypeincalled' changeSelect={this.changeValues} selected={item.canclientselecttypeincalled} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({ id: "organizations.settings.canclientselecttypeincalled.info" })}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({ id: "organizations.settings.canclientviewslametricsincalled" })} name='canclientviewslametricsincalled' stateName='canclientviewslametricsincalled' changeSelect={this.changeValues} selected={item.canclientviewslametricsincalled} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({ id: "organizations.settings.canclientviewslametricsincalled.info" })}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({id:"organization.setting.edit.client.only.views.own.calls"})} name='clientvisualization' stateName='clientvisualization' changeSelect={this.changeValues} selected={item.clientvisualization} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({id:"organization.setting.edit.functionality.enable.client.view.only.own.called"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={this.props.intl.formatMessage({id:"organization.setting.can.client.finish.called"})} name='canclientfinishcalled' stateName='canclientfinishcalled' changeSelect={this.changeValues} selected={item.canclientfinishcalled} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.can.client.finish.called.info"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={this.props.intl.formatMessage({id:"organization.setting.can.client.reopen.called"})} name='canclientreopencalled' stateName='canclientreopencalled' changeSelect={this.changeValues} selected={item.canclientreopencalled} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.can.client.reopen.called.info"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={this.props.intl.formatMessage({id:"organization.setting.can.client.automatic.change.status.after.send.accompaniment"})} name='canclientautomaticchangestatusaftersendaccompaniment' stateName='canclientautomaticchangestatusaftersendaccompaniment' changeSelect={this.changeValues} selected={item.canclientautomaticchangestatusaftersendaccompaniment} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={this.props.intl.formatMessage({id:"organization.setting.can.client.automatic.change.status.after.send.accompaniment.info"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={this.props.intl.formatMessage({id:"organizations.settings.allowclientinteractiononclosedcalleds"})} name='allowclientinteractiononclosedcalleds' stateName='allowclientinteractiononclosedcalleds' changeSelect={this.changeValues} selected={item.allowclientinteractiononclosedcalleds} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={this.props.intl.formatMessage({id:"organizations.settings.allowclientinteractiononclosedcalleds.info"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel>
                      <Grid container spaging={2}>
                        <Grid item xs={10} sm={7}>
                          <SimpleCheckBox label={intl.formatMessage({id:"organization.settings.responsavel.chamado"})} name='canassingusercalled' stateName='canassingusercalled' changeSelect={this.changeValues} selected={item.canassingusercalled} />
                        </Grid>
                        <Grid item xs={2} sm={5} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({id:"organization.setting.edit.functionality.responsible.pick.called"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({id:"organization.settings.allow.call"})} name='canviewcalledfromothertechnical' stateName='canviewcalledfromothertechnical' changeSelect={this.changeValues} selected={item.canviewcalledfromothertechnical} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({id:"organization.setting.edit.functionality.responsible.view.calleds"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({ id: "organizations.settings.caninformcalledclosingstatus" })} name='caninformcalledclosingstatus' stateName='caninformcalledclosingstatus' changeSelect={this.changeValues} selected={item.caninformcalledclosingstatus} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({ id: "organizations.settings.caninformcalledclosingstatus.info" })}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({ id: "organizations.settings.caninforminitialchecklist" })} name='caninforminitialchecklist' stateName='caninforminitialchecklist' changeSelect={this.changeValues} selected={item.caninforminitialchecklist} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({ id: "organizations.settings.caninforminitialchecklist.info" })}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        {item.caninforminitialchecklist && (<Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({ id: "organizations.settings.caninitialchecklistisrequired" })} name='caninitialchecklistisrequired' stateName='caninitialchecklistisrequired' changeSelect={this.changeValues} selected={item.caninitialchecklistisrequired} />
                        </Grid>)}
                        {item.caninforminitialchecklist && (<Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({ id: "organizations.settings.caninitialchecklistisrequired.info" })}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>)}
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({id:"organization.setting.edit.allow.technician.finish.attendance"})} name='finalizationexternalstatus' stateName='finalizationexternalstatus' changeSelect={this.changeValues} selected={item.finalizationexternalstatus} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({id:"organization.setting.edit.show.finished.attendance.status"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel>
                      <Grid container spaging={2}>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({id:"organization.setting.edit.can.attach.patrimonies.to.called"})} name='canselectpatrimonyincalled' stateName='canselectpatrimonyincalled' changeSelect={this.changeValues} selected={item.canselectpatrimonyincalled} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({id:"organization.setting.edit.functionality.create.called.add.patrimony"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        {this.state.item.canselectpatrimonyincalled && (
                            <Grid item xs={10} sm={6}>
                              <SimpleCheckBox label={intl.formatMessage({id:"organization.setting.edit.create.called.read.qrcode"})} name='automaticallycreatecalledwhenreadingqr' stateName='automaticallycreatecalledwhenreadingqr' changeSelect={this.changeValues} selected={item.automaticallycreatecalledwhenreadingqr} />
                            </Grid>)}
                        {this.state.item.canselectpatrimonyincalled && (
                            <Grid item xs={2} sm={6} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                              <Tooltip title={intl.formatMessage({id:"organization.setting.edit.add.patrimony.to.called.create.called.read.qrcode"})}>
                                <HelpIcon />
                              </Tooltip>
                            </Grid>)}
                        {this.state.item.canselectpatrimonyincalled && (
                            <Grid item xs={10} sm={5}>
                              <SimpleCheckBox label={intl.formatMessage({ id: "organizations.settings.canclientselectpatrimonyincalled" })} name='canclientselectpatrimonyincalled' stateName='canclientselectpatrimonyincalled' changeSelect={this.changeValues} selected={item.canclientselectpatrimonyincalled} />
                            </Grid>)}
                        {this.state.item.canselectpatrimonyincalled && (
                            <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                              <Tooltip title={intl.formatMessage({ id: "organizations.settings.canclientselectpatrimonyincalled.info" })}>
                                <HelpIcon />
                              </Tooltip>
                            </Grid>
                        )}
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({id:"organization.setting.edit.can.attach.departament.to.called"})} name='canselectdepartamentincalled' stateName='canselectdepartamentincalled' changeSelect={this.changeValues} selected={item.canselectdepartamentincalled} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({id:"organization.setting.edit.functionality.create.called.add.departament"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({id:"organization.setting.edit.can.select.parent.called.in.called"})} name='canselectparentcalledincalled' stateName='canselectparentcalledincalled' changeSelect={this.changeValues} selected={item.canselectparentcalledincalled} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({id:"organization.setting.edit.can.select.parent.called.in.called.info"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({id:"organization.setting.edit.can.select.displacement.in.called"})} name='canselectdisplacementincalled' stateName='canselectdisplacementincalled' changeSelect={this.changeValues} selected={item.canselectdisplacementincalled} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({id:"organization.setting.edit.can.select.displacement.in.called.info"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({id:"organization.setting.edit.can.select.hour.cost.or.called.cost.in.called"})} name='canselecthourcostorcalledcostincalled' stateName='canselecthourcostorcalledcostincalled' changeSelect={this.changeValues} selected={item.canselecthourcostorcalledcostincalled} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({id:"organization.setting.edit.can.select.hour.cost.or.called.cost.in.called.info"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({id:"organizations.settings.caninformutilizedmaterialsincalledfinish"})} name='caninformutilizedmaterialsincalledfinish' stateName='caninformutilizedmaterialsincalledfinish' changeSelect={this.changeValues} selected={item.caninformutilizedmaterialsincalledfinish} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({id:"organizations.settings.caninformutilizedmaterialsincalledfinish.info"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({id:"display.default.accompaniments"})} name='defaultaccompaniment' stateName='defaultaccompaniment' changeSelect={this.changeValues} selected={item.defaultaccompaniment} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({id:"organization.setting.registration.user.can.select.standard.actions"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({id:"organizations.settings.canselectoriginrequestincalledcreation"})} name='canselectoriginrequestincalledcreation' stateName='canselectoriginrequestincalledcreation' changeSelect={this.changeValues} selected={item.canselectoriginrequestincalledcreation} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({id:"organizations.settings.canselectoriginrequestincalledcreation.info"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel>
                      <Grid container spaging={2}>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({ id: "organizations.settings.caninformzeroincalledtechnicalcost" })} name='caninformzeroincalledtechnicalcost' stateName='caninformzeroincalledtechnicalcost' changeSelect={this.changeValues} selected={item.caninformzeroincalledtechnicalcost} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({ id: "organizations.settings.caninformzeroincalledtechnicalcost.info" })}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({ id: "organizations.settings.canfinalchecklistisrequired" })} name='canfinalchecklistisrequired' stateName='canfinalchecklistisrequired' changeSelect={this.changeValues} selected={item.canfinalchecklistisrequired} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({ id: "organizations.settings.canfinalchecklistisrequired.info" })}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({ id: "allow.change.of.organization.of.called.by.administration" })} name='allowchangeoforganizationofcalledbyadministration' stateName='allowchangeoforganizationofcalledbyadministration' changeSelect={this.changeValues} selected={item.allowchangeoforganizationofcalledbyadministration} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({ id: "allow.change.of.organization.of.called.by.administration.info" })}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({ id: "redirect.the.user.after.completing.the.called" })} name='redirecttheuseraftercompletingthecalled' stateName='redirecttheuseraftercompletingthecalled' changeSelect={this.changeValues} selected={item.redirecttheuseraftercompletingthecalled} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({ id: "redirect.the.user.after.completing.the.called.info" })}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel>
                      <Grid container spaging={2}>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({ id: "organizations.settings.canselectorganizationcategoriesbylevels" })} name='canselectorganizationcategoriesbylevels' stateName='canselectorganizationcategoriesbylevels' changeSelect={this.changeValues} selected={item.canselectorganizationcategoriesbylevels} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({ id: "organizations.settings.canselectorganizationcategoriesbylevels.info" })}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel>
                      <Grid container spacing={2}>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({id:"organizations.settings.automaticchangestatusaftermobilefinish"})} name='automaticchangestatusaftermobilefinish' stateName='automaticchangestatusaftermobilefinish' changeSelect={this.changeValues} selected={item.automaticchangestatusaftermobilefinish} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({id:"organizations.settings.automaticchangestatusaftermobilefinish.info"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({id:"organizations.settings.cantechnicalopencustomerreviewlinkonmobile"})} name='cantechnicalopencustomerreviewlinkonmobile' stateName='cantechnicalopencustomerreviewlinkonmobile' changeSelect={this.changeValues} selected={item.cantechnicalopencustomerreviewlinkonmobile} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({id:"organizations.settings.cantechnicalopencustomerreviewlinkonmobile.info"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel>
                      <Grid container spacing={2}>
                        <Grid item xs={10} sm={6}>
                          <SimpleCheckBox 
                            label={intl.formatMessage({id:"organization.settings.videocall"})} 
                            name='canstartcallcenter' 
                            stateName='canstartcallcenter' 
                            changeSelect={this.changeValues} 
                            selected={item.canstartcallcenter} 
                            disabled={!item.canstartcallcenter ? this.state.disabledvertex : false}/>
                        </Grid>
                        <Grid item xs={2} sm={6} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({id:"organization.setting.edit.functionality.video.call"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({id:"organization.settings.allow.call.email"})} name='allowscreateanonymouscalledsbyemail' stateName='allowscreateanonymouscalledsbyemail' changeSelect={this.changeValues} selected={item.allowscreateanonymouscalledsbyemail} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({id:"organization.setting.edit.functionality.create.called.from.email"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs={10} sm={5}>
                          <SimpleCheckBox label={intl.formatMessage({id:"organization.setting.edit.allow.chat.attendance"})} name='allowattendanceviachat' stateName='allowattendanceviachat' changeSelect={this.changeValues} selected={item.allowattendanceviachat} />
                        </Grid>
                        <Grid item xs={2} sm={7} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                          <Tooltip title={intl.formatMessage({id:"wizard.pages.configuration.allow.chat.attendance.info"})}>
                            <HelpIcon />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </TabPanel>
                  </Tabs >
                  </Grid>
                </Grid>
                <Grid container spacing={0}  style={{marginBottom: '60px', display: 'flex', marginTop:'20px'}}>
                
                <Grid item xs={12} sm={2} >
                      {this.props.backButton}


                  </Grid>

                  <Grid item xs={12} sm={2} >
                  {this.props.nextButton} 


                  </Grid>
                  <Grid item xs={12} sm={6} style={{marginLeft:'auto'}}>
                        <Button 
                          
              
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                         
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                  </Grid>
              
            
             </Grid>

              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        
                   
     </div>
    );
  }
}
OrganizationSettingRegistration.propTypes = {
                    classes: PropTypes.object.isRequired,
                    back: PropTypes.func,
                    next: PropTypes.func
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                    
                });
            export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(OrganizationSettingRegistration)));