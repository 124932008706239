import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Grid } from '@mui/material';
import SimpleAutoComplete from '../../../components/auto-complete/autocomplete';
import {  IconButton } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';

const { forwardRef, useImperativeHandle } = React;

//function MainWindow(props) {
const MainWindow = forwardRef((props, ref) => {
  const { startCall, clientId, onlinelist, friend, calledId, showEditor,handleClose } = props;
  const [friendID, setFriendID] = useState(friend ? friend : '');
  const [user, setUser] = useState(null);
  const intl = this.props.intl;
  //console.log(onlinelist);

  useImperativeHandle(ref, () => ({

    Refresh() {
      //console.log("refresh Child from parent");
    }

  }));

  React.useEffect(() => {
      //console.log('mounted')
      props.setRefresh(refresh);
    });

  const refresh = () =>  {
    //console.log("refresh Child from parent");
    setUser({'id':'', 'name':''});
    setFriendID('');

  }
//  React.useEffect(() => {
    //console.log('update');
//    setFriendID(friend);
//  }, [friend]);

  const refreshHandler  = (e) => {
    if (typeof props.refreshUser === 'function') {
        props.refreshUser(calledId);
    }
  }

  const formattedList = onlinelist.map(item => {
    return {
        ...item.user,
        key: item.key
    }

    });

    //console.log(formattedList);

  /**
   * Start the call with or without video
   * @param {Boolean} video
   */
  const callWithVideo = (video) => {
    const config = { audio: true, video };
    return () => friendID && startCall(true, friendID, config);
  };
  const openEditor = (show, image = '') => {
    return () => showEditor(show,image);
  };
  const changeValues = (stateName, value, text = '') => {
    setUser(value);
    setFriendID(value.key);
    //console.log('changed');
  };

  const closeModal = () => {
    return () => handleClose();
  };

  return (
    <div className="container main-window">
      <Grid container spacing={4}>
        <Grid item xs={11} sm={6}>
          <div>
            <h3>
              {intl.formatMessage({id:"videocall.mainwindow.your.contact.id"})}
              <input
                type="text"
                className="txt-clientId"
                defaultValue={clientId}
                readOnly
              />
            </h3>
            <h4>{intl.formatMessage({id:"videocall.mainwindow.inform.contact.id"})}</h4>
          </div>
        </Grid>
        <Grid item xs={11} sm={6}>

          <Grid container justify="center">
              <Grid item sm={8}>
                <SimpleAutoComplete label={intl.formatMessage({id:"videocall.mainwindow.available.users"})} options={formattedList} stateName="user" changeSelect={changeValues} selected={user} />
              </Grid>
              <Grid item sm={2}>
                  <IconButton color="inherit"
                  aria-label="open drawer"
                  onClick={refreshHandler}
                  edge="start" title={intl.formatMessage({id:"update"})}
                >
                  <CachedIcon />
                </IconButton>
              </Grid>
          </Grid>

        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={11} sm={12}>

            <div>
              <input
                type="text"
                className="txt-clientId"
                spellCheck={false}
                placeholder="ID"
                value={friendID}
                onChange={event => setFriendID(event.target.value)}
              />
              <div>
                <button
                  type="button"  title={intl.formatMessage({id:"videocall.mainwindow.start.video.audio.call"})}
                  className="btn-action fa fa-video-camera"
                  onClick={callWithVideo(true)}
                />
                <button
                  type="button"  title={intl.formatMessage({id:"videocall.mainwindow.start.audio.call"})}
                  className="btn-action fa fa-phone"
                  onClick={callWithVideo(false)}
                />
                <button
                  type="button"  title={intl.formatMessage({id:"videocall.mainwindow.open.image.editor"})}
                  className="btn-action fa fa-picture-o"
                  onClick={openEditor(true)}
                />
                <button
                  type="button"  title={intl.formatMessage({id:"close"})}
                  className="btn-action fa fa-times-circle"
                  onClick={closeModal()}
                />

              </div>
            </div>
            </Grid>
      </Grid>

    </div>
  );
});

MainWindow.propTypes = {
  clientId: PropTypes.string.isRequired,
  startCall: PropTypes.func.isRequired,
  refreshUser: PropTypes.func.isRequired
};

export default MainWindow;
