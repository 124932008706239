export default function additionalcalledcosts(state = [], action) {
  switch (action.type) {
      case 'ADD_CURRENT':
          return {
              ...state,
              additionalcalledcost: action.additionalcalledcosts,
          }
      default:
          return state;
  }
}