import React, { Component, Fragment } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Typography,
  CircularProgress,
  Container,
} from "@mui/material";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { withStyles } from "@mui/styles";
import { Styles } from "../../../styles/material-styles";
import { Overlay } from "../../../styles/global";
import Api from "../../../services/api";
import CustomizedSnackbars from "../../../components/material-snackbars";
import { connect } from "react-redux";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

class PasswordReset extends Component {
  constructor(props) {
    super(props);

    this.ButtonExpired = null

    this.state = {
      passwordResetForm: {
        email: "",
        password: "",
        confirmPassword: "",
        phone: "",
        confirmPhone: "",
        active: true,
        isResponsiblePhone: false,
        codeAccess: "",
        responsibleMail: ""
      },
      buttonRender: false,
      emailExpired: null,
      loading: false,
      disabledButtons: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      userLogged: false,
      step: 1
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this)
    this.handleChangeConfirmPhone = this.handleChangeConfirmPhone.bind(this)
    this.validatePassword = this.validatePassword.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.resendEmail = this.resendEmail.bind(this);
  }

  handleChangePhone(e) {
    this.setState(prevState => ({
      passwordResetForm: {
        ...prevState.passwordResetForm,
        ['phone']: e
      }
    }));
  }

  handleChangeConfirmPhone(e) {
    this.setState(prevState => ({
      passwordResetForm: {
        ...prevState.passwordResetForm,
        ['confirmPhone']: e
      }
    }));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {
    if (this.props.match.params.token) {
      this.getUser(this.props.match.params.token);
    }
  }

  mtel(v) {
    v = v.replace(/\D/g, "");
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
    v = v.replace(/(\d)(\d{4})$/, "$1-$2");
    return v;
  }

  getUser(token) {
    const intl = this.props.intl;
    this.setState({ loading: true });
    Api.get(`/hirer/verifyTokenHirer/${token}`)
      .then((result) => {
        try {
          if (result.data.success) {

            const { email, phone, responsiblephone, responsiblemail } = result.data.data;
            if ((phone == null && responsiblephone == null)) {
              this.setState({
                loading: false,
                disabledButtons: true,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({id:"admin.empty.phone"})
              });
              document.getElementById("submit").classList.add("Mui-disabled");
            } else {

              var bnext = true;
              if (phone != null) {
                if (phone.toString().trim() == "") {
                  bnext = false;
                }
              }
              else {
                bnext = false;
              }
              if (bnext == false) {
                bnext = true;
                if (responsiblephone != null) {
                  if (responsiblephone.toString().trim() == "") {
                    bnext = false;
                  }
                }
                else {
                  bnext = false;
                }
              }

              if (!bnext) {
                this.setState({
                  loading: false,
                  disabledButtons: true,
                  openNotification: true,
                  notificationVariant: "error",
                  notificationMessage: intl.formatMessage({id:"admin.empty.phone"})
                });
                document.getElementById("submit").classList.add("Mui-disabled");

              }
              else {
                this.setState((prevState) => ({
                  loading: false,
                  passwordResetForm: {
                    ...prevState.passwordResetForm,
                    email,
                    phone: responsiblephone ? this.mtel(responsiblephone) : phone ? this.mtel(phone) : "",
                    isResponsiblePhone: responsiblephone ? true : false,
                    responsibleMail: responsiblemail
                  },
                }));
              }
            }

          } else {
            document.getElementById("submit").classList.add("Mui-disabled");
          }
        }
        catch (e) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            disabledButtons: true,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: intl.formatMessage({id:"process.error.and.message"}) + e.message,
          });
        }
      })
      .catch((err) => {
        const intl = this.props.intl;
        this.setState({
          loading: false,
          disabledButtons: true,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            err.response && err.response.data && err.response.data.errors
              ? err.response.data.errors[0]
              : this.props.intl.formatMessage({id:"process.error"}),
        });
      });
  }

  addButtonExpiredToken() {

    const intl = this.props.intl;

    this.setState({ loading: true });
    Api.get(`/users/get-user-by-token/${this.props.match.params.token}`)
      .then((result) => {
        console.log(result)
        if (result.data.success) {

          this.setState({
            emailExpired: result.data.data.email,
            passwordResetForm: {
              email: result.data.data.email,
            },
          });

        }
      })
      .catch((err) => {

        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            err.response && err.response.data && err.response.data.errors
              ? err.response.data.errors[0]
              : this.props.intl.formatMessage({id:"process.error"}),
        });

      });

    const { classes } = this.props;
    this.ButtonExpired =
      <div className={classes.form} >
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={this.resendEmail}

        >
          {intl.formatMessage({id:"resend.activation.link"})}
        </Button>
      </div>

    this.setState({ buttonRender: true, loading: false });



  }

  resendEmail() {
    const intl = this.props.intl;
    console.log(this.state)

    this.setState({ loading: true });
    let data = new FormData();
    data.append("email", this.state.emailExpired);


    Api.post("/users/resendExpiredEmail", data)
      .then(result => {
        if (result.data.success) {
          this.setState((prevState) => ({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({id:"email.sent"}),

          }));
        }
      })
      .catch(err => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
      });

  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      passwordResetForm: {
        ...prevState.passwordResetForm,
        [name]: value,
      },
    }));
    if (e.target.name === "phone") {
      this.setState((prevState) => ({
        passwordResetForm: {
          ...prevState.passwordResetForm,
          ["phone"]: this.mtel(value),
        },
      }));
    }
    if (e.target.name === "confirmPhone") {
      this.setState((prevState) => ({
        passwordResetForm: {
          ...prevState.passwordResetForm,
          ["confirmPhone"]: this.mtel(value),
        },
      }));
    }
    if (e.target.name === "codeAccess") {
      this.setState((prevState) => ({
        passwordResetForm: {
          ...prevState.passwordResetForm,
          ["codeAccess"]: value.toUpperCase(),
        },
      }));
    }
  }

  handleSubmit = async (e) => {
    const intl = this.props.intl;
    e.preventDefault();

    if (this.validatePhone()) {
      this.setState((prevState) => ({
        loading: true,
        passwordResetForm: {
          ...prevState.passwordResetForm,
          phone: phone.replace(/\D/gim, ""),
        },
      }));

      const { email, phone, isResponsiblePhone } = this.state.passwordResetForm;

      const ConfirmationGuid = this.props.match.params.token;

      Api.post("/hirer/sendCodePhone", { email, phone, ConfirmationGuid, isResponsiblePhone })
        .then((result) => {
          if (result.data.success) {
            this.setState((prevState) => ({
              loading: false,
              openNotification: true,
              notificationVariant: "success",
              notificationMessage: intl.formatMessage({id:"sms.sent"}),
              passwordResetForm: {
                ...prevState.passwordResetForm,
                phone: this.mtel(phone),
              },
              step: 2
            }));
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({id:"process.error"}),
          });
        });
    }


  };

  validatePassword() {
    const intl = this.props.intl;
    const { passwordResetForm } = this.state;
    if (passwordResetForm.password !== passwordResetForm.confirmPassword) {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"password.reset.passwords.dont.match"}),
      });
      return false;
    } else if (passwordResetForm.password.replace(' ', '') == "") {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"admin.account.confirmation.missing.password.field"}),
      });
      return false;
    } else {
      this.closeNotification();
      return true;
    }
  }

  validatePhone() {
    const intl = this.props.intl;
    const { passwordResetForm } = this.state;
    if (passwordResetForm.phone.replace(' ', '').replace('(', '').replace(')', '').replace('-', '') !== passwordResetForm.confirmPhone.replace(' ', '').replace('(', '').replace(')', '').replace('-', '')) {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"phones.dont.match"}),
      });
      return false;
    } else {
      this.closeNotification();
      return true;
    }
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  confirmationCodeAndPassword = async (e) => {
    e.preventDefault();
    const intl = this.props.intl;
    if (this.validatePassword()) {
      this.setState({
        loading: true,
      });

      const { codeAccess, isResponsiblePhone, password } = this.state.passwordResetForm;

      const ConfirmationGuid = this.props.match.params.token;

      Api.post("/hirer/phoneConfirmation", { ConfirmationGuid, codeAccess, isResponsiblePhone, password })
        .then((result) => {
          if (result.data.success && result.data.data !== false) {
            this.setState((prevState) => ({
              loading: false,
              openNotification: true,
              notificationVariant: "success",
              notificationMessage: intl.formatMessage({id:"enabled.success"})
            }));
            setTimeout(() => {
              document.location.href = "../"
            }, 1000);
          } else {
            this.setState((prevState) => ({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"error.occurred.try.again.later"}),
            }));
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : this.props.intl.formatMessage({id:"process.error"}),
          });
        });
    }

  }

  render() {
    const { classes } = this.props;
    const { passwordResetForm, userLogged } = this.state;
    const intl = this.props.intl;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            {" "}
            <CompareArrowsIcon />{" "}
          </Avatar>
          <Typography component="h1" variant="h5">
            {" "}
            {intl.formatMessage({id:"confirm.phone"})}{" "}
          </Typography>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <TextField
              margin="normal"
              variant="outlined"
              required
              fullWidth
              id="email"
              label={this.props.intl.formatMessage({id:"email"})}
              name="email"
              autoComplete="email"
              value={passwordResetForm.email}
              disabled
            />

            <TextField
              margin="normal"
              variant="outlined"
              required
              fullWidth
              id="responsibleMail"
              label={intl.formatMessage({id:"responsable.email"})}
              name="responsibleMail"
              autoComplete="responsibleMail"
              value={passwordResetForm.responsibleMail}
              disabled
            />
            <PhoneInput
              inputStyle={{
                padding: '23.5px 14px 23.5px 58px',
                width: '100%'
              }}
              style={{
                marginTop: 16,
                marginBottom: 8
              }}
              margin="normal"
              id="phone"
              name="phone"
              country={'br'}
              value={passwordResetForm.phone}
              onChange={this.handleChangePhone}
              InputLabel={intl.formatMessage({id:"phone"})}
              specialLabel={intl.formatMessage({id:"phone"})}
              disabled={true}
              inputProps={{
                name: intl.formatMessage({id:"phone"})
              }} />

            <PhoneInput
              inputStyle={{
                padding: '23.5px 14px 23.5px 58px',
                width: '100%'
              }}
              style={{
                marginTop: 16,
                marginBottom: 8
              }}
              margin="normal"
              id="confirmPhone"
              name="confirmPhone"
              country={'br'}
              value={passwordResetForm.confirmPhone}
              onChange={this.handleChangeConfirmPhone}
              InputLabel={intl.formatMessage({id:"confirm.phone"})}
              specialLabel={intl.formatMessage({id:"confirm.phone"})}
              disabled={false}
              inputProps={{
                name: intl.formatMessage({id:"confirm.phone"})
              }} />

            {this.state.step == 2 && (
              <>
                <TextField
                  margin="normal"
                  variant="outlined"
                  required
                  fullWidth
                  inputProps={{ maxLength: 6 }}
                  name="codeAccess"
                  label={intl.formatMessage({id:"access.code"})}
                  id="codeAccess"
                  value={passwordResetForm.codeAccess}
                  onChange={this.handleInputChange}
                />

                <Button
                  color="admin"
                  disabled={this.state.disabledButtons}
                  onClick={this.handleSubmit}
                >
                  {intl.formatMessage({id:"resend.sms"})}
                </Button>
              </>
            )}

            {this.state.step == 2 && (
              <>
                <Typography style={{ textAlign: "center" }} component="h1" variant="h5">
                  {" "}{intl.formatMessage({id:"register.password"})}{" "}
                </Typography>
                <TextField
                  margin="normal"
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label={intl.formatMessage({id:"passwords"})}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  inputProps={{ maxLength: 32, minLength: 6 }}
                  value={passwordResetForm.password}
                  onChange={this.handleInputChange}
                  disabled={this.state.disabledButtons}
                />
                <TextField
                  margin="normal"
                  variant="outlined"
                  required
                  fullWidth
                  name="confirmPassword"
                  label={this.props.intl.formatMessage({id:"confirm.password"})}
                  type="password"
                  id="confirmPassword"
                  autoComplete="current-confirmPassword"
                  inputProps={{ maxLength: 32, minLength: 6 }}
                  value={passwordResetForm.confirmPassword}
                  onChange={this.handleInputChange}
                  disabled={this.state.disabledButtons}
                />
              </>
            )}

            {this.state.step == 1 && (
              <Button
                fullWidth
                variant="contained"
                color="admin"
                className={classes.submit}
                id="submit"
                disabled={this.state.disabledButtons}
                onClick={this.handleSubmit}
              >
                {this.props.intl.formatMessage({id:"confirm"})}
              </Button>
            )}
            {this.state.step == 2 && (
              <Button
                onClick={this.confirmationCodeAndPassword}
                fullWidth
                variant="contained"
                color="admin"
              >
                {this.props.intl.formatMessage({id:"confirm"})}
              </Button>
            )}


          </form>

        </div>

        {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}

        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification}
        />
      </Container>
    );
  }
}

PasswordReset.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userSession: state.userSession,
});

export default connect(mapStateToProps)(withStyles(Styles)(PasswordReset));
