import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { AbilityContext } from '../../config/ability-context'
import Base from '../../components/layout-base/base';
import AssociateNewUsersContent from './index.content';

class AssociateNewUsers extends Component {

    constructor(props) {
        super(props);

    }

    componentWillReceiveProps(nextProps) {
    }

    componentDidMount() {
    }
    render() {

        return (


            <AssociateNewUsersContent props={this.props} />


        );
    }
}
AssociateNewUsers.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});

export default connect(mapStateToProps)(withStyles(Styles)(AssociateNewUsers));
AssociateNewUsers.contextType = AbilityContext;