import React from 'react';
import PropTypes from "prop-types";
import { TextField, CircularProgress } from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { VariableSizeList } from 'react-window';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import { useTheme } from '@mui/styles';
import { Typography } from '@mui/material';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

import Api from "../../services/api";
import Api2Talk from "../../services/api2talk";
import { PapiroConsole } from '../../utils/papiroConsole';
import PhoneInput from 'react-phone-input-2'

const LISTBOX_PADDING = 8; // px


function renderRow(props) {
	const { data, index, style } = props;
	const dataSet = data[index];
	const inlineStyle = {
		...style,
		top: style.top + LISTBOX_PADDING,
		wordWrap: "break-word"
	};
	//console.error('dataSet');
	//console.error(dataSet);
	if (dataSet.hasOwnProperty('group')) {
		return (
			<ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
				{dataSet.group}
			</ListSubheader>
		);
	}

	return (
		<Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
			{dataSet[1] && dataSet[1].parentname ? dataSet[1].parentname : dataSet[1].name && dataSet[1].email && dataSet[1].phone ? dataSet[1].name + ' (' + dataSet[1].email + ' | ' + dataSet[1].phone + ')' :
				dataSet[1].email ? dataSet[1].name + ' (' + dataSet[1].email + ')' :
					dataSet[1].title && dataSet[1].id ? dataSet[1].id + " : " + dataSet[1].title : dataSet[1].name}
		</Typography>
	);
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
	const outerProps = React.useContext(OuterElementContext);
	return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
	const ref = React.useRef(null);
	React.useEffect(() => {
		if (ref.current != null) {
			ref.current.resetAfterIndex(0, true);
		}
	}, [data]);
	return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
	const { children, ...other } = props;
	const itemData = [];
	children.forEach((item) => {
		itemData.push(item);
		itemData.push(...(item.children || []));
	});

	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
		noSsr: true,
	});

	const itemCount = itemData.length;
	const itemSize = smUp ? 36 : 48;

	const getChildSize = (child) => {
		if (child.hasOwnProperty('group')) {
			return 48;
		}

		return itemSize;
	};

	const getHeight = () => {
		if (itemCount > 8) {
			return 8 * itemSize;
		}
		return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
	};

	const gridRef = useResetCache(itemCount);

	return (
		<div ref={ref}>
			<OuterElementContext.Provider value={other}>
				<VariableSizeList
					itemData={itemData}
					height={getHeight() + 2 * LISTBOX_PADDING}
					width="100%"
					ref={gridRef}
					outerElementType={OuterElementType}
					innerElementType="ul"
					itemSize={(index) => getChildSize(itemData[index])}
					overscanCount={5}
					itemCount={itemCount}
				>
					{renderRow}
				</VariableSizeList>
			</OuterElementContext.Provider>
		</div>
	);
});

ListboxComponent.propTypes = {
	children: PropTypes.node,
};

const StyledPopper = styled(Popper)({
	[`& .${autocompleteClasses.listbox}`]: {
		boxSizing: 'border-box',
		'& ul': {
			padding: 0,
			margin: 0,
		},
	},
});


export default function SimpleAutoCompleteSearch(props) {


	const { label, options, stateName, changeSelect, selected, required, disabled, urlapi, freesolo, selectedText, sendfrom2talk, numberdigits, multiple, handleInputChange } = props;

	var totaldigits = 5;
	if (numberdigits != null) {
		totaldigits = numberdigits;
	}
	const defaultvalue = multiple ? [] : { id: 0, name: '' };
	//{
	//    "id": 281926,
	//    "name": "Thiago Oliveira da Silva ",
	//    "email": "juciele.aguiar@hotmail.com",
	//    "isexternal": false
	//}
	const [search, setSearch] = React.useState('');

	const [opt, setOption] = React.useState(selected ? selected : defaultvalue);
	const [items, setItems] = React.useState(options);
	const [loading, setLoading] = React.useState(false)
	const [open, setOpen] = React.useState(false);

	const [inputValue, setInputValue] = React.useState('');
	const [value, setValue] = React.useState(selected ? selected : defaultvalue);

	const [textSelected, setTextSelected] = React.useState(selectedText ? selectedText : '');

	const [typing, setTyping] = React.useState(false);
	const [typingTimeout, setTypingTimeout] = React.useState(0);


	React.useEffect(() => {
		setOption(selected ? selected : defaultvalue);
	}, [selected]);

	React.useEffect(() => {
		// action on update of movies
		//console.log('loading -> '+loading);

	}, [loading]);

	const handleChange = (event, value) => {
		//alert('change');
		if (value == null) {
			value = { id: 0, name: '' };
		}
		setOption(value);
		changeSelect(stateName, value);
	};

	const previousController = React.useRef();

	const getData = async (value) => {
		if (previousController.current) {
			previousController.current.abort();
		}
		var searchTerm = search;
		if (value.length > searchTerm.length) {
			searchTerm = value;
		}
		if ((searchTerm + '').length < totaldigits) {
			return false;
		}
		if (loading) {
			return false;
		}
		//console.log('SEARCH!! -> '+searchTerm+' -> '+loading);
		setLoading(true);
		//console.log('loading -> '+loading);
		const controller = new AbortController();
		const signal = controller.signal;
		previousController.current = controller;
		//alert(urlapi+"/" + searchTerm);
		if (sendfrom2talk == true) {
			await Api2Talk.get(urlapi + "/" + searchTerm, {
				signal,
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json"
				}
			})
				.then(function (response) {
					var updatedOptions = response.data.data;
					setItems(updatedOptions);
					setLoading(false);
					setOpen(true);
					//alert(JSON.stringify(updatedOptions))
					//alert(updatedOptions.length);
					if (freesolo && updatedOptions && updatedOptions.length == 0) {

						changeSelect(stateName, value, textSelected);
					}

				}).catch(err => {
					setLoading(false);
					setItems([])
					setOpen(false);

					if (freesolo) {
						changeSelect(stateName, value, textSelected);
					}

				});
		}
		else {
			await Api.get(urlapi + "/" + searchTerm, {
				signal,
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json"
				}
			})
				.then(function (response) {
					var updatedOptions = response.data.data;
					setItems(updatedOptions);
					setLoading(false);
					setOpen(true);
					if (freesolo && updatedOptions && updatedOptions.length == 0) {
						changeSelect(stateName, value, textSelected);
					}

				}).catch(err => {
					setLoading(false);
					setItems([])
					setOpen(false);
					if (freesolo) {
						changeSelect(stateName, value, textSelected);
					}

				});
		}
	};

	const onInputChange = (event, value, reason) => {
		//console.log('value -> '+value);
		setSearch(value);
		if (value) {
			if (reason === 'input') {
				if ((value + '').length >= totaldigits) {
					if (!loading) {
						setTimeout(() => { getData(value); }, 1000);
					}
				}

			}
		} else {
			setItems([]);
		}
	};

	const changeName = (event, value, reason) => {
		PapiroConsole.log("entrei aqui 0")
		setSearch(value);
		handleInputChange(value)
		if (value) {
			if (reason === 'input') {
				if ((value + '').length >= totaldigits) {
					if (typingTimeout) {
						clearTimeout(typingTimeout);
					}

					setTyping(false);
					setTypingTimeout(setTimeout(function () {
						getData(value);;
					}, 2000));
				}
				if (freesolo) {
					PapiroConsole.log("entrei aqui 1")
					setTextSelected(value);


				}
			}
		} else {
			setItems([]);
		}

		/*
	   self.setState({
		  name: event.target.value,
		  typing: false,
		  typingTimeout: setTimeout(function () {
			  self.sendToParent(self.state.name);
			}, 5000)
	   });
	   */
	}



	return (

		<Autocomplete
			id="combo-box-search-auto-complete"
			autoHighlight
			style={props.style != null ? props.style : { fullWidth: true }}
			PopperComponent={StyledPopper}
			ListboxComponent={ListboxComponent}
			loading={loading}
			onChange={handleChange}
			disabled={disabled}
			value={opt}
			options={items}
			onInputChange={changeName}
			multiple={multiple}
			getOptionLabel={option => option.phone ? option.phone : ''}
			renderInput={params => (
				<Tooltip title={<h5>{params.inputProps.value}</h5>} placement="top">
					<TextField {...params} label={label} variant="outlined" required={required != null ? required == false ? false : true : false} disabled={props.disabled} fullWidth
						InputProps={ (loading != null || typeof loading !== 'undefined') ? {
							...params.InputProps,
							endAdornment: (
								<>
									{ loading ? <CircularProgress color="inherit" size={20} /> : null }
									{ params.InputProps.endAdornment }
								</>
							)
						} : { ...params.InputProps }} />

					{/* <PhoneInput
						{...params} id="phone"
						    name="phone"
						    country={'br'}
						    value={phone} 
						label={label} variant="outlined" required={required != null ? required == false ? false : true : false} disabled={props.disabled} fullWidth
						InputProps={(loading != null || typeof loading !== 'undefined') ? {
							...params.InputProps,
							endAdornment: (
								<>
									{loading ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</>
							)
						} : { ...params.InputProps }}
					/> */}
				</Tooltip>
			)}
			renderOption={(props, option, state) => [props, option, state.index]}
			onKeyDown={(event) => {
				if (event.key === 'Enter') {
					// Prevent's default 'Enter' behavior.
					event.defaultMuiPrevented = true;
					// your handler code

				}
			}}

			freeSolo
		/>
	);
}

SimpleAutoCompleteSearch.propTypes = {
	label: PropTypes.string,
	items: PropTypes.array,
	stateName: PropTypes.string,
	changeSelect: PropTypes.func,
	handleInputChange: PropTypes.func,
	selected: PropTypes.object,
	selectedText: PropTypes.string,
	urlapi: PropTypes.string
};