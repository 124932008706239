import React from 'react';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import WebhookCreate from './webhook-create/index';
import WebhookList from './webhook-list/index';


function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function CenteredTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }; 

  document.getElementsByTagName('body')[0].style.background = "white";

  return (
    <div className={classes.root} style={{background: "#FFF !important", marginTop: 50 }}>
    <div className={classes.drawerHeader} />
      
      <div className={classes.paper} >
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            centered
          >
            <Tab label="Cadastrar" {...a11yProps(0)} />
            <Tab label="Listar" {...a11yProps(1)}/>
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <WebhookCreate />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <WebhookList />
        </TabPanel>
      </div>
    </div>
  );
}
