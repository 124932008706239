import React from 'react';
import LikeIcon from '../../images/me-feedback/like_icon.png';

const MELikeIcon = props => (
    <div style={{ display: 'inline-block' }}>
        <img
            src={props.src && props.src.length > 0 ? props.src : LikeIcon}
            style={props.style != null ? props.style : {  }}
            title="FeedbackLikeIcon"
            className='App-Like-icon' />
    </div>
);

export default MELikeIcon;
