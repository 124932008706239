export const validateChatLocationCoordinates = text => {
  const hasLatitude = /latitude/i.test(text)
  const hasLongitude = /longitude/i.test(text)
  const coordinateMatches = text.match(/-?\d+\,\d+/g)
  const hasTwoCoordinates = coordinateMatches && coordinateMatches.length === 2

  if (hasLatitude && hasLongitude && hasTwoCoordinates) {
    return {
      latitude: coordinateMatches[0].replace(",", "."),
      longitude: coordinateMatches[1].replace(",", ".")
    }
  }
  return null
} 