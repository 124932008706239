import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { browserHistory } from '../../helpers/history';
import { Link } from 'react-router-dom';

import * as Service from '../../services/patrimonygroup.service';

import { FormattedMessage, injectIntl } from 'react-intl';
import ReactQuill from 'react-quill';

import * as componentActions from '../../redux/actions/feedbackarticles';
class feedbackarticleEdit extends Component {

    constructor(props) {
        super(props);
        const { userSession, feedbackarticles } = this.props;


        this.globalAdm = false;
        this.isClient = true;
        if (userSession.user.userrole == null) {
            Api.logoff();
        }

         if (!feedbackarticles || (feedbackarticles && !feedbackarticles.feedbackarticle)) {
              var l = this.props.match.params.lang;
              document.location.href = `/${l}/calleds`
          }
  
        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false;
            }
            if (item.role.roletype == 0) {
                this.globalAdm = true;
            }
        }
        );


        this.initialState = {
            UpdatedUser: userSession.user.id,
            id: feedbackarticles.feedbackarticle.id,
            name: feedbackarticles.feedbackarticle.name == null ? '' : feedbackarticles.feedbackarticle.name,
            description: feedbackarticles.feedbackarticle.description == null ? '' : feedbackarticles.feedbackarticle.description,
        };
        this.state = {
            feedbackarticle: this.initialState,

            files: [],
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',

        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.closeNotification = this.closeNotification.bind(this);
        this.changeValues = this.changeValues.bind(this);
    }

    async componentDidMount() {
        await this.getData()
    };

    handleChangeEditor(value, name) {
        this.setState(prevState => ({
            feedbackarticle: {
                ...prevState.feedbackarticle,
                [name]: value || ""
            }
        }));
    }

    handleInputChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        if (name === 'name') {
            if (value.length <= 255) {
                this.setState(prevState => ({
                    feedbackarticle: {
                        ...prevState.feedbackarticle,
                        [name]: value
                    }
                }));
            }
        } else if (name === 'description') {
            if (value.length <= 500) { // Limite máximo de 500 caracteres para 'description'.
                this.setState(prevState => ({
                    feedbackarticle: {
                        ...prevState.feedbackarticle,
                        [name]: value
                    }
                }));
            }
        } else {
            this.setState(prevState => ({
                feedbackarticle: {
                    ...prevState.feedbackarticle,
                    [name]: value
                }
            }));
        }
    }

    handleSubmit = async e => {
        e.preventDefault();

        this.setState({ loading: true });

        const { name, description } = this.state.feedbackarticle; // Desestrutura os campos para facilitar o uso.

        // Validação do campo 'name'.
        if (name.length > 255) {
            const intl = this.props.intl;
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({ id: "name" }) + " " + intl.formatMessage({ id: "limit255" })
            });
            return; // Interrompe a execução caso o 'name' seja inválido.
        }

        // Validação do campo 'description'.
        if (description && description.length > 500) {
            const intl = this.props.intl;
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({ id: "description" }) + " " + intl.formatMessage({ id: "limit500" })
            });
            return; // Interrompe a execução caso o 'description' seja inválido.
        }

        let data = new FormData();

        // Adiciona os dados ao FormData, formatando as datas corretamente.
        for (let key in this.state.feedbackarticle) {
            if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
                data.append(key, moment(this.state.feedbackarticle[key]).format('YYYY/MM/DD HH:mm:ss'));
            } else {
                data.append(key, this.state.feedbackarticle[key]);
            }
        }


        // Realiza a requisição à API.
        try {
            const result = await Api.put('/feedback-article', data);

            if (result.data.success) {
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: 'success',
                    notificationMessage: intl.formatMessage({ id: "update.success" }),
                    files: []
                });
            } else {
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: 'error',
                    notificationMessage: result.data.errors
                        ? result.data.errors[0]
                        : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(result);
            }
        } catch (err) {
            const intl = this.props.intl;
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: err.response?.data?.errors
                    ? err.response.data.errors[0]
                    : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(err);
        }
    };

    getData = async () => {

        const intl = this.props.intl;
        this.setState({ loading: true });

        Api.get(`/feedback-article/${this.state.feedbackarticle.id}`)
            .then(result => {
                if (result.data.success) {
                    this.setState({
                        loading: false,
                        feedbackarticle: {
                            ...this.state.feedbackarticle,
                            description: result.data.data?.description ?? "",
                        },
                    });
                }
                else {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                }
                else {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                }
            });
    };



    closeNotification() {
        this.setState({ openNotification: false });
    }

    changeValues(stateName, value, text = '') {
        this.setState({ loading: true });

        this.setState(prevState => ({
            feedbackarticle: {
                ...prevState.feedbackarticle,
                [stateName]: value
            }
        }));

        this.setState({ loading: false });

    };

    removeItem(file) {
        let array = [...this.state.files];
        let index = array.indexOf(file)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ files: array });
        }
    }

    render() {
        const { classes, headerMenu, actionsHeaderMenu, feedbackarticles } = this.props;
        const { feedbackarticle } = this.state;
        const intl = this.props.intl;

        if (Api == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/unavailable`
        }

        return (
            <div className={classes.root}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    }, classes.actionsContent, {
                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='md'>
                        <CssBaseline />
                        <div className={classes.paper} style={{ marginTop: 0 }}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={4}>
                                    <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                                </Grid>
                                <Grid item xs={12} sm={8} />
                            </Grid>
                            <Avatar className={classes.avatar}>
                                <QueuePlayNext />
                            </Avatar>
                            <Typography component='h1' variant='h5'>
                                {<FormattedMessage id="edit.item" />}
                            </Typography>
                            <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField fullWidth label={intl.formatMessage({ id: "name" })} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={feedbackarticle.name} required />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Typography>
                                            <FormattedMessage id="description" />
                                        </Typography>
                                        <div style={{ resize: "vertical", overflow: "auto", height: "250px", marginBottom: "24px", border: "1px solid #ccc" }}>
                                            <ReactQuill
                                                name="description"
                                                value={feedbackarticle.description}
                                                style={{ height: "100%" }}
                                                modules={this.reactQuillModules}
                                                formats={this.reactQuillFormats}
                                                fullWidth
                                                variant="outlined"
                                                id="description"
                                                onChange={(value) => this.handleChangeEditor(value, "description")} required
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                                            <Grid item xs={12} sm={4}    >
                                                <Button
                                                    type='submit'
                                                    fullWidth
                                                    variant='contained'
                                                    color='primary'
                                                    className={classes.submit}
                                                >
                                                    {<FormattedMessage id="save" />}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>

                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
            </div>
        );
    }
}
feedbackarticleEdit.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    feedbackarticles: state.feedbackarticles
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(feedbackarticleEdit)));
