import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'

import { Styles } from '../../../../styles/material-styles'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper } from '@mui/material'
import { ExpandMore, TimerOutlined } from '@mui/icons-material'
import { ColorfyLinearProgress } from '../../../called-list/components/itemcard/linear-progress'

function CalledSLA(props) {
  
  const [expanded, setExpanded] = useState(true)

  const handleChangeExpand = _ => {
    setExpanded(!expanded)
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChangeExpand}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="called-costs-content"
        id="called-costs-header">
        <h4 style={{ color: "#3F51B4", fill: "#3F51B4", margin: 0, fontWeight: 600, display: 'flex', alignItems: 'flex-end' }}><TimerOutlined />&nbsp;SLA</h4>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <h6 style={{ color: "#58595B", fill: "#58595B", margin: 0, display: 'flex', justifyItems: 'center' }}>{<FormattedMessage id="sla.service" />}: { props.calledData != null && props.calledData.calledcost != null && props.calledData.calledcost.slaservice ? (Math.round(props.calledData.calledcost.slaservice * 100) / 100).toFixed(2) : 0 }%</h6>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ paddingTop: '2px!important' }}>
            <ColorfyLinearProgress progress={props.calledData != null && props.calledData.calledcost != null && props.calledData.calledcost.slaservice ? props.calledData.calledcost.slaservice : 0}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <h6 style={{ color: "#58595B", fill: "#58595B", margin: 0, display: 'flex', justifyItems: 'center' }}>{<FormattedMessage id="sla.solution" />}:  { props.calledData != null && props.calledData.calledcost != null && props.calledData.calledcost.slasolution ? (Math.round(props.calledData.calledcost.slasolution * 100) / 100).toFixed(2) : 0}%</h6>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ paddingTop: '2px!important' }}>
            <ColorfyLinearProgress progress={props.calledData != null && props.calledData.calledcost != null && props.calledData.calledcost.slasolution ? props.calledData.calledcost.slasolution : 0}/>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

CalledSLA.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
})

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(CalledSLA)))