import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { FormattedMessage,injectIntl } from 'react-intl';
import ClearIcon from '@mui/icons-material/Clear';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0
  },
  image: {
    height: 48,
    width: 48
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestProducts = props => {
  const { className, data, type } = props;
  const intl = props.intl;



  const classes = useStyles();

  const [products] = data;
  var length = 0

  if(products != null && products.length != undefined ){
    if(products.length != undefined && products.length != null ){
      length = products.length
    }
    else{
      length = 0 
    }
  

  }
  
  
  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardHeader
        subtitle={`${length} in total`}
        title={intl.formatMessage({id:"last.feedbacks"})}
      />
      <Divider />
      <CardContent className={classes.content}>
        {data.length != 0 && <List>
          {data.map((product, i) => (
            <ListItem
              divider={i < products.length - 2}
              key={product.id}
            >
              <ListItemAvatar>
                <AccountCircleIcon />
              </ListItemAvatar>
              {!type && (
              <ListItemText
                primary={product.assigneduser ? product.assigneduser.name : ''}
              />
              )}
              {type && (
              <ListItemText
                primary={product.assigneduser ? product.assigneduser.name : ''}
              />
              )}
              {type && (

              <Typography component='h1' variant='h5'>
                {product.noterequest} Estrela(s)
              </Typography>
                )}

              {!type && (

          <Typography component='h1' variant='h5'>
          {product.noteassigned} Estrela(s)
          </Typography>)}

            </ListItem>
          ))}
        </List>
        }
        {
          data.length === 0 && <div style={{fontSize:17, textAlign: 'center'}}>Não existem feedbacks</div>
        }
        
      </CardContent>
      <Divider />
    </Card>
  );
};

LatestProducts.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  type: PropTypes.bool
};

export default injectIntl(LatestProducts);
