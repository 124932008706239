import React, { Component } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material'
import { QueuePlayNext, DeleteForever } from '@mui/icons-material'
import { withStyles } from '@mui/styles'
import { Styles } from '../../styles/material-styles'
import { Overlay } from '../../styles/global'
import Api from '../../services/api'
import CustomizedSnackbars from '../../components/material-snackbars'

import HeaderMenu from '../../components/header-menu'
import { connect } from 'react-redux'


import Footer from '../../components/footer'
import { browserHistory } from '../../helpers/history'
import { getAllApiPermissionPolicies } from "../../services/apipermissionpolicy.service";
import { FormattedMessage, injectIntl } from 'react-intl'
import Title from '../../components/title-name'
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';

import 'react-quill/dist/quill.snow.css'; // ES6
import { PapiroConsole } from '../../utils/papiroConsole'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import KeyIcon from '@mui/icons-material/Key';




const NoDataRows = _ => {
  return (
    <div className="MuiDataGrid-window" style={{ display: 'flex', justifyContent: 'center', top: 56, overflowY: 'auto' }} >Não há registros a serem exibidos</div>
  )
}

class SecurityRegistration extends Component {

  constructor(props) {
    super(props)
    const { userSession, organizations } = this.props

    this.globalAdm = false
    this.isClient = true

    if (userSession.user.userrole == null) {
      Api.logoff()
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype !== 3) {
        this.isClient = false
      }
      if (item.role.roletype === 0) {
        this.globalAdm = true
      }
    })

    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      action: '',
      active: false,
      title: '',
      applicationFilter: null
    }

    this.state = {
      item: this.initialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      apiPermissionPolicies: [],
      selectedValue: '',
      temporaryValue: 0,


    }


    this.closeNotification = this.closeNotification.bind(this)
    this.changeValues = this.changeValues.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.handleChangeRadio = this.handleChangeRadio.bind(this)



  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount()
    }
  }

  componentDidMount() {

    this.getApiPermissionPolicies()

  }

  async getApiPermissionPolicies() {

    this.setState({ loading: true });
    var result = await getAllApiPermissionPolicies();
    PapiroConsole.log(result)
    PapiroConsole.log("result aqui")
    if (result.success) {
      this.setState({ loading: false, apiPermissionPolicies: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }


  }

  handleCheckboxChange = (itemId) => {
    const updatedItems = this.state.apiPermissionPolicies.map((item) =>
      item.id === itemId ? { ...item, checked: !item.checked } : item
    );
    this.setState({ apiPermissionPolicies: updatedItems });
  };


  handleChangeRadio = (event) => {
    const { name, value } = event.target;
    if (name === 'temporary') {
      this.setState({
        temporaryValue: value,
      });
    } else {
      this.setState({
        selectedValue: value,
      });
    }
  };

  getValidations(permissionList) {

    let validation = true;
    if (this.state.selectedValue == '') {

      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: "É necessário selecionar ao menos uma opção na expiração do token de acesso"
      })

      validation = false

    }
    if (this.state.selectedValue == 'temporary' && this.state.temporaryValue <= 0) {

      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: "A quantidade de dias não pode ser menor ou igual a 0"
      })

      validation = false

    }
    if (permissionList && permissionList.length == 0) {

      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: "É necessário marcar ao menos uma permissão para geração da access key "
      })
      validation = false


    }

    return validation

  }





  handleSubmit = async e => {
    const intl = this.props.intl;
    e.preventDefault()
    this.setState({ loading: true })
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    let data = new FormData()
    let permissionsCheckedList = []
    let permissionsIds = ''
    this.state.apiPermissionPolicies.forEach(element => {
      if (element.checked == true) {

        permissionsCheckedList.push(element.id);

      }

    });
    permissionsIds = permissionsCheckedList.join(',');

    let validations = this.getValidations(permissionsCheckedList)

    if (validations == true) {

      data.append("permissionsids", permissionsIds);
      this.state.selectedValue == 'permanent' ? data.append("permanent", true) : data.append("permanent", false) ;
      data.append("days", this.state.temporaryValue)

      Api.post('/userapipermission', data, config)

        .then(result => {
          if (result.data.success) {

            PapiroConsole.log(result)
            PapiroConsole.log("result registration aqui")


            this.props.history.push({
              pathname: `/${this.props.match.params.lang}/security-item-registered`,
              state: {
                accesskey : result.data.data.accesskeyid,
                secretAccesskey : result.data.data.secretaccesskey,
              }
            })

           /* this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"delete.sucess"}),
              resultList: []
              });*/

          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err && err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err && err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" }) + err.response.data
            });
          }
          Api.kickoff(err);
        });
    }



  }


  closeNotification() {
    this.setState({ openNotification: false })
  }



  changeValues(stateName, value, text = '') {

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }))
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

    PapiroConsole.log(this.state.apiPermissionPolicies)
    PapiroConsole.log("this.state.apiPermissionPolicies")





    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}>
          <div className={classes.drawerHeader} />
          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <KeyIcon />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="add.access.key" />}
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit}>
                <Grid container spacing={2}>

                  {/*this.state.apiPermissionPolicies && this.state.apiPermissionPolicies.length > 0 &&

                    <Grid item xs={12}>

                    </Grid>

                    
        */ }
                  <Grid item xs={12}>

                    <Typography variant="subtitle2" gutterBottom fontWeight="bold">
                      {<FormattedMessage id="access.token.expiration" />}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Escolha quando essa chave de acesso vai expirar.
                    </Typography>
                  </Grid>


                  <Box>

                    <Box sx={{ p: 2 }}>
                      <FormControl component="fieldset">

                        <RadioGroup
                          aria-label="radio-options"
                          name="radio-options"
                          value={this.state.selectedValue}
                          onChange={this.handleChangeRadio}
                        >
                          <FormControlLabel
                            value="permanent"
                            control={<Radio />}
                            label="Nunca"
                          />
                          <FormControlLabel
                            value="temporary"
                            control={<Radio />}
                            label={
                              <div>
                                {intl.formatMessage({ id: "select.the.number.of.days" })}
                                {this.state.selectedValue === 'temporary' && (
                                  <TextField
                                    name="temporary"
                                    type="number"
                                    value={this.state.temporaryValue}
                                    onChange={this.handleChangeRadio}
                                    sx={{
                                      marginLeft: '20px',
                                      border: 'none',  // Remover a borda

                                    }}
                                  />
                                )}
                              </div>
                            }
                          />

                        </RadioGroup>
                      </FormControl>

                    </Box>
                    <Box sx={{ p: 2 }}>

                      <Typography variant="subtitle2" gutterBottom fontWeight="bold">
                        {<FormattedMessage id="available.permissions" />}
                      </Typography>

                      {this.state.apiPermissionPolicies.length > 0 ?

                        this.state.apiPermissionPolicies.map((item) => (
                          <Grid item xs={12}>
                            <FormControlLabel
                              key={item.id}
                              control={
                                <Checkbox
                                  checked={item.checked || false}
                                  onChange={() => this.handleCheckboxChange(item.id)}
                                />
                              }
                              label={`${item.description} - ${item.name} `}
                            />
                          </Grid>

                        )) : null}
                    </Box>

                  </Box>






                  <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={4}    >
                        <Button
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit} >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>
            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}
            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification} />


          </Container>
        </main>
        <Footer />
      </div>
    )
  }
}

SecurityRegistration.propTypes = { classes: PropTypes.object.isRequired };

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  organizations: state.organizations,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(SecurityRegistration)));