import React, { Component } from 'react';
                import clsx from 'clsx';
                import { connect } from 'react-redux';
                import { bindActionCreators } from 'redux';
                import PropTypes from 'prop-types';
                import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
                import { withStyles } from '@mui/styles';
                import { Styles } from '../../styles/material-styles';
                import { Overlay } from '../../styles/global';
                import CustomizedSnackbars from '../../components/material-snackbars';
                import HeaderMenu from '../../components/header-menu';
                import AddIcon from '@mui/icons-material/Add';
                import { Link } from 'react-router-dom';
                import Api from '../../services/api';
                import MyMaterialTable from '../../components/table';
                import { Can } from '../../config/ability-context'
                import { AbilityContext } from '../../config/ability-context'
                import Footer from '../../components/footer';
                import FirstElement from "../../components/first-element";
                import ClassIcon from '@mui/icons-material/Class';
                import { FormattedMessage,injectIntl } from 'react-intl';

  
                    import * as componentActions from '../../redux/actions/categoryorganizations';
                    class CategoryOrganizations extends Component {

                    constructor(props) {
                        super(props);

                        this.emptyArray = null;

                        const { userSession } = this.props;
                        this.state = {
                            loading: false,
                            openNotification: false,
                            notificationVariant: 'error',
                            notificationMessage: '',
                            resultList: [],
                        };

                        this.closeNotification = this.closeNotification.bind(this);
                        this.deleteItem = this.deleteItem.bind(this);
                    }

                    deleteItem(data, resolve) {
                        const intl = this.props.intl;
                        let abl = this.context.can('Delete', 'CategoryOrganization');
                        if (abl == false){
                            this.setState({loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: this.props.intl.formatMessage({id:"operation.notAllowed"})});
                            resolve(); return false;
                        }

                    Api.delete(`/categoryorganizations/${data.id}`)
    
                            .then(result => {
                                if (result.data.success) {
                                    this.setState({
                                        loading: false,
                                        openNotification: true,
                                        notificationVariant: 'success',
                                        notificationMessage: intl.formatMessage({id:"called.list.notification.delete.success"}),
                                        resultList: []
                                      });
          
                                    this.getItens();
                                }
                                else{
                                    this.setState({
                                        loading: false, openNotification: true, notificationVariant: 'error',
                                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                                    });
                                    Api.kickoff(result);
                                }
                                resolve();
                            })
                            .catch(err => {
                                resolve();
                                this.setState({
                                    loading: false, openNotification: true, notificationVariant: 'error',
                                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                                });
                                Api.kickoff(err);
                            });
    

                    };
    

                    componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

                    componentDidMount() {
                        this.getItens();
                    }

                    getItens() {
                        this.setState({ loading: true });
                                            Api.get(`/categoryorganizations`)
                            
                                    .then(result => {
                                        if (result.data.success) {
                                            if(result.data.data.length === 0)
                                            {
                                                this.emptyArray = true 
                                            }
                                            else
                                            {
                                                this.emptyArray = false
                                            }
                                            const formattedList = result.data.data.map(item => {
                                                return {
                                                    ...item,
                                                     CategoryId: item.category ? item.category.id : 0,
                                                     CategoryText: item.category ? item.category.parentname : '',
                                                     OrganizationId: item.organization ? item.organization.id : 0,
                                                     OrganizationText: item.organization ? item.organization.name : '',

                                          }
                                    });
                                    this.setState({ loading: false, resultList: formattedList });
                                }
                                else{
                                    this.setState({
                                        loading: false, openNotification: true, notificationVariant: 'error',
                                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                                    });
                                    Api.kickoff(result);
                                }
                            })
                            .catch(err => {
                                this.setState({
                                    loading: false, openNotification: true, notificationVariant: 'error',
                                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                                });
                                Api.kickoff(err);
            
                            });
                    };


                    closeNotification() {
                        this.setState({ openNotification: false });
                    }
                     render() {
                        const { classes, headerMenu, actionsHeaderMenu } = this.props;
                        const intl = this.props.intl;
                        if(Api == null) {
                            var l = this.props.match.params.lang;
                            document.location.href=`/${l}/unavailable`
                          }

                        return (
                            <div className={classes.root} style={{paddingLeft:0, marginLeft:0}}>
                                <HeaderMenu />
                                <main
                                    className={clsx(classes.content, {
                                        [classes.contentShift]: headerMenu.open,
                                    }, classes.actionsContent, {
                                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                                    })}
                                >
                                    <div className={classes.drawerHeader} />

                                    <Container component='main' maxWidth='xl' style={{paddingLeft:0, marginLeft:0}}>
                                    {!this.emptyArray ? (
                                        <div>
                                            <CssBaseline />
                                            <Grid container className={classes.mb20}  spacing={0}>
                                                    <Button
                                                        variant='contained'
                                                        color='secondary'
                                                        size='large'
                                                        disabled={!this.context.can('Add', 'OrganizationCategory')}
                                                        className={classes.button}
                                                        startIcon={<AddIcon />}
                                        component={Link} {...{ to: `/${this.props.match.params.lang}/categoryorganization-registration` }}
                                                    >
                                                        {intl.formatMessage({id:"add"})}
                                                    </Button>
                                            </Grid>
                                            <Can I='List' a='OrganizationCategory'>      
                                            <MyMaterialTable
                                                            title={intl.formatMessage({id:"organizations.x.categories"})}
                                                                    rowClick={(event, rowData) => {
                                                                    let abl = this.context.can('Edit', 'OrganizationCategory');
                                                                        if (abl == false)
                                                                        {
                                                                            this.setState({ loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: this.props.intl.formatMessage({id:"operation.notAllowed"})});
                                                                            return false;
                                                                        }
                                                                        this.props.addCurrent(rowData);
                                                            this.props.history.push(`/${this.props.match.params.lang}/categoryorganization-edit`);
                                                                }}
                                                columns={[
                                                    { title: this.props.intl.formatMessage({id:"menu.organization"}), field: 'OrganizationText' },
                                                    { title: intl.formatMessage({id:"category"}), field: 'CategoryText' },


                                                ]}
                                                data={this.state.resultList}
                                                deleteItem={this.deleteItem} 
                                            />
                                            </Can>
                                            {this.state.loading && (
                                                <Overlay>
                                                    <CircularProgress color='secondary' />
                                                </Overlay>
                                            )}
                        

                                            <CustomizedSnackbars
                                                variant={this.state.notificationVariant}
                                                message={this.state.notificationMessage}
                                                isOpen={this.state.openNotification}
                                                toClose={this.closeNotification}
                                            />
                                        </div>)
                                         :(
                                            <div>
                                               <FirstElement
                                               title = {intl.formatMessage({id:"category.organization.list.create.category"})}
                                               subtitle = {intl.formatMessage({id:"category.organization.list.create.category.subtitle"})}
                                               //buttonText = "Novo Chamado"
                                               buttonText = 
                                               {
                                                <Button
                                                variant='contained'
                                                color='secondary'
                                                size='large'
                                                className={classes.button}
                                                startIcon={<AddIcon />}
                                                component={Link} {...{ to: `/${this.props.match.params.lang}/group-registration` }}
                                            >
                                                {<FormattedMessage id="add" />}
                                                    </Button>
                                               }
                                            
                                               icon = {<ClassIcon style={{fontSize: "45px"}} />}
                                               
                                               />
                                            </div>
                                    )}
                                      {this.state.loading && (
                                            <Overlay>
                                                <CircularProgress color='secondary' />
                                            </Overlay>
                                        )}
                      

                                        <CustomizedSnackbars
                                            variant={this.state.notificationVariant}
                                            message={this.state.notificationMessage}
                                            isOpen={this.state.openNotification}
                                            toClose={this.closeNotification}
                                        />
                                        
                                    </Container>
                                </main>
                                <Footer />   

                            </div>
                        );
                    }
                }
CategoryOrganizations.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    actionsHeaderMenu: state.actionsHeaderMenu,
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                });
                const mapDispatchToProps = dispatch =>
                    bindActionCreators(componentActions, dispatch);
            export default connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CategoryOrganizations));CategoryOrganizations.contextType = AbilityContext;
