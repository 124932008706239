import { Button, CircularProgress, Container, Grid, TextField } from "@mui/material";
import { withStyles } from '@mui/styles';
import { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import SimpleAutoComplete from "../../../../components/auto-complete/autocomplete.js";
import CustomizedSnackbars from "../../../../components/material-snackbars/index.js";
import { AbilityContext } from '../../../../config/ability-context';
import * as componentActions from '../../../../redux/actions/calledflows';
import * as MessageProviderConfigService from "../../../../services/2talk/message-provider-config.service.js";
import Api from "../../../../services/api";
import Api2Talk from "../../../../services/api2talk";
import { Overlay } from "../../../../styles/global.js";
import { Styles } from '../../../../styles/material-styles';


class WebchatMessageProviderSetting extends Component {

  constructor(props) {
    super(props);

    const { userSession, webchats } = this.props;
    this.globalAdm = false;
    this.isClient = true;

    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }

    this.itemInitialState = {
      id: (webchats?.webchat?.messageproviderconfigid && webchats?.webchat?.messageproviderconfigid != 0) ?  webchats?.webchat?.messageproviderconfigid : null,
      messageproviderid: null,
      accountsid: '',
      authtoken: '',
    }; 

    this.state = {
      item: this.itemInitialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      optionsMessageProviders: [],
      webchat: webchats?.webchat
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  fetchDataNotSuccess(result) {
    const intl = this.props.intl;
    this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    });
  }

  fetchDataThrowError(err) {
    const intl = this.props.intl;
    this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
    });
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  }

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let input = {
      ...this.state.item,
      messageproviderid: this.state.item.messageproviderid?.id,
    }

    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }; 
    
    try {

      if (!this.state.item.id) {

        input = {
          webchatid: this?.state?.webchat?.id,
          messageproviderid: this.state.item.messageproviderid?.id,
          accountsid: this.state.item.accountsid,
          authtoken: this.state.item.authtoken
        }

        const output = await MessageProviderConfigService.create(input);

        if (output.success) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: 'Registro inserido com sucesso',
            item: {
              ...this.state.item,
              id: output?.data?.id
            }
          });
        } else {
          this.fetchDataNotSuccess(output);
          Api.kickoff(output);
        }

      } else {

        const output = await MessageProviderConfigService.update(input, config);

        if (output.success) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: 'Registro atualizado com sucesso'
          });
        } else {
          this.fetchDataNotSuccess(output);
          Api.kickoff(output);
        }

      }

    } catch (err) {
      this.fetchDataNotSuccess(err);
      Api.kickoff(err);

    } finally {
      this.setState({ loading: false });
    }
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  fetchOptionsMessageProvider = async () => {
    this.setState({ loading: true });

    try {
      const response = await Api2Talk.get(`/messageprovider`);

      const { data } = response;

      if (data.success) {
        const messageProviderOptions = data.data;

        this.setState(prevState => ({
          optionsMessageProviders: messageProviderOptions
        }))

        const messageProvider = messageProviderOptions?.find(item => item.id == 6);

        this.setState(prevState => ({
          item: {
            ...prevState.item,
            messageproviderid: messageProvider ?? null
          }
        }))
        
      } else {
        this.fetchDataNotSuccess(response);
        Api.kickoff(response);
      }

    } catch (err) {
      this.fetchDataThrowError(err);
      Api.kickoff(err);

    } finally {
      this.setState({ loading: false });
    }
  }

  handleChangeAutoComplete(stateName, value) {  
    let data = value.id > 0 ? value : null ;
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: data
      }
    }));
  }

  async fetchData() {
    this.setState({ loading: true });

    try {
      if (this.state.item.id) {
        const output = await MessageProviderConfigService.getById(this.state.item.id);

        if (output.success) {

          const messageProvider = this.state.optionsMessageProviders.find(item => item.id == 6);

          this.setState({
            item: {
              ...this.itemInitialState,
              messageproviderid: messageProvider,
              accountsid: output.data.accountsid,
              authtoken: output.data.authtoken,
            },
          });
        } else {
          this.fetchDataNotSuccess(output);
          Api.kickoff(output);
        }
      }
    } catch (err) {
      this.fetchDataNotSuccess(err);
      Api.kickoff(err);

    } finally {
      this.setState({ loading: false });
    }
  }

  async componentDidMount() {
    await this.fetchOptionsMessageProvider();
    await this.fetchData();
  }

  render() {
    const { classes } = this.props;
   
    const { item } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    return (
      <div style={{ paddingTop: '10px' }}>
          <Container component='main' maxWidth='md'>
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete 
                        name="MessageProviderId"
                        label={intl.formatMessage({ id: "select.message_provider" })}
                        options={this.state.optionsMessageProviders}
                        stateName='messageproviderid'
                        changeSelect={(stateName, value) => {
                          this.handleChangeAutoComplete(stateName, value)
                        }}
                        selected={item.messageproviderid}
                        htmlOptions={true}
                        required 
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField 
                        inputProps={{ maxLength: 250 }} 
                        fullWidth 
                        label={intl.formatMessage({id:"account.sid"})} 
                        autoComplete='accountsid' 
                        variant='outlined' 
                        name='accountsid' 
                        onChange={this.handleInputChange} 
                        value={item.accountsid} 
                        required 
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField 
                        inputProps={{ maxLength: 250 }} 
                        fullWidth 
                        label={intl.formatMessage({id:"auth.token"})} 
                        autoComplete='fauthtoken' 
                        variant='outlined' 
                        name='authtoken' 
                        onChange={this.handleInputChange} 
                        value={item.authtoken} 
                        required 
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                      <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                        <Grid item xs={12} sm={4}    >
                          <Button 
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                          >
                            {<FormattedMessage id="save" />}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            {
              this.state.openNotification && (
                <CustomizedSnackbars
                  variant={this.state.notificationVariant}
                  message={this.state.notificationMessage}
                  isOpen={this.state.openNotification}
                  toClose={this.closeNotification}
                />
              )
            }          
          </Container>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  webchats: state.webchats
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(WebchatMessageProviderSetting)));

WebchatMessageProviderSetting.contextType = AbilityContext;