import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Typography } from '@mui/material';

const Cronometro = ({ dataInicial, name }) => {
  const [tempoDecorrido, setTempoDecorrido] = useState({
    horas: 0,
    minutos: 0,
    segundos: 0,
  });

  useEffect(() => {
    const calcularDiferencaTempo = () => {
      const dataInicio = moment(dataInicial);
      const agora = moment();

      const diff = moment.duration(agora.diff(dataInicio));

      return {
        horas: diff.asHours(), // Obtém o total de horas decorridas
        minutos: diff.minutes(),
        segundos: diff.seconds(),
      };
    };

    const interval = setInterval(() => {
      const { horas, minutos, segundos } = calcularDiferencaTempo();
      setTempoDecorrido({ horas, minutos, segundos });
    }, 1000);

    return () => clearInterval(interval);
  }, [dataInicial]);

  const formatarTempo = (tempo) => String(tempo).padStart(2, '0');

  return (
    <div >

      {name == "chatsWaitingAttendance" ?

        <span style={{ fontSize: '0.9em' }} className='boldLine-two'> Tempo de espera  </span>
        :
        <span style={{ fontSize: '0.9em' }} className='boldLine-two'> Tempo de atendimento  </span>


      }
      <div>
        {Math.floor(tempoDecorrido.horas)}h:{formatarTempo(tempoDecorrido.minutos)}m:{formatarTempo(tempoDecorrido.segundos)}s
      </div>

    </div >
  );
};

export default Cronometro;
