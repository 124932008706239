import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleDatePicker from '../../components/date-pickers/inline';
import * as Service from '../../services/unit.service';
import { parseJSON } from 'date-fns';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage, injectIntl } from 'react-intl';

class UnitRegistration extends Component {

    constructor(props) {
      const intl = props.intl;
        super(props);
        const { userSession, organizations } = this.props;

        this.fromProps = false
        if(this.props && this.props.location && this.props.location.state && this.props.location.state.from && this.props.location.state.from.length > 0)
            this.fromProps = true

        this.globalAdm = false;
        this.isClient = true;

        if (userSession.user.userrole == null) {
            Api.logoff();
        }

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype !== 3) {
                this.isClient = false;
            }
            if (item.role.roletype === 0) {
                this.globalAdm = true;
            }
        }
        );

        this.initialState = {
          createdUser: userSession.user.id,
          createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
          hirerId: userSession.user.hirerid,
          name: ''
        };

        this.state = {
          item: this.initialState,
          loading: false,
          openNotification: false,
          notificationVariant: 'error',
          notificationMessage: ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.closeNotification = this.closeNotification.bind(this);

    } // Fim - construtor

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    componentDidMount() {

    }

    handleInputChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        this.setState(prevState => ({
            item: {
                ...prevState.item,
                [name]: value
            }
        }));
    };

    handleSubmit = async e => {
        e.preventDefault();

        this.setState({ loading: true });

        let data = new FormData();
        
        if (this.state.item.name.length === 0) {

            const intl = this.props.intl;
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({id:"name.is.required"}),
               
            });

        } else {
          this.setState(prevState => ({
            item: {
              ...prevState.item,
            }
          }));

          for (let key in this.state.item) {
            data.append(key, this.state.item[key])
          }

            let response = await Service.addItem(data)
            if (response && response.success) {
              const intl = this.props.intl;
              this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'success',
                  notificationMessage: intl.formatMessage({ id: "add.success" }),
                  item: this.initialState,
                  files: []
              });
            } else {
              const intl = this.props.intl
              this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: 'error',
                  notificationMessage: response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
              })
              Api.kickoff(response)
            }
        }



    };

    closeNotification() {
        this.setState({ openNotification: false });
    }

    render() {
        const { classes, headerMenu, actionsHeaderMenu } = this.props;
        const { item } = this.state;
        const intl = this.props.intl;

        if(Api == null) {
            var l = this.props.match.params.lang;
            document.location.href=`/${l}/unavailable`
          }

        return (
            <div className={classes.root}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    }, classes.actionsContent, {
                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                    })}
                >

                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='md'>
                        <CssBaseline />
                        <div className={classes.paper} style={{ marginTop: 0 }}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={4}>
                                    <Button variant='outlined' color='primary' onClick={() => browserHistory.goBack()}
                                    >{<FormattedMessage id="back" />}</Button>
                                </Grid>
                                <Grid item xs={12} sm={8} />
                            </Grid>
                            <Avatar className={classes.avatar}>
                                <QueuePlayNext />
                            </Avatar>
                            <Typography component='h1' variant='h5'>
                                {<FormattedMessage id="add.item" />}
                            </Typography>
                            <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={12}>
                                    <TextField fullWidth label={intl.formatMessage({id:"name"})} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} required />
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                                      <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                                        <Grid item xs={12} sm={4}    >
                                            <Button 
                                            type='submit'
                                            fullWidth
                                            variant='contained'
                                            color='primary'
                                            className={classes.submit}
                                            >
                                            {<FormattedMessage id="save" />}
                                            </Button>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                </Grid>
                            </form>

                        </div>
                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>

                </main>
            </div>
        )
    }
}

UnitRegistration.propTypes = { classes: PropTypes.object.isRequired };

const mapStateToProps = state => ({
    actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(UnitRegistration)));