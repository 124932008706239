export const locale_pt_BR = { 
    'Apply': 'Aplicar',
    'Arrow': 'Seta',
    'Arrow-2': 'Seta-2',
    'Arrow-3': 'Seta-3',
    'Blend': 'Misturar',
    'Blur': 'Borrar',
    'Bold': 'Negrito',
    'Brightness': 'Brilho',
    'Bubble': 'Bolha',
    'Cancel': 'Cancelar',
    'Center': 'Centralizar',
    'Circle': 'Círculo',
    'Color': 'Cor',
    'Color Filter': 'Filtro de Cor',
    'Crop': 'Cortar',
    'Custom': 'Customizar',
    'Custom icon': 'Ícone de Customização',
    'Delete': 'Excluir',
    'Delete-all': 'Excluir tudo',
    'DeleteAll': 'Excluir tudo',
    'Distance': 'Distância',
    'Download': 'Download',
    'Draw': 'Desenhar',
    'Emboss': 'Gravar',
    'Fill': 'Preencher',
    'Filter': 'Filtro',
    'Flip': 'Girar',
    'Flip X': 'Girar X',
    'Flip Y': 'Girar U',
    'Free': 'Livrar',
    'Grayscale': 'Escala de Cinza',
    'Heart': 'Coração',
    'Icon': 'Ícone',
    'Invert': 'Inverter',
    'Italic': 'Itálico',
    'Left': 'Esquerda',
    'Load': 'Abrir imagem',
    'Load Mask Image': 'Carregar máscara da imagem',
    'Location': 'Local',
    'Mask': 'Máscara',
    'Multiply': 'Multiplicar',
    'Noise': 'Ruído',
    'Pixelate': 'Pixelate',
    'Polygon': 'Polígono',
    'Range': 'Range',
    'Rectangle': 'Retângulo',
    'Redo': 'Refazer',
    'Remove White': 'Remover branco',
    'Reset': 'Redefinir',
    'Right': 'Direita',
    'Rotate': 'Rodar',
    'Sepia': 'Sépia',
    'Sepia2': 'Sépia 2',
    'Shape': 'Forma',
    'Sharpen': 'Afiar',
    'Square': 'Quadrado',
    'Star-1': 'Estrela-1',
    'Star-2': 'Estrela-2',
    'Straight': 'Linha reta',
    'Stroke': 'Stroke',
    'Text': 'Texto',
    'Text size': 'Tamanho do texto',
    'Threshold': 'Limite',
    'Tint': 'Matiz',
    'Triangle': 'Triângulo',
    'Underline': 'Cortar',
    'Undo': 'Desfazer',
    'Value': 'Valor',
};
