export default function organizationcategoryportal(state = [], action) {

    
    switch (action.type) {
        case 'ADD_CURRENT':
            return {
                ...state,
                organizationcategoryportal: action.organizationcategoryportal,
            }
        default:
            return state;
    }
}
