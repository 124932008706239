import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import Rating from '@mui/material/Rating';
import { FormattedMessage,injectIntl } from 'react-intl';
import ClearIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0
  },
  image: {
    height: 48,
    width: 48
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestProducts = props => {
  const { className, data, type, openCalled } = props;
  const intl = props.intl;

  const classes = useStyles();

  const products = data;

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardHeader
        subtitle={`${products.length} in total`}
        title={intl.formatMessage({id:"last.feedbacks"})}
      />
      <Divider />
      <CardContent className={classes.content}>
        <ListItem>
          <span style={{ display: "flex", position: "relative", alignItems: "center" }}>
            <ListItemText primary={ "ID" } style={{ marginRight: "13px", minWidth: "36px" }} />
            <ListItemText primary={<FormattedMessage id="assigned" />} />
          </span>
          <ListItemText
            primary={<FormattedMessage id="classification" />} 
            style={{ position: "absolute", left: "calc(100% - 120px)" }}
            />
        </ListItem>
        <Divider />
        {data.length != 0 && <List>
          {data.map((product, i) => (
            <ListItem
              divider={i < products.length - 2}
              key={product.id}
            >
              <Link style={{ marginRight: "13px", minWidth: "36px" }}>
                <ListItemText onClick={ () => openCalled(product.calledid) }>
                  <span style={{ color: "#0645AD", textDecoration: "underline" }}>{ product.calledid }</span>
                </ListItemText>
              </Link>
              {!type && (
              <ListItemText
                primary={product.assigneduser ? product.assigneduser.name : ''}
              />
              )}
              {type && (
              <ListItemText
                primary={product.assigneduser ? product.assigneduser.name : ''}
              />
              )}
              {type && (
              <Rating
                  name="rating"
                  value={product.noterequest}
                  readOnly
                />)}

              {!type && (
              <Rating
                  name="rating"
                  value={product.noteassigned}
                  readOnly
                />)}

            </ListItem>
          ))}
        </List>
        }
        {
          data.length === 0 && <div style={{fontSize:17, textAlign: 'center'}}>Não existem feedbacks</div>
        }
        
      </CardContent>
      <Divider />
    </Card>
  );
};

LatestProducts.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  type: PropTypes.bool
};

export default injectIntl(LatestProducts);
