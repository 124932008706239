export default function calledvideocalls(state = [], action) {
    switch (action.type) {
        case 'ADD_CURRENT':
            return {
                ...state,
                calledvideocall: action.calledvideocall,
            }
        default:
            return state;
    }
}
