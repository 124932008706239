import Api from './api';

export const getById = async (id) => { 
  let data = []
  await Api.get(`/knowledgebaseevaluations/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByKnowledgeBaseId = async (id) => { 
  let data = []
  await Api.get(`/knowledgebaseevaluations/knowledgebase/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByUserId = async (id) => { 
  let data = []
  await Api.get(`/knowledgebaseevaluations/user/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByKnowledgeBaseIdAndUserId = async (knowledgebaseId, userId) => { 
  let data = []
  await Api.get(`/knowledgebaseevaluations/knowledgebase/${knowledgebaseId}/user/${userId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const addItem = async (formData) => { 
  let data = []
  await Api.post(`/knowledgebaseevaluations`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const editItem = async (formData) => { 
  let data = []
  await Api.put(`/knowledgebaseevaluations`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const deleteItem = async (id) => { 
  let data = []
  await Api.delete(`/knowledgebaseevaluations/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}