import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, TextField, Grid, CircularProgress, Container, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import CustomizedSnackbars from "../../components/material-snackbars";
import { injectIntl } from "react-intl";
import { PapiroConsole } from "src/utils/papiroConsole";
import BackupCodes from "./components/backup-codes";

class TwoFactorAuthenticationApp extends Component {
  constructor(props) {
    super(props);
    this.state = {


      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      twoFactorAuthenticationQrcode: null,
      twoFactorAuthenticationCode: "",
      twoFactorAuthenticationActive: false,
      codes: []

    };


    this.closeNotification = this.closeNotification.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

  }





  componentDidMount() {


    PapiroConsole.log("entrei aqui props")
    PapiroConsole.log(this.props)
    if (this.props.location.state && this.props.location.state.email) {
      this.setTwoFactorAuthenticationQrCode(this.props.location.state.email)
    }
    else {

      let email = localStorage.getItem('emailtoactivate');
      this.setTwoFactorAuthenticationQrCode(email)
    }

  }

  setTwoFactorAuthenticationQrCode = async (email) => {
    const intl = this.props.intl;

    this.setState({ loading: true });

    let token = localStorage.getItem('accesstoken');
    Api.defaults.headers.common['Authorization'] = 'Bearer ' + token;


    Api.get('/users/generateqrcode/' + email)

      .then(result => {
        if (result.data.success) {
          this.setState({
            loading: false,
            twoFactorAuthenticationQrcode: result.data.data
          });
        }
        else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: result.data.errors && result.data.errors[0] ? result.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          });

        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          });
        }
        else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })

          });
        }
      });
  };

  enableTwoFactorAuthentication = () => {
    const intl = this.props.intl;

    let data = new FormData();
    if (this.props.location && this.props.location.state && this.props.location.state.email) {
      data.append('email', this.props.location.state.email);
    }
    else {
      let email = localStorage.getItem('emailtoactivate');
      data.append('email', email);
    }

    data.append('code', this.state['twoFactorAuthenticationCode']);

    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    this.setState({ loading: true });

    let token = localStorage.getItem('accesstoken');
    Api.defaults.headers.common['Authorization'] = 'Bearer ' + token;


    Api.put('/users/enableTwoFactorAuthentication-and-generatecodes', data, config)

      .then(result => {
        if (result.data.success) {
          localStorage.removeItem('accesstoken');
          localStorage.removeItem('emailtoactivate');
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: this.props.intl.formatMessage({ id: "twoFactorAuth.enableSuccess" }),
            twoFactorAuthenticationActive: true,
            codes: result.data.data
          });


        }
        else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: result.data.errors && result.data.errors[0] ? result.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          });

        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          });
        }
        else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })

          });
        }
      });
  }


  closeNotification() {
    this.setState({ openNotification: false });
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value })


  };

  handleBackToLogin = () => {
    // Função para voltar à página de login

    localStorage.removeItem('accesstoken');
    localStorage.removeItem('emailtoactivate');

    this.props.history.push({
      pathname: `/pt`,

    });
  };
  render() {

    const { classes, headerMenu, actionsHeaderMenu, users, intl } = this.props;
    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <Container component="main" maxWidth="md" sx={{ mt: 5, textAlign: 'center', boxShadow: 3, p: 4, borderRadius: 2, bgcolor: 'white' }}>
        {((!this.state.twoFactorAuthenticationActive) && this.state.twoFactorAuthenticationQrcode != '' && this.state.twoFactorAuthenticationQrcode != null) && (
          <Grid container>
            <Typography variant="h5" gutterBottom color="primary" fontWeight="bold">
              Configuração da autenticação de dois fatores
            </Typography>
            <Grid item xs={12} sm={12}>
              <i>{intl.formatMessage({ id: "twoFactorAuth.nowScanCode" })}</i>
            </Grid>
            <Grid container mt={3}>
              <Grid xs={12} sm={4}>
                <img src={this.state.twoFactorAuthenticationQrcode} width={300} height={300} />
              </Grid>

              <Grid item style={{ marginTop: '30px' }} xs={12} sm={6}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    inputProps={{ maxLength: 6 }}
                    fullWidth
                    label={intl.formatMessage({ id: "access.code" })}
                    variant='outlined'
                    name='twoFactorAuthenticationCode'
                    onChange={this.handleInputChange}
                    value={this.state.twoFactorAuthenticationCode} />
                </Grid>
                <Grid style={{ marginTop: '20px' }} item xs={12} sm={6}>
                  <Button
                    fullWidth
                    variant='contained'
                    color='primary'
                    className={classes.submit}
                    onClick={this.enableTwoFactorAuthentication}
                  >
                    {intl.formatMessage({ id: "enable" })}
                  </Button>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        )}

        {(this.state.twoFactorAuthenticationActive) && (

          <BackupCodes codes={this.state.codes} handleBackToLogin={this.handleBackToLogin} />
        )}




        {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}

        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification}
        />
      </Container>
    );
  }
}

TwoFactorAuthenticationApp.propTypes = {
  classes: PropTypes.object.isRequired
};

export default injectIntl(withStyles(Styles)(TwoFactorAuthenticationApp));
