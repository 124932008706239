import React from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from "prop-types";
import { InputLabel, TextField , FormControl, InputAdornment, Box } from '@mui/material';
import Autocomplete, { autocompleteClasses }  from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { VariableSizeList } from 'react-window';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import { useTheme } from '@mui/styles';
import { Typography } from '@mui/material';
import ReactHtmlParser from 'react-html-parser';
import { PapiroConsole } from '../../utils/papiroConsole';
import WarningIcon from '@mui/icons-material/Warning';
import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
import {  styled } from '@mui/material/styles';

const LISTBOX_PADDING = 8; // px

const toggleItemActive = ({ doSomething, value }) => {
  //console.log(doSomething);
};

function renderRow(props) {
	const { data, index, style } = props;
	const dataSet = data[index];
	const inlineStyle = {
	  ...style,
	  top: style.top + LISTBOX_PADDING,
	};
    //console.error('dataSet');
    //console.error(dataSet);
	if (dataSet.hasOwnProperty('group')) {
	  return (
		<ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
		  {dataSet.group}
		</ListSubheader>
	  );
	}
  
	return (
	  <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
		{ dataSet[1].name ? dataSet[1].name : dataSet[1].parentname }
	  </Typography>
	);
  }
  
  const OuterElementContext = React.createContext({});
  
  const OuterElementType = React.forwardRef((props, ref) => {
	const outerProps = React.useContext(OuterElementContext);
	return <div ref={ref} {...props} {...outerProps} />;
  });

  function useResetCache(data) {
	const ref = React.useRef(null);
	React.useEffect(() => {
	  if (ref.current != null) {
		ref.current.resetAfterIndex(0, true);
	  }
	}, [data]);
	return ref;
  }
  
  // Adapter for react-window
  const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
	const { children, ...other } = props;
	const itemData = [];
	children.forEach((item) => {
	  itemData.push(item);
	  itemData.push(...(item.children || []));
	});
  
	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
	  noSsr: true,
	});
  
	const itemCount = itemData.length;
	const itemSize = smUp ? 36 : 48;
  
	const getChildSize = (child) => {
	  if (child.hasOwnProperty('group')) {
		return 48;
	  }
  
	  return itemSize;
	};
  
	const getHeight = () => {
	  if (itemCount > 8) {
		return 8 * itemSize;
	  }
	  return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
	};
  
	const gridRef = useResetCache(itemCount);
  
	return (
	  <div ref={ref}>
		<OuterElementContext.Provider value={other}>
		  <VariableSizeList
			itemData={itemData}
			height={getHeight() + 2 * LISTBOX_PADDING}
			width="100%"
			ref={gridRef}
			outerElementType={OuterElementType}
			innerElementType="ul"
			itemSize={(index) => getChildSize(itemData[index])}
			overscanCount={5}
			itemCount={itemCount}
		  >
			{renderRow}
		  </VariableSizeList>
		</OuterElementContext.Provider>
	  </div>
	);
  });
  
  ListboxComponent.propTypes = {
	children: PropTypes.node,
  };

function random(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}

const categoriesByLevelsWidth = document.getElementById("categoriesByLevels")
PapiroConsole.log("=== categoriesByLevelsWidth ===")
PapiroConsole.log(categoriesByLevelsWidth)

const CustomWidthPopper = function () {
  return (<Popper style={{ width: categoriesByLevelsWidth && categoriesByLevelsWidth.clientWidth ? categoriesByLevelsWidth.clientWidth : "100%" }} />)
}

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
	  boxSizing: 'border-box',
	  '& ul': {
		padding: 0,
		margin: 0,
	  },
	},
  [`& .${autocompleteClasses.popper}`]: {
    width: categoriesByLevelsWidth && categoriesByLevelsWidth.clientWidth ? categoriesByLevelsWidth.clientWidth : "100%",
    minWidth: categoriesByLevelsWidth && categoriesByLevelsWidth.clientWidth ? categoriesByLevelsWidth.clientWidth : "100%"
  },
  [`& .${autocompleteClasses.paper}`]: {
    width: categoriesByLevelsWidth && categoriesByLevelsWidth.clientWidth ? categoriesByLevelsWidth.clientWidth + 20 : "100%",
    minWidth: categoriesByLevelsWidth && categoriesByLevelsWidth.clientWidth ? categoriesByLevelsWidth.clientWidth + 20 : "100%"
  },
  });

const OPTIONS = Array.from(new Array(10000))
  .map(() => random(10 + Math.ceil(Math.random() * 20)))
  .sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));


  
const renderGroup = params => [
  <ListSubheader key={params.key} component="div">
    {params.key}
  </ListSubheader>,
  params.children,
];


const useStyles = makeStyles(theme => ({
  listbox: {
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },  
}));


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const popperStyles = makeStyles({
  paper: { width: "700px !important" }
})

export default function SimpleAutoCompleteByName(props) {

  const popperClasses = popperStyles()

  const convertHtmlOption = value => {
    let body = value
    let temp = document.createElement("div")
    temp.innerHTML = body
    let sanitized = temp.textContent || temp.innerText

    return sanitized
  }

  const { label, options, optionsText, stateName, changeSelect, selected, required, disabled, created = false, customRenderOption, name = '', htmlOptions = false, intl } = props;
 
  //console.log(options);
  const defaultvalue = {id:0, name:''};
  const classes = useStyles();

  //var initIx = convertHtmlOption(selected ? selected : defaultvalue)

  const [opt, setOption] = React.useState(selected ? selected : defaultvalue);
  
  React.useEffect(() => {
    //let cnvrt = convertHtmlOption(selected ? selected : defaultvalue)
    setOption(selected ? selected : defaultvalue);
  }, [selected]);
  
  const handleChange = (event,value) => {
    
    if(value == null){
      value= {id:0, name:''};
    }
    setOption(value);
    changeSelect(stateName, value);
  };
  const getIds = () => {
    const idList = options.map(item => {
      return item.id
    });
    return idList;
 
  };
  const getNames = () => {
    const idList = options.map(item => {
      return item.name
    });
    return idList;

  }  
 
  const Result = () => {

      return (<Autocomplete
          id="combo-box"
          options={options}
          /*classes={{
            paper: popperClasses.paper
          }}*/
          autoHighlight
          //ListboxComponent={!customRenderOption ? ListboxComponent : undefined}
          value={opt}
          getOptionLabel={option => option.name}
          onChange={handleChange}
          style={{ fullWidth: true }}
          disabled={disabled}
          renderInput={params =>  (
            <Tooltip title={<h5>{params.inputProps.value}</h5>} placement="top">
              <TextField {...params}
                label={label}
                name={name}
                variant="outlined"
                required={required != null ? required == false ? false : true : false}
                disabled={disabled}
                fullWidth
                InputProps={ (opt && opt.cancreatecalled != null && opt.cancreatecalled === false) ? {
                  ...params.InputProps,
                  startAdornment: <InputAdornment position="start">
                    <Tooltip title={<h5>{intl.formatMessage({id:"called.registration.create.error.category"})}</h5>} placement="top">
                      <WarningIcon style={{ color: '#F00', fill: '#F00' }} />
                    </Tooltip>
                    </InputAdornment>
                } : {...params.InputProps}} />
            </Tooltip>
          )}
          /*renderOption={(props, option) => (
            <Box
              component="div"
              {...props}
              style={{ overflow: "visible" }}>
                { option.name ? option.name : option.parentname }
              </Box>
          ) }*/ />
      );
    
  }

  return (
    <Result />
  );
}

SimpleAutoCompleteByName.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array,
    stateName: PropTypes.string,
    changeSelect: PropTypes.func,
    selected: PropTypes.object,
    checkbox: PropTypes.bool,
    created: PropTypes.bool
  };