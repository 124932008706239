import Api from './api';

export const getById = async (id) => { 
  let data = []
  await Api.get(`/calledservicesettings/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByIdWithoutIncludes = async (id) => { 
  let data = []
  await Api.get(`/calledservicesettings/${id}/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByHirerId = async (hirerId) => { 
  let data = []
  await Api.get(`/calledservicesettings/hirer/${hirerId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const getByHirerIdWithoutIncludes = async (hirerId) => { 
  let data = []
  await Api.get(`/calledservicesettings/hirer/${hirerId}/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const paginate = async (page, size = 10) => { 
  let data = []
  await Api.get(`/calledservicesettings/paginate?page=${page}&size=${size}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const addItem = async (formData) => { 
  let data = []
  await Api.post(`/calledservicesettings`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const editItem = async (formData) => { 
  let data = []
  await Api.put(`/calledservicesettings`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const deleteItem = async (id) => { 
  let data = []
  await Api.delete(`/calledservicesettings/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}