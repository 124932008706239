import Api from './api';

export const getAllCalledOrganizationChecklist = async () => {
  let data = []
  await Api.get('/calledorganizationchecklists')
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getCalledOrganizationChecklistById = async (id) => {
  let data = []
  await Api.get(`/calledorganizationchecklists/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getCalledOrganizationChecklistFileDownload = async (id) => {
  let data = []
  await Api.get(`/calledorganizationchecklists/downloadtwo/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getAllCalledOrganizationChecklistByCalledId = async (calledId) => {
  let data = []
  await Api.get(`/calledorganizationchecklists/called/${calledId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getAllCalledOrganizationChecklistByCalledIdAndType = async (calledId, type) => {
  let data = []
  await Api.get(`/calledorganizationchecklists/called/${calledId}/type/${type}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getAllCalledOrganizationChecklistByCalledPaginate = async (page, calledId, search, field) => {
  let data = []
  await Api.get(`/calledorganizationchecklists/called/paginate?id=${calledId}&page=${page}&search=${search}&field=${field}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getAllCalledOrganizationChecklistByOrganizationChecklistId = async (organizationChecklistId) => {
  let data = []
  await Api.get(`/calledorganizationchecklists/organizationchecklist/${organizationChecklistId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getAllCalledOrganizationChecklistByCalledIdAndOrganizationChecklistId = async (calledId, organizationChecklistId) => {
  let data = []
  await Api.get(`/calledorganizationchecklists/calledorgcheck/${calledId}/${organizationChecklistId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const insertCalledOrganizationChecklist = async CalledOrganizationChecklistFormData => { 
  let data = [];
  await Api.post('/calledorganizationchecklists', CalledOrganizationChecklistFormData)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const insertByCalledCalledOrganizationChecklist = async CalledOrganizationChecklistFormData => { 
  let data = [];
  await Api.post('/calledorganizationchecklists/called', CalledOrganizationChecklistFormData)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const updateCalledOrganizationChecklist = async CalledOrganizationChecklistFormData => { 
  let data = [];
  await Api.put('/calledorganizationchecklists', CalledOrganizationChecklistFormData)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const updateValueCalledOrganizationChecklist = async CalledOrganizationChecklistFormData => { 
  let data = [];
  await Api.put('/calledorganizationchecklists/value', CalledOrganizationChecklistFormData)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const updateEvidenceTextCalledOrganizationChecklist = async CalledOrganizationChecklistFormData => { 
  let data = [];
  await Api.put('/calledorganizationchecklists/ev/text', CalledOrganizationChecklistFormData)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const updateEvidenceAttachCalledOrganizationChecklist = async (CalledOrganizationChecklistFormData) => { 
  
  let config = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  }

  let data = [];
  await Api.put('/calledorganizationchecklists/ev/attach', CalledOrganizationChecklistFormData, config)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const deleteCalledOrganizationChecklist = async id => { 
  let data = [];
  await Api.delete(`/calledorganizationchecklists/${id}`)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const deleteEvidenceAttachCalledOrganizationChecklist = async id => { 
  let data = [];
  await Api.delete(`/calledorganizationchecklists/ev/attach/${id}`)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const deleteAttachCalledOrganizationChecklist = async id => { 
  let data = [];
  await Api.delete(`/calledorganizationchecklists/attach/${id}`)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const deleteCalledOrganizationChecklistAttachment = async id => { 
  let data = [];
  await Api.delete(`/calledorganizationchecklists/ev/attach/${id}`)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getOrganizationFieldsByOrganization = async (organizationId) => { 
  let data = [];
  await Api.get('/organizationfields/organizations/'+organizationId)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getActiveOrganizationFieldsByOrganization = async (organizationId) => { 
  let data = [];
  await Api.get(`/organizationfields/organizations/${organizationId}/active`)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
}


export const getActiveOrganizationFieldsByOrganizationAndCategories = async (organizationId) => { 
  let data = [];
  await Api.get(`/organizationfields/organizations/${organizationId}/field/active`)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
}

export const getOrganizationFieldsByOrganizationCustomerEdit = async (organizationId) => { 
  let data = [];
  await Api.get('/organizationfields/organizations/customeredit/'+organizationId)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const GetByOrganizationCategoryIdAndActive = async (organizationCategoryId) => { 
  let data = [];
  await Api.get('/organizationfields/active/'+organizationCategoryId)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};


export const GetByOrganizationCategoryIdAndActiveCustomerEdit = async (organizationCategoryId) => { 
  let data = [];
  await Api.get('/organizationfields/active/customeredit/'+organizationCategoryId)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getActiveOrganizationFieldsByOrganizationCustomerEdit = async (organizationId) => { 
  let data = [];
  await Api.get(`/organizationfields/organizations/customeredit/${organizationId}/active`)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getCalledOrganizationFieldsByCalled = async (calledId) => { 
  let data = [];
  await Api.get('/calledorganizationfields/calleds/'+calledId)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const insertCalledOrganizationFields = async calledOrganizationFieldFormData => { 
  let data = [];
  await Api.post('/calledorganizationfields', calledOrganizationFieldFormData)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const updateCalledOrganizationFields = async calledOrganizationFieldFormData => { 
  let data = [];
  await Api.put('/calledorganizationfields', calledOrganizationFieldFormData)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};