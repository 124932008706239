export function addCurrent(organizationmaterial) {
    return {
        type: 'ADD_CURRENT',
        organizationmaterial,
    }
}

export function addCurrentOrganization(organizationmaterial,organization) {
    return {
        type: 'ADD_CURRENT_ORGANIZATION',
        organizationmaterial,
        organization,
    }
}