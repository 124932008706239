import React from 'react';
import HeaderImg from '../../images/logo-cmtech-small-2.png';


const TODOFeedbackHeader = props => (
    <div>
        <img
            src="https://cmtech.2do.mexx.ai/static/media/logo-cmtech-login.08c41bf8.png"
           
            title="FeedbackHeader"
            className='App-feedback-header' />
    </div>
);

export default TODOFeedbackHeader;
