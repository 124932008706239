import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { InputLabel, TextField, FormControl, Checkbox } from "@mui/material";
import Autocomplete, { autocompleteClasses }  from "@mui/material/Autocomplete";
import { VariableSizeList } from "react-window";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListSubheader from "@mui/material/ListSubheader";
import { useTheme } from "@mui/styles";
import { Typography } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Popper from '@mui/material/Popper';
import {  styled } from '@mui/material/styles';

const LISTBOX_PADDING = 8; // px

const toggleItemActive = ({ doSomething, value }) => {
  //console.log(doSomething);
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function renderRow(props) {
	const { data, index, style } = props;
	const dataSet = data[index];
	const inlineStyle = {
	  ...style,
	  top: style.top + LISTBOX_PADDING,
	};
    //console.error('dataSet');
    //console.error(dataSet);
	if (dataSet.hasOwnProperty('group')) {
	  return (
		<ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
		  {dataSet.group}
		</ListSubheader>
	  );
	}
  
	return (
	  <Typography component="li" {...dataSet[0]} style={inlineStyle}>
		{ dataSet[1].parentname ? dataSet[1].parentname : dataSet[1].name }
	  </Typography>
	);
  }
  
  const OuterElementContext = React.createContext({});
  
  const OuterElementType = React.forwardRef((props, ref) => {
	const outerProps = React.useContext(OuterElementContext);
	return <div ref={ref} {...props} {...outerProps} />;
  });

  function useResetCache(data) {
	const ref = React.useRef(null);
	React.useEffect(() => {
	  if (ref.current != null) {
		ref.current.resetAfterIndex(0, true);
	  }
	}, [data]);
	return ref;
  }
  
  // Adapter for react-window
  const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
	const { children, ...other } = props;
	const itemData = [];
	children.forEach((item) => {
	  itemData.push(item);
	  itemData.push(...(item.children || []));
	});
  
	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
	  noSsr: true,
	});
  
	const itemCount = itemData.length;
	const itemSize = smUp ? 36 : 48;
  
	const getChildSize = (child) => {
	  if (child.hasOwnProperty('group')) {
		return 48;
	  }
  
	  return itemSize;
	};
  
	const getHeight = () => {
	  if (itemCount > 8) {
		return 8 * itemSize;
	  }
	  return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
	};
  
	const gridRef = useResetCache(itemCount);
  
	return (
	  <div ref={ref}>
		<OuterElementContext.Provider value={other}>
		  <VariableSizeList
			itemData={itemData}
			height={getHeight() + 2 * LISTBOX_PADDING}
			width="100%"
			ref={gridRef}
			outerElementType={OuterElementType}
			innerElementType="ul"
			itemSize={(index) => getChildSize(itemData[index])}
			overscanCount={5}
			itemCount={itemCount}
		  >
			{renderRow}
		  </VariableSizeList>
		</OuterElementContext.Provider>
	  </div>
	);
  });
  
  ListboxComponent.propTypes = {
	children: PropTypes.node,
  };

function random(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}

const StyledPopper = styled(Popper)({
	[`& .${autocompleteClasses.listbox}`]: {
	  boxSizing: 'border-box',
	  '& ul': {
		padding: 0,
		margin: 0,
	  },
	},
  });

const OPTIONS = Array.from(new Array(10000))
  .map(() => random(10 + Math.ceil(Math.random() * 20)))
  .sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));


  
const renderGroup = params => [
  <ListSubheader key={params.key} component="div">
    {params.key}
  </ListSubheader>,
  params.children,
];

const useStyles = makeStyles((theme) => ({
  listbox: {
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
}));

export default function SimpleAutoCompleteMultiple(props) {
  const {
    label,
    options,
    optionsText,
    stateName,
    changeSelect,
    selected,
    required,
    disabled = false,
  } = props;

  const defaultvalue = [];
  const classes = useStyles();

  const [opt, setOption] = React.useState(selected ? selected : defaultvalue);

  React.useEffect(() => {
    setOption(selected ? selected : defaultvalue);
  }, [selected]);

  const handleChange = (event, value) => {
    if (value == null) {
      value = [];
    }

    setOption(value);
    changeSelect(stateName, value);
  };
  function getIds() {
    const idList = options.map((item) => {
      return item.id;
    });
    return idList;
  }
  function getNames() {
    const idList = options.map((item) => {
      return item.tipping
        ? item.tipping
        : item.parentname
        ? item.parentname
        : item.name;
    });
    return idList;
  }
  function ResultTemp(){
    return (

      <Autocomplete
      multiple
      id="tags-outlined"
      options={top100Films}
      getOptionLabel={(option) => option.title}
      
      defaultValue={[top100Films[2]]}
      filterSelectedOptions
      //renderOption={(option) => <Typography noWrap>{option}</Typography>}
      renderInput={(params) => (
       <TextField
          {...params}
          variant="outlined"
          fullWidth
          label={label}
          placeholder={label}
        />
      )}
    />

    );

  }  
  function Result() {
    return (
      
      <Autocomplete
        disabled={disabled}
        multiple
        limitTags={2}
        disableCloseOnSelect
        id="combo-box"
		PopperComponent={StyledPopper}
        filterSelectedOptions={true}
        options={options}
        disableListWrap
        classes={classes}
        value={opt}
        //defaultValue={opt}
        ListboxComponent={ListboxComponent}
        getOptionLabel={(option) =>
          option.parentname ? option.parentname : option.name ? option.name : ""
        }
        getOptionDisabled={null}
        onChange={handleChange}
        
        //renderOption={(option) => <Typography component={'span'} variant={'body2'} noWrap>{option}</Typography>}
		renderOption={(props, option) => [props, option] } 

        style={{
	      fullWidth: true,
          display: "table",
          tableLayout: "fixed",
          width: "100%",
          maxWidth: "100%",
        }}
        //renderOption={(option, { selected }) => (
        //  <React.Fragment>
        //    <Checkbox
        //      icon={icon}
        //      checkedIcon={checkedIcon}
        //      style={{ marginRight: 8 }}
        //      checked={selected}
        //    />
        //    {option}
        //  </React.Fragment>
        //)}

        renderInput={(params) => (
          <TextField {...params} label={label} variant="outlined" placeholder={label} fullWidth />
        )}
      />
    );
  }
  return <Result />
  //return <div><Result /> <ResultTemp/></div>
}

SimpleAutoCompleteMultiple.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  stateName: PropTypes.string,
  changeSelect: PropTypes.func,
  selected: PropTypes.array,
};

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
  { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
  { title: 'The Good, the Bad and the Ugly', year: 1966 },
  { title: 'Fight Club', year: 1999 },
  { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
  { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
  { title: 'Forrest Gump', year: 1994 },
  { title: 'Inception', year: 2010 },
  { title: 'The Lord of the Rings: The Two Towers', year: 2002 },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: 'Goodfellas', year: 1990 },
  { title: 'The Matrix', year: 1999 },
  { title: 'Seven Samurai', year: 1954 },
  { title: 'Star Wars: Episode IV - A New Hope', year: 1977 },
  { title: 'City of God', year: 2002 },
  { title: 'Se7en', year: 1995 },
  { title: 'The Silence of the Lambs', year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: 'Life Is Beautiful', year: 1997 },
  { title: 'The Usual Suspects', year: 1995 },
  { title: 'Léon: The Professional', year: 1994 },
  { title: 'Spirited Away', year: 2001 },
  { title: 'Saving Private Ryan', year: 1998 },
  { title: 'Once Upon a Time in the West', year: 1968 },
  { title: 'American History X', year: 1998 },
  { title: 'Interstellar', year: 2014 },
  { title: 'Casablanca', year: 1942 },
  { title: 'City Lights', year: 1931 },
  { title: 'Psycho', year: 1960 },
  { title: 'The Green Mile', year: 1999 },
  { title: 'The Intouchables', year: 2011 },
  { title: 'Modern Times', year: 1936 },
  { title: 'Raiders of the Lost Ark', year: 1981 },
  { title: 'Rear Window', year: 1954 },
  { title: 'The Pianist', year: 2002 },
  { title: 'The Departed', year: 2006 },
  { title: 'Terminator 2: Judgment Day', year: 1991 },
  { title: 'Back to the Future', year: 1985 },
  { title: 'Whiplash', year: 2014 },
  { title: 'Gladiator', year: 2000 },
  { title: 'Memento', year: 2000 },
  { title: 'The Prestige', year: 2006 },
  { title: 'The Lion King', year: 1994 },
  { title: 'Apocalypse Now', year: 1979 },
  { title: 'Alien', year: 1979 },
  { title: 'Sunset Boulevard', year: 1950 },
  { title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb', year: 1964 },
  { title: 'The Great Dictator', year: 1940 },
  { title: 'Cinema Paradiso', year: 1988 },
  { title: 'The Lives of Others', year: 2006 },
  { title: 'Grave of the Fireflies', year: 1988 },
  { title: 'Paths of Glory', year: 1957 },
  { title: 'Django Unchained', year: 2012 },
  { title: 'The Shining', year: 1980 },
  { title: 'WALL·E', year: 2008 },
  { title: 'American Beauty', year: 1999 },
  { title: 'The Dark Knight Rises', year: 2012 },
  { title: 'Princess Mononoke', year: 1997 },
  { title: 'Aliens', year: 1986 },
  { title: 'Oldboy', year: 2003 },
  { title: 'Once Upon a Time in America', year: 1984 },
  { title: 'Witness for the Prosecution', year: 1957 },
  { title: 'Das Boot', year: 1981 },
  { title: 'Citizen Kane', year: 1941 },
  { title: 'North by Northwest', year: 1959 },
  { title: 'Vertigo', year: 1958 },
  { title: 'Star Wars: Episode VI - Return of the Jedi', year: 1983 },
  { title: 'Reservoir Dogs', year: 1992 },
  { title: 'Braveheart', year: 1995 },
  { title: 'M', year: 1931 },
  { title: 'Requiem for a Dream', year: 2000 },
  { title: 'Amélie', year: 2001 },
  { title: 'A Clockwork Orange', year: 1971 },
  { title: 'Like Stars on Earth', year: 2007 },
  { title: 'Taxi Driver', year: 1976 },
  { title: 'Lawrence of Arabia', year: 1962 },
  { title: 'Double Indemnity', year: 1944 },
  { title: 'Eternal Sunshine of the Spotless Mind', year: 2004 },
  { title: 'Amadeus', year: 1984 },
  { title: 'To Kill a Mockingbird', year: 1962 },
  { title: 'Toy Story 3', year: 2010 },
  { title: 'Logan', year: 2017 },
  { title: 'Full Metal Jacket', year: 1987 },
  { title: 'Dangal', year: 2016 },
  { title: 'The Sting', year: 1973 },
  { title: '2001: A Space Odyssey', year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: 'Toy Story', year: 1995 },
  { title: 'Bicycle Thieves', year: 1948 },
  { title: 'The Kid', year: 1921 },
  { title: 'Inglourious Basterds', year: 2009 },
  { title: 'Snatch', year: 2000 },
  { title: '3 Idiots', year: 2009 },
  { title: 'Monty Python and the Holy Grail', year: 1975 },
];