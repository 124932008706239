import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Fade from '@mui/material/Fade'
import ClearIcon from '@mui/icons-material/Clear'
import MessageAttachment from './message-attachment'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade direction="up" ref={ref} {...props} />
})

function ChatMessageStickerDialog({ url, title, isOpen, handleCloseDialog }) {
  return (<Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      aria-describedby="chat-message-image-dialog" >
      <DialogTitle>
        {title}
        <ClearIcon onClick={handleCloseDialog}  style={{ float: 'right', cursor : 'pointer'}}/> 
      </DialogTitle>
      <DialogContent className="chat-message-image-dialog">
        <MessageAttachment
          src={url}
          type={"sticker"}
          alt={`${title}.sticker`} />
      </DialogContent>
  </Dialog>)
}

export default React.memo(ChatMessageStickerDialog)