import React, { Component } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import moment from "moment";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
} from "@mui/material";
import { QueuePlayNext } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import CustomizedSnackbars from "../../components/material-snackbars";
import HeaderMenu from "../../components/header-menu";
import { connect } from "react-redux";
import { browserHistory } from "../../helpers/history";
import SimpleAutoComplete from "../../components/auto-complete/autocomplete";
import { FormattedMessage, injectIntl } from "react-intl";
import Title from "../../components/title-name";
import * as componentActions from "../../redux/actions/articlessection";
import { PapiroConsole } from "../../utils/papiroConsole";
import ReactQuill from "react-quill";

class ArticlesSectionEdit extends Component {
  constructor(props) {
    super(props);
    const { userSession, articlessection } = this.props;
    var propsSection = this.props;
    PapiroConsole.log("Props de article section --->");
    PapiroConsole.log(propsSection);

    this.fromProps = false;
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.from &&
      this.props.location.state.from.length > 0
    )
      this.fromProps = true;

    this.globalAdm = false;
    this.isClient = true;
    if (userSession.user.userrole == null) {
      Api.logoff();
    }

    if (
      !articlessection ||
      (articlessection && !articlessection.articlessection)
    ) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/calleds`;
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    });

    this.initialState = {
      UpdatedUser: userSession.user.id,
      id: articlessection.articlessection.id,
      ParentSectionId: {
        id: articlessection?.articlessection?.parentsectionid,
        name: "",
      },
      name: articlessection.articlessection.name ?? "",
      parentname: articlessection.articlessection.parentname ?? "",
      description: articlessection.articlessection.description ?? "",
    };
    this.state = {
      articlessection: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      ParentSectionList: [],
    };

    PapiroConsole.log("initial state aqui ");
    PapiroConsole.log(this.initialState);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }

  async componentDidMount() {
    await this.getParentSection(this.props.articlessection.articlessection.id);
    await this.getData()
  }

  getData = async () => {

    const intl = this.props.intl;
    this.setState({ loading: true });

    Api.get(`/articles-section/${this.state.articlessection.id}`)
      .then(result => {
        if (result.data.success) {
          console.log("@log >> ", result.data.data)
          this.setState({
            loading: false,
            articlessection: {
              ...this.state.articlessection,
              description: result.data?.data?.description,
              ParentSectionId: this.state.ParentSectionList?.find(item => item.id === result.data?.data?.parentsectionid),
            },
          });
        }
        else {   
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
          Api.kickoff(result);
        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
        else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
      });
  };

  async getParentSection(id) {
    this.setState({ loading: true });
    var result = await Api.get(`/articles-section/get-all-parent-section/${id}`)
      .then((rs) => {
        return rs.data;
      })
      .catch((err) => {
        return err;
      });

    PapiroConsole.log("Result do getParentSection()");
    PapiroConsole.log(result);

    if (result.success) {
      var data = result.data;
      PapiroConsole.log(data);
      var parentResult = [];
      data.map((item) => {
        var parent = { id: item.id, name: item.parentname ?? item.name };
        parentResult.push(parent);
      });
      var parentsectionid = parentResult.find(
        (c) =>
          c.id == this.props.articlessection.articlessection.parentsectionid
      );
      PapiroConsole.log("articlessectionid");
      PapiroConsole.log(parentsectionid);

      this.setState((prevState) => ({
        loading: false,
        ParentSectionList: parentResult,
        articlessection: {
          ...prevState.articlessection,
          ParentSectionId: parentsectionid,
        },
      }));
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response &&
          result.response.data &&
          result.response.data.errors &&
          result.response.data.errors[0]
            ? result.response.data.errors[0]
            : this.props.intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }

    return result.data;
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState((prevState) => ({
      articlessection: {
        ...prevState.articlessection,
        [name]: value,
      },
    }));
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();

    for (let key in this.state.articlessection) {
      if (
        key.toString().toLowerCase().indexOf("_date") > -1 ||
        key.toString().toLowerCase().indexOf("date_") > -1
      ) {
        data.append(
          key,
          moment(this.state.articlessection[key]).format("YYYY/MM/DD HH:mm:ss")
        );
      } else if (
        key.toString().toLowerCase().indexOf("parentsectionid") > -1 &&
        this.state.articlessection[key] != ""
      ) {
        const parentIdVar = this.state.articlessection[key]?.id
          ? this.state.articlessection[key]?.id
          : 0;
        data.append("parentsectionid", parentIdVar);
      } else if (key.toString().toLowerCase().indexOf("parentname") > -1) {
        const parentNameVar = this.state.articlessection["ParentSectionId"]?.name
          ? this.state.articlessection["ParentSectionId"]?.name
          : "";
        data.append("parentname", parentNameVar);
      } else {
        data.append(key, this.state.articlessection[key]);
      }
    }

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    Api.put("/articles-section", data, config)

      .then((result) => {
        const intl = this.props.intl;
        if (result.data.success) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({ id: "edit.success" }),
          });
        } else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              result.data.errors && result.data.errors[0]
                ? result.data.errors[0]
                : this.props.intl.formatMessage({ id: "process.error" }),
          });
        }
      })
      .catch((err) => {
        const intl = this.props.intl;
        if (err.response && err.response.data && err.response.data.errors) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : this.props.intl.formatMessage({ id: "process.error" }),
          });
        } else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: err.response.data.errors
              ? err.response.data.errors[0]
              : this.props.intl.formatMessage({ id: "process.error" }) +
                err.response.data,
          });
        }
      });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value) {
    this.setState({ loading: true });

    if (stateName === "ParentSectionId") {
      this.setState((prevState) => ({
        articlessection: {
          ...prevState.articlessection,
          ["ParentSectionId"]: value,
        },
      }));
    }

    this.setState({ loading: false });
  }

  handleChangeEditor(value, name) {
    this.setState(prevState => ({
      articlessection: {
            ...prevState.articlessection,
            [name]: value || ""
        }
    }));
  }

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { articlessection } = this.state;
    const intl = this.props.intl;

    PapiroConsole.log("console do articlessection");
    PapiroConsole.log(articlessection);

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`;
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(
            classes.content,
            {
              [classes.contentShift]: headerMenu.open,
            },
            classes.actionsContent,
            {
              [classes.actionscontentShift]: actionsHeaderMenu.open,
            }
          )}
        >
          <div className={classes.drawerHeader} />

          <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      browserHistory.goBack();
                    }}
                  >
                    {this.props.intl.formatMessage({ id: "back" })}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component="h1" variant="h5">
                {<FormattedMessage id="edit.item" />}
                <Title />
              </Typography>
              <form
                name="myForm"
                className={classes.form}
                onSubmit={this.handleSubmit}
                encType="multipart/form-data"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      inputProps={{ maxLength: 255 }}
                      fullWidth
                      label={intl.formatMessage({ id: "name" })}
                      autoComplete="fname"
                      variant="outlined"
                      name="name"
                      onChange={this.handleInputChange}
                      value={articlessection.name}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "parent.section.name" })}
                      name="parentsectionid"
                      options={this.state.ParentSectionList}
                      stateName="ParentSectionId"
                      changeSelect={this.changeValues}
                      selected={articlessection.ParentSectionId}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography>
                      <FormattedMessage id="section.description" />
                    </Typography>
                    <div
                      style={{
                        resize: "vertical",
                        overflow: "auto",
                        height: "250px",
                        marginBottom: "24px",
                        border: "1px solid #ccc",
                      }}
                    >
                      <ReactQuill
                        name="description"
                        value={articlessection.description}
                        style={{ height: "100%" }}
                        modules={this.reactQuillModules}
                        formats={this.reactQuillFormats}
                        fullWidth
                        variant="outlined"
                        id="description"
                        onChange={(value) =>
                          this.handleChangeEditor(value, "description")
                        }
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{
                      marginTop: 15,
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: 15,
                    }}
                  >
                    <Grid
                      container
                      justify="flex-end"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Grid item xs={12} sm={4}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
      </div>
    );
  }
}

ArticlesSectionEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  articlessection: state.articlessection,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(Styles)(ArticlesSectionEdit))
);
