import { CircularProgress, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { Overlay } from 'src/styles/global';
import { getItemsSelect } from '../../services/chatbotflow.service';
import Select from '../Chatbot/Input/Select/Select';
import './HelperCustomField.scss';

interface HelperCustomFieldProps{
  isRichText: boolean
  description: string,
  param1: string[],
  onChangeDescription: Function,
  chatbotFlowId: string
}


const HelperCustomField: React.FC<HelperCustomFieldProps> = ({
  isRichText,
  description,
  param1,
  onChangeDescription,
  chatbotFlowId
}) => {

  const [loading, setLoading] = useState(false);
  const [showContainer, setShowContainer] = useState(false);
  
  const [labelSelect, setLabelSelect] = useState('');
  const [items, setItems] = useState<Array<{id:string, name:string}>>([]);

  const [messageFecth, setMessageFetch] = useState('');

  const handleChangeSelect = (value: string) => {

    let param = items?.find(p => p.id === value);

    if (param) {
      let descriptionModifield = description.replace(/^<p>/, "").replace(/<\/p>$/, "");

      if (isRichText) { 
        onChangeDescription(param1, `<p>${descriptionModifield} ${value}</p>`);
      } else {
        onChangeDescription(param1, `${descriptionModifield} ${value}`);
      }
    }
  }

  const handleSelectTypeCustomField = async (type: string) => {
    setLoading(true);
    
    setItems([]);
    setMessageFetch("");
    const _fetchItems = await fetchItems(type);

    switch(type) {
      case 'param':  
        setLabelSelect('Parâmetros');
        break;
      case 'variable':
        setLabelSelect('Variáveis');
        break;
      case 'customField':
        setLabelSelect('Campos personalizados');
        break;
    }
    
    if (_fetchItems?.length <= 0) {
      setMessageFetch("Não foi encontrado registros...");
    } else {
      setItems(_fetchItems);
    }

    setLoading(false);
  } 

  const fetchItems = async (type: string) => {
    let items = await getItemsSelect(chatbotFlowId, type);

    if (type === "variable") {
      items = [
        {id:"@MEXXNUMEROCHAMADO@", name: "MEXXNUMEROCHAMADO"}, 
        ...items
      ];
    }
    
    return items;
  }

  useEffect(() => {
    setItems([]);
    setLabelSelect('');
    setMessageFetch('');
  }, [showContainer])

  return (
    <div className="box-container">
      <div 
        style={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            marginTop: '8px',
            marginBottom: '8px'
        }}
      >
        <input 
            type="checkbox" 
            style={{
                height: '16px',
                width: '16px',
                margin: '0',
                padding: '0'
            }} 
            checked={showContainer}
            onChange={(e) => setShowContainer(e?.target?.checked)}
        />
        <label
            style={{
                marginTop: '4px'
            }}
        >
          Deseja adicionar um valor de campo dinâmico?
        </label>
      </div>

      {showContainer && (
        <div style={{width: '100%'}}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            onChange={(e) => handleSelectTypeCustomField(e.target.value)}
          >
            <FormControlLabel value="param" control={<Radio />} label="Parâmetros" />
            <FormControlLabel value="variable" control={<Radio />} label="Variáveis" />
            <FormControlLabel value="customField" control={<Radio />} label="Campos Personalizados" />
          </RadioGroup>

          {
            items?.length > 0 
            ? (
              <label style={{width: '100%'}}>{labelSelect}
                  <Select
                      onChange={(e) => handleChangeSelect(e.target.value)}
                      options={items}
                  />
              </label>
            )
            : <p className='msg-fetch'>{messageFecth}</p>
          }
          
        </div>
      )}

      {loading && (
          <Overlay>
              <CircularProgress color='secondary' />
          </Overlay>
      )}
    </div>
  )
}

export default HelperCustomField;