import React, { Component, useEffect, useState } from 'react'
import Api from '../../services/api'
import * as componentActions from '../../redux/actions/chats'
import CustomizedSnackbars from '../../components/material-snackbars'
import PropTypes from 'prop-types'
import { AbilityContext } from '../../config/ability-context'
import { bindActionCreators } from 'redux'
import { CircularProgress, Container, CssBaseline, Grid, Button } from '@mui/material'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { PapiroConsole } from '../../utils/papiroConsole'
import { Styles } from '../../styles/material-styles-omnichannel'
import { Overlay } from '../../styles/global'
import { withRouter } from 'react-router-dom';
import { makeStyles, withStyles } from '@mui/styles'
import Chat from './components/chat'
import ChatMenu from './components/chat-menu'
import './styles.css'
import moment from 'moment'
import clsx from 'clsx';
import { GetChatListByTech as GetChatListByTechService, getUserStatusList, getEnabledUsers, GetChatToObserve } from '../../services/2talk/omnichannel';

import { getOrganizationsNewSimple } from '../../services/organization.service';
import { getCategoriesByOrganizationSimpleList } from '../../services/organizationcategory.service';

import { getAllSimple as getPriorityListSimple } from '../../services/priority.service';
import { getOrganizationsDepartamentsSimpleByOrganizationId } from '../../services/organizationdepartament.service';




import ChatChannelsTabs from './components/chat/chat-channels-tabs'
import ChatInboxHeader from './components/chat/chat-inbox-header'
import ChatUserDetailsSidebar from './components/chat/chat-user-details-sidebar'
import { browserHistory } from '../../helpers/history';
import Api2Talk from "../../services/api2talk";
import DialogGeneralSm from '../../components/dialog-general-sm'
import DialogGeneralWithAutoComplete from '../../components/dialog-general-with-auto-complete'
import DialogGeneralWithCheckbox from '../../components/dialog-general-with-checkbox'
import DialogWithRichTextAndAttachment from './components/dialogs/dialog-with-rich-text-and-attachment'

const drawerWidth = 230;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    position: 'absolute',
  },
  content: {
    flexGrow: 1,
    marginRight: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    marginRight: drawerWidth, // Ajuste conforme necessário
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  open: {
    width: '80%',
    '@media (max-width:1199.9px)': {
      width: '70%',
    },
    '@media (min-width:1200px)': {
      width: '80%',
    }
  },
  toolbar: theme.mixins.toolbar,
}));

function ToTalkOmniChannel(props) {
  const { userSession, techinicalTeamList, phoneNumberList, load,isGlobalAdm,
    channelList, providerList, statusList, chatClosingStatus, techList, changeValues, handleInputChange, getChatsByTechWithFilters, handleChangeEditor, sendMessage, sendMessageAttachment, reSendMessage, phonenumberid, customerid, loadingcustomerid, noteDescription, customerList, intl, openOrCloseConfirmationModal, openOrCloseEndChatModal, openOrCloseDialogSyncDataModal, openOrCloseDialogAddNoteModal, openOrCloseTransferChatModal, DialogAddNoteState, DialogRemoveState, DialogEndChatState, DialogTransferChatState, DialogSyncDataState, handleStatusChangeConfirmation, handleAddnoteConfirmation, handleDataSyncConfirmation, handleEndChatConfirmation, handleTransferChatConfirmation, newStatusValue, messageNotification, notificationType, openNotificationMessage, automaticallyCreateCalledAfterEndingChat, createCalledToChat, files, validateAtachments, removeItem } = props
  //console.error('phoneNumberList -> ');
  //console.error(phoneNumberList);
  //console.error('load -> '+load);
  if (Api == null) {
    var l = props.match.params.lang;
    document.location.href = `/${l}/unavailable`
  }

  const classes = useStyles();

  const [loading, setLoading] = useState()
  const [notificationMessage, setNotificationMessage] = useState('')
  const [notificationVariant, setNotificationVariant] = useState('success')
  const [openNotification, setOpenNotification] = useState(false)
  const [count, setCount] = useState(0)
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [userDetailsSidebarOpen, setUserDetailsSidebarOpen] = useState(false)
  const [statusOptions, setStatusOptions] = useState([])
  const [userStatusId, setUserStatusId] = useState(6)
  const [organizationList, setOrganizationList] = useState([])
  const [organizationCategoryList, setOrganizationCategoryList] = useState([])
  const [organizationDepartamentList, setOrganizationDepartamentList] = useState([])
  const [priorityList, setPriorityList] = useState([])




  const closeNotification = () => setOpenNotification(false)


  useEffect(() => {
    props.addCurrentOmnichannelChatList([])
    props.addCurrentChatList([])
    props.addTotalChatCount(0)
    props.addCurrentChat(null)
    props.handleChatUserDetailsSidebarToggle(false)
  }, [])


  useEffect(() => {

    
    if(props.chatId != null){
      getChatsByTech(props.chatId)
      getStatusOptions()
      getEnableUsers()
      getOrganizationList()
      getPriorityList()
      if (props.chats && props.chats.chat && props.chats.chat.organizationid) {
        let orgId = props.chats && props.chats.chat && props.chats.chat.organizationid
        getOrganizationsDepartamentsByOrganizationId(orgId)
        getCategoriesByOrganizationId(orgId)
      }
  
      PapiroConsole.log("props aqui 1 ")
      PapiroConsole.log(props)
    }
   

  }, [props.chatId])

  useEffect(() => {
    if (isSidebarOpen != props.chats.issidebaropen)
      setIsSidebarOpen(props.chats.issidebaropen)
  }, [props.chats.issidebaropen])

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 599.9);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 599.9);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /*useEffect(() => {
      setLoading(load)
  }, [load])

  useEffect(() => {

    PapiroConsole.log("entrei aqui openNotificationMessage")
    PapiroConsole.log(openNotificationMessage)
    if(openNotificationMessage == true){
      setOpenNotification(openNotificationMessage)
      setNotificationVariant(notificationType)
      setNotificationMessage(messageNotification)
    }
      
}, [openNotificationMessage])*/



  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen)
    props.handleChatUserDetailsSidebarToggle(!isSidebarOpen)
  }

  const getChatsByTech = async (id) => {
    
      let result = await GetChatToObserve(id)
      PapiroConsole.log(result)
      PapiroConsole.log("result requisição ")
      if (result && result.success) {
        let chatList = result.data;
        props.addCurrentOmnichannelChatList(chatList);
        props.addTotalChatCount(chatList.length);
        setCount(chatList.length);
        getCurrentChat(chatList,id)

      }
      else {
        setOpenNotification(true)
        setNotificationVariant('error')
        setNotificationMessage(result.errors && result.errors.length > 0 ? result.errors[0] : intl.formatMessage({ id: "process.error" }))
        
      }

    

  }



  const getStatusOptions = async _ => {

    let result = await getUserStatusList()
    if (result && result.success) {
      let statusOptions = result.data;
      setStatusOptions(statusOptions)

    }

    else {

      setOpenNotification(true)
      setNotificationVariant('error')
      setNotificationMessage(result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))

    }
  }

  const getEnableUsers = async _ => {

    var userStatusId = 6;
    let userId = props.userSession && props.userSession.user ? props.userSession.user.id : null
    PapiroConsole.log(props)
    PapiroConsole.log("props iniciada")
    let result = await getEnabledUsers()
    if (result && result.success) {

      PapiroConsole.log(result)
      PapiroConsole.log("result data")

      let enableUsers = result.data;
      if (userId) {
        var currentUser = enableUsers.find(item => item.userid === userId);
        userStatusId = currentUser ? currentUser.userstatusid : 6
      }

      setUserStatusId(userStatusId)

      //this.setState({ loading: false, enableUsers: enableUsers ? enableUsers : [], userStatusId: userStatusId })

    }
    else {
      setOpenNotification(true)
      setNotificationVariant('error')
      setNotificationMessage(result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
    }
  }

  const getOrganizationList = async _ => {


    let result = await getOrganizationsNewSimple()
    if (result && result.success) {
      if (result.data) {

        setOrganizationList(result.data)
      }
      PapiroConsole.log(result)

    }
    else {
      setOpenNotification(true)
      setNotificationVariant('error')
      setNotificationMessage(result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
    }
  }

  const getCategoriesByOrganizationId = async (id) => {


    let result = await getCategoriesByOrganizationSimpleList(id)
    if (result && result.success) {
      if (result.data) {

        setOrganizationCategoryList(result.data)
      }
      PapiroConsole.log(result)

    }
    else {
      setOpenNotification(true)
      setNotificationVariant('error')
      setNotificationMessage(result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
    }
  }


  const getOrganizationsDepartamentsByOrganizationId = async (id) => {


    let result = await getOrganizationsDepartamentsSimpleByOrganizationId(id)
    if (result && result.success) {
      if (result.data) {

        setOrganizationDepartamentList(result.data)
      }
      PapiroConsole.log(result)

    }
    else {
      setOpenNotification(true)
      setNotificationVariant('error')
      setNotificationMessage(result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
    }
  }

  const getPriorityList = async _ => {


    let result = await getPriorityListSimple()
    if (result && result.success) {

      if (result.data) {

        setPriorityList(result.data)
      }

    }
    else {
      setOpenNotification(true)
      setNotificationVariant('error')
      setNotificationMessage(result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
    }
  }





  const getCurrentChat = (chatList, id) => {

      let currentChat = chatList.find(item => item.id == id)
      PapiroConsole.log(currentChat)
      PapiroConsole.log("currentChat")
      props.addCurrentChat(currentChat)
    

  }

  const backToPreviousPage = () => {

    if (props && props.match && props.history) {
      const { lang } = props.match.params
      props.history.push(`/${lang}/chat-attendance-list`)
    }

  }

  const changeStatus = async (id) => {

    setLoading(true)

    Api2Talk.put(`/enableusers/changestatus/${id}`)
      .then(result => {
        PapiroConsole.log("resultado enable users")
        PapiroConsole.log(result)
        if (result.data.success) {

          setLoading(false)
          setOpenNotification(true)
          setNotificationVariant('success')
          setNotificationMessage('Status alterado com sucesso')

        }
        else {
          setLoading(false)
          setOpenNotification(true)
          setNotificationVariant('error')
          setNotificationMessage(result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))

        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          setLoading(false)
          setOpenNotification(true)
          setNotificationVariant('error')
          setNotificationMessage(err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
        }
        else {
          setLoading(false)
          setOpenNotification(true)
          setNotificationVariant('error')
          setNotificationMessage(err && err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
        }
      });

  }



  return (
    <main className='omnichannel-main' >
      <CssBaseline />
      <div className={clsx('omnichannel', classes.content, { [classes.open]: isSidebarOpen })}>
        <Grid container style={{ marginBottom: '20px' }}>
          {/*<Grid item xs={12} sm={4}>
            <Button variant='outlined' color='primary' onClick={backToPreviousPage}>{<FormattedMessage id="back" />}</Button>
  </Grid>*/}
          <Grid item xs={12} sm={8} />
        </Grid>
        <ChatInboxHeader  statusOptions={statusOptions} userStatusId={userStatusId} changeStatus={changeStatus} redirectprops={props} />
        
        <div style={{ display: 'flex', flexGrow: 1, maxHeight: '95%' }}>
         
          {(count >= 0) && (<Chat phonenumberid={phonenumberid} sendMessage={sendMessage} reSendMessage={reSendMessage}
            sendMessageAttachment={sendMessageAttachment}
            setNotificationMessage={setNotificationMessage}
            setNotificationVariant={setNotificationVariant}
            setOpenNotification={setOpenNotification}
            changeSelect={changeValues}
            customerid={customerid}
            loadingcustomerid={loadingcustomerid}
            customerList={customerList}
            statusList={statusList}
            isGlobalAdm = {isGlobalAdm}

            setLoading={setLoading}
            newStatusValue={newStatusValue}
            openOrCloseEndChatModal={openOrCloseEndChatModal}
            openOrCloseTransferChatModal={openOrCloseTransferChatModal}
          />)}
        </div>
      </div>
      <ChatUserDetailsSidebar
        isOpen={isSidebarOpen}
        onClose={handleSidebarToggle}
        intl={intl}
        setLoading={setLoading}
        setNotificationMessage={setNotificationMessage}
        setNotificationVariant={setNotificationVariant}
        setOpenNotification={setOpenNotification}
        openOrCloseDialogSyncDataModal={openOrCloseDialogSyncDataModal}
        openOrCloseDialogAddNoteModal={openOrCloseDialogAddNoteModal}
        priorityList={priorityList}
        organizationList={organizationList}
        organizationCategoryList={organizationCategoryList}
        organizationDepartamentList={organizationDepartamentList}
        getCategoriesByOrganizationId={getCategoriesByOrganizationId}
        getOrganizationsDepartamentsByOrganizationId={getOrganizationsDepartamentsByOrganizationId}
      />

      {/* Dialogs criados para o 2talk  */}

      <DialogGeneralSm
        dialogTitle={intl.formatMessage({ id: "status.change.confirmation" })}
        dialogSubTitle={intl.formatMessage({ id: "status.change.confirmation.info" })}
        open={DialogRemoveState}
        openOrCloseModalFunction={openOrCloseConfirmationModal}
        handleConfirmation={handleStatusChangeConfirmation}
      />
      <DialogGeneralSm
        dialogTitle={intl.formatMessage({ id: "data.sync" })}
        dialogSubTitle={intl.formatMessage({ id: "data.sync.info" })}
        open={DialogSyncDataState}
        openOrCloseModalFunction={openOrCloseDialogSyncDataModal}
        handleConfirmation={handleDataSyncConfirmation}
      />
      <DialogGeneralWithAutoComplete
        dialogTitle={intl.formatMessage({ id: "transfer.chat" })}
        dialogSubTitle={intl.formatMessage({ id: "transfer.chat.info" })}
        open={DialogTransferChatState}
        openOrCloseModalFunction={openOrCloseTransferChatModal}
        handleConfirmation={handleTransferChatConfirmation}
        options={techList}
        changeValues={changeValues}
        autocompleteStateName="techId"
        autocompleteLabel="Técnicos"

      />
      <DialogGeneralWithCheckbox
        dialogTitle={intl.formatMessage({ id: "end.chat.confirmation" })}
        dialogSubTitle={intl.formatMessage({ id: "end.chat.confirmation.info" })}
        open={DialogEndChatState}
        openOrCloseModalFunction={openOrCloseEndChatModal}
        handleConfirmation={handleEndChatConfirmation}
        chechBoxLabel={intl.formatMessage({ id: "create.called.for.chat" })}
        checkBoxStateName="createCalledToChat"
        checkBoxSelected={createCalledToChat}
        checkBoxDisabled={automaticallyCreateCalledAfterEndingChat}
        chatClosingStatus={chatClosingStatus}
        changeValues={changeValues}
        autocompleteStateName="chatClosingStatusId"
        autocompleteLabel="Status de finalização"
      />

      <DialogWithRichTextAndAttachment
        dialogTitle="Adicionar Nota"
        dialogSubTitle="Adicione abaixo sua nota e se necessário, anexos"
        open={DialogAddNoteState}
        openOrCloseModalFunction={openOrCloseDialogAddNoteModal}
        handleConfirmation={handleAddnoteConfirmation}
        nameDescription="noteDescription"
        handleChangeEditor={handleChangeEditor}
        value={noteDescription}
        classes={props.classes}
        files={files}
        validateAtachments={validateAtachments}
        removeItem={removeItem}
      />

      {loading && (
        <Overlay>
          <CircularProgress color='secondary' />
        </Overlay>
      )}

      <CustomizedSnackbars
        variant={notificationVariant}
        message={notificationMessage}
        isOpen={openNotification}
        toClose={closeNotification} />
    </main>
  )
}

ToTalkOmniChannel.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  chats: state.chats,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ToTalkOmniChannel))));
//ToTalkOmniChannel.contextType = AbilityContext;
