import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import {  Grid } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormattedMessage,injectIntl } from 'react-intl';

import SimpleAutoComplete from '../auto-complete/autocomplete'
import { PapiroConsole } from '../../utils/papiroConsole';



function DialogGeneralSm(props) {

  const intl = props.intl;

  

  return (
    <div>
      <Dialog
        
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {props.dialogSubTitle}
          </DialogContentText>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.openOrCloseModalFunction()} color="primary" >
            {intl.formatMessage({id:"cancel"})}
          </Button>
          <Button onClick={() => { props.handleConfirmation() }} variant="contained" color="primary" autoFocus>
            {intl.formatMessage({id:"confirm"})}
          </Button>
        </DialogActions>
      </Dialog>
      
    </div>
  );
}

export default injectIntl(DialogGeneralSm);