import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Grid, TextField } from '@mui/material';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl'
import Typography from '@mui/material/Typography'
import PropTypes from "prop-types"
import { withStyles } from "@mui/styles"
import Api from '../../../services/api'
import * as calledsActions from '../../../redux/actions/calleds'
import SimpleAutoComplete from '../../../components/auto-complete/autocomplete'
import { Styles } from "../../../styles/material-styles"
import { Overlay } from '../../../styles/global'
import CustomizedSnackbars from '../../../components/material-snackbars'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Tab as RTab, Tabs as RTabs, TabList as RTabList, TabPanel as RTabPanel } from 'react-tabs'
import { getAccompanimentsToFinish } from '../../../services/called.service'
import Dropzone from 'react-dropzone';
import { DeleteForever } from '@mui/icons-material';
import moment from 'moment';
import 'react-quill/dist/quill.snow.css'; // ES6
import ReactQuill from 'react-quill'; // ES6
import SimpleAutoCompleteRichText from '../../../components/auto-complete/autocomplete-rich-text';
import { PapiroConsole } from '../../../utils/papiroConsole';
import AttachFileIcon from '@mui/icons-material/AttachFile'; 

const ActionStep = props => {

  PapiroConsole.log("=== ActionStep ===")
  PapiroConsole.log(props)

  const defaultSelectedItem = { id: 0, name: '' }
  const intl = props.intl
  const classes = props.classes
  const called = props.called
  let [files, setFiles] = useState(props.files)
  let [openNotification, setOpenNotification] = useState(false)
  let [notificationVariant, setNotificationVariant] = useState("error")
  let [notificationMessage, setNotificationMessage] = useState("")
  let [loading, setLoading] = useState(false)
  let [finishaccompanimentid, setFinishaccompanimentid] = useState(props.actionFinishId)
  let [description, setDescription] = useState(props.actionDescription)
  let [privacy, setPrivacy] = useState("false")
  let [finishAccompanimentList, setFinishAccompanimentList] = useState([])
  let [rTabIndex, setRTabIndex] = useState(0)
  let [defaultAction, setDefaultAction] = useState(defaultSelectedItem)
  const [updateRadio, setUpdateRadio] = useState(true)

  const getTechnicalPublicAccompaniment = async _ => {
    setLoading(true)
    let result = await getAccompanimentsToFinish(called.id, called.requestingUser.id)
    if (result && result.data && result.data.data) {
      let finishAccompaniments = result.data.data.map(item => {

        let body = item.description
        let temp = document.createElement("div")
        temp.innerHTML = body
        let sanitized = temp.textContent || temp.innerText

        return {
          ...item,
          name: sanitized,
          Name: item.description
        }
      })
      PapiroConsole.log("=== getTechnicalPublicAccompaniment ===")
      PapiroConsole.log(finishAccompaniments)
      setFinishAccompanimentList(finishAccompaniments)
      setLoading(false)
    }else {
      setLoading(false)
      setOpenNotification(true)
      setNotificationVariant("error")
      setNotificationMessage(result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
    }
  }

  const saveAction = async e => {

    e.preventDefault()
    PapiroConsole.log("=== Action Step -> saveAction ===")
    const { userSession } = props

    setLoading(true)

    let isEmpty = false
    if (description != null && description.length > 0) {
      let temp = document.createElement("div")
      temp.innerHTML = description
      let content = temp.textContent || temp.innerText
      if(content != null && content.length == 0)
        isEmpty = true
    } else
      isEmpty = true

    PapiroConsole.log(isEmpty)
    PapiroConsole.log(description)
    PapiroConsole.log(finishaccompanimentid)
    if((isEmpty || (description && description.length == 0)) && finishaccompanimentid == null) {
      PapiroConsole.log("=== Caiu -> 5 ===")
      setLoading(false)
      setOpenNotification(true)
      setNotificationVariant("error")
      setNotificationMessage(intl.formatMessage({id:"called.accompaniment.notification.missing.action.existing"}))
      return
    }

    if (props.calledProps.organization.organizationsettings.requireattachedimage) {
      if (files.length === 0) {
        setLoading(false)
        setOpenNotification(true)
        setNotificationVariant("error")
        setNotificationMessage(intl.formatMessage({ id: "files.required" }))
        return
      }
    }
    
    if(defaultAction){
      if(defaultAction.id){
         props.setDefaultActionAccompaniment(defaultAction)
      }
    }
    
    if(description.length > 0) {

      setLoading(false)
      setOpenNotification(true)
      setNotificationVariant("success")
      setNotificationMessage(intl.formatMessage({id:"calledfinish.actionstep.action.inserted"}))

      
      props.setFinishStep(2)
      props.setMessage("success", intl.formatMessage({id:"calledfinish.actionstep.action.inserted"}), true)
      props.setActionDescription(description)
   
     
      if(props.canInformCalledClosingStatus) {
        props.setNavValue("status")
        props.nextStep()
      } else if(props.CalledOrganizationChecklistList && props.CalledOrganizationChecklistList.length > 0 && !props.isClient) {
        props.setNavValue("check")
        props.nextStep()
      } else if (props.caninformutilizedmaterialsincalledfinish) {
        props.setNavValue("material")
        props.nextStep()
      } else
        props.handleSubmit()

      
    }else if(finishaccompanimentid != null) {
     
      props.setFinishStep(2)
      props.setMessage("success", intl.formatMessage({id:"calledfinish.actionstep.action.selected"}), true)
      props.setFinishAccompanimentId(finishaccompanimentid)
      if(props.canInformCalledClosingStatus) {
        props.setNavValue("status")
        props.nextStep()
      } else if(props.CalledOrganizationChecklistList && props.CalledOrganizationChecklistList.length > 0 && !props.isClient) {
        props.setNavValue("check")
        props.nextStep()
      } else if (props.caninformutilizedmaterialsincalledfinish) {
        props.setNavValue("material")
        props.nextStep()
      } else {
        props.handleSubmit()
      }
      
      setLoading(false)
      setOpenNotification(true)
      setNotificationVariant("success")
      setNotificationMessage(intl.formatMessage({id:"calledfinish.actionstep.action.selected"}))  
    }



    
  }

  const changeNextStepText = _ => {
    if(props.canInformCalledClosingStatus || (props.CalledOrganizationChecklistList && props.CalledOrganizationChecklistList.length > 0 && !props.isClient) || props.caninformutilizedmaterialsincalledfinish) {
      return intl.formatMessage({id:"next"})
    } else {
      return intl.formatMessage({id:"end.called"})
    }
  }

  const closeNotification = _ => {
    setOpenNotification(false)
  }

  const addFileItem = files => {
    setFiles(files)
    props.addFileItem(files)
  }

  const removeItem = file => {
    let array = [...files];
    let index = array.indexOf(file);
    if (index !== -1) {
        array.splice(index, 1);
        setFiles(array)
        addFileItem(array)
    }
  }

  const previousStep = _ => {
    props.setNavValue("sla")
    props.previousStep()
  }

  const handleChangeEditorAction = value => {
    setDescription(value)
	}

  const reactQuillModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  }

  const reactQuillFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ]

  useEffect(() => {
    if(props.actionDescription.trim() != ""){
   
      setRTabIndex(0)
    }
    if(props.actionFinishId && props.actionFinishId.id){
     
      setRTabIndex(1)
    }
  }, [])

  return (
    <form name='ActionForm' onSubmit={saveAction} encType='multipart/form-data' className={classes.form} >
      <RTabs selectedIndex={rTabIndex} onSelect={ index => setRTabIndex(index) } >
        <RTabList style={{ borderColor: '#BB8FCE' }}>
          <RTab onClick={() => {
            setFinishaccompanimentid(null)
            setDefaultAction(defaultSelectedItem)
          }}>{intl.formatMessage({id:"action.new"})}</RTab>
          <RTab onClick={() => {
            setDefaultAction(defaultSelectedItem)
            getTechnicalPublicAccompaniment()
            setPrivacy("false")
          }}>{intl.formatMessage({id:"action.existing"})}</RTab>
          <RTab onClick={() => {
            setFinishaccompanimentid(null)
            getTechnicalPublicAccompaniment()
            setPrivacy("false")
          }}>{intl.formatMessage({id:"default.action"})}</RTab>
        </RTabList>
        <RTabPanel style={{ padding: 10 }}>
          <Grid container spacing={0} style={{ marginTop: 15 }}>
            <Grid item xs={12}>
              <Typography>
                <FormattedMessage id="action" />
              </Typography>
              <ReactQuill
                id="action"
                name="action"
                modules={reactQuillModules}
                formats={reactQuillFormats}
                value={description}
                style={{ height: 200, width: '65vw' }}
                fullWidth
                required
                InputLabelProps={{ shrink: description && description.length > 0 ? true : false }}
                variant="outlined"
                id="outlined-multiline-static"
                onChange={handleChangeEditorAction} />
            </Grid>
          </Grid>
          <Grid container spacing={0} style={{ marginTop: 25 }}>
            <section style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}>
              <div className={classes.dropzoneArea} style={{minHeight:'70px',marginTop:'5px '}} >
                <Dropzone  onDrop={acceptedFiles => { addFileItem([ ...files, ...acceptedFiles ]) }}>
                  {({ getRootProps, getInputProps, acceptedFiles }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <AttachFileIcon  />
                      <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                          <FormattedMessage  id="drag.and.drop.files" />
                      </Typography>
                    </div>
                  )}
                </Dropzone>
                <div style={{ maxHeight: 100, overflowY: 'scroll'}}>
                  {files.map(file => {
                    return (
                      <div className={classes.dropzoneItem} key={file.name}>
                        {file.name}
                        <DeleteForever className={classes.deleteItem} onClick={() => { removeItem(file) }} />
                      </div>
                    )
                  })}
                </div>
              </div>
            </section>
          </Grid>
        </RTabPanel>
        <RTabPanel style={{ padding: 10 }}>
          <Grid container spacing={0} style={{ marginTop: 15 }}>
            <Grid item xs={12}>
              <SimpleAutoComplete
                label={intl.formatMessage({ id: "select.action" })}
                options={finishAccompanimentList}
                stateName='finishaccompanimentid'
                changeSelect={(stateName, value) => {
                  PapiroConsole.log("=== finishaccompanimentid ===")
                  PapiroConsole.log(stateName)
                  PapiroConsole.log(value)
                  setFinishaccompanimentid(value)
                }}
                selected={finishaccompanimentid}
                htmlOptions={true} />
            </Grid>
          </Grid>
        </RTabPanel>
        <RTabPanel style={{ padding: 10 }}>
          <Grid container spacing={0} style={{ marginTop: 15 }}>
            <Grid item xs={12} sm={12}>
              <SimpleAutoComplete
                label={intl.formatMessage({ id: "action" })}
                options={props.defaultActionList}
                stateName='defaultActionId'
                changeSelect={(stateName, value) => {
                  setDefaultAction(value)
                  setDescription(value.text)
                }}
                selected={defaultAction} />
            </Grid>
          </Grid>
        </RTabPanel>
      </RTabs>
      <Grid container spacing={2} justifyContent='space-between'>
        <Grid item xs={6} sm={6} onClick={_ => setUpdateRadio(!updateRadio)}>
            {props.actionRadio()}
        </Grid>
        <Grid item container xs={6} sm={6} justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
          <Grid item xs={6} sm={6}>
          {props.previous ?
            (
              <Button
                variant='contained'
                type="button"
                onClick={previousStep}
                fullWidth >
                <FormattedMessage id="previous" />
              </Button>
              ) : (
              <Button
                variant='contained'
                type="button"
                onClick={props.closeModal}
                fullWidth >
                <FormattedMessage id="cancel" />
              </Button>
              )}
          </Grid>
          <Grid item xs={6} sm={6}>
            <Button
              type='button'
              variant='contained'
              color='primary'
              onClick={saveAction}
              fullWidth >
              { changeNextStepText() }
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <CustomizedSnackbars
        variant={notificationVariant}
        message={notificationMessage}
        isOpen={openNotification}
        toClose={closeNotification} />

      {loading && (
        <Overlay>
          <CircularProgress color="secondary" />
        </Overlay>
      )}
    </form>
  )
}

ActionStep.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userSession: state.userSession,
  calleds: state.calleds,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(calledsActions, dispatch);

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ActionStep))));