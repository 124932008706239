import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid, Dialog } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import Api from '../../services/api';
import { AbilityContext } from '../../config/ability-context'
import * as componentActions from '../../redux/actions/addresses';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import SimpleAutoCompleteMultiple from '../../components/auto-complete/autocomplete-multiple'
import * as Service from '../../services/originrequest.service'
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import { PapiroConsole } from '../../utils/papiroConsole';
import { Avatar, Typography } from '@mui/material';
import { PersonAdd, QueuePlayNext } from '@mui/icons-material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { blue } from '@mui/material/colors';

class AssociateNewUsersContent extends Component {

    constructor(props) {
        super(props);

        this.typingTimeout = null;
        this.emptyArray = null;

        let fromProps = ""
        if (this.props && this.props.from && this.props.from.length > 0 && this.props.from == 'orgEdit')
            fromProps = this.props.from

        const intl = this.props.intl;

        let userOptions = [
            { name: intl.formatMessage({ id: "all.clients" }), id: "1" },

        ];

        const { userSession, organizations } = this.props;

        PapiroConsole.log(organizations)
        PapiroConsole.log("organizations")
        this.initialState = {
            userOptionId: { id: 0, name: '' },
            originRequestId: [],
        };
        this.state = {
            item: this.initialState,
            organizations: organizations,
            organizationId: organizations.organization ? organizations.organization.id : 0,
            usersAssociated : "",
            loading: false,
            userOptionsList: userOptions,
            originRequestList: [],
            openNotification: false,
            notificationVariant: 'error',
            dialogState: false,
            dialogUsersAssociatedState : false,
            notificationMessage: '',
        };


        this.changeValues = this.changeValues.bind(this);

        this.closeNotification = this.closeNotification.bind(this);

        this.getOriginRequests = this.getOriginRequests.bind(this);

        this.openOrCloseDialog = this.openOrCloseDialog.bind(this);

        this.openOrCloseDialogUsersAssociated = this.openOrCloseDialogUsersAssociated.bind(this);

        

        

        this.checkValidationsToOpenDialog = this.checkValidationsToOpenDialog.bind(this);

    }



    componentWillReceiveProps(nextProps) {
        if (nextProps.location && nextProps.location.state === "reload") {
            this.componentDidMount();
        }

    }

    async componentDidMount() {

        this.getOriginRequests()

    }
    changeValues(stateName, value, text = '') {
        this.setState({ loading: true });

        this.setState(prevState => ({
            item: {
                ...prevState.item,
                [stateName]: value
            }
        }));

        this.setState({ loading: false });
    };
    handleSubmit = async e => {
        e.preventDefault();
        this.setState({ loading: true });
        var data = new FormData();



        for (let key in this.state.item) {

            if (key == "originRequestId") {
                var originRequestIdsList = []
                this.state.item.originRequestId.forEach(item => {
                    originRequestIdsList.push(item.id)
                })
                let originRequestIds = originRequestIdsList.join(',');

                data.append('originRequestIds', originRequestIds);

            }
            /*else if(key == "userOptionId"){
                data.append('userOptionId', this.state.item[key.id]);

            }*/
            else {
                data.append(key, this.state.item[key].id);
            }
        }
        data.append("organizationId", this.state.organizationId);

        let config = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        };


        Api.post('/organizationusers', data, config)
            .then(result => {
                if (result.data.success) {

                            this.setState({
                                loading: false,
                                openNotification: true,
                                notificationVariant: "success",
                                notificationMessage: this.props.intl.formatMessage({ id: "add.success" }),
                                usersAssociated : result.data.data ,
                                item: this.initialState,
                            });
                            

                            this.openOrCloseDialogUsersAssociated()
                            this.openOrCloseDialog()

                        
                    }
                    /*else {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "info",
                            notificationMessage: this.props.intl.formatMessage({ id: "no.customers.to associate" }),
                            
                        });
                        this.openOrCloseDialog()

                    }*/


                
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

                    });
                    Api.kickoff(result);
                }

            })
            .catch(err => {
                if (err.response && err.response.data && err.response.data.errors) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'error',
                        notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }) + err.response.data
                    });
                }
                Api.kickoff(err);
            });

        
    };

    async getOriginRequests() {
        this.setState({ loading: true });
        var result = await Service.getAll();
        if (result.success) {
            PapiroConsole.log(result.data)
            PapiroConsole.log("result.data")
            if (result.data) {

                let filtersIds = [2, 4, 9];

                //let filterArray = result.data.filter(item => filtersIds.includes(item.id));
                
                let filterArray = result.data.filter(item => item.onlyinternuse == true);

                
                this.setState({ originRequestList: filterArray });

            }





        } else {
            this.setState({
                openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }

        this.setState({ loading: false });
    };

    

    openOrCloseDialog = (e) => {
        PapiroConsole.log(e)
        if (e != null) {
            e.preventDefault()
        }
        this.setState({ dialogState: !this.state.dialogState })
    }

    openOrCloseDialogUsersAssociated = (e) => {
        PapiroConsole.log(e)
        if (e != null) {
            e.preventDefault()
        }
        this.setState({ dialogUsersAssociatedState: !this.state.dialogUsersAssociatedState })
    }

    

    checkValidationsToOpenDialog = () => {
        if (this.state.item.userOptionId == 0 || this.state.item.userOptionId == null || (this.state.item.userOptionId && this.state.item.userOptionId.id == 0)) {
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: "É necessário preencher o campo de usuários"
            });
        }
        else if (!this.state.item.originRequestId || this.state.item.originRequestId.length == 0) {
            this.setState({
                loading: false, openNotification: true, notificationVariant: "error",
                notificationMessage: "É necessário preencher o campo de origem"
            });
        }
        else {
            this.openOrCloseDialog()
        }
    }


    closeNotification() {
        this.setState({ openNotification: false });
    }
    render() {
        const { classes, headerMenu } = this.props;
        const intl = this.props.intl;
        PapiroConsole.log(classes)
        PapiroConsole.log("this.state.item.userOptionId")
        PapiroConsole.log(this.state.item.userOptionId)

        return (


            <div className={classes.paper} style={{ marginTop: 0 }} >
                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={this.state.dialogUsersAssociatedState}
                    onClose={this.openOrCloseDialogUsersAssociated}
                    aria-labelledby="association.of.users" >

                    <DialogTitle id="association.of.users.success">

                        <FormattedMessage id="association.of.users.success" />
                    </DialogTitle>
                    <DialogContent >
                        <DialogContentText  >
                            <div>
                                {this.state.usersAssociated} 
                            </div>

                        </DialogContentText>


                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.openOrCloseDialogUsersAssociated} color="secondary">
                            {intl.formatMessage({ id: "close" })}
                        </Button>
                        
                    </DialogActions>
                </Dialog>
                <Dialog
                    fullWidth={true}
                    maxWidth={"xs"}
                    open={this.state.dialogState}
                    onClose={this.openOrCloseDialog}
                    aria-labelledby="confirm.association.of.users" >

                    <DialogTitle id="confirm.association.of.users">

                        <FormattedMessage id="confirm.association.of.users" />
                    </DialogTitle>
                    <DialogContent >
                        <DialogContentText  >
                            <div>
                                {intl.formatMessage({ id: "confirm.association.of.users.info" })}
                            </div>

                        </DialogContentText>


                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.openOrCloseDialog} color="secondary">
                            {intl.formatMessage({ id: "cancel" })}
                        </Button>
                        <Button variant='contained' color='primary' onClick={this.handleSubmit} type="submit">
                            {intl.formatMessage({ id: "confirm" })}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Container component='main' maxWidth='lg' >
                    <div className={classes.paper} style={{ marginTop: 0 }}>
                        <Avatar  sx={{ bgcolor: blue[500] }} className={classes.avatar}> 
                            <PersonAdd />
                        </Avatar>
                        <Typography component='h1' variant='h5'>
                            <FormattedMessage id="associate.new.users" />
                        </Typography>

                        <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                            <Grid container spacing={2}>

                                <Grid item xs={12} sm={12}>
                                    <SimpleAutoComplete label={intl.formatMessage({ id: "menu.users" })} options={this.state.userOptionsList} stateName='userOptionId' changeSelect={this.changeValues}  selected={this.state.item.userOptionId} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <SimpleAutoCompleteMultiple label={intl.formatMessage({ id: "origin.request" })} options={this.state.originRequestList} stateName='originRequestId' changeSelect={this.changeValues}  selected={this.state.item.originRequestId} />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>

                                    <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                                        <Grid item xs={12} sm={4}    >
                                            <Button
                                                //type='submit'
                                                fullWidth
                                                variant='contained'
                                                color='primary'
                                                //className={classes.submit}
                                                onClick={this.checkValidationsToOpenDialog}
                                            >
                                                {<FormattedMessage id="save" />}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12}  container justifyContent="flex-end">
                                    <Typography display="block" gutterBottom variant='caption'>
                                    {<FormattedMessage id="not.recomended.execute.now" />}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </form>


                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />



                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}


                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </div>

                    {this.state.loading && (
                        <Overlay>
                            <CircularProgress color='secondary' />
                        </Overlay>
                    )}


                    <CustomizedSnackbars
                        variant={this.state.notificationVariant}
                        message={this.state.notificationMessage}
                        isOpen={this.state.openNotification}
                        toClose={this.closeNotification}
                    />



                </Container>


            </div>

        );
    }
}
AssociateNewUsersContent.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    organizations: state.organizations,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(AssociateNewUsersContent))));
AssociateNewUsersContent.contextType = AbilityContext;