import React, { Component } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material'
import { QueuePlayNext, DeleteForever } from '@mui/icons-material'
import { withStyles } from '@mui/styles'
import { Styles } from '../../styles/material-styles'
import { Overlay } from '../../styles/global'
import Api from '../../services/api'
import CustomizedSnackbars from '../../components/material-snackbars'
import Dropzone from 'react-dropzone'
import HeaderMenu from '../../components/header-menu'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import SimpleSelect from '../../components/select/simple'
import SimpleCheckBox from '../../components/checkbox/check'
import SimpleAutoComplete from '../../components/auto-complete/autocomplete'
import SimpleDatePicker from '../../components/date-pickers/inline'
import * as Service from '../../services/defaultaction.service'
import { parseJSON } from 'date-fns'
import Footer from '../../components/footer'
import { browserHistory } from '../../helpers/history'
import { getOrg } from "../../services/organization.service";
import { FormattedMessage, injectIntl } from 'react-intl'
import Title from '../../components/title-name'
import Tooltip from '@mui/material/Tooltip'
import HelpIcon from '@mui/icons-material/Help'
import 'react-quill/dist/quill.snow.css'; // ES6
import DialogConfirmation from '../../components/dialog-confirmation'
import ReactQuill from 'react-quill'; // ES6
import { DataGrid, GridToolbarFilterButton } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import SimpleAutoCompleteMultiple from "../../components/auto-complete/autocomplete-multiple-create";
import { PapiroConsole } from '../../utils/papiroConsole'
import DialogAddMultiple from '../../components/dialog-add-multiple'


const NoDataRows = _ => {
  return (
    <div className="MuiDataGrid-window" style={{ display: 'flex', justifyContent: 'center', top: 56, overflowY: 'auto' }} >Não há registros a serem exibidos</div>
  )
}

class DefaultActionsRegistration extends Component {

  constructor(props) {
    super(props)
    const { userSession, organizations } = this.props

    this.globalAdm = false
    this.isClient = true

    if (userSession.user.userrole == null) {
      Api.logoff()
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype !== 3) {
        this.isClient = false
      }
      if (item.role.roletype === 0) {
        this.globalAdm = true
      }
    })

    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      action: '',
      active: false,
      title: '',
    }

    this.state = {
      item: this.initialState,
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      OrgList: [],
      OrgListSelect: [],
      OrgListIds: "",
      OrgListForDelete: "",
      OrgListForSelect: [],
      openOrgInsertModal: false,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleChangeEditorAction = this.handleChangeEditorAction.bind(this)
    this.closeNotification = this.closeNotification.bind(this)
    this.changeValues = this.changeValues.bind(this)
    this.closeOrgModal = this.closeOrgModal.bind(this)

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount()
    }
  }

  componentDidMount() {

    this.getOrganizations();
  
  }

  openOrgInsertModal = async () => {
    this.setState({
      openOrgInsertModal: !this.state.openOrgInsertModal
    })
  }

  saveOrgInsertModal = async () => {

    const intl = this.props.intl;

    let organizationIds = ""
    
    if (this.state.item.OrganizationId && this.state.item.OrganizationId.length > 0) {
      this.state.item.OrganizationId.map(item => {
        if (item && item.id && item.id > 0)
          organizationIds = `${item.id},${organizationIds}`
      })

      this.setState({
        loading: false,
        OrgList: this.state.item.OrganizationId,
        OrgListIds: organizationIds,
        openNotification: true,
        notificationVariant: 'success',
        notificationMessage: intl.formatMessage({ id: "add.success" }),
        openOrgInsertModal: !this.state.openOrgInsertModal,
      })
    }
    else{
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage:intl.formatMessage({ id: "need.to.add.an.organization" }), 
      })
    }

    
  }

  setOrgDelete = async (org) => {
    this.setState({
      OrgListForDelete: org + "," + this.state.OrgListForDelete
    })
  }

  closeOrgModal = () => this.setState({ openOrgInsertModal: false })


  orgDeleteModal = async () => {
    const intl = this.props.intl;
    if (this.state.OrgListForDelete && this.state.OrgListForDelete.length > 0) {

      this.setState({ loading: true })

      var orgList = [];
      let organizationIds = "";
      var orgDeleteList = this.state.OrgListForDelete.split(",");

      orgDeleteList.map(organizationDelete => {
        if (organizationDelete != "") {
          let returnok = this.state.OrgList.filter(el => el.id != organizationDelete)
          returnok.map(item => {
            if (orgList.filter(el => el.id != item.id)) {
              orgList.push(item);
              organizationIds = `${item.id},${organizationIds}`
            }
          })
        }
      })

      this.setState({
        loading: false,
        OrgList: orgList,
        OrgListIds: organizationIds,
        openNotification: true,
        notificationVariant: 'success',
        notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
      })

    } else {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "organization.material.registration.select.organization" })
      })
    }
  }

  handleInputChange = e => {
    const name = e.target.name
    const value = e.target.value

    

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }))
  }

  handleChangeEditorAction = (value) => {
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        action: value
      }
    }))
  }

  handleSubmit = async e => {
    e.preventDefault()

    this.setState({ loading: true })

    let data = new FormData()

    if (this.state.item.action.trim() === "") {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({ id: "erro.action.rejection" })
      })
    } else {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
        }
      }))


      for (let key in this.state.item) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date') > -1 || key.toString().toLowerCase().indexOf('eventdate') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else if (key.toString().toLowerCase().indexOf('createduser') > -1) {
          data.append(key, this.state.item[key]);
        } else if (key.toString().toLowerCase().indexOf('createdat') > -1) {
          data.append(key, this.state.item[key]);
        } else if (key.toString().toLowerCase().indexOf('action') > -1) {
          data.append("text", this.state.item[key]);
        } else if (key.toString().toLowerCase().indexOf('active') > -1) {
          data.append(key, this.state.item[key]);
        }
        else if (key.toString().toLowerCase().indexOf('title') > -1) {
          data.append(key, this.state.item[key]);
        }
      }

      let idsStringOrg = "";
      if (this.state.OrgList.length > 0) {
        this.state.OrgList.forEach(
          (organization) => (idsStringOrg += organization.id + ",")
        );
      }

      var newStr = idsStringOrg.slice(0, -1);
      data.append("Organizations", newStr);

      var result = await Service.addItem(data)
      if (result && result.success) {
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'success',
          notificationMessage: intl.formatMessage({ id: "add.success" }),
          item: this.initialState,
          OrgList: [],
          OrgListSelect: [],
          OrgListIds: "",
          OrgListForDelete: "",
          
        })

        
      } else {
        const intl = this.props.intl
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result &&  result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(result)
      }
    }
  }

  closeNotification() {
    this.setState({ openNotification: false })
  }

  async getOrganizations() {
    this.setState({ loading: true });
    var result = await getOrg();
    if (result.success) {
      this.setState({ loading: false, OrgListForSelect: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }
  }

  changeValues(stateName, value, text = '') {

   if (stateName == 'selectOrganizations') {
      if (value == true) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            Organizations: this.state.OrgListForSelect,
            OrganizationId: this.state.OrgListForSelect,
            ["OrganizationText"]: text,
          },
          
        }));
        
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            Organizations: [],
            OrganizationId: [],
            ["OrganizationText"]: text,
           
          },
          DepartamentList: [],
          LocationList: []
        }));
        
      }
    }
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }))
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

    PapiroConsole.log(this.state.OrgListForSelect)
    PapiroConsole.log("this.state.OrgListForSelect")
    PapiroConsole.log(this.state.OrgListForSelect.length)
    PapiroConsole.log("this.state.OrgListForSelect.length")

   

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}>
          <div className={classes.drawerHeader} />
          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="add.default.action" />}
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit}>
                <Grid container spacing={2}>


                  <Grid item xs={12} sm={12}>
                    <TextField inputProps={{ maxLength: 255 }} fullWidth label={intl.formatMessage({ id: "title" })} autoComplete='fname' variant='outlined' name='title' onChange={this.handleInputChange} value={item.title} required />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>{intl.formatMessage({ id: "select.organizations" })}*</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2} xs={12} sm={12} >

                          <Grid xs={12} sm={12} style={{ margin: '5px', padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }} >
                            <Grid xs={12} sm={4} >

                            </Grid>
                            <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                              <DialogConfirmation
                                msgButton={intl.formatMessage({ id: "organization.material.registration.remove.organization" })}
                                msg={intl.formatMessage({ id: "user.client.registration.confirm.remove.organization.from.user" })}
                                msgDisagree={intl.formatMessage({ id: "cancel" })}
                                msgAgree={intl.formatMessage({ id: "yes" })}
                                handleConfirmation={e => this.orgDeleteModal()}
                                classes={classes}
                                icon={<DeleteIcon />}
                              />
                            </Grid>
                            <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                              <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                className={classes.button}
                                startIcon={<AddIcon />}
                                onClick={e => this.openOrgInsertModal()} >
                                {intl.formatMessage({ id: "organization.material.registration.add.organization" })}
                              </Button>
                            </Grid>
                          </Grid>

                          <Grid xs={12} sm={12}
                            style={{
                              marginLeft: 5,
                              marginBottom: 5,
                              border: '3px solid #eee'
                            }} >
                            <div style={{ height: 350 }}>
                              <DataGrid
                                style={{ fontSize: 14, color: '#000' }}
                                className="DataGridStyle"
                                components={{
                                  NoRowsOverlay: NoDataRows,
                                  Toolbar: GridToolbarFilterButton,
                                }}
                                rows={this.state.OrgList}
                                rowsPerPageOptions={[5]}
                                checkboxSelection
                                onSelectionModelChange={itm => {
                                  if (itm.length > 0) {
                                    this.setOrgDelete(itm);
                                  } else {
                                    this.setState({
                                      OrgListForDelete: [],
                                    })
                                  }
                                }}
                                columns={[
                                  { headerName: intl.formatMessage({ id: "menu.organization" }), field: 'name', width: 500 },
                                ]}
                                HeadersVisibility="None"
                              />
                            </div>
                          </Grid>
                        </Grid>

                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography>
                      <FormattedMessage id="action" />
                    </Typography>
                    <ReactQuill
                      id="action"
                      name="action"
                      value={item.action}
                      style={{ height: 200 }}
                      fullWidth
                      required
                      InputLabelProps={{ shrink: item.action && item.action.length > 0 ? true : false }}
                      variant="outlined"
                      id="outlined-multiline-static"
                      onChange={this.handleChangeEditorAction} />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ marginTop: 30 }}>
                    <SimpleCheckBox label={intl.formatMessage({ id: "active" })} name='active' stateName='active' changeSelect={this.changeValues} selected={item.active} />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={4}    >
                        <Button
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit} >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>
            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}
            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification} />

            <DialogAddMultiple
              title={intl.formatMessage({ id: "organization.material.registration.new.organizations" })}
              selected={this.state.item.OrganizationId}
              options={this.state.OrgListForSelect}
              checked={this.state.selectOrganizations}
              changeValues={this.changeValues}
              open={this.state.openOrgInsertModal}
              close={this.closeOrgModal}
              save={this.saveOrgInsertModal}
              intl={intl}
              checkboxLabel={intl.formatMessage({ id: "technical.team.edit.select.all.organizations" })}
              checkboxStateName='selectOrganizations'
              autoCompleteLabel={intl.formatMessage({ id: "organization" })}
              autoCompleteStateName="OrganizationId"
            />
            
          </Container>
        </main>
        <Footer />
      </div>
    )
  }
}

DefaultActionsRegistration.propTypes = { classes: PropTypes.object.isRequired };

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  organizations: state.organizations,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(DefaultActionsRegistration)));