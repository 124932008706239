import Api from './api';
export const getCalleds = async () => { 
  let data = [];
  await Api.get('/calleds')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export async function addAccompanimentToKnowledgeBase(accompanimentId) {
  try {
    const response = await Api.post(`/knowledgebases/byaccompaniment/${accompanimentId}`);
    return response.data;
  } catch(err) {
    return err;
  }
} 

export async function getKnowledgeBaseByOrganizaytionId(organizationId) {
  try {
    const response = await Api.get(`/knowledgebases/byorganizationid/${organizationId}`);
    return response.data;
  } catch(err) {
    return err;
  }
}

export async function getKnowledgeBaseByOrganizaytionIdSelectionModel(organizationId) {
  try {
    const response = await Api.get(`/knowledgebases/byorganizationid/${organizationId}/selectionmodel`);
    return response.data;
  } catch(err) {
    return err;
  }
}

export async function getDetailsById(id) {
  try {
    const response = await Api.get(`/knowledgebases/details/${id}`);
    return response.data;
  } catch(err) {
    return err;
  }
}

export async function deleteKnowledgeBaseByAccompanimentId(accompanimentId) {
  try {
    const response = await Api.delete(`/knowledgebases/accompaniment/${accompanimentId}`);
    return response.data;
  } catch(err) {
    return err;
  }
} 

export async function getKeyWordAndAttachmentsByKnowledgeBaseId(id) {
  try {
    const response = await Api.get(`/knowledgebases/keywordsandattachments/${id}`);
    return response.data;
  } catch(err) {
    return err;
  }
}