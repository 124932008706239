import * as React from 'react'
import { getAttachmentExtension, getAttachmentNameByURL } from '../../../../../../utils/regexUtils'
import { getFileType } from '../../../../../../utils/fileUtils'
import { PapiroConsole } from '../../../../../../utils/papiroConsole'
import Pdf from '../../../../../../components/pdf/pdf'
import VideoPlayer from '../../../../../../components/video-player'

function MessageAttachment({ src, title, type }) {
  const [link, setLink] = React.useState("")
  const [attachmentType, setAttachmentType] = React.useState("")
  const [typeAttach, setTypeAttach] = React.useState("")

  React.useEffect(() => {
    PapiroConsole.log("=== MessageAttachment ===")
    PapiroConsole.log(src)
    if (src && src.length > 0 && src != link) {
      let attachment = getAttachmentNameByURL(src)
      PapiroConsole.log(attachment)
      let attachmentExtension = getAttachmentExtension(attachment)
      PapiroConsole.log(attachmentExtension)
      let attachmentType = getFileType(attachmentExtension)
      PapiroConsole.log(attachmentType)
      setAttachmentType(attachmentType)
      setLink(src)
      setTypeAttach(type)
    }
  }, [src,type])

  PapiroConsole.log(link)
  PapiroConsole.log(attachmentType)
  //alert(typeAttach);
  if (link && link.length > 0) {
    if(typeAttach =="sticker"){
      return (<img
        src={link}
        alt={title} />)
      return (
      <picture>
        <source srcset={link} type="image/webp" />
      </picture>        
        )
    }
    else{
        if (attachmentType === "image")
          return (<img
            src={link}
            alt={title} />)
        else if (attachmentType === "video")
          return (<VideoPlayer videoUrl={link}  />)
        else if (attachmentType === "pdf")
          return (<Pdf url={link} />)
        else if (attachmentType === "presentation")
          return (<>
            </>)
        else if (attachmentType === "spreadsheet")
          return (<>
            </>)
        else if (attachmentType === "compressed")
          return (<>
            </>)
        else if (attachmentType === "text")
          return (<>
            </>)
        else if (attachmentType === "audio")
          return (<>
            </>)
        else if (attachmentType === "code")
          return (<>
            </>)
        else
          return (<>
            </>)
    }
  } else
    return (<>
      </>)
}

export default React.memo(MessageAttachment)