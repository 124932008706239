// AgentList.js

import React, { Component } from 'react';
import { Typography, Box, Divider, List, ListItem, ListItemAvatar, ListItemText, Avatar, Paper, Grid } from '@mui/material';
import SimpleSelectWithRoundedColor from '../../../2talk-chat/components/chat/simple-with-rounded-color';
import { PapiroConsole } from '../../../../utils/papiroConsole';
import { CircularProgress } from '@mui/material';
import { Overlay } from '../../../../styles/global';

class AgentList extends Component {

  changeValues = async (stateName, value, text = '') => {
    const { changeStatus } = this.props;
    changeStatus(value)

  }
  render() {



    const { statusOptions, userStatusId, enableUsers, loadingAgents } = this.props;


    PapiroConsole.log("loadingAgents")
    PapiroConsole.log(loadingAgents)

    return (
      <div style={{ padding: '20px' }}>
        <Box>
          <Grid container >
            <Grid item xs={10}>
              <Typography variant="h5" color="primary" style={{ marginBottom: '10px' }}>
                Acompanhamento de Agentes
              </Typography>
            </Grid>
            <Grid item xs={2} style={{ marginBottom: '10px' }}>
              <SimpleSelectWithRoundedColor
                options={statusOptions}
                stateName="status"
                changeSelect={this.changeValues}
                selected={userStatusId}

              />
            </Grid>
          </Grid>

          <Divider />
        </Box>
        {loadingAgents == false ?

          <List >
            {enableUsers.map((agent, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: "#3f51b4" }}>{agent.user ? agent.user.name[0] : ''}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={agent.user ? agent.user.name : ''}
                    secondary={
                      <React.Fragment>
                        <div
                          style={{
                            width: '12px',
                            height: '12px',
                            borderRadius: '50%',
                            backgroundColor: getStatusColor(agent.userstatus),
                            display: 'inline-block',
                            marginRight: '5px',
                          }}
                        />
                        {agent.userstatus ? agent.userstatus?.name : ''} - <span style={{ float: 'right' }}>{agent.chats ? agent.chats.length : ''} atendimentos</span>
                      </React.Fragment>
                    }
                  />
                </ListItem>

              </React.Fragment>
            ))}
          </List>

          :
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop:'20px'}}
            
          >
            <CircularProgress />
          </Box>


        }



      </div>
    );
  }
}

// Função para obter a cor do status
const getStatusColor = (status) => {
  if (status) {
    return status.colorhex;
  }
  else {
    return '#ccc'
  }

};

export default AgentList;
