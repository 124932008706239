import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';import SimpleSelect from '../../components/select/simple';
import { browserHistory } from '../../helpers/history';
import { withRouter } from 'react-router-dom';
import { FormattedMessage,injectIntl } from 'react-intl';
import SimpleHelp from '../../components/help';
import SimpleAutoCompleteMultiple from '../../components/auto-complete/autocomplete-multiple';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import * as Service from '../../services/organization.service';
import SimpleCheckBox from '../../components/checkbox/check';
import * as componentActions from '../../redux/actions/organizations';
import { addOrg } from '../../redux/actions/organization';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { PapiroConsole } from '../../utils/papiroConsole';

class OrganizationEditContent extends Component {

  constructor(props) {
    super(props);

      
            const { userSession,organizations } = this.props;
            //console.log(organizations)
            //console.log('edit.content');
            //console.log(props);
           

    this.globalAdm = false;
    this.isClient = true;
    
    
    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }

    if(!organizations || (organizations && !organizations.organization)) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/calleds`
    }

    const intl = this.props.intl;
    this.displayName = intl.formatMessage({id:"organization.data"});
    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

 
    this.initialState = {
      updatedUser: userSession.user.id,
      id: organizations.organization.id,
      GroupId:  organizations.organization.GroupId,
      GroupText: organizations.organization.GroupText,
      SegmentId:  organizations.organization.SegmentId,
      SegmentText: organizations.organization.SegmentText,
      name : organizations.organization.name, 
      phone: organizations.organization.phone,
      copyTemplate: false,
      active : organizations.organization.active,
      orgCopyId: 0,
      valCopy: '',
      organizationkey: organizations.organization.organizationkey,
     };         
    this.state = {
      organization: this.initialState,
      organizationName : null,
      organizationPhone: null,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      GroupList: [],
      SegmentList: [],
      OrgList: [],
      CopyList: []
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this)
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.location && nextProps.location.state === "reload"){
      this.componentDidMount();
    }
  }    

  async componentDidMount() {
    const intl = this.props.intl;
    await this.props.addOrg(this.props.organizations.organization.id);
    //console.log("esse é o id que eu quero ", this.props.org)
    this.getorganization(this.props.organizations.organization.id);
    this.getGroups();
    this.getSegments();
    this.getOrg();
    this.copyList();
  };
     
    copyList(){
      const intl = this.props.intl;
      let objValores = [
        {id: 1, name: intl.formatMessage({id:"menu.configs"})}, 
        {id: 2, name: intl.formatMessage({id:"slas"})}, 
        {id: 3, name: intl.formatMessage({id:"menu.categories"})},
        {id: 4, name: intl.formatMessage({id:"menu.organizationdepartaments"})},
        {id: 5, name: intl.formatMessage({id:"menu.organizationpatrimonies"})},
        {id: 6, name: intl.formatMessage({id:"menu.organizationmaterials"})},
        {id: 7, name: intl.formatMessage({id:"organization.custom.checklist"})},
        {id: 8, name: intl.formatMessage({id:"addresses"})},
        {id: 9, name: intl.formatMessage({id:"menu.holiday"})},
        {id: 10, name: intl.formatMessage({id:"organization.custom.fields"})},
        {id: 11, name: intl.formatMessage({id:"called.list.data.list.called.list.config"})},
        {id: 12, name: intl.formatMessage({id:"additional.called.cost.config"})},
        {id: 13, name: intl.formatMessage({id:"called.report.config"})},
      ]
      this.setState({ loading: false, CopyList: objValores });
    };
    async getOrg(){
      const intl = this.props.intl;
      this.setState({ loading: true });
      var result = await Service.getOrg();
      if (result.success) {
        this.setState({ loading: false, OrgList: result.data });
                      
      } else {
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);  
      }
    };
     async getGroups(){
      const intl = this.props.intl;
          this.setState({ loading: true });
          var result = await Service.getGroups();
            if (result.success) {
              this.setState({ loading: false, GroupList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);
            }
  };     async getSegments(){
    const intl = this.props.intl;
          this.setState({ loading: true });
          var result = await Service.getSegments();
            if (result.success) {
              this.setState({ loading: false, SegmentList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);
            }
  };

  async getorganization(id){
    this.setState({ loading: true });
  
    Api.get(`/organizations/${id}`)
    .then(result => {

     
      //this.setState({ organizationName: result.data.data.name, organizationPhone: result.data.data.phone  });
      this.setState(prevState => ({
        organization: {
          ...prevState.organization,
          ["organizationkey"] : result.data.data.organizationkey,
          ["name"]: result.data.data.name,
          ["phone"]: result.data.data.phone,
          ["SegmentId"]: result.data.data.segment && result.data.data.segment.id ? result.data.data.segment.id : 0,
          ["GroupId"] : result.data.data.group && result.data.data.group.id ? result.data.data.group.id : 0,
          ["active"] : result.data.data.active
        }
      }));

      

     
     })
     .catch(err => {
     
      Api.kickoff(err);
    });

;
    
    
        

    
};   

  handleChangePhone(e) {
    this.setState(prevState => ({
      organization: {
        ...prevState.organization,
        ['phone']: e
      }
    }));
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      organization: {
        ...prevState.organization,
        [name]: value
      }
    }));
    
    if (e.target.name === "phone") {
      this.setState((prevState) => ({
        organization: {
          ...prevState.organization,
          ["phone"]: this.mtel(value),
        },
      }));
    }
  };

  mtel(v) {
    v = v.replace(/\D/g, "");
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
    
    return v;
  }

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
          organization: {
            ...prevState.organization,
          }
        }));
      } else {
        this.setState(prevState => ({
          organization: {
            ...prevState.organization,
          }
        }));
      }

      //console.log(this.state.organization)


      for (let key in this.state.organization) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.organization[key]).format('YYYY/MM/DD HH:mm:ss'));
        } 
       
        
        else if(key.toString().toLowerCase().indexOf('valcopy') > -1){
          let list = this.state.organization[key];
          if(list != null && list != ""){
            
            let result = "";

            this.state.organization[key].map((item, key2) => {
              result = item.id+","+result
            });

            data.append("valCopy", result);

          }

        }else {
          data.append(key, this.state.organization[key]);
        }
      }

      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      //console.log('entrei aqui')
        Api.put('/organizations', data, config)

        .then(result => {
          //console.log('consegui modificar')
          if (result.data.success) {

            
            //console.log(result.data)
        const formattedList = result.data.data;
        PapiroConsole.log(formattedList)
        PapiroConsole.log("formattedList")
        formattedList.GroupId=  formattedList.group ? formattedList.group.id : '';
        formattedList.SegmentId=  formattedList.segment ? formattedList.segment.id : 0;
        formattedList.GroupText=  formattedList.group ? formattedList.group.name : '';
        formattedList.SegmentText=  formattedList.segment ? formattedList.segment.name : '';
        formattedList.Phone=  formattedList.phone ? formattedList.phone : '';
        formattedList.organizationkey=  formattedList.organizationkey ? formattedList.organizationkey : '';
        formattedList.active =  formattedList.active ? formattedList.active : false;
        
        const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({id:"edit.success"}),
              files: []
            });
        this.props.addCurrent(formattedList);
        this.componentDidMount();
 
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage:  result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({id:"process.error"})
            });

          }
        })
        .catch(err => {
          const intl = this.props.intl;
          if(err.response && err.response.data && err.response.data.errors){
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
         
        });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      organization: {
        ...prevState.organization,
        [stateName]: value
      }
    }));
        if(stateName === 'GroupId'){
          this.setState(prevState => ({
            organization: {
              ...prevState.organization,
              ['GroupText']: text,
            }
          }));
        }
        if(stateName === 'SegmentId'){
          this.setState(prevState => ({
            organization: {
              ...prevState.organization,
              ['SegmentText']: text,
            }
          }));
        }
        if(stateName === 'orgCopyId'){
          this.setState(prevState => ({
            organization: {
              ...prevState.organization,
              [stateName]: value.id,
            }
          }));
        }
 
    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

    render() {
    const { classes, headerMenu, organizations } = this.props;
    const { organization } = this.state;
    const intl = this.props.intl;
     return (


          <Container component='main' maxWidth='xl'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>

              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <SimpleSelect label={intl.formatMessage({id:"group"})} name='groupid'  options={this.state.GroupList} stateName='GroupId' changeSelect={this.changeValues} selected={organization.GroupId} required/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleSelect label={intl.formatMessage({id:"area.activity"})} name='segmentid'  options={this.state.SegmentList} stateName='SegmentId' changeSelect={this.changeValues} selected={organization.SegmentId} />
                    </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField inputProps={{ maxLength: 255 }} fullWidth label={intl.formatMessage({id:"name"})} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={organization.name} required />
                  </Grid>
                      <Grid item xs={12} sm={12}>
                        {/*<TextField  fullWidth label="Telefone" autoComplete='fname' variant='outlined' name='telefone' onChange={this.handleInputChange} value={item.Phone} required />*/}
                        {/*<TextField
                          
                          fullWidth
                          
                          label="Telefone"
                          autoComplete="fname"
                          variant="outlined"
                          name="phone"
                          onChange={this.handleInputChange}
                          value={organization.phone || ""}
                          inputProps={{ maxLength: 13, minLength:13 }}
                          required
                        />*/}
                        <PhoneInput
                          inputStyle={{
                            padding: '23.5px 14px 23.5px 58px',
                            width: '100%'
                          }}
                          style={{
                              marginTop: 16,
                              marginBottom: 8
                          }}
                          id="phone"
                          name="phone"
                          country={'br'}
                          value={organization.phone}
                          onChange={this.handleChangePhone}
                          InputLabel={this.props.intl.formatMessage({id:"phone"})}
                          specialLabel={this.props.intl.formatMessage({id:"phone"})}
                          inputProps={{
                            name: this.props.intl.formatMessage({id:"phone"})
                          }} />
                      </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField inputProps={{ maxLength: 255 }} fullWidth label={this.props.intl.formatMessage({id:"organization.edit.organization.key"})} autoComplete='fname' variant='outlined' name='organizationkey' onChange={this.handleInputChange} value={organization.organizationkey} />
                  </Grid>
                    <Grid item xs={11} sm={11}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organization.edit.copy.organization.config"})} name='copyTemplate' 
                        stateName='copyTemplate' changeSelect={this.changeValues} />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <SimpleHelp message={this.props.intl.formatMessage({id:"organization.edit.functionality.insert.values"})}/>
                    </Grid>

                    <Grid item xs={11} sm={11} s>
                    <SimpleCheckBox label={intl.formatMessage({id:"active.2"})} name='active' 
                      stateName='active' changeSelect={this.changeValues} selected={organization.active} />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <SimpleHelp message={intl.formatMessage({id:"organization.active.tooltip"})}/>
                    </Grid>

                {this.state.organization.copyTemplate && (
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete label={intl.formatMessage({id:"organization"})} options={this.state.OrgList} stateName='orgCopyId' changeSelect={this.changeValues}  />
                    </Grid>
                )}

                {this.state.organization.copyTemplate && (
                 
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoCompleteMultiple label={this.props.intl.formatMessage({id:"organization.edit.values"})} options={this.state.CopyList} stateName='valCopy' changeSelect={this.changeValues} selected={organization.valCopy} />
                    </Grid>
                  
                )}

                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                  <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                    <Grid item xs={12} sm={4}    >
                      <Button 
                        type='submit'
                        fullWidth
                        variant='contained'
                        color='primary'
                        className={classes.submit}
                      >
                        {<FormattedMessage id="save" />}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
    );
  }
}
OrganizationEditContent.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                    organizations: state.organizations,
                    org: state.organization.organization
                });
                const mapDispatchToProps = dispatch =>
                bindActionCreators({addOrg}, dispatch);
                    //OrganizationEditContent.displayName = 

            export default injectIntl(withRouter(connect(mapStateToProps,mapDispatchToProps)(withStyles(Styles)(OrganizationEditContent))));
