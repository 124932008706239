import React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { makeStyles } from '@mui/styles';
import { PapiroConsole } from '../../utils/papiroConsole';
// import { OptionsDialog } from "./optionsDialog";s

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export function ChatOptions({ openDialog, closeChat, disabled, isClient, intl, openOrCloseDialog, chatAlrealdyHasCalled, openOrCloseNewConversationDialog}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  //   const [openOptionsDialog, setOpenOptionsDialog] = React.useState(false);
  const anchorRef = React.useRef(null);

  //   const handleOpenDialog = (event) => {
  //       setOpenOptionsDialog(true)
  //       handleClose(event);
  //   }
  //   const handleCloseDialog = () => {
  //       setOpenOptionsDialog(false);
  //   }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    PapiroConsole.log("entrei aqui 5")
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      PapiroConsole.log("entrei aqui 6")
      return;
    }
    setOpen(false);
  };

  const handleCloseChat = (event) => {
    PapiroConsole.log("entrei aqui 1")
    closeChat();

   /* PapiroConsole.log("entrei aqui 4")
    handleClose(event);*/
    // handleCloseDialog();
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);


  const openDialogCreateConversation = (event) => {
    openDialog()
    // handleCloseDialog();
  };

  return (
    <div className={classes.root}>
      {/* <OptionsDialog 
        handleClose={ handleCloseDialog }
        open={ openOptionsDialog }
        closeChat={ handleCloseChat }
      /> */}
      <div style={{ display: "flex", alignItems: "center" }}>
        <MoreHorizIcon
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          style={{ height: "28px", width: "28px", cursor: "pointer",color:'black' }}
        />
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition style={{ zIndex: 10000 }}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    
                    {isClient == false &&
                      <MenuItem disabled={disabled} onClick={(e) => openOrCloseNewConversationDialog(e)}>{intl.formatMessage({id:"start.new.conversation"})}</MenuItem>
                    }
                    <MenuItem disabled={disabled} onClick={handleCloseChat}>Finalizar atendimento</MenuItem>
                    {isClient == true &&
                      <MenuItem disabled={disabled} onClick={openDialogCreateConversation}>{intl.formatMessage({id:"start.new.conversation"})}</MenuItem>
                    }
                    
                    {isClient == false && chatAlrealdyHasCalled == false &&
                      <MenuItem disabled={disabled} onClick={(e) => openOrCloseDialog(e)}>{intl.formatMessage({id:"create.called.new.by.conversation"})}</MenuItem>
                    }
                    {isClient == false && chatAlrealdyHasCalled == true &&
                      <MenuItem disabled={disabled} onClick={(e) => openOrCloseDialog(e)}>{intl.formatMessage({id:"update.called"})}</MenuItem>
                    }
                    
                    
                  </MenuList>
                  
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}