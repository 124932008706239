import { Card, CardContent, CardHeader, Divider, List, ListItem, ListItemText, } from '@mui/material'
import Rating from '@mui/material/Rating'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import RatingElement from '../../../../components/rating-element'


const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0
  },
  image: {
    height: 48,
    width: 48
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestProducts = props => {
  const { className, data, type, openCalled, openChat } = props
  const intl = props.intl
  const classes = useStyles()
  const products = data
 

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardHeader
        subtitle={`${products.length} in total`}
        title={intl.formatMessage({id:"last.feedbacks"})}
      />
      <Divider />
      <CardContent className={classes.content}>
        <ListItem>
          <span style={{ display: "flex", position: "relative", alignItems: "center" }}>
            <ListItemText primary={ "ID" } style={{ marginRight: "20px", minWidth: "36px" }} />
            <ListItemText style={{marginLeft:'125px'}} primary={<FormattedMessage id="assigned" />} />
           
          </span>
          <ListItemText
            primary={<FormattedMessage id="classification" />} 
            style={{ position: "absolute", left: "calc(100% - 120px)" }}
            />
        </ListItem>
        <Divider />
        {data.length != 0 && <List>
          {data.map((product, i) => (
            <ListItem
              divider={i < products.length - 2}
              key={product.id}
            >
              {
                (product.calledid === 0 && product.chatid > 0)
                ? (
                  <p style={{ marginRight: "35px", minWidth: "36px", width:'180px', cursor: 'pointer' }}>
                    <ListItemText onClick={ () => openChat(product.chatid) } secondary ='Conversa'>
                      <span style={{ color: "#0645AD", textDecoration: "underline" }}>{ product.chatid }</span>
                    </ListItemText>
                  </p>
                ) 
                : (
                  <p style={{ marginRight: "35px", minWidth: "36px", width:'180px', cursor: 'pointer' }} href="/">
                    <ListItemText onClick={ () => openCalled(product.calledid) } secondary = {product.calledcreation ? "Criado em: " + moment(product.calledcreation).format('DD/MM/YYYY HH:mm') : ''}>
                      <span style={{ color: "#0645AD", textDecoration: "underline" }}>{ product.calledid }</span>
                    </ListItemText>
                  </p>
                )
              }

              {!type && (
              <ListItemText
                primary={product.assignedusername ? product.assignedusername : ''}
                secondary = {product.createdat ?  "Avaliado em: " + moment(product.createdat).format('DD/MM/YYYY HH:mm') : ''}
              />
              )}

              {type && (
              <ListItemText
                primary={product.assignedusername ? product.assignedusername : ''}
                secondary = {product.createdat ?   moment(product.createdat).format('DD/MM/YYYY HH:mm') : ''}
              />
              )}

              <>
                {
                  (product.calledid === 0 && product.chatid > 0)
                  ? (
                    <Rating
                      name="rating"
                      value={product.noteassigned}
                      readOnly
                    />
                  )
                  : (
                    <>
                      {type && (
                        <Rating
                            name="rating"
                            value={product.noterequest}
                            readOnly
                          />)}

                        {!type && (
                          <RatingElement
                          type={product.satisfactionsurveyquestionstypeid}
                          value={product.noteassigned}
                        />
                      )}
                    </>
                  )
                }
              </>
             
             

              

            </ListItem>
          ))}
        </List>
        }
        {
          data.length === 0 && <div style={{fontSize:17, textAlign: 'center'}}><FormattedMessage id="quality.dashboard.last.feedbacks.not.exists" /></div>
        }
        
      </CardContent>
      <Divider />
    </Card>
  );
};

LatestProducts.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  type: PropTypes.bool
};

export default injectIntl(LatestProducts);
