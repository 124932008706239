import React from 'react';
import { Button, Menu, MenuItem as MaterialMenuItem, Typography, IconButton } from '@mui/material';
import { ExitToApp, Person, CompareArrows, AccountBox } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl'
import NotificationsIcon from '@mui/icons-material/Notifications';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import SnackbarContent from '@mui/material/SnackbarContent';
import * as signalR from '@microsoft/signalr';
import ItemMenu from './index.itemMenu';
import Api from '../../services/api';

import { makeStyles } from '@mui/styles';

import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useChatHubContext } from "../../contexts/chatHubContext";
import { PapiroConsole } from '../../utils/papiroConsole';



function NoticiationMenu(props) {


  const {
    notifications,
    chatHub: chatHubConnection,
    setAllNotifications
  } = useChatHubContext();

  PapiroConsole.log(notifications)
  PapiroConsole.log("notifications no menu de notificações")

  const intl = props.intl;



  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    paper: {
      marginRight: theme.spacing(2),
    },
  }));

  const StyledPaper = withStyles({
    root: {
      '&::-webkit-scrollbar': {
        width: '9px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(63, 81, 180, 0.6)', /* Cor do thumb (cabeça da barra de rolagem) */
        borderRadius: '6px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#f1f1f1', /* Cor de fundo da barra de rolagem */
      },
    },
    expanded: {},
  })(Paper);


  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [modalRatingOpen, setModalRatingOpen] = React.useState(false);
  const [unratedCalledsListState, setUnratedCalledsListState] = React.useState([]);
  const [showMoreData, setShowMoreData] = React.useState(null);






  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  var organizationId = null;

  const handleClose = (event) => {
    if (event && event.target && anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const readNotification = async (item) => {

    let id = item.id;
    props.loading(true);
    PapiroConsole.log(item)
    PapiroConsole.log("item")
    await Api.put(`/notifications/readnotification/${id}`)
      .then(result => {
        if (result.data.success) {
          props.setAlertNotification("success", "Operação realizada com sucesso!")
          let array = notifications;
          const findItem = array.findIndex(item => item.id === id);
          // Remover o item se encontrado
          if (findItem !== -1) {
            array.splice(findItem, 1);
          }

          setAllNotifications(array)
        }

        props.loading(false);

      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          const intl = props.intl;
          props.setAlertNotification("error", err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
        }
        else {
          const intl = props.intl;
          props.setAlertNotification("error", intl.formatMessage({ id: "process.error" }))

        }

        props.loading(false);
      });




  }

  const redirectNotification = async (item) => {


    PapiroConsole.log(item)
    PapiroConsole.log("item aqui 12")
    if (!item.required) {
      let id = item.id;
      props.loading(true);
      
      await Api.put(`/notifications/readnotification/${id}`)
        .then(result => {
          if (result.data.success) {
            props.setAlertNotification("success", "Operação realizada com sucesso!")
            PapiroConsole.log(props)
            PapiroConsole.log("props aqui")
            let array = notifications;
            const findItem = array.findIndex(item => item.id === id);
            // Remover o item se encontrado
           
            if (findItem !== -1) {
              array.splice(findItem, 1);
            }

            setAllNotifications(array)

            //checagem para redirecionar para determinado link (coluna) ou oura funcionalidade

            // Pesquisa de satisfação  (abrir modal)
            
            //redirecionar para os links
            if (props.propsToRedirect && props.propsToRedirect.match && props.propsToRedirect.history) {
              const { lang } = props.propsToRedirect.match.params
              props.propsToRedirect.history.push(`/${lang}/${item.redirection}`)
            }

          }

          props.loading(false);

        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            const intl = props.intl;
            props.setAlertNotification("error", err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
          }
          else {
            const intl = props.intl;
            props.setAlertNotification("error", intl.formatMessage({ id: "process.error" }))

          }

          props.loading(false);
        });
    } else {

      PapiroConsole.log(item)
      PapiroConsole.log("item aqui 13")

      if (item.notificationType == 14) {

        organizationId = item.organizationId;

        PapiroConsole.log(item)
        PapiroConsole.log("item aqui 14")
        handleClose()
        getAllClassificationItens(1, item.calledId)

      }
      else if (props.propsToRedirect && props.propsToRedirect.match && props.propsToRedirect.history) {
        const { lang } = props.propsToRedirect.match.params
        props.propsToRedirect.history.push(`/${lang}/${item.redirection}`)
      }
    }





  }


  const closeModalRating = () => {
    /*this.setState({
      modalRatingOpen: false,
      unratedCalledsList: []
    });*/

    setModalRatingOpen(false)
    setUnratedCalledsListState([])
  }


  const getAllClassificationItens = async (page = 1,calledId) => {

    PapiroConsole.log("item aqui 15")
    PapiroConsole.log(organizationId)

    props.getItens(page = 1, organizationId, calledId);

  
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  var badge = false;
  var count = 0;

  if (notifications && notifications.length > 0) {

    badge = true;
    count = notifications.length;

    /*if ((notifications.calledNotificationsList && notifications.calledNotificationsList.length > 0) || (notifications.orgNotificationsList && notifications.orgNotificationsList.length > 0)) {
      badge = true;
    }*/

    /*if (notifications) {
    if (notifications.calledNotificationsList && notifications.calledNotificationsList.length > 0) {
      count = notifications.calledNotificationsList.length;
    }

    if (notifications.orgNotificationsList && notifications.orgNotificationsList.length > 0) {
      count += notifications.orgNotificationsList.length;
    }


  }*/

  }


  /*const MenuItem = (item) => (
    <ItemMenu notificationName={item.calledName} calledIdOrOrgId={item.calledId} listNotification={item.notifications} readNotification={readNotification} intl={intl} />
  )

  const MenuItemOrgs = (item) => (
    <ItemMenu notificationName={item.organizationName} calledIdOrOrgId={item.organizationId} listNotification={item.notifications} readNotification={readNotification} intl={intl} />
  )*/

  const MenuItem = (item) => (
    <ItemMenu readNotification={readNotification} redirectNotification={redirectNotification} intl={intl} item={item} /*notificationName={item.calledName} calledIdOrOrgId={item.calledId} listNotification={item.notifications}*/ />
  )

  return (
    <div style={{ marginRight: '-10px' }}>

      <IconButton
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        id="btnNotification"
        style={{ color: '#FFFFFF' }}
      >
        {badge && (
          <Badge badgeContent={count} color="error" >

            <Tooltip title={intl.formatMessage({ id: "notifications" })} aria-label={props.intl.formatMessage({ id: "add" })}>

              <NotificationsIcon />
            </Tooltip>

          </Badge>
        )}
        {!badge && (
          <Tooltip title={intl.formatMessage({ id: "notifications" })} aria-label={props.intl.formatMessage({ id: "add" })}>

            <NotificationsIcon />
          </Tooltip>
        )}
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition sx={{ zIndex: 1099 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <StyledPaper style={{ backgroundColor: '#F2F3F4', marginTop: '22px', marginBottom: '0px', padding: '10px', maxHeight: '60vh', overflowY: 'auto' }}>
              <ClickAwayListener onClickAway={handleClose}>
                {(!notifications || (notifications && notifications.length == 0)) ? (
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MaterialMenuItem onClick={handleClose}>{intl.formatMessage({ id: "notification.menu.no.notifications" })}</MaterialMenuItem>
                  </MenuList>
                ) : (
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {notifications.map((item, key) => {
                      return MenuItem(item);
                    })}
                  </MenuList>
                )

                }
                {/*(notifications.calledNotificationsList == null || (notifications.calledNotificationsList && notifications.calledNotificationsList.length == 0)) ? (
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MaterialMenuItem onClick={handleClose}>{intl.formatMessage({ id: "notification.menu.no.notifications" })}</MaterialMenuItem>
                  </MenuList>
                ) : (
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {notifications.calledNotificationsList.map((item, key) => {
                      return MenuItem(item);
                    })}
                    {notifications.orgNotificationsList.map((item, key) => {
                      return MenuItemOrgs(item);
                    })}
                  </MenuList>
                  )*/}
              </ClickAwayListener>
            </StyledPaper>
          </Grow>
        )}
      </Popper>

    </div>
  );
}

NoticiationMenu.propTypes = {
  listNotification: PropTypes.array.isRequired,
};


export default injectIntl(NoticiationMenu);
