import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import SimpleAutoComplete from '../auto-complete/autocomplete';
import { TextField, Grid, Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import SimpleSelect from "../../components/select/simple";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    fullWidth: true,
    display: "flex",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function RulesAttendance({ deleteButton, index, removeItemCallback, rules }) {
  
  const conditionTypeOptions = [{id: 1, name: 'Mensagem'}, {id: 2, name: 'Nome do contato'}, {id: 3, name: 'Telefone'}];
  const conditionOptions = [{id: 1, name: 'Contém'}, {id: 2, name: 'Não Contém'}, {id: 3, name: 'É igual'}, {id: 4, name: 'Não é igual'}];
  
  const [ conditionType, setConditionType ] = useState(rules && rules.conditionType ? rules.conditionType : 1);
  const [ condition, setCondition ] = useState(rules && rules.condition ? rules.condition : 1);
  const [ value, setValue ] = useState(rules && rules.value ? rules.value : "");

  console.log(rules)
  console.log('rules')
  
  const removeItem = () => {
    removeItemCallback(index);
  }

  return (
    <div>
      <Grid container spacing={2} style={{ padding: 10 }}>
        <Grid item sm={3}>
          <SimpleSelect
            name="propertyRule"
            label="Se"
            options={conditionTypeOptions}
            changeSelect={(stateName, value) => {
              setConditionType(value);
            }}
            selected={conditionType}
            required
            isRequired={true}
          />
        </Grid>
        <Grid item sm={3}>
          <SimpleSelect
            name="relationRule"
            label="Condição"
            options={conditionOptions}
            changeSelect={(stateName, value) => {
              setCondition(value);
            }}
            selected={condition}
            required
            isRequired={true}
          />
        </Grid>
        <Grid item sm={5}>
          <TextField
            name="valueRule"          
            fullWidth
            label="Valor"
            variant='outlined'
            value={value}
            onChange={(e) => {
              setValue(e.target.value) 
            }}
            required 
          />
        </Grid>
        {deleteButton && (
          <Grid item sm={1}>
            <Grid container style={{marginTop: 10}}>
              <Grid item xs={12}>
                <Button 
                  fullWidth
                  variant='contained'
                  color='primary'
                  onClick={removeItem}
                >
                  <DeleteIcon />
                </Button>
              </Grid>
          </Grid>
            
          </Grid>
        )}
        
      </Grid>
    </div>
  );
}