import React, { Component } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import moment from "moment";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Container,
  Tooltip,
} from "@mui/material";
import { QueuePlayNext, DeleteForever, Help, CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import Api from "../../services/api";
import CustomizedSnackbars from "../../components/material-snackbars";
import Dropzone from "react-dropzone";
import HeaderMenu from "../../components/header-menu";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SimpleSelect from "../../components/select/simple";
import SimpleCheckBox from "../../components/checkbox/check";
import MyCheckList from "../../components/checklist/checklist";
import Footer from "../../components/footer";
import { browserHistory } from "../../helpers/history";
import Title from "../../components/title-name";
import * as Service from "../../services/user.service";
import * as AddressService from '../../services/address.service'
import * as componentActions from "../../redux/actions/users";
import { FormattedMessage, injectIntl } from "react-intl";
import SimpleAutoCompleteMultiple from "../../components/auto-complete/autocomplete-multiple-create";
import Autocomplete from "@mui/material/Autocomplete";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { DataGrid, GridToolbarFilterButton } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogConfirmation from '../../components/dialog-confirmation'
import NumberFormat from "react-number-format"
import SimpleHelp from "../../components/help"
import { PapiroConsole } from "../../utils/papiroConsole";
import { getAllWithoutIncludes } from '../../services/unit.service'
import SimpleAutoComplete from "../../components/auto-complete/autocomplete";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogAddMultiple from "../../components/dialog-add-multiple";
import { getAll as getStates } from '../../services/states.service.'

const NoDataRows = _ => {
  return (
    <div className="MuiDataGrid-window" style={{ display: 'flex', justifyContent: 'center', top: 56, overflowY: 'auto' }} >Não há registros a serem exibidos</div>
  )
}

class UserEdit extends Component {
  constructor(props) {
    super(props);
    const { userSession, users } = this.props;
    const intl = this.props.intl;

    this.list = []
    PapiroConsole.log("this.props.users")
    PapiroConsole.log(this.props.users)
    if (this.props.users && this.props.users.user && this.props.users.user.organizationusers) {
      this.props.users.user.organizationusers.forEach((element) => {
        this.list.push(element.organization);
      });//Verificar pois no commit n ficou claro oq era pra fazer, aceitar esse trecho ou n
    }


    this.globalAdm = false;
    this.isClient = true;
    if (userSession == null || userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    PapiroConsole.log(users)
    PapiroConsole.log("users aqui")

    if (!users || (users && !users.user)) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/calleds`
    }

    this.props.users.user.organizationusers.forEach((element) => {
      this.list.push(element.organization);

      //groupListIds = element.organization.group.id + "," + groupListIds;
    });

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    });

    let groupList = [];
    let groupListIds = "";
    let orgList = [];
    let locationIdList = []
    let orgListIds = ""

    users.user.organizationusers.forEach((element) => {
      if( element && element.organization && element.organization.group){
        var g = { id: element.organization.group.id, name: element.organization.group.name.toString() };
        groupList.push(g);
        groupListIds = element.organization.group.id + "," + groupListIds;
      }
    });

    users.user.organizationusers.forEach((element) => {
      let newElement = element;
      orgList.push(newElement.organization);
      if (element && element.organization && element.organization.id && typeof element.organization.id !== 'undefined')
        orgListIds = `${element.organization.id},${orgListIds}`
    });

    if (orgListIds && orgListIds.length > 1)
      orgListIds = orgListIds.slice(0, -1)

    if (users.user.useraddresses) {
      users.user.useraddresses.map((location) => {
        if (location && location.address && location.address.organization && location.address.organization.name && location.address.organization.name.length > 0) {
          location.address.name = `( ${location.address.organization.name} ) ${location.address.name}`;
          locationIdList.push(location.address);
        }
      });
    }



    this.startPageItems = [
      { id: 'calleds', name: intl.formatMessage({ id: "called.header" }) },
      { id: 'dashboard', name: intl.formatMessage({ id: "dashboard.operation" }) },
      { id: 'portal', name: intl.formatMessage({ id: "portal" }) },
      { id: 'knowledgebases-client', name: intl.formatMessage({ id: "knowledgebase.panel" }) }
    ]

    this.genderList = [
      { id: 1, name: intl.formatMessage({ id: "gender.male" }) },
      { id: 2, name: intl.formatMessage({ id: "gender.female" }) }
    ]

    this.stateList = [
      { id: 1, name: 'Acre' },
      { id: 2, name: 'Alagoas' },
      { id: 3, name: 'Amapá' },
      { id: 4, name: 'Amazonas' },
      { id: 5, name: 'Bahia' },
      { id: 6, name: 'Ceará' },
      { id: 7, name: 'Espírito Santo' },
      { id: 8, name: 'Goiás' },
      { id: 9, name: 'Maranhão' },
      { id: 10, name: 'Mato Grosso' },
      { id: 11, name: 'Mato Grosso do Sul' },
      { id: 12, name: 'Minas Gerais' },
      { id: 13, name: 'Pará' },
      { id: 14, name: 'Paraíba' },
      { id: 15, name: 'Paraná' },
      { id: 16, name: 'Pernambuco' },
      { id: 17, name: 'Piauí' },
      { id: 18, name: 'Rio de Janeiro' },
      { id: 19, name: 'Rio Grande do Norte' },
      { id: 20, name: 'Rio Grande do Sul' },
      { id: 21, name: 'Rondônia' },
      { id: 22, name: 'Roraima' },
      { id: 23, name: 'Santa Catarina' },
      { id: 24, name: 'Sao Paulo' },
      { id: 25, name: 'Sergipe' },
      { id: 26, name: 'Tocantins' },
      { id: 27, name: 'Distrito Federal' }
    ]

    //var arr = users.user.userroles.map(item => `${item.role.id}`).join(',');
    this.initialState = {
      startPage: users.user.startpage,
      UpdatedUser: userSession.user.id,
      departamentName: "",
      id: users.user.id,
      OrganizationId: "" /*orgList*/,
      Locations: users.user.useraddress,
      LocationId: "" /*locationIdList*/,
      OrganizationText: users.user.OrganizationText,
      WorkHourId: users.user.workhourid ? users.user.workhourid : "",
      WorkHourText: users.user.workHourText,
      name: users.user.name,
      email: users.user.email,
      document: users.user.document,
      gender: users.user.gender,
      state: users.user.state ? users.user.state : 0,
      unitId: users.user.unitid ? users.user.unitid : 0,
      GroupId: groupList,
      Organizations: "",
      canUserViewArchivedCalleds: users.user.canuserviewarchivedcalleds ? users.user.canuserviewarchivedcalleds : false,
      Groups: "",
      active: users.user.active,
      canCreateCalledInMobile: users.user.cancreatecalledinmobile,
      phone: users.user.phone ? this.mtel(users.user.phone) : "",
      allAddresses: "",
      UserRoles: users.user.userroles,
      WorkHourSelect: users.user.workhour,
      OrganizationUsers: users.user.organizationusers,
      OrganizationDepartamentId: users.user.organizationdepartament
        ? users.user.organizationdepartament
        : null,
      GroupListIds: groupListIds,
      perhour: users.user.costparameter == 1 ? true : false,
      calledunit: users.user.costparameter == 2 ? true : false,
      employeehourvalue: users.user.employeehourvalue,
      valueforcalled: users.user.valueforcalled,
      kmvaluerotated: users.user.kmvaluerotated,
      canInteractWithCalledWhereAreNotRequesterOrAssigned: users.user.caninteractwithcalledwherearenotrequesterorassigned,
      twoFactorAuthenticationActive: users.user.twofactorauthenticationactive ?? false,
      isUnityObrigatory: userSession.user.isunityobrigatory,
      isCostControlObrigatory: userSession.user.iscostcontrolobrigatory,
      isExternal: userSession.user.isexternal,
      defaultorganization: "",
    };

    console.log(users)

    this.addressInitialState = {
      updatedUser: userSession.user.id,
      updatedAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      id: 0,
      userid: users.user.id,
      UserText: '',
      template: true,
      template2: false,
      name: '',
      street: '',
      zipcode: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
      country: '',
      longitude: '',
      latitude: '',
      keepaddress: false,
      iscustomaddress: false,
      saveToAllOrganizationsInGroup: false,
    };

    this.state = {
      itemError: {
        document: '',
      },
      AllOrganizations: [],
      startPageItems: this.startPageItems,
      user: this.initialState,
      address: this.addressInitialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",

      RoleList: [],
      RoleListUser: [],
      WorkHoursList: [],
      DepartamentList: [],
      GroupList: [],

      OrgListForDelete: "",
      OrgListForSelect: [],
      OrgList: orgList,
      OrgListSelect: [],
      OrgListIds: orgListIds,

      LocationListForDelete: "",
      LocationListForSelect: [],
      LocationList: locationIdList,
      LocationListSelect: [],

      genderList: this.genderList,
      statesList: [],
      unitList: [],
      openOrgInsertModal: false,
      openLocationInsertModal: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this)
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.handleSubmitActiveSaaS = this.handleSubmitActiveSaaS.bind(this)
    this.getFirstAddressByUserId = this.getFirstAddressByUserId.bind(this)
    this.handleSubmitAddress = this.handleSubmitAddress.bind(this)
    this.closeLocationModal = this.closeLocationModal.bind(this)
    this.closeOrgModal = this.closeOrgModal.bind(this)
    this.getUnitList = this.getUnitList.bind(this)
  }

  closeLocationModal = () => this.setState({ openLocationInsertModal: false })

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  async componentDidMount() {
    await this.getData()
    this.getUnitList()
    this.getRoles()
    await this.getWorkHoursByOrgIds(this.state.OrgListIds)
    this.getOrganizations()
    this.getAllDepartaments(this.state.user.OrganizationUsers)
    this.GetLocationsByOrganizationId(this.state.OrgListIds)
    this.getFirstAddressByUserId()
    this.getStates();
  }

  getData = async _ => {
    let result = await Service.getForUpdateById(this.state.user.id)
    if (result && result.success) {
      if (result.data != null) {
        const item = result.data

        let groupList = [];
        let groupListIds = "";
        let orgList = [];
        let locationIdList = []
        let orgListIds = ""
        let defaultorganization = ""

        item.organizationusers.forEach((element) => {
          if( element && element.organization && element.organization.group){
            var g = { id: element.organization.group.id, name: element.organization.group.name.toString() };
            groupList.push(g);
            groupListIds = element.organization.group.id + "," + groupListIds;
          }
        });

        item.organizationusers.forEach((element) => {
          let newElement = element;
          orgList.push(newElement.organization);
          if (element && element.organization && element.organization.id && typeof element.organization.id !== 'undefined'){
            orgListIds = `${element.organization.id},${orgListIds}`
            if (element.isdefault) {
              defaultorganization = element.organization.id
            }
          }
        });

        if (orgListIds && orgListIds.length > 1)
          orgListIds = orgListIds.slice(0, -1)

        if (item.useraddresses) {
          item.useraddresses.map((location) => {
            if (location && location.address && location.address.organization && location.address.organization.name && location.address.organization.name.length > 0) {
              location.address.name = `( ${location.address.organization.name} ) ${location.address.name}`;
              locationIdList.push(location.address);
            }
          });
        }

        let userRoles = []
        if (item.userroles)
          userRoles = this.getObjectRolesIds(item.userroles)

        PapiroConsole.log(item)
        PapiroConsole.log("item da listagem aqui")
        this.setState(prevState => ({
          user: {
            ...prevState.user,
            gender: item.gender ? item.gender : 0,
            state: item.state ? item.state : 0,
            unitId: item.unit ? item.unit : 0,
            active: item.active,
            document: item.document ? item.document : "",
            canCreateCalledInMobile: item.cancreatecalledinmobile,
            employeehourvalue: item.employeehourvalue ? item.employeehourvalue : 0,
            valueforcalled: item.valueforcalled ? item.valueforcalled : 0,
            kmvaluerotated: item.kmvaluerotated ? item.kmvaluerotated : 0,
            perhour: item.costparameter == 1 ? true : false,
            calledunit: item.costparameter == 2 ? true : false,
            phone: item.phone ? this.mtel(item.phone) : "",
            startPage: item.startpage ? item.startpage : "",
            twoFactorAuthenticationActive: item.twofactorauthenticationactive,
            canInteractWithCalledWhereAreNotRequesterOrAssigned: item.caninteractwithcalledwherearenotrequesterorassigned,
            UserRoles: userRoles,
            WorkHourId: item.workhour ? item.workhour.id : "",
            workHourText: item.workhour ? item.workhour.name : "",
            departament: item.organizationdepartament ? item.organizationdepartament.name : "",
            departamentId: item.organizationdepartament ? item.organizationdepartament.id : 0,
            Locations: item.useraddress,
            GroupId: groupList,
            WorkHourSelect: item.workhour,
            OrganizationUsers: item.organizationusers,
            GroupListIds: groupListIds,
            canUserViewArchivedCalleds: item.canuserviewarchivedcalleds,
            isExternal: item.isExternal
          },
          OrgList: orgList,
          OrgListIds: orgListIds,
          LocationList: locationIdList,
        }))
      }
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }
  }

  async getUnitList() {
    let result = await getAllWithoutIncludes()
    if (result && result.success) {
      if (result.data && result.data.length > 0)
        this.setState({ unitList: result.data })
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }
  }
  async getStates() {
    let result = await getStates()
    if (result && result.success) {
      if (result.data && result.data.length > 0)
        this.setState({ statesList: result.data })
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }
  }

  async getAllGrous() {
    this.setState({ loading: true });
    var groups = await Service.getGroups();
    //var gr = []
    //    groups.forEach( (g) => (gr.push(g.name) )   );
    //groups.map((item) => gr.push(item.name));
    //let gr =groups.map(item => { return item.name; });

    //this.setState({ loading: false, GroupList: gr });
    this.setState({ loading: false, GroupList: groups });

  }

  async getAllLocations(ids) {

    if (ids.length > 0) {
      this.setState({ loading: true });
      let id = ids.map((item) => item.id);
      let locations = []
      Api.get(`/addresses/`).then(result => {

        if (result.data.success) {

          result.data.data.forEach(element => {
            id.forEach(idElement => {

              if (element.organization.id === idElement) {
                locations = [...locations, element]

              }
            })
          });

          //locations =  result.data.data



          locations = locations.map((location) => {

            return {
              ...location,
              name: `( ${location.organization.name} ) ${location.name}`,
            };
          });

          this.setState({ loading: false, LocationList: locations });



        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

          });
          Api.kickoff(result);

        }
      })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }

        });

    }

  }

  async getAllOrganizations(ids) {
    if (ids.length > 0) {
      this.setState({ loading: true });
      let urlids = ids.map((item) => item.id);
      //this.setState({ loading: true });
      let orgs = await Service.getOrganizationsByGroup(urlids);
      if (orgs != null && orgs.length > 0) {
        orgs = orgs.map((org) => {
          return {
            id: org.id,
            name: `( ${org.group.name} ) ${org.name}`,
          };
        });

        PapiroConsole.log("orgs 1")
        PapiroConsole.log(orgs)
        
        let organizations = this.state.user.OrganizationId;
        this.setState({ loading: false, OrgListForSelect: orgs });
        if (organizations.length > 0) {
          for (var i = 0; i < organizations.length; i++) {
            for (var j = 0; j < orgs.length; j++) {
              if (organizations[i].id === orgs[j].id) {
                organizations[i].name = orgs[j].name;
              }
            }
          }
          this.setState({
            loading: false,
            user: {
              ...this.state.user,
              OrganizationId: organizations,
            },
          });
        }
      }

      //this.setState({ loading: false, GroupList: groups });
    }
  }

  async getAllDepartaments(ids) {


    if (ids.length > 0) {
      this.setState({ loading: true });
      let urlids = ids.map((item) => item.organizationid);
      //this.setState({ loading: true });



      var depts = await Service.getDepartaments(urlids);
      if (depts) {
        depts = depts.map((dept) => {
          return {
            ...dept,
            parentname: dept.name,
            order: dept.organization.name,
          };
        });

        let orgDept = this.state.user.OrganizationDepartamentId
        const { users } = this.props
        if (users.user.organizationdepartament && users.user.organizationdepartament.id != null) {
          let filtered = depts.filter(item => item.id == users.user.organizationdepartament.id)
          if (filtered && filtered.length > 0) {
            orgDept = filtered[0]
          }
        }

        this.setState(prevState => ({
          DepartamentList: depts,
          user: {
            ...prevState.user,
            OrganizationDepartamentId: orgDept
          }
        }));
      }

      this.setState({ loading: false });

      /*if (this.state.user.OrganizationDepartamentId !== 0) {
        depts.forEach((item) => {
          if (item.id === this.state.user.OrganizationDepartamentId) {
            this.setState({
              user: {
                ...this.state.user,
                departamentName: item.name,
              },
            });
          }
        });
      }*/
    }
  }

  async getAllDepartamentsChange(ids) {
    if (ids.length > 0) {
      this.setState({ loading: true });
      let urlids = ids.map((item) => item.id);
      //this.setState({ loading: true });

      var depts = await Service.getDepartaments(urlids);
      depts = depts.map((dept) => {
        return {
          ...dept,
          parentname: dept.name,
          order: dept.organization.name,
        };
      });

      this.setState({ loading: false, DepartamentList: depts });
    }
  }

  async getWorkHours() {
    this.setState({ loading: true });
    var result = await Service.getWorkHoursList(this.state.OrgListIds);
    if (result.success) {
      const id = result.data.find(w => w.id == this.state.user.WorkHourId)
      this.setState((prevState) => ({
        ...prevState,
        loading: false,
        WorkHoursList: result.data,
        user: {
          ...prevState.user,
          WorkHourId: id
        }
      }));
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }
  }

  getWorkHoursByOrgIds = async (ids) => {
    this.setState({ loading: true });

    let data = new FormData();
    data.append('organizationids', ids)

    var result = await Service.getWorkHoursListByOrgs(data);
    if (result.success) {
      const id = result.data.find(w => w.id == this.state.user.WorkHourId)
      this.setState((prevState) => ({
        ...prevState,
        loading: false,
        WorkHoursList: result.data,
        user: {
          ...prevState.user,
          WorkHourId: id
        }
      }));
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }
  }

  async getOrganizations() {
    this.setState({ loading: true });
    var result = await Service.getOrganizations();
    if (result.success) {
      var getData = result.data
      //var orgFiltered = result.data.filter((i) => !this.state.OrgList.includes(i.id));
      PapiroConsole.log("result data aqui")
      let orgFiltered = getData ? getData.filter(o1 => !this.state.OrgList.some(o2 => o1.id === o2.id)) : [];
      this.setState({ loading: false, OrgListForSelect: orgFiltered, AllOrganizations: result.data ? result.data : [] });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }
  async getDepartament(orgId) {
    this.setState({ loading: true });
    var result = await Service.getDepartamentList(orgId);
    if (result.success) {
      this.setState({ loading: false, DepartamentList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });

      Api.kickoff(result);
    }
  }
  async getRoles() {
    this.setState({ loading: true });
    var result = await Service.getRoles();
    if (result.success) {

      let idToRemove = 6;
      const rolesList = result.data.filter(item => item.id !== idToRemove);
      this.setState({ loading: false, RoleList: rolesList});
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }

  async getRolesByUser() {
    this.setState({ loading: true });
    var result = await Service.getRolesByUser(this.state.user.id);
    if (result.success) {
      this.setState({ loading: false, RoleListUser: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : intl.formatMessage({ id: "process.error" }),
      });
      Api.kickoff(result);
    }
  }

  handleChangePhone(e) {
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        'phone': e
      }
    }));
  }

  handleInputChange(e) {
    const name = e.target.name;
    var value = e.target.value;




    if (e.target.name === "valueforcalled" || e.target.name === "employeehourvalue" || e.target.name === "kmvaluerotated") {
      if (e.target.value < 0) {
        value = 0
      }
    }

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [name]: value,
      },
    }));

    if (e.target.name === "phone") {
      this.setState((prevState) => ({
        user: {
          ...prevState.user,
          "phone": this.mtel(value),
        },
      }));


    }

    if (name === 'document') {

      var valor = 0;
      if (value.length <= 14) {
        valor = this.cpfMask(value)
      }
      else {
        valor = this.CNPJMask(value)
      }
      this.isCPFOrCNPJValid(value)

      valor.length === 0 ? this.setState(prevState => ({ itemError: { ...prevState.itemError, document: '' } })) : ((valor.length !== 14 && valor.length !== 18) || !this.isCPFOrCNPJValid(value)) ? this.setState(prevState => ({ itemError: { ...prevState.itemError, document: 'CPF/CNPJ inválido' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, document: '' } }))
      this.setState(prevState => ({
        user: {
          ...prevState.user,
          [name]: valor
        }
      }))
    }
    if (name === 'email') {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          [name]: value.toLowerCase(),
        },
      }));
      
    }
  }

  cpfMask = value => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }

  CNPJMask = (valor) => {
    return valor
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  };

  isCPFOrCNPJValid(value) {
    // Remove caracteres não numéricos
    const numericValue = value.replace(/\D/g, '');
    if (numericValue.length === 11) {
      // Validação CPF
      let sum = 0;
      let remainder;

      for (let i = 1; i <= 9; i++) {
        sum += parseInt(numericValue.substring(i - 1, i)) * (11 - i);
      }

      remainder = (sum * 10) % 11;

      if (remainder === 10 || remainder === 11) {
        remainder = 0;
      }

      if (remainder !== parseInt(numericValue.substring(9, 10))) {
        return false;
      }

      sum = 0;
      for (let i = 1; i <= 10; i++) {
        sum += parseInt(numericValue.substring(i - 1, i)) * (12 - i);
      }

      remainder = (sum * 10) % 11;
      if (remainder === 10 || remainder === 11) {
        remainder = 0;
      }

      return remainder === parseInt(numericValue.substring(10, 11));
    } else if (numericValue.length === 14) {
      // Validação CNPJ
      let tamanho = numericValue.length - 2;
      let numeros = numericValue.substring(0, tamanho);
      const digitos = numericValue.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado !== parseInt(digitos.charAt(0))) {
        return false;
      }

      tamanho = tamanho + 1;
      numeros = numericValue.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

      return resultado === parseInt(digitos.charAt(1));
    }

    return false;
  }


  validateCpf = value => {
    let sum, rest;

    sum = 0

    if (value == "00000000000" || value == "000.000.000-00") return false;

    value = value.replaceAll(".", "").replaceAll("-", "")

    for (let i = 1; i <= 9; i++) sum += parseInt(value.substring(i - 1, i)) * (11 - i)
    rest = (sum * 10) % 11

    if ((rest == 10) || (rest == 11)) rest = 0
    if (rest != parseInt(value.substring(9, 10))) return false

    sum = 0
    for (let i = 1; i <= 10; i++) sum += parseInt(value.substring(i - 1, i)) * (12 - i)
    rest = (sum * 10) % 11

    if ((rest == 10) || (rest == 11)) rest = 0
    if (rest != parseInt(value.substring(10, 11))) return false
    return true
  }

  getObjectRolesIds(list) {
    if (list.length > 0) {
      const idList = list.map((item) => {
        return item.role.id;
      });
      return idList;
    }

    return list;
  }

  checkSelectedRoles = (item) => {
    if (this.state.user.UserRoles.includes(item.id))
      return true
  }

  async getFirstAddressByUserId() {
    const { userSession, users, intl } = this.props;

    this.setState({ loading: true })

    let result = await AddressService.getFirstByUserId(users.user.id)
    if (result && result.success) {
      if (result.data != null) {
        this.setState({
          loading: false,
          address: {
            userid: users.user.id,
            updatedUser: userSession.user.id,
            updatedAt: moment().format('YYYY/MM/DD HH:mm:ss'),
            createdUser: userSession.user.id,
            createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
            name: result.data.name,
            street: result.data.street,
            zipcode: result.data.zipcode,
            number: result.data.number,
            complement: result.data.complement,
            neighborhood: result.data.neighborhood,
            city: result.data.city,
            state: result.data.state,
            country: result.data.country,
            longitude: result.data.longitude,
            latitude: result.data.latitude,
            iscustomaddress: result.data.iscustomaddress
          }
        })
      } else {
        this.setState({ loading: false })
      }
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }

  async handleSubmitAddress() {

    const { address } = this.state

    if (address && address.name && (address.name == null || address.name.length == 0)) {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "missing.name.field" })
      })
      return false
    }

    this.setState({ loading: true })

    let data = new FormData()

    for (let key in this.state.address) {
      if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
        data.append(key, moment(this.state.address[key]).format('YYYY/MM/DD HH:mm:ss'));
      } else if (key.toString().toLowerCase().indexOf('id') > -1) {
        data.append(key, this.state.address[key]);
      } else if (key.toString().toLowerCase().indexOf('userid') > -1) {
        data.append(key, this.state.address[key]);
      } else if (key.toString().toLowerCase().indexOf('name') > -1) {
        data.append(key, this.state.address[key]);
      } else if (key.toString().toLowerCase().indexOf('street') > -1) {
        data.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
      } else if (key.toString().toLowerCase().indexOf('zipcode') > -1) {
        data.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
      } else if (key.toString().toLowerCase().indexOf('number') > -1) {
        data.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
      } else if (key.toString().toLowerCase().indexOf('complement') > -1) {
        data.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
      } else if (key.toString().toLowerCase().indexOf('neighborhood') > -1) {
        data.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
      } else if (key.toString().toLowerCase().indexOf('city') > -1) {
        data.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
      } else if (key.toString().toLowerCase().indexOf('state') > -1) {
        data.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
      } else if (key.toString().toLowerCase().indexOf('country') > -1) {
        data.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
      } else if (key.toString().toLowerCase().indexOf('longitude') > -1) {
        data.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
      } else if (key.toString().toLowerCase().indexOf('latitude') > -1) {
        data.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : '');
      } else if (key.toString().toLowerCase().indexOf('iscustomaddress') > -1) {
        data.append(key, typeof this.state.address[key] !== 'undefined' ? this.state.address[key] : false);
      } else {
      }
    }

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }

    const intl = this.props.intl

    if (this.state.address.id > 0) {
      data.append("updateduser", this.state.address.updatedUser);
      data.append("updatedat", this.state.address.updatedAt);

      let result = await AddressService.editItem(data, config)
      if (result && result.success) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'success',
          notificationMessage: intl.formatMessage({ id: "edit.success" })
        })
      } else {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(result)
      }
    } else {
      data.append("createduser", this.state.address.createdUser);
      data.append("createdat", this.state.address.createdAt);

      let result = await AddressService.addItem(data, config)
      if (result && result.success) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'success',
          notificationMessage: intl.formatMessage({ id: "add.success" })
        })
      } else {
        const intl = this.props.intl
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(result)
      }
    }
  }

  handleSubmit = async (e) => {

    e.preventDefault();
    const intl = this.props.intl;
    this.setState({ loading: true });

    let data = new FormData();

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
      },
    }));

    if (this.state.user.UserRoles == null || this.state.user.UserRoles == "") {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "selected.group" }),
      });
      return;
    }
    
    if (this.state.user.kmvaluerotated <= 0 && this.state.user.isCostControlObrigatory) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "user.edit.km.value.field.invalid" }),
      });
      return
    }
    let roleItemTech = this.state.RoleList.filter(item => item.roletype == 2);
    if (roleItemTech.length > 0) {
      let roleUserTech = this.state.user.UserRoles.filter(item => item == roleItemTech[0].id)
      if (roleUserTech.length > 0) {
        if (this.state.user.perhour && (this.state.user.employeehourvalue == null || this.state.user.employeehourvalue == "" || this.state.user.employeehourvalue == 0) && this.state.user.isCostControlObrigatory) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: intl.formatMessage({ id: "user.edit.hh.value.field.invalid" }),
          });
          return
        } else if (this.state.user.calledunit && (this.state.user.valueforcalled == null || this.state.user.valueforcalled == "" || this.state.user.valueforcalled == 0) && this.state.user.isCostControlObrigatory) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: intl.formatMessage({ id: "user.edit.value.per.called.field.invalid" }),
          });
          return;
        } else if (!this.state.user.calledunit && !this.state.user.perhour && this.state.user.isCostControlObrigatory) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: intl.formatMessage({ id: "user.edit.cost.parameter.missing" }),
          });
          return;
        }
      }
    }

    if (this.state.OrgList.length == 0) {
      var checkIfIsTechOrGlobalAdm = false

      this.state.user.UserRoles.forEach(element => {
        if (element === 1 || element === 5)
          checkIfIsTechOrGlobalAdm = true
      });
      if (checkIfIsTechOrGlobalAdm == false) {
        let roleListToFormat = this.state.RoleList.map(element => {
          if (this.checkSelectedRoles(element))
            return element.displayname
        })
        if (roleListToFormat != null && roleListToFormat.length > 0) {
          let filteredRoles = roleListToFormat.filter(i => typeof i !== "undefined")
          if (filteredRoles && filteredRoles.length > 0) {
            let requiredOrgMessage = intl.formatMessage({ id: "user.client.edit.to.usergroup" })
            if (filteredRoles.length > 1) {
              requiredOrgMessage = intl.formatMessage({ id: "user.client.edit.to.usergroup.plural" })
            }
            requiredOrgMessage += `${filteredRoles.join(", ")} ` + intl.formatMessage({ id: "user.client.edit.missing.organizations" })

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: requiredOrgMessage,
            });
          } else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: intl.formatMessage({ id: "user.client.edit.usergroup.missing.organizations" }),
            });
          }
        }
        return
      }
    }
    if (this.state.user.document && this.isCPFOrCNPJValid(this.state.user.document) == false) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "O campo CPF/CNPJ não foi validado"
      });
      return;
    }
    if (this.state.user.startPage == null || (this.state.user.startPage && this.state.user.startPage.length === 0)) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "user.client.edit.invalid.initial.screen.field" }),
      });
      return
    } else {
      let setStartPage = new Set()
      this.state.startPageItems.map(item => setStartPage.add(item.id))
      let setStartPageStatus = setStartPage.has(this.state.user.startPage)
      if (!setStartPageStatus) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: intl.formatMessage({ id: "user.client.edit.invalid.initial.screen.field" }),
        });
        return
      }
    }

    if (this.state.user.perhour) {
      if (this.state.user.employeehourvalue.length > 0) {
        data.append("costparameter", 1)
        data.append("valueforcalled", 0)
        data.append("employeehourvalue", this.state.user.employeehourvalue)
      } else {
        data.append("employeehourvalue", 0)
        data.append("valueforcalled", 0)
      }
    } else if (this.state.user.calledunit) {
      if (this.state.user.valueforcalled.length > 0) {
        data.append("costparameter", 2)
        data.append("valueforcalled", this.state.user.valueforcalled)
        data.append("employeehourvalue", 0)
      } else {
        data.append("employeehourvalue", 0)
        data.append("valueforcalled", 0)
      }
    } else {
      data.append("employeehourvalue", 0)
      data.append("valueforcalled", 0)
    }

    data.append("kmvaluerotated", this.state.user.kmvaluerotated)

    for (let key in this.state.user) {
      if (
        key
          .toString()
          .toLowerCase()
          .indexOf("_date") > -1 ||
        key
          .toString()
          .toLowerCase()
          .indexOf("date_") > -1
      ) {
        data.append(
          key,
          moment(this.state.user[key]).format("YYYY/MM/DD HH:mm:ss")
        );
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("phone") > -1
      ) {
        data.append(key, this.state.user[key].replace(/\D/gim, ""));
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("employeehourvalue") > -1) {
        if (this.state.user[key].length > 0) {
          /*let employeehourvalueNotComma = this.state.user[key].replaceAll(",", "")
          let employeehourvalueFloat = parseFloat(employeehourvalueNotComma) + ""
          let employeehourvalueFinal = employeehourvalueFloat.replace(".", ",")
          data.append(key, employeehourvalueFinal)*/
          data.append(key, this.state.user[key])
        } else
          data.append(key, 0)
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("document") > -1) {
        data.append(key, typeof this.state.user[key] !== 'undefined' || this.state.user[key] !== null ? this.state.user[key] : "");
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("startpage") > -1) {
        if (this.state.user[key] && this.state.user[key].id)
          data.append(key, this.state.user[key].id);
        else
          data.append(key, this.state.user[key]);
      } else if (key.toString().toLowerCase().indexOf("unitid") > -1) {
        if (this.state.user[key] && this.state.user[key].id && this.state.user[key].id > 0)
          data.append(key, this.state.user[key].id);
        else if (this.state.user[key] && this.state.user[key] > 0)
          data.append(key, this.state.user[key]);
        else
          data.append(key, 0);
      } else if (key.toString().toLowerCase().indexOf("workhourid") > -1) {
        if (this.state.user[key] && this.state.user[key].id && this.state.user[key].id > 0)
          data.append(key, this.state.user[key].id);
        else if (this.state.user[key] && this.state.user[key] > 0)
          data.append(key, this.state.user[key]);
        else
          data.append(key, 0);
      } else if (key.toString().toLowerCase().indexOf("gender") > -1) {
        if (this.state.user[key] && this.state.user[key] > 0)
          data.append(key, this.state.user[key]);
        else
          data.append(key, 0);
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("organizationdepartamentid") > -1) {
        if (this.state.user[key] && this.state.user[key].id)
          data.append(key, this.state.user[key].id);
        else
          data.append(key, 0);
      } else if(key.toString().toLowerCase().indexOf("defaultorganization") > -1) {
        if(this.state.user[key] && this.state.user[key].id && this.state.user[key].id > 0)
          data.append(key, this.state.user[key].id);
        else if (this.state.user[key] && this.state.user[key] > 0)
          data.append(key, this.state.user[key]);
        else
          data.append(key, 0);
      } else {
        data.append(key, this.state.user[key]);
      }
    }




    this.state.files.forEach((file) => data.append("filesUpload", file));
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    Api.put("/users", data, config)

      .then((result) => {
        if (result.data.success) {
          const formattedList = result.data.data;

          /*formattedList.userroles = this.getObjectRolesIds(
            formattedList.userroles
          );*/

          formattedList.passwordhash = null;
          formattedList.passwordrecoveryguid = null;
          formattedList.passwordsalt = null;

          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({ id: "edit.success" }),
            files: [],
          });
          this.props.addCurrent(formattedList);
          //this.componentDidMount();
        } else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              result.data.errors && result.data.errors[0]
                ? result.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        }
      })
      .catch((err) => {


        if (err.response && err.response.data && err.response.data.errors) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        } else {

          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage:
              err.response && err.response.data.errors
                ? err.response.data.errors[0]
                : intl.formatMessage({ id: "process.error" }),
          });
        }
      });
  };

  handleClick = async (e) => {
    this.setState({ loading: true });
    const intl = this.props.intl;
    // console.log(this.props.intl);
    const { userSession, users } = this.props;

    // console.log(users.user.email);
    let data = new FormData();
    data.append("userEmail", users.user.email);

    Api.put("users/resend-mail-active", data)
      .then((response) => {
        // console.log(response);
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "success",
          notificationMessage: "Email Enviado",
          item: this.initialState,
          files: [],
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            err.response && err.response.data && err.response.data.errors
              ? err.response.data.errors[0]
              : intl.formatMessage({ id: "process.error" }),
        });
      });
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  async GetLocationsByOrganizationId(ids) {
    PapiroConsole.log(ids)
    PapiroConsole.log("ids organizations")
    if (ids.length > 0) {
      //let urlids = ids.map((item) => item.id);


      var locations = await Service.getLocationsByOrganizations(ids);


      locations = locations.map((location) => {
        return {
          ...location,
          name: `( ${location.organization.name} ) ${location.name}`,
        }
      });

      this.setState({ loading: false, LocationListForSelect: locations });

    }
  }

  handleSubmitActiveSaaS() {

    this.setState({
      loading: true
    });

    let data = new FormData();

    //if (! /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.user['email'])) { 
    //  this.setState({loading: false,openNotification: true,notificationVariant: 'error',notificationMessage:'E-mail inválido.'});
    //  return;
    //}
    for (let key in this.state.user) {
      if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
        data.append(key, moment(this.state.user[key]).format('YYYY/MM/DD HH:mm:ss'));
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("organizationdepartamentid") > -1) {
        if (this.state.user[key] && this.state.user[key].id)
          data.append(key, this.state.user[key].id);
        else
          data.append(key, 0);
      } else if (
        key
          .toString()
          .toLowerCase()
          .indexOf("unitid") > -1) {
        if (this.state.user[key] && this.state.user[key].id)
          data.append(key, this.state.user[key].id);
        else
          data.append(key, 0);
      } else {
        data.append(key, this.state.user[key]);
      }
    }

    this.state.files.forEach(file => data.append('filesUpload', file));
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    Api.post('/users/addBySaaS', data, config)

      .then(result => {
        if (result.data.success) {

          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: 'Ativado no Mexx 2MOVE com sucesso!',
          });

        }
        else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: result.data.errors && result.data.errors[0] ? result.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          });

        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          });
        }
        else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          });
        }
      });
  };

  async changeValues(stateName, value, text = "") {
    this.setState({ loading: true });


    if (stateName == "perhour") {
      this.setState((prevState) => ({
        user: {
          ...prevState.user,
          'calledunit': false,
        },
      }));
    }

    if (stateName == "calledunit") {
      this.setState((prevState) => ({
        user: {
          ...prevState.user,
          'perhour': false,
        },
      }));
    }

    if (stateName == 'selectLocations') {
      if (value == true) {
        let idAddresses = []

        this.state.LocationListForSelect.forEach(element => {
          idAddresses = [...idAddresses, element.id]
        });
        this.setState((prevState) => ({
          user: {
            ...prevState.user,
            "LocationText": text,
            Location: this.state.LocationListForSelect,
            LocationId: this.state.LocationListForSelect,
            OrganizationDepartamentId: 0,
          },
          departamentName: "",
        }));
      } else {
        this.setState((prevState) => ({
          user: {
            ...prevState.user,
            "LocationText": text,
            Location: [],
            LocationId: [],
          }
        }));
      }
    } else if (stateName == 'selectOrganizations') {
      if (value == true) {
        this.setState(prevState => ({
          user: {
            ...prevState.user,
            Organizations: this.state.OrgListForSelect,
            OrganizationId: this.state.OrgListForSelect,
            "OrganizationText": text,
            departamentName: "",
            OrganizationDepartamentId: 0,
            LocationId: [],
            locationReset: [],
            Locations: [],
            Location: [],
            DepartamentList: []
          },
          DepartamentList: []
        }));
        await this.getAllDepartamentsChange(this.state.OrgListForSelect);
      } else {
        this.setState(prevState => ({
          user: {
            ...prevState.user,
            Organizations: [],
            OrganizationId: [],
            "OrganizationText": text,
            departamentName: "",
            OrganizationDepartamentId: 0,
            LocationId: [],
            locationReset: [],
            Locations: [],
            Location: [],
            DepartamentList: []
          },
          DepartamentList: []
        }));
        await this.getAllDepartamentsChange([]);
      }
    }

    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [stateName]: value,
      },
    }));

    if (stateName === "OrganizationId") {
      //this.setState({ LocationList: [] });

      //await this.getAllLocations(value);
      this.setState((prevState) => ({
        user: {
          ...prevState.user,
          "OrganizationText": text,
          Organizations: value,
          departamentName: "",
          OrganizationDepartamentId: 0,
          LocationId: [],
          locationReset: [],
          Locations: [],
        },
      }));
      this.setState({ DepartamentList: [] });
      await this.getAllDepartamentsChange(value);
    }

    if (stateName === "GroupId") {
      this.setState({ DepartamentList: [] });
      this.setState({ OrgListForSelect: [], LocationList: [], });
      this.setState({
        user: {
          ...this.state.user,
          OrganizationId: [],
          departamentName: "",
          OrganizationDepartamentId: 0,
          locationReset: [],
          LocationId: [],
          Locations: [],
        },
      });

      await this.getAllOrganizations(value);
      this.setState((prevState) => ({
        user: {
          ...prevState.user,
          "GroupText": text,
          Groups: value,
        },
      }));
    }

    if (stateName === "Location") {
      let idAddresses = []

      value.forEach(element => {
        idAddresses = [...idAddresses, element.id]
      });
      this.setState((prevState) => ({
        user: {
          ...prevState.user,
          "LocationText": text,
          Location: value,
          LocationId: idAddresses,
          OrganizationDepartamentId: 0,
        },
        departamentName: "",
      }));
    }

    this.setState({ loading: false });
  }
  mtel(v) {
    v = v.replace(/\D/g, "");
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
    v = v.replace(/(\d)(\d{4})$/, "$1-$2");
    return v;
  }
  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
  getRolesIds(list) {
    const idList = [];
    list.map((item) => {
      idList.push(item.id);
    });
    return idList;
  }
  getRolesNames(list) {
    const idList = []
    list.map((item) => {
      idList.push(item.name);
    });
    return idList;
  }


  getallLocationToDelete() {
    var x = ""
    if (this.state.LocationList != null) {
      this.state.LocationList.forEach(element => {
        x += element.id + ","

      });
    }

    return x;
  }

  NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={false}
        decimalSeparator=","
        isNumericString
        decimalScale={2}
        prefix="R$"
      />
    );
  }

  orgDeleteModal = async () => {
    const intl = this.props.intl;
    if (this.state.OrgListForDelete && this.state.OrgListForDelete.length > 0) {



      var allAddresses = ""

      allAddresses = this.getallLocationToDelete()



      this.setState({ loading: true });

      let data = new FormData();
      data.append("OrganizationListId", this.state.OrgListForDelete);
      data.append("LocalizationListId", this.state.LocationListForDelete);
      data.append("UserId", this.state.user.id);
      data.append("Addresses", allAddresses);





      await Api.post('/users/DeleteOrgOrLocalizationInUser', data)
        .then(result => {

          if (result.data.success) {

            var locations = []

            var organizations = [];
            let organizationIds = ""
            result.data.data.organizationusers.map(item => {
              organizations.push(item.organization)
              if (item.organization && item.organization.id && typeof item.organization.id !== 'undefined')
                organizationIds = `${item.organization.id},${organizationIds}`
            })

            if (organizationIds && organizationIds.length > 1)
              organizationIds = organizationIds.slice(0, -1)

            if (result.data.data.locations != null) {
              locations = result.data.data.locations
            }

            /*result.data.data.map(item => {
              organizations.push(item.organization)
            })*/

            //LocationList
            var orgFiltered = []
            if (organizations) {
              orgFiltered = this.state.AllOrganizations ? this.state.AllOrganizations.filter(o1 => !organizations.some(o2 => o1.id === o2.id)) : [];
            }

            

            this.GetLocationsByOrganizationId(organizationIds);

            this.setState({
              loading: false,
              OrgList: organizations,
              OrgListForSelect: orgFiltered,
              OrgListIds: organizationIds,
              openNotification: true,
              notificationVariant: 'success',
              LocationList: locations,
              notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
            }, () => {
              this.getWorkHoursByOrgIds(organizationIds)
            })
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: intl.formatMessage({ id: "process.error" })
            });
          }
          Api.kickoff(err);
        });
    } else {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "organization.material.registration.select.organization" })
      })
    }
  }

  openOrgInsertModal = async () => {
    this.setState({
      openOrgInsertModal: !this.state.openOrgInsertModal
    })
  }

  saveOrgInsertModal = async () => {



    if (this.state.user.OrganizationId != null && this.state.user.OrganizationId.length > 0) {
      this.setState({
        loading: true
      })

      let data = new FormData();

      var orgids = this.state.user.OrganizationId.map(item => {
        return item.id + ","
      })

      data.append("OrganizationListId", orgids);
      data.append("UserId", this.state.user.id);

      const intl = this.props.intl;
      await Api.post('/users/AddOrgOrLocalizationInUser', data)
        .then(result => {
          if (result.data.success) {
            var organizations = [];
            let organizationIds = ""
            result.data.data.map(item => {
              if (item.organization) {
                organizations.push(item.organization)
                if (item.organization && item.organization.id && typeof item.organization.id !== 'undefined')
                  organizationIds = `${item.organization.id},${organizationIds}`
              }
            })

            if (organizationIds && organizationIds.length > 1)
              organizationIds = organizationIds.slice(0, -1)

            this.GetLocationsByOrganizationId(organizationIds);

            var orgFiltered = []
            if (organizations) {
              orgFiltered = this.state.AllOrganizations ? this.state.AllOrganizations.filter(o1 => !organizations.some(o2 => o1.id === o2.id)) : [];
            }

            this.setState({
              loading: false,
              OrgList: organizations,
              OrgListIds: organizationIds,
              OrgListForSelect: orgFiltered,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({ id: "add.success" }),
            }, () => {
              this.getWorkHoursByOrgIds(organizationIds)
            })

          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: intl.formatMessage({ id: "process.error" })
            });
          }
          Api.kickoff(err);
        });

      this.setState({
        openOrgInsertModal: !this.state.openOrgInsertModal,
        loading: false
      })
    }

  }

  setOrgDelete = async (org) => {
    this.setState({
      OrgListForDelete: org + "," + this.state.OrgListForDelete
    })
  }

  closeOrgModal = () => this.setState({ openOrgInsertModal: false })

  locationDeleteModal = async () => {
    const intl = this.props.intl;
    if (this.state.LocationListForDelete && this.state.LocationListForDelete.length > 0) {

      this.setState({ loading: true });

      let data = new FormData();
      data.append("LocalizationListId", this.state.LocationListForDelete);
      data.append("UserId", this.state.user.id);

      await Api.post('/users/DeleteOrgOrLocalizationInUser', data)
        .then(result => {

          if (result.data.success) {
            var localizations = [];

            result.data.data.map(item => {
              item.address.name = `( ${item.address.organization.name} ) ${item.address.name}`;
              localizations.push(item.address)
            })

            this.setState({
              loading: false,
              LocationList: localizations,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
            })
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: intl.formatMessage({ id: "process.error" })
            });
          }
          Api.kickoff(err);
        });
    } else {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "select.location" })
      })
    }
  }

  openLocationInsertModal = async () => {
    this.setState({
      openLocationInsertModal: !this.state.openLocationInsertModal
    })
  }

  saveLocationInsertModal = async () => {

    if (this.state.user.LocationId != null && this.state.user.LocationId.length > 0) {
      this.setState({
        loading: true
      })

      let data = new FormData();

      var locationIds = this.state.user.LocationId.map(item => {
        return item.id + ","
      })

      data.append("LocalizationListId", locationIds);
      data.append("UserId", this.state.user.id);

      const intl = this.props.intl;
      await Api.post('/users/AddOrgOrLocalizationInUser', data)
        .then(result => {
          if (result.data.success) {
            var localizations = [];

            result.data.data.map(item => {
              item.address.name = `( ${item.address.organization.name} ) ${item.address.name}`;
              localizations.push(item.address)
            })

            this.setState({
              loading: false,
              LocationList: localizations,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({ id: "add.success" }),
            })

          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: intl.formatMessage({ id: "process.error" })
            });
          }
          Api.kickoff(err);
        });

      this.setState({
        openLocationInsertModal: !this.state.openLocationInsertModal,
        loading: false
      })
    }

  }

  setLocationDelete = async (org) => {
    this.setState({
      LocationListForDelete: org + "," + this.state.LocationListForDelete
    })
  }

  disableTwoFactorAuthenticaion = async () => {
    const intl = this.props.intl;

    let data = new FormData();

    data.append('email', this.state.user['email']);

    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    this.setState({ loading: true });

    Api.put('/users/disableTwoFactorAuthentication', data, config)

      .then(result => {
        if (result.data.success) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({ id: "twoFactorAuth.disableSuccess" })
          });
          this.setState(prevState => ({
            user: {
              ...prevState.user,
              twoFactorAuthenticationActive: false
            }
          }));
        }
        else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: result.data.errors && result.data.errors[0] ? result.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          });

        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          });
        }
        else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })

          });
        }
      });
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu, users } = this.props;
    //console.log
    const { user, itemError } = this.state;
    const organizationUsers = user.OrganizationUsers.map( ou => ou.organization )


    PapiroConsole.log(this.state.OrgListForSelect)
    PapiroConsole.log("this.state.OrgListForSelect")

    //console.log(user)
    //console.log(this.state.user)
    //console.log(user)
    const intl = this.props.intl;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    let checkIsActive = false
    if (user.UserRoles && user.UserRoles.length > 0) {
      let techFilter = user.UserRoles.filter(item => item === 5);
      if (techFilter.length > 0) {
        checkIsActive = true
      }
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={browserHistory.goBack}
                  >
                    {<FormattedMessage id="back" />}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component="h1" variant="h5">
                {<FormattedMessage id="edit.item" />}
                <Title />
              </Typography>
              <form
                name="myForm"
                className={classes.form}
                onSubmit={this.handleSubmit}
                encType="multipart/form-data"
              >

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>{intl.formatMessage({ id: "organization.header" })}*</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} xs={12} sm={12} >

                      <Grid xs={12} sm={12} style={{ margin: '5px', padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }} >
                        <Grid xs={12} sm={4} >

                        </Grid>
                        <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                          <DialogConfirmation
                            msgButton={intl.formatMessage({ id: "organization.material.registration.remove.organization" })}
                            msg={intl.formatMessage({ id: "user.client.registration.confirm.remove.organization.from.user" })}
                            msgDisagree={intl.formatMessage({ id: "cancel" })}
                            msgAgree={intl.formatMessage({ id: "yes" })}
                            handleConfirmation={e => this.orgDeleteModal()}
                            classes={classes}
                            icon={<DeleteIcon />}
                          />
                        </Grid>
                        <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            className={classes.button}
                            startIcon={<AddIcon />}
                            onClick={e => this.openOrgInsertModal()} >
                            {intl.formatMessage({ id: "organization.material.registration.add.organization" })}
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid xs={12} sm={12}
                        style={{
                          marginLeft: 5,
                          marginBottom: 5,
                          border: '3px solid #eee'
                        }} >
                        <div style={{ height: 350 }}>
                          <DataGrid
                            style={{ fontSize: 14, color: '#000' }}
                            className="DataGridStyle"
                            components={{
                              NoRowsOverlay: NoDataRows,
                              Toolbar: GridToolbarFilterButton,
                            }}
                            rows={this.state.OrgList}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            onSelectionModelChange={itm => {
                              if (itm.length > 0) {
                                this.setOrgDelete(itm);
                              } else {
                                this.setState({
                                  OrgListForDelete: [],
                                })
                              }
                            }}
                            columns={[
                              { headerName: intl.formatMessage({ id: "menu.organization" }), field: 'name', width: 500 },
                            ]}
                            HeadersVisibility="None"
                          />
                        </div>
                      </Grid>
                    </Grid>

                  </AccordionDetails>
                </Accordion>

                <Accordion style={{ marginTop: "6px", marginBottom: "10px" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Localizações*</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} xs={12} sm={12} >

                      <Grid xs={12} sm={12} style={{ margin: '5px', padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }} >
                        <Grid xs={12} sm={4} >

                        </Grid>
                        <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                          <DialogConfirmation
                            msgButton={intl.formatMessage({ id: "user.client.edit.remove.location" })}
                            msg={intl.formatMessage({ id: "user.client.edit.confirm.remove.user.location" })}
                            msgDisagree={intl.formatMessage({ id: "cancel" })}
                            msgAgree={intl.formatMessage({ id: "yes" })}
                            handleConfirmation={e => this.locationDeleteModal()}
                            classes={classes}
                            icon={<DeleteIcon />}
                          />
                        </Grid>
                        <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            className={classes.button}
                            startIcon={<AddIcon />}
                            onClick={e => this.openLocationInsertModal()} >
                            {intl.formatMessage({ id: "user.client.edit.add.locations" })}
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid xs={12} sm={12}
                        style={{
                          marginLeft: 5,
                          marginBottom: 5,
                          border: '3px solid #eee'
                        }} >
                        <div style={{ height: 350 }}>
                          <DataGrid
                            style={{ fontSize: 14, color: '#000' }}
                            className="DataGridStyle"
                            components={{
                              NoRowsOverlay: NoDataRows,
                              Toolbar: GridToolbarFilterButton,
                            }}
                            rows={this.state.LocationList}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            onSelectionModelChange={itm => {
                              if (itm.length > 0) {
                                this.setLocationDelete(itm);
                              } else {
                                this.setState({
                                  LocationListForDelete: [],
                                })
                              }
                            }}
                            columns={[
                              { headerName: 'Localizações', field: 'name', width: 500 },
                            ]}
                            HeadersVisibility="None"
                          />
                        </div>
                      </Grid>
                    </Grid>

                  </AccordionDetails>
                </Accordion>

                <Grid container spacing={2}>
                  { organizationUsers.length > 0 && <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete
                        label={intl.formatMessage({id:"default.organization"})}
                        name="defaultorganization"
                        options={organizationUsers}
                        stateName="defaultorganization"
                        changeSelect={this.changeValues}
                        selected={user.defaultorganization}
                      />
                    </Grid> }
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      id="grouped-demo"
                      inputValue={this.state.user.departamentName}
                      value={this.state.user.OrganizationDepartamentId}
                      onInputChange={(event, newInputValue) => {
                        this.setState({
                          user: {
                            ...this.state.user,
                            departamentName: newInputValue,
                          },
                        });
                      }}
                      options={this.state.DepartamentList.sort(
                        (a, b) => -b.order.localeCompare(a.order)
                      )}
                      groupBy={(option) => option.order}
                      getOptionLabel={(option) => option.parentname}
                      style={{ minWidth: "100%", width: "100%" }}
                      onChange={(event, value) => {
                        if (value) {
                          this.setState((prevState) => ({
                            user: {
                              ...prevState.user,
                              OrganizationDepartamentId: value,
                            },
                          }));
                        } else {
                          this.setState((prevState) => ({
                            user: {
                              ...prevState.user,
                              OrganizationDepartamentId: 0,
                            },
                          }));
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ minWidth: "100%", width: "100%" }}
                          label="Departamentos"
                          variant="outlined"
                        />
                      )}
                    />

                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "name" })}
                      inputProps={{ maxLength: 255 }}
                      autoComplete="fname"
                      variant="outlined"
                      name="name"
                      onChange={this.handleInputChange}
                      value={user.name}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "email" })}
                      autoComplete="fname"
                      inputProps={{ maxLength: 255 }}
                      variant="outlined"
                      name="email"
                      onChange={this.handleInputChange}
                      value={user.email}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "document" })}
                      autoComplete="fname"
                      inputProps={{ maxLength: 255 }}
                      variant="outlined"
                      name="document"
                      onChange={this.handleInputChange}
                      value={user.document}
                      InputLabelProps={{ shrink: true }}
                      error={itemError.document?.length > 0}
                      helperText={itemError.document}
                    />
                  </Grid>
                  <Grid item sm={12} md={12} lg={6} >
                    <SimpleSelect
                      label={`Localidade (estado)`}
                      name="state"
                      options={this.state.statesList}
                      stateName="state"
                      changeSelect={this.changeValues}
                      selected={user.state} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleSelect
                      label={intl.formatMessage({ id: "gender" })}
                      name="gender"
                      options={this.state.genderList}
                      stateName="gender"
                      changeSelect={this.changeValues}
                      selected={user.gender}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <PhoneInput
                      inputStyle={{
                        padding: '23.5px 14px 23.5px 58px',
                        width: '100%'
                      }}
                      style={{
                        marginTop: 16,
                        marginBottom: 8
                      }}
                      id="phone"
                      name="phone"
                      country={'br'}
                      value={user.phone}
                      onChange={this.handleChangePhone}
                      InputLabel={intl.formatMessage({ id: "phone" })}
                      specialLabel={intl.formatMessage({ id: "phone" })}
                      inputProps={{
                        name: intl.formatMessage({ id: "cellphone" })
                      }} />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <TextField
                      label={intl.formatMessage({ id: "called.distance.value" })}
                      variant="outlined"
                      value={user.kmvaluerotated}
                      onChange={this.handleInputChange}
                      name="kmvaluerotated"
                      id="formatted-numberformat-input"
                      InputProps={{
                        inputComponent: this.NumberFormatCustom,
                      }}
                      autoComplete="fname"
                      required={user.isCostControlObrigatory}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} style={{ paddingLeft: 16, marginTop: 10, marginLeft: 5, marginBottom: 5 }}>
                    <Typography component='h1' variant='h5'>
                      {intl.formatMessage({ id: "cost.parameter" })}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={2} style={{ paddingLeft: 16 }}>
                    <SimpleCheckBox icon="true" changeSelect={this.changeValues} stateName='perhour' fullWidth label='Por hora' autoComplete='fname' name="perhour" selected={user.perhour} variant='outlined' />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <SimpleCheckBox icon="true" changeSelect={this.changeValues} stateName='calledunit' fullWidth label='Por unidade de chamado' autoComplete='fname' name="calledunit" selected={user.calledunit} variant='outlined' />
                  </Grid>
                  <Grid item xs={12} sm={4}></Grid>

                  {this.state.user.calledunit && (
                    <Grid item xs={3} sm={3}>
                      <TextField
                        label={intl.formatMessage({ id: "value.per.called" })}
                        variant="outlined"
                        value={user.valueforcalled}
                        onChange={this.handleInputChange}
                        name="valueforcalled"
                        id="formatted-numberformat-input"
                        InputProps={{
                          inputComponent: this.NumberFormatCustom,
                        }}
                        autoComplete="fname"
                        required={user.isCostControlObrigatory}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>)}

                  {this.state.user.perhour && (
                    <Grid item xs={3} sm={3}>
                      <TextField
                        label={intl.formatMessage({ id: "called.employee.hour.value" })}
                        variant="outlined"
                        value={user.employeehourvalue}
                        onChange={this.handleInputChange}
                        name="employeehourvalue"
                        id="formatted-numberformat-input"
                        InputProps={{
                          inputComponent: this.NumberFormatCustom,
                        }}
                        autoComplete="fname"
                        required={user.isCostControlObrigatory}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>)}

                  {(user.UserRoles && ((user.UserRoles.length == 1 && user.UserRoles[0] != 4) || user.UserRoles.length > 1)) &&
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete
                        label={intl.formatMessage({ id: "menu.workhours" })}
                        name="WorkHourId"
                        options={this.state.WorkHoursList}
                        stateName="WorkHourId"
                        changeSelect={this.changeValues}
                        selected={user.WorkHourId}
                        required
                      />
                    </Grid>}
                  <Grid item xs={12} sm={12}>
                    <SimpleSelect
                      label={intl.formatMessage({ id: "startpage" })}
                      name="startPage"
                      options={this.state.startPageItems}
                      stateName="startPage"
                      changeSelect={this.changeValues}
                      selected={user.startPage}
                      required
                    />
                  </Grid>
                  <Grid item xs={10} sm={11.5}>
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "unit" })}
                      name="unit"
                      options={this.state.unitList}
                      stateName="unitId"
                      changeSelect={this.changeValues}
                      selected={user.unitId}
                      required={user.isUnityObrigatory}
                    />
                  </Grid>
                  <Grid item xs={2} sm={0.5} alignSelf="center">
                    <Tooltip title="Utilizado para classificar usuário">
                      <Help />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleCheckBox
                      label={intl.formatMessage({ id: "can.user.view.archived.calleds" })}
                      name={intl.formatMessage({ id: "can.user.view.archived.calleds" })}
                      stateName="canUserViewArchivedCalleds"
                      changeSelect={this.changeValues}
                      selected={user.canUserViewArchivedCalleds}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleCheckBox
                      label={intl.formatMessage({ id: "active" })}
                      name={intl.formatMessage({ id: "active" })}
                      stateName="active"
                      changeSelect={this.changeValues}
                      selected={user.active}
                    />
                  </Grid>
                  {checkIsActive === true && (
                    <Grid item xs={12} sm={12}>
                      <SimpleCheckBox
                        label={intl.formatMessage({ id: "can.create.calleds.in.2move" })}
                        name={intl.formatMessage({ id: "can.create.calleds.in.2move" })}
                        stateName="canCreateCalledInMobile"
                        changeSelect={this.changeValues}
                        selected={user.canCreateCalledInMobile}
                      />
                    </Grid>)}
                  { <Grid item xs={12} sm={12} container direction='row'>
                      <Typography>
                        {<FormattedMessage id="external"/>}
                      </Typography>
                      <Typography marginLeft={1}>
                        {<FormattedMessage id={user.isExternal ? 'yes' : 'no'}/>}
                      </Typography>
                    </Grid>}
                  {(user.UserRoles && user.UserRoles.filter(r => r == 4).length > 0) && (
                    <Grid item xs={10} sm={8}>
                      <SimpleCheckBox
                        label={intl.formatMessage({ id: "customer.can.join.another.customers.call" })}
                        name={intl.formatMessage({ id: "customer.can.join.another.customers.call" })}
                        stateName="canInteractWithCalledWhereAreNotRequesterOrAssigned"
                        changeSelect={this.changeValues}
                        selected={user.canInteractWithCalledWhereAreNotRequesterOrAssigned}
                      />
                    </Grid>)}
                  {(user.UserRoles && user.UserRoles.filter(r => r == 4).length > 0) && <Grid item xs={2} sm={4} style={{ textAlign: 'right', alignSelf: 'stretch' }}>
                    <Tooltip title={intl.formatMessage({ id: "customer.can.join.another.customers.call.info" })}>
                      <Help />
                    </Tooltip>
                  </Grid>}
                  <Grid  item xs={12} sm={12} style={{ paddingLeft: 16 }}>
                    <Typography component="h5" variant="h5">
                      {<FormattedMessage id="user.profile" />}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <MyCheckList
                      label={intl.formatMessage({ id: "org.group" })}
                      name="UserRoles"
                      optionsText={this.getRolesNames(this.state.RoleList)}
                      options={this.getRolesIds(this.state.RoleList)}
                      stateName="UserRoles"
                      changeSelect={this.changeValues}
                      selected={user.UserRoles}
                    />
                  </Grid>


                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                    <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={3} style={{ margin: "5px" }}>
                        <Button
                          onClick={this.handleSubmitActiveSaaS}
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                        >
                          {intl.formatMessage({ id: "enable.on.2move" })}
                        </Button>
                      </Grid>

                      <Grid item xs={12} sm={4} style={{ margin: "5px" }}>
                        <Button
                          onClick={this.handleClick}
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                        >
                          {intl.formatMessage({ id: "resend.activation.email" })}
                        </Button>
                      </Grid>

                      <Grid item xs={12} sm={3} style={{ margin: "5px" }}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                        >
                          {<FormattedMessage id="save" />}
                        </Button>

                      </Grid>
                    </Grid>
                  </Grid>


                  {this.state.user.twoFactorAuthenticationActive && (
                    <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={6} style={{ marginTop: "8px" }}>
                        <Button
                          onClick={this.disableTwoFactorAuthenticaion}
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                        >
                          {intl.formatMessage({ id: "twoFactorAuth.disable" })}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />

            <DialogAddMultiple
              title={intl.formatMessage({ id: "organization.material.registration.new.organizations" })}
              selected={this.state.user.OrganizationId}
              options={this.state.OrgListForSelect}
              checked={this.state.selectOrganizations}
              changeValues={this.changeValues}
              open={this.state.openOrgInsertModal}
              close={this.closeOrgModal}
              save={this.saveOrgInsertModal}
              intl={intl}
              checkboxLabel={intl.formatMessage({ id: "technical.team.edit.select.all.organizations" })}
              checkboxStateName='selectOrganizations'
              autoCompleteLabel={intl.formatMessage({ id: "organization" })}
              autoCompleteStateName="OrganizationId"
            />
            
            <DialogAddMultiple
              title={intl.formatMessage({ id: "new.locations" })}
              selected={this.state.user.Location}
              options={this.state.LocationListForSelect}
              checked={this.state.selectLocations}
              changeValues={this.changeValues}
              open={this.state.openLocationInsertModal}
              close={this.closeLocationModal}
              save={this.saveLocationInsertModal}
              intl={intl}
              checkboxLabel={intl.formatMessage({ id: "select.all.locations" })}
              checkboxStateName='selectLocations'
              autoCompleteLabel={intl.formatMessage({ id: "locations" })}
              autoCompleteStateName="LocationId"
            />

            <Dialog
              fullWidth
              maxWidth="md"
              open={this.state.openLocationInsertModal}
              onClose={this.closeLocationModal}
              aria-labelledby="form-dialog-title-org-mat"
              PaperProps={{
                style: {
                  overflow: 'hidden'
                }
              }} >
              <DialogTitle id="form-dialog-title-org-mat">
                {intl.formatMessage({ id: "new.locations" })}
                <ClearIcon onClick={this.closeLocationModal} style={{ float: 'right', cursor: 'pointer' }} />
              </DialogTitle>
              <Grid item xs={12} sm={12} >
                <Grid container spacing={4} style={{ padding: "5px" }}>
                  <Grid item xs={12} sm={12} >
                    <SimpleCheckBox label={intl.formatMessage({ id: "select.all.locations" })} name='selectLocations' stateName='selectLocations' changeSelect={this.changeValues} selected={this.state.selectLocations} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    < SimpleAutoCompleteMultiple
                      label="Localizações"
                      options={this.state.LocationListForSelect}
                      stateName="LocationId"
                      changeSelect={this.changeValues}
                      selected={this.state.user.Location}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid spacing={2} container justifyContent="flex-end" style={{ padding: 15 }}>
                <Grid item xs={12} sm={4}>
                  <Button
                    type="button"
                    onClick={this.closeLocationModal}
                    fullWidth >
                    {intl.formatMessage({ id: "cancel" })}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={this.saveLocationInsertModal}
                  >
                    {<FormattedMessage id="save" />}
                  </Button>
                </Grid>
              </Grid>
            </Dialog>

          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}
UserEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  users: state.users,
  addresses: state.addresses,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(UserEdit))
);
