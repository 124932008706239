import React, { memo, useCallback, useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { PapiroConsole } from '../../../utils/papiroConsole'
import '../styles.css'
import { Grid, Typography, Tooltip, IconButton } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';

export function DndContainer(props) {
  const [organizationFieldBlockList, setOrganizationFieldBlockList] = useState(props.organizationFieldBlockList)

  useEffect(() => {
    setOrganizationFieldBlockList(props.organizationFieldBlockList)
  }, [props.organizationFieldBlockList])

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(organizationFieldBlockList)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setOrganizationFieldBlockList(items)
    props.setOrganizationFieldBlockList(items)
  }

  const handleOpenEditOrganizationFieldBlockDialog = (item) => {
    PapiroConsole.log("=== handleOpenEditOrganizationFieldBlockDialog ===")
    PapiroConsole.log(item)
    props.openEditOrganizationFieldBlockDialog(item)
  }

  const handleOpenDeleteOrganizationFieldBlockDialog = (id, title) => {
    PapiroConsole.log("=== handleOpenDeleteOrganizationFieldBlockDialog ===")
    PapiroConsole.log(`${id}: ${title}`)
    props.openDeleteOrganizationFieldBlockDialog(id, title)
  }


  const handleOpenConfigureOrganizationFieldBlockDialog = (item) => {
    PapiroConsole.log("=== handleOpenConfigureOrganizationFieldBlockDialog ===")
    PapiroConsole.log(item)
    props.openConfigureOrganizationFieldBlockDialog(item)
  }

  return (
    <Grid item xs={12} sm={12} style={{ border: '1px dashed rgba(0, 0, 0, .6)', borderRadius: 10, backgroundColor: '#eee' }}>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId='orgfieldblocks'>
        {(provided) => (
          <ul className='orgfieldblocks' {...provided.droppableProps} ref={provided.innerRef} >
        {organizationFieldBlockList.map((item, index) => {
          return (<Draggable key={item.id} draggableId={`${item.id}`} index={index}>
          {(provided) => (
            <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              <Grid container spacing={0} >
                <Grid item xs={9} md={9} style={{ display: 'flex', alignItems: 'center' }} >
                  <Typography style={{ fontWeight: 'normal' }} variant="body1">{ item.title }</Typography>
                </Grid>
                <Grid item xs={1} md={1}>
                  <Tooltip title={<h5><FormattedMessage id="called.report.config.organization.field.block.configuration" /></h5>}>
                    <IconButton aria-label='config-org-field-blocks'
                      onClick={() => {handleOpenConfigureOrganizationFieldBlockDialog(item)}} >
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={1} md={1}>
                  <Tooltip title={<h5><FormattedMessage id="edit" /></h5>}>
                    <IconButton aria-label='edit'
                      onClick={() => {handleOpenEditOrganizationFieldBlockDialog(item)}} >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={1} md={1}>
                  <Tooltip title={<h5><FormattedMessage id="delete" /></h5>}>
                    <IconButton aria-label='delete'
                      onClick={() => {handleOpenDeleteOrganizationFieldBlockDialog(item.id, item.title)}} >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </li>
          )}
          </Draggable>)
        })}
            {provided.placeholder}
          </ul>
          )}
        </Droppable>
      </DragDropContext>
    </Grid>
  )
}