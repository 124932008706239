

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Styles } from '../../../styles/material-styles';
import { AbilityContext } from '../../../config/ability-context'
import * as Service from "../../../services/user.service";
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import { getOrganizations } from "../../../services/called.service";
import { getOrganizationsByRole } from "../../../services/called.service";
import Api from "../../../services/api"

import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Overlay } from "../../../styles/global"
import CustomizedSnackbars from "../../../components/material-snackbars"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { CircularProgress } from "@mui/material"
import {
  PieChart, Pie, Sector, Cell,BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Text
} 

from 'recharts';
import { injectIntl } from 'react-intl';




class OrganizationsTab extends Component {
    constructor(props) {
    super(props);

    this.state = {
        GroupList: [],
        OrganizationList :[],
        loading: false,
       
      }

      this.pieChart = null


    console.log(this.props)
    console.log("props aqui")
    }

componentWillReceiveProps(nextProps){

}    

async componentDidMount() {


   // await this.getAllGrous();
    //await this.getAllOrganizations();

    await this.getCalledsBygroup();
}

renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

gerar_cor_hexadecimal()
{
  return '#' + parseInt((Math.random() * 0xFFFFFF))
    .toString(16)
    .padStart(6, '0');
}

getPercent(value, arraytotal)
{

  var total = 0
  arraytotal.forEach(element => {
          total += element.value
    });

    if(total == 0){
      return 0
    }


    return ((value * 100) / total).toFixed(2);

}

async getAllGrous() {
    this.setState({ loading: true });
    var groups = await Service.getGroups();
    this.setState({ loading: false, GroupList: groups });
    console.log("grupos aqui ")
    console.log(groups)
  }


  async getAllOrganizations() {
    this.setState({ loading: true });
    //var groups = await Service.getOrganizations();
    const { userSession } = this.props
    let orgData = []
    if(userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
      orgData = userSession.user.userorganizations
    } else {
      const result = await getOrganizationsByRole()
      if (result && result.data) {
        orgData = result.data
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            result.response && result.response.data && result.response.data.errors
              ? result.response.data.errors[0]
              : intl.formatMessage({ id: "process.error" }),
        });
        Api.kickoff(result);
      }
    }
    
    if(orgData != null) {
      this.setState({ loading: false, OrganizationList: orgData });
    }

  }

  customizedAxisTick = ({
    x, y, payload,
  }) => {
    
    return <Text x={x} y={y} width={70} style={{fill:'#808080', fontSize:'11px', }} textAnchor="middle" verticalAnchor="start">{payload.value}</Text>
  };
  


  async getCalledsBygroup() {
    const intl = this.props.intl;
    const { classes } = this.props
    this.setState({ loading: true });

    


    var organizationsreponse = this.props.slacalleds.organizationsreponse

    if(organizationsreponse!= null){


      if(organizationsreponse.length > 0 ){


        var arrayTable = null
        if(organizationsreponse.length > 10)
        {
          arrayTable = organizationsreponse.slice(0, 9)
        }
        else{
          arrayTable=[...organizationsreponse]
        }


        
        this.pieChart = 
        <div>


            <ResponsiveContainer width="95%" height={400} >
                
                <BarChart 
                    
                    margin={{ bottom: 80}}
                    data={arrayTable}

                    barSize={20}
                  >
                    <XAxis tick={<this.customizedAxisTick />} dataKey="name"  padding={{ left: 10, right: 10 }} interval={0}/>
                    <YAxis allowDecimals =  {false}  />
                    <Tooltip />
                   
                    <CartesianGrid strokeDasharray="3 3" />
                    <Bar dataKey="value" fill="#8884d8" background={{ fill: '#eee' }} />
                </BarChart>
                
                
            </ResponsiveContainer>
          
            <div >

            <Accordion style={{pointerEvents:"none"} }>
              <AccordionSummary style={{pointerEvents:"none"} } >
                <Typography className={classes.heading}>{intl.formatMessage({id:"calleds.by.organizations"})}</Typography>
              </AccordionSummary>
                               
            </Accordion>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table" >
                  <TableBody style={{ border: 1, borderColor: 'black', borderStyle: 'solid' }}>
                    <TableRow>
                       
                          <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor:'#8884d8' ,color : 'white' }}>{intl.formatMessage({id:"organization.name"})}</TableCell>
                          <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor:'#8884d8' ,color : 'white' }}>{intl.formatMessage({id:"amount.calleds"})}</TableCell>
                          <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', borderStyle: 'solid', backgroundColor:'#8884d8' ,color : 'white' }}>{intl.formatMessage({id:"percentage"})}</TableCell>
                    </TableRow>
                   {
                      organizationsreponse.map((entry, index) => (
                        <TableRow>
                         
                          <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey',backgroundColor:'#f2f2f2',  borderStyle: 'solid' }}>{entry.name}</TableCell>
                          <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey',backgroundColor:'#f2f2f2', borderStyle: 'solid' }}>{entry.value}</TableCell>
                          <TableCell align="center" style={{ border: 1, borderColor: 'darkgrey', backgroundColor:'#f2f2f2', borderStyle: 'solid' }}>{this.getPercent(entry.value, organizationsreponse)}%</TableCell>
                      </TableRow>
                      ))
                   }
                    


                  </TableBody>
                </Table>
              </TableContainer>

            </div>

            
        </div>





        this.setState({ loading: false });


      }


      

        

        

       


        

    }
    this.setState({ loading: false });

  }

render() {

   
        return (
        

            <div>
           
                        {this.pieChart}
                        {this.state.loading && (
                          <Overlay>
                              <CircularProgress color="secondary" />
                          </Overlay>
                          )}

                          <CustomizedSnackbars
                              variant={this.state.notificationVariant}
                              message={this.state.notificationMessage}
                              isOpen={this.state.openNotification}
                              toClose={this.closeNotification} />
                      </div>
        )
    }
}

OrganizationsTab.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
headerMenu: state.headerMenu,
userSession: state.userSession,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(OrganizationsTab)));
OrganizationsTab.contextType = AbilityContext;