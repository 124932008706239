import Api2Talk from "../api2talk";

class ChatClassificationService {

  PATH = '/chatclassifications';

  async listAllPaginate(page, paramSearch = "") { 
    try {
      const response = await Api2Talk.get(`${this.PATH}/paginate?page=${page}${paramSearch}`);
      return response.data;
    } catch (err) {
      throw err;
    }
  };

  async getById(calledClassificationId) {
    try {
      const response = await Api2Talk.get(`${this.PATH}/${calledClassificationId}`);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

  async update(input) {
    try {
      const response = await Api2Talk.put(`${this.PATH}`, input);
      return response.data;
    } catch (err) {
      throw err;
    }
  }

}

export default ChatClassificationService;
