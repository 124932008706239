import React, { Component } from 'react';
                import { connect } from 'react-redux';
                import { bindActionCreators } from 'redux';
                import PropTypes from 'prop-types';
                import { withStyles } from '@mui/styles';
                import { Styles } from '../../styles/material-styles';
                import { AbilityContext } from '../../config/ability-context'
                import Base from '../../components/layout-base/base';
                import AddressContent from './index.content';

                    class Addresses extends Component {

                    constructor(props) {
                        super(props);

                    }

                    componentWillReceiveProps(nextProps){
                    }    

                    componentDidMount() {
                    }
                     render() {

                        return (
                           
                            <Base props={this.props}>
                                   <AddressContent props={this.props} />

                            </Base>        
                        );
                    }
                }
Addresses.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                });

            export default connect(mapStateToProps)(withStyles(Styles)(Addresses));
            Addresses.contextType = AbilityContext;