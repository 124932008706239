
import Api2Talk from "../api2talk";

export const getChannelList = async () => { 
  let data = [];
  await Api2Talk.get("channel")
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const GetAllActiveAndByMessageProvider = async (messageProviderId) => { 
  let data = [];
  await Api2Talk.get(`channel/getbymessageproviderid/${messageProviderId}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getPhonesAssociations = async (channelId) => { 
  let data = [];
  await Api2Talk.get(`channelregistrationnumberrequest/getphonesassociationsbychannel/${channelId}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};