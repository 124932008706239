export function addCurrent(organizationdepartament) {
    return {
        type: 'ADD_CURRENT',
        organizationdepartament,
    }
}

export function addCurrentOrganization(organizationdepartament,organization) {
    return {
        type: 'ADD_CURRENT_ORGANIZATION',
            organizationdepartament,
            organization,
    }
}