import React from 'react'
import { SvgIcon } from '@mui/material';

export default function TextFieldCheckIcon(props) {
  return (
    <SvgIcon {...props}>
      <g id="Grupo_1" data-name="Grupo 1" transform="translate(-912 -481)">
        <path id="Check" d="M7.034,12.448l-2.7-2.7-.921.914,3.624,3.624L14.813,6.5,13.9,5.59Z" transform="translate(921.187 483.064)"/>
        <g id="TextFields" transform="translate(912 481)">
          <path id="a" d="M16.6,24H0V0H16.6Z" fill="none"/>
          <path id="Caminho_2" data-name="Caminho 2" d="M2.5,4V7H5.552V19H7.383V7h3.052V4ZM14.1,9H8.6v3h1.831v7h1.831V12H14.1Z" transform="translate(0 0)"/>
        </g>
      </g>
    </SvgIcon>
  );
}

