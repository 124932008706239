import React, { Component } from 'react';
import clsx from 'clsx';

import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import MyMaterialTable from '../../components/table/index-report';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from "moment";


import CustomizedSnackbars from '../../components/material-snackbars';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as componentActions from '../../redux/actions/npssearchs';



import AddIcon from '@mui/icons-material/Add';

class AnswerContet extends Component {

    constructor(props) {
        super(props);

        const { userSession, calleds } = this.props;

        console.log(props)
        console.log("props da resposta aqui")

        var getArray = []
        if(props.npssearchs.npssearch.userorganizationnpses){
            getArray = [...props.npssearchs.npssearch.userorganizationnpses]
        }

        this.state = {
            
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            resultList: getArray,
        };

        this.closeNotification = this.closeNotification.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    componentDidMount() {

        
        
    }

    

    closeNotification() {
        this.setState({ openNotification: false });
    }
    render() {
        const { classes } = this.props;
        const intl = this.props.intl;

        return (
            <>
                <MyMaterialTable
                    title={intl.formatMessage({ id: "calledStatus.header" })}
                    columns={[
                        { title: intl.formatMessage({id:"name"}), field: 'user.name' },
                        { title: "Email", field: 'useremail' },
                        { title: "Nota", field: 'note' },
                        { title: "Mensagem", field: 'message' },
                        
                    ]}
                    data={this.state.resultList}
                />

                {this.state.loading && (
                    <Overlay>
                        <CircularProgress color='secondary' />
                    </Overlay>
                )}
            </>
        );
    }
}
AnswerContet.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    npssearchs: state.npssearchs,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(AnswerContet)));

AnswerContet.contextType = AbilityContext;