import React, { useEffect } from 'react'
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import { renderToStaticMarkup } from "react-dom/server"
import { AttachFile } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import * as FileService from '../../../../../services/attachment.service';
import { PapiroConsole } from '../../../../../utils/papiroConsole';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '8px',
    padding: '10px',
    border: '1px solid rgb(1, 67, 97)',
    backgroundColor: 'rgb(229, 246, 253)',
    color: '#000',
    marginBottom : '10px',
  }
}));

function ChatNote(props) {
  const classes = useStyles();
  const [content, setContent] = React.useState("")

  const getFileName = (fileName) => {
    var file= fileName.substring(fileName.lastIndexOf('/')+1);
    return file;
  }

  const getFile = async (hirerid, fileName,filerepositorymethod) => {
    
	const intl = props.intl;
  props.setLoading(true);

 PapiroConsole.log(hirerid)
 PapiroConsole.log(fileName)
 PapiroConsole.log(filerepositorymethod)
 PapiroConsole.log("hirerid,fileName,filerepositorymethod")
 PapiroConsole.log(props)
	
	let url = await FileService.getFileByPath(hirerid,fileName,filerepositorymethod); 
	//alert(JSON.stringify(url.data));
	props.setLoading(false);
	if(url &&  url.data && url.data.data && url.data.success){
		window.open(`${url.data.data}`, '_blank');
	}
	else{
    props.setLoading(false);
    props.setOpenNotification(true);
    props.setNotificationMessage("Arquivo não encontrado");
    props.setNotificationVariant("error");

	}	

  };

  useEffect(() => {
    let contentRendered = props.content
    if (props.content.trim().startsWith("<p> &lt;h") || props.content.trim().startsWith("<p>&lt;div")) {
      contentRendered = renderToStaticMarkup(props.content)

    } else if (props.content.trim().startsWith("<div style=\"") || props.content.trim().startsWith("<div dir=") || props.content.trim().startsWith("<p>") || props.content.trim().startsWith("<h1>") || props.content.trim().startsWith("<h2>") || props.content.trim().startsWith("<h3>")) {
      contentRendered = DOMPurify.sanitize(props.content)
    }
    setContent(contentRendered)
  }, [props.content])

  

  return (<div className={clsx(classes.root)}>
    <label>Descrição</label>
    {parse(content)}
    <Grid container style={{ marginTop: '10px' }}>
      <Grid item xs={12}>
        {props.attachments && props.attachments.length > 0 ?
          <label>Anexos</label> 
        : null}
        
      </Grid>
      <Grid item xs={12} >
        {props.attachments && props.attachments.length > 0 ? props.attachments.map((entry, index) => (

          <Grid container key={props.attachments.path}>
            <Grid item xs={1}>
              <AttachFile />
            </Grid>
            <Grid item xs={11}>
              <Button onClick={() => getFile(entry.attachment.hirerid, entry.attachment.path, entry.attachment.filerepositorymethod)} style={{ maxWidth: '100%', overflow: 'hidden' }} >{getFileName(entry.attachment.description)} </Button>
            </Grid>
           

          </Grid>

        ))
          : null
        }
      </Grid>
    </Grid>

  </div>)
}

export default ChatNote