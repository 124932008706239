import React, { useState, useEffect } from 'react';
import { makeStyles, styled } from '@mui/styles'
import { TextField, IconButton, Box, InputAdornment } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import Api2Talk from "../../../services/api2talk";
import Api from "../../../services/api";
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";
import { PapiroConsole } from '../../../utils/papiroConsole';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: theme.spacing(2),
      boxSizing: 'border-box',
      border: `1px solid grey`,
    },
    messagesContainer: {
      flex: 1,
      overflowY: 'scroll',
      marginBottom: 2,
      flexDirection: 'column-reverse',
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: 30,
      marginLeft: 15,
    },
    messageWrapper: {
      marginTop: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
      marginBottom: 10,
      
    },
    rightMessageWrapper: {
      alignItems: 'flex-end'
    },
    message: {
      borderRadius: 4,
      maxWidth: '70%',
      padding: 5,
      backgroundColor: '#A8C8D6',
      minWidth: 150,
      textAlign: 'left',
      fontSize: 16,
      color: '#525252',
      marginLeft: '10px',
      '&::before': {
        content: '""',
        position: 'relative',
        top: '-2px',
        right: '0px',
        left: '-10px',
        width: 2,
        height: '0px',
        border: '5px solid #A8C8D6',
        borderLeftColor: 'transparent',
        borderBottomColor: 'transparent'
      },
    },
    rightMessage: {
      backgroundColor: '#CDDFE7',
      marginRight: 15,
      '&::after': {
        content: '""',
        position: 'relative',
        display: 'inline-block',
        float: 'right',
        bottom: 0,
        right: 0,
        width: 2,
        height: 0,
        top: -15,
        border: '5px solid #CDDFE7',
        borderRightColor: 'transparent',
        borderBottomColor: 'transparent',
        boxShadow: 'none',
        marginRight: '-12px',
        marginTop: '10px'
      },
      '&::before': { display: 'none' }
    },
    inputWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 1
    },
    input: {
      flex: 1,
      borderRadius: 60,
      background: '#3F51B4',
      color: 'white'
    },
  }));  

  const CustomTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
      borderRadius: '20px',
      background: '#3F51B4',
      color: '#FFF',
      width: '100%',
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
      
    }
  });

const Chat = ({messages, setMessages, phoneNumber, phoneSender}) => {

  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [connection, setConnection] = useState(null);
  const userid = localStorage.getItem('userid');

  useEffect(() => {

    PapiroConsole.log("conexão 1 2 3")
    const connection = new HubConnectionBuilder()
        .withUrl(`${Api.apiurl()}/chat`, 
        { accessTokenFactory: () => localStorage.getItem('accesstoken'), 
          skipNegotiation: true,
			// eslint-disable-next-line no-undef
			    transport: HttpTransportType.WebSockets })
        .build();

    setConnection(connection);
  }, []);

  useEffect(async () => {
    if (connection) {
      await connection.start().then(() => {
        connection.on("ReceiveNotification", (message) => {
          const newMessage = {
            sender: 'Teste',
            content: message,
            id: Date.now(),
          };
          setMessages(newMessage);
        })

      }).catch(() => console.log("Não foi possível conectar"));
    }
  }, [connection]);

  const sendMessage = (message) => {
    const data = { content: message.content, to: phoneSender, idphonenumber: phoneNumber?.id };
    console.log('phoneSender')
    console.log(data)
    if(Api2Talk == null){
      this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: "Api de mensageria não configurada."
      });
      return;
    }   

    Api2Talk.post(`/message`, data)
      .then(result => {
          if (result.data.success) {
            console.log("sucesso ao enviar")
          }
      })
      .catch(err => {
        console.log(err)
      });
  }

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      const newMessage = {
        sender: 'Your',
        content: inputValue,
        id: Date.now(),
      };
      setMessages(newMessage);
      setInputValue('');

      sendMessage(newMessage);
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.messagesContainer}>
        {messages.slice(0).reverse().map((message) => (
          <Box
            key={message.id}
            className={`${classes.messageWrapper} ${
              message.sender === 'Your' ? classes.rightMessageWrapper : ''
            }`}
          >
            <Box
              className={`${classes.message} ${
                message.sender === 'Your' ? classes.rightMessage : ''
              }`}
            >
              {message.content}
            </Box>
          </Box>
        ))}
      </Box>
      <Box className={classes.inputWrapper}>
        <CustomTextField
            placeholder="Mensagem"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleInputKeyDown}
            size="small"
            hiddenLabel="true"
            style={{width: '100%'}}
            InputProps={{
                endAdornment: (
                <InputAdornment position="end">
                    <IconButton>
                    <AttachFileIcon sx={{color: "white "}} />
                    </IconButton>
                </InputAdornment>
                ),
                startAdornment: (
                    <InputAdornment position="start">
                    <IconButton>
                        <EmojiEmotionsIcon sx={{color: "white"}}  />
                    </IconButton>
                    </InputAdornment>
                ),
            }}
        />
        <IconButton
          color="primary"
          disabled={!inputValue}
          onClick={() => {
            const newMessage = {
              sender: 'Your',
              content: inputValue,
              id: Date.now(),
            };
            setMessages(newMessage);
            setInputValue('');
      
            sendMessage(newMessage);
          }}
        >
          <SendIcon fontSize="large" sx={{color: "##3852cf"}}  />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Chat;
