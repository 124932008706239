import { Button, Grid } from '@mui/material'
import { withStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as componentActions from '../../../../redux/actions/chats'; // trocar pelo redux que criarei no chat
import { Styles } from '../../../../styles/material-styles'
import { PapiroConsole } from '../../../../utils/papiroConsole'
import './styles.css'

function ChatInboxHeader(props) {

  console.log(props)
  PapiroConsole.log("props aqui no chatinbox")

  const { statusOptions, userStatusId, changeStatus, redirectprops } = props;

  const [statusList, setStatusList] = React.useState(statusOptions)
  const [userStatusIdSelected, setUserStatusIdSelected] = React.useState(userStatusId)



  const changeValues = async (stateName, value, text = '') => {

    if (stateName === "status") {
      if (!isNaN(value)) {
        setUserStatusIdSelected(value)
        changeStatus(value)
      }

      else if (value && value.id && !isNaN(value.id)) {
        setUserStatusIdSelected(value.id)
        changeStatus(value.id)
      }

    }

  }

  const backToPreviousPage = () => {

    PapiroConsole.log(redirectprops)
    PapiroConsole.log("redirectprops")

    if (redirectprops && redirectprops.match && redirectprops.history) {
      const { lang } = redirectprops.match.params
      redirectprops.history.push(`/${lang}/chat-attendance-list` )
    }

  }

  return (<div className="omnichannel-chat-inbox-header" style={{ width: '100%' }}>
    <Grid container spacing={2}>
      <Grid item xs={4} sm={4} md={3} lg={2}  xl={0.8}>
        <Button variant='outlined' color='primary' onClick={backToPreviousPage}  >{<FormattedMessage id="back" />}</Button>
      </Grid>
      <Grid item xs={8} sm={8} md={7} lg={8} xl={9.2} className="omnichannel-chat-inbox-header-name" >
      
          <span style={{paddingLeft: '2rem'}}>
            Conversa {props.chats.chat? props.chats.chat?.id : null} - {props.chats.chat && props.chats.chat.customer ? props.chats.chat.customer.name : null} 
          </span>
 
      </Grid>
      {/*<Grid className='omnichannel-chat-inbox-header-status' item xs={12} sm={12} md={2} lg={2} xl={2}>
        <SimpleSelectWithRoundedColor
          options={statusOptions ? statusOptions : statusList}
          stateName="status"
          changeSelect={changeValues}
          selected={props.userStatusId ? userStatusId : userStatusIdSelected} />
</Grid>*/}
    </Grid>
  </div>)
}

ChatInboxHeader.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  chats: state.chats,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ChatInboxHeader)))