import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import MoneyIcon from '@mui/icons-material/Money';
import { FormattedMessage,injectIntl } from 'react-intl';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Steps from './steps';
import Api from "../../../../services/api";

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceIconSucess: {
    color: 'green'
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

const Budget = props => {
  const { className, total, comparation, positiveMonth, organization, save, reset, orgId, attNotification } = props;
  const intl = props.intl;
  const classes = useStyles();
  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item sm={4} xs={4}>
            <Typography>
              {intl.formatMessage({id:"initial.configs"})}
            </Typography>
          </Grid>
          
          <Grid container>
            <Grid item xs={4} sm={4} style={{textAlign: 'left'}}>
              <Typography variant={'h6'}> {intl.formatMessage({id:"tutorial.progress.typography.initial.configs"})}</Typography>
            </Grid>
            
            <Grid item sm={4} xs={4} style={{textAlign: 'center'}}>
              <Typography>
                {props.name}
              </Typography>
            </Grid>

            <Grid sm={4} xs={4} />
          </Grid>
          <Grid sm={4} xs={4} />
          <Grid container style={{textAlign: 'center'}}>
            <Steps attNotification={attNotification} save={save} organization={organization} reset={reset} orgId={orgId}  />
          </Grid>

        </Grid>
        
        {/*
        <div className={classes.difference}>
          {positiveMonth && (
            <ArrowUpwardIcon className={classes.differenceIconSucess} />
          )}
          {!positiveMonth && (
            <ArrowDownwardIcon className={classes.differenceIcon} />
          )}
          <Typography
            className={positiveMonth ? classes.differenceIconSucess : classes.differenceIcon}
            variant="body2"
          >
          {comparation}%
          </Typography>
          <Typography
            className={classes.caption}
            variant="caption"
          >
            &nbsp; {<FormattedMessage id="relation.last.mounth" />}
          </Typography>
        </div>
          */}
      </CardContent>
    </Card>
  );
};

Budget.propTypes = {
  className: PropTypes.string,
  total: PropTypes.number,
  comparation: PropTypes.number,
  positiveMonth: PropTypes.bool,
  save: PropTypes.func,
  organization: PropTypes.string,
  reset: PropTypes.func,
  orgId: PropTypes.string,
  name: PropTypes.string,
  attNotification: PropTypes.func
};

export default injectIntl(Budget);
