import Api from './api';




export const getAll = async () => { 
  let data = []
  await Api.get(`/states/all`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

