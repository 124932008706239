import React, { memo, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider'
import { BarChart, ComposedChart, Bar, Line, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { PapiroConsole } from '../../../../utils/papiroConsole';
import { Checkbox, FormControlLabel, FormGroup, IconButton, Menu, MenuItem } from '@mui/material';
import {MoreVert } from '@mui/icons-material';

const MontlySolutionTimeAverage = memo(function MontlySolutionTimeAverage(props) {

  const [data, setData] = useState(props.data)
  const [solutionTimeAverageHidden, setSolutionTimeAverageHidden] = useState(true)
  const [longerSolutionTimeHidden, setLongerSolutionTimeHidden] = useState(true)
  const [shorterSolutionTimeHidden, setShorterSolutionTimeHidden] = useState(true)

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  useEffect(() => {
    if (props.data != data)
      setData(props.data)
  }, [props.data])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const getIntroOfPage = (dataKey, payload) => {
    if (dataKey === "solutiontimeaverage")
      return payload.solutiontimeaveragedata != null && payload.solutiontimeaveragedata.length > 0 ? payload.solutiontimeaveragedata : payload.solutiontimeaverage
    if (dataKey === "longersolutiontime")
      return payload.longersolutiontimedata != null && payload.longersolutiontimedata.length > 0 ? payload.longersolutiontimedata : payload.longersolutiontime
    if (dataKey === "shortersolutiontime")
      return payload.shortersolutiontimedata != null && payload.shortersolutiontimedata.length > 0 ? payload.shortersolutiontimedata : payload.shortersolutiontime
    return '';
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}`}</p>
          {payload.map((item) => {
            return <p className="intro">{`${item.name} : ${item.payload != null ? getIntroOfPage(item.dataKey, item.payload) : item.value}`}</p>
          })}
        </div>
      );
    }
  
    return null;
  };

  return (
    <Card style={{ height: '100%' }}>
      <CardHeader
        title={props.intl.formatMessage({ id: "productivity.dashboard.montly.solution.time.in.hours" })}
        action={
          <IconButton
            id='settings-button'
            aria-label="settings"
            aria-controls={open ? 'settings-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick} >
            <MoreVert />
          </IconButton>
        } />
      <Menu
        id="settings-menu"
        MenuListProps={{
          'aria-labelledby': 'settings-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose} >
        <MenuItem key="solutiontimeaverage">
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={solutionTimeAverageHidden} onChange={() => setSolutionTimeAverageHidden(!solutionTimeAverageHidden)} />} label={props.intl.formatMessage({ id: "productivity.dashboard.solution.time.average.in.hours" })} />
          </FormGroup>
        </MenuItem>
        <MenuItem key="longersolutiontime">
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={longerSolutionTimeHidden} onChange={() => setLongerSolutionTimeHidden(!longerSolutionTimeHidden)} />} label={props.intl.formatMessage({ id: "productivity.dashboard.larger.solution.time.in.hours" })} />
          </FormGroup>
        </MenuItem>
        <MenuItem key="shortersolutiontime">
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={shorterSolutionTimeHidden} onChange={() => setShorterSolutionTimeHidden(!shorterSolutionTimeHidden)} />} label={props.intl.formatMessage({ id: "productivity.dashboard.shorter.solution.time.in.hours" })} />
          </FormGroup>
        </MenuItem>
      </Menu>
      <Divider />
      <CardContent>
          <ComposedChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }} >
            <XAxis dataKey="monthyear" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <CartesianGrid strokeDasharray="3 3" />
            { solutionTimeAverageHidden && <Bar dataKey="solutiontimeaverage" name={props.intl.formatMessage({ id: "productivity.dashboard.solution.time.average.in.hours" })} fill="#8884d8" /> }
            { longerSolutionTimeHidden && <Line type="monotone" name={props.intl.formatMessage({ id: "productivity.dashboard.larger.solution.time.in.hours" })} dataKey="longersolutiontime" fill="#ff7300" /> }
            { shorterSolutionTimeHidden && <Line type="monotone" name={props.intl.formatMessage({ id: "productivity.dashboard.shorter.solution.time.in.hours" })} dataKey="shortersolutiontime" fill="#82ca9d" /> }
          </ComposedChart>
      </CardContent>
    </Card>
  )
})

export default injectIntl(MontlySolutionTimeAverage)