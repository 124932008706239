import React, { useEffect, useState } from 'react'
import CachedIcon from '@mui/icons-material/Cached'
import Dropzone from 'react-dropzone'
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill' // ES6
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { DeleteForever, AttachFile } from '@mui/icons-material'
import { FormattedMessage, injectIntl } from 'react-intl'
import { withStyles } from '@mui/styles'
import { withRouter } from "react-router-dom"
import { Button, CircularProgress, Container, CssBaseline, FormControlLabel, Grid, IconButton, ImageList, Radio, RadioGroup, Typography } from '@mui/material'
import CalledAction from './components/action'
import CustomizedSnackbars from '../../components/material-snackbars'
import * as componentActions from '../../redux/actions/accompaniments'
import { Overlay } from '../../styles/global'
import { Styles } from '../../styles/material-styles'
import { PapiroConsole } from '../../utils/papiroConsole'
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import Api from '../../services/api'
import { addAccompanimentToKnowledgeBase, deleteKnowledgeBaseByAccompanimentId } from "../../services/knowledgebase.service"
import { getByAccompanimentIdAndHistoryCheckType } from '../../services/called.service'
import { getFile } from '../../services/accompaniment.service'
import { getFile as calledServiceGetFile } from '../../services/called.service';
import { getCanEditAccompanimentInActiveCalledByOrgId } from '../../services/organizationsetting.service'
import * as KnowledgeBaseService from '../../services/knowledgebase.service'
import * as KnowledgeBaseCalledService from "../../services/knowledgebasecalled.service";
import * as FileService from '../../services/attachment.service';

import AccompanimentLocationDialog from './components/accompaniment-location-dialog'
import KnowledgebaseDialog from './components/knowledgebase-dialog'
import KnowledgebaseDetailsDialog from './components/knowledgebase-details-dialog'
import AttachFileIcon from '@mui/icons-material/AttachFile';

const useStyles = _ => {
  return withStyles({
    ImageList: {
      width: "100%",
      height: 100,
    },
    icon: {
      height: 32,
      width: 32
    }
  })
}

function CalledActions(props) {

  const { id } = props.match.params
  const { classes, intl, userSession } = props;
  const classes2 = useStyles();

  const hirerid = userSession.user.hirerid
  const called = props.calleds && props.calleds[`called-${id}`] != null ? props.calleds[`called-${id}`] : null
  const calledName = called && called.title && called.title.length > 0 ? called.title : ''
  const reactQuillModules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ]}
  const reactQuillFormats = [
    'header', 'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent', 'link', 'image'
  ]

  const initialKnowledgeBaseItem = {
    id: 0,
    title: '',
    description: '',
    knowledgebasepatrimonies: [],
    knowledgebaseattachments: [],
    knowledgebasekeywords: [],
  }

  const [loading, setLoading] = useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const [notificationVariant, setNotificationVariant] = useState('error')
  const [notificationMessage, setNotificationMessage] = useState('')

  const [displayassignedinfomode, setDisplayassignedinfomode] = useState(false)
  const [techinicalteamname, setTechinicalteamname] = useState('')
  const [caneditaccompanimentinactivecalled, setCaneditaccompanimentinactivecalled] = useState(false)
  const [otherClientInteractsWithCalled, setOtherClientInteractsWithCalled] = useState(false)
  const [allowclientinteractiononclosedcalleds, setAllowclientinteractiononclosedcalleds] = useState(false)
  const [disablebutton, setDisablebutton] = useState(false)
  const [isClient, setIsClient] = useState(true)
  const [isTechinical, setIsTechinical] = useState(false)
  const [isCoordenator, setIsCoordenator] = useState(false)
  const [isAdmOrganization, setIsAdmOrganization] = useState(false)
  const [isGlobalAdm, setIsGlobalAdm] = useState(false)

  const [newDiskAccept, setNewDiskAccept] = useState(false)
  const [description, setDescription] = useState('')
  const [privacy, setPrivacy] = useState('')
  const [files, setFiles] = useState([])
  const [knowledgebaseattachments, setKnowledgebaseattachments] = useState([])
  const [defaultActionList, setDefaultActionList] = useState([])
  const [defaultActionId, setDefaultActionId] = useState(0)

  const [positionAccompCalled, setPositionAccompCalled] = useState([-8.06224030, -34.87254230])
  const [addressAccompCalled, setAddressAccompCalled] = useState('')
  const [accompanimentLocationModalOpen, setAccompanimentLocationModalOpen] = useState(false)

  const [knowledgebase, setKnowledgebase] = useState(null)
  const [knowledgeBaseList, setKnowledgeBaseList] = useState([])
  const [knowledgeBaseDialogOpen, setKnowledgeBaseDialogOpen] = useState(false)

  const [knowledgeBaseDetailsDialogOpen, setKnowledgeBaseDetailsDialogOpen] = useState(false)
  const [knowledgeBaseItem, setKnowledgeBaseItem] = useState(initialKnowledgeBaseItem)

  const closeNotification = _ => {
    setOpenNotification(false)
    setNotificationVariant('error')
    setNotificationMessage('')
  }

  const setMessage = (open, variant, message) => {
    setNotificationVariant(variant)
    setNotificationMessage(message)
    setOpenNotification(open)
  }

  const convertHtmlOption = value => {
    let body = value
    let temp = document.createElement("div")
    temp.innerHTML = body
    let sanitized = temp.textContent || temp.innerText

    return sanitized
  }

  const changeValuesDefaultAction = (stateName, value, text = '') => {
    if(value && value.Name && value.Name.length > 0) {
      setDescription(value.Name)
      setDefaultActionId(value)
    } else {
      setDefaultActionId(0)
    }
  }

  const changeValuesKnowledgeBase = (stateName, value, text = '') => {
    setKnowledgebase(value)
  }

  const addAccompanimentActionToKnowledgeBase = async (accompanimentId) => {
    setLoading(true)

    const response = await addAccompanimentToKnowledgeBase(accompanimentId);
    if (response && response.success && response.data) {
      setLoading(false)
      setMessage(true, "success", intl.formatMessage({ id: "add.knowledgebase" }))
    } else {
      setLoading(false)
      setMessage(true, "error", response && response.data && response.data.errors ? response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
    }
    setLoading(false)
  }

  const deleteKnowledgeBase = async (accompanimentId) => {
    setLoading(true)

    const response = await deleteKnowledgeBaseByAccompanimentId(accompanimentId)
    if (response && response.success) {
      setLoading(false)
      setMessage(true, "success", intl.formatMessage({ id: "delete.knowledgebase" }))
    } else {
      setLoading(false)
      setMessage(true, "error", response && response.data && response.data.errors ? response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
    }
    setLoading(false)
  }

  const getFile = async (attachment) => {
		setLoading(true)
    await Api.get(`/file/downloadlink/attachment/${attachment.id}`)
      .then(result => {
        if (result && result.data && result.data.success && result.data.data) {
          setLoading(false)
			    window.open(`${result.data.data}`, '_blank')
        } else {
          setLoading(false)
          setMessage(true, "error", intl.formatMessage({ id: "file.not.found" }))
        }
      })
      .catch(err => {
        setLoading(false)
        setMessage(true, "error", intl.formatMessage({ id: "file.not.found" }))
      })
  }

  const copyFileLink = async (attachment) => {
    setLoading(true)
    await Api.get(`/file/downloadlink/attachment/${attachment.id}`)
      .then(result => {
        if (result && result.data && result.data.success && result.data.data) {
          setLoading(false)
			    navigator.clipboard.writeText(result.data.data)
          setMessage(true, "success", "Link de download copiado!")
        } else {
          setLoading(false)
          setMessage(true, "error", intl.formatMessage({ id: "file.not.found" }))
        }
      })
      .catch(err => {
        setLoading(false)
        setMessage(true, "error", intl.formatMessage({ id: "file.not.found" }))
      })
  }

  const refreshAccompaniments = async _ => {
    setLoading(true)
    await Api.get(`/accompaniments/called/${called.id}`)
      .then(result => {
        PapiroConsole.log("=== refreshAccompaniments ===")
        if (result && result.data && result.data.success && result.data.data) {
          PapiroConsole.log(result.data)
          props.addAccompanimentsListInCalled(result.data.data, called.id)
          setLoading(false)
        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          setLoading(false)
          setMessage(true, "error", err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
        } else {
          setLoading(false)
          setMessage(true, "error", intl.formatMessage({ id: "process.error" }))
        }
      })
  }

  const openAccompanimentLocationModal = async (id, type = 2) => {
    setLoading(true)
    let result = await getByAccompanimentIdAndHistoryCheckType(id, type)
    if (result && result.data && result.data.data) {
      setLoading(false)
      setPositionAccompCalled([result.data.data.latitude ? result.data.data.latitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '') : '', result.data.data.longitude ? result.data.data.longitude.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '') : ''])
      setAddressAccompCalled(result.data.data.addresscomplete ? result.data.data.addresscomplete.replaceAll('\"', '').replaceAll('\\', '').replaceAll('\'', '') : '')
      setAccompanimentLocationModalOpen(true)
    } else {
      setLoading(false)
      setMessage(true, "error", result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
    }
  }

  const closeAccompanimentLocationModal = _ => {
    setAccompanimentLocationModalOpen(false)
    setPositionAccompCalled([-8.06224030, -34.87254230])
    setAddressAccompCalled('')
  }

  const openKnowledgeBaseDialog = async _ => {
    if (knowledgeBaseList == null || (knowledgeBaseList && knowledgeBaseList.length == 0)) {
      setLoading(true)
      let result = await KnowledgeBaseService.getKnowledgeBaseByOrganizaytionIdSelectionModel(called.organizationid)
      if (result && result.success)
      {
        let formmatedList = result.data.map(item => {
          let toName = item && item.name
          if ('title' in item)
            toName = item['title']
            if (item.knowledgebasekeywords && item.knowledgebasekeywords.length > 0) {
              toName += ` (`
              item.knowledgebasekeywords.map((word, ix) => {
                if (word && word.word && word.word.length > 0) {
                  if (ix == 0)
                    toName += `${word.word}`
                  else
                    toName += `, ${word.word}`
                }
              })
              toName += `)`
            }
            return {
              ...item,
              name: toName
            }
          })
          setKnowledgeBaseList(formmatedList)
          setLoading(false)
          setKnowledgeBaseDialogOpen(true)
      } else {
        setLoading(false)
        setMessage(true, "error", result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
          Api.kickoff(result);
      }
    } else
      setKnowledgeBaseDialogOpen(true)
  }

  const closeKnowledgeBaseDialog = _ => {
    setKnowledgeBaseDialogOpen(false)
  }

  const insertKnowledgebase = async e => {
    e.preventDefault()

    if(knowledgebase != null) {
      if(knowledgebase.knowledgebaseattachments != null && knowledgebase.knowledgebaseattachments.length > 0) {
        let attachmentList = []
        knowledgebase.knowledgebaseattachments.forEach(element => {
          if(element != null && element.attachment != null)
            attachmentList.push(element.attachment)
        })
        setKnowledgebaseattachments(attachmentList)
      }
      if(knowledgebase.description != null) {
        let convKnowldgbs = convertHtmlOption(knowledgebase.description)
        convKnowldgbs = convKnowldgbs.trim()
        if(convKnowldgbs.length > 0)
          setDescription(knowledgebase.description)
      }

      let data = new FormData()
      data.append("CalledId", called.id);
      data.append("KnowledgeBaseId", knowledgebase.id);
      let result = await KnowledgeBaseCalledService.addItem(data)
      if (result && result.success) {
        setLoading(false)
        setMessage(true, 'success', intl.formatMessage({ id: "add.success" }))
      } else {
        setLoading(false)
        setMessage(true, 'error', result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
        Api.kickoff(result)
      }
    }
    closeKnowledgeBaseDialog()
  }

  const knowledgeBaseDetails = async _ => {
    setLoading(true)

    let result = await KnowledgeBaseService.getDetailsById(knowledgebase.id)
    if(result && result.success) {
      if(result.data != null) {
        setLoading(false)
        setKnowledgeBaseDetailsDialogOpen(true)
        setKnowledgeBaseItem(result.data)
      }
    } else {
      setLoading(false)
      setMessage(true, 'error', result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      Api.kickoff(result)
    }
  }

  const closeKnowledgeBaseDetailsDialog = _ => {
    setKnowledgeBaseDetailsDialogOpen(false)
    setKnowledgeBaseItem(initialKnowledgeBaseItem)
  }

  const getCanEditAccompanimentInActiveCalledByOrgId = async _ => {
    setLoading(true)
    await Api.get(`/organizationsettings/settingstoactionpage/organization/${called.organizationid}`)
      .then(result => {
        if (result && result.data && result.data.success && result.data.data != null) {
          setCaneditaccompanimentinactivecalled(result.data.data.caneditaccompanimentinactivecalled)
          setAllowclientinteractiononclosedcalleds(result.data.data.allowclientinteractiononclosedcalleds)
          setDisplayassignedinfomode(result.data.data.displayassignedinfomode)
        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          setLoading(false)
          setMessage(true, "error", err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
        } else {
          setLoading(false)
          setMessage(true, "error", intl.formatMessage({ id: "process.error" }))
        }
      })
  }

  /*const getAllowClientInteractionOnClosedCalledsByOrgId = async _ => {
    setLoading(true)
    await Api.get(`/organizationsettings/allowclientinteractiononclosedcalleds/${called.organizationid}`)
      .then(result => {
        if (result && result.data && result.data.success && result.data.data != null)
          setAllowclientinteractiononclosedcalleds(result.data.data)
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errors) {
          setLoading(false)
          setMessage(true, "error", err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
        } else {
          setLoading(false)
          setMessage(true, "error", intl.formatMessage({ id: "process.error" }))
        }
      })
  }*/

  const getCalledFile = async (fileName,filerepositorymethod) => {
		setLoading(true)
        
		let url = await calledServiceGetFile(hirerid,id,fileName,filerepositorymethod); 
		if(url &&  url.data && url.data.data && url.data.success){
      setLoading(false)
			window.open(`${url.data.data}`, '_blank');
		}
		else{
      setLoading(false)
      setMessage(true, "error", intl.formatMessage({ id: "file.not.found" }))
		}
  }

  const submitAction = async () => {
    setLoading(true)

    if (isClient == true && privacy !== "true" && privacy !== "false")
      setPrivacy("false")

    /*if (this.state.requireattachedimage) { // mudar
      if (files.length === 0) {
        setMessage(true, "error", intl.formatMessage({ id: "files.required" }))
        setLoading(false)
        return
      }
    }*/

    let sanitized = convertHtmlOption(description)
    if (sanitized != null && sanitized.length > 0)
      sanitized = sanitized.trim()

    let check = checkAccompaniment(sanitized);
    if (check == true) {
      let data = new FormData();

      data.append('description', sanitized && sanitized.length > 0 ? description : `#${intl.formatMessage({id:"attachments"})}`);
      data.append('privacy', (isClient && privacy == '') ? false : privacy)
      data.append('calledId', called.id)
      data.append('userAction', userSession.user.id)
      data.append('originid', 2)
      files.forEach(file => data.append('filesUpload', file))

      if(newDiskAccept) {
        data.append("acceptNewDisk", true)
        setNewDiskAccept(false)
      }

      if(defaultActionId != 0 && defaultActionId != null && defaultActionId.id != null){
        data.append("DefaultActionId", defaultActionId.id)
        data.append("DefaultActionTitle", defaultActionId.title ? defaultActionId.title : null )
      }

      let listKnowledgebaseAttachments = knowledgebaseattachments
      if(listKnowledgebaseAttachments != null && listKnowledgebaseAttachments != "") {
        let knowledgeBaseAttachmentsIds = ""
        listKnowledgebaseAttachments.map(item => {
          knowledgeBaseAttachmentsIds = `${item.id},${knowledgeBaseAttachmentsIds}`
        })
        data.append("knowledgeBaseAttachmentsIds", knowledgeBaseAttachmentsIds)
      }

      Api.post("/performance/accompaniments", data, { headers: { "Content-Type": "multipart/form-data" } })
        .then(result => {
          if (result.data.success) {
            setKnowledgebaseattachments([])
            setDescription('')
            setPrivacy('')
            setFiles([])
            setDefaultActionId(0)
            setMessage(true, 'success', intl.formatMessage({ id: "add.action.success" }))
            refreshAccompaniments()
          } else {
            setMessage(true, 'error', "Ocorreu um erro ao adicionar a ação")
            setLoading(false)
          }
        })
        .catch(err => {
          if(err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && !isNaN(err.response.data.errors[0])){
            setLoading(false)
            /*this.setState({ openDialogNewDisk: true, valueNewDisk: err.response.data.errors[0] })*/
          }else{
            setMessage(true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
            setLoading(false)
            //this.setState({ openDialogNewDisk: false });
          }
        })
    }
  }

  const getDefaultActions = async _ => {
    await Api.get(`/defaultaction/byorganizationid/${called.organizationid}`)
      .then(result => {
        if (result && result.data && result.data.data) {
          let formmatedList = result.data.data.map(item => {
            let body = item.title && item.title!=""? item.title : item.text
            let temp = document.createElement("div")
            temp.innerHTML = body
            let sanitized = temp.textContent || temp.innerText

            return {
              ...item,
              name: sanitized,
              Name: item.text
            }
          })

        setDefaultActionList(formmatedList && formmatedList.length > 0 ? formmatedList.sort((a, b) => a.name.localeCompare(b.name)) : formmatedList)
        setLoading(false)
      } else {
          setLoading(false)
      }
    })
    .catch(err => {
      setMessage(true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      setLoading(false)
      Api.kickoff(err)
    });
  }

  const checkAccompaniment = content => {
    if (!isClient && ((content !== null && content.length > 0) || files.length > 0) && privacy !== 'true' && privacy !== 'false')
    {
      setMessage(true, "error", intl.formatMessage({ id: "called.accompaniment.select.action.privacy" }))
      setLoading(false)
      return false
    } else if (((content !== null && content.length == 0) || content === null) && files.length == 0) {
      setMessage(true, "error", intl.formatMessage({ id: "called.accompaniment.notification.missing.action" }))
      setLoading(false)
      return false
    }
    return true
  }

  const removeItem = (file, stateName) => {
    if (stateName == 'files') {
      let array = [files]
      let index = array.indexOf(file)
      if (index !== -1) {
        array.splice(index, 1)
        setFiles(array)
      }
    } else if (stateName == 'knowledgebaseattachments') {
      if(file != null && file.id != null) {
        let newListItem = knowledgebaseattachments.filter(item => item.id != file.id)
        setKnowledgebaseattachments(newListItem)
      }
    }
  }

  useEffect(() => {
    if (userSession == null ||  userSession.user == null || userSession.user.userrole == null)
      Api.logoff()

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3)
        setIsClient(false)
      if (item.role.roletype == 0)
        setIsGlobalAdm(true)
      if (item.role.roletype == 1)
        setIsAdmOrganization(true)
      if (item.role.roletype == 4)
        setIsCoordenator(true)
      if (item.role.roletype == 2)
        setIsTechinical(true)
    })

    if (isClient && userSession.user.caninteractwithcalledwherearenotrequesterorassigned != null && userSession.user.caninteractwithcalledwherearenotrequesterorassigned == true) {
      userSession.user.userorganizations.map(item => {
          if(item.id == called.organizationid) {
              setOtherClientInteractsWithCalled(true)
          }
      })
    }

    getCanEditAccompanimentInActiveCalledByOrgId()
    refreshAccompaniments()
    getDefaultActions()
  }, [])

  if (Api == null) {
    let l = props.match.params.lang;
    document.location.href = `/${l}/unavailable`
  }

  PapiroConsole.log("=== props.accompaniments[`accompaniments-${id}`]")
  PapiroConsole.log(props.accompaniments[`accompaniments-${id}`])

  return (
    <Container component='main' maxWidth="md" style={{ marginTop: 16 }}>
      <CssBaseline />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <h2>Nº {(id && id > 0) ? id : 0} | {calledName}</h2>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginBottom: 15 }}>
          <SimpleAutoComplete
            label={intl.formatMessage({ id: "add.default.action" })}
            options={defaultActionList}
            stateName='defaultActionId'
            changeSelect={changeValuesDefaultAction}
            selected={defaultActionId}
            htmlOptions={true} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginBottom: 30 }}>
          <Typography>
            <FormattedMessage id="action" />
          </Typography>
          <ReactQuill
            id="accompanimentAction"
            name="action"
            value={description}
            style={{ height: 200 }}
            fullWidth
            modules={reactQuillModules}
            formats={reactQuillFormats}
            required
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(value) => setDescription(value)} />
        </Grid>
        { !isClient && !called.archived && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justify="flex-start" style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Tooltip title={<h5><FormattedMessage id="insert.knowledge.base" /></h5>}>
            <IconButton aria-label="insert-knowledge-base"
              onClick={openKnowledgeBaseDialog}
              style={{
								marginTop: '10px' ,
                backgroundColor: '#277afe',
                color: '#FFF'
              }} >
              <EmojiObjectsIcon style={{ fontSize: '2rem' }} />
            </IconButton>
          </Tooltip>
        </Grid> }
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {(((isClient && called && called.requestinguserid && userSession && called.requestinguserid == userSession.user.id) || otherClientInteractsWithCalled == true) || (!isClient && (isTechinical || isAdmOrganization || isCoordenator || isGlobalAdm))) && (<Grid container spacing={0}>
            <section style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}>
              <div className={classes.dropzoneArea} style={{minHeight:'70px',marginTop:'5px '}}>
                <Dropzone onDrop={acceptedFiles => { setFiles([...files, ...acceptedFiles]) }}>
                {({ getRootProps, getInputProps, acceptedFiles }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <AttachFileIcon  />
                    <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                        <FormattedMessage  id="drag.and.drop.files" />
                    </Typography>
                  </div>)}
                  </Dropzone>
                  {files.map(file => {
                  return (
                    <div className={classes.dropzoneItem} key={file.name}>
                      {file.name}
                      <DeleteForever className={classes.deleteItem} onClick={() => { removeItem(file, 'files') }} />
                    </div>
                  )
                })}
              </div>
            </section>
          </Grid>)}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {knowledgebaseattachments.length > 0  && (
          <Grid container spacing={0} style={{marginTop: "5px"}}>
            <Grid item xs={12}>
              <div className={classes.attachmentsBox}>
                <label>{<FormattedMessage id="knowledgebase.attachments" />}</label>
                <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                  <ul>
                    {knowledgebaseattachments.map(file => {
                    return (<li key={file.path}>
                      <DeleteForever onClick={() => removeItem(file, 'knowledgebaseattachments')} style={{ cursor: "pointer" }} />
                      <AttachFile /> <Button onClick={() => getCalledFile(file.path, file.filerepositorymethod)}>{file.description}</Button>
                    </li>)
                    })}
                  </ul>
                </ImageList>
              </div>
            </Grid>
          </Grid>)}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
            {!isClient && (
              <RadioGroup aria-label="privacy" name="privacy" value={privacy} onChange={(e) => setPrivacy(e.target.value)} row>
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({ id: "public.action" })}
                  labelPlacement="end" />
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label={intl.formatMessage({ id: "private.action" })}
                  labelPlacement="end" />
              </RadioGroup>
            )}
            </Grid>
            {(((called && called.status && called.status.statustypeid != 5 && isClient && called.requestinguserid && userSession && called.requestinguserid == userSession.user.id) || otherClientInteractsWithCalled == true) || (!isClient && (isTechinical || isAdmOrganization || isCoordenator || isGlobalAdm))) && !called.archived && (<Grid item xs={12} sm={4}>
              <div style={ !isClient ? { float: 'right', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' } : { display: 'flex', justifyContent: 'flex-end' }} >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ borderRadius: '4px 0 0 4px !important' }}
                  onClick={submitAction}
                  disabled={(disablebutton) ? true : false} >
                  {<FormattedMessage id="add.action" />}
                </Button>
              </div>
            </Grid>)}
            {(isClient && called && called.requestinguserid && userSession && called.requestinguserid == userSession.user.id) && called.status.statustypeid == 5  &&
            <Grid item xs={12} sm={4}>
              <div style={{display: 'flex', justifyContent: 'flex-end'}} >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ width: '100%',  borderRadius: '4px 0 0 4px !important' }}
                  onClick={submitAction}
                  disabled={!allowclientinteractiononclosedcalleds} >
                  {<FormattedMessage id="add.action" />}
                </Button>
              </div>
            </Grid>}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <IconButton color="inherit"
            aria-label="open drawer"
            onClick={refreshAccompaniments}
            edge="start" >
            <CachedIcon />
          </IconButton>
        </Grid>
        { props.accompaniments[`accompaniments-${id}`] && props.accompaniments[`accompaniments-${id}`].length > 0 && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={2}>
        { props.accompaniments[`accompaniments-${id}`].map((action, i) => {
          if (isClient == true && action != null && action.privacy == true) {
            
          } else {
            return <Grid item key={action.id} xs={12} sm={12} md={12} lg={12} xl={12}>
              <CalledAction
                action={action}
                addAccompanimentToKnowledgeBase={() => addAccompanimentActionToKnowledgeBase(action.id)}
                attachments={action.accompanimentattachments}
                calledId={id}
                caneditaccompanimentinactivecalled={caneditaccompanimentinactivecalled} // Mudar essa linha aqui
                client={isClient}
                clientStatus={called && called.status ? called.status : null}
                convertHtmlOption={convertHtmlOption}
                deleteKnowledgeBase={() => deleteKnowledgeBase(action.id)}
                displayassignedinfomode={displayassignedinfomode}
                getFile={getFile}
                globalAdm={isGlobalAdm}
                knowledgebage={action.knowledgebase && action.knowledgebase.deleted == false }
                openAccompanimentLocationModal={() => {
                  if (action.calledstatusid == 4 && (action.originid == 6 || action.originid == 11)) {
                    if (action.description.search(/realizou a assinatura da OS/i) > -1)
                      openAccompanimentLocationModal(action.id, 5)
                    else if (action.description.search(/realizou Check-out de Deslocamento/i) > -1)
                      openAccompanimentLocationModal(action.id, 8)
                    else
                      openAccompanimentLocationModal(action.id)
                  } else {
                    if (action.description.search(/realizou Check-in .s/i) > -1)
                      openAccompanimentLocationModal(action.id, 3)
                    else if (action.description.search(/realizou Check-out .s/i) > -1)
                      openAccompanimentLocationModal(action.id, 4)
                    else if (action.description.search(/realizou Check-in de Deslocamento/i) > -1)
                      openAccompanimentLocationModal(action.id, 7)
                    else if (action.description.search(/realizou Check-..t de Deslocamento/i) > -1)
                      openAccompanimentLocationModal(action.id, 8)
                    else
                      openAccompanimentLocationModal(action.id)
                  }
                }}
                originId={called && called.originrequestid && called.originrequestid > 0 ? called.originrequestid : 2}
                refreshAccompaniments={refreshAccompaniments}
                setLoading={setLoading}
                setMessage={setMessage}
                showPrivacy={isClient}
                techinicalteamname={techinicalteamname}
                userId={userSession && userSession.user ? userSession.user.id : 0} />
            </Grid>
          }
        })}
          </Grid>
        </Grid>}
      </Grid>

      <AccompanimentLocationDialog
        open={accompanimentLocationModalOpen}
        close={closeAccompanimentLocationModal}
        label={intl.formatMessage({id:"called.accompaniment.location"})}
        positionAccompCalled={positionAccompCalled}
        addressAccompCalled={addressAccompCalled} />

      <KnowledgebaseDialog
        open={knowledgeBaseDialogOpen}
        close={closeKnowledgeBaseDialog}
        changeValues={changeValuesKnowledgeBase}
        insertKnowledgebase={insertKnowledgebase}
        knowledgebase={knowledgebase}
        knowledgeBaseDetails={knowledgeBaseDetails}
        knowledgeBaseList={knowledgeBaseList}
        label={intl.formatMessage({id:"insert.knowledge.base"})} />

      <KnowledgebaseDetailsDialog
        open={knowledgeBaseDetailsDialogOpen}
        close={closeKnowledgeBaseDetailsDialog}
        label={`# ${knowledgeBaseItem.id} | ${knowledgeBaseItem.title}`}
        knowledgeBaseItem={knowledgeBaseItem}
        copyFileLink={copyFileLink}
        getFile={getFile} />

      {loading && <Overlay>
        <CircularProgress color='secondary' />
      </Overlay>}

      <CustomizedSnackbars
        variant={notificationVariant}
        message={notificationMessage}
        isOpen={openNotification}
        toClose={closeNotification} />
    </Container>
  )
}

CalledActions.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  calleds: state.calleds,
  accompaniments: state.accompaniments,
  userSession: state.userSession
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledActions))))