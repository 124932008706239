import Api from './api';

export const getAllSelectionModel = async () => { 
  let data = []
  await Api.get(`/originrequests/selectionmodel`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}


export const getAll = async () => { 
  let data = []
  await Api.get(`/originrequests`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}