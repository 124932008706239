import Api from './api';

export const getById = async (id) => { 
  let data = []
  await Api.get(`/priority/${id}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err
  })
  return data
}

export const getAllByHirerId = async _ => { 
  let data = []
  await Api.get(`/priority/hirer`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err
  })
  return data
}

export const getAllSimple = async _ => { 
  let data = []
  await Api.get(`/priority/list/simple`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err
  })
  return data
}



export const paginate = async (page, search = '', field = '') => { 
  let url = `/priority/paginate?page=${page}`
  if((search && search.length > 0) && (field && field.length > 0))
    url += `&search=${search}&field=${field}`

  let data = []
  await Api.get(url)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const addItem = async (formData) => { 
  let data = []
  await Api.post(`/priority`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const editItem = async (formData) => { 
  let data = []
  await Api.put(`/priority`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const deleteItem = async (id) => { 
  let data = []
  await Api.delete(`/priority/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getAllSelectionModel = async () => { 
  let data = []
  await Api.get(`/priority/selectionmodel`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}