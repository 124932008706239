export default function calledflows(state = [], action) {
    switch (action.type) {
        case 'ADD_CURRENT':
            return {
                ...state,
                calledflow: action.calledflow,
            }
        default:
            return state;
    }
}
