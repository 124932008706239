import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AddIcon from '@mui/icons-material/Add';
import { Button, CircularProgress, Container, CssBaseline, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import FirstElement from "../../components/first-element";
import Footer from '../../components/footer';
import HeaderMenu from '../../components/header-menu';
import CustomizedSnackbars from '../../components/material-snackbars';
import MyMaterialTable from '../../components/table';
import { AbilityContext } from '../../config/ability-context';
import Api from '../../services/api';
import { Overlay } from '../../styles/global';
import { Styles } from '../../styles/material-styles';

import Api2Talk from 'src/services/api2talk';
import * as componentActions from '../../redux/actions/articlessection';
class ArticlesSection extends Component {

    constructor(props) {
        super(props);

        this.typingTimeout = null;
        this.emptyArray = null;

        const { userSession } = this.props;
        this.state = {
            dataFilter: false,
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            resultList: [],

            totalPages: 0,
            page: 1,
            pageStart: 0,
            pageEnd: 0,
            count: 0,
            searchValue: ''
        };

        this.callSearch = this.callSearch.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);

        this.closeNotification = this.closeNotification.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.clearFields = this.clearFields.bind(this);
    }

    onChangePaginate = (e, page) => {
        this.setState({ page: page });
        this.getItens(page);
    }

    setPagination(count, page) {

        let totalPage = Math.ceil(count / 10);
        this.setState({ totalPages: totalPage })
        if (count > 0) {
            if (page == 1) {
                if (count < 10) {
                    this.setState({ pageStart: 1, pageEnd: count });
                } else {
                    this.setState({ pageStart: 1, pageEnd: 10 });
                }
            } else {
                let pageStart = ((page - 1) * 10) + 1;



                if (count >= page * 10) {
                    this.setState({ pageStart: pageStart, pageEnd: page * 10 });
                } else {
                    this.setState({ pageStart: pageStart, pageEnd: count });
                }
            }
        } else {
            this.setState({ totalPages: 0, page: 1, count: 0 });
        }

    }

    onFieldChange(event) {


        this.setState({ [event.target.name]: event.target.value, page: 1 });

    }

    callSearch() {
        if (this.state.searchValue != '') {
            this.getItens(this.state.page);
        }
    }

    async clearFields() {
        await this.setState({ searchValue: '', page: 1, loading: true })
        this.getItens(1);
    }

    deleteItem(data, resolve) {
        const intl = this.props.intl;

        Api.delete(`/articles-section/${data.id}`)

            .then(result => {
                const intl = this.props.intl;
                if (result.data.success) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
                        resultList: []
                    });

                    this.getItens();
                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
                resolve();
            })
            .catch(err => {
                const intl = this.props.intl;
                resolve();
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });


    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    componentDidMount() {
        this.getItens();
    }

    getItens(page = 1) {
        this.setState({ loading: true });

        let url = `/articles-section/paginate?page=${page}`;

        if (this.state.searchValue != '') {
            url += `&search=${this.state.searchValue}`
            this.setState({ dataFilter: true });
        }
        Api.get(url)

            .then(result => {
                if (result.data.success) {

                    if (result.data.data.length === 0) {
                        this.emptyArray = true
                    }
                    else {
                        this.emptyArray = false
                    }

                    let count = result.data.count;
                    this.setState({ count: count });
                    this.setPagination(count, page);

                    const formattedList = result.data.data.map(item => {
                        return {
                            ...item,

                        }
                    });
                    this.setState({ loading: false, resultList: formattedList });
                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });
    };


    closeNotification() {
        this.setState({ openNotification: false });
    }
    render() {
        const { classes, headerMenu, actionsHeaderMenu } = this.props;
        const intl = this.props.intl;

        if (Api == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/unavailable`
        }

        return (
            <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    }, classes.actionsContent, {
                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='xl' style={{ paddingLeft: 0, marginLeft: 0 }}>
                        {(!this.emptyArray || this.state.dataFilter) ? (
                            <div>


                                <CssBaseline />
                                <Grid container className={classes.mb20} spacing={0}>

                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        size='large'
                                        className={classes.button}
                                        startIcon={<AddIcon />}
                                        component={Link} {...{ to: `/${this.props.match.params.lang}/articles-section-registration` }}
                                    >
                                        {<FormattedMessage id="add" />}
                                    </Button>
                                </Grid>
                                <Grid container spacing={6} alignItems="center">
                                    <Grid item xs={12} sm={12}>

                                        <Card className="background-title-search">
                                            <CardContent>

                                                <Grid container spacing={1} alignItems="center">
                                                    <Grid item xs={12} sm={5} md={3} lg={6}>
                                                        <Typography variant="h5">
                                                            {<FormattedMessage id="articles-section.header" />}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>

                                    </Grid>

                                </Grid>
                                    <MyMaterialTable
                                        title=""
                                        rowClick={(event, rowData) => {
                                            this.props.addCurrent(rowData);
                                            this.props.history.push(`/${this.props.match.params.lang}/articles-section-edit`);
                                        }}
                                        columns={[
                                            { title: intl.formatMessage({ id: "id" }), field: 'id' },
                                            { title: intl.formatMessage({ id: "name" }), field: 'name' },
                                            { title: intl.formatMessage({ id: "parent.section.name" }), field: 'parentname' }
                                        ]}
                                        data={this.state.resultList}
                                        deleteItem={this.deleteItem}
                                    />


                                    <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                                        <Grid item xs={12} md={3}>
                                            <Pagination count={this.state.totalPages} page={this.state.page} onChange={this.onChangePaginate} />
                                        </Grid>
                                        <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                                            {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                                        </Grid>
                                    </Grid>


                            </div>)
                            : (
                                <div>
                                    <FirstElement
                                        title={intl.formatMessage({ id: "articlessection.list.create.first.articlessection" })}
                                        subtitle={intl.formatMessage({ id: "articlessection.list.create.articlessection" })}
                                        //buttonText = "Novo Chamado"
                                        buttonText=
                                        {
                                            <Button
                                                variant='contained'
                                                color='secondary'
                                                size='large'
                                                className={classes.button}
                                                startIcon={<AddIcon />}
                                                component={Link} {...{ to: `/${this.props.match.params.lang}/articles-section-registration` }}
                                            >
                                                {<FormattedMessage id="add" />}
                                            </Button>
                                        }

                                        icon={<AccountBalanceWalletIcon style={{ fontSize: "45px" }} />}

                                    />
                                </div>
                            )}
                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}


                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
                <Footer />
            </div>
        );
    }
}
ArticlesSection.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ArticlesSection)));

ArticlesSection.contextType = AbilityContext;