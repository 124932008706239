import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { IconButton, Paper } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const AudioPlayer = ({ audioUrl }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#CDDFE7' }}>
      <ReactPlayer
        url={audioUrl}
        controls={true}
        width="300px"
        height="30px"
        style={{ outline: 'none' }}
      />
    </div>
  );
};

export default AudioPlayer;