import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
//import { Modal } from 'react-bootstrap';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { FormattedMessage,injectIntl } from 'react-intl';



import { googleOAuth2 } from '../../redux/actions/google';

class LoginGoogle extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modalOpen : true,
            loading: false,
            openNotification: false,
      
          };    
          this.handleOpen = this.handleOpen.bind(this);    
          this.handleClose = this.handleClose.bind(this);    
          this.handleClose = this.handleClose.bind(this);    
          this.responseGoogleSuccessInt = this.responseGoogleSuccessInt.bind(this);    
          this.responseGoogleErrorInt = this.responseGoogleErrorInt.bind(this);    
          this.responseGoogleLogoutInt = this.responseGoogleLogoutInt.bind(this);    
        }
    handleOpen() {

        this.setState({ modalOpen: true });
    };

    handleClose  (){
        this.setState({modalOpen:false})
    };

    closeNotification() {
        this.setState({ openNotification: false });
    }    
   responseGoogleSuccessInt = (response) => {
        

        this.props.success(response);
    }
    responseGoogleErrorInt = (response) => {
        
        this.props.error(response);
    }
    responseGoogleLogoutInt = (response) => {
       
        this.props.logout(response);
    }
    render() {
        const intl = this.props.intl;
        let self = this;
        
        function LoginModal() {   
                return (
                    <>  
                        {/*
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            style = {{ display: 'flex',
                                alignItems: 'center',
                                    justifyContent: 'center',
                                    border: '1px ridge #000',
                                
                                }}
                            open={self.state.modalOpen}
                            onClose={self.handleClose}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                        */}
                        <GoogleLogin
                            clientId="294197104676-reqfefldam9kkpav24b3d6p629230smp.apps.googleusercontent.com"
                            buttonText={intl.formatMessage({id:"log.in.with.google"})}
                            onSuccess={self.responseGoogleSuccessInt/*self.props.googleOAuth2*/}
                            onFailure={self.responseGoogleErrorInt/*self.props.googleOAuth2*/}
                            cookiePolicy={'single_host_origin'}
                            isSignedIn={false}
                        />              
                    {/*</Modal>*/}
                    </>
                );
        }

        function LoggedIn(props) {
            return (
                <GoogleLogout
                clientId="294197104676-reqfefldam9kkpav24b3d6p629230smp.apps.googleusercontent.com"
                buttonText="Logout"
                onLogoutSuccess={self.responseGoogleLogoutInt/*self.props.googleOAuth2*/}
                />
            );
        }

        function LoggedOut(props) {
            return (
                <LoginModal />
            );     
        }

        function HandleAuth(props) {
            const isLoggedIn = props.isLoggedIn;
            if (isLoggedIn) {
                return <LoggedIn />;
            }
            return <LoggedOut />;
        }

        return (
            <HandleAuth isLoggedIn={typeof this.props.googleReducer.accessToken !== 'undefined'} />
        );
    }
};

function mapStateToProps (state) {
  return {
      ...state,
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators ({ googleOAuth2 }, dispatch);
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(LoginGoogle));