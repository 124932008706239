import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'

import { Styles } from '../../../../styles/material-styles'
import { Grid } from '@mui/material'
import moment from 'moment'
import Attachment from '../attachment'

function CalledAttachments(props) {

  if (props != null && props.calledData != null && props.calledData.calledattachments != null && props.calledData.calledattachments.length > 0) {
    return (
      <Grid container spacing={0}>
        {props.calledData.calledattachments.map(item => <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ paddingTop: '5px', paddingBottom: '5px' }}>
          <Attachment
            attachment={item.attachment}
            setMessage={props.setMessage}
            setLoading={props.setLoading} />
        </Grid>)}
      </Grid>
    )
  } else {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center', paddingTop: '20px' }}>
          <FormattedMessage id="no.data" />
        </Grid>
      </Grid>
    )
  }
}

CalledAttachments.propTypes = {
  classes: PropTypes.object.isRequired,
  setMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
})

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(CalledAttachments)))