import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../../../../styles/material-styles';
import { Overlay } from '../../../../../styles/global';
import Api from '../../../../../services/api';
import CustomizedSnackbars from '../../../../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../../../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'; import SimpleSelect from '../../../../../components/select/simple';
import SimpleCheckBox from '../../../../../components/checkbox/check';
import SimpleDatePicker from '../../../../../components/date-pickers/inline';
import SimpleAutoComplete from '../../../../../components/auto-complete/autocomplete-org';
import Footer from '../../../../../components/footer';
import { browserHistory } from '../../../../../helpers/history';
import Title from '../../../../../components/title-name';
import * as Service from '../../../../../services/organizationchecklist.service';
import * as AdditionalCalledCostItemReasonsService from '../../../../../services/additionalcalledcostitemreasons.service'
import * as AdditionalCalledCostItemService from '../../../../../services/additionalcalledcostitems.service'
import * as UserService from "../../../../../services/user.service";
import * as componentActions from '../../../../../redux/actions/additionalcalledcostitemreasons';
import * as CalledService from '../../../../../services/called.service';
import { FormattedMessage, injectIntl } from 'react-intl';
import MyCheckList from "../../../../../components/checklist/checklist";
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { PapiroConsole } from '../../../../../utils/papiroConsole';

class AdditionalCalledCostItemReasonEdit extends Component {

    constructor(props) {
        super(props);
        const { userSession, additionalcalledcostitemreasons, organizations } = this.props;

        this.fromProps = false
        if(this.props && this.props.location && this.props.location.state && this.props.location.state.from && this.props.location.state.from.length > 0)
            this.fromProps = true

        this.globalAdm = false;
        this.isClient = true;
        if (userSession.user.userrole == null) {
            Api.logoff();
        }

        if(!additionalcalledcostitemreasons || (additionalcalledcostitemreasons && !additionalcalledcostitemreasons.additionalcalledcostitemreason)) {
            var l = this.props.match.params.lang;
            document.location.href=`/${l}/calleds`
        }

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false;
            }
            if (item.role.roletype == 0) {
                this.globalAdm = true;
            }
        });

        PapiroConsole.log("=== AdditionalCalledCostItemReasonEdit ===")
        PapiroConsole.log(additionalcalledcostitemreasons)

        this.initialState = {
            UpdatedUser: userSession.user.id,
            id: additionalcalledcostitemreasons.additionalcalledcostitemreason.id,
            name: additionalcalledcostitemreasons.additionalcalledcostitemreason.name,
            active: additionalcalledcostitemreasons.additionalcalledcostitemreason.active,
            OrganizationId: additionalcalledcostitemreasons.additionalcalledcostitemreason.organization,
            OrganizationText: additionalcalledcostitemreasons.additionalcalledcostitemreason.organization ? additionalcalledcostitemreasons.additionalcalledcostitemreason.organization.name : '',
            saveToAllOrganizationsInGroup: false,
        };

        this.state = {
            userid: userSession.user.id,
            item: this.initialState,
            organizations: organizations,
            files: [],
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            OrganizationList: [],
            OrganizationCategoriesList: [],
            AdditionalCalledCostItemList: [],
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.closeNotification = this.closeNotification.bind(this);
        this.changeValues = this.changeValues.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    async componentDidMount() {
        if (this.state.organizations && this.state.organizations.organization && this.state.organizations.organization.id && this.fromProps){
            this.getOrganizationsById(this.state.organizations.organization.id);
        }
        else {
            this.getOrganizations();
        }
    }

    getOrganizations = async _ => {
        this.setState({ loading: true });
        var result = await Service.getOrganizations();
        if (result.success) {
            this.setState({ loading: false, OrganizationList: result.data });

        } else {
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
            Api.kickoff(result);
        }
    };
    getOrganizationsById = async id => {
        this.setState({ loading: true });
        var result = await Service.getOrganizationsByIdLight(id);
        if (result.success) {
            this.setState({ loading: false, OrganizationList: [result.data] });

        } else {
            this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
            });
            Api.kickoff(result);
        }
    };

    handleInputChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        this.setState(prevState => ({
          item: {
                ...prevState.item,
                [name]: value
            }
        }));
    };

    handleSubmit = async e => {
        e.preventDefault();

        const { intl } = this.props

        if(this.state.item.OrganizationId == null || this.state.item.OrganizationId == 0 || (this.state.item.OrganizationId && this.state.item.OrganizationId.id && this.state.item.OrganizationId.id == 0)) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({id:"organization.calendar.registration.missing.organization.field"})
            });
            return false
        }
        if(this.state.item.name == null || this.state.item.name.length == 0) {
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: intl.formatMessage({id:"organization.checklist.registration.missing.title"})
            });
            return false
        }

        this.setState({ loading: true });
        const { userSession } = this.props;

        let data = new FormData();


        if (this.isClient) {
            this.setState(prevState => ({
                item: {
                    ...prevState.item,
                }
            }));
        } else {
            this.setState(prevState => ({
              item: {
                    ...prevState.item,
                }
            }));
        }

        for (let key in this.state.item) {
            if(key === 'id') {
                data.append(key, this.state.item[key]);
            }else if(key.toString().toLowerCase().indexOf('organizationid') > -1) {
                data.append(key, this.state.item[key].id);
            }else if(key.toString().toLowerCase().indexOf('savetoallorganizationsingroup') > -1) {
                data.append(key, this.state.item[key]);
            }else if(key.toString().toLowerCase().indexOf('name') > -1) {
              data.append(key, this.state.item[key]);
            }else if(key.toString().toLowerCase().indexOf('active') > -1) {
                data.append(key, this.state.item[key]);
            }else if(key.toString().toLowerCase().indexOf('updateduser') > -1) {
              data.append(key, this.state.item[key]);
            }
        }
        data.append('updateduser', this.state.userid);

        let config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        let response = await AdditionalCalledCostItemReasonsService.editItem(data)
        if (response && response.success) {
          const formattedList = response.data;
          formattedList.OrganizationId = formattedList.organization ? formattedList.organization.id : 0;
          formattedList.OrganizationText = formattedList.organization ? formattedList.organization.name : '';

          let roles = ''
          if(formattedList && formattedList.calledlistdataroles && formattedList.calledlistdataroles.length > 0) {
              const roleFilter = formattedList.calledlistdataroles.map(i => {
                  if (i && i.role && i.role.displayname)
                    return i.role.displayname
              })
              if (roleFilter && roleFilter.length > 0)
                  roles = roleFilter.join(' ,')
          }

          formattedList.Roles = roles

          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({id:"edit.success"}),
            files: []
          });
          if (this.fromProps)
            this.props.addCurrentOrganization(formattedList, this.state.organizations.organization);
          else
            this.props.addCurrent(formattedList);
          this.componentDidMount();
        } else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          })
          Api.kickoff(response)
        }
    };

    getRoles = async _ => {
      this.setState({ loading: true });
      var result = await UserService.getRoles();
      if (result.success) {
        
        this.setState({ loading: false, RoleList: result.data });
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            result.response && result.response.data && result.response.data.errors
              ? result.response.data.errors[0]
              : intl.formatMessage({ id: "process.error" }),
        });
        Api.kickoff(result);
      }
    }

    onChange = (selected) => {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          selected: selected
        }
      }))
    }

    getRolesIds = _ => {
      const idList = this.state.RoleList.map((item) => {
        return item.id;
      });
      return idList;
    }
    
    getRolesNames = _ => {
      const idList = this.state.RoleList.map((item) => {
        return item.name;
      });
      return idList;
    }

    closeNotification() {
        this.setState({ openNotification: false });
    }

    changeValues(stateName, value, text = '') {
        this.setState({ loading: true });

        this.setState(prevState => ({
            item: {
                ...prevState.item,
                [stateName]: value
            }
        }));
        if (stateName === 'OrganizationId') {
            this.setState(prevState => ({
              item: {
                    ...prevState.item,
                    ['OrganizationText']: text,
                }
            }));
        }

        this.setState({ loading: false });

    };

    removeItem(file) {
        let array = [...this.state.files];
        let index = array.indexOf(file)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ files: array });
        }
    }

    render() {
        const { classes, headerMenu, actionsHeaderMenu, additionalcalledcostitemreasons } = this.props;
        const { item } = this.state;
        const intl = this.props.intl;

        if(Api == null) {
            var l = this.props.match.params.lang;
            document.location.href=`/${l}/unavailable`
          }

        return (
            <div className={classes.root}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    }, classes.actionsContent, {
                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='md'>
                        <CssBaseline />
                        <div className={classes.paper} style={{ marginTop: 0 }}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={4}>
                                    <Button variant='outlined' color='primary' onClick={() => {
                                        this.fromProps ? this.props.history.push(`/${this.props.match.params.lang}/organization-edit`, { organizations: this.props.organizations, propTab: 12, propServiceTab: 2 }) : this.props.history.push(`/${this.props.match.params.lang}/additional-service-config`, { propServiceTab: 2 })}
                                    }>{this.props.intl.formatMessage({id:"back"})}</Button>
                                </Grid>
                                <Grid item xs={12} sm={8} />
                            </Grid>
                            <Avatar className={classes.avatar}>
                                <QueuePlayNext />
                            </Avatar>
                            <Typography component='h1' variant='h5'>
                                {<FormattedMessage id="additional.called.cost.reason.edit" />}
                                <Title />
                            </Typography>
                            <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                      <SimpleAutoComplete
                                        label={intl.formatMessage({ id: "organization" })}
                                        options={this.state.OrganizationList}
                                        stateName='OrganizationId'
                                        changeSelect={this.changeValues}
                                        selected={item.OrganizationId}
                                        isRequired required  />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                      <TextField
                                        inputProps={{ maxLength: 255 }}
                                        fullWidth
                                        label={intl.formatMessage({ id: "title" })}
                                        autoComplete='fname'
                                        variant='outlined'
                                        name='name'
                                        onChange={this.handleInputChange}
                                        value={item.name}
                                        required  />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                      <SimpleCheckBox
                                        label={intl.formatMessage({ id: "active" })}
                                        name='active'
                                        stateName='active'
                                        changeSelect={this.changeValues}
                                        selected={item.active} />
                                    </Grid>
                                    <Grid item xs={11} sm={11}>
                                        <SimpleCheckBox
                                        label={intl.formatMessage({id:"save.to.all.organizations.in.group"})}
                                        name='saveToAllOrganizationsInGroup'
                                        stateName='saveToAllOrganizationsInGroup' 
                                        changeSelect={this.changeValues}
                                        selected={item.saveToAllOrganizationsInGroup} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                                        <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                                            <Grid item xs={12} sm={4}    >
                                                <Button 
                                                type='submit'
                                                fullWidth
                                                variant='contained'
                                                color='primary'
                                                className={classes.submit}
                                                >
                                                {<FormattedMessage id="save" />}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>

                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
                <Footer />
            </div>
        );
    }
}
AdditionalCalledCostItemReasonEdit.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    organizations: state.organizations,
    additionalcalledcostitemreasons: state.additionalcalledcostitemreasons
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(AdditionalCalledCostItemReasonEdit)));
