export default function calledorganizationfields(state = [], action) {
  switch (action.type) {
      case 'ADD_CURRENT':
          return {
              ...state,
              organizationfield: action.organizationfield,
          }
      case 'ADD_CURRENT_ORGANIZATION':
          return {
              ...state,
              organizationfield: action.organizationfield,
              organization: action.organization,
          }
      case 'ADD_CURRENT_CALLED':
          return {
              ...state,
              organizationfield: action.organizationfield,
              called: action.called,
          }
      case 'ADD_CURRENT_CALLED_AND_ORGANIZATION':
          return {
              ...state,
              organizationfield: action.organizationfield,
              organization: action.organization,
              called: action.called,
          }

      default:
          return state;
  }
}