import 'date-fns';
import React from 'react';
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid';
import DateFnsUtils from '@date-io/date-fns';
import ptBRLocale from 'date-fns/locale/pt-BR'
import {TextField } from "@mui/material";
import { FormattedMessage,injectIntl } from 'react-intl';


//import { DatePicker } from '@mui/x-date-pickers/DatePicker';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


function MaterialUIPickerInline(props) {

    const intl =  props.intl;

    const { label, stateName, changeDate, onChange, currentDate, format = 'dd/MM/yyyy' } = props;

    const [selectedDate, setSelectedDate] = React.useState();


    React.useEffect(() => {
        setSelectedDate(currentDate);
      }, [currentDate]);

    const handleDateChange = date => {
        setSelectedDate(date);
        if(changeDate != null){
            changeDate(stateName, date);
        }
        else{
            if(onChange != null){
                onChange(stateName, date);
            }
        }
    };

    const today = new Date();

    return (
        <LocalizationProvider utils={DateFnsUtils} locale={ptBRLocale} dateAdapter={AdapterDateFns}>
            <Grid container justify="space-around">
                <DatePicker renderInput={(props) => <TextField {...props} />} 
                    inputFormat={format}
                    disableToolbar
                    variant="inline"
                    format={format}
                    label={label}
                    value={selectedDate}
                    invalidDateMessage={intl.formatMessage({id:"date.pickers.invalid.date"})}
                    minDateMessage={intl.formatMessage({id:"date.pickers.date.error"})}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </Grid>
        </LocalizationProvider>
    );
}

MaterialUIPickerInline.propTypes = {
    label: PropTypes.string,
    stateName: PropTypes.string,
    changeDate: PropTypes.func,
    currentDate: PropTypes.string,
    format: PropTypes.string
};

export default injectIntl(MaterialUIPickerInline);