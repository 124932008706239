import React from 'react';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import { ExitToApp, Person, CompareArrows, AccountBox } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

function GroupMenu2(props) {

  //console.log(props);
  const intl = props.intl;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //console.log(props);
  //{ resetSession },

  const MenuItemConst = (item) => (
    <MenuItem onClick={handleClose}>
      <AccountBalanceIcon /> <span style={{ marginLeft: '10px' }}>{ item }</span>
    </MenuItem>
  )

  return (
  
    <div style={{display: 'inline'}}>
      <Typography variant="h6">
      <div style={{paddingLeft: '30px',color:"#fff", fontSize:'20px'}}>
        <strong>{props.group.groupName}</strong>
      </div>
      </Typography>
    </div>
  );
}

GroupMenu2.propTypes = {
  group: PropTypes.object.isRequired,
};


export default GroupMenu2;
