export function addCurrent(integrationauth) {
  return {
      type: 'ADD_CURRENT',
      integrationauth,
  }
}


export function addRow(name,data) {
  return {
      type: 'ADD_ROW',
      name,
      data,
  }
}