export default function organizationfieldreportblocks(state = [], action) {
  switch (action.type) {
    case 'ADD_CURRENT':
        return {
            ...state,
            organizationfieldreportblock: action.organizationfieldreportblock,
        }
    case 'ADD_CURRENT_ORGANIZATION':
        return {
            ...state,
            organizationfieldreportblock: action.organizationfieldreportblock,
            organization: action.organization,
        }
      default:
          return state;
  }
}