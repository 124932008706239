import Api from './api';

export const getById = async (id) => { 
  let data = []
  await Api.get(`/calledservicesettingsfileuploadvalidation/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getByIdWithoutIncludes = async (id) => { 
  let data = []
  await Api.get(`/calledservicesettingsfileuploadvalidation/${id}/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getAllByCalledServiceSettingsFileUploadId = async (calledServiceSettingsFileUploadId) => { 
  let data = []
  await Api.get(`/calledservicesettingsfileuploadvalidation/all/calledservicesettingsfileupload/${calledServiceSettingsFileUploadId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getAllByCalledServiceSettingsFileUploadIdWithoutIncludes = async (calledServiceSettingsFileUploadId) => { 
  let data = []
  await Api.get(`/calledservicesettingsfileuploadvalidation/all/calledservicesettingsfileupload/${calledServiceSettingsFileUploadId}/withoutincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const addItem = async (formData) => { 
  let data = []
  await Api.post(`/calledservicesettingsfileuploadvalidation`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const editItem = async (formData) => { 
  let data = []
  await Api.put(`/calledservicesettingsfileuploadvalidation`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const deleteItem = async (id) => { 
  let data = []
  await Api.delete(`/calledservicesettingsfileuploadvalidation/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}