export function toggle(open) {
  return {
    type: 'TOGGLE',
    open,
  }
}
export function reload(refresh) {
  return {
    type: 'RELOAD',
    refresh,
  }
}


export function notificationMenuRefreshed(notificationmenurefreshed) {
  return {
    type: 'NOTIFICATION_MENU_REFRESHED',
    notificationmenurefreshed,
  }
}
