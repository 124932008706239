import Api from './api';
export const getOrganizations = async () => { 
  let data = [];
  await Api.get('/organizations')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getOrganizationsById = async (id) => { 
  let data = [];
  await Api.get('/organizations/'+id)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getOrganizationsByIdLight = async (id) => { 
  let data = [];
  await Api.get('/organizations/notincludes/'+id)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getOrganizationDepartaments = async () => { 
  let data = [];
  await Api.get('/organizationdepartaments')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getOrganizationDepartamentsById = async (id) => { 
  let data = [];
  await Api.get(`/organizationdepartaments/${id}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getParentOrganizationDepartaments = async (orgId = 0) => { 
  let data = [];
  if(orgId == 0){
    await Api.get(`/organizationdepartaments`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
    });
    return data;
  }else{
    await Api.get(`/organizationdepartaments/organization/${orgId}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
    });
    return data;
  }
  
};

export const getOrganizationsDepartaments = async (id = 0) => { 
  let data = [];
  await Api.get(`organizationdepartaments/organization/${id}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getOrganizationsDepartamentsSimpleByOrganizationId = async (id = 0) => { 
  let data = [];
  await Api.get(`organizationdepartaments/list/simple/${id}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

