import Api from './api';
export const getCategories = async () => { 
  let data = [];
  await Api.get('/categories')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getOrganizations = async () => { 
  let data = [];
  await Api.get('/organizations')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getAllCategoriesWithParent = async () => {
  let data = [];
  await Api.get("/categories/parent")
      .then(result => {
          data = result.data
      })
      .catch(err => {
          data = err;
      });
  return data;
};
