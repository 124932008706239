import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import HeaderMenu from '../../components/header-menu';
import { bindActionCreators } from 'redux';
import * as calledsActions from '../../redux/actions/calleds';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { AbilityContext } from '../../config/ability-context'
import Footer from '../../components/footer';
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import clsx from 'clsx';
import PropTypes, { array } from "prop-types";
import { CssBaseline, CircularProgress, Container, Grid } from '@mui/material';
import Api from "../../services/api";
import Configuration from '../wizard-pages/configuration';
import Address from '../wizard-pages/address';
import Slas from '../wizard-pages/slas';
import Categories from '../wizard-pages/categories';
import Departaments from '../wizard-pages/departaments';
import Materials from '../wizard-pages/materials';
import Patrimony from '../wizard-pages/patrimony';
import Check from '@mui/icons-material/Check';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom'
import * as Service from '../../services/organizationsla.service';
import CustomizedSnackbars from '../../components/material-snackbars';
import Divider from '@mui/material/Divider';

import image2 from "./images/organization2.png";
import MobileStepper from '@mui/material/MobileStepper';
import WizardOrganization from '../wizard-organization/wizard';



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  progressBar: {
   // maxWidth: 800,
    //flexGrow: 2,
  },
}));

const progressBar = makeStyles((theme) => ({
  root: {
    maxWidth: 450,
    flexGrow: 1,
  },
}));

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

var arrayStespCompletedes = []
var organizationIds = 0
var checkMenu = false
var headerConditional = null

var saveId = undefined



function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;
  

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}
function getSteps(props) {
  const intl = props.intl;
  //return ['Configuração', 'Endereço', 'Sla', 'Categorias','Departamentos','Material','Patrimônios'];
  return [intl.formatMessage({id:"config"}), intl.formatMessage({id:"address"}), intl.formatMessage({id:"sla.filter"}),intl.formatMessage({id:"categories"}),intl.formatMessage({id:"menu.organizationdepartaments"}),intl.formatMessage({id:"components"}),intl.formatMessage({id:"menu.organizationpatrimonies"})];
}

function getOrganizationByid(id)
{
    Api.get(`/organizations/${id}`)
                .then(result => {
                    if (result.data.success) {
                      
                       



                    }

                })
                .catch(err => {
                    if (err.response && err.response.data && err.response.data.errors) {
                        const intl = this.props.intl;
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                        });
                    }
                    else {
                        const intl = this.props.intl;
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: "error",
                            notificationMessage: intl.formatMessage({ id: "process.error" })
                        });
                    }
                });
}

function getStepContent(step) {
  const intl = this.props.intl;
  switch (step) {
    case 0:
      return intl.formatMessage({id:"tutorial.progress.steps.config.organization"});
    case 1:
      return intl.formatMessage({id:"tutorial.progress.steps.register.address"});
    case 2:
      return intl.formatMessage({id:"tutorial.progress.steps.create.slas"});
    case 3:
    return intl.formatMessage({id:"tutorial.progress.steps.associate.categories"});
    case 4:
        return intl.formatMessage({id:"wizard.add.departaments.to.organization"});
    case 5:
        return intl.formatMessage({id:"wizard.create.materials.to.organization"});
    case 6:
        return intl.formatMessage({id:"wizard.add.patrimonies.to.organization"});
    default:
        return intl.formatMessage({id:"tutorial.progress.steps.complete"});
  }
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

function Wizard(props) {
  const classed = useStyles();
  const progressBars = progressBar();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [activeProgress, setActiveProgress] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [arraySteps, setArraySteps] = React.useState({array : [], organization : null})
  //const [array,setArray]= React.useState([]) 
  //const [saveId, setsaveId] =  React.useState(undefined);
  const [data, setData] = React.useState({data: false});
  
  const[notifications, setnotification] = React.useState({notificationVariant:'error',  openNotification: false, notificationMessage: '', });
  const steps = getSteps(props);
  const history = useHistory()

  if(Api == null) {
    var l = this.props.match.params.lang;
    document.location.href=`/${l}/unavailable`
  }

  var completedTask = 0;
  
  var hasValue = null
  var x = 0 
  
 
 

 
  
   
    if(props.location !== undefined)
    {
        if( props.location.state !== undefined || saveId !== undefined )
        {

            if(props.location.state !== "")
            {    
              saveId = props.location.state.organization.id
            }
                
        
            x =  saveId
      
        }
        else
        {
          
          history.push({
            pathname:`/pt/calleds`,
            
          });
          //x = 7953
        }
  
      }
      else
      {
        x = props.organizations.organization.id
       
      }

  const {  classes,userSession, headerMenu, actionsHeaderMenu, calleds } = props;

 

  

  async function getDados(id){
     
    var result = await Service.getSLA(id);
      if (result.success) {

        if(result.data.length > 0 )
        {
          setData({data :true})
        }
     

        
      } else {
       // const intl = this.props.intl;
        //Api.kickoff(result);  
      }
  };

  function closeNotification() {
    const intl = this.props.intl;
    setnotification({ openNotification: false, notificationVariant: 'error',notificationMessage:this.props.intl.formatMessage({id:"wizard.register.sla.first"}) })

  }

  

  function getContent(step, organizationid, handleBack, handleNext, handleReset) {
    const intl = this.props.intl;
    switch (step) {
    
      case 0:
        return <Configuration handleNextBarProgress={handleNextBarProgress} handleComplete={handleComplete} organizationid={organizationid} back={handleBack} next={handleNext} backButton = {  <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
        {this.props.intl.formatMessage({id:"back"})}
    </Button>} nextButton = {<Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            className={classes.button}
                            style={{textAlign: 'center', marginLeft:'5px', marginRight:'5px' }}
                          >
                            
                            {this.props.intl.formatMessage({id:"next"})}
                        </Button>} />;
      case 1:
        return <Address handleNextBarProgress={handleNextBarProgress} handleComplete={handleComplete} organizationid={organizationid} back={handleBack} next={handleNext} backButton = {  <Button fullWidth disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
        {this.props.intl.formatMessage({id:"back"})}
    </Button>} nextButton = {<Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handleNext}
                            className={classes.button}
                            style={{textAlign: 'center', marginLeft:'5px', marginRight:'5px' }}
                          >
                            
                            {this.props.intl.formatMessage({id:"next"})}
                        </Button>} />;
      case 2:
          return <Slas handleNextBarProgress={handleNextBarProgress} handleComplete={handleComplete} organizationid={organizationid} back={handleBack} next={handleNext} backButton = {  <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
          {this.props.intl.formatMessage({id:"back"})}
      </Button>} nextButton = {<Button
                              variant="contained"
                              color="primary"
                              onClick={handleNext}
                              className={classes.button}
                              style={{textAlign: 'center', marginLeft:'5px', marginRight:'5px' }}
                            >
                              
                              {this.props.intl.formatMessage({id:"next"})}
                          </Button>} />;
     
     case 3:
        return <Categories handleNextBarProgress={handleNextBarProgress} handleComplete={handleComplete} organizationid={organizationid} back={handleBack} next={handleNext} backButton = {  <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
        {this.props.intl.formatMessage({id:"back"})}
    </Button>} nextButton = {<Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            className={classes.button}
                            style={{textAlign: 'center', marginLeft:'5px', marginRight:'5px' }}
                          >
                            
                            {this.props.intl.formatMessage({id:"next"})}
                        </Button>} />;
      case 4:
        return <Departaments handleNextBarProgress={handleNextBarProgress} handleComplete={handleComplete} organizationid={organizationid} back={handleBack} next={handleNext} backButton = {  <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
        {this.props.intl.formatMessage({id:"back"})}
    </Button>} nextButton = {<Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            className={classes.button}
                            style={{textAlign: 'center', marginLeft:'5px', marginRight:'5px' }}
                          >
                            
                            {this.props.intl.formatMessage({id:"next"})}
                        </Button>} />;
      case 5:
        return <Materials handleNextBarProgress={handleNextBarProgress} handleComplete={handleComplete} organizationid={organizationid} back={handleBack} next={handleNext} backButton = {  <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
        {this.props.intl.formatMessage({id:"back"})}
    </Button>} nextButton = {<Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            className={classes.button}
                            style={{textAlign: 'center', marginLeft:'5px', marginRight:'5px' }}
                          >
                            
                            {this.props.intl.formatMessage({id:"next"})}
                        </Button>} />;
      case 6:
        return <Patrimony handleNextBarProgress={handleNextBarProgress} handleComplete={handleComplete} organizationid={organizationid} back={handleBack} next={handleNext}  />;
       
      
        default:
          return <div>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link} {...{ to: `/pt/calleds` }}
                    >
                    {this.props.intl.formatMessage({id:"end"})}
                  </Button>
                </div>;

      
    }
  }

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;

    if(newActiveStep === 3)
    {
    
        if(hasValue === true || data.data === true )
        {
      
        
          setActiveStep(newActiveStep);
        }
        else
        {
    
          setActiveStep(newActiveStep+1);
        }
    }
    else
    {
      setActiveStep(newActiveStep);
    }
    
  };


  const handleBack = () => {
    if(activeStep - 1 === 3)
    {
        if(data.data == true || hasValue == true)
        {
          //setActiveStep(newActiveStep);
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
        else
        {
          setActiveStep((prevActiveStep) => prevActiveStep - 2);
        }
    }
    else
    {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
    
  };

  const handleNextBarProgress = () => {
    setActiveProgress((prevActiveStep) => prevActiveStep + 1);
    completedTask = completedTask +1 ;
  };



   async function handleStep(step) {

    const intl = this.props.intl;

    if(step ===3)
    {
      var result = await Service.getSLA(x);
      if (result.success) {

        if(result.data.length > 0 )
        {
          setData({data :true})
          hasValue = true;
          setActiveStep(step);


        }
        else {
        
            setnotification({ openNotification: true, notificationVariant: 'error',notificationMessage:this.props.intl.formatMessage({id:"wizard.register.sla.first"}) })
  
  
          
        }
    
        
      } 
    }
    else
    {
      setActiveStep(step);
    }

    
  };

  const handleCompleteInitials = (step) => {
    const newCompleted = completed;
    newCompleted[step] = true;
   
    if(step === 2)
    {
      hasValue = true;
      setData({data :true})
    }
    setCompleted(newCompleted);
    setActiveProgress((prevActiveStep) => prevActiveStep + 1);
    handleNext();
  };

  

  const checkStyle = (i) => {
   
    let arraystepcomplete = []

    
    arrayStespCompletedes.forEach(element => {
    
        if(element === i)
        {
          arraystepcomplete.push(i)
       
         
        }
    });
    
    if(arraystepcomplete.length > 0)
    {
     
      return "classeteste"
    }
    else
    {
     
      return ""
    }
    

  };
  const styleheader = () => {
   
    
    if(checkMenu === true)
    {
        
      return clsx(classes.content, {
        [classes.contentShift]: headerMenu.open,
      })
    }
    else
    {
     
      return clsx(classes.content)
    }
    
    

  };

  const componentHeader = () => {
   

    if(checkMenu === true)
    {
        return (

       
            <main
              className={clsx(classes.content, {
                [classes.contentShift]: headerMenu.open,
              })}
              />
  
        )
    }
    else
    {
    
     
      return (
        <main
              className={clsx(classes.content )}
              />
      )
    }
    
    

  };



  
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
 
  
    if(activeStep === 2)
    {
      hasValue = true;
      setData({data :true})
    }
   
    arrayStespCompletedes.push(activeStep)
  
    
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    //setActiveStep(0);
    //setCompleted({});
    history.push({
      pathname:`/pt/calleds`,
      
     });
  };

  return (
      
    
    <div className={classes.root}>
          <HeaderMenu />

          <main
              className={clsx(classes.content, {
                [classes.contentShift]: headerMenu.open,
              }, classes.actionsContent, {
                [classes.actionscontentShift]: actionsHeaderMenu.open,
              })}
              />

            <Container component='main' maxWidth='xl' style={{paddingLeft:0, marginLeft:0}}>
              
              <WizardOrganization location= {props.location}/>
            </Container>
         
          
        
              
        <Footer />   

        {loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

        <CustomizedSnackbars
              variant={notifications.notificationVariant}
              message={notifications.notificationMessage}
              isOpen={notifications.openNotification}
              toClose={closeNotification}
            />
    </div>



  );
}


/*
Wizard.propTypes = {
    save: PropTypes.func,
    organization: PropTypes.string,
    reset: PropTypes.func,
    orgId: PropTypes.string,
    attNotification: PropTypes.func
  };
  
  export default Wizard;
  */

 Wizard.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    history: PropTypes.object.isRequired,
    organizations: state.organizations,
    org: state.organization.organization

});

const mapDispatchToProps = dispatch =>
    bindActionCreators(calledsActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(Wizard)));