export default function customers(state = [], action) {
  switch (action.type) {
    case 'ADD_CURRENT':
      return {
        ...state,
        customer: action.customer,
      }
    default:
      return state;
  }
}