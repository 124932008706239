import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import React, { Component } from "react";
import PropTypes from 'prop-types'
import * as Service from '../../../services/config.service';
import { AllInbox, Inbox, Send } from "@mui/icons-material";
import { connect } from "react-redux";
import Api from "src/services/api";
import { PapiroConsole } from "src/utils/papiroConsole";
import * as MailBoxService from '../../../services/configmailbox.service'
import EmailSendingConfig from "./emailconfigtabcomponents/emailsendingconfig";
import EmailReadingConfig from "./emailconfigtabcomponents/emailreadingconfig";
import ConfigMailBoxList from "./emailconfigtabcomponents/configmailbosxlist";
import { injectIntl } from "react-intl";
import { Styles } from "src/styles/material-styles";
import { withStyles } from "@mui/styles";

class EmailConfigTab extends Component {
    constructor(props) {
        super(props)
        const { config, OrgsList } = this.props
        this.state = {
            config: {
                id: config.id,
                frommail: config.frommail == null ? '' : config.frommail,
                hostmail: config.hostmail == null ? '' : config.hostmail,
                portmail: config.portmail == null ? '' : config.portmail,
                usermail: config.usermail == null ? '' : config.usermail,
                passwordmail: "",
                mailtest: "",
                enablessl: config.enablessl == null ? false : config.enablessl,
                usemexxdefaultemailprovider: config.usemexxdefaultemailprovider ? config.usemexxdefaultemailprovider : false,
                frommailreceive: config.frommailreceive,
                emailorganizationid: config.emailorganizationid ? config.emailorganizationid : { id: 0, name: "" },
                accompanimentcalledforresponseemail: config.accompanimentcalledforresponseemail,
                responseemailisnewcalled: config.responseemailisnewcalled
            },
            OrgsList: OrgsList ? OrgsList : [],
            openDeleteProviderUserDialog: false,
            bottomNavigationTabValue: 0,
            configMailBoxList: [],
        }

        this.changeValues = this.changeValues.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.checkProviderDeletion = this.checkProviderDeletion.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.getConfigMailBoxByConfigId = this.getConfigMailBoxByConfigId.bind(this)
        this.handleDeleteItem = this.handleDeleteItem.bind(this)
        this.handleItemListClick = this.handleItemListClick.bind(this)
    }

    async componentDidMount() {
        await this.getConfigMailBoxByConfigId(this.state.config.id)
    }

    getConfigMailBoxByConfigId = async (configId) => {
        let { intl, setLoading, setMessage } = this.props
        setLoading(true)

        var result = await MailBoxService.getByConfigId(configId)

        if (result && result.success == true) {
            this.setState({ configMailBoxList: result.data })
            setLoading(false)
        } else {
            const error = result && result.errors && result.errors[0] ? result.errors[0] : intl.formatMessage({ id: "process.error" })
            setLoading(false)
            setMessage(true, 'error', error)
            Api.kickoff(result)
        }
    }

    handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState(prevState => ({
            config: {
                ...prevState.config,
                [name]: value
            }
        }));
    }

    changeValues = (stateName, value) => {
        this.setState(prevState => ({
            config: {
                ...prevState.config,
                [stateName]: value
            }
        }));
    }

    handleClick = async (e) => {

        let { intl, setLoading, setMessage } = this.props
        setLoading(true)

        if (this.state.config.mailtest.length > 0) {
            let result = await Service.testMail(this.state.config.mailtest);
            if (result && result.data && result.data.success == true) {
                setLoading(false)
                setMessage(true, 'success', intl.formatMessage({ id: "email.sent" }))
            } else {
                const error = result && result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                setLoading(false)
                setMessage(true, 'error', error)
                Api.kickoff(result)
            }
        } else {
            setLoading(false)
            setMessage(true, 'error', intl.formatMessage({ id: "missing.destination.email" }))
        }
    }

    checkProviderDeletion = (e) => {
        e.preventDefault();
        if (this.props.config.usemexxdefaultemailprovider != this.state.config.usemexxdefaultemailprovider && this.state.config.usemexxdefaultemailprovider == false) {
            this.setState({
                openDeleteProviderUserDialog: true
            })
        } else {
            this.handleSubmit(e)
        }
    }

    handleSubmit = async () => {
        let { intl, setLoading, setMessage } = this.props

        if (this.state.config.responseemailisnewcalled === true && this.state.config.accompanimentcalledforresponseemail === true) {
            setMessage(true, 'error', intl.formatMessage({ id: "organization.account.email.registration.email.answer.is.new.called" }))
        } else {
            setLoading(true)

            let data = new FormData()

            for (let key in this.state.config) {
                if (key.toString().toLowerCase().indexOf('emailorganization') > -1) {
                    if (this.state.config[key] && this.state.config[key].id) {
                        data.append(key, this.state.config[key].id);
                    }
                    else {
                        data.append(key, 0);
                    }
                } else {
                    data.append(key, this.state.config[key]);
                }
            }
            let apiConfig = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            await Api.put('/configs/emailconfig', data, apiConfig)
                .then(result => {
                    if (result.data.success) {
                        const formattedList = result.data.data;
                        const intl = this.props.intl;
                        setLoading(false)
                        setMessage(true, 'success', intl.formatMessage({ id: "edit.success" }))
                    }
                    else {
                        const error = result && result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
                        setLoading(false)
                        setMessage(true, 'error', error)
                    }
                })
                .catch(err => {
                    if (err.response && err.response.data && err.response.data.errors) {
                        const error = err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                        setLoading(false)
                        setMessage(true, 'error', error)
                    }
                    else {
                        const error = err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }) + err.response.data
                        setLoading(false)
                        setMessage(true, 'error', error)
                    }
                })
        }
    }

    updateOpenDeleteProviderUserDialog = () => {
        this.setState({ openDeleteProviderUserDialog: false })
    }

    handleDeleteItem = async (item, resolve) => {
        let { intl, setLoading, setMessage } = this.props

        setLoading(true)

        const deleteresult = await MailBoxService.deleteById(item.id)
        
        if (deleteresult.success) {
            const configMailBoxListFiltered = this.state.configMailBoxList.filter(c => c.id != item.id)
            this.setState({ configMailBoxList: configMailBoxListFiltered })
            setLoading(false)
        } else {
            const error = deleteresult && deleteresult.errors && deleteresult.errors[0] ? deleteresult.errors[0] : intl.formatMessage({ id: "process.error" })
            setLoading(false)
            setMessage(true, 'error', error)
            Api.kickoff(deleteresult)
        }
        resolve()
    }

    handleItemListClick = async (item) => {
        this.props.history.push({ pathname: `/${this.props.lang}/configmailbox-edit/${item.id}` })
    }

    render() {
        const { classes, intl, lang } = this.props
        const { config, bottomNavigationTabValue, OrgsList, openDeleteProviderUserDialog, configMailBoxList } = this.state

        return (
            <div>
                <BottomNavigation showLabels value={bottomNavigationTabValue} onChange={(e, value) => this.setState({ bottomNavigationTabValue: value })}>
                    <BottomNavigationAction
                        label={intl.formatMessage({ id: "mail.sending" })}
                        icon={<Send />}
                        style={{
                            backgroundColor: bottomNavigationTabValue == 0 ? "#EEE" : "#FFF",
                            textOverflow: "elipsis", overflow: "hidden", whiteSpace: "pre"
                        }}
                    />
                    <BottomNavigationAction
                        label={intl.formatMessage({ id: "mail.receiving" })}
                        icon={<Inbox />}
                        style={{
                            backgroundColor: bottomNavigationTabValue == 1 ? "#EEE" : "#FFF",
                            textOverflow: "elipsis", overflow: "hidden", whiteSpace: "pre"

                        }}
                    />
                    <BottomNavigationAction
                        label={intl.formatMessage({ id: "mail.box.config.register" })}
                        icon={<AllInbox />}
                        style={{
                            backgroundColor: bottomNavigationTabValue == 2 ? "#EEE" : "#FFF",
                            textOverflow: "elipsis", overflow: "hidden", whiteSpace: "pre"
                        }}
                    />
                </BottomNavigation>
                {
                    bottomNavigationTabValue == 0
                    &&
                    <EmailSendingConfig
                        config={config}
                        classes={classes}
                        intl={intl}
                        handleInputChange={this.handleInputChange}
                        changeValues={this.changeValues}
                        handleSubmit={this.handleSubmit}
                        checkProviderDeletion={this.checkProviderDeletion}
                    />
                }
                {
                    bottomNavigationTabValue == 1
                    &&
                    <EmailReadingConfig
                        config={config}
                        classes={classes}
                        intl={intl}
                        orgsList={OrgsList}
                        checkProviderDeletion={this.checkProviderDeletion}
                        handleInputChange={this.handleInputChange}
                        changeValues={this.changeValues}
                        updateOpenDeleteProviderUserDialog={this.updateOpenDeleteProviderUserDialog}
                        handleSubmit={this.handleSubmit}
                        openDeleteProviderUserDialog={openDeleteProviderUserDialog}
                    />
                }
                {
                    bottomNavigationTabValue == 2
                    &&
                    <ConfigMailBoxList
                        classes={classes}
                        intl={intl}
                        configMailBoxList={configMailBoxList}
                        deleteItem={this.handleDeleteItem}
                        handleItemListClick={this.handleItemListClick}
                        lang={lang}
                    />
                }
            </div>
        )
    }
}

EmailConfigTab.propTypes = {
    classes: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
    setMessage: PropTypes.func.isRequired,
    orgslist: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
}

export default injectIntl(connect(null, null)(withStyles(Styles)(EmailConfigTab)))