import React from 'react';
import Path from '../../../images/attachment.png';
import { GetImageMeta as GetImageMetaService } from '../../../../../services/2talk/omnichannel';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import './styles.css'

function ChatMessageDocument({ content, id, setLoading, src, title }) {
  const download = async e => {
    e.preventDefault()

    if (e.target.classList.contains('omnichannel-chat-message-document') || e.target.classList.contains('omnichannel-chat-message-document-icon') || e.target.classList.contains('omnichannel-chat-message-document-name')) {
      setLoading(true)
      let result = await GetImageMetaService(id)
      if (result && result.success && result.data && result.data.url)
        window.open(result.data.url)
      setLoading(false)
    }
  }

  return (<div
      className="omnichannel-chat-message-document"
      onClick={download} >
    <div className="omnichannel-chat-message-document-icon" >
      <FilePresentIcon style={{ color: '#545454', fontSize: '3em' }} />
    </div>
    <div className="omnichannel-chat-message-document-name" >
      {content}
    </div>
  </div>)
};

export default ChatMessageDocument;
