import React from 'react';
import Path from '../../../images/attachment.png';
import { GetImageMeta as GetImageMetaService } from '../../../../../services/2talk/omnichannel';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';import './styles.css'
import AudioPlayer from '../../../../../components/audio-player';

function ChatMessageAudio({ content, id, setLoading }) {
  const [src, setSrc] = React.useState("")
  
  React.useEffect(() => {
    const getAudioLink = async () => {
      setLoading(true)
      let result = await GetImageMetaService(id)
      if (result && result.success && result.data && result.data.url)
        setSrc(result.data.url)
      setLoading(false)
    }

    if (id) {
      getAudioLink()
    }
  }, []);

  return (<div className="omnichannel-chat-message-audio" >
    <div className="omnichannel-chat-message-audio-name" >
      {src.length > 0 && <AudioPlayer audioUrl={src} />}
    </div>
  </div>)
};

export default ChatMessageAudio;
