export function addUserSession(user) {
    return {
        type: 'ADD_USER',
        user,
    }
}

export function removeUserSession() {
    localStorage.removeItem('accesstoken');
    localStorage.removeItem('accesstokenreports');
    localStorage.removeItem('accesstoken2talk');
  return {
        type: 'REMOVE_USER'
    }
}

