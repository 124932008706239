import SmartToyIcon from '@mui/icons-material/SmartToy';
import { Avatar, Button, CircularProgress, Container, CssBaseline, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import { withStyles } from '@mui/styles';
import { styled } from '@mui/system';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import HeaderMenu from "../../components/header-menu";
import CustomizedSnackbars from "../../components/material-snackbars/index.js";
import { AbilityContext } from '../../config/ability-context';
import { browserHistory } from '../../helpers/history';
import * as componentActions from '../../redux/actions/chatbots.js';
import Api from "../../services/api";
import { Overlay } from "../../styles/global.js";
import { Styles } from '../../styles/material-styles';


import { PapiroConsole } from "../../utils/papiroConsole.js";
import ChatbotFlows from './components/chatbot-flows/index.js';
import ChatbotGeneralData from "./components/chatbot-general-data/index.js";

const MyPaper = styled(Paper)({
  backgroundColor: (theme) => theme.palette.background.paper,
});

const MyTabs = styled(Tabs)({
  backgroundColor: (theme) => theme.palette.primary.main,
  color: (theme) => theme.palette.primary.contrastText,
});

class ChatbotEdit extends Component {

  constructor(props) {
    super(props);

    const { userSession, chatbots } = this.props;

    this.globalAdm = false;
    this.isClient = true;

    if (userSession == null || userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    if (!chatbots || (chatbots && !chatbots.chatbot)) {
      var lang = this.props.match.params.lang;
      document.location.href = `/${lang}/chatbots`
    }

    this.state = {
      loading: false,
      tabSelected: 0,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      openCancelRegisterNumber: false,
      tabSelected: 0,
      isDefault: false,
    };


    this.closeNotification = this.closeNotification.bind(this);
    this.getNotification = this.getNotification.bind(this);
    this.setLoadingState = this.setLoadingState.bind(this);
    this.setIsDefault = this.setIsDefault.bind(this);
  }

  handleChange = (event, newValue) => {
    this.setState({ tabSelected: newValue });
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }


  getNotification(loading, openNotification, notificationVariant, notificationMessage) {

    this.setState({ loading: loading, openNotification: openNotification, notificationVariant: notificationVariant, notificationMessage: notificationMessage });
  }

  setLoadingState(loading) {

    this.setState({ loading: loading });
  }

  setIsDefault(value) {
    PapiroConsole.log(value)
    PapiroConsole.log("setIsDefault")
    this.setState({ isDefault: value})
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu, intl, chatbots } = this.props;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary' onClick={browserHistory.goBack}><FormattedMessage id="back" /></Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar mt={7} style={{ backgroundColor: '#303f9f', color: 'white' }}>
                <SmartToyIcon />
              </Avatar>
              <Typography component='h1' variant='h5' mt={2}>
                {intl.formatMessage({ id: "edit.chatbot" })}
              </Typography>

              <div style={{ marginTop: '20px' }}>
                <MyPaper square>
                  <MyTabs
                    value={this.state.tabSelected}
                    onChange={this.handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    centered
                  >
                    <Tab label="Dados Gerais" />
                    <Tab label="Fluxo chatbot" />
                  </MyTabs>
                </MyPaper>
                
                {this.state.tabSelected === 0 && <ChatbotGeneralData getNotification={this.getNotification} setLoadingState={this.setLoadingState} chatbot={chatbots.chatbot} />}

                {this.state.tabSelected === 1 && <ChatbotFlows />}
              </div>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
      </div>
    )
  }
}

ChatbotEdit.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  chatbots: state.chatbots
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ChatbotEdit)));

ChatbotEdit.contextType = AbilityContext;