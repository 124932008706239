import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import PolicyPassowordValidation from "./policyPasswordValidation";
import Box from '@mui/material/Box';

function formattedPolicyEntries(configPasswordPolicy) {
  console.log(configPasswordPolicy)
  console.log("configPasswordPolicy")
  let newEntriesPolicy = new Array;
  for (const [key, value] of Object.entries(configPasswordPolicy)) {
    if(key != "hirerid" && key != "configid" && key != "notallowcaracterlinkeduser" && key != "passwordtimeexpiration") {
      if((key == "minimumamountcharacters" && value != null && value > 0) || value == true){
        newEntriesPolicy.push(key);
      }
    }
  }
  return newEntriesPolicy;
}

export default function PasswordPolicy(props) {
  const { configPasswordPolicy, password } = props;

  const [atLeastOneSpecialCaracter, setAtLeastOneSpecialCaracter] = useState(false);
  const [atLeastOneNumber, setAtLeastOneNumber] = useState(false);
  const [atLeastOneLetter, setAtLeastOneLetter] = useState(false);
  const [atLeastOneLetterUpperCase, setAtLeastOneLetterUpperCase] = useState(false);
  const [minimumAmountCharacters, setMinimumAmountCharacters] = useState(false);

  const [isRepeatNumber, setIsRepeatNumber] = useState(false);
  const [isSequentialNumber, setIsSequentialNumber] = useState(false);
  const entriesConfigPolicys = configPasswordPolicy != null ? formattedPolicyEntries(configPasswordPolicy) : null;
  
  useEffect(() => {

    validateAtLeastOneSpecialCaracter();
    validateAtLeastOneNumber();
    validateAtLeastOneLetter();
    validateAtLeastOneLetterUpperCase();

    if(password.length > 0) {
      setIsRepeatNumber(!validateRepeatNumber());
      setIsSequentialNumber(!validateSequentialNumber());
      validateMinimumAmountCharacters();
    } else {
      setIsRepeatNumber(false);
      setIsSequentialNumber(false);
      setMinimumAmountCharacters(false);
    }
    
  });

  const validateRepeatNumber = () => {
    let i = 0;
    if (password == null || password.length === 0) {
      return false
    }
    while (i < password.length) {
      const character = password.charAt(i)
      if(!isNaN(character) && password.split(character).length > 2) {
        return true;
      }
      i++
    }
    return false
  };

  const validateSequentialNumber = () => {
    if (password == null) {
      return false
    }
    for(var i in password) 
      if (+password[+i+1] == +password[i]+1) return true;
    return false;
  };

  const validateAtLeastOneLetterUpperCase = () => {
    let i = 0;
    if (password == null) {
      setAtLeastOneLetterUpperCase(false);
      return false
    }
    if (password == null || password.length === 0) {
      setAtLeastOneLetterUpperCase(false);
    }
    while (i < password.length) {
      const character = password.charAt(i)
      if (character == character.toLowerCase()) {
        // O Caracter é lowercase, numeric ou simbolo
      } else if (character == character.toUpperCase()) {
        setAtLeastOneLetterUpperCase(true);
      }
      i++
    }
  };

  const validateAtLeastOneLetter = () => {
    if(/[a-z]/g.test(password)) {
      setAtLeastOneLetter(true);
    } else {
      setAtLeastOneLetter(false);
    }
  };

  const validateAtLeastOneNumber = () => {
    if(/\d/g.test(password)) {
      setAtLeastOneNumber(true);
    } else {
      setAtLeastOneNumber(false);
    }
  };

  const validateAtLeastOneSpecialCaracter = () => {
    if(/[~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]/g.test(password)) {
      setAtLeastOneSpecialCaracter(true);
    } else {
      setAtLeastOneSpecialCaracter(false);
    }
  };

  const validateMinimumAmountCharacters = () => {
    if(configPasswordPolicy && configPasswordPolicy.minimumamountcharacters){
      if(!configPasswordPolicy.minimumamountcharacters || password.length >= configPasswordPolicy.minimumamountcharacters) {
        setMinimumAmountCharacters(true);
      } else {
        setMinimumAmountCharacters(false);
      }
    }
    
  };

  const getNameValidate = (name) => {
    switch (name) {
      case 'minimumamountcharacters':
        return minimumAmountCharacters
      case 'notallowrepeatnumber':
        return isRepeatNumber
      case 'notallowsequentialnumber':
        return isSequentialNumber
      case 'atleastonenumber':
        return atLeastOneNumber
      case 'atleastoneletter':
        return atLeastOneLetter
      case 'atleastonespecialcaracter':
        return atLeastOneSpecialCaracter
      case 'atleastoneletteruppercase':
        return atLeastOneLetterUpperCase
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: '#e9e9e9',
      }}
    >
      {configPasswordPolicy != null && entriesConfigPolicys.map(item => {
        if (item !== 'passwordexpirationtime') {
          return PolicyPassowordValidation({ 
            name: item, 
            isValid: getNameValidate(item),
            numMinimo: configPasswordPolicy.minimumamountcharacters
          });
        }
      })}
    </Box>
  );
}

PasswordPolicy.propTypes = {
  configPasswordPolicy: PropTypes.object,
  password: PropTypes.string,
};
