export function addCurrent(channel) {
  return {
    type: 'ADD_CURRENT',
    channel,
  }
}

export function updatedChannelTechinicalTeams(channeltechinicalteams) {
  console.log("entrei aqui 1")
  return {
      type: 'UPDATE_CHANNEL_TECHINICALTEAMS',
      channeltechinicalteams,
  }
}
