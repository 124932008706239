export function addCurrent(organizationsetting) {
    return {
        type: 'ADD_CURRENT',
        organizationsetting,
    }
}
export function addCurrentOrganization(organizationsetting, organization) {
    return {
        type: 'ADD_CURRENT_ORGANIZATION',
        organizationsetting,
        organization,
    }
}
