export default function organizationcalendars(state = [], action) {
    switch (action.type) {
        case 'ADD_CURRENT':
            return {
                ...state,
                organizationcalendar: action.organizationcalendar,
            }
        case 'ADD_CURRENT_ORGANIZATION':
            return {
                ...state,
                organizationcalendar: action.organizationcalendar,
                organization: action.organization,

            }

        default:
            return state;
    }
}
