import React from 'react'
import ChatMenuHeader from './header'
import ChatMenuUserList from './users-list'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Styles } from '../../../../styles/material-styles'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@mui/styles'
import './styles.css'

function ChatMenu(props) {
  return (<div className="omnichannel-chat-menu">
    <ChatMenuHeader techinicalTeamList={props.techinicalTeamList} phoneNumberList={props.phoneNumberList}
            channelList={props.channelList}
            providerList={props.providerList}
            statusList={props.statusList}
            changeValues={props.changeValues}
            handleInputChange = {props.handleInputChange}
            getChatsByTechWithFilters = {props.getChatsByTechWithFilters}
            />
    <ChatMenuUserList />
  </div>)
}

ChatMenu.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession
})

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(ChatMenu)))