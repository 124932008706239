import { IconButton, Table, TableCell, TableContainer, TextField } from '@mui/material';
import styled from 'styled-components';

export const StyledTableContainer = styled(TableContainer)`
  width: 100%;
  margin: 20px auto;
`;

export const StyledTable = styled(Table)`
  min-width: 650px;
  background-color: #ffffff;
`;

export const StyledTableCell = styled(TableCell)`
  && {
    font-weight: bold;
    text-align: center;
    border: 1px solid #e0e0e0;  /* Linha de divisão */
  }
`;

export const StyledTableBodyCell = styled(TableCell)`
  && {
    text-align: center;
    border: 1px solid #e0e0e0;  /* Linha de divisão */
  }
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
`;

export const StyledIconButton = styled(IconButton)`
  && {
    color: ${props => props.delete ? '#f44336' : '#687EFC'};
    &:hover {
      color: ${props => props.delete ? '#f44336' : '#3f51b4'};
    }
  }
`;