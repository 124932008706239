import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import { PapiroConsole } from '../../utils/papiroConsole';
import ReactQuill, { Quill }  from 'react-quill'; // ES6
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleSelect from '../../components/select/simple';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import SimpleAutoCompleteParentCategory from '../../components/auto-complete/autocomplete-parent-category';
import SimpleAutoCompleteMultiple from '../../components/auto-complete/autocomplete-multiple-create';
import SimpleCheckBox from '../../components/checkbox/check';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as Service from '../../services/organizationcategory.service';
import * as OrgDepartamentService from '../../services/organizationdepartament.service';
import Title from '../../components/title-name';
import SimpleHelp from '../../components/help';
import * as PriorityService from '../../services/priority.service'
import { Pa } from 'react-flags-select';
import AttachFileIcon from '@mui/icons-material/AttachFile';

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida"
];
Quill.register(Font, true);
class OrganizationCategoryRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession, organizations } = this.props;

    this.fromProps = false
    if(this.props && this.props.location && this.props.location.state && this.props.location.state.from && this.props.location.state.from.length > 0)
      this.fromProps = true

    this.globalAdm = false;
    this.isClient = true;
    if (userSession.user.userrole == null) {
      Api.logoff();
    }
    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    }
    );

    this.reactQuillModules = {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean'],	
      ],
    }
  
    this.reactQuillFormats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image','color','font'
    ]

    var defaultvalue = {id:0, name:''};
    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      OrganizationId: 0,
      TypeId: 0,
      PriorityId: 0,
      OrganizationText: '',
      TypeText: '',
      OrganizationSlaId: 0,
      OrganizationSlaText: '',
      CalledFlowId: 0,
      CalledFlowText: '',
      SlaCountTypeId: 0,
      SlaCountTypeText: '',
      CategoryId: 0,
      CategoryText: '',
      ParentOrganizationCategoryId: defaultvalue,
      ParentOrganizationCategoryText: '',
      name: '',
      cancreatecalled: false,
      imageicon: '',
      parentname: '',
      servicetime: '',
      solutiontime: '',
      useslatime: false,
      serviceslanotification: false,
      isdefaultcategory: false,
      DepartamentId: 0,
      DepartamentText: '',
      caneditcalledtitleanddescription: false,
      saveToAllOrganizationsInGroup: false,
      AppearsOnPortal : false,
      inactive: false
    };
    this.state = {
      files: [],
      item: this.initialState,
      organizations: organizations,
      loading: false,
      openNotification: false,
      descriptionCategory: "",
      categoryInformation: "",
      notificationVariant: 'error',
      notificationMessage: '',
      OrganizationList: [],
      TypeList: [],
      OrganizationSlaList: [],
      CalledFlowList: [],
      SlaCountTypeList: [],
      CategoryList: [],
      ParentOrganizationCategoryList: [],
      DepartamentList: [],
      priorityList: [],
      template: true,
      disableFields: false,
      template2: true

    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.getTemplate = this.getTemplate.bind(this);
    this.handleChangeEditorInformation = this.handleChangeEditorInformation.bind(this);
    this.handleChangeEditorDescription = this.handleChangeEditorDescription.bind(this);
    this.removeItem = this.removeItem.bind(this);
    
  }

  verifyItems() {
    //console.log('template '+ this.state.template);

  }

  componentDidMount() {
    if (this.state.organizations && this.state.organizations.organization && this.state.organizations.organization.id) {
      PapiroConsole.log("entrei aqui")
      this.getOrganizationsById(this.state.organizations.organization.id);
    } else {
      PapiroConsole.log("entrei aqui 2")
      this.getOrganizations();
    }

    this.getTypes();
    this.getPriorities()
    //this.getOrganizationSlas();
    this.getCalledFlows();
    this.getSlaCountTypes();
    this.getCategories();
    this.getOrganizationDepartaments()
    //this.getParentOrganizationCategories();
  };

  getPriorities = async () => {
    this.setState({ loading: true });
    let result = await PriorityService.getAllByHirerId()
    if(result && result.success) {
        if(result.data != null)
            this.setState({ loading: false, priorityList: result.data })
    } else {
        const intl = this.props.intl
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(result)
    }
  }

  async getOrganizations() {
    this.setState({ loading: true });
    var result = await Service.getOrganizations();
    if (result.success) {
      this.setState({ loading: false, OrganizationList: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };

  handleChangeEditorInformation(value) {
    //this.setState({ description: value });
    
    this.setState(prevState => ({
      categoryInformation : value
  }));


  }

  handleChangeEditorDescription(value) {
    //this.setState({ description: value });

    this.setState(prevState => ({
          descriptionCategory : value
      }));


  }

  getOrganizationsById = async (id) => {
    this.setState({ loading: true });
    var result = await Service.getOrganizationsByIdLight(id);
    if (result.success) {
        this.setState({ loading: false, OrganizationList: [result.data] });

    } else {
        this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);
    }
  };

  async getTypes() {
    //this.setState({ loading: true });
    var result = await Service.getTypes();
    if (result.success) {
      this.setState({ loading: false, TypeList: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };

  validateImagesAtachments(files) {

    
    const intl = this.props.intl;
    if(files.length > 1) {
      this.setState({
        evAttachFile: {},
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"checklist.modal.select.image.error"}),
    })
      return
    }
    else{
      this.setState({ files: files }) 
    }
    
  }

  async getOrganizationSlas(id = 0) {
    this.setState({ loading: true });
    var result = await Service.getOrganizationSlas(id);
    if (result.success) {
      this.setState({ loading: false, OrganizationSlaList: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }; async getCalledFlows() {
    this.setState({ loading: true });
    var result = await Service.getCalledFlows();
    if (result.success) {
      this.setState({ loading: false, CalledFlowList: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }; async getSlaCountTypes() {
    this.setState({ loading: true });
    var result = await Service.getSlaCountTypes();
    if (result.success) {
      this.setState({ loading: false, SlaCountTypeList: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }; async getCategories() {
    this.setState({ loading: true });
    var result = await Service.getCategoriesList();
    if (result.success) {
      this.setState({ loading: false, CategoryList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };

  async getParentOrganizationCategories(id) {
    this.setState({ loading: true });
    var result = await Service.getParentOrganizationCategoriesList(id);
    if (result.success) {
      this.setState({ loading: false, ParentOrganizationCategoryList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };

  async getOrganizationDepartaments(id) {
    this.setState({ loading: true });
    var result = await OrgDepartamentService.getOrganizationsDepartaments(id);
    if (result.success) {
      this.setState({ loading: false, DepartamentList: result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));

    this.verifyItems();

  };

  handleSubmit = async e => {
    const intl = this.props.intl;
    e.preventDefault();

    this.setState({ loading: true });
    var data = new FormData();

    if (!this.state.template) {
      
      var departamentIds = "";
      if (this.state.item.DepartamentId) {
        this.state.item.DepartamentId.forEach(element => {
          departamentIds = departamentIds + element.id + ",";
        });
      }

      PapiroConsole.log(this.state.item)
      PapiroConsole.log("this.state.item")
      
      for (let key in this.state.item) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format("YYYY/MM/DD HH:mm:ss"));
        } else {
        
          if(key == "ParentOrganizationCategoryId"){

            if(this.state.item[key].id){
              data.append(key, this.state.item[key].id);
            }
            else{
              data.append(key, 0);
            }
            
          }
          else if(key == "DepartamentId" || key == "CategoryId" ){
           
            data.append(key, 0);
            
          }
          else{
            data.append(key, this.state.item[key]);
          }
        }
      }

      for (let key in this.state) {
          
        if(key == "descriptionCategory"){
          
          data.append("Description", this.state[key]);
        } 
        else if(key == "categoryInformation"){
          
          data.append("Information", this.state[key]);
        } 
        
        
      }
      //data.append("CategoryId",0)
      data.append("Departaments",departamentIds)
      this.state.files.forEach(file => data.append('filesUpload', file));


      let config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };



      
      PapiroConsole.log("estou aqui")
      PapiroConsole.log("item state")
      PapiroConsole.log(this.state.item)
      Api.post('/organizationcategories/add', data,config)
        .then(result => {
          
          if (result.data.success) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({ id: "add.success" }),
              item: this.initialState,
              files: [],
              descriptionCategory : '',
              categoryInformation : ''
            });

            //document.getElementsByClassName('MuiAutocomplete-clearIndicator')[1].click()
            this.getOrganizations();
            this.getOrganizationSlas();
            this.getCalledFlows();
            this.getSlaCountTypes();
            this.getCategories();
            this.getOrganizationDepartaments()
            //this.getParentOrganizationCategories(data.OrganizationId);
          }
          else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }) + err.response.data
            });
          }
          Api.kickoff(err);
        });
    } else {

      PapiroConsole.log("entrei no else aqui")

      var categoryIds = "";
      if (this.state.item.CategoryId) {
        this.state.item.CategoryId.forEach(element => {
          categoryIds = categoryIds + element.id + ",";
        });
      }

      var departamentIds = "";
      if (this.state.item.DepartamentId) {
        this.state.item.DepartamentId.forEach(element => {
          departamentIds = departamentIds + element.id + ",";
        });
      }

      
      /*var data = {
        Name: intl.formatMessage({id:"menu.categories"}),
        ParentName: intl.formatMessage({id:"tutorial.pages.categories.categories.father"}),
        CategoryId: 0,
        ServiceTime: this.state.item.servicetime,
        SolutionTime: this.state.item.solutiontime,
        CanCreateCalled: this.state.item.cancreatecalled,
        UseSLATime: this.state.item.useslatime,
        ServiceSLANotification: this.state.item.serviceslanotification,
        IsDefaultCategory: this.state.item.isdefaultcategory,
        OrganizationId: this.state.item.OrganizationId,
        TypeId: this.state.item.TypeId,
        PriorityId: this.state.item.PriorityId,
        OrganizationSLAId: this.state.item.OrganizationSlaId,
        CalledFlowId: this.state.item.CalledFlowId,
        SLACountTypeId: this.state.item.SlaCountTypeId,
        ParentOrganizationCategoryId: this.state.item.ParentOrganizationCategoryId ? this.state.item.ParentOrganizationCategoryId.id : 0,
        CreatedAt: this.state.item.createdAt,
        CreatedUser: this.state.item.createdUser,
        Categories: categoryIds,
        CreateChildren: this.state.template2,
        DepartamentId: 0,
        Departaments: departamentIds,
        saveToAllOrganizationsInGroup: this.state.item.saveToAllOrganizationsInGroup
      }*/

      this.state.files.forEach(file => data.append('filesUpload', file));

      for (let key in this.state.item) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format("YYYY/MM/DD HH:mm:ss"));
        } else {
          
          if(key == "ParentOrganizationCategoryId" || key == "CategoryId" ){

            if(this.state.item[key].id){
              data.append(key, this.state.item[key].id);
            }
            else{
              data.append(key, 0);
            }
            
          }
          else if(key == "DepartamentId"){
           
            data.append(key, 0);
            
          }
          else{
            data.append(key, this.state.item[key]);
          }
        }
      }

      PapiroConsole.log(this.state.item)
      PapiroConsole.log("console this.state.item")
      for (let key in this.state) {
        

        if(key == "descriptionCategory"){
          
          data.append("Description", this.state[key]);
        } 
        else if(key == "categoryInformation"){
          
          data.append("Information", this.state[key]);
        } 
        
      }
     
      data.append("Categories",categoryIds)
      data.append("CreateChildren",this.state.template2)
      data.append("Name",intl.formatMessage({id:"menu.categories"}))
      data.append("ParentName",intl.formatMessage({id:"tutorial.pages.categories.categories.father"}))
      //data.append("CategoryId",0)
     // data.append("ParentOrganizationCategoryId", ParentOrganizationCategoryId)
      //data.append("DepartamentId",0)
      
      data.append("Departaments",departamentIds)
      

      let config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };
      
      //data['ParentOrganizationCategoryId'] = this.state.ParentOrganizationCategoryId;
      //console.log(this.state.ParentOrganizationCategoryId);
      //data['ParentOrganizationCategoryId'] = data['ParentOrganizationCategoryId']['id'];
      
      Api.post('/organizationcategories/add', data,config)
        .then(result => {
          
          if (result.data.success) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({ id: "add.success" }),
              item: this.initialState,
              files: []
            });
            this.getOrganizations();
            //this.getOrganizationSlas();
            this.getCalledFlows();
            this.getSlaCountTypes();
            this.getCategories();
            this.getOrganizationDepartaments()
            //this.getParentOrganizationCategories(data.OrganizationId);
          }
          else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

            });
            Api.kickoff(result);
          }

        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }) + err.response.data
            });
          }
          Api.kickoff(err);
        });
    }
  };
  getTemplate(id) {

    Api.get(`/categories/${id}`)
      .then(result => {
        if (result.data.success) {
          /*
          this.setState(prevState => ({
            item: {
              ...prevState.item,
                  cancreatecalled: result.data.data.cancreatecalled ? result.data.data.cancreatecalled: false,
                  name: result.data.data.name ? result.data.data.name : '',
            }
          }));*/
          this.setState({ loading: false });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
          Api.kickoff(result);

        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(err);

      });
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  async changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));
    if (stateName === 'OrganizationId') {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          DepartamentId: 0,
          ['OrganizationText']: text,
        }
      }));
      this.getParentOrganizationCategories(value);
      this.getOrganizationSlas(value);
      await this.getOrganizationDepartaments(value)
    }

    if (stateName === 'TypeId') {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['TypeText']: text,
        }
      }));
    }
    if (stateName === 'OrganizationSlaId') {
      if (value !== 0 && this.state.item.useslatime) {
        let result = await Service.getSlaById(value);
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            ['servicetime']: result.data.servicetime,
            ['solutiontime']: result.data.solutiontime
          },
          disableFields: true
        }));
        //this.state.item.solutiontime = result
      } else {
        this.setState(prevState => ({
          disableFields: false
        }));
      }
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['OrganizationSlaText']: text,
        }
      }));
    }
    if (stateName === 'CalledFlowId') {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['CalledFlowText']: text,
        }
      }));
    }
    if (stateName === 'SlaCountTypeId') {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['SlaCountTypeText']: text,
        }
      }));
    }
    if (stateName === 'CategoryId') {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['CategoryText']: text,
        }
      }));
    }
    if (stateName === 'DepartamentId') {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['DepartamentText']: text,
        }
      }));
    }
    if (stateName === 'ParentOrganizationCategoryId') {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['ParentOrganizationCategoryText']: text,
        }

      }));
    }

    if (stateName == 'CategoryId' && this.state.template === true) {
      this.getTemplate(value.id);
    }
    if (stateName === 'useslatime') {
      if (value && this.state.item.OrganizationSlaId !== 0) {
        let result = await Service.getSlaById(this.state.item.OrganizationSlaId);
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            ['servicetime']: result.data.servicetime,
            ['solutiontime']: result.data.solutiontime
          },
          disableFields: true
        }));
      } else {
        this.setState(prevState => ({
          disableFields: false
        }));
      }
    }
    if (stateName === 'inactive') {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          ['inactive']: !value,
        }

      }));
    }

    this.setState({ loading: false });

    //console.log(value)
  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
  render() {

    
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

   

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary' onClick={() => {
                    this.fromProps ? this.props.history.push(`/${this.props.match.params.lang}/organization-edit`, { organizations: this.props.organizations, propTab: 7 }) : browserHistory.goBack()}
                  }>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="add.item" />}
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>

                <Grid container spacing={2}>
                  <Grid item xs={11} sm={11}>
                    <SimpleSelect label={intl.formatMessage({ id: "organization" })} options={this.state.OrganizationList} stateName='OrganizationId' changeSelect={this.changeValues} selected={item.OrganizationId} required />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleSelect label={intl.formatMessage({ id: "type" })} options={this.state.TypeList} stateName='TypeId' changeSelect={this.changeValues} selected={item.TypeId} isRequired />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <SimpleHelp message={this.props.intl.formatMessage({id:"organization.category.edit.default.called.type"})} />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleSelect label={intl.formatMessage({ id: "priority" })} options={this.state.priorityList} stateName='PriorityId' changeSelect={this.changeValues} selected={item.PriorityId} isRequired />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <SimpleHelp message={this.props.intl.formatMessage({id:"organization.category.edit.default.called.priority"})} />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleAutoCompleteMultiple label={intl.formatMessage({ id: "departament" })} options={this.state.DepartamentList} stateName='DepartamentId' changeSelect={this.changeValues} selected={item.DepartamentId} />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <SimpleHelp message={this.props.intl.formatMessage({id:"organization.category.edit.departament"})} />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleCheckBox label={intl.formatMessage({ id: "organization.category.inform.category" })} selected={true} name='template' stateName='template'
                      changeSelect={(event, newValue) => {
                        this.setState({
                          template: newValue
                        });
                      }} />
                  </Grid>

                {this.state.template && (
                    <Grid item xs={11} sm={11}>
                      <SimpleCheckBox label={this.props.intl.formatMessage({id:"organization.category.registration.register.filters"})} selected={true} name='template2' stateName='template2'
                        changeSelect={(event, newValue) => {
                          this.setState({
                            template2: newValue
                          });
                        }} />
                    </Grid>
                )}

                {this.state.template && (
                    <Grid item xs={11} sm={11}>
                      <SimpleAutoCompleteMultiple label={intl.formatMessage({ id: "category" })} options={this.state.CategoryList} stateName='CategoryId' changeSelect={this.changeValues} />
                    </Grid>)}
                {this.state.template && (
                    <Grid item xs={1} sm={1}>
                      <SimpleHelp message={this.props.intl.formatMessage({id:"organization.category.edit.cateogory"})} />
                    </Grid>
                )}

                {!this.state.template && (
                    <Grid item xs={11} sm={11}>
                      <SimpleAutoCompleteParentCategory label={intl.formatMessage({ id: "organization.category.parent" })} options={this.state.ParentOrganizationCategoryList} stateName='ParentOrganizationCategoryId' created={true} changeSelect={this.changeValues} />
                    </Grid>)}
                {!this.state.template && (
                    <Grid item xs={1} sm={1}>
                      <SimpleHelp message={this.props.intl.formatMessage({id:"organization.category.registration.parent.category"})} />
                    </Grid>
                )}
                {!this.state.template && (
                    <Grid item xs={11} sm={11}>
                      <TextField fullWidth label={intl.formatMessage({ id: "name" })} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} required />
                    </Grid>
                )}
                  <Grid item xs={11} sm={11}>
                    <SimpleSelect label={intl.formatMessage({ id: "organization.category.sla" })} options={this.state.OrganizationSlaList} stateName='OrganizationSlaId' changeSelect={this.changeValues} selected={item.OrganizationSlaId} required />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <SimpleHelp message={this.props.intl.formatMessage({id:"organization.category.edit.category.sla"})} />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleCheckBox label={intl.formatMessage({ id: "organization.category.time.sla" })} name='useslatime' stateName='useslatime' changeSelect={this.changeValues} selected={item.useslatime} />
                  </Grid>
                  <Grid item xs={11} sm={11} style={{ display: 'none' }}>
                    <SimpleSelect label={intl.formatMessage({ id: "flow" })} options={this.state.CalledFlowList} stateName='CalledFlowId' changeSelect={this.changeValues} selected={item.CalledFlowId} />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleSelect label={intl.formatMessage({ id: "organization.category.count.type" })} options={this.state.SlaCountTypeList} stateName='SlaCountTypeId' changeSelect={this.changeValues} selected={item.SlaCountTypeId} />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <SimpleHelp message={this.props.intl.formatMessage({id:"organization.category.edit.category.sla.count"})} />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <TextField fullWidth type='number' label={intl.formatMessage({ id: "organization.category.service.sla.min" })} disabled={this.state.disableFields} autoComplete='fname' variant='outlined' name='servicetime' onChange={this.handleInputChange} value={item.servicetime} required />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <TextField fullWidth type='number' label={intl.formatMessage({ id: "organization.category.solution.sla.min" })} disabled={this.state.disableFields} autoComplete='fname' variant='outlined' name='solutiontime' onChange={this.handleInputChange} value={item.solutiontime} required />
                  </Grid>

                  
                  {!this.state.template && (
                      <Grid item xs={12} sm={12}>
                        <Typography>
                          <FormattedMessage id="category.description" />*
                        </Typography>
                        <ReactQuill name="descriptionCategory" value={this.state.descriptionCategory} style={{ height: 200 }} modules={this.reactQuillModules} formats={this.reactQuillFormats}
                        fullWidth
                        required
                        variant="outlined"
                        id="outlined-multiline-static"
                                onChange={this.handleChangeEditorDescription} />					  
                    </Grid>
                )}
                {!this.state.template && (
                            <Grid item xs={12} sm={12} mt={10} mb={5}>
                            <Typography>
                              <FormattedMessage id="category.infomation" />*
                            </Typography>
                            <ReactQuill name="categoryInformation" value={this.state.categoryInformation} style={{ height: 200 }} modules={this.reactQuillModules} formats={this.reactQuillFormats}
                            fullWidth
                            required
                            variant="outlined"
                            id="outlined-multiline-static"
                                    onChange={this.handleChangeEditorInformation} />					  
        
                          </Grid>
                )}
                

              
               
                
                  <Grid item xs={11} sm={11}>
                    <SimpleCheckBox label={intl.formatMessage({ id: "organization.category.create.call" })} name='cancreatecalled' stateName='cancreatecalled' changeSelect={this.changeValues} selected={item.cancreatecalled} />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <SimpleHelp message={this.props.intl.formatMessage({id:"organization.category.edit.category.import.flag.default"})} />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleCheckBox label={intl.formatMessage({ id: "service.sla.notification" })} name='serviceslanotification' stateName='serviceslanotification' changeSelect={this.changeValues} selected={item.serviceslanotification} />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleCheckBox label={intl.formatMessage({ id: "organization.category.time.standard" })} name='isdefaultcategory' stateName='isdefaultcategory' changeSelect={this.changeValues} selected={item.isdefaultcategory} />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleCheckBox label={intl.formatMessage({ id: "organization.category.can.edit.called.title.and.description" })} name='caneditcalledtitleanddescription' stateName='caneditcalledtitleanddescription' changeSelect={this.changeValues} selected={item.caneditcalledtitleanddescription} />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleCheckBox
                    label={intl.formatMessage({id:"categories.appears.on.portal"})}
                    name='AppearsOnPortal'
                    stateName='AppearsOnPortal' 
                    changeSelect={this.changeValues}
                    selected={item.AppearsOnPortal} />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleCheckBox
                    label={intl.formatMessage({id:"save.to.all.organizations.in.group"})}
                    name='saveToAllOrganizationsInGroup'
                    stateName='saveToAllOrganizationsInGroup' 
                    changeSelect={this.changeValues}
                    selected={item.saveToAllOrganizationsInGroup} />
                  </Grid>
                  <Grid item xs={11} sm={11}>
                    <SimpleCheckBox
                    label={intl.formatMessage({id:"active"})}
                    name='inactive'
                    stateName='inactive' 
                    changeSelect={this.changeValues}
                    selected={!item.inactive} />
                  </Grid>

                  {!this.state.template && (

                      <Grid item xs={12} sm={12}>
                          <section style={{ width: '100%' }}>
                            <div className={classes.dropzoneArea} style={{minHeight:'70px',marginTop:'5px '}}>
                              <Dropzone onDrop={acceptedFiles =>  this.validateImagesAtachments([ ...acceptedFiles, ...this.state.files ])}>
                                {({ getRootProps, getInputProps, acceptedFiles }) => (
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <AttachFileIcon  />
                                    <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                                      <FormattedMessage  id="drag.and.drop.files" />
                                    </Typography>
                                  </div>
                                )}
                              </Dropzone>
                              {this.state.files.map(file => {
                                return (
                                  <div className={classes.dropzoneItem} key={file.name}>
                                    {file.name}
                                    <DeleteForever className={classes.deleteItem} onClick={() => { this.removeItem(file) }} />
                                  </div>
                                )
                              })}
                            </div>
                          </section>
                      </Grid>  
                )}
                
                  
                  <Grid item xs={11} sm={11} md={11} lg={11} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                    <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={4}    >
                        <Button
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit}
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
      </div>
    );
  }
}
OrganizationCategoryRegistration.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  organizations: state.organizations
});
export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(OrganizationCategoryRegistration)));
