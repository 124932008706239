import React, { Component } from "react"
import clsx from 'clsx'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from "prop-types"
import { CssBaseline, CircularProgress, Container, Button, Grid } from "@mui/material"
import { withStyles } from "@mui/styles"
import { Styles } from "../../styles/material-styles"
import { Overlay } from "../../styles/global"
import CustomizedSnackbars from "../../components/material-snackbars"
import HeaderMenu from "../../components/header-menu"
import MyMaterialTable from '../../components/table'
import Api from "../../services/api"
import Pagination from '@mui/material/Pagination';
import * as calledsActions from '../../redux/actions/calleds'
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import Footer from '../../components/footer'
import moment from "moment"
import { FormattedMessage } from 'react-intl'
import SimpleCheckBox from "../../components/checkbox/check";
import { injectIntl } from 'react-intl'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import SimpleDatePicker from '../../components/date-pickers/inline-no-today'
import SimpleAutoComplete from '../../components/auto-complete/autocomplete'
import SimpleAutoCompleteOrganizationCategories from '../../components/auto-complete/autocomplete organization-categories'
import CardHeader from '@mui/material/CardHeader';
import 'jspdf-autotable'
import LogoMarca from '../../images/new-logo-cmtech.png'
import Divider from '@mui/material/Divider'
import * as ExcelJS from 'exceljs'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ApiReports from "../../services/apireport";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PapiroConsole } from "../../utils/papiroConsole"
import SimpleAutoCompleteMultiple from "../../components/auto-complete/autocomplete-multiple-create";
import fileDownload from 'js-file-download'
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Text
} from 'recharts';
import * as Service from '../../services/techinicalteamorganizationcategory.service';

class QuantitativeCalledsReport extends Component {

  constructor(props) {
    super(props)

    this.isClient = true
    this.isAdm = false
    this.typingTimeout = null
    this.organizationId = null

    const { userSession, intl } = this.props


    if (userSession == null || userSession.user == null || userSession.user.userrole == null)
      Api.logoff()

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3)
        this.isClient = false
      if (item.role.roletype == 0 || item.role.roletype == 1)
        this.isAdm = true
    });

    if (userSession.user.organization)
      this.organizationId = userSession.user.organization.id

    this.monthsList =
      [{ id: 1, name: "Janeiro", value: '01' },
      { id: 2, name: "Fevereiro", value: '02' },
      { id: 3, name: "Março", value: '03' },
      { id: 4, name: "Abril", value: '04' },
      { id: 5, name: "Maio", value: '05' },
      { id: 6, name: "Junho", value: '06' },
      { id: 7, name: "Julho", value: '07' },
      { id: 8, name: "Agosto", value: '08' },
      { id: 9, name: "Setembro", value: '09' },
      { id: 10, name: "Outubro", value: '10' },
      { id: 11, name: "Novembro", value: '11' },
      { id: 12, name: "Dezembro", value: '12' },
      ]

    this.yearsList = Array.from({ length: 4 }, (_, i) => {
      const year = new Date().getFullYear() - i;
      return { id: year, name: year.toString() };
    })

    this.state = {

      quantitativeCalleds: [],
      organizationId: this.organizationId,
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      headerTitle : "",
      showGrid: false,
      calledsList: [],
      OrgsList: [],
      TechinicalTeamList: [],
      OrganizationFilter: [],
      TechinicalTeamFilter: { id: 0, name: '' },
      showHeader: false,
      yearsList: this.yearsList,
      monthsList: this.monthsList,
      daysList: Array.from({ length: moment().daysInMonth() }, (_, i) => ({ id: i + 1, name: (i + 1).toString() })),
      day: { id: 0, name: '' },
      month: this.monthsList.find(item => item.id === moment().month() + 1),
      year: this.yearsList.find(item => item.id === moment().year()),
      OrganizationCategoryParentList: [],
      columns: [],
      columnsExcell: [],

    }
  }
  
  async getTechinicalTeams(OrganizationId = 0) {
    var result = null
    if (OrganizationId > 0) {
      result = await Service.getTechinicalTeamsByOrganization(OrganizationId);
    } else {
      result = await Service.getTechinicalTeams()
    }
    if (result.success) {
      this.setState({ TechinicalTeamList: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  changeValues = async (stateName, value, text = '') => {
    this.setState({ loading: true })


    if (stateName == "month") {
      if (this.state.year && this.state.year.id == 0 && value.id != 0) {

        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: "É necessário adicionar o ano, antes de selecionar o mês",
          [stateName]: { id: 0, name: '' }
        })

      } else if (value.id == 0) {

        this.setState({
          daysList: [],
          [stateName]: value
        })


      }
      else {
        let getDaysInMonth = moment(`${this.state.year.id}-${value.value}`, 'YYYY-MM').daysInMonth();
        this.setState({
          daysList: Array.from({ length: getDaysInMonth }, (_, i) => ({ id: i + 1, name: (i + 1).toString() })),
          [stateName]: value
        })

      }

    }
    else {
      this.setState({ [stateName]: value });
    }


    this.setState({ loading: false })
  };

  Reload = () => {
    const reload = localStorage.getItem('reload')
    if (reload == "true")
      localStorage.setItem('reload', false)
    return null;
  }

  getOrganizations = async _ => {
    let data = [];
    const { userSession } = this.props
    if (userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
      data = userSession.user.userorganizations
    } else {
      await Api.get('/organizations/tech')
        .then(result => {
          data = result.data.data
        })
        .catch(err => {
          data = err
        });
    }
    this.setState({ OrgsList: data })
  }


  onChangePaginate = (e, page) => {
    this.setState({ page: page })
    this.getCalleds('', page)
  }

  exportPdf = _ => {
    this.getCalledsToPdf()
  }




  onFieldChange = event => {
    // Clears the previously set timer.
    clearTimeout(this.typingTimeout)
    // Reset the timer, to make the http call after 475MS (this.callSearch is a method which will call the search API. Don't forget to bind it in constructor.)
    this.typingTimeout = setTimeout(this.callSearch, 475);
    this.setState({ [event.target.name]: event.target.value, page: 1 })
  }

  callSearch = _ => {
    this.getCalleds('', this.state.page)
  }

  async componentDidMount() {
    await this.getTechinicalTeams()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "techinicalteamsreport")
      this.getCalleds(this.props.location.state)
    else if (nextProps.location.state === "assignedcalleds")
      this.getCalleds(nextProps.location.state)
    else if (nextProps.location.state === "reload")
      this.componentDidMount()
  }

  getFile = async () => {

    const intl = this.props.intl;

    this.setState({ loading: true });

    
    
    let url = `/report/quantitycalleds/excell?day=${this.state.day.id}&month=${this.state.month.id}&year=${this.state.year.id}&techteam=${this.state.TechinicalTeamFilter.id}`

    ApiReports.get(url, {
      responseType: 'blob'
    })
      .then((response) => {

        //   let url = window.URL.createObjectURL(new Blob([response.data]));
        //saveAs(url, 'called_list.csv');

        fileDownload(response.data, `quantitativo_de_chamados_${moment().format('DD/MM/YYYY HH:mm:ss')}.zip`);

        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });

  }

  getColumns() {

    const intl = this.props.intl;
    var columns =
      [
        { title: intl.formatMessage({ id: "id" }), field: 'id' },
        { title: intl.formatMessage({ id: "name" }), field: 'name' },
        { title: intl.formatMessage({ id: "category.parent.name.report" }), field: 'parent_name_category' },
        { title: intl.formatMessage({ id: "organization" }), field: 'organization' },
        { title: intl.formatMessage({ id: "organization.category.sla" }), field: 'sla_name' },
        { title: intl.formatMessage({ id: "organization.category.appears.on.portal" }), field: 'appears_on_portal' },
        { title: intl.formatMessage({ id: "category.parent.id" }), field: 'parent_id' },
        { title: intl.formatMessage({ id: "organization.category.parent" }), field: 'parent_name' },
      ];

    this.setState({ columns: columns })

  }

  getCalleds = async (filter = '', page = 1) => {

    const intl = this.props.intl;
    this.setState({ loading: true });

    let url = `/report/quantitycalleds?day=${this.state.day.id}&month=${this.state.month.id}&year=${this.state.year.id}&techteam=${this.state.TechinicalTeamFilter.id}`

    PapiroConsole.log("entrei aqui 1")
    this.setState({ showGrid: true })

    ApiReports.get(url)
      .then(result => {
        if (result.data.success) {
          PapiroConsole.log("result aqui formatted")
          PapiroConsole.log(result)
          let totalQuantity = 0;

          const formattedList = result.data.data.map(item => {
            totalQuantity += item.quantity;
            if (this.state.month.id == 0 && this.state.day.id == 0) {

              return {
                name: item.month,
                quantidade: item.quantity

              }
            }
            else {

              return {
                name: item.day,
                quantidade: item.quantity
              }

            }

          })

          var headerTitle = "";
          if(this.state.month.id != 0 && this.state.day.id != 0 ){
            headerTitle = `${intl.formatMessage({id:"quantitative.calleds.report"})} - ${this.state.day.id}/${this.state.month.value}/${this.state.year.id} - ${intl.formatMessage({id:"total.registers"})} : ${totalQuantity}`;
          }
          else if(this.state.month.id != 0 && this.state.day.id == 0){
            headerTitle = ` ${intl.formatMessage({id:"quantitative.calleds.report"})} - ${this.state.month.value}/${this.state.year.id} -  ${intl.formatMessage({id:"total.registers"})} : ${totalQuantity}`;
          }
          else{
            headerTitle = `${intl.formatMessage({id:"quantitative.calleds.report"})} - ${this.state.year.id} -  ${intl.formatMessage({id:"total.registers"})} : ${totalQuantity}`;
          }
          
          this.setState({ quantitativeCalleds: formattedList, loading: false , headerTitle : headerTitle})

          this.getColumns()
        } else {
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.data && result.data.errors && result.data.errors[0] ? result.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
          })
          Api.kickoff(result)
        }
      })
      .catch(err => {

        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(err)
      })



  }
  customizedAxisTick = ({
    x, y, payload,
  }) => {

    return <Text x={x} y={y} width={70} style={{ fill: '#808080', fontSize: '11px', }} textAnchor="middle" verticalAnchor="start">{payload.value}</Text>
  };



  handleSubmit = async e => {
    const intl = this.props.intl;
    e.preventDefault();
    this.setState({ loading: true });
    await this.getCalleds(this.props.location.state)
  }


  closeNotification = _ => {
    this.setState({ openNotification: false });
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props
    const intl = this.props.intl

    PapiroConsole.log(this.state.quantitativeCalleds)
    PapiroConsole.log("this.state.quantitativeCalleds")

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    const tableList = () => {
      return (
        <>

          <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
            <Accordion >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>{intl.formatMessage({ id: "filters" })}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container style={{ marginBottom: 20 }} spacing={2}>


                  <Grid item xs={12} sm={6} md={2} >
                    <SimpleAutoComplete label={intl.formatMessage({ id: "day" })} options={this.state.daysList} stateName='day' changeSelect={this.changeValues} selected={this.state.day} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={2} >
                    <SimpleAutoComplete label={intl.formatMessage({ id: "month" })} options={this.state.monthsList} stateName='month' changeSelect={this.changeValues} selected={this.state.month} />
                  </Grid>

                  <Grid item xs={12} sm={6} md={2} >
                    <SimpleAutoComplete label={intl.formatMessage({ id: "year" })} options={this.state.yearsList} stateName='year' changeSelect={this.changeValues} selected={this.state.year} required />
                  </Grid>
                  
                  <Grid item xs={12} sm={6} md={3} >
                    <SimpleAutoComplete label={intl.formatMessage({ id: "productivity.dashboard.techinical.teams" })} options={this.state.TechinicalTeamList} stateName='TechinicalTeamFilter' selected={this.state.TechinicalTeamFilter} changeSelect={this.changeValues} />
                  </Grid>

                </Grid>
              </AccordionDetails>
            </Accordion>
            
            <Grid container style={{ marginBottom: 10 }}>
              <Grid item xs={12} sm={2} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='secondary' >
                  {<FormattedMessage id="filter" />}
                </Button>
              </Grid>

              {this.state.showGrid && <Grid item xs={12} sm={5} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                <Button
                  fullWidth
                  variant='contained'
                  color='secondary'
                  onClick={this.getFile} >
                  {<FormattedMessage id="export.plan" />}
                </Button>
              </Grid>}


            </Grid>
          </form>
          {this.state.showGrid &&





            

              <Card style={{ width: '100%', marginTop: "50px" }} >
                <CardHeader classes={{ title: classes.cardheadercolor }} style={{ backgroundColor: "#8884d8", color: "white" }} title={this.state.headerTitle} />
                <Divider />
                <CardContent>

                <ResponsiveContainer width="95%" height={600} style={{marginTop: "30px"}} >

                    <BarChart
                      margin={{ bottom: 80, top: 80 }}
                      
                      data={this.state.quantitativeCalleds}

                      barSize={20}
                    >
                      <XAxis tick={<this.customizedAxisTick />} dataKey="name" padding={{ left: 10, right: 10 }} interval={0} />
                      <YAxis allowDecimals={false} />
                      <Tooltip />

                      <CartesianGrid strokeDasharray="3 3" />
                      <Bar dataKey="quantidade" fill="#8884d8" background={{ fill: '#eee' }} /> 
                    </BarChart>


                    </ResponsiveContainer>
                  


                </CardContent>
              </Card>


           
          }

          {/*
            this.state.showGrid && <>
              <MyMaterialTable
                title=""
                columns={
                  this.state.columns
                }
                data={this.state.calledsList} />

              <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                <Grid item xs={12} md={3}>
                  <Pagination count={this.state.totalPages} page={this.state.page} onChange={this.onChangePaginate} />
                </Grid>
                <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                  {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                </Grid>
              </Grid>


            </>
              */}
        </>
      );
    }

    return (
      <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })} >
          <div className={classes.drawerHeader} />
          <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft: 0, marginLeft: 0 }}>
            <CssBaseline />
            {this.state.showHeader &&
              <Grid container>
                <Grid id="header-pdf" item lg={4} sm={12} xl={this.state.grid} xs={12} style={{ padding: '2px', minWidth: 600 }}>
                  <Card >
                    <CardContent>
                      <img src={LogoMarca} style={{ width: "70px" }} /> <span style={{ marginLeft: 25, fontSize: 18 }}> {<FormattedMessage id="calleds.origin.categories.report" />}</span>
                      <Divider />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            }

            <Grid container spacing={6}>
              <Grid item xs={12} sm={12}>
                <Card >
                  <CardContent className="background-title-search">
                    <Typography variant="h5">
                      {<FormattedMessage id="quantitative.calleds.report" />}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>


            {tableList()}


            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification} />
          </Container>
        </main>
        <Footer />
      </div>
    )
  }
}

QuantitativeCalledsReport.propTypes = {
  classes: PropTypes.object.isRequired
}


const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(calledsActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(QuantitativeCalledsReport)))
QuantitativeCalledsReport.contextType = AbilityContext