import Api from './api';

export const getTimeZones = async () => { 
    let data = [];
    await Api.get('/configs/timezone')
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };
  export const getTimeZone = async (id) => { 
    let data = [];
    await Api.get('/configs/timezone/'+id)
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };
  
  export const getFormatDates = async () => { 
    let data = [];
    await Api.get('/configs/formatdate')
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };
  
  export const testMail = async (email) => { 
    let data = [];
    await Api.get('/configs/testmail/' + email)
        .then(result => {
            data = result
         })
         .catch(err => {
            data = err;
    });
    return data;
  };

  export const getCanCreateClientUsersExternal = async () => { 
    let data = []
    await Api.get('/configs/cancreateclientusersexternal')
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err
    });
    return data
  }
  export const getEmailConfig = async (id) => { 
    let data = [];
    await Api.get('/configs/emailconfig/'+id)
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };