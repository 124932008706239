import Api from './api';
export const getOrganizations = async (id = 0) => { 
  let data = []
  
  if(id != 0){
    
    await Api.get(`/organizationcategories/organizations/${id}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
    });
  }else{
    await Api.get('/organizations')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
      });
  }
  
  return data;
};

export const getOrganizationsById = async (id) => { 
  let data = [];
  await Api.get('/organizations/'+id)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};


export const GetOrganizationByIdSimple = async (id) => { 
  let data = [];
  
  await Api.get('/organizations/organizationbyidsimple/'+id)
      .then(result => {
        
          data = result.data;
       })
       .catch(err => {
        
          data = err;
         
  });
  return data;
};


export const getOrganizationCategoriesByOrganizationFieldId = async (id) => { 
  let data = [];
  await Api.get('/organizationcategories/organizationfield/'+id)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getOrganizationsByIdLight = async (id) => { 
  let data = [];
  await Api.get('/organizations/notincludes/'+id)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getCategoryDefault = async (orgid) => {
  
  let data = [];

  await Api.get(`/organizationcategories/default/${orgid}`)
  .then(result => {
    data = result.data
  })
  .catch(err => {
    data = err;
  });

};

export const getTypes = async () => {
  
  let data = [];

  await Api.get(`/types`)
  .then(result => {
    data = result.data
    
  })
  .catch(err => {
    data = err;
  });

  return data

};

export const getOrganizationSlas = async (id = 0) => { 
  let data = [];
  if(id != 0){
    await Api.get(`/organizationslas/organizations/${id}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
    });
  }else{
    await Api.get('/organizationslas')
    .then(result => {
        data = result.data
     })
     .catch(err => {
        data = err;
    });
  }
  
  return data;
};


export const getCalledFlows = async () => { 
  let data = [];
  await Api.get('/calledflows')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};
export const getSlaCountTypes = async () => { 
  let data = [];
  await Api.get('/slacounttypes')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const applySLACountType = async (formData) => { 
  let data = [];

  await Api.post('/organizationcategories/appyslacounttype', formData)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
}

export const getCategories = async () => { 
  let data = [];
  await Api.get('/categories')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getCategoriesList = async () => { 
  let data = [];
  await Api.get('/categories/list')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};


export const getParentOrganizationCategories = async (id) => { 
  let data = [];
  await Api.get(`/organizationcategories/organizations/${id}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getbyorganization = async (id) => { 
  let data = [];
  await Api.get(`/organizationcategories/getbyorganization/${id}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getParentOrganizationCategoriesList = async (id) => { 
  let data = [];
  await Api.get(`/organizationcategories/organizations/list/${id}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getByOrganizationAndNotIdList = async (oraganizationId, id) => { 
  let data = [];
  await Api.get(`/organizationcategories/organizations/list/${oraganizationId}/not/${id}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
}

export const getSlaById = async (calledId) => { 
  let data = [];
  await Api.get('/organizationslas/'+calledId)
      .then(result => {
          data = result.data
          // console.log(data);
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getOrganizationCategoryDepartamentsByDepartament = async (id = 0) => { 
  let data = []
  
  if(id != 0){
    
    await Api.get(`/organizationcategoriedepartaments/organizations/${id}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
    });
  }else{
    await Api.get('/organizations')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
      });
  }
  
  return data;
};

export const getOrganizationCategoryDepartamentsByOrganizationCategory = async (id = 0) => { 
  let data = []
  
  if(id != 0){
    
    await Api.get(`/organizationcategoriedepartaments/departament/${id}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
    });
  }else{
    await Api.get('/organizationcategoriedepartaments/departament')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
      });
  }
  
  return data;
};

export const getDepartamentsByOrganizationCategoryId = async (id = 0) => { 
  let data = []
  
  if(id != 0){
    
    await Api.get(`/organizationcategoriedepartaments/getdepartament/organizationcategory/${id}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
    });
  }else{
    await Api.get('/organizationcategoriedepartaments/getdepartament/organizationcategory')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
      });
  }
  
  return data;
};

export const getDepartamentsByOrganizationCategoryIdNew = async (id = 0) => { 
  let data = []
  
  await Api.get(`/organizationcategoriedepartaments/getdepartament/organizationcategory/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err;
  });
  
  return data;
};

export const getCategoriesByOrganizationDepartamentId = async (id = 0) => { 
  let data = []
  
  await Api.get(`/organizationcategoriedepartaments/getcategory/organizationdepartament/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err;
  });
  
  return data;
};

export const getAllSelectionModelCanCreateCalledByOrganizationId = async (orgId) => {
  let data = []
  
  await Api.get(`/organizationcategories/selectionmodel/createcalled/organization/${orgId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err;
  });
  
  return data
}

export const getAllSelectionModelByOrganizationId = async (organizationId) => {
  let data = []
  await Api.get(`/performance/organizationcategories/selectionmodel/organization/${organizationId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getAllSelectionModelRootCategoriesByOrganizationId = async (organizationId) => {
  let data = []
  await Api.get(`/performance/organizationcategories/rootcategories/selectionmodel/organization/${organizationId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getAllSelectionModelByParentOrganizationCategoryId = async (parentOrganizationCategoryId) => {
  let data = []
  await Api.get(`/performance/organizationcategories/selectionmodel/parentorganizationcategory/${parentOrganizationCategoryId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getCategoryParentByChildrenOrganizationCategoryId = async (childrenId) => {
  let data = []
  await Api.get(`/performance/organizationcategories/selectionmodel/parentorganizationcategorybychildren/${childrenId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getCategoriesByOrganizationSimpleList = async (organizationId) => {
  let data = [];
  await Api.get("/organizationcategories/simple/list/organization/"+ organizationId)
    .then((result) => {
      data = result.data;
    })
    .catch((err) => {
      data = err;
    });
  return data;
};