import React from "react"
import { Button, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material"
import SimpleCheckBox from "../checkbox/check"
import SimpleAutoCompleteMultiple from "../auto-complete/autocomplete-multiple-create"
import { FormattedMessage } from "react-intl"
import ClearIcon from '@mui/icons-material/Clear'

export default function DialogAddMultiple(props) {
    const { selected, options, checked, changeValues, save, open, close, title, intl, checkboxLabel, checkboxStateName, autoCompleteLabel, autoCompleteStateName } = props
    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={open}
            onClose={close}
            aria-labelledby="form-dialog-title-org-mat"
            PaperProps={{
                style: {
                    overflow: 'hidden'
                }
            }}
        >
            <DialogTitle id="form-dialog-title-org-mat">
                {title}
                <ClearIcon onClick={close} style={{ float: 'right', cursor: 'pointer' }} />
            </DialogTitle>
            <DialogContent>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={4} style={{ padding: "5px" }}>
                        <Grid item xs={12} sm={12} >
                            <SimpleCheckBox label={checkboxLabel} name={checkboxStateName} stateName={checkboxStateName} changeSelect={changeValues} selected={checked} />
                        </Grid>
                        <Grid item xs={12} sm={12} >
                            <SimpleAutoCompleteMultiple
                                label={autoCompleteLabel}
                                options={options}
                                stateName={autoCompleteStateName}
                                changeSelect={changeValues}
                                selected={selected} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid spacing={2} container justifyContent="flex-end" style={{ padding: 15 }}>
                    <Grid item xs={12} sm={4}>
                        <Button
                            type="button"
                            onClick={close}
                            fullWidth >
                            {intl.formatMessage({ id: "cancel" })}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            onClick={save}
                        >
                            {<FormattedMessage id="save" />}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}