export default function userSession(state = [], action) {
    switch (action.type) {
        case 'ADD_USER':
            return {
                user: action.user,
            }
        case 'REMOVE_USER':
            return null;
        default:
            return state;
    }
}
