import { Button, CircularProgress, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import { withStyles } from "@mui/styles";
import clsx from 'clsx';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import SimpleDatePicker from '../../components/date-pickers/inline-no-today';
import Footer from '../../components/footer';
import HeaderMenu from "../../components/header-menu";
import CustomizedSnackbars from "../../components/material-snackbars";
import LogoMarca from '../../images/new-logo-cmtech.png';
import * as calledsActions from '../../redux/actions/calleds';
import Api from "../../services/api";
import * as CalledService from '../../services/called.service';
import { getChart } from '../../services/calledclassification.service';
import { getLastFeedbacks } from '../../services/dashboard.service';
import { getChart as getNPSChart } from '../../services/nps.service';
import { Overlay } from "../../styles/global";
import { Styles } from "../../styles/material-styles";
import { evaluateSession } from '../../utils/sessionUtils';
import { getSlaAtendimento, getSlaSolucao } from '../called-list/called.functions';
import LastFeedback from './components/latestproducts';
import Pizza from './components/pizza';
import PizzaNps from "./components/pizzaNps";
import { getAllSelectionModel } from '../../services/techinicalteam.service'
import { PapiroConsole } from '../../utils/papiroConsole';


class QualityDashboard extends Component {

  constructor(props) {
    super(props)
    this.Reload = this.Reload.bind(this)
    this.Reload()


    const { userSession } = this.props

    evaluateSession(userSession)

    this.isClient = true
    this.globalAdm = false
    this.AdmOrganization = false
    this.onlyAdmOrganization = false
    this.isTechinical = false

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true
      }
      if (item.role.roletype == 1) {
        this.AdmOrganization = true
      }
      if (item.role.roletype == 4) {
        this.isCoordenator = true
      }
      if (item.role.roletype == 2) {
        this.isTechinical = true
      }
    })

    if (this.AdmOrganization == true && this.globalAdm == false) {
      this.onlyAdmOrganization = true
    }

    if (userSession.user.userrole == null) {
      Api.logoff()
    }

    this.state = {
      dateStart: moment().format('YYYY/MM/DD HH:mm:ss'),
      dateEnd: moment().format('YYYY/MM/DD HH:mm:ss'),
      grid: 4,
      grid12: 12,
      WindowSize: window.innerWidth * 0.9,
      maxWidth: '100%',
      width: '100%',
      classificationNoteList: [],
      NpsNoteList:[],
      dataLastFeedback: [],
      organizationsList: [],
      loading: false,
      organization: 0,
      showChart: false,
      showButtonPdf: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      technicalTeamList: [],
      technicalTeam : { id: 0, name: '' },
    }

    this.loadItems = this.loadItems.bind(this)
    this.getOrganizations = this.getOrganizations.bind(this)
    this.getClassificationPie = this.getClassificationPie.bind(this)
    this.getNpsClassification = this.getNpsClassification.bind(this)
    this.getLastClassification = this.getLastClassification.bind(this)
    this.closeNotification = this.closeNotification.bind(this)
    this.getById = this.getById.bind(this)
    this.redirectToChat = this.redirectToChat.bind(this)
    this.changeValues = this.changeValues.bind(this)

    this.search =  this.search.bind(this)
    this.getPdf = this.getPdf.bind(this)
    this.getTechnicalTeamList = this.getTechnicalTeamList.bind(this)
    
  }

  async componentDidMount() {
    await this.setState({ loading: true })
    //await this.loadItems()
    await this.getTechnicalTeamList()
    await this.getOrganizations()
    await this.getClassificationPie()
    await this.getNpsClassification()
    await this.getLastClassification(null, null, true)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount()
    }
  }

  async loadItems(organizationId = '', techinicalTeamId) {
    //await this.getOrganizations()
    PapiroConsole.log("this.state.technicalTeam 1")
    PapiroConsole.log(techinicalTeamId)
    await this.getClassificationPie(organizationId, techinicalTeamId)
    await this.getNpsClassification(organizationId, techinicalTeamId)
    await this.getLastClassification(organizationId, techinicalTeamId)
  }

  async getTechnicalTeamList() {
    const result = await getAllSelectionModel();
    if (result.success) {
      this.setState({ technicalTeamList: result.data })
    } else {
      const intl = this.props.intl;
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  async getOrganizations() {
    const { userSession } = this.props
    let orgData = []

    if(userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
      orgData = userSession.user.userorganizations
    } else {
      const result = await CalledService.getOrganizations()
      
      if (result.success) {
        orgData = result.data
      } else {
        const intl = this.props.intl

        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        })

        Api.kickoff(result)
      }
    }

    if(orgData != null) {
      this.setState({ organizationsList: orgData })
    }
  }

  async getClassificationPie(organizationId = '', techinicalTeamId = null) {
    if(organizationId == null || organizationId == ''){
      organizationId = 0
    }
    if(techinicalTeamId == null || techinicalTeamId == ''){
      techinicalTeamId = 0
    }

    let result = await getChart(moment(this.state.dateStart).format('YYYY-MM-DD'), moment(this.state.dateEnd).format('YYYY-MM-DD'), organizationId, techinicalTeamId)
    if (result && result.success) {
      this.setState({ classificationNoteList: result.data })
    } else {
      const intl = this.props.intl

      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })

      Api.kickoff(result)
    }
  }

  async getNpsClassification(organizationId = '', techinicalTeamId = null) {
    PapiroConsole.log("this.state.technicalTeam 2")
    PapiroConsole.log(techinicalTeamId)
    if(organizationId == null || organizationId == ''){
      organizationId = 0
    }
    if(techinicalTeamId == null || techinicalTeamId == ''){
      techinicalTeamId = 0
    }
    
    let result = await getNPSChart(moment(this.state.dateStart).format('YYYY-MM-DD'), moment(this.state.dateEnd).format('YYYY-MM-DD'), organizationId,techinicalTeamId)

    if (result && result.success) {
      this.setState({ NpsNoteList: result.data })
    } else {
      const intl = this.props.intl

      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })

      Api.kickoff(result)
    }
  }

  async getLastClassification(organizationId = '', techinicalTeamId = null, defaultDate = null) {
    
    if(organizationId == null || organizationId == ''){
      organizationId = 0;
    }

    if(techinicalTeamId == null || techinicalTeamId == ''){
      techinicalTeamId = 0
    }

    let dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
    let dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')

    let result = await getLastFeedbacks(dateStart,dateEnd, organizationId, techinicalTeamId, defaultDate)

    if (result && result.success) {
      this.setState({ dataLastFeedback: result.data })
    } else {
      const intl = this.props.intl

      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })

      Api.kickoff(result)
    }

    this.setState({ loading: false, showButtonPdf: true })
  }

  async getById(id) {
    let data = {}
    //this.setState({ loading: true })

    Api.get(`/calleds/calledInfoPaginate/${id}`)
      .then(async result => {
        if (result.data.success) {

          let item = result.data.data

          let times = "";
            if (item.organizationcategory.techinicalteamorganizationcategories != null) {
              item.organizationcategory.techinicalteamorganizationcategories.map(item2 => {
                  times = times + item2.techinicalteam.name + ","
              })
              times = times.substring(0, times.length - 1)
            }
            data = {
              ...item,
              statusName: item.status.name,
              OrganizationCategory: item.organizationcategory.parentname,
              Organization: item.organization.name,
              slaAtendimento: getSlaAtendimento(item, this.props.userSession) + "%",
              slaSolucao: getSlaSolucao(item, this.props.userSession) + "%",
              assignedUser: item.assigneduser ? item.assigneduser.name : "",
              requestingUser: item.requestinguser ? item.requestinguser.name : "",
              time: times,
              addressname: item.address.name,
              opening: moment(item.openingdate).format("DD/MM/YYYY"),
              classification: item.calledclassification
            }

            await this.props.addCurrentCalled(data)
            await this.props.addAccompanimentsList(data.accompaniments ? data.accompaniments.sort((a, b) => new Date(b.actiontime) - new Date(a.actiontime)) : null)

            await this.props.addCalled(data)
            await this.props.addAccompanimentsListInCalled(data.accompaniments ? data.accompaniments.sort((a, b) => new Date(b.actiontime) - new Date(a.actiontime)) : [], data.id)

            const urlRedirect = "/" + this.props.match.params.lang + `/called-accompaniment/${data.id}`

            window.open(urlRedirect, "_blank")

          } else {
            this.setState({
                openNotification: true, notificationVariant: 'error',
                notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : 'Erro ao processar a solicitação.'
            })

            Api.kickoff(result)
        }
      })
      .catch(err => {
        this.setState({
          openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : "Erro ao processar a solicitação."
        })

        Api.kickoff(err)
      })
  }

  redirectToChat(chatid) {
    const urlRedirect = "/" + this.props.match.params.lang + `/omnichannel`;
    window.location.href = urlRedirect;
  }

  async search() {
    this.setState({ loading: true })

    let dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
    let dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')
    
    if(dateStart > dateEnd){
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:"Data inicial não pode ser maior que data final"
      })
    } else{
      
      await this.loadItems(this.state.organization && this.state.organization.id, this.state.technicalTeam && this.state.technicalTeam.id)
    }

  }

  async changeValues(stateName, value, text = '') {
    this.setState({ [stateName]: value })
  }

  closeNotification() {
    this.setState({ openNotification: false })
  }

  Reload = () => {
    const reload = localStorage.getItem('reload')
    if (reload == "true") {
      localStorage.setItem('reload', false)
      setTimeout(function () { document.location.reload(true); }, 500)
    }
    return 0
  }

  async getPdf() {
    await this.setState({ loading: true })
    await this.setState({ maxWidth: '500px', grid: 12, width: '500px', showChart: true })
    
    const header = document.getElementById('header-pdf');
    let headerCanvas = await html2canvas(header);
    const headerImg = headerCanvas.toDataURL('image/png');
    
    const pdf = new jsPDF();
    pdf.addImage(headerImg, 'PNG', 0, 0);

    if (this.onlyAdmOrganization || this.AdmOrganization || this.globalAdm) {
      const pizza = document.getElementById('pizza');
      if(pizza != null){
        let canvaspizza = await html2canvas(pizza);
        let imgPizza = null
        if (canvaspizza != null)
          imgPizza = canvaspizza.toDataURL('image/png');
        if (imgPizza != null)
          pdf.addImage(imgPizza, 'PNG', 5, 30, 200, 125);
      }
      
      const pizzaNps = document.getElementById('pizzaNps');
      if(pizzaNps != null){
        let canvasPizzaNps = await html2canvas(pizzaNps);
        let imgPizzaNps = null
        if (canvasPizzaNps != null)
        imgPizzaNps = canvasPizzaNps.toDataURL('image/png');
        if (imgPizzaNps != null)
          pdf.addImage(imgPizzaNps, 'PNG', 5, 165, 200, 125);
      }
      
      const lastFeedback = document.getElementById('lastFeedback');
      if(lastFeedback != null) {
        if(lastFeedback != null && pizzaNps != null) {
          pdf.addPage()
        }
        let canvasLastFeedback = await html2canvas(lastFeedback);
        let imgLastFeedback = null
        if (canvasLastFeedback != null)
        imgLastFeedback = canvasLastFeedback.toDataURL('image/png');
        if (imgLastFeedback != null)
          pdf.addImage(imgLastFeedback, 'PNG', 5, 0, 200, 105);
      }
    }

    pdf.save("download.pdf");
    //   html2canvas(input)
    // .then((canvas) => {
    //   const imgData = canvas.toDataURL('image/png');
    //   const pdf = new jsPDF();
    //   pdf.addImage(imgData, 'PNG', 0, 0);
    //   pdf.save("download.pdf");
    // });

    await this.setState({ maxWidth: '100%', width: '100%', grid: 4, showChart: false, loading: false })

  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props
    const intl = this.props.intl

    PapiroConsole.log("this.state.technicalTeam")
    PapiroConsole.log(this.state.technicalTeam)


    if(Api == null) {
      var l = this.props.match.params.lang
      document.location.href=`/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })} >
          <div className={classes.drawerHeader} style={{ minWidth: '300px' }} />
          <Grid container spacing={2}>
            <Grid id="header-pdf" item lg={12} sm={12} xl={12} xs={12} style={{ padding: '2px' }}>
              <Card>
                <CardContent>
                  {this.state.showChart && <img src={LogoMarca} style={{ width: 70 }} /> }

                  <span style={{ marginLeft: 25, fontSize: 18 }}>
                    { this.state.showChart && intl.formatMessage({ id: "productivity.dashboard.report.of" }) } 
                    <FormattedMessage id="quality.dashboards.title" />
                  </span>
                  
                  { this.state.showChart && <span style={{ marginLeft: 25, fontSize: 18 }}><FormattedMessage id="from.capital" /> {moment(this.state.dateStart).format('DD/MM/YYYY')} <FormattedMessage id="to" /> {moment(this.state.dateEnd).format('DD/MM/YYYY')}</span>}

                  <Divider />
                  
                  { this.state.showChart && this.state.organization.id > 0 && <span style={{ marginLeft: 25, fontSize: 18 }}>{this.state.organization.name}</span>}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{ marginBottom: 15 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={4}>
                  {this.state.showButtonPdf && <Button variant='contained'
                    color='secondary'
                    style={{ marginBottom: "24px" }}
                    size='large' onClick={this.getPdf}>{<FormattedMessage id="dash.pdf" />}</Button>}
                </Grid>
                <Grid item xs={12} sm={8} md={8}></Grid>
              
                <>
                  <Grid item xs={12} sm={6} md={2}>
                    <SimpleDatePicker label={intl.formatMessage({id:"start.date"})} name='dateStart' stateName='dateStart' changeDate={this.changeValues} currentDate={this.state.dateStart} required/>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <SimpleDatePicker label={intl.formatMessage({id:"end.date"})} name='dateEnd' stateName='dateEnd' changeDate={this.changeValues} currentDate={this.state.dateEnd}  required/>
                  </Grid>
                  {(this.AdmOrganization || this.globalAdm || this.isCoordenator) &&
                    <Grid item xs={12} sm={6} md={3}>
                      <SimpleAutoComplete label={intl.formatMessage({ id: "productivity.dashboard.organizations" })} options={this.state.organizationsList} stateName="organization" changeSelect={this.changeValues} required />
                    </Grid>
                  }
                  {(this.AdmOrganization || this.globalAdm || this.isCoordenator) &&
                    <Grid item xs={12} sm={6} md={3} >
                    <SimpleAutoComplete
                      label={intl.formatMessage({ id: "productivity.dashboard.techinical.teams" })}
                      options={this.state.technicalTeamList}
                      stateName="technicalTeam"
                      changeSelect={this.changeValues}
                       />
                  </Grid>
                  }
                     
                  <Grid item xs={12} sm={6} md={2} style={{ display: 'flex', alignItems: 'center'}}>
                    <Button
                      variant='contained'
                      color='secondary'
                      size='large'
                      onClick={this.search}>
                      {<FormattedMessage id="search" />}
                    </Button>
                  </Grid>
                </>
              </Grid>
            </Grid>

          {(this.state.classificationNoteList.length > 0 && (this.isCoordenator || this.AdmOrganization || this.globalAdm)) &&
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{ padding: '2px', minWidth: '320px' }} id="pizza">
              <Pizza data={this.state.classificationNoteList}  />
            </Grid>
          }

          {(this.state.NpsNoteList.length > 0 && (this.isCoordenator || this.AdmOrganization || this.globalAdm)) &&
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{ padding: '2px', minWidth: '320px' }} id="pizzaNps">
              <PizzaNps data={this.state.NpsNoteList} title={intl.formatMessage({id:'quality.dashboard.nps.transactional.for.calleds'})}  />
            </Grid>
          }

            <Grid item  xs={12} id="lastFeedback" >
              <LastFeedback 
                type={this.isClient} 
                data={this.state.dataLastFeedback} 
                openCalled={this.getById} 
                openChat={this.redirectToChat}
              />
            </Grid>
          </Grid>
          {this.state.loading && (
            <Overlay>
              <CircularProgress color="secondary" />
            </Overlay>
          )}
           <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification} />
        </main>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  chatHubConnection: state.chatHubConnection,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(calledsActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(QualityDashboard)));
