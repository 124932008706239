export default function phoneregistrationrequests(state = [], action) {
    
    switch (action.type) {
        case 'ADD_CURRENT':
            return {
                ...state,
                phoneregistrationrequests: action.phoneregistrationrequests,
            }
        case 'UPDATE_MESSAGE_PROVIDER_CONFIG':
            return {
                ...state,
                phoneregistrationrequests: {
                    ...state.phoneregistrationrequests,
                    messageproviderconfig : action.messageproviderconfig
                }
            }
            
        default:
            
            return state;
    }
}
