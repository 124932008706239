export function addCurrent(organizationcalendar) {
    return {
        type: 'ADD_CURRENT',
        organizationcalendar,
    }
}
export function addCurrentOrganization(organizationcalendar,organization) {
    return {
        type: 'ADD_CURRENT_ORGANIZATION',
        organizationcalendar,
        organization,
        }
}
