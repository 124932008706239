import React, { Component } from "react";
import PropTypes from "prop-types";
import { Avatar, Button, CssBaseline, TextField, Typography, Container, Link, Grid, CircularProgress } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Api from "../../../services/api";
import { withStyles } from "@mui/styles";
import { Styles } from "../../../styles/material-styles";
import CustomizedSnackbars from "../../../components/material-snackbars";
import { Overlay } from "../../../styles/global";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      loading: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: ""
    };

    this.closeNotification = this.closeNotification.bind(this);
  }

  handleInputChange = e => {
    this.setState({ email: e.target.value });
  };

  handleSubmit = async e => {
    const intl = this.props.intl;
    e.preventDefault();

    this.setState({ loading: true });
    
    let data = new FormData();
    data.append("email", this.state.email);

    Api.post("/hirer/password-recovery", data)
      .then(result => {
        console.log(result)
        console.log("password")
        if (result.data.success && result.data != null && result.data.data != false) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: this.props.intl.formatMessage({id:"mail.sent.success.verify.mail"})
          });
        }else{
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: intl.formatMessage({id:"mail.not.found"})
          });
        }
      })
      .catch(err => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
        });
      });
  };
  
  closeNotification() {
    this.setState({ openNotification: false });
  }

  render() {
    const { classes } = this.props;
    const { email } = this.state;
    const intl = this.props.intl;

    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {intl.formatMessage({id:"recover.password"})}
          </Typography>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={this.props.intl.formatMessage({id:"email"})}
              name="email"
              autoComplete="email"
              type="email"
              autoFocus
              value={email}
              onChange={this.handleInputChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="admin"
              className={classes.submit}
            >
              {intl.formatMessage({id:"recover"})}
            </Button>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <Link href="/pt/admin">{this.props.intl.formatMessage({id:"back"})}</Link>
              </Grid>
            </Grid>
          </form>
        </div>

        {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}

        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification}
        />
      </Container >
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(Styles)(ForgotPassword);
