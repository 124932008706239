// ColorlibStepIcon.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CheckIcon from '@mui/icons-material/Check';
import ContactPageIcon from '@mui/icons-material/ContactPage';

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 95deg, #3f51b4 0%, #3949ab 50%, #303f9f 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 95deg, #3f51b4 0%, #3949ab 50%, #303f9f 100%)',
  }),
  
}));

class ColorlibStepIcon extends Component {
  render() {
    const { active, completed, className } = this.props;

    const icons = {
      1: <TextSnippetIcon />,
      2: <ContactPageIcon />,
      3: <CheckIcon />,
    };

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(this.props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

export default ColorlibStepIcon;
