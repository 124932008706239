import Api from './api';



export const getAll = async (hirerId) => { 
  let data = []
  await Api.get(`/templatemessage/all`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};


export const getByRegistrationNumberRequestId = async (id) => { 
  let data = []
  await Api.get(`/templatemessage/registrationnumberrequest/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};




