export default function companies(state = [], action) {
  switch (action.type) {
    case 'ADD_CURRENT':
      return {
        ...state,
        company: action.company,
      }
    default:
      return state;
  }
}