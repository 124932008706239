import React from 'react';
import { makeStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    //width:'600px',
    //height:'600px',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #ffffff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    zIndex: 1301
  },
}));

export default function TransitionsModal(props) {
  const classes = useStyles();
  const { children, isopen, handleOpen, handleClose  } = props;
  const [open, setOpen] = React.useState(false);


  React.useEffect(() => {
    setOpen(isopen);
  },[isopen]);    

  //const handleOpen = () => {
  //  setOpen(true);
  //};

  //const handleClose = () => {
  //  setOpen(false);
  //};


  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
              {children}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}