import React from 'react';
import UnlikeIcon from '../../images/me-feedback/unlike_icon.png';

const MEUnlikeIcon = props => (
    <div style={{ display: 'inline-block' }}>
        <img
            src={props.src && props.src.length > 0 ? props.src : UnlikeIcon}
            style={props.style != null ? props.style : {  }}
            title="FeedbackUnlikeIcon"
            className='App-Unlike-icon' />
    </div>
);

export default MEUnlikeIcon;
