import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import MaskedInput from 'react-text-mask'
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container, DialogActions, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import HelpIcon from '@mui/icons-material/Help';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleSelect from '../../components/select/simple';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import SimpleCheckBox from '../../components/checkbox/check';
import Tooltip from '@mui/material/Tooltip';
import * as Service from '../../services/organization.service';
import SimpleHelp from '../../components/help';
import SimpleAutoCompleteMultiple from '../../components/auto-complete/autocomplete-multiple';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import { createHashHistory } from 'history'
import { withRouter } from 'react-router-dom';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { PapiroConsole } from '../../utils/papiroConsole';
import { getAllItemsSelectionModel } from '../../services/organizationsetting.service';
import { associateTechinicalTeams } from '../../services/techinicalteamorganization.service';

export const history = createHashHistory()


class OrganizationRegistration extends Component {

  constructor(props) {
    super(props);
   //console.log(props);
    const { userSession } = this.props;
    this.globalAdm = false;
    this.isClient = true;
    this.Copyed = []
    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

 
    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      GroupId:  '',
      GroupText: '',
      SegmentId:  0,
      SegmentText: '',
      name : '', 
      importHoliday: false,
      copyTemplate: false,
      active: true,
      orgCopyId: 0,
      valCopy: '',
      copyed : []
     };         
    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      GroupList: [],
      SegmentList: [],
      OrgList: [],
      CopyList: [],
      associateTechinicalTeamDialogOpen: false,
      techinicalTeamsList: [],
      associatedTechinicalTeamsList: [],
      organization: null
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this)
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.openAssociateTechinicalTeamDialog = this.openAssociateTechinicalTeamDialog.bind(this)
    this.closeAssociateTechinicalTeamDialog = this.closeAssociateTechinicalTeamDialog.bind(this)
    this.handleConfirmAssociateTechicalTeam = this.handleConfirmAssociateTechicalTeam.bind(this)
    this.changeValuesAssociateOrganization = this.changeValuesAssociateOrganization.bind(this);
  }

  openAssociateTechinicalTeamDialog = async orgId => {
    this.setState({ loading: true });
    let result = await getAllItemsSelectionModel(orgId);
    if (result.success) {
      if (result.data && result.data.length > 0) {
        this.setState({
          loading: false,
          associateTechinicalTeamDialogOpen: true,
          techinicalTeamsList: result.data
        })
      } else {
        this.setState({
          loading: false
        }, () => {
          this.props.history.push({
            pathname:`/pt/wizard-organization`,
            state:{
              organization : this.state.organization,
              valcopy : this.state.item.valCopy,
              item : this.state.item,
              copyed : this.Copyed
            }
          })
        })
      }      
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
      });
      Api.kickoff(result);
    }
  }

  closeAssociateTechinicalTeamDialog = _ => {
    this.setState({
      associateTechinicalTeamDialogOpen: false,
      loading: false
    }, () => {
      this.props.history.push({
        pathname:`/pt/wizard-organization`,
        state:{
          organization : this.state.organization,
          valcopy : this.state.item.valCopy,
          item : this.state.item,
          copyed : this.Copyed
        }
      })
    })
  }

  handleConfirmAssociateTechicalTeam = async _ => {
    if (this.state.organization && this.state.organization.id && this.state.organization.id > 0 && this.state.associatedTechinicalTeamsList.length > 0) {
      this.setState({ loading: true })

      let data = new FormData()
      data.append("CreatedUser", this.state.item.createdUser)
      data.append("CreatedAt", moment(this.state.item.createdAt).format('YYYY/MM/DD HH:mm:ss'))
      data.append("OrganizationId", this.state.organization.id)
      this.state.associatedTechinicalTeamsList.forEach(item => {
        data.append('TechinicalTeamIds', item.id)
      })
      
      let result = await associateTechinicalTeams(data)
      if (result.success) {
        this.setState({
          loading: false,
          techinicalTeamsList: [],
          associatedTechinicalTeamsList: []
        }, () => {
          this.props.history.push({
            pathname:`/pt/wizard-organization`,
            state:{
              organization : this.state.organization,
              valcopy : this.state.item.valCopy,
              item : this.state.item,
              copyed : this.Copyed
            }
          })
        })
      } else {
        const intl = this.props.intl
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        })
        Api.kickoff(result)
      }
    }
  }

  async componentWillReceiveProps(nextProps){
                        if(nextProps.location.state === "reload"){
                            this.componentDidMount();
                        }
                    }    

  async componentDidMount() {
    await this.getGroups();
    await this.getSegments();
    await this.getOrg();
    this.copyList();
  };
  copyList(){
    const intl = this.props.intl;
    let objValores = [
      {id: 1, name: intl.formatMessage({id:"menu.configs"})}, 
      {id: 2, name: intl.formatMessage({id:"slas"})}, 
      {id: 3, name: intl.formatMessage({id:"menu.categories"})},
      {id: 4, name: intl.formatMessage({id:"menu.organizationdepartaments"})},
      {id: 5, name: intl.formatMessage({id:"menu.organizationpatrimonies"})},
      {id: 6, name: intl.formatMessage({id:"menu.organizationmaterials"})},
      {id: 7, name: intl.formatMessage({id:"organization.custom.checklist"})},
      {id: 8, name: intl.formatMessage({id:"addresses"})},
      {id: 9, name: intl.formatMessage({id:"menu.holiday"})},
      {id: 10, name: intl.formatMessage({id:"organization.custom.fields"})},
      {id: 11, name: intl.formatMessage({id:"called.list.data.list.called.list.config"})},
      {id: 12, name: intl.formatMessage({id:"additional.called.cost.config"})},
      {id: 13, name: intl.formatMessage({id:"called.report.config"})},
    ]
    this.setState({ loading: false, CopyList: objValores });
  };
  async getOrg(){
    this.setState({ loading: true });
    var result = await Service.getOrg();
    if (result.success) {
      this.setState({ loading: false, OrgList: result.data });
                    
    } else {
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
      });
      Api.kickoff(result);  
    }
};  

     async getGroups(){
          this.setState({ loading: true });
          var result = await Service.getGroups();
        
            if (result.success) {
              this.setState({ loading: false, GroupList: result.data });
            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);
            }
  };     
  async getSegments(){
          this.setState({ loading: true });
          var result = await Service.getSegments();
          
            if (result.success) {
              this.setState({ loading: false, SegmentList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);
            }
  };

  handleChangePhone(e) {
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        ['phone']: e
      }
    }));
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));

    if (e.target.name === "phone") {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          ["phone"]: this.mtel(value),
        },
      }));
    }
    
  };

  mtel(v) {
    v = v.replace(/\D/g, "");
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
    return v;
  }

  handleSubmit = async e => {
    e.preventDefault();
    
    
    const intl = this.props.intl;

      this.setState({ loading: true })
      if(this.state.item.copyTemplate === true && (this.state.item.orgCopyId === 0 || this.state.item.valCopy ==='')  )
      {
        if(this.state.item.orgCopyId === 0)
        {
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: intl.formatMessage({id:"organization.registration.organization.name.copy"}),
          });
        }
        else
        {
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: intl.formatMessage({id:"organization.registration.organization.values.copy"}),
          });
        }
        
      }

    
      else
      {
        let data = new FormData();
    

    


        if (this.isClient) {
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              
            }
          }));
        } else {
          this.setState(prevState => ({
            item: {
              ...prevState.item,
            }
          }));
        }
  
      
        for (let key in this.state.item) {
          if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
            data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
          } else if(key.toString().toLowerCase().indexOf('valcopy') > -1){
            
            
            let list = this.state.item[key];
            // console.log(list)
            if(list != null && list != ""){
              
              let result = "";
  
              this.state.item[key].map((item, key2) => {
                // console.log(item[key]);
                // console.log(key2)
                result = item.id+","+result
              });
  
              data.append("valCopy", result);
  
            }
            
  
          }
           else {
            data.append(key, this.state.item[key]);
          }
        }
  
        this.state.files.forEach(file => data.append('filesUpload', file));
        let config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        };
          // console.log(data)
          // console.log(config)
       
          Api.post('/organizations', data, config)
  
          .then(result => {
            if (result.data.success) {
              
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({id:"add.success"}),
                item: this.initialState,
                files: [],
                organization : result.data.data,
              });
              this.getGroups();
              this.getSegments();
            
              if (result.data && result.data.data && result.data.data.id)
                this.openAssociateTechinicalTeamDialog(result.data.data.id)
              else {
                this.props.history.push({
                  pathname:`/pt/wizard-organization`,
                  state:{
                    organization : result.data.data,
                    valcopy : this.state.item.valCopy,
                    item : this.state.item,
                    copyed : this.Copyed
                  }
                })
              }
  
  
            }
            else{
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})
  
              });
              Api.kickoff(result);
  
            }
  
  
  
          })
          .catch(err => {
            if(err.response && err.response.data && err.response.data.errors){
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
            }
            else{
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
  
              });
            }
          });
      }
  

  

    
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    
    this.setState({ loading: true });
    PapiroConsole.log("value")
    PapiroConsole.log(value)
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));
            
      if(stateName === 'orgCopyId'){
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            [stateName]: value.id,
          }
        }));
      }
       if(stateName === 'GroupId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['GroupText']: text,
            }
          }));
        }
        if(stateName === 'SegmentId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['SegmentText']: text,
            }
          }));
        }

        if(stateName === 'valCopy'){
          PapiroConsole.log("value")
          PapiroConsole.log(value)

          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['valCopy']: value,
              //copyed : [value]
            }
          }));
          this.Copyed = value
        }
 
    this.setState({ loading: false });

  };

  changeValuesAssociateOrganization = (stateName, value, text = '') => {
    this.setState({ [stateName]: value })
  }

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
  
    render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;
    
    if(Api == null) {
      var l = this.props.match.params.lang;
      document.location.href=`/${l}/unavailable`
    }

     return (
       
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={this.state.associateTechinicalTeamDialogOpen}
                onClose={this.closeAssociateTechinicalTeamDialog}
                aria-labelledby="confirm.association.of.techinical.teams" >
                <DialogTitle id="confirm.association.of.techinical.teams">
                  <FormattedMessage id="confirm.association.of.techinical.teams" />
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} sm={12} md={12}>
                      <SimpleAutoCompleteMultiple
                        label={intl.formatMessage({id:"menu.techinicalteams"})}
                        options={this.state.techinicalTeamsList}
                        stateName='associatedTechinicalTeamsList'
                        changeSelect={this.changeValuesAssociateOrganization}
                        selected={this.state.associatedTechinicalTeamsList} />
                    </Grid>
                  </Grid>   
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={this.closeAssociateTechinicalTeamDialog}
                    variant='outlined'
                    color="primary">
                    {intl.formatMessage({ id: "cancel" })}
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={this.handleConfirmAssociateTechicalTeam}
                    type="submit" >
                    {intl.formatMessage({ id: "confirm" })}
                  </Button>
                </DialogActions>
              </Dialog>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
              {<FormattedMessage id="add.item" />}
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit}  encType='multipart/form-data'>
                <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                      <SimpleSelect label={intl.formatMessage({id:"group"})} options={this.state.GroupList} stateName='GroupId' changeSelect={this.changeValues} selected={item.GroupId} required/>
                    </Grid>
                      <Grid item xs={12} sm={12}>
                        <SimpleSelect label={intl.formatMessage({id:"area.activity"})} options={this.state.SegmentList} stateName='SegmentId' changeSelect={this.changeValues} selected={item.SegmentId} />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField fullWidth label={intl.formatMessage({id:"name"})} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} required />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        {/*<TextField  fullWidth label="Telefone" autoComplete='fname' variant='outlined' name='telefone' onChange={this.handleInputChange} value={item.Phone} required />*/}
                        {/*<TextField
                          inputProps={{ maxLength: 13, minLength:13 }}
                          fullWidth
                          label="Telefone"
                          autoComplete="fname"
                          variant="outlined"
                          name="phone"
                          onChange={this.handleInputChange}
                          value={item.phone}
                          required
                          />*/}
                        <PhoneInput
                          inputStyle={{
                            padding: '23.5px 14px 23.5px 58px',
                            width: '100%'
                          }}
                          style={{
                              marginTop: 16,
                              marginBottom: 8
                          }}
                          id="phone"
                          name="phone"
                          country={'br'}
                          value={item.phone}
                          onChange={this.handleChangePhone}
                          InputLabel={intl.formatMessage({id:"phone"})}
                          specialLabel={intl.formatMessage({id:"phone"})}
                          inputProps={{
                            name: intl.formatMessage({id:"phone"})
                          }} />
                      </Grid>
                    <Grid item xs={11} sm={11} style={{textAlign: 'left'}}>
                      <SimpleCheckBox label={intl.formatMessage({id:"import.holiday"})} name='importHoliday' 
                      stateName='importHoliday' changeSelect={this.changeValues} />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <SimpleHelp message={intl.formatMessage({id:"organization.calendar.registration.holiday.register.functionality"})} />
                    </Grid>
                    <Grid item xs={11} sm={11} style={{textAlign: 'left'}}>
                    <SimpleCheckBox label={intl.formatMessage({id:"organization.edit.copy.organization.config"})} name='copyTemplate' 
                      stateName='copyTemplate' changeSelect={this.changeValues} />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <SimpleHelp message={intl.formatMessage({id:"organization.edit.functionality.insert.values"})}/>
                    </Grid>

                    <Grid item xs={11} sm={11} style={{textAlign: 'left'}}>
                    <SimpleCheckBox label={intl.formatMessage({id:"active.2"})} name='active' 
                      stateName='active' changeSelect={this.changeValues} selected={item.active} />
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <SimpleHelp message={intl.formatMessage({id:"organization.active.tooltip"})}/>
                    </Grid>

                    
                
                
                {this.state.item.copyTemplate && (
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete label={intl.formatMessage({id:"organization"})} options={this.state.OrgList} stateName='orgCopyId' changeSelect={this.changeValues}  required />
                    </Grid>
                )}

                {this.state.item.copyTemplate && (
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoCompleteMultiple label={intl.formatMessage({id:"organization.edit.values"})} options={this.state.CopyList} stateName='valCopy' changeSelect={this.changeValues} selected={item.valCopy} required />
                    </Grid>
                )}
                <Grid item xs={12} sm={12}>
                <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }} >
                  <Grid item xs={12} sm={4} style={{ justifyContent: 'flex-end' }} >
                    <Button 
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                </Grid>
                </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />                    
      </div>
    );
  }
}
OrganizationRegistration.propTypes = {
                    classes: PropTypes.object.isRequired
                };
                const mapStateToProps = state => ({
                    actionsHeaderMenu: state.actionsHeaderMenu,
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                });
            export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(OrganizationRegistration)));
