import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import ClearIcon from '@mui/icons-material/Clear'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Pagination from '@mui/material/Pagination'
import PropTypes from 'prop-types'
import Search from '@mui/icons-material/Search'
import { withStyles } from '@mui/styles'

import { AbilityContext } from '../../config/ability-context'
import AddIcon from '@mui/icons-material/Add'
import Api from '../../services/api'
import { Can } from '../../config/ability-context'
import * as componentActions from '../../redux/actions/calledreportconfigs'
import CustomizedSnackbars from '../../components/material-snackbars'
import { evaluateSession } from '../../utils/sessionUtils'
import Footer from '../../components/footer'
import { FormattedMessage, injectIntl } from 'react-intl'
import HeaderMenu from '../../components/header-menu'
import MyMaterialTable from '../../components/table'
import { Overlay } from '../../styles/global'
import { PapiroConsole } from '../../utils/papiroConsole'
import { paginate, paginateByOrganization, deleteItem } from '../../services/calledreportconfig.service'
import { Styles } from '../../styles/material-styles'
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';


class CalledReportConfigs extends Component {
  constructor(props) {
    super(props)

    const { userSession } = this.props
    evaluateSession(userSession)

    let fromProps = ""
    if(this.props && this.props.from && this.props.from.length > 0 && this.props.from == 'orgEdit')
      fromProps = this.props.from

    let filters = [
      { name: "Palavras-Chaves", id: "keyword" },
    ];

    this.state = {
      count:0,
      loading: false,
      notificationMessage: '',
      notificationVariant: 'error',
      openNotification: false,
      page:1,
      pageEnd:0,
      pageStart:0,
      resultList: [],
      searchValue: '',
      filterSelected: 0,
      filters: filters,
      totalPages:0,
      OrganizationFilter: 0,
      OrganizationFilterItem: null,
      from: fromProps,
      organizationList: []
    }
  }

  changeValues = async (stateName, value, text = '') => {
    if(stateName === 'OrganizationFilter') {
      this.setState({ [stateName]: value.id })
      this.getItens()
    }else
      this.setState({filterSelected : value})
  }

  callSearch = _ => {
    if(this.state.searchValue !== '')
      this.getItens(this.state.page)
  }

  clearFields = async _ => {
    await this.setState({ searchValue: '', page: 1 })
    this.getItens(1)
  }

  closeNotification = () => {
    this.setState({ openNotification: false })
  }

  async componentDidMount() {
    if (this.props.location && (this.props.location.pathname === `/${this.props.match.params.lang}/called-report-configs` || this.props.location.pathname === `/called-report-configs`))
      await this.getOrganizations()
    
    let sessionOrgFilter = localStorage.getItem('additionalcalledcostitemTableOrganizationFilter')
    if(sessionOrgFilter == null) {
        sessionOrgFilter = 0
        localStorage.setItem('additionalcalledcostitemTableOrganizationFilter', '0')
    }
    let orgFilterItem = null
    if(!isNaN(sessionOrgFilter) && typeof sessionOrgFilter === 'string') {
      sessionOrgFilter = parseInt(sessionOrgFilter)
      if(sessionOrgFilter > 0 && this.state.organizationList != null) {
        orgFilterItem = this.state.organizationList.filter(item => item.id == sessionOrgFilter)
        if(orgFilterItem && orgFilterItem.length > 0) {
          await this.setState({
            OrganizationFilterItem: orgFilterItem[0]
          })
        }
      }
    }
    await this.setState({ OrganizationFilter: sessionOrgFilter, OrganizationFilterItem: orgFilterItem })

    await this.getItens(1)
  }

  getOrganizations = async _ => { 
    this.setState({ loading: true })
    let data = [];
    const { userSession } = this.props
    if(userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
        data = userSession.user.userorganizations
    } else {
        await Api.get('/organizations/tech')
            .then(result => {
                data = result.data.data
            })
            .catch(err => {
                data = err;
        })
    }
    this.setState({ organizationList: data, loading: false })
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.location.state === "reload")
      this.componentDidMount()
  }

  deleteItem = (data, resolve) => {
    let abl = this.context.can('Delete', 'Config')
    if (abl === false){
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({ id: "operation.notAllowed" })
      })
      resolve()
      return false
    }

    Api.delete(`/configs/${data.id}`)
    .then(result => {
      if (result.data.success) {
        const intl = this.props.intl
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'success',
          notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
          resultList: []
        })

        this.getItens()
      } else{
        const intl = this.props.intl
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(result)
      }
        resolve()
    })
    .catch(err => {
      resolve()
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(err)
    })
  }

  onChangePaginate = (e, page) => {
    this.getItens(page)
    this.setState({ page: page })
  }

  onChangeField = (event) => {  
  this.setState({
    [event.target.name]: event.target.value,
    page: 1
  })
 }

  setPagination = (count, page) => {                                      
    let totalPage = Math.ceil (count/10);
    this.setState({ totalPages: totalPage })
    if(count > 0){
      if(page === 1) {
        if(count < 10)
          this.setState({ pageStart: 1, pageEnd: count })
        else
          this.setState({ pageStart: 1, pageEnd: 10 })
      } else {
        let pageStart = ((page - 1) * 10) + 1
        if(count >= page * 10)
          this.setState({pageStart: pageStart, pageEnd: page * 10 })
        else
          this.setState({pageStart: pageStart, pageEnd: count })
      }
    } else
      this.setState({ totalPages: 0, page: 1, count: 0 })
  }

  getItens = async (page = 1) => {
    this.setState({ loading: true })

    let result = (this.props.location && (this.props.location.pathname === `/${this.props.match.params.lang}/called-report-configs` || this.props.location.pathname === `/called-report-configs`)) ? await paginate(page, this.state.OrganizationFilter, this.state.searchValue, this.state.filterSelected) : paginateByOrganization(page, this.state.organizations.organization.id, this.state.searchValue, this.state.filterSelected)
    if (result && result.success) {
      let count = result.count;
      this.setState({ count: count });
      this.setPagination(count, page);
      if(result.data.length === 0) {
          if(this.state.OrganizationFilter == 0)
              this.emptyArray = true
          localStorage.setItem('calledreportconfigTableOrganizationFilter', '0')
          this.setState({ OrganizationFilter: 0 })
      } else {
          this.emptyArray = false
      }

      const formattedList = result.data.map(item => {
        return {
          ...item,
          OrganizationId: item.organization ? item.organization.id : 0,
          OrganizationText: item.organization ? item.organization.name : '',
        }
      })
      this.setState({ loading: false, resultList: formattedList })
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }

  render () {
    const { classes, headerMenu, actionsHeaderMenu, intl } = this.props

    if (Api == null) {
      var l = this.props.match.params.lang
      document.location.href = `/${l}/unavailable`
    }
    
    return (
      <div className={classes.root}
        style={{
          paddingLeft: 0,
          marginLeft: 0
        }}
      >
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />
          <Container
            component='main'
            maxWidth='xl'
             
            style={{ maxWidth: '100%', paddingLeft:0, marginLeft:0}}
          >
            <CssBaseline />
            <Grid container className={classes.mb20}  spacing={0}>
              <Button
                variant='contained'
                color='secondary'
                size='large'
                disabled={!this.context.can('Add', 'DefaultAccompaniment')}
                className={classes.button}
                startIcon={<AddIcon />}
                component={Link}
                {...{ to: {
                  pathname: `/${this.props.match.params.lang}/called-report-config-registration`,
                  state: {
                    internal: this.props.internal,
                    from: this.state.from
                }
                  }
                }} >
                {<FormattedMessage id="add" />}
              </Button>
            </Grid>
            <Grid
              container
              spacing={6}
            >
              <Grid
                item
                sm={8}
                xs={12}
              />
            </Grid>
            <Grid
              container
              spacing={6}>
              <Grid
                item
                sm={12}
                xs={12}
              >
                <Card className="background-title-search">
                  <CardContent>
                    <Grid
                      alignItems="flex-end"
                      container
                      spacing={1}
                    >
                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <Typography variant="h5">
                          <FormattedMessage id="called.report.configs" />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        {(this.props.location && this.props.location.pathname === `/${this.props.match.params.lang}/called-report-configs`) && (<SimpleAutoComplete label={intl.formatMessage({id:"organization"})} options={this.state.organizationList} stateName='OrganizationFilter'
                          selected={this.state.OrganizationFilterItem}
                          changeSelect={(stateName, value, text = '') => {
                            PapiroConsole.log("=== OrganizationFilter ===")
                            PapiroConsole.log(value)

                            this.setState({
                              OrganizationFilter: value.id,
                              OrganizationFilterItem: value
                          }, () => {
                            if(value && value.id && value.id > 0)
                                localStorage.setItem('calledreportconfigTableOrganizationFilter', `${value.id}`)
                            else
                              localStorage.setItem('calledreportconfigTableOrganizationFilter', '0')
                              this.getItens()
                          })
                        }}/>)}
                      </Grid>
                      <Grid item xs={12} md={2}></Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Can
              I="List"
              a="CalledReportConfig"
            >
              <MyMaterialTable
                title=""
                rowClick={(event, rowData) => {
                  let abl = this.context.can('Edit', 'CalledReportConfig')
                  if (abl === false) {
                    this.setState({
                      loading: false,
                      openNotification: true,
                      notificationVariant: 'error',
                      notificationMessage: intl.formatMessage({ id: "operation.notAllowed" })
                    })
                    return false
                  }
                  this.props.addCurrent(rowData)
                  this.props.history.push({
                    pathname: `/${this.props.match.params.lang}/called-report-config-edit`,
                    state: {
                      internal: this.props.internal,
                      from: this.state.from
                    }
                  })
                }}
                columns={[
                  { title: intl.formatMessage({id:"title"}), field: 'title' },
                  { title: intl.formatMessage({ id: "organization" }), field: 'OrganizationText' },
                  { title: intl.formatMessage({id:"called.report.config.declaration"}), field: 'declaration' },
                ]}
                data={this.state.resultList}
              />
            </Can>
            <Can
              I="List"
              a="CalledReportConfig"
            >
              <Grid
                container
                style={{
                  marginBottom: 12,
                  marginTop: 8
                }}
              >
                <Grid
                  item
                  md={3}
                  xs={12}
                >
                  <Pagination
                    count={this.state.totalPages}
                    onChange={this.onChangePaginate}
                    page={this.state.page}
                  />
                </Grid>
                <Grid
                  item
                  style={{ marginTop: 6 }}
                  md={3}
                  xs={12}
                >
                  {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                </Grid>
              </Grid>
            </Can>
            {this.state.loading && (
              <Overlay>
                <CircularProgress color="primary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              isOpen={this.state.openNotification}
              message={this.state.notificationMessage}
              toClose={this.closeNotification}
              variant={this.state.notificationVariant}
            />
          </Container>
        </main>
        <Footer />
      </div>
    )
  }
}

CalledReportConfigs.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(CalledReportConfigs)))

CalledReportConfigs.contextType = AbilityContext