import Api from './api';
export const getOrganizations = async () => { 
  let data = [];
  await Api.get('/organizations')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getOrganizationsById = async (id) => { 
  let data = [];
  await Api.get('/organizations/'+id)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};


export const getFieldOptionValuesByOrganizationField = async (id) => { 
  let data = [];
  await Api.get(`/organizationfieldoptionsvalues/getfieldoptionvaluesbyorganizationfield/${id}`)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};
export const getOrganizationsByIdLight = async (id) => { 
  let data = [];
  await Api.get('/organizations/notincludes/'+id)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getAllActiveByOrganizationIdWithoutIncludes = async (organizationId) => { 
  let data = [];
  await Api.get(`/organizationfields/organizations/${organizationId}/active/withoutincludes`)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getAllActivedWithoutIncludes = async () => { 
  let data = [];
  await Api.get(`/organizationfields/all/active/withoutincludes`)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};


export const getAllActivedWithOrganizations = async () => { 
  let data = [];
  await Api.get(`/organizationfields/all/active/withorganizations`)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
};

export const getAllActiveByOrganizationIdAndCalledServiceSettingIdWithoutIncludes = async (organizationId, calledServiceSettingsId) => { 
  let data = [];
  await Api.get(`/organizationfields/organizations/${organizationId}/calledservicesettings/${calledServiceSettingsId}/active/withoutincludes`)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
}

export const getAllActiveByOrganizationIdAndCalledServiceSettingIdAndCalledServiceSettingsOrganizationFieldIdWithoutIncludes = async (organizationId, calledServiceSettingsId, calledServiceSettingsOrganizationFieldId) => { 
  let data = [];
  await Api.get(`/organizationfields/organizations/${organizationId}/calledservicesettings/${calledServiceSettingsId}/calledservicesettingsorganizationfield/${calledServiceSettingsOrganizationFieldId}/active/withoutincludes`)
      .then(result => {
          data = result.data;
       })
       .catch(err => {
          data = err;
  });
  return data;
}

export const getAllSelectionModelByOrganizationIdAndActiveAndtarget = async (orgId, isActive = true, target = 1) => { 
  let data = []
  await Api.get(`/organizationfields/selectionmodel/organization/${orgId}/active/${isActive}/target/${target}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}