import React from 'react';
import HeaderImg from '../../images/me-feedback/header-new-2.png';


const MEFeedbackHeader = props => (
    <div>
        <img
            src={props.src && props.src.length > 0 ? props.src : HeaderImg}
            style={props.style != null ? props.style : { maxWidth: '100%' }}
            title="FeedbackHeader"
            className='App-feedback-header' />
    </div>
);

export default MEFeedbackHeader;
