export default function organizationslacalleds(state = [], action) {
    switch (action.type) {
        case 'ADD_CURRENT':
            return {
                ...state,
                organizationslacalled: action.organizationslacalled,
            }
        default:
            return state;
    }
}
