import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { importGraphFromJSON, loadStencilShapes, zoomToFit } from '../../joint-plus/actions';
import { State } from '../../redux/reducer';
import JointPlusService from '../../services/joint-plus.service';
import { STENCIL_WIDTH } from '../../theme';
import './Chatbot.scss';
import Inspector from './Inspector/Inspector';

import { useIntl } from 'react-intl';

import Api from 'src/services/api';
import * as ChatbotFlowService from "../../../../../../../services/2talk/chatbot-flow.service.js";

import { CircularProgress } from '@mui/material';
import CustomizedSnackbars from 'src/components/material-snackbars';
import { Overlay } from 'src/styles/global';



const Chatbot = ({id, idChatbot}: {id: string, idChatbot: string}): ReactElement => {
    
    const dispatch = useDispatch();
    const intl = useIntl();

    const elementRef = useRef(null);
    const toolbarRef = useRef(null);
    const stencilRef = useRef(null);
    const paperRef = useRef(null);

    const [joint, setJoint] = useState(null);
    const [stencilOpened, setStencilOpened] = useState(true);
    const [jsonEditorOpened, setJsonEditorOpened] = useState(true);
    const [fileJSON, setFileJSON] = useState(null);

    const [statePage, setStatePage] = useState({
        loading: false,
        openNotification: false,
        notificationVariant: "error",
        notificationMessage: ""
    });

    const fetchDataNotSuccess = (result: any) => {
        setStatePage({
        loading: false, 
        openNotification: true, 
        notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        })
    }

    const fetchDataThrowError = (err: any) => {
        setStatePage({
        loading: false, 
        openNotification: true, 
        notificationVariant: "error",
        notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        })
    }

    const closeNotification = () => {
        setStatePage(prevState => ({...prevState, openNotification: false}));
    };

    const getJson = async () => {
        setStatePage(prevState => ({...prevState, loading: true}));
        
        try {
            const output = await ChatbotFlowService.getJsonFlowById(id);
        
            if (output.success) {
                const jsonflow = output?.data?.jsonflow; 
                if (jsonflow) return JSON.parse(jsonflow);
            } else {
                fetchDataNotSuccess(output);
                Api.kickoff(output);
            }

            return {}
        } catch (err) {
            fetchDataThrowError(err);
            Api.kickoff(err);
            return {}
        } finally {
            setStatePage(prevState => ({...prevState, loading: false}));
        }
    }

    const graphJSON = useSelector<State>(state => {
        return state.graphJSON;
    });

    const openFile = useCallback((json: Object): void => {
        setFileJSON(json);
        importGraphFromJSON(joint, json);
        zoomToFit(joint);
    }, [joint]);

    const onStart = useCallback(async (): Promise<void> => {
        loadStencilShapes(joint);
        let _json = await getJson();
        setFileJSON(_json);
        openFile(_json);
    }, [joint, openFile]);

    const onStencilToggle = useCallback((): void => {
        if (!joint) {
            return;
        }
        const { scroller, stencil } = joint;
        if (stencilOpened) {
            stencil.unfreeze();
            scroller.el.scrollLeft += STENCIL_WIDTH;
        } else {
            stencil.freeze();
            scroller.el.scrollLeft -= STENCIL_WIDTH;
        }
    }, [joint, stencilOpened]);

    const toggleJsonEditor = (): void => {
        setJsonEditorOpened(!jsonEditorOpened);
    };

    const toggleStencil = (): void => {
        setStencilOpened(!stencilOpened);
    };

    const setStencilContainerSize = useCallback((): void => {
        stencilRef.current.style.width = `${STENCIL_WIDTH}px`;
    }, []);

    useEffect(() => {
        setFileJSON(graphJSON);
    }, [graphJSON]);

    useEffect((): void => {
        onStencilToggle();
    }, [stencilOpened, onStencilToggle]);

    useEffect(() => {
        setJoint(new JointPlusService(
            elementRef.current,
            paperRef.current,
            stencilRef.current,
            toolbarRef.current,
            dispatch
        ));
    }, [dispatch]);

    useEffect(() => {
        if (!joint) {
            return;
        }
        setStencilContainerSize();
        onStart();
    }, [joint, onStart, setStencilContainerSize, dispatch]);

    useEffect(() => {
        if (!joint) {
            return;
        }
        return () => {
            joint.destroy();
        };
    }, [joint]);

    return (
        <>  
            <div ref={elementRef} className="joint-scope chatbot">
                <div ref={toolbarRef}/>
                <div className="side-bar">
                    <div className="toggle-bar">
                        <div onClick={toggleStencil}
                            className={'icon toggle-stencil ' + (!stencilOpened ? 'disabled-icon' : '')}
                            data-tooltip="Toggle Element Palette"
                            data-tooltip-position-selector=".toggle-bar"/>
                        {/*<div onClick={toggleJsonEditor}
                            className={'icon toggle-editor ' + (!jsonEditorOpened ? 'disabled-icon' : '')}
                            data-tooltip="Toggle JSON Editor"
                            data-tooltip-position-selector=".toggle-bar"/>*/}
                    </div>
                    <div ref={stencilRef}
                        style={{ display: stencilOpened ? 'initial' : 'none', minWidth: '230px' }}
                        className="stencil-container"/>
                </div>
                <div className="main-container">
                    <div ref={paperRef} className="paper-container"/>
                    {/*<div style={{ display: jsonEditorOpened ? 'initial' : 'none' }}>
                        <JsonEditor content={fileJSON}/>
                    </div>*/}
                </div>
                <Inspector chatbotFlowId={id} json={fileJSON} chatbotId={idChatbot}/>
            </div>

            {statePage.loading && (
                <Overlay>
                    <CircularProgress color='secondary' />
                </Overlay>
            )}

            <CustomizedSnackbars
                variant={statePage.notificationVariant}
                message={statePage.notificationMessage}
                isOpen={statePage.openNotification}
                toClose={closeNotification}
            />

        </>
    );
};

export default Chatbot;
