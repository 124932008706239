
import Api2Talk from "../../services/api2talk";

export const getMessageProviders = async () => { 
  let data = [];
  await Api2Talk.get('/messageprovider')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};



export const getAllWithAccountSid = async () => { 
  let data = [];
  await Api2Talk.get('/messageprovider/with-accountsid')
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};