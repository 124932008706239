import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'

import { Styles } from '../../../styles/material-styles'
import { Grid, Paper, Typography } from '@mui/material'
import ReadOnlyField from '../../../components/read-only-field'

function CalledData(props) {

  const { intl } = props
  if (props.calledData != null) {
    return (
      <Paper elevation={3} sx={{ width: '100%', paddingBottom: '25px' }}>
        <Grid container spacing={2} sx={{ marginTop: '16px!important', paddingLeft: '16px!important', paddingRight: '16px!important' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <h4 style={{ color: "#3F51B4", fill: "#3F51B4", margin: 0, fontWeight: 600, display: 'flex', alignItems: 'flex-end' }}>Dados atuais do chamado</h4>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <ReadOnlyField
              label={intl.formatMessage({ id: "called" })}
              value={`#${ props.calledData.id != null && props.calledData.id } - ${ props.calledData.title != null && props.calledData.title }`} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6} />
          {props.calledData.organization != null && <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <ReadOnlyField
              label={intl.formatMessage({ id: "organization" })}
              value={ (props.calledData.organization.name && props.calledData.organization.name.length > 0) && props.calledData.organization.name } />
          </Grid>}
          {props.calledData.organizationcategory != null && <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <ReadOnlyField
              label={intl.formatMessage({ id: "category" })}
              value={(props.calledData.organizationcategory.parentname != null && props.calledData.organizationcategory.parentname.length > 0) ? props.calledData.organizationcategory.parentname : (props.calledData.organizationcategory != null && props.calledData.organizationcategory.name != null && props.calledData.organizationcategory.name.length > 0) && props.calledData.organizationcategory.name } />
          </Grid>}
          {props.calledData.organizationdepartament != null && <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <ReadOnlyField
              label={intl.formatMessage({ id: "departament" })}
              value={ (props.calledData.organizationdepartament.name && props.calledData.organizationdepartament.name.length > 0) && props.calledData.organizationdepartament.name } />
          </Grid>}
          {props.calledData.address != null && <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <ReadOnlyField
              label={intl.formatMessage({ id: "address" })}
              value={ (props.calledData.address != null && props.calledData.address.name && props.calledData.address.name.length > 0) && props.calledData.address.name} >
              <React.Fragment>
                <Typography>{props.intl.formatMessage({id:"name"})}: {props.calledData.address && props.calledData.address.name ? props.calledData.address.name : '---'}</Typography>
                <Typography>{ props.calledData.address.street.length > 0 && props.calledData.address.street } { props.calledData.address.number.length > 0 && `, ${props.calledData.address.number}` } { props.calledData.address.neighborhood.length > 0 && props.calledData.address.neighborhood } { props.calledData.address.city.length > 0 && `- ${props.calledData.address.city}` } { props.calledData.address.state.length > 0 && `, ${props.calledData.address.state}` } { props.calledData.address.country.length > 0 && `- ${props.calledData.address.country}` }</Typography>
              </React.Fragment>
            </ReadOnlyField>
          </Grid>}
          {props.calledData.techinicalteam != null && <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <ReadOnlyField
              label={intl.formatMessage({ id: "attendance.team" })}
              value={ (props.calledData.techinicalteam.name && props.calledData.techinicalteam.name.length > 0) && props.calledData.techinicalteam.name } />
          </Grid>}
          {props.calledData.assigneduser != null && <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <ReadOnlyField
              label={intl.formatMessage({ id: "assigned" })}
              value={ (props.calledData.assigneduser.name && props.calledData.assigneduser.name.length > 0) && `${props.calledData.assigneduser.name} (${props.calledData.assigneduser.email && props.calledData.assigneduser.email.length > 0 && props.calledData.assigneduser.email})`} />
          </Grid>}
        </Grid>
      </Paper >
    )
  }

  return (<React.Fragment></React.Fragment>)
}

CalledData.propTypes = {
  classes: PropTypes.object.isRequired,
  setMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
})

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(CalledData)))