import 'date-fns';
import React from 'react';
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {TextField } from "@mui/material";

//import { DatePicker } from '@mui/x-date-pickers/DatePicker';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
//import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function MaterialUIPickerInline(props) {

    const { label, stateName, changeDate, onChange,  currentDate, format = 'dd/MM/yyyy', fullWidth = false } = props;

    const [selectedDate, setSelectedDate] = React.useState();


    React.useEffect(() => {
        setSelectedDate(currentDate);
      }, [currentDate]);

    const handleDateChange = date => {
        setSelectedDate(date);
        if(changeDate != null){
            changeDate(stateName, date);
        }
        else{
            if(onChange != null){
                onChange(stateName, date);
            }
        }
    };

    const today = new Date();

    return (
        <LocalizationProvider utils={DateFnsUtils}  dateAdapter={AdapterDateFns}>
            <Grid container justify="space-around">
                <DatePicker renderInput={(props) => <TextField fullWidth={fullWidth} {...props} />} 
                    inputFormat={format}
                    disableToolbar
                    variant="inline"
                    format={format}
                    label={label}
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </Grid>
        </LocalizationProvider>
    );
}

MaterialUIPickerInline.propTypes = {
    label: PropTypes.string,
    stateName: PropTypes.string,
    changeDate: PropTypes.func,
    currentDate: PropTypes.string,
    format: PropTypes.string
};
