import React from 'react'
import * as componentActions from '../../../../redux/actions/chats' // trocar pelo redux que criarei no chat
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Styles } from '../../../../styles/material-styles'
import { withStyles } from '@mui/styles'
import './styles.css'
import { Box, Grid } from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import LanguageIcon from '@mui/icons-material/Language';
import PublicIcon from '@mui/icons-material/Public';
import ChatIcon from '@mui/icons-material/Chat';
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import classnames from 'classnames';
import { PapiroConsole } from '../../../../utils/papiroConsole'
import moment from 'moment'
import { styled } from '@mui/system';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { FacebookIconWithGradient } from '../icons/icons-with-gradient'
import Api2Talk from "../../../../services/api2talk";

const StyledBadge = styled(Badge)(({ theme, getBackgroundColor }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: getBackgroundColor(), // Usando a propriedade backgroundColor dinamicamente
    color: getBackgroundColor(), // Estilo do texto
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      border: '1px solid currentColor',
      content: '""',
    },
  },
}));




function ChatMenuUserInfo(props) {

  PapiroConsole.log("=== ChatMenuUserInfo data ===")
  PapiroConsole.log(props)

   const  handleClick = _ => {
    
    if (props.data && props.chats.chat && props.data.id == props.chats.chat.id) {
      PapiroConsole.log(props.data)
      PapiroConsole.log("props.data 1")
      props.addCurrentChat(null)
    } else {
      PapiroConsole.log(props.data)
      PapiroConsole.log("props.data")
      props.addCurrentChat(props.data)
      if(props.data.unreadmessages){
        updateNotificationsMessages()
      }

      //código feito parar fechar a barra de histórico quando o usuário troca a conversa e ela está aberta 
      if(props.chats.ishistorysidebaryopen){
        props.handleChatHistorySidebarToggle(false)
      }
      

      
    }
  }

  const updateNotificationsMessages = async () => {
    
    if (props && props.data && props.data.id && props.unreadMessages) {

      let sentData = {
        
        chatid : props.data.id
      }
      
      await Api2Talk.put('chat/updatenotificationmessage', sentData)
        .then((result) => {
          PapiroConsole.log(result)
          PapiroConsole.log("result notifications")
          if (result.data.success) {
            
            if (result.data && result.data.data ) {
              PapiroConsole.log("enntrei aqui")
              props.resetChatNumberOfNotification(props.data.id)
  
              /*props.setLoading(false);
              props.setOpenNotification(true);
              props.setNotificationMessage("Suas informações foram atualizadas com sucesso");
              props.setNotificationVariant("success");*/
  
  
  
            }
  
          } else {
            /*props.setLoading(false);
            props.setOpenNotification(true);
            props.setNotificationMessage("Erro ao processar a solicitação.");
            props.setNotificationVariant("error");*/
          }
        })
        .catch((err) => {
        
          if (err.response && err.response.data && err.response.data.errors) {
            /*props.setLoading(false);
            props.setOpenNotification(true);
            props.setNotificationMessage(err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : "Erro ao processar a solicitação.");
            props.setNotificationVariant("error");*/
  
  
          } else {
            /*props.setLoading(false);
            props.setOpenNotification(true);
            props.setNotificationMessage(err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : "Erro ao processar a solicitação.");
            props.setNotificationVariant("error");*/
          }
        });
      
    }
  
  
  
  
  
  }

  const getDynamicBackgroundColor = () => {
    if (props.chatstatuscolorhex) {
      PapiroConsole.log(props.chatstatuscolorhex)
      PapiroConsole.log("props da cor aqui")
      return props.chatstatuscolorhex;
    }
    else {
      return '#CCC'
    }


  }

  return (<div className={classnames(
    'omnichannel-chat-menu-user-info',
    {
      'omnichannel-chat-menu-user-info-selected': (props.chats != null && props.chats.chat != null && props.chats.chat.id != null && props.chats.chat.id == props.id)
    }
  )}
    style={(props.priorityColorHex && props.priorityColorHex.length > 0) ? { borderLeft: `6px solid ${props.priorityColorHex}` } : {}}
    onClick={handleClick} >
    <Grid container spacing={1} >
      <Grid item xs={12} sm={10} md={10} lg={11} xl={11} className="omnichannel-chat-menu-user-info-header">

        <Grid className='omnichannel-chat-menu-user-info-header-icon' item xs={2.5}>
          <StyledBadge
            getBackgroundColor={getDynamicBackgroundColor}
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
          >
            <AccountCircleIcon sx={{ color: '#3F51B4', width: '50px', height: '50px' }} />
          </StyledBadge>
        </Grid>
        <Grid item xs={9.5} style={{ marginTop: '5px', paddingLeft: '10px' }}>
          <span > {props.title ? props.title + ' - ' : ''} {props.userName} </span>
          <Typography variant="subtitle2" color="#9048D9" style={{ fontWeight: 'bold', fontSize: '10px' }} >
            {props.category ? props.category.name : ''}
          </Typography>

        </Grid>


      </Grid>
      <Grid item xs={4} sm={2} md={2} lg={1} xl={1} sx={{ display: 'flex', alignItems: 'center' }}>
        {(props.messageproviderid == 2) && <WhatsAppIcon sx={{ color: '#59BC6B' }} />}
        {(props.messageproviderid == 3) && <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
          <radialGradient id="yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1" cx="19.38" cy="42.035" r="44.899" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#fd5"></stop><stop offset=".328" stop-color="#ff543f"></stop><stop offset=".348" stop-color="#fc5245"></stop><stop offset=".504" stop-color="#e64771"></stop><stop offset=".643" stop-color="#d53e91"></stop><stop offset=".761" stop-color="#cc39a4"></stop><stop offset=".841" stop-color="#c837ab"></stop></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8ma_Xy10Jcu1L2Su_gr1)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><radialGradient id="yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2" cx="11.786" cy="5.54" r="29.813" gradientTransform="matrix(1 0 0 .6663 0 1.849)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#4168c9"></stop><stop offset=".999" stop-color="#4168c9" stop-opacity="0"></stop></radialGradient><path fill="url(#yOrnnhliCrdS2gy~4tD8mb_Xy10Jcu1L2Su_gr2)" d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20	c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20	C42.014,38.383,38.417,41.986,34.017,41.99z"></path><path fill="#fff" d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5	s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"></path><circle cx="31.5" cy="16.5" r="1.5" fill="#fff"></circle><path fill="#fff" d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12	C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"></path>
        </svg>}
        {(props.messageproviderid == 4) && <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
          <path fill="#448AFF" d="M24,4C13.5,4,5,12.1,5,22c0,5.2,2.3,9.8,6,13.1V44l7.8-4.7c1.6,0.4,3.4,0.7,5.2,0.7c10.5,0,19-8.1,19-18C43,12.1,34.5,4,24,4z"></path><path fill="#FFF" d="M12 28L22 17 27 22 36 17 26 28 21 23z"></path>
        </svg>}
        {(props.messageproviderid == 5) && <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 48 48">
          <path fill="#29b6f6" d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z"></path><path fill="#fff" d="M33.95,15l-3.746,19.126c0,0-0.161,0.874-1.245,0.874c-0.576,0-0.873-0.274-0.873-0.274l-8.114-6.733 l-3.97-2.001l-5.095-1.355c0,0-0.907-0.262-0.907-1.012c0-0.625,0.933-0.923,0.933-0.923l21.316-8.468 c-0.001-0.001,0.651-0.235,1.126-0.234C33.667,14,34,14.125,34,14.5C34,14.75,33.95,15,33.95,15z"></path><path fill="#b0bec5" d="M23,30.505l-3.426,3.374c0,0-0.149,0.115-0.348,0.12c-0.069,0.002-0.143-0.009-0.219-0.043 l0.964-5.965L23,30.505z"></path><path fill="#cfd8dc" d="M29.897,18.196c-0.169-0.22-0.481-0.26-0.701-0.093L16,26c0,0,2.106,5.892,2.427,6.912 c0.322,1.021,0.58,1.045,0.58,1.045l0.964-5.965l9.832-9.096C30.023,18.729,30.064,18.416,29.897,18.196z"></path>
        </svg>}
        {(props.messageproviderid == 6) && <ChatIcon sx={{ color: '#277afe' }} />}
      </Grid>





      <Grid item xs={10} sm={10} md={10} lg={11} xl={11} className="omnichannel-chat-menu-user-info-header">
        {props.senderid == props.userSession.user.id && <span style={{ alignItems: 'left', color: '#B4B4B4', fontSize: '10px' }}><br />{"*Conversa iniciada pelo atendente"}</span>}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid container spacing={1}>
          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} />
          <Grid className='omnichannel-chat-menu-user-info-message' item xs={5} sm={5} md={6} lg={6} xl={6} sx={{
            display: 'flex',
            alignItems: 'center',
            color: '#B4B4B4',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          }}>
            {props.lastMessage?.content ?? ''}
          </Grid>
          <Grid item xs={3} sm={3} md={2} lg={2} xl={2} sx={{ display: 'flex', alignItems: 'center', color: '#B4B4B4', justifyContent: 'flex-end' }}>
            { /*moment(props.lastMessage?.createdat.toString()+'').format('YYYY-MM-DD[T]HH:mm:ss') ?? ''*/}
            {props.lastMessage?.createdat.toString()}

          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            {props.unreadMessages > 0 && <Box component="div" className="omnichannel-chat-menu-header-total-messages" >
              {props.unreadMessages}
            </Box>}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </div>)
}

ChatMenuUserInfo.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  chats: state.chats,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(ChatMenuUserInfo)))