import React from 'react'
import ChatMessageImage from './chat-message-image'
import ChatMessageSticker from './chat-message-sticker'
import ChatMessageDocument from './chat-message-document'
import Comming from '../../icons/image-comming'
import Attachment from '../../icons/attachment'
import Audio from '../../icons/audio'
import Video from '../../icons/video'
import { PapiroConsole } from '../../../../../utils/papiroConsole'
import { validateChatLocationCoordinates } from '../../../../../utils/locationUtils'
import ChatMessageLocation from './chat-message-location'
import ChatMessageAudio from './chat-message-audio'
import ChatMessageVideo from './chat-message-video'
import ChatMessageContact from './chat-message-contact'

function ChatMessageHandler({ content, id, setLoading, src, title, messagetype }) {
  PapiroConsole.log("=== ChatMessageHandler ===")
  PapiroConsole.log(content)
  PapiroConsole.log(messagetype)
  if (content) {
    switch (messagetype) {
      case "image":
        return <ChatMessageImage
        content={content}
        id={id}
        setLoading={setLoading}
        src={src}
        title={title} />
      case "sticker":
        return <ChatMessageSticker
        content={content}
        id={id}
        setLoading={setLoading}
        src={src}
        title={title} />
      case "document":
        return <ChatMessageDocument
        content={content}
        id={id}
        setLoading={setLoading}
        src={src}
        title={title} />
      case "audio":
        return <ChatMessageAudio
        content={content}
        id={id}
        setLoading={setLoading} />
      case "video":
        return <ChatMessageVideo
        content={content}
        id={id}
        setLoading={setLoading}
        src={src}
        title={title} />
      case "contacts":
        return <ChatMessageContact
          content={content} />
      case "location":
        const locationAddressCoordinates = content
        let coordinates = validateChatLocationCoordinates(locationAddressCoordinates)
        if (coordinates != null && coordinates.longitude != null && coordinates.latitude != null) {
        return <ChatMessageLocation
          setLoading={setLoading}
          id={id}
          title={title}
          content={content}
          initialLatitude={coordinates.latitude}
          initialLongitude={coordinates.longitude} />
        } else {
          return "Não foi possível carregar a localização!"
        }
      default:
        return content
    }
  } else {
    return <></>
  }
}

export default React.memo(ChatMessageHandler)