
import React, { Component } from "react";
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import { CssBaseline, CircularProgress, Container, Button, Grid, Select } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Overlay } from "../../styles/global";
import CustomizedSnackbars from "../../components/material-snackbars";
import HeaderMenu from "../../components/header-menu";
import MaterialTable from 'material-table';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import MyMaterialTable from '../../components/table';
import Api from "../../services/api";
import * as CalledService from '../../services/called.service';
import * as calledsActions from '../../redux/actions/calleds';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import Footer from '../../components/footer';
import moment from "moment";
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { getSlaAtendimento, getSlaSolucao } from '../called-list/called.functions';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import SimpleAutoCompleteSearch from '../../components/auto-complete/autocomplete-search-api';
import SimpleSelect from '../../components/select/simple';
import SimpleDatePicker from '../../components/date-pickers/inline-no-today';
import { saveAs } from 'file-saver';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import Pagination from '@mui/material/Pagination';
import Search from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import ApiReports from "../../services/apireport";
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import LogoMarca from '../../images/new-logo-cmtech.png';
import html2canvas from 'html2canvas';
import Divider from '@mui/material/Divider'
import * as ExcelJS from 'exceljs'
import { PapiroConsole } from "../../utils/papiroConsole";
import fileDownload from 'js-file-download'
import { getAuditContextOptions } from "../../utils/auditOptions";

class AuditReport extends Component {

    constructor(props) {
        super(props);
        this.Reload = this.Reload.bind(this);
        this.getFile = this.getFile.bind(this);
        this.Reload();

        this.typingTimeout = null;

        const { userSession } = this.props;

        this.isClient = true;
        this.isAdm = false;

        if (userSession == null ||  userSession.user == null || userSession.user.userrole == null) {
            Api.logoff();
        }

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype != 3) {
                this.isClient = false;
            }
            if (item.role.roletype == 0 || item.role.roletype == 1) {
                this.isAdm = true;
            }
        }
        );
        this.organizationId = null;
        if (userSession.user.organization) {
            this.organizationId = userSession.user.organization.id;
        }

        const contextOptions = getAuditContextOptions()

        this.state = {
            dateStart: moment().format('YYYY/MM/DD HH:mm:ss'),
            dateEnd: moment().format('YYYY/MM/DD HH:mm:ss'),
            organizationId: this.organizationId,
            OrganizationFilter: 0,
            location: 0,
            slafiltersolution: 0,
            slafilterservice: 0,
            techuser: 0,
            departament: 0,
            loading: false,
            openNotification: false,
            notificationVariant: "error",
            notificationMessage: "",
            showGrid: false,
            calledsList: [],
            organizationList: [],
            organizationAddressesList: [],
            techUsersList: [],
            organizationDepartamentsList: [],
            calledPdfList: [],
            totalPages: 0,
            page: 1,
            pageStart: 0,
            pageEnd: 0,
            count: 0,
            searchValue: '',
            showHeader: false,
            context: "",
            contextSelected: "",
            usersOptions: new Array,
            user: 0,
            operationOptions: [
                { id: 'Login', name: 'Login' },
                { id: 'Insert', name: 'Insert' },
                { id: 'Update', name: 'Update' },
                { id: 'Delete', name: 'Delete' }
            ],
            operation: 0,
            pkkey: 0,
            contextOptions: contextOptions,
            searchOptions: [
                { id: 1, name: props.intl.formatMessage({ id: "search.mode.equalsto"}) },
                { id: 2, name: props.intl.formatMessage({ id: "search.mode.includes"}) },
            ],
            searchOption: 1,
        };
        this.closeNotification = this.closeNotification.bind(this);
        this.changeValues = this.changeValues.bind(this);

        this.callSearch = this.callSearch.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);
        this.exportPdf = this.exportPdf.bind(this);
        this.getCalledsToPdf = this.getCalledsToPdf.bind(this);
    }

    async changeValues(stateName, value, text = '') {
        PapiroConsole.log(stateName)
        PapiroConsole.log(value)
        this.setState({ loading: true });
        if (stateName === 'operation' || stateName === 'user' || stateName == 'slafiltersolution' || stateName == 'slafilterservice' || stateName == 'techuser') {
            this.setState({ [stateName]: value.id });
        } else if(stateName === 'context'){
            this.setState({ [stateName]: value.id, contextSelected: value });
        }else {
            this.setState({ [stateName]: value });
        }
        this.setState({ loading: false });
    };

    Reload = () => {
        const reload = localStorage.getItem('reload');
        if (reload == "true") {
            localStorage.setItem('reload', false);
        }
        return null;
    }

    onChangePaginate = (e, page) => {
        this.setState({ page: page });
        this.getCalleds('', page);
    }

    exportPdf() {
        this.getCalledsToPdf();
    }

    setPagination(count, page) {

        let totalPage = Math.ceil(count / 10);
        this.setState({ totalPages: totalPage })
        if (count > 0) {
            if (page == 1) {
                if (count < 10) {
                    this.setState({ pageStart: 1, pageEnd: count });
                } else {
                    this.setState({ pageStart: 1, pageEnd: 10 });
                }
            } else {
                let pageStart = ((page - 1) * 10) + 1;
                if (count >= page * 10) {
                    this.setState({ pageStart: pageStart, pageEnd: page * 10 });
                } else {
                    this.setState({ pageStart: pageStart, pageEnd: count });
                }
            }
        } else {
            this.setState({ totalPages: 0, page: 1, count: 0 });
        }

    }

    onFieldChange(event) {
        // Clears the previously set timer.
        clearTimeout(this.typingTimeout);

        // Reset the timer, to make the http call after 475MS (this.callSearch is a method which will call the search API. Don't forget to bind it in constructor.)
        this.typingTimeout = setTimeout(this.callSearch, 475);


        this.setState({ [event.target.name]: event.target.value, page: 1 });

    }

    callSearch() {
        this.getCalleds('', this.state.page);
    }

    async getAllUsers() {
        let data = [];
        await Api.get('/users')
        .then(result => {
            if(result && result.data && result.data.success){
                this.setState({ usersOptions: result.data.data })
            }
        })
        .catch(err => {
            data = err;
        });
    };

    deleteItem(data, resolve) {
        const intl = this.props.intl;
        let abl = this.context.can('Delete', 'Called');
        if (abl == false) {
            this.setState({ loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: this.props.intl.formatMessage({id:"operation.notAllowed"}) });
            resolve(); return false;
        }
        Api.delete(`/calledreports/${data.id}`)

            .then(result => {
                if (result.data.success) {
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: intl.formatMessage({id:"called.list.notification.delete.success"}),
                        resultList: []
                    });

                    this.getCalleds();

                }
                else {
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(result);
                }
                resolve();
            })
            .catch(err => {
                resolve();
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(err);
            });


    };

    async componentDidMount() {
        this.setState({ loading: true })
        //await this.getAllUsers(); // CRIME
        this.setState({ loading: false })
    }

    componentWillReceiveProps(nextProps) {

        //if (nextProps.location.search.indexOf("assignedcalleds") > -1) {
        if (nextProps.location.state === "calledReports") {
            // do stuffs
            this.getCalleds(this.props.location.state);
        }
        else if (nextProps.location.state === "assignedcalleds") {
            this.getCalleds(nextProps.location.state);
        }
        else if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }
    async getFile() {

        const intl = this.props.intl;

        this.setState({ loading: true });

        let url = `report/audit/excell?dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&user=${this.state.user}&operation=${this.state.operation}&context=${this.state.context}&pkkey=${this.state.pkkey}&searchOption=${this.state.searchOption}`;
            

        ApiReports.get(url, {
        responseType: 'blob'
        })
        .then((response) => {

            //   let url = window.URL.createObjectURL(new Blob([response.data]));
            //saveAs(url, 'called_list.csv');

            fileDownload(response.data, `auditoria_${moment().format('DD/MM/YYYY HH:mm:ss')}.zip`);

            this.setState({ loading: false });
        })
        .catch((error) => {
            this.setState({ loading: false });
        });

       
    }

    getMessageBadUseFlag(message) {
        let writing = ""
        if (message === true) {
            writing = this.props.intl.formatMessage({id:"yes"})
        }
        else if (message === false) {
            writing = this.props.intl.formatMessage({id:"no"})
        }
        else {
            writing = this.props.intl.formatMessage({id:"undefined"})
        }

        return writing
    }




    async getCalleds(filter = '', page = 1) {
        this.setState({ loading: true });

        var dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
        var dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')

        if (dateStart > dateEnd) {
            this.setState({
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: this.props.intl.formatMessage({id:"report.audit.initial.date.higher.than.end.date"}),
                loading: false
            });
        } else {

            let url = `report/audit/paginate?page=${page}`;
            
            if (this.state.searchValue != '') {
                url += `&search=${this.state.searchValue}`
            }

            if (filter != '') {
                url = url + '&filter=' + filter;
            }
            url += `&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&user=${this.state.user}&operation=${this.state.operation}&context=${this.state.context}&pkkey=${this.state.pkkey}&searchOption=${this.state.searchOption}`;

            this.setState({ showGrid: true });

            var writing = ""

            ApiReports.get(url)
                .then(result => {
                    PapiroConsole.log("result aqui relatório")
                    PapiroConsole.log(result)
                    if (result.data.success) {
                        
                        let count = result.data.count;
                        this.setState({ count: count });
                        this.setPagination(count, page);

                        var formatedList = result.data.data.map(item => {
                            return {
                                ...item,
                                UserName: item.user_selected ? item.user_selected : '',
                                DateHour: item.created_at,
                                pkvalue : item.pk_value
                            }
                        });
                        
                        this.setState({ calledsList: formatedList, loading: false });
                    }
                    else {
                        this.setState({
                            loading: false, openNotification: true, notificationVariant: 'error',
                            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                        });
                        Api.kickoff(result);
                    }
                })
                .catch(err => {

                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(err);

                });

        }




    };

   /* async getCalleds(filter = '', page = 1) {
        this.setState({ loading: true });

        var dateStart = moment(this.state.dateStart).format('YYYY-MM-DD')
        var dateEnd = moment(this.state.dateEnd).format('YYYY-MM-DD')

        if (dateStart > dateEnd) {
            this.setState({
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: this.props.intl.formatMessage({id:"report.audit.initial.date.higher.than.end.date"}),
                loading: false
            });
        } else {

            let url = `/audit/paginate?page=${page}`;
            
            if (this.state.searchValue != '') {
                url += `&search=${this.state.searchValue}`
            }

            if (filter != '') {
                url = url + '&filter=' + filter;
            }

            url += `&dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&user=${this.state.user}&operation=${this.state.operation}&context=${this.state.context}&pkkey=${this.state.pkkey}`;

            this.setState({ showGrid: true });

            var writing = ""

            Api.get(url)
                .then(result => {
                    if (result.data.success) {
                        
                        let count = result.data.count;
                        this.setState({ count: count });
                        this.setPagination(count, page);

                        var formatedList = result.data.data.map(item => {
                            return {
                                ...item,
                                UserName: item.user ? item.user.name : "",
                                DateHour: moment(item.createdat).format("DD/MM/YYYY HH:mm:ss")
                            }
                        });
                        
                        this.setState({ calledsList: formatedList, loading: false });
                    }
                    else {
                        this.setState({
                            loading: false, openNotification: true, notificationVariant: 'error',
                            notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                        });
                        Api.kickoff(result);
                    }
                })
                .catch(err => {

                    this.setState({
                        loading: false, openNotification: true, notificationVariant: "error",
                        notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(err);

                });

        }




    };*/

    getMessageExcellBadUseFlag(message, status) {
        const intl = this.props.intl;
        let writing = ""
        if (status === intl.formatMessage({id:"finished"})) {
            if (message === true) {
                writing = this.props.intl.formatMessage({id:"yes"})
            }
            else if (message === false) {
                writing = this.props.intl.formatMessage({id:"no"})
            }
        }
        else {
            writing = this.props.intl.formatMessage({id:"undefined"})

        }


        return writing
    }


    getCalledsToPdf(filter = '') {

        this.setState({
            openNotification: true,
            notificationVariant: 'info',
            notificationMessage: this.props.intl.formatMessage({id:"routine.in.maintenance"})
        })

        /*this.setState({ loading: true });
        let url = `/calledreports/getpdforexcell`;
        if (this.state.searchValue != '') {
            url += `?search=${this.state.searchValue}`
        }

        url += `?dateStart=${moment(this.state.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.state.dateEnd).format('YYYY-MM-DD')}&org=${this.state.OrganizationFilter}&loc=${this.state.location}&dep=${this.state.departament}&slasolution=${this.state.slafiltersolution}&slaservice=${this.state.slafilterservice}&techuser=${this.state.techuser}`;



        Api.get(url)
            .then(async (result) => {
                if (result.data.success) {




                    await this.setState({ showHeader: true })
                    const formattedList = result.data.data.map(item => {
                        let lastAction = "";
                        let tecteams = "";

                        if (item.organizationcategory.techinicalteamorganizationcategories != null && item.organizationcategory.techinicalteamorganizationcategories.length > 0)
                            item.organizationcategory.techinicalteamorganizationcategories.forEach((element, index) => {
                                if (element.organizationid === item.organization.id && item.organizationcategory.id === element.organizationcategoryid) {
                                    tecteams += element.techinicalteam.name + ' ,'
                                }

                            });




                        tecteams = tecteams.slice(0, -1);

                        if (item.accompaniments.length > 0) {
                            lastAction = item.accompaniments[item.accompaniments.length - 1].actiontime;
                        } else {
                            lastAction = item.openingdate;
                        }
                        let writing = ""
                        writing = this.getMessageBadUseFlag(item.baduse)

                        return {
                            ...item,
                            baduse: writing,
                            typeName: item.type.name,
                            openingdate: moment(item.openingdate).format('DD/MM/YYYY HH:mm'),
                            originRequestName: item.originrequest.name,
                            statusName: item.status.name,
                            OrganizationCategory: item.organizationcategory ? item.organizationcategory.name : "",
                            Organization: item.organization.name,
                            slaAtendimento: item.slaservice != null ? item.slaservice : getSlaAtendimento(item, this.props.userSession) + "%",
                            slaSolucao: item.slasolution != null ? item.slasolution : getSlaSolucao(item, this.props.userSession) + "%",
                            assignedUser: item.assigneduser ? item.assigneduser.name : "",
                            requestingUser: item.requestinguser ? item.requestinguser.name : "",
                            lastAction: moment(lastAction).format('DD/MM/YYYY'),
                            lastActionHour: moment(lastAction).format('HH:mm'),
                            tecnteams: tecteams,
                            userdepartament: item.organizationdepartament ? item.organizationdepartament.name : "",
                            location: item.address.name
                        }
                    });

                    const intl = this.props.intl;
                    const doc = new jsPDF("p", "mm", "a4");
                    const header = document.getElementById('header-pdf');


                    let headerCanvas = await html2canvas(header);

                    const headerImg = headerCanvas.toDataURL('image/png');
                    doc.addImage(headerImg, 'PNG', 0, 0);



                    doc.autoTable({
                        body: formattedList,
                        margin: { top: 30, left: 1 },

                        bodyStyles: { valign: 'middle' },
                        styles: { overflow: 'linebreak', columnWidth: 'wrap', fontSize: 7, halign: 'center' },
                        theme: 'striped',
                        columns: [
                            { header: intl.formatMessage({id:"id"}), dataKey: 'id' },
                            { header: intl.formatMessage({ id: "category" }), dataKey: 'OrganizationCategory' },
                            { header: intl.formatMessage({ id: "last.move.date" }), dataKey: 'lastAction' },
                            { header: intl.formatMessage({ id: "last.move.hour" }), dataKey: 'lastActionHour' },
                            { header: intl.formatMessage({ id: "open.date" }), dataKey: 'openingdate' },
                            { header: intl.formatMessage({ id: "title" }), dataKey: 'title' },
                            { header: intl.formatMessage({ id: "assigned" }), dataKey: 'assignedUser' },
                            { header: intl.formatMessage({id:"menu.statuses"}), dataKey: 'statusName' },
                            { header: intl.formatMessage({ id: "organization" }), dataKey: 'Organization' },
                            { header: intl.formatMessage({ id: "departament" }), dataKey: 'userdepartament' },
                            { header: intl.formatMessage({ id: "menu.techinicalteams" }), dataKey: 'tecnteams' },
                            { header: intl.formatMessage({ id: "location" }), dataKey: 'location' },
                            { header: intl.formatMessage({ id: "wasbaduse" }), dataKey: 'baduse' }
                        ],
                        columnStyles: {
                            id: {
                                columnWidth: 10,
                            },
                            OrganizationCategory: {
                                columnWidth: 16,
                            },
                            lastAction: {
                                columnWidth: 17,
                            },
                            lastActionHour: {
                                columnWidth: 17,
                            },
                            openingdate: {
                                columnWidth: 16,
                            },
                            title: {
                                columnWidth: 16,
                            },
                            assignedUser: {
                                columnWidth: 19,
                            },
                            statusName: {
                                columnWidth: 16,
                            },
                            Organization: {
                                columnWidth: 18,
                            },
                            userdepartament: {
                                columnWidth: 20,
                            },
                            tecnteams: {
                                columnWidth: 16,
                            },
                            location: {
                                columnWidth: 18,
                            },
                            baduse: {
                                columnWidth: 10,
                            },
                        },
                    })

                    doc.save("relatorio-chamados.pdf");
                    this.setState({ loading: false, showHeader: false });
                }
                else {

                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {





                this.setState({
                    loading: false, openNotification: true, notificationVariant: "error",
                    notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({id:"process.error"})
                });
                Api.kickoff(err);

            });*/
    };

    handleSubmit = async e => {
        e.preventDefault();
        this.setState({ loading: true });

        await this.getCalleds(this.props.location.state);


    };

    handleInputChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        
        this.setState({ [name]: value });
    };

    closeNotification() {
        this.setState({ openNotification: false });
    }

    render() {
        const { classes, headerMenu, actionsHeaderMenu } = this.props;

        const intl = this.props.intl;

        if (Api == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/unavailable`
        }

        const tableList = () => {
            return (
                <>
                    <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                        <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>{intl.formatMessage({id:"filters"})}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container style={{ marginBottom: 20 }} spacing={2} >
                                    <Grid item xs={12} sm={2} md={2}  >
                                        <SimpleDatePicker label={intl.formatMessage({ id: "start.date" })} name='dateStart' stateName='dateStart' changeDate={this.changeValues} currentDate={this.state.dateStart} required />
                                    </Grid>

                                    <Grid item xs={12} sm={2} md={2} >
                                        <SimpleDatePicker label={intl.formatMessage({ id: "end.date" })} name='dateEnd' stateName='dateEnd' changeDate={this.changeValues} currentDate={this.state.dateEnd} required />
                                    </Grid>

                                    <Grid item xs={12} sm={2} md={2} >
                                        <SimpleAutoComplete label="Operação" options={this.state.operationOptions} stateName='operation' changeSelect={this.changeValues} />
                                    </Grid>

                                    <Grid item xs={12} sm={2} md={2} >
                                        {/*<SimpleAutoComplete label={intl.formatMessage({id:"user"})} options={this.state.usersOptions} stateName='user' changeSelect={this.changeValues} />*/}
										<SimpleAutoCompleteSearch label={intl.formatMessage({ id: "keypress.search.instruction" })+" "+intl.formatMessage({ id: "user" })} options={this.state.usersOptions} 
											stateName='user' changeSelect={this.changeValues}
											urlapi={Api.apiurlbase()+'api/users/search/user'} />

                                    </Grid>

                                    <Grid item xs={12} sm={2} md={2}  >
                                        <SimpleAutoComplete
                                            name="context"
                                            stateName="context"
                                            label={intl.formatMessage({id:"context"})}
                                            fullWidth
                                            selected={this.state.contextSelected}
                                            variant="outlined"
                                            options={this.state.contextOptions}
                                            changeSelect={this.changeValues}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={1} md={1}  >
                                        <SimpleSelect
                                            name="searchOption"
                                            stateName="searchOption"
                                            label={intl.formatMessage({id:"search.mode"})}
                                            fullWidth
                                            selected={this.state.searchOption}
                                            variant="outlined"
                                            options={this.state.searchOptions}
                                            changeSelect={this.changeValues}
                                            inputProps={{ maxLength: 255 }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={1} md={1}  >
                                        <TextField
                                            name="pkkey"
                                            autoComplete="fname"
                                            label={intl.formatMessage({id:"primary.key"})}
                                            type="number"
                                            fullWidth
                                            value={this.state.pkkey}
                                            variant="outlined"
                                            onChange={this.handleInputChange}
                                            inputProps={{ maxLength: 255 }}
                                        />
                                    </Grid>

                                </Grid>
                            </AccordionDetails>
                        </Accordion>


                        <Grid container style={{ marginBottom: 10 }}>
                            <Grid item xs={12} sm={2} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                                <Button
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    color='secondary'

                                >
                                    {<FormattedMessage id="filter" />}
                                </Button>
                            </Grid>

                            {this.state.showGrid && <Grid item xs={12} sm={5} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='secondary'
                                    onClick={this.getFile}

                                >
                                    {<FormattedMessage id="export.plan" />}
                                </Button>
                            </Grid>}

                            {this.state.showGrid && 1== 2 && <Grid item xs={12} sm={5} md={2} style={{ marginRight: 10, marginTop: 10 }}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='secondary'
                                    onClick={this.getCalledsToPdf}

                                >
                                    {<FormattedMessage id="dash.pdf" />}
                                </Button>
                            </Grid>}
                        </Grid>
                    </form>

                    {

                        this.state.showGrid && <>
                            <MyMaterialTable
                                title=""
                                columns={[
                                { title: intl.formatMessage({id:"id"}), field: 'id' },
                                { title: 'Contexto', field: 'context' },
                                { title: intl.formatMessage({id:"operation"}), field: 'operation' },
                                { title: intl.formatMessage({id:"user"}), field: 'UserName' }, 
                                { title: intl.formatMessage({id:"primary.key"}), field: 'pkvalue' },
                                { title: intl.formatMessage({id:"user.error.report.date.time"}), field: "DateHour" }]}
                                data={this.state.calledsList}

                            />

                            <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                                <Grid item xs={12} md={3}>
                                    <Pagination count={this.state.totalPages} page={this.state.page} onChange={this.onChangePaginate} />
                                </Grid>
                                <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                                    {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                                </Grid>
                            </Grid>
                        </>
                    }



                </>
            );
        }

        return (
            <div className={classes.root} style={{ paddingLeft: 0, marginLeft: 0 }}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    }, classes.actionsContent, {
                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                    })}
                >
                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft:0, marginLeft:0}}>
                        <CssBaseline />
                        {this.state.showHeader &&
                            <Grid container>
                                <Grid id="header-pdf" item lg={4} sm={12} xl={this.state.grid} xs={12} style={{ padding: '2px' }} style={{ minWidth: 600 }}>
                                    <Card  >

                                        <CardContent>

                                            <img src={LogoMarca} style={{ width: "70px" }} /> <span style={{ fontSize: 18 }}> Auditoria </span>
                                            <Divider />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>}
                        <Grid container spacing={6}>
                            <Grid item xs={12} sm={12}>

                                <Card >
                                    <CardContent className="background-title-search">
                                        <Typography variant="h5">
                                            Auditoria
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                        </Grid>
                        <Can I="List" a="Called">
                            {tableList()}
                        </Can>


                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color="secondary" />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>
                </main>
                <Footer />
            </div>
        );
    }
}

AuditReport.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
    actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(calledsActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(AuditReport)));
AuditReport.contextType = AbilityContext;