import React, { Component } from "react";
import clsx from 'clsx';
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { Styles } from "../../styles/material-styles";
import { Typography, Container, Grid, CssBaseline, Button, ButtonGroup, CircularProgress } from '@mui/material';
import chatIcon from './images/pontos-de-comentario.png';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LogoSmall2 from '../logo/logo-small-2';
import IconButton from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import Tooltip from '@mui/material/Tooltip';
import { Can } from '../../config/ability-context';
import { AbilityContext } from '../../config/ability-context';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import QRCodeReader from "../qrcode-reader/qrcode-reader";
import TransitionsModal  from '../modal/modal';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import CustomizedSnackbars from "../../components/material-snackbars";
import { Overlay } from '../../styles/global';

import { AvailabilityConfigChat } from '../../components/availability-chat';
import { ClientChat } from '../../components/client-chat';
import TechnicianChat from '../../components/technician-chat';
import { ChatListButton } from "../chatListButton";

import * as componentActions from "../../redux/actions/users";
import { bindActionCreators } from "redux";
import { getOrganizations } from "../../services/called.service";
import { isThisSecond } from "date-fns";
import Api from "../../services/api";
import { PapiroConsole } from '../../utils/papiroConsole';
import ReactGA from 'react-ga';
import DialogHelp from '../dialog-help';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import QuickCalledDialog from "../quick-called-dialog";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { GetChatList as GetChatListService, getUserStatusList, getEnabledUserById, getEnabledUsers } from '../../services/2talk/omnichannel';

var isLoggedIn=true;

const footerStyle = {
    backgroundColor: "#f1f3f4",
    fontSize: "20px",
    color: "white",
    borderTop: "1px solid #E7E7E7",
    padding: "10px",
    position: "fixed",
    textAlign:"center",
    left: "0",
    bottom: "0",
    height: "25px",
    width: "100%",
    zIndex: 0,
  };

  const flex = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }
  const footerStyleMobile = {
    backgroundColor: "#f1f3f4",
    fontSize: "20px",
    color: "white",
    borderTop: "1px solid #E7E7E7",
    padding: "10px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "25px",
    width: "100%",
    zIndex: 0,
    
  };

  const phantomStyle = {
    display: "block",
    padding: "50px",
    height: "60px",
    width: "100%",
    marginTop:"200px",

  };

  const styles = {
    container: isRowBased => ({
      top:'300%' ,
      backgroundColor: "#f1f3f4",
      fontSize: "20px",
      color: "white",
      borderTop: "1px solid #E7E7E7",
      textAlign: "center",
      padding: "10px",
      position: "absolute",
      left: "0",
      bottom: "0",
      height: "40px",
      width: "100%",
      zIndex: 0,      
      flexDirection: isRowBased ? 'row' : 'column',
    })
  };

  function FooterContent({ children }) {
    const matches = useMediaQuery('(max-width:530px)');

    if(isLoggedIn){
      return (
        <div>
                  <div>{children}</div>
        </div>
      );
    }
    else{
     return (<div></div>);
    }
    
  }
  

class Footer extends Component {

    constructor(props) {
      super(props);
      if(props.userSession == null){
        isLoggedIn=false;
      }

      this.is2TalkAvaliable = false;
      if(this.props.userSession && this.props.userSession.user && this.props.userSession.user.productsavailables){
        var productsavailables = this.props.userSession.user.productsavailables;
        productsavailables.forEach(element => {
          if (element.id == 2) {
            this.is2TalkAvaliable = true
          }
        });
      }
      
      const falQuickCalledIconButtonPosition = (props.fabPosition && props.fabPosition === 'vertical') ? {
        bottom: 35,
        right: 10
      } : {
        top: -35,
        left: '94%',
        right: 0,
      }

      this.quickCalledIconButton = {
        position: 'absolute',
        zIndex: 1,
        ...falQuickCalledIconButtonPosition,
        margin: '0 auto'
      };

      const falHelpPosition = (props.fabPosition && props.fabPosition === 'vertical') ? {
        bottom: 108,
        right: 10
      } : {
        top: -35,
        left: '85%',
        right: 0,
      }
      
      this.fabHelpButton = {
        position: 'absolute',
        zIndex: 1,
        ...falHelpPosition,
        margin: '0 auto'
      };

      const falButtonPosition = (props.fabPosition && props.fabPosition === 'vertical') ? {
        bottom: 181,
        right: 10
      } : {
        top: -35,
        left: '76%',
        right: 0,
      }

      this.fabButton = {
        position: 'absolute',
        zIndex: 1,
        ...falButtonPosition,
        margin: '0 auto'
      };

      const falChatListButtonStylePosition = (props.fabPosition && props.fabPosition === 'vertical') ? {
        bottom: 254,
        right: 10
      } : {
        top: -35,
        left: '67%',
        right: 0,
      }

      this.ChatListButtonStyle = {
        position: 'absolute',
        zIndex: 1,
        ...falChatListButtonStylePosition,
        margin: '0 auto'
      };

      const falChatConfigStylePosition = (props.fabPosition && props.fabPosition === 'vertical') ? {
        bottom: 326,
        right: 10
      } : {
        top: -35,
        left: '58%',
        right: 0,
      }

      this.chatConfigStyle = {
        position: 'absolute',
        zIndex: 1,
        ...falChatConfigStylePosition,
        margin: '0 auto'
      };

      const falToTalkChatStylePosition = (props.fabPosition && props.fabPosition === 'vertical') ? {
        bottom: 400,
        right: 10
      } : {
        top: -35,
        left: '67%',
        right: 0,
      }

      this.ToTalkChatStyle = {
        position: 'absolute',
        zIndex: 1,
        ...falToTalkChatStylePosition,
        margin: '0 auto'
      };
      
      this.state = {
        
        chatHubConnection: null,
        modalopen:false,
        openNotification: false,
        notificationVariant: "error",
        notificationMessage: "",
        openAvailabilityConfigChat: false,
        openQuickCalledModal : false,
        matches: window.matchMedia("(max-width: 599px)").matches,
        showDialog: false,
        showChatConfig : false,
        showClientChat: false,
        showTechnicianChat: false,
        showChatButton: false,
        showHelp: false,
        organizations: this.props.organizations,
        orgList: [],
        newAttendenceAccept: false,
        appName: Api != null ? Api.getAppName() : "CMTECH - Mexx 2DO",
        footerText: Api != null ? Api.getFooterText() : "CMTECH - Mexx 2DO",
        showfooterusetermslink: Api != null ? Api.getShowFooterUseTermsLink() : true,
        valueNewAttendence: 0.00,
        openDialogNewAttendence: false,
        choosenOrganization : null,
        showQuickCalled : this.props.userSession.user.showquickcalled,
        is2TalkAvaliable : this.is2TalkAvaliable
      };
      this.chatHub = this.props.userSession.user.chatHub;

      this.ApplyWebchat = this.ApplyWebchat.bind(this);
      this.QRCode = this.QRCode.bind(this);
      this.openModal = this.openModal.bind(this);
      this.closeModal = this.closeModal.bind(this);
      this.openAvailabilityConfigChatModal = this.openAvailabilityConfigChatModal.bind(this);
      this.closeAvailabilityConfigChatModal = this.closeAvailabilityConfigChatModal.bind(this);

      this.openOrCloseQuickCalledModal = this.openOrCloseQuickCalledModal.bind(this);
      this.createQuickCalled = this.createQuickCalled.bind(this);
      this.validationsOnCreateQuickCalled = this.validationsOnCreateQuickCalled.bind(this)
      
      this.showChatConfigMount = this.showChatConfigMount.bind(this);
      this.closeTechnicianChat = this.closeTechnicianChat.bind(this);
      this.toggleTechnicianChat = this.toggleTechnicianChat.bind(this);
      
      this.closeClientChat = this.closeClientChat.bind(this);
      this.toggleClientChat = this.toggleClientChat.bind(this);
      this.closeNotification = this.closeNotification.bind(this);
      this.redirectTo2talkAttendance = this.redirectTo2talkAttendance.bind(this);
    
    
    }

    closeTechnicianChat() {
      this.setState({ showTechnicianChat: false })
    }
    toggleTechnicianChat() {
      this.setState({ showTechnicianChat: !this.state.showTechnicianChat })
    }

    closeNotification() {
      this.setState({ openNotification: false });
    }

    closeClientChat(notify = false) {
      const intl = this.props.intl;
      this.setState({ showClientChat: false })
      if (notify == true) {
        this.setState({
          openNotification: true, 
          notificationVariant: "success",
          notificationMessage: this.props.intl.formatMessage({id:"footer.chat.attendance.finished"})
        });
      }
    }
    toggleClientChat() {
      this.setState({ showClientChat: !this.state.showClientChat })
    }

    toggleHelp = () => {
      this.setState({ showHelp: !this.state.showHelp })
    }
  
    componentWillReceiveProps(nextProps){
        if(nextProps.location.state === "reload"){
            this.componentDidMount();
        }
    } 
    
    async componentDidMount() {
      var ga_code = process.env.REACT_APP_GACODE;
      if (ga_code == null || ga_code == "") {
        ga_code="UA-198557600-1";
      }    

      ReactGA.initialize(ga_code, {
        debug: false,
        titleCase: false,
        gaOptions: {
          userId: this.props.userSession && this.props.userSession.user ? this.props.userSession.user.id : 0,
        }
      });
      ReactGA.pageview(window.location.pathname + window.location.search);

      document.title = this.state.appName;

      PapiroConsole.log("Google Analytics Initialized");
      const handler = e => this.setState({matches: e.matches});
      window.matchMedia("(max-width: 599px)").addListener(handler);
      this.showChatConfigMount();
     //console.log("DUAS VEZES?");
    }
  ApplyWebchat(REACT_APP_URL,_2talk_options){
        //alert(REACT_APP_URL);
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            // dev code
            REACT_APP_URL='https://dev.webchat.mexx.ai:3000/';
            //console.error('ERRO:'+JSON.stringify(_2talk_options));
        } else {
            // production code
        }
        //alert(REACT_APP_URL);
        var _2talk_root_div = document.getElementById('2talk');
        if(_2talk_root_div == null){
            _2talk_root_div = document.createElement('div');
            _2talk_root_div.setAttribute('id','2talk');
            document.body.appendChild(_2talk_root_div);

        }

        if(_2talk_root_div != null){

        
          //alert(window._2talk_customer_name);

          var _2talk_link = document.getElementById('2talk_css');
          if(_2talk_link != null){
            document.head.removeChild(_2talk_link);
          }
          //alert(2);
          var _2talk_root_script = document.getElementById('2talk_script');
          if(_2talk_root_script != null){
            document.head.removeChild(_2talk_root_script);
          }
          //alert(3);

        }

        window._2talk_chatclientkey=_2talk_options._2talk_chatclientkey;
        //alert(window._2talk_chatclientkey);  
        //window._2talk_customer_mail=_2talk_options._2talk_customer_mail;
        //window._2talk_customer_phone=_2talk_options._2talk_customer_phone;
        //window._2talk_customer_name=_2talk_options._2talk_customer_name;
        //window._2talk_external_application_id=_2talk_options._2talk_external_application_id;    	

        var _2talk_root_script = document.createElement('script');
        _2talk_root_script.setAttribute('src',REACT_APP_URL+'build/static/js/main.js');
        _2talk_root_script.setAttribute('defer','defer');
        _2talk_root_script.setAttribute('id','2talk_script');
        document.head.appendChild(_2talk_root_script);

        var _2talk_link = document.createElement('link');
        _2talk_link.setAttribute('href',REACT_APP_URL+'build/static/css/main.css');
        _2talk_link.setAttribute('rel','stylesheet');
        _2talk_link.setAttribute('id','2talk_css');
        document.head.appendChild(_2talk_link);


    	
  }
    async showChatConfigMount() {
      try {
        let orgData = []
        const { userSession } = this.props;
        PapiroConsole.log(userSession)
        PapiroConsole.log("userSession aqui")
        PapiroConsole.log(this.state.organizations)
        if(userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
          orgData = userSession.user.userorganizations
        }
        //alert(userSession.user.defaultwebchatid);
        //alert(userSession.user.defaultwebchaturl);
        //alert(userSession.user.defaultwebchattoken);
        if(userSession.user.defaultwebchatid && userSession.user.defaultwebchaturl && userSession.user.defaultwebchattoken){
          
          userSession.user.onlyclient=true;
          if(userSession.user.onlyclient){

            if(document.getElementById("script_webchat_2talk") == null){
                //alert('SCRIPT FIRST LOAD');
                var txtscript = 'var _2talk_chatclientkey = "'+userSession.user.defaultwebchattoken+'";';          
                let scriptvar = document.createElement("script");
                scriptvar.id="script_webchat_2talk";
                scriptvar.async = false;
                scriptvar.append(txtscript);
                document.body.appendChild(scriptvar);
                  
                /*let script = document.createElement("script");
                script.src = userSession.user.defaultwebchaturl;
                script.id="script_webchat_url_2talk";
                script.async = true;
                script.onload = () => {
                */
                  // Execute library related code after it has been loaded

                    localStorage.setItem('_2talk_chatclientkey',userSession.user.defaultwebchattoken);
                
                      if( userSession.user.defaultwebchattoken){
                        var _2talk_options = { _2talk_mail: null, _2talk_customer_name: null,_2talk_customer_mail: null,
                          _2talk_customer_phone: null,_2talk_external_application_id: null  };
                  
                          _2talk_options._2talk_chatclientkey= userSession.user.defaultwebchattoken;
                
                                this.ApplyWebchat(userSession.user.defaultwebchaturl.replace('2talk-widget.js',''),_2talk_options);
                        }
                

                /*
                }
                document.body.appendChild(script);
                */
             }
             else{
              //alert('SCRIPT ALREADY LOADED!');

             }
          }

        }

        if(orgData == null || (orgData && orgData.length == 0)) {
          let response = await getOrganizations();
          if(response && response.data)
            orgData = response.data
        }
        orgData = orgData.filter(c => c.active == true);

        PapiroConsole.log(orgData)
        PapiroConsole.log("orgData aqui")
        
        this.setState({ orgList: orgData && orgData.length > 1 ? orgData.sort((a, b) => a.name.localeCompare(b.name)) : orgData, showDialog : true });

        for (let item of orgData) {
          if (item.organizationsettings) {
            if (item.organizationsettings.allowattendanceviachat) {
              this.setState({ showChatButton: true });
              this.setState({ showChatConfig: this.isClient ? false : true });
              return
            }
          }
        }

        

        
        this.setState({ showChatConfig: false });
        this.setState({ showChatConfig: false });
      } catch(err) {
        PapiroConsole.log(err);
        this.setState({ showChatConfig: false });
        this.setState({ showChatConfig: false });
      }
    }
  
    QRCode(){
      this.openModal();
    }

    openAvailabilityConfigChatModal() {
      this.setState({ openAvailabilityConfigChat: true })
    }
    closeAvailabilityConfigChatModal() {
      this.setState({ openAvailabilityConfigChat: false })
    }

    openOrCloseQuickCalledModal(){
      
      this.setState({ openQuickCalledModal: !this.state.openQuickCalledModal })


    }

    validationsOnCreateQuickCalled(organization){
      const intl = this.props.intl;
      if(!organization){
        
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: intl.formatMessage({ id: "validation.organization" })
        });

        return false
        
      }

      return true
    }

    randomIntFromInterval(min, max) { // min and max included 
      return Math.floor(Math.random() * (max - min + 1) + min);
    }

    handleSubmitAccept = async (e) => {
      await this.setState({ newAttendenceAccept: true });
      this.createQuickCalled(this.state.choosenOrganization);
    }

    openDialogNewAttendence = async () => {
      this.setState({ openDialogNewAttendence: !this.state.openDialogNewAttendence });
    }

    async createQuickCalled(organization){

      this.setState({loading: true, choosenOrganization : organization});
      var SecurityPassword = this.randomIntFromInterval(1000, 99999)
      let validate = this.validationsOnCreateQuickCalled(organization)

      
      if(validate){
        let data = new FormData();
        
        let config = {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        };
        
        data.append("SecurityPassword", SecurityPassword.toString());
        data.append("organizationid", organization.id);
        if(this.state.newAttendenceAccept){
          data.append("acceptNewAttendence", true);
          this.setState({ newAttendenceAccept: false });
        }
        if(window && window.location &&  window.location.href){
          const currentURL = window.location.href;
          data.append('absoluteOrigin', currentURL)
        }
        await Api.post("/calleds/create/quickcalled", data, config)
         .then(result => {
          if (result && result.data && result.data.success) {

            this.openOrCloseQuickCalledModal()

            if (result.data.data) {

              let calledId = result.data.data.id

                const intl = this.props.intl;
                  this.setState({
                    loading: false,
                    openDialogNewAttendence: false,
                    openNotification: true,
                    notificationVariant: "success",
                    notificationMessage: intl.formatMessage({id:"called.list.notification.called.taken"}),
                  }, () => this.props.history.push(`/${this.props.match.params.lang}/called-accompaniment/${calledId}`));
             
            }
            else{
                this.setState({loading: false});
            }


          } 
        })
        .catch(err => {

          PapiroConsole.log(err)
          PapiroConsole.log("erro aqui console")
          if(err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0]){
            if(!isNaN(err.response.data.errors[0])){
              this.openOrCloseQuickCalledModal()
              
                this.setState({
                  loading: false,
                  openDialogNewAttendence: true,
                  valueNewAttendence: err.response.data.errors[0],
                });
  
            }else{
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage:
                  err.response && err.response.data && err.response.data.errors
                    ? err.response.data.errors[0]
                    : intl.formatMessage({ id: "process.error" }),
              });
            }
            
          }

          else if (err.response && err.response.data && err.response.data.errors) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: intl.formatMessage({ id: "process.error" })
            });
          }
          
        });

      }
      
  }


    
  
    openModal(){
      this.setState({  modalopen:true });
  
    }
    async closeModal(){
        this.setState({  modalopen:false });
    }

    async redirectTo2talkAttendance(){
      this.setState({ loading: true})
      let result = await getEnabledUserById(this.props.userSession && this.props.userSession.user ? this.props.userSession.user.id : null)
      if (result && result.success) {
    
        if(this.props.match && this.props.match && this.props.match.params.lang ){
          PapiroConsole.log("entrei aqui 2 ")
          this.props.history.push(`/${this.props.match.params.lang}/omnichannel`)
        }

        this.setState({ loading: false})
  
      }
      else {
        PapiroConsole.log(result)
        PapiroConsole.log("result")
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.errors && result.errors[0] ? result.errors[0]: intl.formatMessage({ id: "process.error" })
        });
  
      }
   
      
     
      
      
    }
  
    render() {
        const { classes, userSession } = this.props;
        PapiroConsole.log(userSession)
        PapiroConsole.log("userSession footer")
        const intl = this.props.intl;
        this.isClient = true;
       
        var link = `/${this.props.match.params.lang}/called-registration`;
        if(userSession == null){
           isLoggedIn=false;
        }
        else{
          userSession.user.userrole.map((item, key) => {
                  if(item.role.roletype !=3){
                      this.isClient = false;
                  }
              }
          );
        }
        if(this.isClient){
            link = `/${this.props.match.params.lang}/called-registration-client`;
        }

        var version = process.env.REACT_APP_VERSION;
        if (version == null || version == "") {
            version="1.0.0";
        }  
		//console.error(userSession.user.startpage);  
        return (
            <FooterContent >
              {this.state.matches && 
              <div style={footerStyleMobile}>
              <Container component='main' maxWidth='xl'>
                    
                  <CssBaseline />
                  <div className={classes.paper} style={{ marginTop: 0, paddingTop: 0, height: "25px" }}>
                  <Grid container xs style={{ paddingTop: 0, ...flex }}>
                      <Grid item style={{ paddingTop: 0, ...flex }}>
                          <Typography variant="h6" className={classes.title}>
                              <div className={classes.title} style={{ paddingLeft: '30px', color:'#3e3c3c', fontSize:'11px' }}>
                                  {this.state.footerText} <FormattedMessage id="version"/> {version} - <FormattedMessage id="copyright"/>
                              </div>
                          </Typography>
                          <ButtonGroup>
                          {((userSession != null && userSession.user != null && userSession.user.isexternal != null && userSession.user.isexternal && userSession.user.canexternaluserclientcreatecalled != null && userSession.user.canexternaluserclientcreatecalled) || (userSession != null && userSession.user.isexternal != null && !userSession.user.isexternal) && (userSession.user.originrequestid != 8 && userSession.user.originrequestid != 9)) &&
                          <Can I="Open" a="Called">
                            <Tooltip title={intl.formatMessage({id:"called.new"})} aria-label="add">                            
                              <Fab color="secondary" aria-label="add" style={this.fabButton} component={Link} {...{ to: link }}>
                                    <AddIcon />
                              </Fab>
                            </Tooltip>
                          </Can> 
                          } 
                          </ButtonGroup>
                      </Grid>
                  </Grid>    
                  </div>
              </Container>  
        </div>} {!this.state.matches && 
            <>
              <CustomizedSnackbars
                  variant={this.state.notificationVariant}
                  message={this.state.notificationMessage}
                  isOpen={this.state.openNotification}
                  toClose={this.closeNotification}
              />
              {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}
              {/* this.isClient && this.state.showClientChat &&
                <ClientChat closeChat={ this.closeClientChat } userId={ userSession.user.id } userSession={ userSession } intl={intl} /> 
              }
              { !this.isClient && this.state.showTechnicianChat &&
                <TechnicianChat closeChat={ this.closeTechnicianChat } userId={ userSession.user.id }  />
              }*/}
              {this.state.showHelp && (
                <DialogHelp handleClose={this.toggleHelp} />
              )}
              <div style={{...footerStyle, position: "fixed", zIndex: 2, padding: 0}}>
                <Container component='main' maxWidth='xl'>
                    <CssBaseline />
                    <div className={classes.paper} style={{ marginTop: 0, paddingTop: 0, height: "25px" }}>
                    <Grid container style={{ paddingTop: 0, ...flex }}>
                        <Grid item xs style={{ paddingTop: 0, ...flex }}>
                            <Typography variant="h6" className={classes.title}>
                                <div className={classes.title} style={{ paddingLeft: '30px', color:'#3e3c3c', fontSize:'11px' }}>
                                {this.state.footerText} <FormattedMessage id="version"/> {version} - <FormattedMessage id="copyright"/>
                                    <span style={{marginLeft:'15px'}}>
										{/*
                                      <a href="https://dpo.privacytools.com.br/policy-view/lm3JvwDqN/1/poli%CC%81tica-de-privacidade/pt_BR?s=1622720326739" target="_blank"><FormattedMessage id="privacypolicy"/></a> |&nbsp;
                                      <a href="https://dpo.privacytools.com.br/policy-view/G0zQY4kdY/2/poli%CC%81tica-de-cookies/pt_BR?s=1622720365335" target="_blank"><FormattedMessage id="cookiepolicy"/></a> |&nbsp; 
                                      <a href="https://dpo.privacytools.com.br/public_api/dsar/gvSNdhCIcgJy1dunSD630LIRwHbyNagMC0qFst6zYRXQzKWNZ__bar__YLQsk6IW__plus__5MO1oLVBiN43sMaak9zpqQxKyFg__equal____equal__/form.html" target="_blank"><FormattedMessage id="talkdpo"/></a> |&nbsp;
										*/}  
                                      { this.state.showfooterusetermslink == true && <a href="https://dpo.privacytools.com.br/policy-view/nzVRBwDmQ/3/termos-e-condic%CC%A7o%CC%83es-gerais-de-uso-plataforma-mexx/pt_BR" target="_blank" rel="noreferrer"><FormattedMessage id="useterm"/></a>}
                                    </span>
                                </div>
                            </Typography>
                            <ButtonGroup>
								{((userSession != null && userSession.user != null && userSession.user.isexternal != null && userSession.user.isexternal && userSession.user.canexternaluserclientcreatecalled != null && userSession.user.canexternaluserclientcreatecalled) || (userSession != null && userSession.user.isexternal != null && !userSession.user.isexternal) && (userSession.user.originrequestid != 8 && userSession.user.originrequestid != 9)) &&
								<Can I="Open" a="Called">
									<Tooltip title={intl.formatMessage({id:"called.new"})} aria-label="add">                            
										<Fab color="secondary" aria-label="add" style={this.fabButton} component={Link} {...{ to: link }}>
												<AddIcon />
										</Fab>
									</Tooltip>
								</Can>  
								}
                            </ButtonGroup>
                            {((((userSession.user.isexternal != null && userSession.user.isexternal) || (userSession.user.onlyclient != null && userSession.user.onlyclient)) && userSession.user.canclientshowhelpfloatingbutton != null && userSession.user.canclientshowhelpfloatingbutton) || ((userSession.user.isexternal != null && !userSession.user.isexternal) && (userSession.user.onlyclient != null && !userSession.user.onlyclient))) &&
                            <Tooltip title={intl.formatMessage({id:"problems.with.the.platform"})} aria-label="add">                            
                              <Fab color="secondary" aria-label="add" style={this.fabHelpButton} onClick={this.toggleHelp }>
                                  <HelpOutlineIcon />
                              </Fab>
                            </Tooltip>
							}               
                            {!this.isClient && this.state.showQuickCalled && this.state.showDialog && 
                                <>
                                <Tooltip title={intl.formatMessage({id:"quick.called"})} aria-label="add">                            
                                                <Fab color="secondary" aria-label="add" style={this.quickCalledIconButton} onClick={this.openOrCloseQuickCalledModal }>
                                                    <DynamicFormIcon />
                                                </Fab>
                                  </Tooltip>
                                  <QuickCalledDialog
                                  orgList = {this.state.orgList}
                                  openOrCloseModalFunction={ this.openOrCloseQuickCalledModal }
                                  QuickCalledModalState ={this.state.openQuickCalledModal}
                                  handleConfirmation = { this.createQuickCalled}
                                  userSession={ this.props.userSession }
                                  intl={this.props.intl}
                                  />
                                    <Dialog
                                      open={this.state.openDialogNewAttendence}
                                      aria-labelledby="alert-dialog-title"
                                      aria-describedby="alert-dialog-description"
                                    >
                                      <DialogTitle id="alert-dialog-title">{intl.formatMessage({id:"dialog.title.warning.max.attendances.reached"})}</DialogTitle>
                                      <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                          {intl.formatMessage({id:"warning.max.attendances.reached.first.half"})} R$ {this.state.valueNewAttendence} {intl.formatMessage({id:"warning.max.attendances.reached.second.half"})}
                                        </DialogContentText>
                                      </DialogContent>
                                      <DialogActions>
                                        <Button onClick={this.openDialogNewAttendence} variant="contained" color="secondary" autoFocus >
                                          {intl.formatMessage({id:"cancel"})}
                                        </Button>
                                        <Button variant="contained" onClick={this.handleSubmitAccept} color="primary" >
                                        {intl.formatMessage({id:"accept"})}
                                        </Button>
                                      </DialogActions>
                                    </Dialog>
                                </>
                              
                            }
                            {/* !this.isClient && this.state.showChatConfig &&
                              <>
                                <Tooltip title="Configurações do chat" aria-label="add">                            
                                  <Fab color="secondary" aria-label="add" style={this.chatConfigStyle} onClick={ this.openAvailabilityConfigChatModal }  disabled={!this.context.can('Add', 'RealtimeChat') && !this.context.can('View', 'RealtimeChat')} >
                                        <SettingsIcon />
                                  </Fab>
                                </Tooltip>
                                <AvailabilityConfigChat 
                                  onClose={ this.closeAvailabilityConfigChatModal }
                                  open={ this.state.openAvailabilityConfigChat }
                                  userSession={ this.props.userSession }
                                  intl={this.props.intl}
                                  />
                                 

                                  
                              </>
                          */}
                            {/* this.state.showChatButton && 
                             <>
                              <ChatListButton 
                                clientChatIsOpen={ this.state.showClientChat }
                                buttonStyle={ this.ChatListButtonStyle } 
                                handleOnClick={ this.isClient ? this.toggleClientChat : this.toggleTechnicianChat }
                                disabled={!this.context.can('Add', 'RealtimeChat') && !this.context.can('View', 'RealtimeChat')}
                                />
                               
                              </>
                          */}
                            { !this.isClient && this.state.is2TalkAvaliable &&
                              <>
                                <Tooltip title="Abrir conversas 2talk" aria-label="add">                            
                                  <Fab color="secondary" aria-label="add" style={this.ToTalkChatStyle} onClick={  this.redirectTo2talkAttendance }   >
                                    <img src={chatIcon} alt="Minha Imagem" style={{ width: '20px', height: '20px' }}/>
                                  </Fab>
                                </Tooltip>
                                  
                              </>
                            }

                            
                            
                        </Grid>
                    </Grid>    
                    </div>
                    
              </Container>  
          </div>
        </> 
        }

              {/*userSession.user.defaultwebchatid && userSession.user.defaultwebchaturl && userSession.user.defaultwebchattoken && (
                  <>
                  <script>
                    var _2talk_chatclientkey = "{userSession.user.defaultwebchattoken}";
                    </script>
                    <script src={userSession.user.defaultwebchaturl}></script>
                    </>
              )*/}                 

          </FooterContent>
        );
      }
    }
    
    Footer.propTypes = {
      classes: PropTypes.object.isRequired,
    };
    
    const mapStateToProps = state => ({
      Footer: state.Footer,
      userSession: state.userSession,
      users: state.users,
      chatHubConnection: state.chatHubconnection
    });

    const mapDispatchToProps = (dispatch) =>
      bindActionCreators(componentActions, dispatch);
    
    export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(Footer))));
    Footer.contextType = AbilityContext;