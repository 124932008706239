import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import HeaderImg from '../../images/me-feedback/pop-up-response.png';
import { PapiroConsole } from '../../utils/papiroConsole';
import { FormattedMessage,injectIntl } from 'react-intl';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 480,
  bgcolor: 'background.paper',
  borderRadius: 8, 
  boxShadow: 24,
  p: 4,
};

function DialogClientFeedbackSuccess(props) {

  PapiroConsole.log(props)
  PapiroConsole.log("props aqui")
 

  return (
    <div>
      
      <Modal
      
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        //onClose={props.handleCloseDialogSuccessFeedback}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <img
              src={HeaderImg}
              alt="Descrição da imagem"
              style={{ maxWidth: '100%',  }}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default injectIntl(DialogClientFeedbackSuccess);