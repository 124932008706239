import Api from './api';

export const getByIdWithAttachmentIncludes = async (calledServiceSettingsFileUploadId) => { 
  let data = []
  await Api.get(`/calledservicesettingsfileuploadattachment/calledservicesettingsfileupload/${calledServiceSettingsFileUploadId}/withattachmentincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getAllByIdWithAttachmentIncludes = async (calledServiceSettingsFileUploadId) => { 
  let data = []
  await Api.get(`/calledservicesettingsfileuploadattachment/all/calledservicesettingsfileupload/${calledServiceSettingsFileUploadId}/withattachmentincludes`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};

export const deleteItem = async (id) => { 
  let data = []
  await Api.delete(`/calledservicesettingsfileuploadattachment/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}