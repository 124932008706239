import Api2Talk from "../../services/api2talk";

 export const getPhoneNumberList = async () => {
	let data;
	await Api2Talk.get("/registrationphonenumber/getallphoneactiveable")
		.then(result => {
			//console.error('RESULTT0');
			//console.error(result);
			if (result.data.success) {

				var formatlist = result.data.data.map(item => {
					return {
						id: item.id,
						name: item.phonenumber
					}
				})
				result.data.data=formatlist;
				data= result.data;

			} else {
				data= result.data;
			}
		})
		.catch(err => {
			data=err;
		 
		});
	return data;
}


export const getPhoneNumberListFromChannel = async (id) => {
	let data;
	await Api2Talk.get(`/registrationphonenumber/channel/${id}`)
		.then(result => {
			if (result.data.success) {

				
				var formatlist = result.data.data.map(item => {
					return {
						id: item.id,
						name: item.phonenumber,
						phonenumber: item.phonenumber,
						accountid : item.accountid, 
						channelid: item.channelid, 
						companyaccountid: item.companyaccountid,
						companyname: item.companyname,
						messageproviderconfigid: item.messageproviderconfigid,
						messageproviderid: item.messageproviderid,
						organizationid: item.organizationid,
						phonenumberid: item.phonenumberid,
						requeststatusid: item.requeststatusid,
						techinicalteamid: item.channel?.techinicalteamid,
						originrequestid: item.messageprovider?.originrequest?.id
					}
				})
				result.data.data=formatlist;
				
				data= result.data;

			} else {
				data= result.data;
			}
		})
		.catch(err => {
			data=err;
		 
		});
	return data;
}

export const organizationHasRegistrationNumberRequestActive = async (id) => {
	let data;
	await Api2Talk.get(`/registrationphonenumber/by-organization/${id}`)
		.then(result => {
			if (result.data.success) {
				data= result.data;
			} else {
				data= result.data;
			}
		})
		.catch(err => {
			data=err;
		 
		});
	return data;
}


export const metaRquest = async (formData) => { 
	let data = []
	await Api2Talk.post(`/registrationphonenumber/meta-request`, formData)
	  .then(result => {
		data = result.data
	  })
	  .catch(err => {
		data = err.response
	  })
	return data
  }


  export const metaDataVerification = async (formData) => { 
	let data = []
	await Api2Talk.post(`/registrationphonenumber/meta-data-verification`, formData)
	  .then(result => {
		data = result.data
	  })
	  .catch(err => {
		data = err.response
	  })
	return data
  }
  