import React from 'react';
import { makeStyles } from '@mui/styles';
import PropTypes from "prop-types";
import { InputLabel, TextField, FormControl } from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Api from "../../services/api";
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { VariableSizeList } from 'react-window';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import { useTheme } from '@mui/styles';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import { injectIntl } from 'react-intl';
import { PapiroConsole } from '../../utils/papiroConsole';


const LISTBOX_PADDING = 8; // px

const toggleItemActive = ({ doSomething, value }) => {
    //console.log(doSomething);
};

function renderRow(props) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: style.top + LISTBOX_PADDING,
    };
    //console.error('dataSet');
    //console.error(dataSet);
    if (dataSet.hasOwnProperty('group')) {
        return (
            <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
                {dataSet.group}
            </ListSubheader>
        );
    }

    return (
        <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
            {dataSet[1].parentname ? dataSet[1].parentname : dataSet[1].name}
        </Typography>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = [];
    children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });

    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (child.hasOwnProperty('group')) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

ListboxComponent.propTypes = {
    children: PropTypes.node,
};

function random(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
}

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

const OPTIONS = Array.from(new Array(10000))
    .map(() => random(10 + Math.ceil(Math.random() * 20)))
    .sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));



const renderGroup = params => [
    <ListSubheader key={params.key} component="div">
        {params.key}
    </ListSubheader>,
    params.children,
];


const useStyles = makeStyles(theme => ({
    listbox: {
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
}));


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function SimpleAutoCompleteAsynchronousKnowledgebaseByOrganization(props) {


    const { label, linkToSearch, organization, options, optionsText, stateName, changeSelect, selected, required, disabled, created = false, toSearchProp = true, nameAndEmail = false, customRenderOption, setErrorMessage, isNotClientErrorMessage = false, isClient, stateToName = '', intl,showMessageMissing } = props;

    //console.log(options);
    const defaultvalue = { id: 0, name: '' };
    const classes = useStyles();

    const [opt, setOption] = React.useState(selected ? selected : defaultvalue);
    const [listOption, setListOption] = React.useState(options);
    const [open, setOpen] = React.useState(false);
    const [toSearch, setToSearch] = React.useState(toSearchProp)
    const loading = open && (toSearch === true);

    React.useEffect(() => {
        if (selected != null)
            setOption(selected ? selected : defaultvalue);
    }, [selected]);

    React.useEffect(() => {
        if (!loading) {
            return undefined;
        }

        


        (async () => {
            PapiroConsole.log("organization")
            PapiroConsole.log(organization)
            PapiroConsole.log(loading)
            PapiroConsole.log("loading")
            if (organization) {
                
                var url = linkToSearch + `/byorganizationid/${organization}`
                PapiroConsole.log("url aqui")
                PapiroConsole.log(url)
                await Api.get(url)
                    .then(result => {
                        if (result && result.data && result.data.success) {
                            if (result.data.data && result.data.data.length > 0) {
                                if (toSearch || listOption.length == 0) {
                                    if (nameAndEmail) {
                                        let formmatedList = result.data.data.map(item => {
                                            let nameAndEmail = `${item.name} (${item.email})`

                                            return {
                                                ...item,
                                                name: nameAndEmail
                                            }
                                        })
                                        setListOption(formmatedList)
                                        setToSearch(false)
                                    } else {
                                        let formmatedList = result.data.data.map(item => {
                                            let toName = item && item.name
                                            if (stateToName != null && stateToName.length > 0) {
                                                if (stateToName in item)
                                                    toName = item[stateToName]
                                            }

                                            if (item.knowledgebasekeywords && item.knowledgebasekeywords.length > 0) {
                                                toName += ` (`
                                                item.knowledgebasekeywords.map((word, ix) => {
                                                    if (word && word.word && word.word.length > 0) {
                                                        if (ix == 0)
                                                            toName += `${word.word}`
                                                        else
                                                            toName += `, ${word.word}`
                                                    }
                                                })
                                                toName += `)`
                                            }

                                            return {
                                                ...item,
                                                name: toName
                                            }
                                        })
                                        setListOption(formmatedList)
                                        setToSearch(false)
                                    }
                                }
                            } else if ((result.data.data == null || (result.data.data && result.data.data.length == 0)) && isNotClientErrorMessage && !isClient) {
                                //setErrorMessage(true, "error", "Nenhum resultado foi encontrado")
                                let errorArr = []
                                setListOption(errorArr)
                                setToSearch(false)
                                return undefined;
                            }
                        }
                    })
                    .catch(err => {
                        setListOption(options)
                        Api.kickoff(err);
                    });
            }
            else{
                PapiroConsole.log("entrei no set to search")
                let errorArr = []
                setListOption(errorArr)
                setToSearch(false)
                showMessageMissing()
                return undefined;
            }

            PapiroConsole.log(loading)
            PapiroConsole.log("loading")

        })();

        return () => {
            setToSearch(false)
        };
    }, [loading]);

    React.useEffect(() => {
        if (options.length == 0) {
            setListOption(options)
        }
    }, [linkToSearch, options])

    const handleChange = (event, value) => {
        //console.log(value);
        if (value == null) {
            value = { id: 0, name: '' };
        }
        setOption(value);
        changeSelect(stateName, value);
    };
    const getIds = () => {
        const idList = listOption.map(item => {
            return item.id
        });
        return idList;

    };
    const getNames = () => {
        const idList = listOption.map(item => {
            return item.parentname ? item.parentname : item.name
        });
        return idList;

    }

    const Result = () => {

        return (<Autocomplete
            id="combo-box"
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            options={listOption}
            loading={loading}
            disableListWrap
            classes={classes}
            ListboxComponent={!customRenderOption ? ListboxComponent : undefined}
            value={opt}
            getOptionLabel={option => option.parentname ? option.parentname : option.name}
            getOptionDisabled={!created ? option => option.cancreatecalled === false || option.id === intl.formatMessage({ id: "no.results" }) : option => option}
            onChange={handleChange}
            style={{ fullWidth: true }}
            disabled={disabled}
            renderInput={params => (
                <TextField {...params} label={label} variant="outlined" required={required != null ? required == false ? false : true : false} fullWidth InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }} />
            )}
            PopperComponent={StyledPopper}
            renderOption={customRenderOption ? customRenderOption : (props, option) => [props, option]}
        />
        );

    }

    return (
        <Result />
    );
}

SimpleAutoCompleteAsynchronousKnowledgebaseByOrganization.propTypes = {
    label: PropTypes.string,
    linkToSearch: PropTypes.string,
    options: PropTypes.array,
    stateName: PropTypes.string,
    changeSelect: PropTypes.func,
    selected: PropTypes.object,
    checkbox: PropTypes.bool,
    created: PropTypes.bool,
    toSearchProp: PropTypes.bool,
    nameAndEmail: PropTypes.bool,
};

export default injectIntl(SimpleAutoCompleteAsynchronousKnowledgebaseByOrganization)