export default function organizations(state = [], action) {
    switch (action.type) {
        case 'ADD_CURRENT':
            return {
                ...state,
                organization: action.organization,
            }
        default:
            return state;
    }
}
