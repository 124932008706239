import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from 'react-intl';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

export default function PolicyPasswordValidation(props) {
  const { name, isValid, numMinimo } = props;

  return (
    <div style={{padding: 5}} >
      {isValid && (
        <div 
          style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
          <DoneIcon color="success" />
          &nbsp;
          <b>
            <FormattedMessage     
              values={{ numMinimo: numMinimo }} 
              id={"passwordpolicy."+name}/>
          </b>
        </div>
      )}
      {!isValid && (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
          <CloseIcon  sx={{ color: 'red' }} />
          &nbsp;
          <FormattedMessage 
            values={{ numMinimo: numMinimo }}
            id={"passwordpolicy."+name} />
        </div>
      )}
      
    </div>
  );
}

PolicyPasswordValidation.propTypes = {
  configPasswordPolicy: PropTypes.object,
  password: PropTypes.string,
};
