import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever, ContactPhoneOutlined } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleSelect from '../../components/select/simple';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage, injectIntl } from 'react-intl';
import Title from '../../components/title-name';
import * as Service from '../../services/address.service';
import SimpleCheckBox from '../../components/checkbox/check';
import SimpleHelp from '../../components/help';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { makeStyles } from '@mui/styles';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import { Dialog } from '@mui/material';




class AddressRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession } = this.props;

    this.globalAdm = false;
    this.isClient = true;
    this.hasAddress = null
    this.address = null
    if (userSession == null ||  userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    this.fromProps = false
    if(this.props && this.props.location && this.props.location.state && this.props.location.state.from && this.props.location.state.from.length > 0)
      this.fromProps = true

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    }
    );


    this.initialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      OrganizationId: this.props.organizations.organization.id,
      OrganizationText: '',
      UserText: '',
      template: true,
      template2: false,
      name: '',
      street: '',
      zipcode: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
      country: '',
      longitude: '',
      latitude: '',
      keepaddress: false,
      iscustomaddress : false, 
      saveToAllOrganizationsInGroup: false,
    };
    this.state = {
      item: this.initialState,
      modalOpen: false,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      OrganizationList: [],
      UserList: [],

    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChangeRadio = this.handleChangeRadio.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.getCep = this.getCep.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  componentDidMount() {
    this.getOrganizations(this.state.item.OrganizationId)


  };
  async getOrganizations(id) {
    this.setState({ loading: true });

    var result = await Service.getOrganizations(id);
    if (result.success) {
      if (result.data.length === 0) {
        this.hasAddress = false
      }
      else {
        this.hasAddress = true
        result.data.forEach(element => {
          if (element.defaultlocation === true) {
            this.address = element.name

          }
        });
      }



      this.setState({ loading: false, OrganizationList: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }; /*async getUsers(){
          this.setState({ loading: true });
          var result = await Service.getUsers();
            if (result.success) {
              this.setState({ loading: false, UserList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);
            }
  };*/

  handleOpen() {

    this.setState({ modalOpen: true });
  };

  handleClose() {
    this.setState({ modalOpen: false })
  };

  async handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    if (name == 'zipcode') {

      if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            [name]: value
          }
        }));
        if (value.length == 8) {
          this.setState({ loading: true });
          var data = await this.getCep(value);
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['street']: data.logradouro,
              ['complement']: data.complemento,
              ['neighborhood']: data.bairro,
              ['city']: data.localidade,
              ['state']: data.uf,
              ['country']: 'BRASIL',
            }
          }));
          this.setState({ loading: false });
        }
      } else {
        return false;
      }
    } else {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [name]: value
        }
      }));
    }

  };

  handleSubmit = async e => {
    if (e !== undefined) {
      e.preventDefault();
    }


    if (this.hasAddress === true && this.state.item.template === true && this.state.modalOpen !== true && this.address !== null) {
      this.handleOpen()
    }

    else {
      this.setState({ loading: true });

      let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      }




      for (let key in this.state.item) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else {
          data.append(key, this.state.item[key]);
        }
      }

      this.state.files.forEach(file => data.append('filesUpload', file));
      data.append('defaultlocation', this.state.item['template']);
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      Api.post('/addresses', data, config)

        .then(result => {
          if (result.data.success) {
            const intl = this.props.intl;

            if (!this.state.item.keepaddress) {
              this.setState({
                item: this.initialState,
                files: []
              })
            }

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({ id: "add.success" }),
            });
            this.getOrganizations(this.state.item.OrganizationId)
            this.handleClose()
            // this.getOrganizations();
            //this.getUsers();


          }
          else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })

            });
            Api.kickoff(result);

          }
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: intl.formatMessage({ id: "process.error" })
            });
          }
        });
    }



  };

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    });
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  async choiceDefaultLocation(value) {


    await this.setStateAsync(prevState => ({
      loading: true,
      item: {
        ...prevState.item,
        ['template']: value,
      }

    }))

    this.handleSubmit()

    /*this.setState(prevState => ({
      loading: true,
      item: {
        ...prevState.item,
        ['template'] : value,
      }
      
    })); */









  }



  async getCep(cepNumber) {

    return new Promise(function (resolve, reject) {

      fetch("https://viacep.com.br/ws/".concat(cepNumber, "/json/")).then(function (response) {
        return response.json();
      }).then(function (data) {
        // console.log(data);
        resolve(data);
      })["catch"](function (err) {
        // console.log(err);
        reject(err);
      })["finally"](function (data) {
        return data;
      });

    });
  }

  handleChangeRadio(e) {

    var value = null;

    if (e.target.value === 'true') {
      value = true
    }
    else {
      value = false
    }

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        ['template']: value,

      }
    }));


  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });


    if (stateName == 'template') {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [stateName]: value,
          ['template2']: !value,
        }
      }));



    } else if (stateName == 'template2') {

      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [stateName]: value,
          ['template']: !value,
        }
      }));

    }
    else {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [stateName]: value
        }
      }));
      if (stateName === 'OrganizationId') {

        this.setState(prevState => ({
          item: {
            ...prevState.item,
            ['OrganizationText']: text,
          }
        }));
      }

    }






    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }



    return (
      <div className={classes.root}>

        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div>
              <Dialog open={this.state.modalOpen} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                  {intl.formatMessage({id:"default.location"})}
                </DialogTitle>

                <DialogContent>
                  <DialogContentText>
                    {intl.formatMessage({id:"wizard.pages.address.location.exists.first.half"})} {this.address} {intl.formatMessage({id:"wizard.pages.address.location.exists.second.half"})}
                  </DialogContentText>

                </DialogContent>

                <div style={{ textAlign: 'center', marginTop: '6px', marginBottom: '10px' }}>
                  <Button variant='contained' color='primary' onClick={() => {

                    this.choiceDefaultLocation(true)

                  }} style={{ marginRight: '4px' }}>{intl.formatMessage({id:"yes"})}</Button>
                  <Button variant='contained' color='primary' onClick={() => {

                    this.choiceDefaultLocation(false)

                  }}>{intl.formatMessage({id:"no"})}</Button>
                </div>

              </Dialog>
            </div>
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary' onClick={() => {
                    this.fromProps ? this.props.history.push(`/${this.props.match.params.lang}/organization-edit`, { organizations: this.props.organizations, propTab: 2 }) : browserHistory.goBack()}}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="add.item" />}
                <Title />
              </Typography>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField fullWidth label={intl.formatMessage({ id: "name" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChange} value={item.name} required />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField inputProps={{ maxLength: 100 }} fullWidth label={intl.formatMessage({ id: "cep" })} autoComplete='fname' variant='outlined' name='zipcode' onChange={this.handleInputChange} value={item.zipcode} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField fullWidth label={intl.formatMessage({ id: "street" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='street' onChange={this.handleInputChange} value={item.street} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField fullWidth label={intl.formatMessage({ id: "number" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='number' onChange={this.handleInputChange} value={item.number} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField fullWidth label={intl.formatMessage({ id: "complement" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='complement' onChange={this.handleInputChange} value={item.complement} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField fullWidth label={intl.formatMessage({ id: "neighborhood" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='neighborhood' onChange={this.handleInputChange} value={item.neighborhood} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField fullWidth label={intl.formatMessage({ id: "city" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='city' onChange={this.handleInputChange} value={item.city} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField fullWidth label={intl.formatMessage({ id: "state" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='state' onChange={this.handleInputChange} value={item.state} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField fullWidth label={intl.formatMessage({ id: "country" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='country' onChange={this.handleInputChange} value={item.country} />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ display: 'none' }}>
                    <TextField fullWidth label={intl.formatMessage({ id: "longitude" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='longitude' onChange={this.handleInputChange} value={item.longitude} />
                  </Grid>
                  <Grid item xs={12} sm={12} style={{ display: 'none' }}>
                    <TextField fullWidth label={intl.formatMessage({ id: "latitude" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='latitude' onChange={this.handleInputChange} value={item.latitude} />
                  </Grid>
                  <Grid item xs={11} sm={11} style={{ textAlign: 'left' }}>
                    <SimpleCheckBox label={intl.formatMessage({ id: "keep.called" })} name='keepaddress'
                      stateName='keepaddress' changeSelect={this.changeValues} />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <SimpleHelp message={intl.formatMessage({id:"wizard.pages.address.functionality.keep.address.data"})} />
                  </Grid>
                {this.hasAddress === true &&
                  <Grid  item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography variant="body1" component="span" >
                            {intl.formatMessage({id:"enable.as.default.location"})}
                          </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4} md={3} lg={2}>
                        <span style={{ fontSize: '15px', marginTop: '10px' }}>{intl.formatMessage({id:"yes"})}</span>
                          <Radio
                            label={this.props.intl.formatMessage({id:"yes"})}
                            checked={this.state.item.template === true}
                            onChange={this.handleChangeRadio}
                            value={true}
                            color="default"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': true }}
                            size="small"
                          />
                      </Grid>
                      <Grid item xs={6} sm={4} md={3} lg={2}>
                        <span style={{ fontSize: '15px', marginTop: '10px' }}>{intl.formatMessage({id:"no"})}</span>
                        <Radio
                          label={this.props.intl.formatMessage({id:"no"})}
                          checked={this.state.item.template === false}
                          onChange={this.handleChangeRadio}
                          value={false}
                          color="default"
                          name="radio-button-demo"
                          inputProps={{ 'aria-label': false }}
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  </Grid>}
                  <Grid item xs={11} sm={11}>
                    <SimpleCheckBox
                    label={intl.formatMessage({id:"save.to.all.organizations.in.group"})}
                    name='saveToAllOrganizationsInGroup'
                    stateName='saveToAllOrganizationsInGroup' 
                    changeSelect={this.changeValues}
                    selected={item.saveToAllOrganizationsInGroup} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                    <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={4}    >
                        <Button
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                          className={classes.submit}
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>


              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}
AddressRegistration.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  org: state.organization.organization,
  organizations: state.organizations,
});
export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(AddressRegistration)));
