import Api from './api';


export const getPermissions = async () => { 
    let data = [];
    await Api.get('/permissions/min')
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };
  export const getPermissionsExceptRole = async (id) => { 
    let data = [];
    await Api.get('/permissions/role-remove/'+id)
        .then(result => {
            data = result.data
         })
         .catch(err => {
            data = err;
    });
    return data;
  };

  export const getPermissionsByRole = async (id) => { 
      let data = [];
      await Api.get('/permissions/role/'+id)
        .then(result => {
              data = result.data
           })
           .catch(err => {
              data = err;
      });
      return data;
    };