export function addCurrent(organizationfield) {
    return {
        type: 'ADD_CURRENT',
        organizationfield,
    }
}
export function addCurrentOrganization(organizationfield,organization) {
    return {
        type: 'ADD_CURRENT_ORGANIZATION',
        organizationfield,
        organization,
        }
}
