import { QueuePlayNext } from '@mui/icons-material';
import { Avatar, Button, CircularProgress, Container, CssBaseline, Grid, TextField, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import HeaderMenu from '../../components/header-menu';
import CustomizedSnackbars from '../../components/material-snackbars';
import Api from '../../services/api';
import { Overlay } from '../../styles/global';
import { Styles } from '../../styles/material-styles';
//import * as Service from '../../services/segment.service';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import SimpleAutoCompleteSearch from '../../components/auto-complete/autocomplete-search-api';
import SimpleCheckBox from '../../components/checkbox/check';
import SimpleDatePicker from '../../components/date-pickers/inline-no-today';
import SimpleRadio from '../../components/radio-button/index';
import SimpleSelect from '../../components/select/simple';
import { browserHistory } from '../../helpers/history';
import * as Service from '../../services/user.service';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { DataGrid, GridToolbarFilterButton } from '@mui/x-data-grid';
import InputMask from 'react-input-mask';
import { bindActionCreators } from 'redux';
import DialogConfirmation from '../../components/dialog-confirmation';
import * as componentActions from '../../redux/actions/timeentries';
import LocationService from '../../services/location.service';
import './styles.css';


const CepCoords = require("coordenadas-do-cep");

const NoDataRows = _ => {
  const intl = this.props.intl;
  return (
    <div className="MuiDataGrid-window" style={{ display: 'flex', justifyContent: 'center', top: 56, overflowY: 'auto' }} >{intl.formatMessage({id:"rows.show"})}</div>
  )
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

class CalledTimeEntriesEdit extends Component {

  constructor(props) {
    super(props);

    const { userSession,timeentries, calleds } = this.props;
    const intl = this.props.intl;
    this.globalAdm = false;
    this.isClient = true;
    if (userSession == null ||  userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    this.regexHourFormat = new RegExp(/^[0-9]{2}\:[0-9]{1}$|^[0-9]{1}\:[0-9]{2}$|^[0-9]{1}\:[0-9]{1}$|^[0-9]{2}\:[0-9]{2}$|^[0-9]{2}$|^[0-9]{1}$/gm)

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
    }
    );

    this.hourMask = [
      /[0-2]/,
      /[0-3]/,
      ':',
      /[0-5]/,
      /[0-9]/
    ]

    let typeentries = intl.formatMessage({id:"remote"});

    if(timeentries.calledtimeentries.typetimesentriesid == 2){
      typeentries = intl.formatMessage({id:"on.site"});
    }
    
    var defaultvalue = { id: 0, name: '' };

    let total = timeentries.calledtimeentries.hours.toString().split(".");
    let hour = total[0].toString().length == 1 ? "0"+total[0] : total[0];
    let minute = total[1] ? (total[1]*6).toString() : "00";
    if(minute.length > 2){
      minute = minute.substr(0, 2)
    }

    this.initialState = {
      updatedUser: userSession.user.id,
      id: timeentries.calledtimeentries? timeentries.calledtimeentries.id : 0 ,
      hours : this.convertTohoursFormat(timeentries.calledtimeentries.hours),
      comment : timeentries.calledtimeentries? timeentries.calledtimeentries.comment : '',
      users: [], 
      UserId : timeentries.calledtimeentries? timeentries.calledtimeentries.userid : 0, 
      User : timeentries.calledtimeentries? timeentries.calledtimeentries.user : defaultvalue,
      Activity : timeentries.calledtimeentries? timeentries.calledtimeentries.activity: defaultvalue,
      activities : [], 
      ActivityId : timeentries.calledtimeentries? timeentries.calledtimeentries.activityid: 0,
      organizationId : calleds.called ?  calleds.called.organizationid : 0, 
      organizationCategoryId : calleds.called.organizationcategory ?  calleds.called.organizationcategory.id : 0,
      calledId : calleds.called.id,
      dateStart: timeentries.calledtimeentries? timeentries.calledtimeentries.timeentrydate : moment().format('YYYY/MM/DD'),
      typetimeentriesid: timeentries.calledtimeentries.typetimesentriesid,
      typetimeentries: typeentries,
      typeBack: null
    };

    this.initialStateLocation = {

      calledtimeentriesid: 0,

      latitudesource: "",
      longitudesource: "",
      zipcodesource: "",
      numbersource: "",
      streetsource: "",
      complementsource: "",
      neighborhoodsource: "",
      citysource: "",
      statesource: "",
      countrysource: "",

      latitudetarget: "",
      longitudetarget: "",
      zipcodetarget: "",
      numbertarget: "",
      streettarget: "",
      complementtarget: "",
      neighborhoodtarget: "",
      citytarget: "",
      statetarget: "",
      countrytarget: "",

      timedisplacement: '0',
      timeattendence: '0',
      timedisplacementback: '0',
      starttime: '08:00',

      latitudeadditional: "",
      longitudeadditional: "",
      zipcodeadditional: "",
      numberadditional: "",
      streetadditional: "",
      complementadditional: "",
      neighborhoodadditional: "",
      cityadditional: "",
      stateadditional: "",
      countryadditional: "",

      sameOrigin: false

    };

    this.state = {
      item: this.initialState,
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      typetimeentriesList: [
        { id: 1, name: intl.formatMessage({id:"remote"}) },
        { id: 2, name: intl.formatMessage({id:"on.site"}) },
      ],

      LocationListForDelete: "",
      LocationListForSelect: [],
      LocationList: timeentries.calledtimeentries && timeentries.calledtimeentries.calledtimeentrieslocation ? this.formatedCalledTimeEntriesLocation(timeentries.calledtimeentries.calledtimeentrieslocation) : new Array(),
      LocationListSelect: [],
      itemLocation: this.initialStateLocation,
      openLocationInsertModal: false,
      typeBack: [{ id: 1, name: intl.formatMessage({id:"called.time.entries.registration.went.to.base"}) }, { id: 2, name: intl.formatMessage({id:"called.time.entries.registration.finished.shift"}) }, { id: 3, name: intl.formatMessage({id:"called.time.entries.registration.other.client"}) }],
      activeStep: 1,
      activeTab: 0,
      disabledHour: timeentries.calledtimeentries.typetimesentriesid == 2 ? true : false

    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);

    this.nonDecimalPlaces = this.nonDecimalPlaces.bind(this)
    this.decimalPlaces = this.decimalPlaces.bind(this)
    this.hasFraction = this.hasFraction.bind(this)
    this.NoDataRows = this.NoDataRows.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

  async componentDidMount() {

    //await this.getUsers();

    await this.getActivities();



  };

  NoDataRows = _ => {
    const intl = this.props.intl;
    return (
      <div className="MuiDataGrid-window" style={{ display: 'flex', justifyContent: 'center', top: 56, overflowY: 'auto' }} >{intl.formatMessage({id:"rows.show"})}</div>
    )
  }

  async getActivities() {
    this.setState({ loading: true });

    var result = await Service.GetAllActivities();

    if (result.success) {

      this.setState(prevState => ({
        item: {
          ...prevState.item,
          "activities": result.data
        },
        loading: false
      }));
      // this.setState({ loading: false, users: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };

  async getUsers() {
    this.setState({ loading: true });

    var result = await Service.GetUsersTechs(this.state.item.organizationCategoryId, this.state.item.organizationId);

    console.log(result)

    if (result.success) {

      this.setState(prevState => ({
        item: {
          ...prevState.item,
          "users": result.data
        },
        loading: false
      }));
      // this.setState({ loading: false, users: result.data });

    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };

  handleInputChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name == "hours") {
      if (e.target.value < 0) {
        e.target.value = 1
      }
      else {

        if(e.target.value.length < 6){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              [name]: this.mHour(value)
            }
          }));
        }

      }
    }
    else {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [name]: value
        }
      }));

    }

  };

  convertTohoursFormat = (minutos) => {
    let calledHourLoc = minutos ? minutos : "00:00"
    if(calledHourLoc && calledHourLoc > 0) {
        var splitCalledHoutLoc = [
            (calledHourLoc > 0) ? Math.floor(calledHourLoc) : Math.ceil(calledHourLoc),
            calledHourLoc % 1
        ]
        let nDate = new Date(0,0)
        nDate.setSeconds(+splitCalledHoutLoc[1] * 60 * 60)
        if(nDate.getSeconds() >= 58 ) {
            nDate.setSeconds(0)
            nDate.setMinutes(nDate.getMinutes() + 1)
        }
        let calledHourLocResult = nDate.toTimeString().slice(2, 5)
        console.log(calledHourLocResult)
        calledHourLocResult = splitCalledHoutLoc[0] == 0 ? "00" + calledHourLocResult : splitCalledHoutLoc[0] < 10 ? "0" + splitCalledHoutLoc[0] + calledHourLocResult : splitCalledHoutLoc[0] + calledHourLocResult
        calledHourLoc = calledHourLocResult

        if(calledHourLoc == '0')
            calledHourLoc = "00:00"
    } else if(calledHourLoc == '0')
        calledHourLoc = "00:00"

    return calledHourLoc
  }

  handleInputChangeLocation = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name == 'zipcodesource') {

      if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
        this.setState(prevState => ({
          itemLocation: {
            ...prevState.itemLocation,
            [name]: value
          }
        }));
        if (value.length == 8) {
          this.setState({ loading: true });
          let data = await this.getCep(value);
          if(data && data?.uf){
            this.setState(prevState => ({
              itemLocation: {
                ...prevState.itemLocation,
                'streetsource': data?.logradouro,
                'complementsource': data?.complemento,
                'neighborhoodsource': data?.bairro,
                'citysource': data?.localidade,
                'statesource': data?.uf,
                'countrysource': 'BRASIL',
              }
            }));
            LocationService.getByCep(value)
              .then(info => {
                console.log(info)
                console.log(info)
                this.setState(prevState => ({
                  itemLocation: {
                    ...prevState.itemLocation,
                    'latitudesource': info.lat,
                    'longitudesource': info.lon,
                  }
                }));
              })
              .catch(err => {
                console.log("CepCoords.getByCep error")
                console.log(err)
                //retorna o mesmo parâmetro 'err' da versão em promise
              })
          }else{
            this.setState(prevState => ({
              itemLocation: {
                ...prevState.itemLocation,
                'streetsource': "",
                'complementsource': "",
                'neighborhoodsource': "",
                'citysource': "",
                'statesource': "",
                'countrysource': "",
                'latitudesource': "",
                'longitudesource': "",
              }
            }));
          }
          
          this.setState({ loading: false });
        }
      } else {
        return false;
      }

    } else if (name == 'zipcodetarget') {

      if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
        this.setState(prevState => ({
          itemLocation: {
            ...prevState.itemLocation,
            [name]: value
          }
        }));
        if (value.length == 8) {
          this.setState({ loading: true });
          let data = await this.getCep(value);
          if(data && data?.uf){
            this.setState(prevState => ({
              itemLocation: {
                ...prevState.itemLocation,
                'streettarget': data.logradouro,
                'complementtarget': data.complemento,
                'neighborhoodtarget': data.bairro,
                'citytarget': data.localidade,
                'statetarget': data.uf,
                'countrytarget': 'BRASIL',
              }
            }));
            LocationService.getByCep(value)
              .then(info => {
                console.log(info)
                console.log(info)
                this.setState(prevState => ({
                  itemLocation: {
                    ...prevState.itemLocation,
                    'latitudetarget': info.lat,
                    'longitudetarget': info.lon,
                  }
                }));
              })
              .catch(err => {
                console.log("CepCoords.getByCep error")
                console.log(err)
                //retorna o mesmo parâmetro 'err' da versão em promise
              })
          }else{
            this.setState(prevState => ({
              itemLocation: {
                ...prevState.itemLocation,
                'streettarget': "",
                'complementtarget': "",
                'neighborhoodtarget': "",
                'citytarget': "",
                'statetarget': "",
                'countrytarget': "",
                'latitudetarget': "",
                'longitudetarget': "",
              }
            }));
          }
          

          this.setState({ loading: false });
        }
      } else {
        return false;
      }

    } else if (name == 'zipcodeadditional') {

      if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
        this.setState(prevState => ({
          itemLocation: {
            ...prevState.itemLocation,
            [name]: value
          }
        }));
        if (value.length == 8) {
          this.setState({ loading: true });
          let data = await this.getCep(value);
          if(data && data?.uf){
            this.setState(prevState => ({
              itemLocation: {
                ...prevState.itemLocation,
                'streetadditional': data?.logradouro,
                'complementadditional': data?.complemento,
                'neighborhoodadditional': data?.bairro,
                'cityadditional': data?.localidade,
                'stateadditional': data?.uf,
                'countryadditional': 'BRASIL',
              }
            }));
            LocationService.getByCep(value)
              .then(info => {
                console.log("CepCoords.getByCep")
                console.log(info)
                this.setState(prevState => ({
                  itemLocation: {
                    ...prevState.itemLocation,
                    'latitudeadditional': info.lat,
                    'longitudeadditional': info.lon,
                  }
                }));
              })
              .catch(err => {
                console.log("CepCoords.getByCep error")
                console.log(err)
                //retorna o mesmo parâmetro 'err' da versão em promise
              })
          }else{
            this.setState(prevState => ({
              itemLocation: {
                ...prevState.itemLocation,
                'streetadditional': "",
                'complementadditional': "",
                'neighborhoodadditional': "",
                'cityadditional': "",
                'stateadditional': "",
                'countryadditional': "",
                'latitudeadditional': "",
                'longitudeadditional': "",
              }
            }));
          }
          

          this.setState({ loading: false });
        }
      } else {
        return false;
      }

    }
    else if (name == "timedisplacement" || name == "timeattendence" || name == 'timedisplacementback') {
      if (e.target.value < 0) {
        e.target.value = 1
      }
      else {

        if(e.target.value.length < 6){
          this.setState(prevState => ({
            itemLocation: {
              ...prevState.itemLocation,
              [name]: this.mHour(value)
            }
          }));
        }

      }
    }
    else {
      this.setState(prevState => ({
        itemLocation: {
          ...prevState.itemLocation,
          [name]: value
        }
      }));

    }

  };

  getCep = async (cepNumber) => {

    try{
      return new Promise(function (resolve, reject) {

        fetch("https://viacep.com.br/ws/".concat(cepNumber, "/json/")).then(function (response) {
          return response.json();
        }).then(function (data) {
          console.error(data);
          resolve(data);
        })["catch"](function (err) {
          console.error(err);
          reject(err);
        })["finally"](function (data) {
          console.error(data);
          return data;
        });
  
      });
    }catch(err){
      return {error: true}
    }
    
  }

  formatedCalledTimeEntriesLocation = (data) => {
    if(data){
      var datareturn = data.map(item => {
        let total = item.timedisplacement.toString().split(".");
        let hourDispl = total[0].toString().length == 1 ? "0"+total[0] : total[0];
        let minuteDispl = total[1] ? (total[1]*6).toString() : "00";
        if(minuteDispl.length > 2){
          minuteDispl = minuteDispl.substr(0, 2)
        }

        total = item.timeattendence.toString().split(".");
        let hourAtte = total[0].toString().length == 1 ? "0"+total[0] : total[0];
        let minuteAtte = total[1] ? (total[1]*6).toString() : "00";
        if(minuteAtte.length > 2){
          minuteAtte = minuteAtte.substr(0, 2)
        }

        total = item.timedisplacementback.toString().split(".");
        let hour = total[0].toString().length == 1 ? "0"+total[0] : total[0];
        let minute = total[1] ? (total[1]*6).toString() : "00";
        if(minute.length > 2){
          minute = minute.substr(0, 2)
        }

        return {
          ...item,
          timedisplacement: this.convertTohoursFormat(item.timedisplacement),
          timeattendence: this.convertTohoursFormat(item.timeattendence),
          timedisplacementback: this.convertTohoursFormat(item.timedisplacementback),
        }
      })

      return datareturn;
    }
    return data;
    
  }

  handleSubmitLocation = async (e, data) => {
    const intl = this.props.intl;
    e.preventDefault();

    for (var pair of data.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }

    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    await Api.post('/calledtimeentries/location', data, config)
      .then(result => {
        if(result.data.success){
          this.setState({
            loading: false,
            LocationList: this.formatedCalledTimeEntriesLocation(result.data.data),
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: intl.formatMessage({id:"added.with.success"}),
            openLocationInsertModal: !this.state.openLocationInsertModal,
            itemLocation: this.initialStateLocation,
            activeStep: 1,
            activeTab: 0,
          })

          let sumHours = 0.0
          result.data.data.map(item => {
            sumHours += item.timedisplacement + item.timeattendence + item.timedisplacementback
          })

          this.setState(prevState => ({
            item: {
              ...prevState.item,
              'typeBack': null,
              "hours": this.convertTohoursFormat(sumHours)
            }
          }));
          
        }else{
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: intl.formatMessage({id:"error.occurred.try.again.later"}),
          })
        }
      })
      .catch(err => {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: intl.formatMessage({id:"error.occurred.try.again.later"}),
        })
      });


  }

  mHour(v) {
    v = v.replace(/^(\d{2})(\d)/g, "$1:$2");
    //v = v.replace(/\D/g, "");
    
    //v = v.replace(/(\d)(\d{2})$/, "$1$2");
    return v;
  }

  nonDecimalPlaces = n => {
    let nFLoor = Math.floor(n)

    return nFLoor.toString().length
  }

  decimalPlaces = n => {
    let count = 0;
    // multiply by increasing powers of 10 until the fractional part is ~ 0
    while (this.hasFraction(n * (10 ** count)) && isFinite(10 ** count))
      count++;
    return count;
  }

  hasFraction = n => {
    return Math.abs(Math.round(n) - n) > 1e-10;
  }

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    var returnReponse = this.checkValidations();



    if (returnReponse == true) {
      let data = new FormData();

      data.append("typetimesentriesid", this.state.item.typetimeentriesid);
      let hour = this.formatedInHour(this.state.item.hours);
      if(this.decimalPlaces(hour) > 4) {
        var precision = 4 + this.nonDecimalPlaces(hour)
        hour = hour.toPrecision(precision)
      }
      data.append("hours", hour);

      for (let key in this.state.item) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        } else {
          data.append(key, this.state.item[key]);
        }
      }

      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

      await Api.put('/calledtimeentries', data, config)

        .then(result => {
          if (result.data.success && result.data.data) {

            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'success',
              notificationMessage: intl.formatMessage({ id: "edit.success" }),
            });


          }
          else {

            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);

          }
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.errors) {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })

            });
          }
        });

    }


  };

  checkValidations() {
    const intl = this.props.intl;

    if (moment(this.state.item.dateStart).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')) {

      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"called.time.entries.registration.date.field"})

      });

      return false

    }
    if (this.state.item.comment == '') {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"nps.email.rating.missing.comment"})

      });

      return false

    }
    if (this.state.item.UserId == 0) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"called.time.entries.registration.user.field"})

      });

      return false

    }

    if (this.state.item.ActivityId == 0) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"called.time.entries.registration.activity.field"})

      });

      return false

    }

    if (this.state.item.hours != null) {
      if (this.state.item.hours.length > 0) {
        let reg = new RegExp(/^[0-9]{2}\:[0-9]{1}$|^[0-9]{1}\:[0-9]{2}$|^[0-9]{1}\:[0-9]{1}$|^[0-9]{2}\:[0-9]{2}$|^[0-9]{2}$|^[0-9]{1}$/gm)
        let testReg = reg.test(this.state.item.hours)
        if(!testReg) {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'error',
            notificationMessage: intl.formatMessage({id:"called.time.entries.registration.hour.format.invalid"})
          });
          return false
        }
      }
    }

    if (this.state.item.hours <= 0) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"called.time.entries.registration.hour.zero.invalid"})

      });
      return false
    }

    if (this.state.item.typetimeentriesid == 2 && this.state.LocationList.length == 0) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"called.time.entries.registration.on.field.report"})

      });
      return false
    }

    return true


  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    if (stateName == "dateStart") {

      this.setState(prevState => ({

        item: {
          ...prevState.item,
          [stateName]: moment(value).format('YYYY/MM/DD')
        }
      }));
    }
    else {

      if (stateName == "UserId") {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            [stateName]: value.id,
            "User": value
          }
        }));

      }

      else if (stateName == "ActivityId") {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            [stateName]: value.id,
            "Activity": value

          }
        }));

      } else if (stateName == 'typetimeentries') {

        if(value.name == 2){
          this.setState({ disabledHour: true })
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              "hours": '0',
            }
          }));
        }else{
          this.setState({ disabledHour: false })
        }

      } else if (stateName == "sameOrigin") {
        this.setState(prevState => ({
          itemLocation: {
            ...prevState.itemLocation,
            [stateName]: value
          }
        }));
        if (value == true) {

          this.setState(prevState => ({
            itemLocation: {
              ...prevState.itemLocation,
              'streetadditional': this.state.itemLocation.streetsource,
              'complementadditional': this.state.itemLocation.complementsource,
              'neighborhoodadditional': this.state.itemLocation.neighborhoodsource,
              'cityadditional': this.state.itemLocation.citysource,
              'stateadditional': this.state.itemLocation.statesource,
              'countryadditional': this.state.itemLocation.countrysource,
              'latitudeadditional': this.state.itemLocation.latitudesource,
              'longitudeadditional': this.state.itemLocation.longitudesource,
              'zipcodeadditional': this.state.itemLocation.zipcodesource,
              'numberadditional': this.state.itemLocation.numbersource
            }
          }));
        } else {
          this.setState(prevState => ({
            itemLocation: {
              ...prevState.itemLocation,
              'streetadditional': "",
              'complementadditional': "",
              'neighborhoodadditional': "",
              'cityadditional': "",
              'stateadditional': "",
              'countryadditional': "",
              'latitudeadditional': "",
              'longitudeadditional': "",
              'zipcodeadditional': "",
              'numberadditional': ""
            }
          }));
        }
      } else {
        this.setState(prevState => ({

          item: {
            ...prevState.item,
            [stateName]: value
          }
        }));
      }

    }

    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  locationDeleteModal = async () => {
    const intl = this.props.intl;
    if (this.state.LocationListForDelete && this.state.LocationListForDelete.length > 0) {

      this.setState({ loading: true });

      var locationList = [];
      var locationDeleteList = this.state.LocationListForDelete.split(",");

      locationDeleteList.map(itemDelete => {
        if (itemDelete != "") {
          Api.delete('/calledtimeentries/location/'+itemDelete)
            .then(result => {

            })
            .catch(err => {

            });
          let returnok = this.state.LocationList.filter(el => el.id != itemDelete)
          returnok.map(item => {
            if (locationList.filter(el => el.id != item.id)) {
              locationList.push(item);
            }
          })
        }
      })

      const intl = this.props.intl;
      this.setState({
        loading: false,
        LocationList: locationList,
        openNotification: true,
        notificationVariant: 'success',
        notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
        LocationListForDelete: ""
      }, () => {
        let sumHours = 0.0
        this.state.LocationList.map(item => {
          sumHours += this.formatedInHour(item.timedisplacement) + this.formatedInHour(item.timeattendence) + this.formatedInHour(item.timedisplacementback)
        })

        this.setState(prevState => ({
          item: {
            ...prevState.item,
            "hours": this.convertTohoursFormat(sumHours)
          }
        }));
      })

    } else {
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"select.location"})
      })
    }
  }

  openLocationInsertModal = async () => {
    this.setState({
      openLocationInsertModal: !this.state.openLocationInsertModal
    })
  }

  checkValidationTabs = () => {
    const intl = this.props.intl;
    const item = this.state.itemLocation;

    if (this.state.activeStep == 1) {
      if (item.timedisplacement != null) {
        if (item.timedisplacement.length > 0) {
          let reg = new RegExp(/^[0-9]{2}\:[0-9]{1}$|^[0-9]{1}\:[0-9]{2}$|^[0-9]{1}\:[0-9]{1}$|^[0-9]{2}\:[0-9]{2}$|^[0-9]{2}$|^[0-9]{1}$/gm)
          let testReg = reg.test(item.timedisplacement)
          if(!testReg) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: intl.formatMessage({id:"called.time.entries.registration.invalid.displacement.time"})
            });
            return false
          }
        }
      }
      if (item.timeattendence != null) {
        if (item.timeattendence.length > 0) {
          let reg = new RegExp(/^[0-9]{2}\:[0-9]{1}$|^[0-9]{1}\:[0-9]{2}$|^[0-9]{1}\:[0-9]{1}$|^[0-9]{2}\:[0-9]{2}$|^[0-9]{2}$|^[0-9]{1}$/gm)
          let testReg = reg.test(item.timeattendence)
          if(!testReg) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: intl.formatMessage({id:"called.time.entries.registration.invalid.attendance.time"})
            });
            return false
          }
        }
      }
    } else {
      if (item.timedisplacementback != null) {
        if (item.timedisplacementback.length > 0) {
          let reg = new RegExp(/^[0-9]{2}\:[0-9]{1}$|^[0-9]{1}\:[0-9]{2}$|^[0-9]{1}\:[0-9]{1}$|^[0-9]{2}\:[0-9]{2}$|^[0-9]{2}$|^[0-9]{1}$/gm)
          let testReg = reg.test(item.timedisplacementback)
          if(!testReg) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: intl.formatMessage({id:"called.time.entries.registration.invalid.displacement.return.time"})
            });
            return false
          }
        }
      }
    }

    if (item.zipcodesource == "" ||
      item.numbersource == "" ||
      item.streetsource == "" ||
      item.neighborhoodsource == "" ||
      item.citysource == "" ||
      item.statesource == "" ||
      item.countrysource == "") {
      this.setState({
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"called.time.entries.registration.missing.origin.field"}),
      })
      return false
    } else if ((item.latitudesource == "" ||
    item.longitudesource == "") && item.zipcodesource != "") {
      this.setState({
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: "Você precisa fornecer um CEP válido na aba 'Origem'",
      })
      return false
    } else if (item.zipcodetarget == "" ||
      item.numbertarget == "" ||
      item.streettarget == "" ||
      item.neighborhoodtarget == "" ||
      item.citytarget == "" ||
      item.statetarget == "" ||
      item.countrytarget == "") {
      this.setState({
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: intl.formatMessage({id:"called.time.entries.registration.missing.destination.field"}),
      })
      return false
    } else if ((item.latitudetarget == "" ||
    item.longitudetarget == "") && item.zipcodetarget != "") {
      this.setState({
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: "Você precisa fornecer um CEP válido na aba 'Destino'",
      })
      return false
    } else{
      return true
    }
  }

  formatedInHour = (item) => {
    if(item.split(":")[1]){
      return parseFloat(item.split(":")[1])/60 + parseFloat(item.split(":")[0]);
    }else{
      return parseFloat(item);
    }
    
  }

  saveLocationInsertModal = async (e) => {

    e.preventDefault();

    if (this.checkValidationTabs()) {
      if (this.state.activeStep == 1) {
        this.setState({ activeStep: 2 })
        return;
      }

      let timeDispla = this.formatedInHour(this.state.itemLocation.timedisplacement)
      let timeAttendenc = this.formatedInHour(this.state.itemLocation.timeattendence)
      let timeBack = this.formatedInHour(this.state.itemLocation.timedisplacementback)
      
      let total = (timeDispla + timeAttendenc + timeBack).toString().split(".");
      let hour = total[0];
      let minute = total[1] ? (total[1]*6).toString() : "00";
      if(minute.length > 2){
        minute = minute.substr(0, 2)
      }

      /*this.setState(prevState => ({
        item: {
          ...prevState.item,
          ["hours"]: this.convertTohoursFormat(timeDispla + timeAttendenc + timeBack)
        }
      }));*/

      this.setState({
        loading: true
      })

      //var item = this.state.LocationList;
      var item = new Array();

      var newLocation = {
        id: Math.random(),
        calledtimeentriesid: 0,
        latitudesource: this.state.itemLocation.latitudesource,
        longitudesource: this.state.itemLocation.longitudesource,
        zipcodesource: this.state.itemLocation.zipcodesource,
        numbersource: this.state.itemLocation.numbersource,
        streetsource: this.state.itemLocation.streetsource,
        complementsource: this.state.itemLocation.complementsource,
        neighborhoodsource: this.state.itemLocation.neighborhoodsource,
        citysource: this.state.itemLocation.citysource,
        statesource: this.state.itemLocation.statesource,
        countrysource: this.state.itemLocation.countrysource,

        latitudetarget: this.state.itemLocation.latitudetarget,
        longitudetarget: this.state.itemLocation.longitudetarget,
        zipcodetarget: this.state.itemLocation.zipcodetarget,
        numbertarget: this.state.itemLocation.numbertarget,
        streettarget: this.state.itemLocation.streettarget,
        complementtarget: this.state.itemLocation.complementtarget,
        neighborhoodtarget: this.state.itemLocation.neighborhoodtarget,
        citytarget: this.state.itemLocation.citytarget,
        statetarget: this.state.itemLocation.statetarget,
        countrytarget: this.state.itemLocation.countrytarget,

        latitudeadditional: this.state.itemLocation.latitudeadditional,
        longitudeadditional: this.state.itemLocation.longitudeadditional,
        zipcodeadditional: this.state.itemLocation.zipcodeadditional,
        numberadditional: this.state.itemLocation.numberadditional,
        streetadditional: this.state.itemLocation.streetadditional,
        complementadditional: this.state.itemLocation.complementadditional,
        neighborhoodadditional: this.state.itemLocation.neighborhoodadditional,
        cityadditional: this.state.itemLocation.cityadditional,
        stateadditional: this.state.itemLocation.stateadditional,
        countryadditional: this.state.itemLocation.countryadditional,

        timedisplacement: (this.formatedInHour(this.state.itemLocation.timedisplacement)),
        timeattendence:  (this.formatedInHour(this.state.itemLocation.timeattendence)),
        timedisplacementback:  (this.formatedInHour(this.state.itemLocation.timedisplacementback)),

        starttime: this.state.itemLocation.starttime,
      }

      if(this.decimalPlaces(newLocation.timedisplacement) > 4) {
        var precision = 4 + this.nonDecimalPlaces(newLocation.timedisplacement)
        newLocation.timedisplacement = newLocation.timedisplacement.toPrecision(precision)
      }
      if(this.decimalPlaces(newLocation.timeattendence) > 4) {
        var precision = 4 + this.nonDecimalPlaces(newLocation.timeattendence)
        newLocation.timeattendence = newLocation.timeattendence.toPrecision(precision)
      }
      if(this.decimalPlaces(newLocation.timedisplacementback) > 4) {
        var precision = 4 + this.nonDecimalPlaces(newLocation.timedisplacementback)
        newLocation.timedisplacementback = newLocation.timedisplacementback.toPrecision(precision)
      }

      let dataLocation = new FormData();
      dataLocation.append('calledtimeentriesid', this.state.item.id);
      dataLocation.append('gotobase', this.state.item.typeBack == 1 ? true : false);

      for (let key in newLocation) {
        dataLocation.append(key, newLocation[key]);
      }

      var result = await this.handleSubmitLocation(e, dataLocation);
    }



  }

  setLocationDelete = async (org) => {
    console.log(org)
    console.log("setLocationDelete")
    this.setState({
      LocationListForDelete: org + "," + this.state.LocationListForDelete
    })
  }

  backStep = () => {
    this.setState({
      activeStep: 1
    })
  }

  handleChangeTab = (event, value) => {
    this.setState({
      activeTab: value
    })
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { item, itemLocation } = this.state;
    const intl = this.props.intl;

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }

    return (
      <div className={classes.root}>
        <HeaderMenu />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant='outlined' color='primary' onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component='h1' variant='h5'>
                {<FormattedMessage id="add.item" />} {<FormattedMessage id="appointment.hours" />}
              </Typography>

              <Dialog
                fullWidth
                maxWidth="lg"
                open={this.state.openLocationInsertModal}
                onClose={this.closeLocationModal}
                aria-labelledby="form-dialog-title-org-mat"
                PaperProps={{
                  style: {
                    overflowX: 'hidden',
                  }
                }} >
                <form onSubmit={this.saveLocationInsertModal}>
                  <DialogTitle id="form-dialog-title-org-mat">
                    Nova(s) Localizações(s)
                    <ClearIcon onClick={this.openLocationInsertModal} style={{ float: 'right', cursor: 'pointer' }} />
                  </DialogTitle>
                  <Stepper alternativeLabel activeStep={this.state.activeStep}>

                    <Step key={1} >
                      <StepLabel >{intl.formatMessage({id:"called.time.entries.registration.where.to"})}</StepLabel>
                    </Step>
                    <Step key={2}  >
                      <StepLabel > {intl.formatMessage({id:"called.time.entries.registration.did.what.after.called"})}</StepLabel>
                    </Step>
                  </Stepper>

                  <Container component='main' maxWidth='md'>

                    {this.state.activeStep == 1 && (

                      <Grid style={{ marginBottom: "10px" }} container spacing={2}>
                        <Grid item xs={1} sm={1}></Grid>
                        <Grid item xs={2} sm={2}>
                          <TextField
                            id="starttime"
                            name="starttime"
                            label="Horário de saida"
                            type="time"
                            defaultValue="08:00"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 60, // 1 min
                            }}
                            onChange={this.handleInputChangeLocation}
                          />
                        </Grid>
                        <Grid item xs={3} sm={3}>
                          <TextField fullWidth label={intl.formatMessage({id:"called.time.entries.registration.displacement.time"})}  variant='outlined' name='timedisplacement' onChange={this.handleInputChangeLocation} value={itemLocation.timedisplacement} >
                            <InputMask mask={this.hourMask}  />
                          </TextField>
                        </Grid>
                        <Grid item xs={3} sm={3}>
                          <TextField fullWidth label={intl.formatMessage({id:"called.time.entries.registration.attendance.time"})}  variant='outlined' name='timeattendence' onChange={this.handleInputChangeLocation} value={itemLocation.timeattendence} >
                            <InputMask mask={this.hourMask}  />
                          </TextField>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <Tabs
                            value={this.state.activeTab}
                            onChange={this.handleChangeTab}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                          >
                            <Tab label="Origem" {...a11yProps(0)} />
                            <Tab label="Destino" {...a11yProps(1)} />

                          </Tabs>

                          <TabPanel value={this.state.activeTab} index={0}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12}>
                                <TextField inputProps={{ maxLength: 8 }} fullWidth label={intl.formatMessage({ id: "cep" })} autoComplete='fname' variant='outlined' name='zipcodesource' onChange={this.handleInputChangeLocation} value={itemLocation.zipcodesource} required />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "street" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='streetsource' onChange={this.handleInputChangeLocation} value={itemLocation.streetsource} required />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "number" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='numbersource' onChange={this.handleInputChangeLocation} value={itemLocation.numbersource} required />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "complement" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='complementsource' onChange={this.handleInputChangeLocation} value={itemLocation.complementsource} />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "neighborhood" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='neighborhoodsource' onChange={this.handleInputChangeLocation} value={itemLocation.neighborhoodsource} required />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "city" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='citysource' onChange={this.handleInputChangeLocation} value={itemLocation.citysource} required />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "state" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='statesource' onChange={this.handleInputChangeLocation} value={itemLocation.statesource} required />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "country" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='countrysource' onChange={this.handleInputChangeLocation} value={itemLocation.countrysource} />
                              </Grid>
                              <Grid item xs={12} sm={12} style={{ display: 'none' }}>
                                <TextField fullWidth label={intl.formatMessage({ id: "longitude" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='longitudesource' onChange={this.handleInputChangeLocation} value={itemLocation.longitudesource} />
                              </Grid>
                              <Grid item xs={12} sm={12} style={{ display: 'none' }}>
                                <TextField fullWidth label={intl.formatMessage({ id: "latitude" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='latitudesource' onChange={this.handleInputChangeLocation} value={itemLocation.latitudesource} />
                              </Grid>
                            </Grid>
                          </TabPanel>

                          <TabPanel value={this.state.activeTab} index={1}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12}>
                                <TextField inputProps={{ maxLength: 8 }} fullWidth label={intl.formatMessage({ id: "cep" })} autoComplete='fname' variant='outlined' name='zipcodetarget' onChange={this.handleInputChangeLocation} value={itemLocation.zipcodetarget} required />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "street" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='streettarget' onChange={this.handleInputChangeLocation} value={itemLocation.streettarget} required />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "number" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='numbertarget' onChange={this.handleInputChangeLocation} value={itemLocation.numbertarget} required />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "complement" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='complementtarget' onChange={this.handleInputChangeLocation} value={itemLocation.complementtarget} />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "neighborhood" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='neighborhoodtarget' onChange={this.handleInputChangeLocation} value={itemLocation.neighborhoodtarget} required />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "city" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='citytarget' onChange={this.handleInputChangeLocation} value={itemLocation.citytarget} required />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "state" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='statetarget' onChange={this.handleInputChangeLocation} value={itemLocation.statetarget} required />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "country" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='countrytarget' onChange={this.handleInputChangeLocation} value={itemLocation.countrytarget} />
                              </Grid>
                              <Grid item xs={12} sm={12} style={{ display: 'none' }}>
                                <TextField fullWidth label={intl.formatMessage({ id: "longitude" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='longitudetarget' onChange={this.handleInputChangeLocation} value={itemLocation.longitudetarget} />
                              </Grid>
                              <Grid item xs={12} sm={12} style={{ display: 'none' }}>
                                <TextField fullWidth label={intl.formatMessage({ id: "latitude" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='latitudetarget' onChange={this.handleInputChangeLocation} value={itemLocation.latitudetarget} />
                              </Grid>
                            </Grid>
                          </TabPanel>
                        </Grid>
                      </Grid>
                    )}

                    {this.state.activeStep == 2 && (
                      <>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12}>
                            <SimpleSelect
                              label={intl.formatMessage({id:"called.time.entries.registration.did.what.after.called"})}
                              options={this.state.typeBack}
                              stateName='typeBack'
                              changeSelect={this.changeValues}
                              selected={this.state.item.typeBack}
                              required={true}
                            />
                          </Grid>

                        {this.state.item.typeBack == 1 && (
                          <>
                              <Grid item xs={3} sm={3}>
                                <TextField fullWidth label={intl.formatMessage({id:"called.time.entries.registration.displacement.return.time"})}  variant='outlined' name='timedisplacementback' onChange={this.handleInputChangeLocation} value={itemLocation.timedisplacementback} >
                                  <InputMask mask={this.hourMask}  />
                                </TextField>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <SimpleCheckBox label={intl.formatMessage({id:"called.time.entries.registration.same.origin.location"})} name='sameOrigin' stateName='sameOrigin' changeSelect={this.changeValues} selected={this.state.itemLocation.sameOrigin} />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField inputProps={{ maxLength: 8 }} fullWidth label={intl.formatMessage({ id: "cep" })} autoComplete='fname' variant='outlined' name='zipcodeadditional' onChange={this.handleInputChangeLocation} value={itemLocation.zipcodeadditional} required />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "street" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='streetadditional' onChange={this.handleInputChangeLocation} value={itemLocation.streetadditional} required />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "number" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='numberadditional' onChange={this.handleInputChangeLocation} value={itemLocation.numberadditional} required />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "complement" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='complementadditional' onChange={this.handleInputChangeLocation} value={itemLocation.complementadditional} />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "neighborhood" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='neighborhoodadditional' onChange={this.handleInputChangeLocation} value={itemLocation.neighborhoodadditional} required />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "city" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='cityadditional' onChange={this.handleInputChangeLocation} value={itemLocation.cityadditional} required />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "state" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='stateadditional' onChange={this.handleInputChangeLocation} value={itemLocation.stateadditional} required />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField fullWidth label={intl.formatMessage({ id: "country" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='countryadditional' onChange={this.handleInputChangeLocation} value={itemLocation.countryadditional} />
                              </Grid>
                              <Grid item xs={12} sm={12} style={{ display: 'none' }}>
                                <TextField fullWidth label={intl.formatMessage({ id: "longitude" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='longitudeadditional' onChange={this.handleInputChangeLocation} value={itemLocation.longitudeadditional} />
                              </Grid>
                              <Grid item xs={12} sm={12} style={{ display: 'none' }}>
                                <TextField fullWidth label={intl.formatMessage({ id: "latitude" })} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='latitudeadditional' onChange={this.handleInputChangeLocation} value={itemLocation.latitudeadditional} />
                              </Grid>
                          </>
                        )}
                        </Grid>
                      </>
                    )}
                  </Container>

                  {this.state.activeStep == 1 && (
                    <Grid spacing={2} container justify="flex-end" style={{ padding: 15, justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={4} style={{ justifyContent: 'flex-end' }}>
                        <Button
                          variant='contained'
                          type="button"
                          onClick={this.openLocationInsertModal}
                          fullWidth >
                          {this.props.intl.formatMessage({id:"cancel"})}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Button
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                        //onClick={this.saveLocationInsertModal}
                        >
                          {intl.formatMessage({id:"go.next"})}
                        </Button>
                      </Grid>
                    </Grid>
                  )}

                  {this.state.activeStep == 2 && (
                    <Grid spacing={2} container justify="flex-end" style={{ padding: 15, justifyContent: 'flex-end' }}>
                      <Grid item xs={12} sm={4}>
                        <Button
                          variant='contained'
                          type="button"
                          onClick={this.backStep}
                          fullWidth
                        >
                          {this.props.intl.formatMessage({id:"back"})}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Button
                          type='submit'
                          fullWidth
                          variant='contained'
                          color='primary'
                        //onClick={this.saveLocationInsertModal}
                        >
                          {<FormattedMessage id="save" />}
                        </Button>
                      </Grid>
                    </Grid>
                  )}

                </form>
              </Dialog>

              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>


                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3} style={{ marginLeft: '10px' }} >
                    <SimpleDatePicker style={{ float: 'left' }} align="left" label="Data" name='dateStart' stateName='dateStart' changeDate={this.changeValues} currentDate={item.dateStart} />
                    <Grid item xs={12} sm={9}></Grid>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField disabled={this.state.disabledHour} fullWidth label={intl.formatMessage({ id: "hours" })}  variant='outlined' name='hours' onChange={this.handleInputChange} value={item.hours} >
                      <InputMask mask={this.hourMask}  />
                    </TextField>
                  </Grid>
                  <Grid item xs={6} sm={9}>
                    <SimpleRadio
                      seletected={this.state.item.typetimeentries}
                      options={this.state.typetimeentriesList}
                      changeValues={this.changeValues}
                      label={intl.formatMessage({id:"report.type"})}
                      name={"typetimeentries"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      label={intl.formatMessage({ id: "comment" })}
                      inputProps={{ maxLength: 255 }}

                      variant="outlined"
                      name="comment"
                      onChange={this.handleInputChange}
                      value={item.comment}

                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {/*<SimpleAutoComplete label={intl.formatMessage({ id: "user" })} options={item.users} stateName='UserId' changeSelect={this.changeValues} selected={item.User} />*/}
					<SimpleAutoCompleteSearch label={intl.formatMessage({ id: "keypress.search.instruction" })+" "+intl.formatMessage({ id: "user" })} options={item.users} 
											stateName='UserId' changeSelect={this.changeValues} selected={item.User} 
											urlapi={Api.apiurlbase()+`api/users/getusers/category/${this.state.item.organizationCategoryId}/organization/${this.state.item.organizationId}/name`} />

                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <SimpleAutoComplete label={intl.formatMessage({ id: "activity" })} options={item.activities} stateName='ActivityId' changeSelect={this.changeValues} selected={item.Activity} />
                  </Grid>

                {this.state.item.typetimeentriesid == 2 && (
                  <Grid item xs={12} sm={12}>
                  <Accordion defaultExpanded={true} style={{ marginTop: "6px", marginBottom: "10px" }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>{intl.formatMessage({id:"user.registration.select.locations"})}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container xs={12} sm={12} >

                        <Grid xs={12} sm={12} style={{ margin: '5px', padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }} >
                          <Grid xs={12} sm={4} >

                          </Grid>
                          <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                            <DialogConfirmation
                              msgButton={intl.formatMessage({id:"user.client.edit.remove.location"})}
                              msg={intl.formatMessage({id:"user.client.edit.confirm.remove.location"})}
                              msgDisagree={this.props.intl.formatMessage({id:"cancel"})}
                              msgAgree={this.props.intl.formatMessage({id:"yes"})}
                              handleConfirmation={e => this.locationDeleteModal()}
                              classes={classes}
                              icon={<DeleteIcon />}
                            />
                          </Grid>
                          <Grid xs={12} sm={4} style={{ margin: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              className={classes.button}
                              startIcon={<AddIcon />}
                              onClick={e => this.openLocationInsertModal()} >
                              {intl.formatMessage({id:"user.client.edit.add.locations"})}
                            </Button>
                          </Grid>
                        </Grid>

                        <Grid xs={12} sm={12}
                          style={{
                            marginLeft: 5,
                            marginBottom: 5,
                            border: '3px solid #eee'
                          }} >
                          <div style={{ height: 350 }}>
                            <DataGrid
                              style={{ fontSize: 14, color: '#000' }}
                              className="DataGridStyle"
                              components={{
                                NoRowsOverlay: this.NoDataRows,
                                Toolbar: GridToolbarFilterButton,
                              }}
                              rows={this.state.LocationList}
                              rowsPerPageOptions={[5]}
                              checkboxSelection
                              onSelectionModelChange={itm => {
                                if (itm.length > 0) {
                                  this.setLocationDelete(itm);
                                } else {
                                  this.setState({
                                    LocationListForDelete: [],
                                  })
                                }
                              }}
                              columns={[
                                { headerName: intl.formatMessage({id:"called.time.entries.registration.displacement.time.header"}), field: 'timedisplacement', width: 180 },
                                { headerName: intl.formatMessage({id:"called.time.entries.registration.attendance.time.header"}), field: 'timeattendence', width: 180 },
                                { headerName: intl.formatMessage({id:"called.time.entries.registration.displacement.return.time.header"}), field: 'timedisplacementback', width: 180 },
                                { headerName: intl.formatMessage({id:"called.time.entries.registration.origin.street.header"}), field: 'streetsource', width: 200 },
                                { headerName: intl.formatMessage({id:"called.time.entries.registration.origin.number.header"}), field: 'numbersource', width: 180 },
                                { headerName: intl.formatMessage({id:"called.time.entries.registration.destination.street.header"}), field: 'streettarget', width: 200 },
                                { headerName: intl.formatMessage({id:"called.time.entries.registration.destination.number.header"}), field: 'numbertarget', width: 180 },
                              ]}
                              HeadersVisibility="None"
                            />
                          </div>
                        </Grid>
                      </Grid>

                    </AccordionDetails>
                  </Accordion>
                  </Grid>
                )}


                  <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Grid container justify="flex-end" spacing={2} style={{ justifyContent: 'flex-end' }}>
                  <Grid item xs={12} sm={4} >
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                </Grid>
                </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>
        </main>
      </div>
    );
  }
}

CalledTimeEntriesEdit.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
  calleds: state.calleds,
  timeentries: state.timeentries,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps,mapDispatchToProps)(withStyles(Styles)(CalledTimeEntriesEdit)));
