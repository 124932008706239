import Api from './api';

export const getByConfigId = async (configId) => {
    let data = []
    await Api.get(`/emailcustomsettings/${configId}`)
        .then(result => {
            data = result.data
        })
        .catch(err => {
            data = err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : err.message ? err.message : null
        })
    return data
}

export const editItem = async (item) => {
    let data = []
    await Api.put('/emailcustomsettings', item)
        .then(result => {
            data = result.data
        })
        .catch(err => {
            data = err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : err.message ? err.message : null
        })
    return data
}

export const addItem = async (formData) => { 
  let data = []
  await Api.post(`/emailcustomsettings`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : err.message ? err.message : null
    })
  return data
}

export const deleteLogoImage = async (emailCustomSettingsId) => {
  let data = []
  await Api.put(`/emailcustomsettings/deletelogoimage/${emailCustomSettingsId}`)
    .then(result => {
      if (result.data.success) {
        data = result.data
      } else {
        data = result.errors ? result.errors[0] : null
      }
    })
    .catch(err => {
      data = err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : err.message ? err.message : null
    })
  return data
}