import React from 'react';
import Logo from '../../images/me-feedback/me-new-3.jpg';

const MELogo = props => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
            src={props.src && props.src.length > 0 ? props.src : Logo}
            style={props.style != null ? props.style : {  }}
            title="FeedbackLogo"
            className='App-ME-Logo' />
    </div>
);

export default MELogo;
