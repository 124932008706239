import React from 'react';
import { Grid} from '@mui/material';
import MaterialTable from 'material-table';
import PropTypes from "prop-types";
import  { MTableToolbar } from 'material-table';
import { FormattedMessage,injectIntl } from 'react-intl';

function MyMaterialTable(props) {

  const { title, urledit,columns ,data, options, deleteItem, rowClick } = props;
  var opt = options;
  if(opt == null){
    opt={
      sorting: true,
      pageSize:10,
      showSelectAllCheckbox:true,
      columnsButton:true,
      toolbar:true,
      headerStyle: {
          backgroundColor: '#f9f9f9',
          fontWeight: 'bold',
          borderBottom: '2px solid #b3b3b3'
      }
    };
  }

  opt.toolbar = false;

  const handleDelete = (data,resolve) => {
    //console.log(data);
    deleteItem(data,resolve);

  };
  const intl = props.intl;
  return (
    <Grid container spacing={0} >
    <Grid item xs={12} sm={12} style={{display: 'table', tableLayout:'fixed', width:'100%', minWidth:'210px',paddingLeft: 0, paddingRight: 0}}>
      {deleteItem == null ? (
          <MaterialTable
          title={title}
          columns={columns}
          data={data}
          options={opt}
          onRowClick={rowClick}
          
          components={{
            Toolbar: props => (
                <div style={{ backgroundColor: '#e8eaf5', color:'#000000' }}>
                    <MTableToolbar {...props} />
                </div>
            )
          }}          
          localization={{
              grouping: {
                groupedBy: intl.formatMessage({id:"grouped.by"}),
                placeholder: intl.formatMessage({id:"headers.drap"})
              },
              pagination: {
                labelDisplayedRows: '{from}-{to} '+intl.formatMessage({id:"of"}) +' {count}',
                labelRowsPerPage: intl.formatMessage({id:"recordsPerPage"}),
                labelRowsSelect: intl.formatMessage({id:"records"})
              },
              toolbar: {
                  addRemoveColumns: intl.formatMessage({id:"add.remove"}),
                  nRowsSelected: '{0}' + intl.formatMessage({id:"rows.select"}),
                  showColumnsTitle: intl.formatMessage({id:"show.columns"}),
                  showColumnsAriaLabel: intl.formatMessage({id:"show.columns"}),
                  exportTitle: intl.formatMessage({id:"export"}),
                  exportAriaLabel: intl.formatMessage({id:"export"}),
                  exportName: intl.formatMessage({id:"export.csv"}),
                  searchTooltip: intl.formatMessage({id:"search"}),
                  searchPlaceholder: intl.formatMessage({id:"search"})
              },
              header: {
                actions: ''          
              },
              body: {
                emptyDataSourceMessage: intl.formatMessage({id:"rows.show"}),
                filterRow: {},
                editRow: {
                  saveTooltip: intl.formatMessage({id:"save"}),
                  cancelTooltip: intl.formatMessage({id:"cancel"}),
                  deleteText: intl.formatMessage({id:"sure.delete"})
                },
                addTooltip: intl.formatMessage({id:"add"}),
                deleteTooltip: intl.formatMessage({id:"delete"}),
                editTooltip: intl.formatMessage({id:"update"})
              }
            }}                            
      />
      ) : 
      (

          <MaterialTable
          title={title}
          columns={columns}
          data={data}
          options={opt}
          onRowClick={rowClick}
          
          editable={{
              onRowDelete: oldData =>
                new Promise(resolve => {
                    handleDelete(oldData, resolve);
                    
                })
              //,onRowUpdate: (newData, oldData) =>
              //new Promise(resolve => {
              //    resolve();
              //}),
          }}
          
          components={{
            Toolbar: props => (
                <div style={{ backgroundColor: '#e8eaf5', color:'#000000' }}>
                    <MTableToolbar {...props} />
                </div>
            )
          }}          
          localization={{
            grouping: {
              groupedBy: intl.formatMessage({id:"grouped.by"}),
              placeholder: intl.formatMessage({id:"headers.drap"})
            },
            pagination: {
              labelDisplayedRows: '{from}-{to} '+intl.formatMessage({id:"of"}) +' {count}',
              labelRowsPerPage: intl.formatMessage({id:"recordsPerPage"}),
              labelRowsSelect: intl.formatMessage({id:"records"})
            },
            toolbar: {
                addRemoveColumns: intl.formatMessage({id:"add.remove"}),
                nRowsSelected: '{0}' + intl.formatMessage({id:"rows.select"}),
                showColumnsTitle: intl.formatMessage({id:"show.columns"}),
                showColumnsAriaLabel: intl.formatMessage({id:"show.columns"}),
                exportTitle: intl.formatMessage({id:"export"}),
                exportAriaLabel: intl.formatMessage({id:"export"}),
                exportName: intl.formatMessage({id:"export.csv"}),
                searchTooltip: intl.formatMessage({id:"search"}),
                searchPlaceholder: intl.formatMessage({id:"search"})
            },
            header: {
              actions: ''          
            },
            body: {
              emptyDataSourceMessage: intl.formatMessage({id:"rows.show"}),
              filterRow: {},
              editRow: {
                saveTooltip: intl.formatMessage({id:"save"}),
                cancelTooltip: intl.formatMessage({id:"cancel"}),
                deleteText: intl.formatMessage({id:"sure.delete"})
              },
              addTooltip: intl.formatMessage({id:"add"}),
              deleteTooltip: intl.formatMessage({id:"delete"}),
              editTooltip: intl.formatMessage({id:"update"})
            }
            }}                            
      />

      )}
      
    </Grid>
    
  </Grid>  
  );
}

export default injectIntl( MyMaterialTable);

MyMaterialTable.propTypes = {
  title: PropTypes.string,
  urledit: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  options: PropTypes.object,
  deleteItem: PropTypes.func,
  rowClick: PropTypes.func,
};

