import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container, Dialog } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import CustomizedSnackbars from '../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as componentActions from '../../redux/actions/securitys';
import { Link } from 'react-router-dom';
import SimpleDatePicker from '../../components/date-pickers/inline';
import * as Service from '../../services/userapipermission.service';
import { parseJSON } from 'date-fns';
import { IconButton, Tooltip } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage, injectIntl } from 'react-intl';
import { PapiroConsole } from '../../utils/papiroConsole';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import { getAllApiPermissionPolicies } from "../../services/apipermissionpolicy.service";
import 'react-quill/dist/quill.snow.css'; // ES6
import { Menu, MenuItem } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

class SecurityEdit extends Component {

    constructor(props) {
        const intl = props.intl;
        super(props);
        const { userSession, securitys } = this.props;

        PapiroConsole.log(securitys)
        PapiroConsole.log("securitys")
        PapiroConsole.log(this.props)
        PapiroConsole.log("props")

        this.globalAdm = false;
        this.isClient = true;

        if (userSession.user.userrole == null) {
            Api.logoff();
        }

        userSession.user.userrole.map((item, key) => {
            if (item.role.roletype !== 3) {
                this.isClient = false;
            }
            if (item.role.roletype === 0) {
                this.globalAdm = true;
            }
        });



        this.initialState = {
            //id: units.unit.id,
            updatedUser: userSession.user.id,
            updatedAt: moment().format('YYYY/MM/DD HH:mm:ss'),
            hirerId: userSession.user.hirerid,
            accessKeyEntryConfirm: '',
            tokenDays: 0
            //permanent : securitys.security.permanent

        };

        this.state = {
            item: this.initialState,
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            id: securitys.security.id ? securitys.security.id : 0,
            selectedValue: securitys.security.permanent ? 'permanent' : 'temporary',
            temporaryValue: securitys.security.days ? securitys.security.days : 0,
            token: securitys.security.token ? securitys.security.token : null,
            accesskey: securitys.security.accesskeyid ? securitys.security.accesskeyid : '',
            apiPermissionPoliciesCheckedArray: securitys.security.userapipermissionpolicy ? securitys.security.userapipermissionpolicy : [],
            apiPermissionPolicies: [],
            anchorEl: null,
            active: securitys.security.active ? securitys.security.active : false,
            dialogDeleteItem: false,
            dialogActivateOrDeactivateItem: false,
            dialogGenerateToken: false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.closeNotification = this.closeNotification.bind(this);
        this.openOrClosedialogDeleteItem = this.openOrClosedialogDeleteItem.bind(this);

        this.openOrClosedialoActivateOrDeactivateItem = this.openOrClosedialoActivateOrDeactivateItem.bind(this);


        this.openOrClosedialogGenerateToken = this.openOrClosedialogGenerateToken.bind(this);


        this.generateToken = this.generateToken.bind(this);

        this.handleCopyClick = this.handleCopyClick.bind(this);

        this.ellipsisString = this.ellipsisString.bind(this);








    } // Fim - construtor

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    componentDidMount() {
        this.getApiPermissionPolicies()
    }


    handleCopyClick(link) {


        navigator.clipboard.writeText(link).then(() => {
            console.log('Link copied to clipboard:', link);
        }).catch(error => {
            console.error('Error copying link:', link);
        });
    }


    ellipsisString(text) {
        var maxLength = 80;
        const truncatedText = text.substring(0, maxLength - 3) + '...';

        return truncatedText;
    }


    async getApiPermissionPolicies() {

        this.setState({ loading: true });
        var result = await getAllApiPermissionPolicies();
        PapiroConsole.log(result)
        PapiroConsole.log("result aqui")
        if (result.success) {

            let apiPermissionPolicies = result.data
            apiPermissionPolicies.forEach(item1 => {
                const matchingItem = this.state.apiPermissionPoliciesCheckedArray.find(item2 => item2.apipermissionpolicyid === item1.id);
                PapiroConsole.log("matchingItem")
                PapiroConsole.log(matchingItem)
                if (matchingItem) {

                    item1.checked = true
                    // Execute a ação desejada para os itens com IDs correspondentes aqui
                }
            });
            this.setState({ loading: false, apiPermissionPolicies: apiPermissionPolicies });



        } else {
            const intl = this.props.intl;
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage:
                    result.response && result.response.data && result.response.data.errors
                        ? result.response.data.errors[0]
                        : intl.formatMessage({ id: "process.error" }),
            });

            Api.kickoff(result);
        }


    }

    checkAccessKeyValidation() {

        let validation = true

        if (this.state.item.accessKeyEntryConfirm != this.state.accesskey) {

            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: "A chave de acesso informada está incorreta"

            });
            validation = false

        }

        return validation;
    }

    updateItem = async e => {
        e.preventDefault();

        this.setState({ loading: true });

        let data = new FormData();


        for (let key in this.state.item) {
            data.append(key, this.state.item[key])
        }

        data.append("id", this.state.id)
        data.append("active", !this.state.active)

        let response = await Service.editItem(data)
        if (response && response.success) {
            const intl = this.props.intl;
            this.openOrClosedialoActivateOrDeactivateItem()
            this.setState({
                active: !this.state.active,
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({ id: "edit.success" }),

            });
        } else {
            const intl = this.props.intl
            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            })
            Api.kickoff(response)
        }



    };


    generateToken = async e => {

        if (this.state.item.tokenDays <= 0) {

            this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'error',
                notificationMessage: "A quantidade de dias não pode ser menor ou igual a 0"
            })

        }
        else {
            const intl = this.props.intl;
            e.preventDefault()
            this.setState({ loading: true })
            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
            let data = new FormData()


            data.append("AccessKeyId", this.state.accesskey);

            for (let key in this.state.item) {
                data.append(key, this.state.item[key])
            }


            Api.post('/token/create-token', data, config)

                .then(result => {
                    PapiroConsole.log("resultado")
                    PapiroConsole.log(result)
                    if (result.data.success) {
                        PapiroConsole.log("resultado")
                        PapiroConsole.log(result)
                        this.openOrClosedialogGenerateToken()

                        this.setState({
                            token: result.data.data.accesstoken,
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'success',
                            notificationMessage: intl.formatMessage({ id: "add.success" })
                        });

                    }
                    else {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'error',
                            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })

                        });
                        Api.kickoff(result);
                    }

                })
                .catch(err => {
                    PapiroConsole.log(err)
                    PapiroConsole.log("err aqui")
                    if (err.response && err.response.data && err.response.data.errors) {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'error',
                            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" })
                        });
                    }
                    else {
                        this.setState({
                            loading: false,
                            openNotification: true,
                            notificationVariant: 'error',
                            notificationMessage: err.response.data.errors ? err.response.data.errors[0] : this.props.intl.formatMessage({ id: "process.error" }) + err.response.data
                        });
                    }
                    Api.kickoff(err);
                });

        }




    }



    deleteItem = async () => {

        this.setState({ loading: true });

        let validation = this.checkAccessKeyValidation()
        if (validation) {

            let response = await Service.deleteItem(this.state.id)
            if (response && response.success) {
                const intl = this.props.intl;
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: 'success',
                    notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
                    resultList: []
                });

                this.openOrClosedialogDeleteItem()

                setTimeout(() => {
                    // Coloque a ação que deseja executar após o atraso aqui
                    this.props.history.push({
                        pathname: `/${this.props.match.params.lang}/security`,
                    })

                }, 2000);

            } else {

                const intl = this.props.intl
                this.setState({
                    loading: false,
                    openNotification: true,
                    notificationVariant: 'error',
                    notificationMessage: response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                })
                Api.kickoff(response)
            }

        }

    };

    handleInputChange(e) {
        const name = e.target.name;
        const value = e.target.value;

        PapiroConsole.log(name)
        PapiroConsole.log(value)

        PapiroConsole.log("name")
        PapiroConsole.log("value")

        this.setState(prevState => ({
            item: {
                ...prevState.item,
                [name]: value
            }
        }));
    };



    closeNotification() {
        this.setState({ openNotification: false });
    }

    handleOpenMenu = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleCloseMenu = () => {
        this.setState({ anchorEl: null });
    };

    openOrClosedialogDeleteItem = (e) => {
        PapiroConsole.log(e)
        if (e != null) {
            e.preventDefault()
        }
        this.setState({ dialogDeleteItem: !this.state.dialogDeleteItem })
    }


    openOrClosedialoActivateOrDeactivateItem = (e) => {
        PapiroConsole.log(e)
        if (e != null) {
            e.preventDefault()
        }
        this.setState({ dialogActivateOrDeactivateItem: !this.state.dialogActivateOrDeactivateItem })
    }

    openOrClosedialogGenerateToken = (e) => {
        PapiroConsole.log(e)
        if (e != null) {
            e.preventDefault()
        }
        this.setState({ dialogGenerateToken: !this.state.dialogGenerateToken })
    }

    render() {
        const { classes, headerMenu, actionsHeaderMenu } = this.props;
        const { item } = this.state;
        const intl = this.props.intl;

        if (Api == null) {
            var l = this.props.match.params.lang;
            document.location.href = `/${l}/unavailable`
        }

        return (
            <div className={classes.root}>
                <HeaderMenu />
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: headerMenu.open,
                    }, classes.actionsContent, {
                        [classes.actionscontentShift]: actionsHeaderMenu.open,
                    })}
                >

                    <div className={classes.drawerHeader} />

                    <Container component='main' maxWidth='md'>
                        <CssBaseline />
                        <div className={classes.paper} style={{ marginTop: 0 }}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} sm={4}>
                                    <Button variant='outlined' color='primary' onClick={() => browserHistory.goBack()}
                                    >{<FormattedMessage id="back" />}</Button>
                                </Grid>
                                <Grid item xs={12} sm={8} />
                            </Grid>
                            <Avatar className={classes.avatar}>
                                <KeyIcon />
                            </Avatar>
                            <Typography component='h1' variant='h5'>
                                {<FormattedMessage id="edit.item" />}
                            </Typography>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" gutterBottom fontWeight="bold">
                                        {<FormattedMessage id="access.key" />}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        {this.state.accesskey}
                                    </Typography>
                                </Grid>



                                <Grid item xs={12}>

                                    <Typography variant="subtitle2" gutterBottom fontWeight="bold">
                                        {<FormattedMessage id="access.token.expiration" />}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Escolha quando essa Chave de acesso vai expirar.
                                    </Typography>
                                </Grid>




                                <Box>

                                    <Box sx={{ p: 2 }}>
                                        <FormControl component="fieldset">

                                            <RadioGroup
                                                aria-label="radio-options"
                                                name="radio-options"
                                                value={this.state.selectedValue}
                                            //onChange={this.handleChangeRadio}
                                            >
                                                <FormControlLabel
                                                    value="permanent"
                                                    control={<Radio />}
                                                    label="Nunca"
                                                    disabled
                                                />
                                                <FormControlLabel
                                                    value="temporary"
                                                    control={<Radio />}
                                                    disabled
                                                    label={
                                                        <div>
                                                            {intl.formatMessage({ id: "select.the.number.of.days" })}
                                                            {this.state.selectedValue === 'temporary' && (
                                                                <TextField
                                                                    disabled
                                                                    name="temporary"
                                                                    type="number"
                                                                    value={this.state.temporaryValue}
                                                                    //onChange={this.handleChangeRadio}
                                                                    sx={{
                                                                        marginLeft: '20px',
                                                                        border: 'none',  // Remover a borda

                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    }
                                                />

                                            </RadioGroup>
                                        </FormControl>

                                    </Box>
                                    <Box sx={{ p: 2 }}>

                                        <Typography variant="subtitle2" gutterBottom fontWeight="bold">
                                            {<FormattedMessage id="available.permissions" />}
                                        </Typography>

                                        {this.state.apiPermissionPolicies.length > 0 ?

                                            this.state.apiPermissionPolicies.map((item) => (
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        disabled
                                                        key={item.id}
                                                        control={
                                                            <Checkbox
                                                                checked={item.checked || false}
                                                            //onChange={() => this.handleCheckboxChange(item.id)}
                                                            />
                                                        }
                                                        label={`${item.description} - ${item.name} `}
                                                    />
                                                </Grid>

                                            )) : null}
                                    </Box>

                                </Box>





                                {/*<Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                                    <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                                        <Button
                                            onClick={this.handleOpenMenu}
                                            endIcon={<MoreVertIcon />}

                                            variant='contained'
                                            color='primary'
                                            className={classes.submit}
                                        >
                                            Ações
                                        </Button>
                                        <Menu
                                            anchorEl={this.state.anchorEl}
                                            open={Boolean(this.state.anchorEl)}
                                            onClose={this.handleCloseMenu}
                                        >
                                            <MenuItem onClick={this.openOrClosedialogDeleteItem}>Excluir</MenuItem>
                                            <MenuItem onClick={this.openOrClosedialoActivateOrDeactivateItem} disabled={!this.state.active}>Desativar</MenuItem>
                                            <MenuItem onClick={this.openOrClosedialoActivateOrDeactivateItem} disabled={this.state.active}>Ativar</MenuItem>
                                        </Menu>
                                    </Grid>
                                                    </Grid>*/}
                                {this.state.token != null && this.state.token != '' &&
                                    <Grid item sm={12} >
                                        <Typography variant="subtitle2" gutterBottom fontWeight="bold">
                                            {<FormattedMessage id="access.token" />}
                                        </Typography>
                                        <Tooltip title="Copy Link">
                                            <IconButton onClick={() => this.handleCopyClick(this.state.token)}>
                                                <FileCopyIcon />
                                                <Typography variant="body2" gutterBottom style={{ paddingTop: '3px' }}>
                                                    {this.ellipsisString(this.state.token)}
                                                </Typography>
                                            </IconButton>
                                        </Tooltip>


                                    </Grid>
                                }

                                <Grid container style={{marginTop : '20px'}}  >
                                    <Grid item sm={4} >

                                        <Button
                                            variant='contained'
                                            color='primary'
                                            onClick={this.openOrClosedialogGenerateToken}
                                        >
                                            {this.state.token != null && this.state.token != '' ?
                                                <FormattedMessage id="renew.token" />
                                                :
                                                <FormattedMessage id="generate.token" />
                                            }
                                        </Button>

                                    </Grid>

                                    <Grid item sm={4} >



                                    </Grid>

                                    <Grid item sm={4} >
                                        <Button
                                            onClick={this.handleOpenMenu}
                                            endIcon={<MoreVertIcon />}

                                            variant='contained'
                                            color='primary'

                                        >
                                            Ações
                                        </Button>
                                        <Menu
                                            anchorEl={this.state.anchorEl}
                                            open={Boolean(this.state.anchorEl)}
                                            onClose={this.handleCloseMenu}
                                        >
                                            <MenuItem onClick={this.openOrClosedialogDeleteItem}>Excluir</MenuItem>
                                            <MenuItem onClick={this.openOrClosedialoActivateOrDeactivateItem} disabled={!this.state.active}>Desativar</MenuItem>
                                            <MenuItem onClick={this.openOrClosedialoActivateOrDeactivateItem} disabled={this.state.active}>Ativar</MenuItem>
                                        </Menu>

                                    </Grid>
                                </Grid>



                            </Grid>


                        </div>

                        <Dialog
                            fullWidth={true}
                            maxWidth={"md"}
                            open={this.state.dialogDeleteItem}
                            onClose={this.openOrClosedialogDeleteItem}
                            aria-labelledby="delete.item" >

                            <DialogTitle id="delete.item">

                                <FormattedMessage id="delete.access.key" />
                            </DialogTitle>
                            <DialogContent >
                                <DialogContentText  >
                                    <Typography variant="subtitle2" gutterBottom>
                                        Excluir a chave de acesso {this.state.accesskey} ? Uma chave desativada não pode ser utilizada. Mas você pode ativa-la novamente mais tarde.

                                    </Typography>

                                    <Typography variant="subtitle2" gutterBottom style={{ marginTop: '15px' }}>
                                        Para confirmar a exclusão insira a id da chave de acesso no campo de entrada de texto

                                    </Typography>
                                    <TextField
                                        style={{ marginTop: '5px' }}
                                        name="accessKeyEntryConfirm"
                                        label="Digite algo"
                                        variant="outlined"
                                        value={this.state.item.accessKeyEntryConfirm}
                                        onChange={this.handleInputChange}
                                        fullWidth
                                    />

                                </DialogContentText>


                            </DialogContent>
                            <DialogActions>

                                <Button onClick={this.openOrClosedialogDeleteItem} color="secondary">
                                    {intl.formatMessage({ id: "close" })}
                                </Button>
                                <Button variant='contained' color='primary' onClick={this.deleteItem} type="submit">
                                    {intl.formatMessage({ id: "confirm" })}
                                </Button>

                            </DialogActions>
                        </Dialog>

                        <Dialog
                            fullWidth={true}
                            maxWidth={"md"}
                            open={this.state.dialogActivateOrDeactivateItem}
                            onClose={this.openOrClosedialoActivateOrDeactivateItem}
                            aria-labelledby="delete.item" >

                            <DialogTitle id="delete.item">

                                {this.state.active ? <FormattedMessage id="deactivate.access.key" />
                                    : <FormattedMessage id="activate.access.key" />
                                }


                            </DialogTitle>
                            <DialogContent >
                                <DialogContentText  >
                                    <Typography variant="subtitle2" gutterBottom>
                                        {this.state.active ? 'Desativar' : 'Ativar'} a chave de acesso {this.state.accesskey} ? Uma chave desativada não pode ser utilizada. Mas você pode ativa-la novamente mais tarde.

                                    </Typography>


                                </DialogContentText>


                            </DialogContent>
                            <DialogActions>

                                <Button onClick={this.openOrClosedialoActivateOrDeactivateItem} color="secondary">
                                    {intl.formatMessage({ id: "close" })}
                                </Button>
                                <Button variant='contained' color='primary' onClick={this.updateItem} type="submit">
                                    {intl.formatMessage({ id: "confirm" })}
                                </Button>

                            </DialogActions>
                        </Dialog>

                        <Dialog
                            fullWidth={true}
                            maxWidth={"md"}
                            open={this.state.dialogGenerateToken}
                            onClose={this.openOrClosedialogGenerateToken}
                            aria-labelledby="generate.token" >

                            <DialogTitle id="generate.token">
                                {this.state.token != null && this.state.token != '' ?
                                    <FormattedMessage id="renew.token" />
                                    :
                                    <FormattedMessage id="generate.token" />
                                }

                            </DialogTitle>
                            <DialogContent >
                                <DialogContentText  >
                                    <Typography variant="subtitle2" gutterBottom>

                                        {this.state.token != null && this.state.token != '' ?
                                            <FormattedMessage id="renew.token.dialog" />
                                            :
                                            <FormattedMessage id="generate.token.dialog" />
                                        }

                                    </Typography>

                                    <Typography variant="body2" gutterBottom style={{ marginTop: '20px' }}>
                                        <FormattedMessage id="choose.expiration.token" />
                                    </Typography>
                                    <TextField

                                        name="tokenDays"
                                        type="number"
                                        value={this.state.item.tokenDays}
                                        onChange={this.handleInputChange}

                                    />
                                </DialogContentText>


                            </DialogContent>
                            <DialogActions>

                                <Button onClick={this.openOrClosedialogGenerateToken} color="secondary">
                                    {intl.formatMessage({ id: "close" })}
                                </Button>
                                <Button variant='contained' color='primary' onClick={this.generateToken} type="submit">
                                    {intl.formatMessage({ id: "confirm" })}
                                </Button>

                            </DialogActions>
                        </Dialog>
                        {this.state.loading && (
                            <Overlay>
                                <CircularProgress color='secondary' />
                            </Overlay>
                        )}

                        <CustomizedSnackbars
                            variant={this.state.notificationVariant}
                            message={this.state.notificationMessage}
                            isOpen={this.state.openNotification}
                            toClose={this.closeNotification}
                        />
                    </Container>

                </main>
            </div>
        )
    }
}

SecurityEdit.propTypes = { classes: PropTypes.object.isRequired };

const mapStateToProps = state => ({
    actionsHeaderMenu: state.actionsHeaderMenu,
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    securitys: state.securitys
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(SecurityEdit)));