import React, { memo, useEffect, useState } from 'react'
import { PapiroConsole } from '../../../utils/papiroConsole'
import '../styles.css'
import { Grid, Typography, Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, TextField, Tooltip  } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import ClearIcon from '@mui/icons-material/Clear';
import PropTypes from 'prop-types'
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment'
import { Overlay } from '../../../styles/global';
import CustomizedSnackbars from '../../../components/material-snackbars';
import Api from '../../../services/api';
import * as OrganizationFieldReportBlockItemService from '../../../services/organizationfieldreportblockitems.service'
import { DndItemContainer } from './DnDItemContainer'
import AddIcon from '@mui/icons-material/Add';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SaveIcon from '@mui/icons-material/Save';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SimpleCheckBox from '../../../components/checkbox/check';
import SimpleAutoComplete from '../../../components/auto-complete/autocomplete';
import { getAllActiveByOrganizationIdWithoutIncludes, getOrganizationsByIdLight } from '../../../services/organizationfield.service'
import * as OrganizationChecklistService from '../../../services/organizationchecklist.service'

function OrganizationFieldBlockItemDialog(props) {
  
  // 1 = LIST | 2 = LIST ALL | 3 = ADD | 4 = EDIT | 5 = DELETE
  const [dialogStep, setDialogStep] = useState(1)
  const [open, setOpen] = useState(props.open)

  const addItemInitialState = {
    active: false,
    sequence: 0,
    organizationId: props.organization,
    organizationfieldId: 0,
    createdUser: props.userId,
    createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
  }

  const editItemInitialState = {
    id: 0,
    active: false,
    sequence: 0,
    organizationId: props.organization,
    organizationfieldId: 0,
    updatedUser: props.userId,
    updatedAt: moment().format('YYYY/MM/DD HH:mm:ss'),
  }

  const deleteItemInitialState = {
    id: 0,
    organizationfieldText: ''
  }

  const [organizationList, setOrganizationList] = useState([[props.organizationFieldBlock]])

  const [organizationFieldBlockItemList, setOrganizationFieldBlockItemList] = useState([])
  const [allOrganizationFieldBlockItemList, setAllOrganizationFieldBlockItemList] = useState([])

  const [searchValue, setSearchValue] = useState('')

  const [addItem, setAddItem] = useState(addItemInitialState)
  const [editItem, setEditItem] = useState(editItemInitialState)
  const [deleteItem, setDeleteItem] = useState(deleteItemInitialState)

  const [organizationFieldBlock, setOrganizationFieldBlock] = useState(props.organizationFieldBlock)
  const [loading, setLoading] = useState(false)

  const [organizationFieldList, setOrganizationFieldList] = useState([])
  
  const [addOrganizationFieldBlockOrganizationFieldReportBlockId, setAddOrganizationFieldBlockOrganizationFieldReportBlockId] = useState(props.organizationFieldBlock)
  const [addOrganizationFieldBlockOrganizationFieldId, setAddOrganizationFieldBlockOrganizationFieldId] = useState(0)
  const [addOrganizationFieldBlockActive, setAddOrganizationFieldBlockActive] = useState(false)

  const [editOrganizationFieldBlockId, setEditOrganizationFieldBlockId] = useState(0)
  const [editOrganizationFieldBlockOrganizationFieldReportBlockId, setEditOrganizationFieldBlockOrganizationFieldReportBlockId] = useState(props.organizationFieldBlock)
  const [editOrganizationFieldBlockOrganizationFieldId, setEditOrganizationFieldBlockOrganizationFieldId] = useState(0)
  const [editOrganizationFieldBlockActive, setEditOrganizationFieldBlockActive] = useState(false)

  const intl = props.intl
  const classes = props.classes

  useEffect(() => {
    setOpen(props.open)

    if (props.open) {
      getActiveByOrganizationFieldReportBlockIdWithoutIncludesOrderBySequence()
      getActiveOrganizationFieldsByOrganizationId()
      getAllOrganizationFieldBLockItemsByOrganizationFieldBlockId()
    }
  }, [props.open])

  useEffect(() => {
    
    if (props.organizationFieldBlock != null) {
      setOrganizationFieldBlock(props.organizationFieldBlock)
      setAddOrganizationFieldBlockOrganizationFieldReportBlockId(props.organizationFieldBlock)
      setOrganizationList([props.organizationFieldBlock])
    }
  }, [props.organizationFieldBlock])

  const clearAddData = _ => {
    setAddOrganizationFieldBlockOrganizationFieldReportBlockId(props.organizationFieldBlock)
    setAddOrganizationFieldBlockOrganizationFieldId(0)
    setAddOrganizationFieldBlockActive(false)
  }

  const setEditData = (item) => {
    setEditOrganizationFieldBlockId(item.id)
    setEditOrganizationFieldBlockOrganizationFieldReportBlockId(props.organizationFieldBlock)
    setEditOrganizationFieldBlockOrganizationFieldId(item.organizationfield)
    setEditOrganizationFieldBlockActive(item.active)
  }

  const clearEditData = _ => {
    setEditOrganizationFieldBlockId(0)
    setEditOrganizationFieldBlockOrganizationFieldReportBlockId(props.organizationFieldBlock)
    setEditOrganizationFieldBlockOrganizationFieldId(0)
    setEditOrganizationFieldBlockActive(false)
  }

  const handleSearchFiltertOrganizationFieldBlockItem = async () => {
    if (props.organizationFieldBlock && props.organizationFieldBlock.id && props.organizationFieldBlock.id > 0) {
      if (searchValue && searchValue.length > 0) {
        setLoading(true)
        let formData = new FormData();
        formData.append("organizationfieldid", 0)
        formData.append("organizationfieldreportblockid", props.organizationFieldBlock.id)
        formData.append("title", searchValue)

        let result = await OrganizationFieldReportBlockItemService.getAllWithFilter(formData)
        if (result && result.success) {
          if (result.data && result.data.length > 0) {
            let formmatedList = result.data.map(item => {
              return {
                ...item,
                organizationfieldname: item?.organizationfield?.name,
                organizationfieldreportblockname: item?.organizationfieldreportblock?.title,
                activetext: item.active ? this.props.intl.formatMessage({id:"yes"}) : this.props.intl.formatMessage({id:"no"})
              }
            })
            setAllOrganizationFieldBlockItemList(formmatedList)
          }
          setLoading(false)
        } else {
          props.setErrorMessage(true, 'error', result && result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
          setLoading(false)
          Api.kickoff(result)
        }
      } else {
        props.setErrorMessage(true, 'error', intl.formatMessage({id:"value.to.search"}))
      }
    }
  }

  const getActiveOrganizationFieldsByOrganizationId = async () => {
    if (props.organization && props.organization.id && props.organization.id > 0) {
      setLoading(true)

      let result = await getAllActiveByOrganizationIdWithoutIncludes(props.organization.id)
      if (result && result.success) {
        if (result.data && result.data.length > 0) {
          setOrganizationFieldList(result.data)
        }
        setLoading(false)
      } else {
        props.setErrorMessage(true, 'error', result && result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
        setLoading(false)
        Api.kickoff(result)
      }
    }
  }

  const getActiveByOrganizationFieldReportBlockIdWithoutIncludesOrderBySequence = async () => {
    if (props.organizationFieldBlock && props.organizationFieldBlock.id && props.organizationFieldBlock.id > 0) {
      PapiroConsole.log("=== getActiveByOrganizationFieldReportBlockIdWithoutIncludesOrderBySequence ===")
      PapiroConsole.log(props.organizationFieldBlock)
      setLoading(true)

      let result = await OrganizationFieldReportBlockItemService.getActiveByOrganizationFieldReportBlockIdWithoutIncludesOrderBySequence(props.organizationFieldBlock.id)
      if (result && result.success) {
        if (result.data && result.data.length > 0) {
          PapiroConsole.log(result.data)
          setOrganizationFieldBlockItemList(result.data)
        }
        setLoading(false)
      } else {
        props.setErrorMessage(true, 'error', result && result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
        setLoading(false)
        Api.kickoff(result)
      }

    }
  }

  const getAllOrganizationFieldBLockItemsByOrganizationFieldBlockId = async () => {
    if (props.organizationFieldBlock && props.organizationFieldBlock.id && props.organizationFieldBlock.id > 0) {
      PapiroConsole.log("=== getAllOrganizationFieldBLockItemsByOrganizationFieldBlockId ===")
      setLoading(true)

      let result = await OrganizationFieldReportBlockItemService.getByOrganizationFieldReportBlockId(props.organizationFieldBlock.id)
      if (result && result.success) {
        PapiroConsole.log("getByOrganizationFieldReportBlockId")
        PapiroConsole.log(result)
        if (result.data && result.data.length > 0) {
          let formmatedList = result.data.map(item => {
            return {
              ...item,
              organizationfieldname: item?.organizationfield?.name,
              organizationfieldreportblockname: item?.organizationfieldreportblock?.title,
              activetext: item.active ? this.props.intl.formatMessage({id:"yes"}) : this.props.intl.formatMessage({id:"no"})
            }
          })

          setAllOrganizationFieldBlockItemList(formmatedList)
        }
        setLoading(false)
      } else {
        props.setErrorMessage(true, 'error', result && result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
        setLoading(false)
        Api.kickoff(result)
      }
    }
  }

  const addOrganizationFieldBlockItem = async () => {
    setLoading(true)

    if (addOrganizationFieldBlockOrganizationFieldReportBlockId == null || (addOrganizationFieldBlockOrganizationFieldReportBlockId && (addOrganizationFieldBlockOrganizationFieldReportBlockId.id == null || addOrganizationFieldBlockOrganizationFieldReportBlockId.id == 0))) {
      props.setErrorMessage(true, 'error', intl.formatMessage({id:"called.report.config.edit.organization.field.block.item.missing.custom.field.block"}))
      setLoading(false)
    }
    if (addOrganizationFieldBlockOrganizationFieldId == null || (addOrganizationFieldBlockOrganizationFieldId && (addOrganizationFieldBlockOrganizationFieldId.id == null || addOrganizationFieldBlockOrganizationFieldId.id == 0))) {
      props.setErrorMessage(true, 'error', intl.formatMessage({id:"called.report.config.edit.organization.field.block.item.missing.custom.field"}))
      setLoading(false)
    }
    if (addOrganizationFieldBlockActive == null) {
      props.setErrorMessage(true, 'error', intl.formatMessage({id:"called.report.config.edit.organization.field.block.item.missing.active.field"}))
      setLoading(false)
    }

    var data = new FormData();

    data.append('organizationfieldreportblockid', addOrganizationFieldBlockOrganizationFieldReportBlockId.id);
    data.append('organizationfieldid', addOrganizationFieldBlockOrganizationFieldId.id)
    data.append('active', addOrganizationFieldBlockActive)
    data.append('sequence', 0)
    data.append('createduser', props.userId)

    let config = { headers: { "Content-Type": "multipart/form-data" } }

    let result = await OrganizationFieldReportBlockItemService.addItem(data, config)
    if (result && result.success) {
      props.setErrorMessage(true, 'success', intl.formatMessage({id:"add.success"}))
      clearAddData()
      await getActiveByOrganizationFieldReportBlockIdWithoutIncludesOrderBySequence()
      await getAllOrganizationFieldBLockItemsByOrganizationFieldBlockId()
    } else {
      props.setErrorMessage(true, 'error', result && result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      setLoading(false)
      Api.kickoff(result)
    }
  }

  const editOrganizationFieldBlockItem = async () => {
    setLoading(true)

    if (editOrganizationFieldBlockOrganizationFieldReportBlockId == null || (editOrganizationFieldBlockOrganizationFieldReportBlockId && (editOrganizationFieldBlockOrganizationFieldReportBlockId.id == null || editOrganizationFieldBlockOrganizationFieldReportBlockId.id == 0))) {
      props.setErrorMessage(true, 'error', intl.formatMessage({id:"called.report.config.edit.organization.field.block.item.missing.custom.field.block"}))
      setLoading(false)
    }
    if (editOrganizationFieldBlockOrganizationFieldId == null || (editOrganizationFieldBlockOrganizationFieldId && (editOrganizationFieldBlockOrganizationFieldId.id == null || editOrganizationFieldBlockOrganizationFieldId.id == 0))) {
      props.setErrorMessage(true, 'error', intl.formatMessage({id:"called.report.config.edit.organization.field.block.item.missing.custom.field"}))
      setLoading(false)
    }
    if (editOrganizationFieldBlockActive == null) {
      props.setErrorMessage(true, 'error', intl.formatMessage({id:"called.report.config.edit.organization.field.block.item.missing.active.field"}))
      setLoading(false)
    }

    var data = new FormData();

    data.append('id', editOrganizationFieldBlockId)
    data.append('organizationfieldreportblockid', editOrganizationFieldBlockOrganizationFieldReportBlockId.id);
    data.append('organizationfieldid', editOrganizationFieldBlockOrganizationFieldId.id)
    data.append('active', editOrganizationFieldBlockActive)
    data.append('sequence', 0)
    data.append('createduser', props.userId)

    let config = { headers: { "Content-Type": "multipart/form-data" } }

    let result = await OrganizationFieldReportBlockItemService.editItem(data, config)
    if (result && result.success) {
      props.setErrorMessage(true, 'success', intl.formatMessage({id:"add.success"}))
      clearEditData()
      await getActiveByOrganizationFieldReportBlockIdWithoutIncludesOrderBySequence()
      await getAllOrganizationFieldBLockItemsByOrganizationFieldBlockId()
    } else {
      props.setErrorMessage(true, 'error', result && result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      setLoading(false)
      Api.kickoff(result)
    }
  }

  const deleteOrganizationFieldBlockItem = async () => {
    setLoading(true)

    let result = await OrganizationFieldReportBlockItemService.deleteItem(deleteItem.id)
    if (result && result.success) {
      props.setErrorMessage(true, 'success', intl.formatMessage({id:"delete.sucess"}))
      setDeleteItem(deleteItemInitialState)
      await getActiveByOrganizationFieldReportBlockIdWithoutIncludesOrderBySequence()
      await getAllOrganizationFieldBLockItemsByOrganizationFieldBlockId()
      setDialogStep(1)
    } else {
      props.setErrorMessage(true, 'error', result && result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      setLoading(false)
      Api.kickoff(result)
    }
  }

  const handleSetOrganizationFieldBlockItemList = (items) => {
    setOrganizationFieldBlockItemList(items)
  }

  const handleOpenDeleteOrganizationFieldBlockDialog = (id, title) => {
    setDeleteItem({ id: id, organizationfieldText: title })
    setSearchValue('')
    setDialogStep(5)
  }

  const handleOpenEditOrganizationFieldBlockDialog = (item) => {
    PapiroConsole.log("=== handleOpenEditOrganizationFieldBlockDialog ===")
    PapiroConsole.log(item)
    setEditData(item)
    setSearchValue('')
    setDialogStep(4)
  }

  const updateOrderOrganizationFieldBlockItem = async () => {
    let list = organizationFieldBlockItemList

    if (list && list.length > 0) {
      setLoading(true)

      let ids = ''
      let orders = ''

      list.forEach((item, i) => {
        ids = ids.length === 0 ? `${item.id}` : `${ids},${item.id}`
        orders = orders.length === 0 ? `${i+1}` : `${orders},${i+1}`
      })

      var data = new FormData();

      data.append('ids', ids);
      data.append('order', orders);

      let config = { headers: { "Content-Type": "multipart/form-data" } }

      let result = await OrganizationFieldReportBlockItemService.updateOrder(data, config)
      if (result && result.success) {
        props.setErrorMessage(true, 'success', intl.formatMessage({id:"edit.success"}))
        setLoading(false)
      } else {
        props.setErrorMessage(true, 'error', result && result.response && result.response.data && result.response.data.errors && result.response.data.errors[0] ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
        setLoading(false)
        Api.kickoff(result)
      }
    }
  }

  const onClose = () => {
    props.onClose()
    setDialogStep(1)
    setOrganizationList([])
    setDeleteItem(deleteItemInitialState)
    setOrganizationFieldBlockItemList([])
    setAllOrganizationFieldBlockItemList([])
    setSearchValue('')
    clearAddData()
    clearEditData()
  }

  return (
    <Dialog
      maxWidth="md"
      fullWidth={true}
      open={open}
      onClose={() => onClose()}
      aria-labelledby="organizationfield-block-item-dialog-title">
      <DialogTitle id="organizationfield-block-item-dialog-title">
          {dialogStep == 1 && (<Typography style={{ float: 'left' }} ><FormattedMessage id="called.report.config.organization.field.item.list.title" />: {props.organizationFieldBlock && props.organizationFieldBlock.title ? props.organizationFieldBlock.title : ''}</Typography>)}
          {dialogStep == 2 && (<Typography style={{ float: 'left' }} ><FormattedMessage id="called.report.config.organization.field.item.list.all.title" />: {props.organizationFieldBlock && props.organizationFieldBlock.title ? props.organizationFieldBlock.title : ''}</Typography>)}
          {dialogStep == 3 && (<Typography style={{ float: 'left' }} ><FormattedMessage id="called.report.config.organization.field.item.add.title" /></Typography>)}
          {dialogStep == 4 && (<Typography style={{ float: 'left' }} ><FormattedMessage id="called.report.config.organization.field.item.edit.title" /></Typography>)}
          {dialogStep == 5 && (<Typography style={{ float: 'left' }} ><FormattedMessage id="called.report.config.organization.field.item.delete.title" /></Typography>)}
        <ClearIcon onClick={() => onClose()} style={{ float: 'right', cursor: 'pointer' }} />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={0}>
          {dialogStep == 1 && (
            <Grid container spacing={0} style={{ marginTop: 15 }}>
              <Grid item xs={8} md={8} sm={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  startIcon={<AddIcon />}
                  onClick={() => setDialogStep(3)} >
                  <FormattedMessage id="called.report.config.add.organization.field.block.item" />
                </Button>
              </Grid>
              <Grid item xs={2} md={2} sm={12} >
                <Tooltip title={<h5><FormattedMessage id="called.report.config.organization.field.block.list.all" /></h5>}>
                  <IconButton aria-label='config-all-org-field-blocks'
                    onClick={() => setDialogStep(2)} style={{ backgroundColor: '#277AFE', color: '#FFF' }} >
                    <FormatListBulletedIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={2} md={2} sm={12} >
                <Tooltip title={<h5><FormattedMessage id="called.report.config.organization.field.block.item.list.save.order" /></h5>}>
                  <IconButton aria-label='config-save-org-field-block'
                    onClick={() => updateOrderOrganizationFieldBlockItem()} style={{ backgroundColor: '#3f51b4', color: '#FFF' }} >
                    <SaveIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <DndItemContainer
                organizationFieldBlockItemList={organizationFieldBlockItemList}
                setOrganizationFieldBlockItemList={handleSetOrganizationFieldBlockItemList}
                openDeleteOrganizationFieldBlockDialog={handleOpenDeleteOrganizationFieldBlockDialog}
                openEditOrganizationFieldBlockDialog={handleOpenEditOrganizationFieldBlockDialog} />
            </Grid>
          )}
          {dialogStep == 2 && (
            <Grid container spacing={0} style={{ marginTop: 15 }}>
              <Grid xs={12} sm={12} style={{ marginLeft: 5, marginTop: 20, padding: 10, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                <Grid xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                  <TextField type="text"
                    name="searchValueListTableCustomFieldItem"
                    value={searchValue}
                    placeholder={intl.formatMessage({ id: "search" })}
                    onChange={(e) => setSearchValue(e.target.value)} />
                </Grid>
                <Grid xs={12} sm={1}></Grid>
                <Grid xs={12} sm={2} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.button}
                    startIcon={<SearchIcon />}
                    onClick={() => {
                      setSearchValue('')
                      handleSearchFiltertOrganizationFieldBlockItem()
                    }} >
                    {intl.formatMessage({id:"search"})}
                  </Button>
                </Grid>
                <Grid xs={12} sm={1}></Grid>
                <Grid xs={12} sm={2} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.button}
                    startIcon={<ClearIcon />}
                    onClick={() => getAllOrganizationFieldBLockItemsByOrganizationFieldBlockId()} >
                    {intl.formatMessage({id:"clear"})}
                  </Button>
                </Grid>
              </Grid>
              <Grid xs={12} sm={12}
                style={{
                  marginLeft: 5,
                  marginBottom: 5,
                  border: '3px solid #eee'
                }} >
                <div style={{ height: 400 }}>
                  <DataGrid
                    style={{ fontSize: 12, color: '#000' }}
                    className="DataGridStyleCallReportEdit"
                    rows={allOrganizationFieldBlockItemList}
                    rowsPerPageOptions={[5]}
                    columns={[
                      {
                        headerName: intl.formatMessage({ id: "delete" }),
                        field: 'data',
                        width: 120,
                        renderCell: (params) => (
                          <IconButton aria-label="delete" color="primary" onClick={() => handleOpenDeleteOrganizationFieldBlockDialog(params.row.id, params.row.title)}>
                            <DeleteIcon />
                          </IconButton>
                        )
                      },
                      {
                        headerName: intl.formatMessage({ id: "edit" }),
                        field: 'editdata',
                        width: 120,
                        renderCell: (params) => (
                          <IconButton aria-label="edit" color="primary" onClick={() => handleOpenEditOrganizationFieldBlockDialog(params.row)}>
                            <EditIcon />
                          </IconButton>
                        )
                      },
                      { headerName: intl.formatMessage({ id: "organizationfield" }), field: 'organizationfieldname', width: 400 },
                      { headerName: intl.formatMessage({ id: "organization.field.report.block" }), field: 'organizationfieldreportblockname', width: 300 },
                      { headerName: intl.formatMessage({ id: "active" }), field: 'activetext', width: 120 },
                    ]} />
                </div>
              </Grid>
            </Grid>
          )}
          {dialogStep == 3 && (
          <Grid container spacing={0} style={{ marginTop: 15 }}>
            <Grid item xs={12} sm={12}>
              <SimpleAutoComplete
                label={intl.formatMessage({ id: 'organization.field.report.block' })}
                name='addOrganizationFieldBlockOrganizationFieldReportBlockId'
                options={organizationList}
                stateName='addOrganizationFieldBlockOrganizationFieldReportBlockId'
                changeSelect={(stateName, value, text = '') => setAddOrganizationFieldBlockOrganizationFieldReportBlockId(value)}
                selected={addOrganizationFieldBlockOrganizationFieldReportBlockId}
                disabled
                required />
            </Grid>
            <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
              <SimpleAutoComplete
                label={intl.formatMessage({ id: 'organizationfield' })}
                name='addOrganizationFieldBlockOrganizationFieldId'
                options={organizationFieldList}
                stateName='addOrganizationFieldBlockOrganizationFieldId'
                changeSelect={(stateName, value, text = '') => setAddOrganizationFieldBlockOrganizationFieldId(value)}
                selected={addOrganizationFieldBlockOrganizationFieldId}
                required />
            </Grid>
            <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
              <SimpleCheckBox
                label={intl.formatMessage({ id: 'active' })}
                name='active'
                stateName='active'
                changeSelect={(stateName, value, text = '') => setAddOrganizationFieldBlockActive(value)}
                selected={addOrganizationFieldBlockActive}
                required />
            </Grid>
          </Grid>)}
          {dialogStep == 4 && (
          <Grid container spacing={0} style={{ marginTop: 15 }}>
            <Grid item xs={12} sm={12}>
              <SimpleAutoComplete
                label={intl.formatMessage({ id: 'organization.field.report.block' })}
                name='editOrganizationFieldBlockOrganizationFieldReportBlockId'
                options={organizationList}
                stateName='editOrganizationFieldBlockOrganizationFieldReportBlockId'
                changeSelect={(stateName, value, text = '') => setEditOrganizationFieldBlockOrganizationFieldReportBlockId(value)}
                selected={editOrganizationFieldBlockOrganizationFieldReportBlockId}
                disabled
                required />
            </Grid>
            <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
              <SimpleAutoComplete
                label={intl.formatMessage({ id: 'organizationfield' })}
                name='editOrganizationFieldBlockOrganizationFieldId'
                options={organizationFieldList}
                stateName='editOrganizationFieldBlockOrganizationFieldId'
                changeSelect={(stateName, value, text = '') => setEditOrganizationFieldBlockOrganizationFieldId(value)}
                selected={editOrganizationFieldBlockOrganizationFieldId}
                required />
            </Grid>
            <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
              <SimpleCheckBox
                label={intl.formatMessage({ id: 'active' })}
                name='active'
                stateName='active'
                changeSelect={(stateName, value, text = '') => setEditOrganizationFieldBlockActive(value)}
                selected={editOrganizationFieldBlockActive}
                required />
            </Grid>
          </Grid>)}
          {dialogStep == 5 && (
          <Grid container spacing={0} style={{ marginTop: 15 }}>
            <Grid item xs={12} sm={12}>
              <FormattedMessage style={{ fontSize: '1.3em' }} id="called.report.config.organization.field.item.delete.subtitle" />
            </Grid>
          </Grid>)}
        </Grid>
      </DialogContent>
      <DialogActions>
        {(dialogStep == 2 || dialogStep == 3) && (<Button
          variant="contained"
          color="secondary"
          autoFocus
          onClick={() => {
            clearAddData()
            setDialogStep(1)
          }} >
          <FormattedMessage id="back" />
        </Button>)}
        {(dialogStep > 3) && (<Button
          variant="contained"
          color="secondary"
          autoFocus
          onClick={() => {
            clearEditData()
            setDialogStep(2)
            }} >
          <FormattedMessage id="back" />
        </Button>)}
        {(dialogStep == 1) && (
        <Button
          onClick={() => onClose()}
          variant="contained"
          color="secondary"
          autoFocus >
          <FormattedMessage id="cancel" />
        </Button>
        )}
        {(dialogStep == 3) && (<Button
          variant="contained"
          color="primary"
          onClick={() => addOrganizationFieldBlockItem()} >
          <FormattedMessage id="save" />
        </Button>)}
        {(dialogStep == 4) && (<Button
          variant="contained"
          color="primary"
          onClick={() => editOrganizationFieldBlockItem()} >
          <FormattedMessage id="save" />
        </Button>)}
        {(dialogStep == 5) && (<Button
          variant="contained"
          color="primary"
          onClick={() => deleteOrganizationFieldBlockItem()} >
          <FormattedMessage id="confirm" />
        </Button>)}
      </DialogActions>
      {loading && (
        <Overlay>
          <CircularProgress color='secondary' />
        </Overlay>
      )}
    </Dialog>
  )
}

OrganizationFieldBlockItemDialog.propTypes = {
  userId: PropTypes.number,
  open: PropTypes.bool,
  organizationList: PropTypes.array,
  organizationFieldBlockItemList: PropTypes.array,
  allOrganizationFieldBlockItemList: PropTypes.array,
  onClose: PropTypes.func,
  searchFiltertOrganizationFieldBlockItem: PropTypes.func,
  getAllOrganizationFieldBLockItemsByOrganizationFieldBlockId: PropTypes.func,
  addOrganizationFieldBlockItem: PropTypes.func,
  editOrganizationFieldBlockItem: PropTypes.func,
  deleteOrganizationFieldBlockItem: PropTypes.func,
}

export default OrganizationFieldBlockItemDialog