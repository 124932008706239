import React from "react";
import PropTypes from "prop-types";
import {
    Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Autocomplete, Grid, Stepper, Step, StepLabel, Tabs,
    Tab
} from "@mui/material";
import { withStyles } from "@mui/styles";
import SimpleAutoComplete from "src/components/auto-complete/autocomplete";
import PhoneInput from 'react-phone-input-2'
import { newConversationModal, validatePhone } from '../../../../services/2talk/omnichannel'
import Api2Talk from "src/services/api2talk";
import { PapiroConsole } from "src/utils/papiroConsole";
import SimpleAutoCompleteSearch from '../../../../components/auto-complete/autocomplete-search-api';
import SimpleAutoCompleteSearchPhone from '../../../../components/auto-complete/autocomplete-search-api-client-phone-numbers';
import Api from "src/services/api";

const styles = (theme) => ({
    dialogTitle: {
        backgroundColor: "#3f51b4",
        color: "#ffffff",
        textAlign: "center",
        fontWeight: "bold",
        padding: "16px",
    },
    dialogContent: {
        backgroundColor: "#ffffff",
        padding: "24px",
        marginTop: "30px"
    },
    section: {
        marginBottom: "24px",
    },
    sectionTitle: {
        fontSize: "1.2rem",
        fontWeight: "bold",
        color: "#3f51b4",
    },
    sectionText: {
        backgroundColor: "#f3f3f3",
        padding: "12px",
        borderRadius: "4px",
        marginBottom: "8px",
        lineHeight: "1.6",
    },
    variableField: {
        marginBottom: "16px",
    },
    dialogActions: {
        backgroundColor: "#f5f5f5",
        padding: "16px",
    },
    buttonPrimary: {
        backgroundColor: "#3f51b4",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: "#2e3a99",
        },
    },
    buttonSecondary: {
        color: "#3f51b4",
        borderColor: "#3f51b4",
    },

    gridContainer: {
        maxHeight: "400px",       // Altura máxima do Grid
        overflowY: "auto",        // Ativa o scroll vertical
        padding: theme.spacing(2),
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#3F51B4',
            borderRadius: '8px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#2c387e',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            borderRadius: '8px',
        },
    },
    tabs: {
        justifyContent: "center",
    },



});

class CustomModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            messageProviderId: null,
            registrationNumberRequestId: null,
            templateMessageId: null,
            message: "",
            phone: "",
            selectedOption: null,
            bodyVariableValues: {},
            headerVariableValues: {},
            activeStep: 0,
            optinSelected: false,
            clientUsersList: [],
            phoneUsersList: [],
            clientUser: null,
            phoneUser: null,
            activeTab: 0,
            // phoneOptions: [
            //     { id: 1, number: "(11) 98765-4321" },
            //     { id: 2, number: "(21) 91234-5678" },
            //     { id: 3, number: "(31) 99876-5432" },
            //     { id: 4, number: "(41) 93456-7890" },
            //     { id: 5, number: "(51) 90000-1234" },
            //     { id: 6, number: "(61) 95555-6789" },
            // ],
            inputNumber: "",
        };
    }

    handleChangeAutocomplete = (stateName, value, textSelected) => {
        PapiroConsole.log("stateName")
        PapiroConsole.log(stateName)
        PapiroConsole.log("value")
        PapiroConsole.log(value)
        PapiroConsole.log("textSelected")
        PapiroConsole.log(textSelected)
        let data = value.id > 0 ? value : null;
        this.setState({
            [stateName]: data
        });
    };

    handleInputChange = (field, value) => {
        PapiroConsole.log("field")
        PapiroConsole.log(field)
        PapiroConsole.log("value")
        PapiroConsole.log(value)

        this.setState({ [field]: value });
    };



    handleChangeTemplateMessageAutocomplete = (stateName, value) => {
        let data = value.id > 0 ? value : null;
        this.setState({ selectedOption: value }, () => {
            if (value) {
                const bodyVariables = this.extractVariables(value.bodyText);
                const headerVariables = this.extractVariables(value.headerText);
                const bodyVariableValues = bodyVariables.reduce((acc, variable) => {
                    acc[variable] = ""; // Inicializa os valores das variáveis do corpo
                    return acc;
                }, {});

                const headerVariableValues = headerVariables.reduce((acc, variable) => {
                    acc[variable] = ""; // Inicializa os valores das variáveis do cabeçalho
                    return acc;
                }, {});

                this.setState({ bodyVariableValues, headerVariableValues, [stateName]: data });

            }
        });
    };

    handlePhoneChange = (value) => {
        this.setState({ phone: value });
    };

    handleChangeTextArea = (value) => {
        this.setState({ message: value });
    };


    submitValidations = () => {
        PapiroConsole.log("entrei aqui validations")


        const {  messageProviderId, registrationNumberRequestId, templateMessageId } = this.state;
        const { setMessage } = this.props;
        
        PapiroConsole.log(registrationNumberRequestId)
        PapiroConsole.log(messageProviderId)
        PapiroConsole.log(templateMessageId)
        if (registrationNumberRequestId == null || (registrationNumberRequestId != null && registrationNumberRequestId.id == 0)) {
            PapiroConsole.log("entrei aqui validations 1")
            
            setMessage(true, 'error', "É necessário preencher o campo 'Número registrado' ")
            return false;
        }
        else if (messageProviderId == null || (messageProviderId != null && messageProviderId.id == 0)) {
            PapiroConsole.log("entrei aqui validations 2")
            setMessage(true, 'error', "É necessário preencher o campo 'Provedor da mensagem' ")
            return false;
        }
        else if (templateMessageId == null || (templateMessageId != null && templateMessageId.id == 0)) {
            PapiroConsole.log("entrei aqui validations 3")
            setMessage(true, 'error', "É necessário preencher o campo 'Modelos' ")
            return false;
        }

        return true;

    }


    handleSubmitModal = async () => {

        const { setLoading, setMessage, match, history } = this.props;



        setLoading(true)

        const { messageProviderId, registrationNumberRequestId, templateMessageId, message, phone, headerVariableValues, bodyVariableValues } = this.state;

        let validations = this.submitValidations();
        PapiroConsole.log("validations VALOR ")
        PapiroConsole.log(validations)
        if (validations) {
            let data = {
                messageProviderId: messageProviderId.id,
                registrationNumberRequestId: registrationNumberRequestId.id,
                templateMessageId: templateMessageId.id,
                message,
                phone,
                bodyVariableValues,
                headerVariableValues,
            }

            let result = await newConversationModal(data)
            if (result && result.success) {
                setLoading(false)
                // PapiroConsole.log("matchaqui")
                // PapiroConsole.log(match)
                // if (match && match.params && match.params.lang) {
                //     PapiroConsole.log("entrei aqui 2 ")
                //     history.push(`/${this.props.match.params.lang}/omnichannel`)
                // }



            }
            else {
                const intl = this.props.intl;
                setLoading(false)
                setMessage(true, 'error', result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
                Api2Talk.kickoff(result)
            }
        }
        else{
            setLoading(false)
        }


    };



    extractVariables = (...texts) => {
        const regex = /\{\{(\d+)\}\}/g;
        const variables = new Set();
        texts.forEach((text) => {
            let match;
            while ((match = regex.exec(text))) {
                variables.add(match[1]);
            }
        });
        return Array.from(variables);
    };



    handleVariableChange = (type, variable, value) => {
        this.setState((prevState) => ({
            [`${type}VariableValues`]: {
                ...prevState[`${type}VariableValues`],
                [variable]: value,
            },
        }));
    };

    handleNextStep = async () => {
        const { activeStep } = this.state;
        if (activeStep === 0) {
            var validation = await this.validatePhone(); // Valida o telefone ao clicar no botão
            if (validation) {
                this.setState({ activeStep: 1 });
            }
        }

    };

    handlePreviousStep = () => {
        const { setTemplateMessageListComplete } = this.props;
        this.setState((prevState) => ({
            activeStep: prevState.activeStep - 1,
            messageProviderId: null,
            registrationNumberRequestId: null,
            templateMessageId: null,
            selectedOption: null,
            bodyVariableValues: {},
            headerVariableValues: {},
            optinSelected: false
        }));
        setTemplateMessageListComplete()
    };

    validatePhone = async () => {



        const { setLoading, setMessage, setTemplateMessageList, templateMessageList } = this.props;


        var phone = "";



        if (this.state.activeTab == 0) {

            PapiroConsole.log("entrei aqui 1")

            if (this.state.clientUser && this.state.clientUser.id != null && this.state.clientUser.id != 0 && this.state.clientUser.phone != null) {
                phone = this.state.clientUser.phone;
                PapiroConsole.log("entrei aqui 2")
                PapiroConsole.log(phone)
            }

            else {

                setLoading(false)
                setMessage(true, 'error', 'É necessário adicionar um cliente com um número de telefone associado')
                return false;

            }

        }
        else if (this.state.activeTab == 1) {

            if (this.state.phoneUser && this.state.phoneUser.id != null && this.state.phoneUser.id != 0 && this.state.phoneUser.phone != null) {
                phone = this.state.phoneUser.phone;
                PapiroConsole.log("entrei aqui 3")
                PapiroConsole.log(phone)
            }
            else if (this.state.inputNumber != "") {
                phone = this.state.inputNumber;
                PapiroConsole.log("entrei aqui 4")
                PapiroConsole.log(phone)
            }
            else {

                setLoading(false)
                setMessage(true, 'error', 'É necessário adicionar um número de telefone')
                return false;

            }

        }


        setLoading(true)
       

        let data = {
            phone: phone
        }

        let result = await validatePhone(data)
        if (result && result.success) {

            if (result.data) {

                if (!(result.data.showalltemplates)) {
                    if (result.data.optintemplate && result.data.optintemplate) {

                        const templateOptInList = templateMessageList.filter(c => c.id == result.data.optintemplate.id)
                        const templateOptIn = templateMessageList.find(c => c.id == result.data.optintemplate.id);

                        // setTemplateMessageList(templateOptInList)
                        this.handleChangeTemplateMessageAutocomplete("templateMessageId", templateOptIn)
                        this.setState({ optinSelected: true })

                    }
                    else {
                        setTemplateMessageList([])
                    }
                }

                this.setState({  phone: phone })
            }
            setLoading(false)
            return true;



        }
        else {
            const intl = this.props.intl;

            setLoading(false)
            setMessage(true, 'error', result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
            Api2Talk.kickoff(result)
            return false;
        }



    }

    handleTabChange = (event, newValue) => {
        this.setState({ activeTab: newValue });
    };


    renderStepContent = () => {

        const {
            messageProvidersList,
            registrationNumberRequestList,
            templateMessageList,
            classes,
            intl,
            match,
            history,
        } = this.props;

        PapiroConsole.log("match here")
        PapiroConsole.log(match)

        PapiroConsole.log("history here")
        PapiroConsole.log(history)



        const { activeStep, registrationNumberRequestId, messageProviderId, templateMessageId, phone, selectedOption, headerVariableValues, bodyVariableValues, optinSelected, clientUser, phoneUser, activeTab, phoneOptions, inputNumber } = this.state;



        if (activeStep === 0) {
            return (
                <Grid container spacing={2} className={classes.gridContainer} >
                    <Grid item xs={12} sm={12}>
                        <Tabs centered className={classes.tabs} value={activeTab} onChange={this.handleTabChange}>
                            <Tab label="Cliente" />
                            <Tab label="Telefone" />
                        </Tabs>
                    </Grid>

                    <Grid item xs={12}>

                        {activeTab === 0 &&

                            <SimpleAutoCompleteSearch label={intl.formatMessage({ id: "keypress.search.instruction" }) + " " + intl.formatMessage({ id: "requestuser" }) + " (min 5 caracteres)"} options={this.state.clientUsersList} stateName="clientUser" changeSelect={this.handleChangeAutocomplete} selected={clientUser} urlapi={Api.apiurlbase() + 'api/users/search/clientuser'} required />

                        }
                        {activeTab === 1 &&

                            <Grid item xs={12}>

                                <Grid item xs={12}>
                                    {/* <PhoneInput
                                        country={"us"} // País inicial
                                        onChange={(_, countryData) => this.handlePrefixChange(_, countryData)}
                                        enableSearch={true} // Permitir busca de países
                                        inputStyle={{ display: "none" }} // Esconder apenas o campo interno, mantendo o botão de bandeiras
                                        dropdownStyle={{ zIndex: 1000 }}
                                        buttonStyle={{ padding: "10px", border: "1px solid #ccc", borderRadius: "4px" }}
                                    /> */}


                                </Grid>
                                <Grid item xs={12} style={{ marginTop: '20px' }}>
                                    <SimpleAutoCompleteSearchPhone handleInputChange={(value) => {
                                        this.handleInputChange("inputNumber", value)
                                    }}

                                        label={"Com qual número você deseja entrar em contato? (min 5 caracteres)"} options={this.state.phoneUsersList} stateName="phoneUser" changeSelect={this.handleChangeAutocomplete} selected={phoneUser} urlapi={Api.apiurlbase() + 'api/users/search/clientphone'} required />
                                </Grid>




                            </Grid>
                         
                        }
                    </Grid>
                </Grid>

            );
        }

        if (activeStep === 1) {
            return (
                <Grid container spacing={2} className={classes.gridContainer} >
                    <Grid item xs={12}>
                        <Autocomplete
                            style={{ paddingTop: '10px' }}
                            options={registrationNumberRequestList}
                            getOptionLabel={(option) => option.phonenumber}
                            onChange={(event, value) => this.handleChangeAutocomplete('registrationNumberRequestId', value)}
                            renderInput={(params) => <TextField {...params} required label="Número registrado" variant="outlined" />}
                            value={registrationNumberRequestId}

                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SimpleAutoComplete
                            style={{ paddingTop: '10px' }}
                            name="messageProviderId"
                            label={intl.formatMessage({ id: "select.message_provider" })}
                            options={messageProvidersList}
                            stateName='messageProviderId'
                            changeSelect={(stateName, value) => {
                                this.handleChangeAutocomplete(stateName, value)
                            }}
                            selected={messageProviderId}
                            required
                        />
                    </Grid>


                    <Grid item xs={12}>
                        <SimpleAutoComplete
                            style={{ paddingTop: '10px' }}
                            name="templateMessageId"
                            label={intl.formatMessage({ id: "templates" })}
                            options={templateMessageList}
                            stateName='templateMessageId'
                            changeSelect={(stateName, value) => {
                                this.handleChangeTemplateMessageAutocomplete(stateName, value)
                            }}
                            selected={templateMessageId}
                            disabled={optinSelected}
                            required

                        />
                    </Grid>
                    {selectedOption &&
                        <Grid item xs={12}>
                            <Grid container>
                                {selectedOption && selectedOption.headertext &&
                                    <Grid item sx={12}>
                                        <h4 className={classes.sectionTitle}>Cabeçalho da Solicitação</h4>
                                        <p className={classes.sectionText}>{selectedOption.headertext}</p>
                                        {this.extractVariables(selectedOption.headertext).map((variable) => (
                                            <TextField
                                                key={variable}
                                                label={`Valor para {{${variable}}}`}
                                                value={headerVariableValues[variable] || ""}
                                                onChange={(e) =>
                                                    this.handleVariableChange("header", variable, e.target.value)
                                                }
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                            />
                                        ))}
                                    </Grid>
                                }
                                {selectedOption && selectedOption.bodytext &&
                                    <Grid item sx={12} className={classes.section}>
                                        <h4 className={classes.sectionTitle}>Corpo da Solicitação</h4>
                                        <p className={classes.sectionText}>{selectedOption.bodytext}</p>
                                        {this.extractVariables(selectedOption.bodytext).map((variable) => (
                                            <TextField
                                                key={variable}
                                                label={`Valor para {{${variable}}}`}
                                                value={bodyVariableValues[variable] || ""}
                                                onChange={(e) =>
                                                    this.handleVariableChange("body", variable, e.target.value)
                                                }
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                            />
                                        ))}
                                    </Grid>
                                }

                            </Grid>
                        </Grid>
                    }

                </Grid>
            );

        }

        return null;
    };


    render() {
        const {
            open,
            onClose,
            classes,
        } = this.props;



        const { activeStep, headerVariableValues, bodyVariableValues } = this.state;

        PapiroConsole.log("headerVariableValues aqui ")
        PapiroConsole.log(headerVariableValues)
        PapiroConsole.log("bodyVariableValues aqui ")
        PapiroConsole.log(bodyVariableValues)
        return (
            <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
                <DialogTitle className={classes.dialogTitle}>Nova conversa</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Stepper sx={{
                        height: '60px', // Aumenta a altura do Stepper
                        '.MuiStepLabel-label': {
                            fontSize: '1rem', // Aumenta o tamanho da fonte dos labels
                            fontWeight: 'bold', // Torna os labels mais destacados
                        },
                        '.MuiStepConnector-line': {
                            borderWidth: '3px', // Aumenta a espessura da linha conectando os steps
                        },
                        '.MuiStepIcon-root': {
                            width: '38px', // Aumenta o tamanho do ícone
                            height: '38px',
                        },
                        marginBottom: '10px'
                    }} activeStep={activeStep} alternativeLabel>
                        <Step>
                            <StepLabel>Telefone</StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>Dados da conversa</StepLabel>
                        </Step>
                    </Stepper>
                    {this.renderStepContent()}


                </DialogContent>
                <DialogActions className={classes.dialogActions} sx={{ justifyContent: 'flex-end' }}>
                    {activeStep > 0 && (
                        <Button
                            onClick={this.handlePreviousStep}
                            className={classes.buttonSecondary}
                            variant="outlined"
                        >
                            Voltar
                        </Button>
                    )}
                    {activeStep < 1 ? (
                        <Button
                            onClick={this.handleNextStep}
                            className={classes.buttonPrimary}
                        >
                            Avançar
                        </Button>
                    ) : (
                        <Button onClick={this.handleSubmitModal}
                            type='submit'

                            variant='contained'
                            color='primary'
                            className={classes.submit}>
                            Iniciar conversa
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        );
    }
}

CustomModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    messageProvidersList: PropTypes.array.isRequired,
    registrationNumberRequestList: PropTypes.array.isRequired,
    templateMessageList: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomModal);
