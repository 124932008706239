
import Api2Talk from "../api2talk";

export const getCompanyByCnpj = async (cnpj) => { 
  let data = [];
  await Api2Talk.get(`/company/getcompanybycnpj?cnpj=${cnpj}`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err;
  });
  return data;
};