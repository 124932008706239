import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { injectIntl } from 'react-intl';
import {  Button, TextField, Grid } from '@mui/material';

function DialogTwoFactorAuth(props) {

  const intl = props.intl;

  const handleTwoFactorAuthCode = (value) => {
    props.handleInputChange(value);
  };

  return (
    <div>
      <Dialog
        open={props.openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"> { intl.formatMessage({ id: "twoFactorAuth.enableDone" }) } </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid style={{marginTop: '10px'}} item xs={12} sm={12}>
              <TextField 
                inputProps={{ maxLength: 6 }}
                fullWidth
                label={intl.formatMessage({ id: "twoFactorAuth.code" })}
                variant='outlined'
                name='twoFactorAuthCode'
                onChange={handleTwoFactorAuthCode}
                value={props.twoFactorAuthCode}
              />
            </Grid>
            <Grid style={{marginTop: '10px'}}  item xs={12} sm={12}>
              <i>
                {props.twoFactorAuthType == "email" && 
                  intl.formatMessage({id:"twoFactorAuth.digitYourCodeEmail"})
                }
                {props.twoFactorAuthType == "app" && 
                  intl.formatMessage({id:"twoFactorAuth.digitYourCode"})
                }
              </i>
            </Grid>
          </Grid>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.openDialogFunc()} color="primary" >
            {intl.formatMessage({id:"cancel"})}
          </Button>
          <Button onClick={(e) => { props.twoFactorAuthSubmit(e) }} variant="contained" color="primary" autoFocus>
            {intl.formatMessage({id:"send"})}
          </Button>
        </DialogActions>
      </Dialog>
      
    </div>
  );
}

export default injectIntl(DialogTwoFactorAuth);