import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'

import { Styles } from '../../../styles/material-styles'
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Divider, Grid, Paper, Typography } from '@mui/material'
import { Overlay } from '../../../styles/global'
import { AccountBalance, Attachment, Comment, ExpandMore, Group, History, InfoOutlined } from '@mui/icons-material'
import moment from 'moment'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './tab.css'
import HistoryCalledStatus from './tabs/history-called-status'
import HistoryCalledAssignedUsers from './tabs/history-called-assigned-users'
import HistoryCalledRequestingUsers from './tabs/history-called-requesting-users'
import HistoryCalledTeams from './tabs/history-called-teams'
import CalledAttachments from './tabs/called-attachments'
import CalledActions from './tabs/called-actions'
import ReadOnlyField from '../../../components/read-only-field'
import HistoryCalledOrganization from './tabs/history-called-organization'
import DOMPurify from 'dompurify'
import { PapiroConsole } from '../../../utils/papiroConsole'

function CalledData(props) {

  const { intl } = props
  const [calledDescription, setCalledDescription] = useState("")
  const [expanded, setExpanded] = useState(true)

  const handleChangeExpand = _ => {
    setExpanded(!expanded)
  }

  useEffect(() => {
    if (props.calledData != null && props.calledData.description != null) {
      let description = DOMPurify.sanitize(props.calledData.description)
      setCalledDescription(description)
    } else {
      setCalledDescription("")
    }
  }, [props.calledData])
  
  const assigned = props.calledData.organization.organizationsettings.displayassignedinfomode == 4 ?
    props.calledData.techinicalteam != null && props.calledData.techinicalteam.name && props.calledData.techinicalteam.name.length > 0 ?
      props.calledData.techinicalteam.name
      :
      ""
    :
    props.calledData.assigneduser != null && props.calledData.assigneduser.name && props.calledData.assigneduser.name.length > 0 ?
    props.calledData.assigneduser.email && props.calledData.assigneduser.email.length > 0 ? props.calledData.assigneduser.name + ` (${props.calledData.assigneduser.email})`  : props.calledData.assigneduser.name
    :
    ""

  return (
    <Paper elevation={3} sx={{ width: '100%', minHeight: 500, paddingBottom: '25px' }}>
      {props.calledData != null && (<Grid container spacing={2} sx={{ marginTop: '16px!important', paddingLeft: '16px!important', paddingRight: '16px!important' }}>
        <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
          <h4 style={{ color: "#3F51B4", fill: "#3F51B4", margin: 0, fontWeight: 600, display: 'flex', alignItems: 'flex-end' }}>#{ props.calledData.id != null && props.calledData.id } - { props.calledData.title != null && props.calledData.title }</h4>
        </Grid>
        {(props.calledData != null && props.calledData.archived != null && props.calledData.archived == true) && <Grid item xs={12} sm={12} md={2} lg={2} xl={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <h6 style={{marginTop:0, paddingTop:0, fontStyle:'italic'}}>(arquivado)</h6>
        </Grid>}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: 0 }}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex' }}>
        { !props.isClient && props.calledData.priority && props.calledData.priority.name && props.calledData.priority.name.length > 0 &&<div style={{ display: 'flex' }}>
            <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0  }}><FormattedMessage id="priority" />:</h5>
            {props.calledData.priority.colorhex && props.calledData.priority.colorhex.length > 0 &&<span style={{ width: 7, borderRadius: 7, backgroundColor: props.calledData.priority.colorhex, marginLeft: 5 }}></span>}
            <h5 style={{ color: "#3f4041", fill: "#3f4041", margin: 0, marginLeft: 5  }}>{props.calledData.priority.name}</h5>
          </div>}
        { props.calledData.status && props.calledData.status.name && props.calledData.status.name.length > 0 &&<div style={{ display: 'flex', marginLeft: (props.calledData.priority && props.calledData.priority.name && props.calledData.priority.name.length > 0) ? 50 : 0 }}>
            <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0  }}><FormattedMessage id="status" />:</h5>
            {props.calledData.status.colorhex && props.calledData.status.colorhex.length > 0 &&<span style={{ width: 7, borderRadius: 7, backgroundColor: props.calledData.status.colorhex, marginLeft: 5 }}></span>}
            <h5 style={{ color: "#3f4041", fill: "#3f4041", margin: 0, marginLeft: 5  }}>{props.calledData.status.name}</h5>
          </div>}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: 0 }}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Accordion
            expanded={expanded}
            onChange={handleChangeExpand} >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="called-details-content"
              id="called-details-header">
              <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0  }}><FormattedMessage id="description" />:</h5>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: '0 5% 0 5%', backgroundColor: 'rgb(242, 242, 242);', paddingTop: '10px', borderRadius: '4px' }}>
                  {props.calledData.description != null && <div className='calledViewDescription' dangerouslySetInnerHTML={{ __html: calledDescription }} />}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: 0 }}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0, display: 'inline' }}><FormattedMessage id="created.via" /> { props.calledData.originrequest && props.calledData.originrequest.name && props.calledData.originrequest.name } <FormattedMessage id="in" /> { props.calledData.openingdate != null && moment(props.calledData.openingdate).format("DD/MM/YYYY HH:mm") }</h5>
          <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0, display: 'inline'}}><FormattedMessage id="security.token" /> { props.calledData.securitypassword != null && props.calledData.securitypassword }</h5>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <h5 style={{ color: "#58595B", fill: "#58595B", margin: 0, display: 'inline'}}><FormattedMessage id="solution.forecast" /> <FormattedMessage id="in" /> { props.calledData.duedate != null && moment(props.calledData.duedate).format("DD/MM/YYYY HH:mm") }</h5>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: 0 }}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReadOnlyField
            label={intl.formatMessage({ id: "category" })}
            value={ (props.calledData.organizationcategory != null && props.calledData.organizationcategory.parentname != null && props.calledData.organizationcategory.parentname.length > 0) ? props.calledData.organizationcategory.parentname : (props.calledData.organizationcategory != null && props.calledData.organizationcategory.name != null && props.calledData.organizationcategory.name.length > 0) && props.calledData.organizationcategory.name } />
        </Grid>
        {!props.isClient && <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <ReadOnlyField
            label={intl.formatMessage({ id: "organization" })}
            value={ (props.calledData.organization != null && props.calledData.organization.name && props.calledData.organization.name.length > 0) && props.calledData.organization.name } />
        </Grid>}
        {!props.isClient && <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <ReadOnlyField
            label={intl.formatMessage({ id: "requestuser" })}
            value={ (props.calledData.requestinguser != null && props.calledData.requestinguser.name && props.calledData.requestinguser.name.length > 0) && `${props.calledData.requestinguser.name} (${props.calledData.requestinguser.email && props.calledData.requestinguser.email.length > 0 && props.calledData.requestinguser.email})`} />
        </Grid>}
        {!props.isClient && <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <ReadOnlyField
            label={intl.formatMessage({ id: "attendance.team" })}
            value={ (props.calledData.techinicalteam != null && props.calledData.techinicalteam.name && props.calledData.techinicalteam.name.length > 0) && props.calledData.techinicalteam.name } />
        </Grid>}
        {!props.isClient && <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <ReadOnlyField
            label={intl.formatMessage({ id: "assigned" })}
            value={assigned} />
        </Grid>}
        {!props.isClient && <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <ReadOnlyField
            label={intl.formatMessage({ id: "type" })}
            value={ (props.calledData.type != null && props.calledData.type.name && props.calledData.type.name.length > 0) && props.calledData.type.name} />
        </Grid>}
        {!props.isClient && <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <ReadOnlyField
            label={intl.formatMessage({ id: "address" })}
            value={ (props.calledData.address != null && props.calledData.address.name && props.calledData.address.name.length > 0) && props.calledData.address.name} >
            <React.Fragment>
              <Typography>{props.intl.formatMessage({id:"name"})}: {props.calledData.address && props.calledData.address.name ? props.calledData.address.name : '---'}</Typography>
              <Typography>{ props.calledData.address.street.length > 0 && props.calledData.address.street } { props.calledData.address.number.length > 0 && `, ${props.calledData.address.number}` } { props.calledData.address.neighborhood.length > 0 && props.calledData.address.neighborhood } { props.calledData.address.city.length > 0 && `- ${props.calledData.address.city}` } { props.calledData.address.state.length > 0 && `, ${props.calledData.address.state}` } { props.calledData.address.country.length > 0 && `- ${props.calledData.address.country}` }</Typography>
            </React.Fragment>
          </ReadOnlyField>
        </Grid>}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ paddingBottom: '20px' }}>
        <Tabs >
          <TabList style={{ borderColor: '#BB8FCE' }}>
            <Tab style={{ fontSize: "14px", display: 'inline-flex' }}><Attachment />&nbsp;<FormattedMessage id="attachments" /></Tab>
            <Tab style={{ fontSize: "14px", display: 'inline-flex' }}><Comment />&nbsp;<FormattedMessage id="actions" /></Tab>
            <Tab style={{ fontSize: "14px", display: 'inline-flex' }}><History />&nbsp;<FormattedMessage id="calledStatus.header" /></Tab>
            <Tab style={{ fontSize: "14px", display: 'inline-flex' }}><Group />&nbsp;<FormattedMessage id="assigned-called-user" /></Tab>
            <Tab style={{ fontSize: "14px", display: 'inline-flex' }}><Group />&nbsp;<FormattedMessage id="calledrequestinguser.history.header" /></Tab>
            <Tab style={{ fontSize: "14px", display: 'inline-flex' }}><Group />&nbsp;<FormattedMessage id="calledteams.history.header" /></Tab>
            <Tab style={{ fontSize: "14px", display: 'inline-flex' }}><AccountBalance />&nbsp;<FormattedMessage id="called.organization.history.header" /></Tab>
          </TabList>
          <TabPanel>
            <CalledAttachments
              calledData={props.calledData}
              setMessage={props.setMessage}
              setLoading={props.setLoading} />
            </TabPanel>
          <TabPanel>
            <CalledActions
              calledData={props.calledData}
              setMessage={props.setMessage}
              setLoading={props.setLoading} />
          </TabPanel>
          <TabPanel><HistoryCalledStatus calledData={props.calledData} /></TabPanel>
          <TabPanel><HistoryCalledAssignedUsers calledData={props.calledData} /></TabPanel>
          <TabPanel><HistoryCalledRequestingUsers calledData={props.calledData} /></TabPanel>
          <TabPanel><HistoryCalledTeams calledData={props.calledData} /></TabPanel>
          <TabPanel><HistoryCalledOrganization calledData={props.calledData} /></TabPanel>
        </Tabs>
        </Grid>
      </Grid>)}
    </Paper >
  )
}

CalledData.propTypes = {
  classes: PropTypes.object.isRequired,
  setMessage: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
})

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(CalledData)))