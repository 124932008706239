import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container, ImageList, Paper } from '@mui/material';
import { QueuePlayNext, DeleteForever, Height, AttachFile } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import Api from '../../services/api';
import { getOriginRequests } from '../../services/organizationsetting.service';
import { getOriginRequestsByFlag } from '../../services/organizationsetting.service';
import * as CalledService from '../../services/called.service';
import * as FileService from '../../services/attachment.service';
import * as CalledOrganizationChecklistService from '../../services/calledorganizationchecklist.service'
import * as OrgDepartamentService from '../../services/organizationdepartament.service';
import * as KnowledgeBaseService from '../../services/knowledgebase.service'
import * as UserService from '../../services/user.service'
import { associateOrganizationUser, getDepartamentByUserId } from '../../services/user.service'
import { getDepartamentsByOrganizationCategoryIdNew } from '../../services/organizationcategory.service';
import CustomizedSnackbars from '../../components/material-snackbars';
import SimpleSelect from '../../components/select/simple';
import SimpleDatePicker from '../../components/date-pickers/inline';
import SimpleDatePickerHour from '../../components/date-pickers/inline-hour';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../components/header-menu';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
//import SimpleTreeView from '../../components/tree-view/tree';
//import { Link } from 'react-router-dom';
import SimpleAutoCompleteMultiple from '../../components/auto-complete/autocomplete-multiple-create';
import SimpleAutoCompleteAsynchronousKnowledgebaseByOrganization from  '../../components/auto-complete/autocomplete-asynchronous-knowledgebase-by-organization';
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';
import SimpleAutoCompleteSearch from '../../components/auto-complete/autocomplete-search-api';
import SimpleAutoCompleteAsynchronousKnowledgebase from './components/auto-complete-async-knowledgebase';
import Footer from '../../components/footer';
import { browserHistory } from '../../helpers/history';
import { FormattedMessage, injectIntl } from 'react-intl';
import SimpleCheckBox from '../../components/checkbox/check';
import SimpleHelp from '../../components/help';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import If from '../../components/conditionals/if'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import * as PriorityService from '../../services/priority.service';
import 'react-quill/dist/quill.snow.css'; // ES6
import ReactQuill from 'react-quill'; // ES6
import ClearIcon from '@mui/icons-material/Clear';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import SimpleAutoCompleteByName from '../../components/auto-complete/autocomplete-by-name';

import ReactDOM from 'react-dom'
import { PapiroConsole } from '../../utils/papiroConsole';
import SimpleSelectWithColor from '../../components/select/simple-with-color';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputMask from 'react-input-mask'


class CalledRegistration extends Component {

  constructor(props) {
    super(props);

    const { userSession } = this.props;
    // PapiroConsole.log(this.props);
    //this.isClient = userSession.user.role.roleType === 3;
    this.globalAdm = false;
    this.AdmOrganization = false;
    this.isClient = true;
    this.isTec = false;

    this.idCalled = null;
    this.noteUser = null
    this.descriptionCalled = undefined;
    this.lastAccompaniment = null
    if (userSession == null ||  userSession.user == null || userSession.user.userrole == null) {
      Api.logoff();
    }

    userSession.user.userrole.map((item, key) => {
      if (item.role.roletype != 3) {
        this.isClient = false;
      }
      if (item.role.roletype == 0) {
        this.globalAdm = true;
      }
      if (item.role.roletype == 1) {
        this.AdmOrganization = true;
      }
      if (item.role.roletype == 2 || item.role.roletype == 4) {
        this.isTec = true;
        // PapiroConsole.log('entrei aqui')
      }
    }
    );

    this.canselectpatrimonyincalled = false;
    this.canselectdepartamentincalled = false;
    this.canselectparentcalledincalled = false

    this.reactQuillModules = {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ],
    }
  
    this.reactQuillFormats = [
      'header',
      'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent',
      'link', 'image'
    ]

    this.categoryId = null;
    this.organizationId = null;
    if (this.isClient || this.AdmOrganization) {
      if (!this.globalAdm) {
        // PapiroConsole.log(userSession.user);
        if (userSession.user.organizationlistid) {
          this.organizationId = userSession.user.organizationlistid;
          PapiroConsole.log("=== userSession.user.organizationlistid ===")
          PapiroConsole.log(userSession.user.organizationlistid)
        }
      }
    }

    const defaultvalue = { id: 0, name: '' };

    this.addressInitialState = {
      createdUser: userSession.user.id,
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      UserText: '',
      template: true,
      template2: false,
      name: '',
      street: '',
      zipcode: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: '',
      country: '',
      longitude: '',
      latitude: '',
      keepaddress: false,
      iscustomaddress : true, 
      saveToAllOrganizationsInGroup: false,
    };

    this.initialCalledState = {
      requestingUser: false,
      createdUser: userSession.user.id,
      organization: this.organizationId,
      type: 0,
      typeDisabled: false,
      priorityDisabled: false,
      keepcalled: false,
      CategoryId: defaultvalue,
      categoryText: '',
      ParentCalledId: 0,
      ParentCalledText: '',
      dueDate: moment().endOf('day').add('days', 1).format("YYYY/MM/DD HH:mm:ss"),
      title: "",
      status: 0,
      description: "",
      AddressId: 0,
      originRequest: 0,
      observantUser: false,
      assignedUser: 0,
      techinicalTeam: 0,
      patrimonyId: "",
      departamentId: 0,
      priorityid: 1,
      newCategoryDepartament: false,
      hasdisplacementTrue: true,
      hasdisplacementFalse: false,
      knowledgebaseattachments: [],
      addresstype: "1"
    };

    this.categoriesByLevelsInitialState = {
      firstCategoryId: defaultvalue,
    }

    this.levelCategoriesInitialState = {
      rootCategoriesList: [],
    }

    this.levelCategoryId = 1

    this.initialKnowledgeBaseItem = {
      id: 0,
      title: '',
      description: '',
      knowledgebasepatrimonies: [],
      knowledgebaseattachments: [],
      knowledgebasekeywords: [],
    }

    this.state = {
      informativeText: '',
      informativeTextAccordionExpanded: true,
      itemError: {
        document: '',
      },
      redirectClientWithNoAccess : false,
      originrequestid : 0,
      createdUser: userSession.user.id,
      OrganizationFilter: [],
      createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
      createUserCalled: {
        nameOfNewUser:null,
        cpfOfNewUser:null,
        emailOfNewUser:null,
        confirmEmailOfNewUser:null
      },
      
      called: this.initialCalledState,
      radioChoice : {},
      address: this.addressInitialState,
      categoriesByLevels: this.categoriesByLevelsInitialState,
      search: {
        knowledgebase: '/knowledgebases'
      },
      canassingusercalled: false,
      canselectoriginrequestincalledcreation: false,
      canselectorganizationcategoriesbylevels: false,
      files: [],
      loading: false,
      loadingRequestingUsers: false,
      loadingParentCalleds: false,
      openNotification: false,
      notificationVariant: "error",
      notificationMessage: "",
      notificationLinkMove: "",
      knowledgebase: null,
      knowledgeBaseList: [],
      typesList: [],
      categoriesList: [],
      parentCalledsList: [],
      technicalUsersList: [],
      usersList: [],
      clientUsersList: [],
      organizationAddressesList: [],
      userAddressesList: [],
      organizationsList: [],
      originRequestList: [],
      patrimonyList: [],
      organizationFieldList: [],
      campoTexto: null,
      userId: userSession.user.id,
      organizationDepartamentList: [],
      userDepartamentId: 0,
      DepartamentList: [],
      priorityList: [],
      techinicalTeamList: [],
      techinicalTeamSettings: 0,
      defaultTechinicalSettings: 0,
      openDialogNewAttendence: false,
      valueNewAttendence: 0.00,
      newAttendenceAccept: false,

      openDialogNewDisk: false,
      valueNewDisk: 0.00,
      newDiskAccept: false,
      knowledgeBaseDialogOpen: false,
      newUserBaseDialogOpen: false,

      levelCategories: this.levelCategoriesInitialState,
      levelCategoryId: 0,
      categoryChildrens: [ defaultvalue ],
      categoryLists: [],
      addresstype: 1,
      assignCalledId: 0,
      assignCalledDialogOpen: false,
      associateOrganizationUserDialogOpen: false,
      associateUser: null,
      knowledgeBaseDetailsDialogOpen: false,
      knowledgeBaseItem: this.initialKnowledgeBaseItem,
      associateOrganizationOptions : 'associateCalledOrganization',
      OrgsList: [],
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeNewUser = this.handleInputChangeNewUser.bind(this);
    this.handleInputChangeAddress = this.handleInputChangeAddress.bind(this)
    this.randomIntFromInterval = this.randomIntFromInterval.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.handleRedirection = this.handleRedirection.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.changeValuesCheckbox = this.changeValuesCheckbox.bind(this);
	  this.handleChangeEditor = this.handleChangeEditor.bind(this);
    this.getCategoryFields = this.getCategoryFields.bind(this);
    
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeRadio= this.handleChangeRadio.bind(this)
    this.handleChangeRadioDialog= this.handleChangeRadioDialog.bind(this)
    
    this.getUserAddresses = this.getUserAddresses.bind(this)
    this.getCep = this.getCep.bind(this)
    this.convertHtmlOption = this.convertHtmlOption.bind(this)
    this.getTechnicalTeams = this.getTechnicalTeams.bind(this)
    this.getTechnicalUsersByTechnicalTeam = this.getTechnicalUsersByTechnicalTeam.bind(this)
    this.openAssignCalledDialog = this.openAssignCalledDialog.bind(this)
    this.closeAssignCalledDialog = this.closeAssignCalledDialog.bind(this)
    this.assignCalled = this.assignCalled.bind(this)
    this.handleState = this.handleState.bind(this)
    this.setLoading = this.setLoading.bind(this)
    this.setMessage = this.setMessage.bind(this)
    this.showMessageMissing = this.showMessageMissing.bind(this)
    this.getOriginRequests = this.getOriginRequests.bind(this)
    this.getOriginRequestsByFlag = this.getOriginRequestsByFlag.bind(this)
    
    this.openAssociateOrganizationUserDialog = this.openAssociateOrganizationUserDialog.bind(this)
    this.closeAssociateOrganizationUserDialog = this.closeAssociateOrganizationUserDialog.bind(this)
    this.handleConfirmAssociateOrganizationUserDialog = this.handleConfirmAssociateOrganizationUserDialog.bind(this)
    this.handleChangeAccordion = this.handleChangeAccordion.bind(this)
  }

  handleChangeRadioDialog = (event) => {
    
    this.setState({ associateOrganizationOptions: event.target.value })
    

  }

  handleChangeAccordion = (accordion) => (event, isExpanded) => {
    if (accordion === "informative-text")
      this.setState({ informativeTextAccordionExpanded: isExpanded })
  }

  openAssociateOrganizationUserDialog = userId => this.setState({ associateOrganizationUserDialogOpen: true, associateUser: userId })

  closeAssociateOrganizationUserDialog = _ => this.setState({ associateOrganizationUserDialogOpen: false, associateUser: null })

  handleConfirmAssociateOrganizationUserDialog = async (e) => {
    e.preventDefault()

    if (this.state.called.organization == null || (this.state.called.organization && this.state.called.organization.id && this.state.called.organization.id == 0)) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "Informe uma organização antes de criar um novo usuário"
      });
    } else if (this.state.associateUser == null || (this.state.associateUser && (this.state.associateUser.id == null || this.state.associateUser.id == 0))) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "Ocorreu um erro ao encontrar o usuário"
      });
    } else {
      let data = new FormData()
      data.append("OrganizationId", this.state.called.organization.id)
      data.append("UserId", this.state.associateUser.id)

      let result = await associateOrganizationUser(data)
      if (result && result.success) {
        await this.getClientsByOrganizationId()
        
        this.setState(prevState => ({
          called: {
            ...prevState.called,
            ["requestingUser"]: this.state.associateUser
          }
        }))

        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'success',
          notificationMessage: 'Usuário associado com sucesso'
        })
        Api.kickoff(result)
        this.closeAssociateOrganizationUserDialog()
      } else {
        const intl = this.props.intl
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(result)
      }
    }
  }

  handleState = (stateName, value) => {
    this.setState({ [stateName]: value })
  }

  setLoading = (isLoading) => {
    this.setState({ loading: true })
  }

  setMessage = (open, variant, message) => {
    this.setState({
      openNotification: open,
      notificationVariant: variant,
      notificationMessage: message
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state === "reload") {
      this.componentDidMount();
    }
  }

   extractContent(html) {
    return new DOMParser()
        .parseFromString(html, "text/html")
        .documentElement.textContent;
}

  convertHtmlOption = value => {
    let body = value
    let temp = document.createElement("div")
    temp.innerHTML = body
    let sanitized = temp.textContent || temp.innerText

    PapiroConsole.log("=== convertHtmlOption ===")
    PapiroConsole.log(temp.textContent)
    PapiroConsole.log(temp.textContent.length)
    PapiroConsole.log(temp.innerText)
    PapiroConsole.log(temp.innerText.length)

    
    
    return sanitized
  }

  openDialogNewAttendence = async () => {
    this.setState({ openDialogNewAttendence: !this.state.openDialogNewAttendence });
  }

  openDialogNewDisk = async () => {
    this.setState({ openDialogNewDisk: !this.state.openDialogNewDisk });
  }

  loading = (isLoading) => this.setState({ loading: isLoading })

  getFile = async (attachment) => {
		const intl = this.props.intl;
		this.setState({ loading: true });
		
		let url = await FileService.getFileDownloadLink(attachment.id);
		if(url &&  url.data && url.data.data && url.data.success){
      this.setState({ loading: false });
			window.open(`${url.data.data}`, '_blank');
		} else{
			this.setState({
				loading: false,
        openNotification: true,
        notificationVariant: "error",
				notificationMessage: intl.formatMessage({ id: "file.not.found" })
			});
    }
  }

  copyFileLink = async (attachment) => {
    const intl = this.props.intl;
    this.setState({ loading: true });

    let url = await FileService.getFileDownloadLink(attachment.id);
    if(url &&  url.data && url.data.data && url.data.success){
            this.setState({ loading: false });
      navigator.clipboard.writeText(url.data.data)
            this.setState({
        loading: false,
                openNotification: true,
                notificationVariant: "success",
        notificationMessage: "Link de download copiado!"
      })
    } else{
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "file.not.found" })
      });
    }
  }

  openKnowledgeBaseDialog = async () => {
    if (this.state.called.organization == null || (this.state.called.organization && this.state.called.organization.id && this.state.called.organization.id == 0)) {
      const intl = this.props.intl;
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"add.organization.to.called"})
      })
      return false
    } else if (this.state.knowledgeBaseList == null || (this.state.knowledgeBaseList && this.state.knowledgeBaseList.length == 0)) {
        this.setState({ loading: true })
        let result = await KnowledgeBaseService.getKnowledgeBaseByOrganizaytionIdSelectionModel(this.state.called.organization.id)
        if (result && result.success)
        {
            let formmatedList = result.data.map(item => {
                let toName = item && item.name
                if ('title' in item)
                    toName = item['title']

                if (item.knowledgebasekeywords && item.knowledgebasekeywords.length > 0) {
                    toName += ` (`
                    item.knowledgebasekeywords.map((word, ix) => {
                        if (word && word.word && word.word.length > 0) {
                            if (ix == 0)
                                toName += `${word.word}`
                            else
                                toName += `, ${word.word}`
                        }
                    })
                    toName += `)`
                }

                return {
                    ...item,
                    name: toName
                }
            })
            this.setState({
                knowledgeBaseList: formmatedList,
                loading: false,
                knowledgeBaseDialogOpen: true
            })
        } else {
            const intl = this.props.intl;
            this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
            Api.kickoff(result);
        }
    } else
      this.setState({ knowledgeBaseDialogOpen: true })
  }

  closeKnowledgeBaseDialog = () => this.setState({ knowledgeBaseDialogOpen: false })

  knowledgeBaseDetailsClose = _ => this.setState({ knowledgeBaseDetailsDialogOpen: false, knowledgeBaseItem: this.initialKnowledgeBaseItem })

  knowledgeBaseDetails = async () => {
    this.setState({ loading: true })

    let result = await KnowledgeBaseService.getDetailsById(this.state.knowledgebase.id)
    if(result && result.success) {
      if(result.data != null) {
        this.setState({ loading: false, knowledgeBaseDetailsDialogOpen: true, knowledgeBaseItem: result.data })
      }
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
      Api.kickoff(result)
    }
  }
  openNewUserBaseDialog = () => {

    if(this.state.called.organization == null || (this.state.called.organization && this.state.called.organization.id == 0)){

      this.setState({
       
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "informe uma organização antes de criar um novo usuário"
      });
      
    }
    else{
       this.setState({ newUserBaseDialogOpen: true })
    }
    

    
  }

  //openNewUserBaseDialog = () => this.setState({ newUserBaseDialogOpen: true })

  closeNewUserBaseDialog = () => this.setState({ newUserBaseDialogOpen: false })

  openAssignCalledDialog = () => this.setState({ assignCalledDialogOpen: true })

  closeAssignCalledDialog = () => this.setState({ assignCalledDialogOpen: false, assignCalledId: 0 })

  assignCalled = async () => {
    let calledToAssign = this.state.assignCalledId
    if(calledToAssign != null && calledToAssign > 0) {
        const { userSession } = this.props;
        this.loading(true);

        let data = new FormData()
        data.append("calledid", calledToAssign)
        data.append("userid", userSession.user.id)

        await Api.post(`/calleds/canassignuser/team`, data)
            .then(async result => {
                if (result.data.success) {
                  const intl = this.props.intl;
                  this.setState({
                    assignCalledId: 0,
                    assignCalledDialogOpen: false,
                    loading: false,
                    openNotification: true,
                    notificationVariant: "success",
                    notificationMessage: intl.formatMessage({id:"called.list.notification.called.taken"}),
                  }, () => this.props.history.push(`/${this.props.match.params.lang}/called-accompaniment/${calledToAssign}`));
                }
            })
            .catch((err) => {
                const intl = this.props.intl;
                this.setState({
                    assignCalledId: 0,
                    assignCalledDialogOpen: false,
                    loading: false,
                    openNotification: true,
                    notificationVariant: "error",
                    notificationMessage:
                        err.response &&
                            err.response.data &&
                            err.response.data.errors &&
                            err.response.data.errors[0]
                            ? err.response.data.errors[0]
                            : intl.formatMessage({ id: "process.error" }),
                })
                Api.kickoff(err);
            });
        this.loading(false);
        this.setState({ assignCalledDialogOpen: false, assignCalledId: 0 })
    }
  }

  async componentDidMount() {

    const { userSession, history, match} = this.props;
    const intl = this.props.intl

    if(this.isClient && userSession.user.isexternal == true && userSession.user.canexternaluserclientcreatecalled == false){

      this.setState({
        openNotification: true, notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"user.cannot.access.this.functionality"}),
        redirectClientWithNoAccess : true,
      });

      setTimeout(function(){

       history.push({
          pathname: `/${match.params.lang}/calleds`,
          
        })


      }, 7000);

      
    }

    this.setState({loading: true});
    //await this.getOriginRequests();
    await this.getTypes();
    await this.getPriorities()
    await this.getDepartamentByUserId()
    await this.getOrganizations();
    if (this.isTec === true) {
      await this.getItens();
    }
    this.setState({loading: false});

  };

  getPriorities = async (isLoading = false) => {
    if(isLoading)
      this.setState({ loading: true });
    let result = await PriorityService.getAllByHirerId()
    if(result && result.success) {
      if(result.data != null)
        this.setState({ priorityList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data })
      if(isLoading)
        this.setState({ loading: false });
    } else {
        const intl = this.props.intl
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: 'error',
          notificationMessage: result && result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({ id: "process.error" })
        })
        Api.kickoff(result)
    }
  }

  getAccompaniment(id) {
    if (id == null) {
      return false;
    }
    Api.get(`accompaniments/getAllByCalled/${id}`)
      .then(result => {

        if (result.data.success) {
          // PapiroConsole.log(result.data)
          this.lastAccompaniment = result.data.data[result.data.data.length - 1].description
          // PapiroConsole.log(this.lastAccompaniment)


        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: intl.formatMessage({ id: "add.action.success" }),
            files: [],
            action: {
              description: "",
              privacy: "false"
            }
          });
        }
      }
      )
  }

  async getTypes(isLoading = false) {
    if(isLoading)
      this.setState({ loading: true });

    const result = await CalledService.getTypes();

    if (result.success) {
      this.setState({ typesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });

      if(isLoading)
        this.setState({ loading: false });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };

  async getPatrimonios(id) {

    const result = await CalledService.getPatrimonyByOrg(id);
    // PapiroConsole.log(result);
    if (result.success) {
      this.setState({ patrimonyList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };

  async getDepartamentsByOrganizationCategoryId(id) {
    this.setState({ loading: true });
    var result = await getDepartamentsByOrganizationCategoryIdNew(id);
    if (result.success) {
      if (result.data && result.data.length > 0) {
        if (this.isClient) {
          if (this.state.called.departamentId && this.state.called.departamentId.id) {
            var updatedList = result.data.filter(item => item.id == this.state.called.departamentId.id)
            if (updatedList)
              updatedList = updatedList[0]

            if (result.data.length == 1) {
              this.setState(prevState => ({
                called: {
                  ...prevState.called,
                  departamentId: result.data[0]
                }
              }))
            }

            this.setState({ loading: false, DepartamentList: updatedList && updatedList.length > 1 ? updatedList.sort((a, b) => a.name.localeCompare(b.name)) : updatedList });
          }
        } else if (this.isTec) {
          if (this.state.called.departamentId && this.state.called.departamentId.id) {
            var updatedList = result.data.filter(item => item.id == this.state.called.departamentId.id)

            if (result.data.length == 1) {
              this.setState(prevState => ({
                called: {
                  ...prevState.called,
                  departamentId: result.data[0]
                }
              }))
            }

            this.setState({ loading: false, DepartamentList: updatedList && updatedList.length > 1 ? updatedList.sort((a, b) => a.name.localeCompare(b.name)) : updatedList });
          } else {
            /*if(result.data.length == 1) {
              this.setState(prevState => ({
                called: {
                  ...prevState,
                  departamentId: result.data[0]
                }
              }))
            }*/

            this.setState({ loading: false, DepartamentList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
          }
        } else {
          /*if(result.data.length == 1) {
            this.setState(prevState => ({
              called: {
                ...prevState,
                departamentId: result.data[0]
              }
            }))
          }*/

          this.setState({ loading: false, DepartamentList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
        }
        this.setState({ newCategoryDepartament: false })
      } else {
        PapiroConsole.log("=== this.getOrganizationDepartaments ===")
        PapiroConsole.log(this.state.called.organization)
        this.getOrganizationDepartaments(this.state.called.organization.id)
      }
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  async getOrganizationDepartaments(id) {
    var result = await OrgDepartamentService.getOrganizationsDepartaments(id);
    if (result.success) {
      this.setState({ DepartamentList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data, newCategoryDepartament: true });
    } else {
      const intl = this.props.intl;
      this.setState({
        newCategoryDepartament: false,
        loading: false, openNotification: true, notificationVariant: 'error',
        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  async getDepartamentByUserId(isLoading = false) {
    const { userSession } = this.props;
    if(isLoading)
      this.setState({ loading: true });

    var result = await getDepartamentByUserId(userSession.user.id);
    if (result.success) {
      this.setState({ userDepartamentId: result.data });
      if(isLoading)
        this.setState({ loading: false });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  }

  async getCategories() {
    this.setState({ loading: true });

    var result = await CalledService.getAllCategoriesWithParent();

    if (result.success) {
      this.setState({ loading: false, categoriesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };
  async getCategoriesByOrganization(organizationId) {
    const intl = this.props.intl;
    var result = await CalledService.getCategoriesByOrganization(organizationId);

    if (result.success) {
      if (this.isTec == false) {
        if (result.data.length < 1 && organizationId.toString().indexOf(",") == -1) {
          if (this.AdmOrganization) {
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.missing.organization.categories"}),
              notificationLinkMove: "organizationcategories"
            });
          } else {
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.missing.organization.categories"})
            });
          }
        }
      }

      this.setState({ categoriesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };

  

  getRootCategoriesByOrganization = async (organizationId) => {
    const intl = this.props.intl;

    var result = await CalledService.getRootCategoriesByOrganization(organizationId);
    if (result.success) {
      if (this.isTec == false) {
        if (result.data.length < 1 && organizationId.toString().indexOf(",") == -1) {
          if (this.AdmOrganization) {
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.missing.organization.categories"}),
              notificationLinkMove: "organizationcategories"
            });
          } else {
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.missing.organization.categories"})
            });
          }
        }
      }
      // <Grid item xs={1} sm={1} style={{ display: 'flex', alignItems: 'center', fontSize: '2em' }}>&gt;</Grid>
      this.setState({
        levelCategories: {
          rootCategoriesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data
        },
        categoryLists: [
          result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data
        ]
      });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };

  returnFields(){
    var campoTexto = []
    var arrayseleção = []
    var fieldType = 0
    
    
    
   if(this.state.organizationFieldList.length > 0){
      this.state.organizationFieldList.forEach(element => {
        fieldType = element.organizationfieldtype
        
        if(element.organizationfieldoptionsvalues.length > 0){
          element.organizationfieldoptionsvalues.forEach(element2 => {
            
              if(fieldType == 2 || fieldType == 4 ){
                arrayseleção.push(element2)
              }

          });
          if( fieldType == 2){
            campoTexto.push(
                  <Grid item xs={6} sm={6}>
                    <FormControl required={element.isrequired}>
                    <FormLabel id="demo-radio-buttons-group-label">{element.name}</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      
                    >
                      {arrayseleção.map(item =>
                          <FormControlLabel name={'ofield-' + element.id} value={item.name +'\n'+item.id} stateName={'ofield-' + element.name} onChange={this.handleChangeRadio} control={<Radio required={element.isrequired} />} label={item.name} />
                      )}
                    </RadioGroup>
                </FormControl>
              </Grid>
             
              
            )
            arrayseleção =[]
          } 
          if( fieldType == 4){
            campoTexto.push(
              <Grid item xs={6} sm={6}>
                <SimpleAutoComplete label={element.name} options={arrayseleção} stateName={'ofield-' + element.id} changeSelect={this.changeValues}  required={element.isrequired} />
              </Grid>
              
            )
            arrayseleção =[]
          } 
        }


        if(fieldType == 1){
            campoTexto.push(
              <Grid item xs={6} sm={6}>
                <TextField
                  autoComplete="fname"
                  name={'ofield-' + element.id}
                  variant="outlined"
                  id={'ofield-' + element.id}
                  label={element.name}
                  fullWidth
                  onChange={this.handleInputChange}
                  inputProps={{ maxLength: element.maxlength ? element.maxlength : 250 }}
                  required={element.isrequired}
                />
            </Grid>
          
          ) 
        }
        if(fieldType == 3){               
                
          campoTexto.push(
              <Grid item xs={6} sm={6}>
                <SimpleCheckBox
                  selected={true}
                  label={element.name}
                  name={'ofield-' + element.id}
                  stateName={'ofield-' + element.id}
                  changeSelect={this.changeValuesCheckbox}
                />
            </Grid>
          ) 
        }
    
      });

     
    }


   
    

    this.setState({ campoTexto:campoTexto   });


  }

  GoodRender = (thing, place) => {
    let extradiv = document.getElementsByClassName('categoriesByLevels')[0];
    ReactDOM.render(thing, extradiv);
    extradiv = document.getElementsByClassName('categoriesByLevels')[0];
    place.innerHTML += extradiv.innerHTML;
  }

  getByParentOrganizationCategoryId = async (parentId, stateName = '') => {
    const intl = this.props.intl;

    PapiroConsole.log("=== getByParentOrganizationCategoryId.map ===")
    PapiroConsole.log(stateName)
    
    var result = await CalledService.getByParentOrganizationCategoryId(parentId);
    if (result && result.success) {
      let firstCategoryId = null
      let rootCategoriesList = null
      if(stateName.includes("lcat-")) {
        let stateNameSplit = stateName.split("-")
        let stateNameId = stateNameSplit[1]
        stateNameId = parseInt(stateNameId)

        let listLevelCatArr = []
        let levelCatArr = []

        Object.keys(this.state.levelCategories).forEach((key) => {
          if (key.includes("lcatlist-")) {
            let lCatListSplit = key.split("-")
            let lCatListId = lCatListSplit[1]
            lCatListId = parseInt(lCatListId)

            PapiroConsole.log("=== Object.keys(this.state.levelCategories) ===")
            PapiroConsole.log(lCatListId)
            PapiroConsole.log(stateNameId)

            if(lCatListId < stateNameId)
              listLevelCatArr.push(this.state.levelCategories[key])
          } else
            rootCategoriesList = this.state.levelCategories[key] && this.state.levelCategories[key].length > 1 ? this.state.levelCategories[key].sort((a, b) => a.name.localeCompare(b.name)) : this.state.levelCategories[key]
        })
        Object.keys(this.state.categoriesByLevels).forEach((key) => {
          if (key.includes("lcat-")) {
            let lCatSplit = key.split("-")
            let lCatId = lCatSplit[1]
            lCatId = parseInt(lCatId)

            PapiroConsole.log("=== Object.keys(this.state.categoriesByLevels) ===")
            PapiroConsole.log(lCatId)
            PapiroConsole.log(stateNameId)

            if(lCatId < stateNameId)
              levelCatArr.push(this.state.categoriesByLevels[key])
          } else
            firstCategoryId = this.state.categoriesByLevels[key]
        })

        await this.setState({
          levelCategories: {
            rootCategoriesList: rootCategoriesList && rootCategoriesList.length > 1 ? rootCategoriesList.sort((a, b) => a.name.localeCompare(b.name)) : rootCategoriesList,
            ...listLevelCatArr,
          }
        })
        await this.setState({
          categoriesByLevels: {
            firstCategoryId: firstCategoryId,
            ...levelCatArr,
          }
        })

        let childrens =
          <Grid item xs={11} sm={3}>
            <SimpleAutoCompleteByName
              intl={intl}
              label={intl.formatMessage({ id: "category" })}
              options={this.state.levelCategories.rootCategoriesList}
              stateName='firstCategoryId'
              name='firstCategoryId'
              changeSelect={this.changeValuesCategoriesByLevels}
              selected={this.state.categoriesByLevels.firstCategoryId} required />
          </Grid>
        
        let levelCategoryId = 1

        listLevelCatArr.map((item, key) => {
          let lCatListSplit = key.split("-")
          let lCatListId = lCatListSplit[1]

          PapiroConsole.log("=== listLevelCatArr.map ===")
          PapiroConsole.log(key)
          PapiroConsole.log(levelCategoryId)

          if(parseInt(lCatListId) > levelCategoryId)
            levelCategoryId = lCatListId

          childrens += <Grid item xs={11} sm={3}>
              <SimpleAutoCompleteByName
                intl={intl}
                label={intl.formatMessage({ id: "category" })}
                options={this.state.levelCategories[`lcatlist-${lCatListId}`] || []}
                stateName={`lcat-${lCatListId}`}
                name={`lcat-${lCatListId}`}
                changeSelect={this.changeValuesCategoriesByLevels}
                selected={this.state.categoriesByLevels[`lcat-${lCatListId}`]} required />
            </Grid>

          return { levelCategoryId: levelCategoryId }
        })

        let rootElement = document.getElementById("categoriesByLevels")
        rootElement && ReactDOM.render(
          childrens,
          rootElement
        )

        this.levelCategoryId = levelCategoryId
        await this.setState({ levelCategoryId: levelCategoryId })
      } else {
        await this.setState({
          levelCategories: {
            rootCategoriesList: this.state.levelCategories.rootCategoriesList && this.state.levelCategories.rootCategoriesList.length > 1 ? this.state.levelCategories.rootCategoriesList.sort((a, b) => a.name.localeCompare(b.name)) : this.state.levelCategories.rootCategoriesList,
          }
        })
        await this.setState({
          categoriesByLevels: {
            firstCategoryId: this.state.categoriesByLevels.firstCategoryId,
          }
        })

        let childrens =
          <Grid item xs={11} sm={3}>
            <SimpleAutoCompleteByName
              intl={intl}
              label={intl.formatMessage({ id: "category" })}
              options={this.state.levelCategories.rootCategoriesList}
              stateName='firstCategoryId'
              name='firstCategoryId'
              changeSelect={this.changeValuesCategoriesByLevels}
              selected={this.state.categoriesByLevels.firstCategoryId} required />
          </Grid>
        
        let rootElement = document.getElementById("categoriesByLevels")
        rootElement && ReactDOM.render(
          childrens,
          rootElement
        )

        await this.setState({ levelCategoryId: 1 })
        this.levelCategoryId =  1
      }

      if(result.data && result.data.length > 0) {
        let levelCategoryId = this.state.levelCategoryId + 1
        await this.setState({ levelCategoryId: levelCategoryId })
        this.levelCategoryId = this.levelCategoryId + 1
        
        this.setState(prevState=> ({
          levelCategories: {
            ...prevState.levelCategories,
            [`lcatlist-${this.levelCategoryId}`]: result.data,
          },
          categoriesByLevels: {
            ...prevState.categoriesByLevels,
            [`lcat-${this.levelCategoryId}`]: { id: 0, name: '' }
          }
        }), () => {
          let rootElement = document.getElementById("categoriesByLevels")
          rootElement && ReactDOM.render(
            ReactDOM.createPortal(
              <Grid item xs={1} sm={1} style={{ display: 'flex', alignItems: 'center', fontSize: '2em' }}>&gt;</Grid>,
              rootElement
            ),
            document.createElement('div')
          )

          rootElement = document.getElementById("categoriesByLevels")
          rootElement && ReactDOM.render(
            ReactDOM.createPortal(
              <Grid item xs={11} sm={3}>
                <SimpleAutoCompleteByName
                  intl={intl}
                  label={intl.formatMessage({ id: "category" })}
                  options={this.state.levelCategories[`lcatlist-${this.levelCategoryId}`] || []}
                  stateName={`lcat-${this.levelCategoryId}`}
                  name={`lcat-${this.levelCategoryId}`}
                  changeSelect={this.changeValuesCategoriesByLevels}
                  selected={this.state.categoriesByLevels[`lcat-${this.levelCategoryId}`]} required />
              </Grid>,
              rootElement
            ),
            document.createElement('div')
          )
        });
      }
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  };



  async getOrganizationFields(organizationId) {

    PapiroConsole.log("GET ORGANIZATION FIELDS")

    var result = await CalledOrganizationChecklistService.getActiveOrganizationFieldsByOrganizationAndCategories(organizationId);

    if (result.success) {
      
      this.setState({ organizationFieldList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
      Object.keys(this.state.called).forEach((key) => {

        if(key.includes("ofield-")){
          this.setState(prevState => ({
            called: {
                ...prevState.called,
                [key]: undefined

              }
          }))
      }
          
      })
      
      if(result.data){
        

        result.data.forEach(element => {

          if(element.organizationfieldtype == 3){
              this.setState(prevState => ({
                called: {
                    ...prevState.called,
                    ['ofield-' + element.id]: true

                  }
              }))
          }
          else{
            this.setState(prevState => ({
              called: {
                  ...prevState.called,
                  ['ofield-' + element.id]: ""

                }
            }))
          }
          
            
          
        });

        this.returnFields();

      }  

      //this.returnFields();
      
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };

  async getCategoryFields(organizationCategoryId) {

    

    var result = await CalledOrganizationChecklistService.GetByOrganizationCategoryIdAndActive(organizationCategoryId);
    PapiroConsole.log("result aqui do organizationcategory")
    PapiroConsole.log(result)
    if (result.success) {
      PapiroConsole.log(result.data.length)
      if(result.data.length > 0){
         
      this.setState({ organizationFieldList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
      Object.keys(this.state.called).forEach((key) => {

        if(key.includes("ofield-")){
          this.setState(prevState => ({
            called: {
                ...prevState.called,
                [key]: undefined

                }
            }))
          }
        })

      if(result.data){

          result.data.forEach(element => {

            if(element.organizationfieldtype == 3){
                this.setState(prevState => ({
                  called: {
                      ...prevState.called,
                      ['ofield-' + element.id]: true

                    }
                }))
            }
            else{
              this.setState(prevState => ({
                called: {
                    ...prevState.called,
                    ['ofield-' + element.id]: ""

                  }
              }))
            }
            
              
            
          });

          this.returnFields();

      }  

      }else{
        PapiroConsole.log("entrei aqui no else")
        this.getOrganizationFields(this.organizationId)
      }

      
     

      //this.returnFields();*/


      
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };


  async getParentCalleds(organizationid) {
    this.setState({ loadingParentCalleds: true })
    var result = await CalledService.getParentCalled(organizationid);

    if (result.success) {

      let parents = result.data.map(item => {
        return {
          ...item,
          name: `${item.id}: ${item.title}`,
          parentname: `${item.id}: ${item.title}`
        }

      })

      await this.setState({ loadingParentCalleds: false, parentCalledsList: parents && parents.length > 1 ? parents.sort((a, b) => {
        if (a.name && a.name.length > 0)
         return a.name.localeCompare(b.name)
        else
          return a.parentname.localeCompare(b.parentname)
      }) : parents });
      // PapiroConsole.log(this.state.parentCalledsList);
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, loadingParentCalleds: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };

  getOriginRequests = async () => {
    const result = await getOriginRequests()
    if (result && result.success) {
      
      this.setState({ originRequestList: result.data ? result.data : [] })
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  getOriginRequestsByFlag = async () => {
    this.setState({ loading: true});
    const result = await getOriginRequestsByFlag()
    if (result && result.success) {
      
      
      var getdefaultOriginElement = null
      if(result.data.length > 0 && this.state.originrequestid != 0){

        result.data.forEach(element => {
              if(element.id == this.state.originrequestid){
                getdefaultOriginElement = element
              }
        });
      }

     
       this.setState({ originRequestList: result.data ? result.data : [], loading: false })
      if(getdefaultOriginElement !=null){
        await this.setState(prevState => ({
          called: {
            ...prevState.called,
            originRequest: getdefaultOriginElement
          }
        }))
      }
      
     
     
        
      
     
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  async getOrganizations(isLoading = false) {
    if(isLoading)
      this.setState({ loading: true });
    const { userSession } = this.props
    let orgData = []
    PapiroConsole.log(userSession)
    PapiroConsole.log("userSession")
    if(userSession && userSession.user && userSession.user.userorganizations && userSession.user.userorganizations.length > 0) {
      orgData = userSession.user.userorganizations
      PapiroConsole.log(orgData)
      PapiroConsole.log("orgData")
    } else {
      const result = await CalledService.getOrganizations()
      if (result.success)
        orgData = result.data
      else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(result);
      }
    }

    if(orgData != null) {

      orgData= orgData.filter(c => c.active == true);
      if (this.globalAdm) {
        this.setState({ organizationsList: orgData && orgData.length > 1 ? orgData.sort((a, b) => a.name.localeCompare(b.name)) : orgData });
        if(isLoading)
          this.setState({ loading: false });
      }
      else {
        //PapiroConsole.log("this.organizationId : "+this.organizationId);
        this.setState({ organizationsList: orgData && orgData.length > 1 ? orgData.sort((a, b) => a.name.localeCompare(b.name)) : orgData });
        await this.updateOrganization(this.organizationId);
        
        this.setState({ organization: this.organizationId });
        if(isLoading)
          this.setState({ loading: false });
      }
      if(this.globalAdm || this.AdmOrganization) {
       
        if(orgData && orgData.length == 1) {

          
          this.setState((prevState) => ({
            called: {
              ...prevState.called,
              organization: orgData[0]
            }
          }), () => {
            this.organizationId = orgData[0].id
            this.updateOrganization(this.organizationId);
            
          })
          
        }
        else{
          this.setState(prevState => ({
            called: {
              ...prevState.called,
              organization: null
            }
          }))
        }
      }
    } else {
      orgData = []
      this.setState({ organizationsList: orgData && orgData.length > 1 ? orgData.sort((a, b) => a.name.localeCompare(b.name)) : orgData });
      if(isLoading)
        this.setState({ loading: false });
    }
  };




  async getOrganizationsByCategory(categoryId) {
    this.setState({ loading: true });

    const result = await CalledService.getOrganizationsByCategory(categoryId);
    // PapiroConsole.log(result);
    if (result.success) {
      if (this.globalAdm) {
        this.setState({ loading: false, organizationsList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
      }
      else {
        var filteredOrg = result.data.filter(org => org.id == this.organizationId)
        this.setState({ organizationsList: filteredOrg && filteredOrg.length > 1 ? filteredOrg.sort((a, b) => a.name.localeCompare(b.name)) : filteredOrg });

      }
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };


  async getRequestingUsers(organizationId, locationid, isLoading = false) {
    
	return false; // COLOCADO POIS A LEITURA DE CLIENTES PROS COMBOS DE SOLICITANTE E OBSERVADOR SERÁ DINAMICA
	this.setState({ loadingRequestingUsers: true });

    const result = await CalledService.getRequestingUsersByLocation(organizationId, locationid);
    const intl = this.props.intl;

    if (result.success) {
      if (this.isTec == false) {
        if (result.data.length < 1 && organizationId.toString().indexOf(",") == -1) {
          if (this.AdmOrganization) {
            this.setState({
              loading: false, loadingRequestingUsers: false, openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.organization.no.registered.requester"}),
              notificationLinkMove: "users"
            });
          } else {
            this.setState({
              loading: false, loadingRequestingUsers: false, openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.organization.no.registered.requester"})
            });
          }
        }
      }
      let reordernewarrayuserteclist = new Array();
      result.data.map(item => {
        item.name =  item.name + " ("+item.email+")";
        reordernewarrayuserteclist.push(item);
      })
      this.setState({ loadingRequestingUsers: false, clientUsersList: reordernewarrayuserteclist && reordernewarrayuserteclist.length > 1 ? reordernewarrayuserteclist.sort((a, b) => a.name.localeCompare(b.name)) : reordernewarrayuserteclist });
      if(isLoading)
        this.setState({ loading: false, loadingRequestingUsers: false });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, loadingRequestingUsers: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };

  async getTechnicalTeams(organizationId, organizationCategoryId) {
    if (organizationCategoryId && organizationCategoryId > 0) {

      const result = await CalledService.getTechinicalTeamsByOrganizationIdAndOrganizationCategoryId(organizationId, organizationCategoryId);
      const intl = this.props.intl;

      if (result.success) {
        if(result.data.length > 0) {
          let reordernewarrayuserteclist = result.data && result.data.length > 0 ? result.data : new Array();
          this.setState({ techinicalTeamList: reordernewarrayuserteclist && reordernewarrayuserteclist.length > 1 ? reordernewarrayuserteclist.sort((a, b) => a.name.localeCompare(b.name)) : reordernewarrayuserteclist });
          if (this.state.techinicalTeamSettings && this.state.techinicalTeamSettings.id && this.state.techinicalTeamSettings.id > 0) {
            let find = reordernewarrayuserteclist.find(item => item.id == this.state.techinicalTeamSettings.id)
            if (find != null)
              this.setState(prevState => ({ called: { ...prevState.called, techinicalTeam: this.state.techinicalTeamSettings } }))
            else
            this.setState(prevState => ({ called: { ...prevState.called, techinicalTeam: 0 } }))
          } else
            this.setState(prevState => ({ called: { ...prevState.called, techinicalTeam: 0 } }))
        } else {
          this.getTechnicalUsers(organizationCategoryId, organizationId)
        }
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(result);

      }
    }
  }

  async getTechnicalUsersByTechnicalTeam(technicalTeamId) {
    if (technicalTeamId && technicalTeamId > 0) {
      this.setState({ loading: true });

      const result = await CalledService.getTechnicalUsersByTechnicalTeamIdAndRoleType(technicalTeamId, 2);
      const intl = this.props.intl;

      if (result.success) {
        if(result.data.length > 0) {
          let reordernewarrayuserteclist = new Array();
          result.data.map(item => {
              if (item && item.user != null) {
                let user = item.user
                user.name = `${user.name} (${user.email})`
                reordernewarrayuserteclist.push(user)
              }
          })
          let defaultUser = reordernewarrayuserteclist.find(u => u.id == this.state.defaultTechinicalSettings)
          if ( defaultUser == null || defaultUser == undefined) {
            defaultUser = { id: 0, name : ''}
          }
          this.setState({ loading: false, technicalUsersList: reordernewarrayuserteclist && reordernewarrayuserteclist.length > 1 ? reordernewarrayuserteclist.sort((a, b) => a.name.localeCompare(b.name)) : reordernewarrayuserteclist });
          this.setState((prevState) => ({
            ...prevState,
            called: {
              ...prevState.called,
              assignedUser: defaultUser
            }
          }))
        } else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "info",
            notificationMessage: intl.formatMessage({id:"called.registration.category.missing.team"})
          })
        }
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(result);

      }
    }
  }

  async getTechnicalUsers(categoryId, organizationId) {
    if (categoryId && categoryId > 0) {
      this.setState({ loading: true });

      const result = await CalledService.getTechnicalUsers(categoryId, organizationId);
      const intl = this.props.intl;

      if (result.success) {
        if(result.data.length > 0) {
          let reordernewarrayuserteclist = new Array();
          result.data.map(item => {
              item.name =  item.name + " ("+item.email+")";
              reordernewarrayuserteclist.push(item);
          })
          let defaultUser = reordernewarrayuserteclist.find(u => u.id == this.state.defaultTechinicalSettings)
          if ( defaultUser == null || defaultUser == undefined) {
            defaultUser = { id: 0, name : ''}
          }
          this.setState({ loading: false, technicalUsersList: reordernewarrayuserteclist && reordernewarrayuserteclist.length > 1 ? reordernewarrayuserteclist.sort((a, b) => a.name.localeCompare(b.name)) : reordernewarrayuserteclist });
          this.setState((prevState) => ({
            ...prevState,
            called: {
              ...prevState.called,
              assignedUser: defaultUser
            }
          }))
        } else {
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "info",
            notificationMessage: intl.formatMessage({id:"called.registration.category.missing.team"})
          })
        }
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(result);

      }
    }
  };

  async getOrganizationCategoryDepartaments(organizationCategoryId) {
    this.setState({ loading: true })

    const result = await CalledService.getOrganizationCategoryDepartaments(organizationCategoryId)

    if (result.success) {
      this.setState({ loading: false })
    } else {
      const intl = this.props.intl
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.erros ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      })
    }
  }

  async getUsersExceptClients(organizationId) {
    this.setState({ loading: true });

    const result = await CalledService.getUsersExceptClients(organizationId);

    if (result.success) {
      this.setState({ loading: false, usersList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };

  async getOrganizationAddresses(organizationId) {
    const result = await CalledService.getOrganizationAddresses(organizationId);
    const intl = this.props.intl;


    if (result.success) {
      if (this.isTec == false) {
        if (result.data.length < 1 && organizationId.toString().indexOf(",") == -1) {
          if (this.AdmOrganization) {
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.organization.no.registered.address"}),
              notificationLinkMove: "organizations"
            });
          } else {
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.organization.no.registered.address"})
            });
          }
        }
      }

      if(this.globalAdm || this.AdmOrganization) {
        if(result.data && result.data.length == 1) {
          PapiroConsole.log("=== AddressId ===")
          PapiroConsole.log(this.state.called)
          PapiroConsole.log(this.organizationId)

          this.setState(prevState => ({
            called: {
              ...prevState.called,
              AddressId: result.data[0]
            }
          }), () => { 
            PapiroConsole.log("=== After SetState ===")
            PapiroConsole.log(this.state.called)
            this.getRequestingUsers(this.organizationId, this.state.called.AddressId.id)
           })
        }
      }

      this.setState({ organizationAddressesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };

  async getUserAddresses(userId) {
    const intl = this.props.intl;
    this.setState({ loading: true })
    const result = await CalledService.getUserAddresses(userId);
    if (result.success) {
      if (this.globalAdm || this.AdmOrganization) {
        if (result.data.length < 1) {
          if (this.AdmOrganization) {
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"user.no.registered.addresses"}),
              notificationLinkMove: "organizations"
            });
          } else {
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"user.no.registered.addresses"})
            });
          }
        }
      }

      if(this.globalAdm || this.AdmOrganization) {
        if(result.data && result.data.length == 1) {
          PapiroConsole.log("=== AddressId ===")
          PapiroConsole.log(this.state.called)
          PapiroConsole.log(this.organizationId)

          this.setState(prevState => ({
            called: {
              ...prevState.called,
              AddressId: result.data[0]
            }
          }))
        }
      }

      this.setState({ loading: false, userAddressesList: result.data && result.data.length > 1 ? result.data.sort((a, b) => a.name.localeCompare(b.name)) : result.data });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };

  async getClientsByOrganizationId() {

    this.setState({ loading: true })
    PapiroConsole.log(this.organizationId)
    PapiroConsole.log("this.organizationId 1")
    const result = await CalledService.getClientsByOrganizationId(this.organizationId);
    const intl = this.props.intl;

    if (result.success) {
      if (this.isTec == false) {

        PapiroConsole.log(result.data.length)
        PapiroConsole.log(this.organizationId)
        PapiroConsole.log("this.organizationId")
        if (result.data.length < 1 && this.organization && this.organizationId.toString().indexOf(",") == -1) {
          if (this.AdmOrganization) {
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.organization.no.registered.requester"}),
              notificationLinkMove: "users"
            });
          } else {
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.organization.no.registered.requester"})
            });
          }
        }
      }
      let reordernewarrayuserteclist = new Array();
      result.data.map(item => {
        item.name =  item.name + " ("+item.email+")";
        reordernewarrayuserteclist.push(item);
      })

      this.setState({ loading: false, clientUsersList: reordernewarrayuserteclist && reordernewarrayuserteclist.length > 1 ? reordernewarrayuserteclist.sort((a, b) => a.name.localeCompare(b.name)) : reordernewarrayuserteclist });
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false, openNotification: true, notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);

    }
  };

  async getItens(page = 1) {
    let idOrganization = null;
    const { userSession } = this.props;
    this.setState({ loading: true });
    

    let url = `/calleds/classifications/paginate/${this.state.userId}?page=${page}`;

    if (this.state.searchValue != "" && this.state.filterSelected != 0) {
      url += `&search=${this.state.searchValue}&field=${this.state.filterSelected}`;
    }
    
    Api.get(url)
      .then((result) => {
        if (result.data.success) {

          this.setState({ loading: false })
          // PapiroConsole.log(result.data)


        }
      })
      .catch((err) => {
        const intl = this.props.intl;
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage:
            err.response &&
              err.response.data &&
              err.response.data.errors &&
              err.response.data.errors[0]
              ? err.response.data.errors[0]
              : intl.formatMessage({ id: "process.error" }),
        });
        Api.kickoff(err);
      });
  }


  async getOrganizationSettings() {
    const intl = this.props.intl;
    let infos;
    if (this.organizationId == 0 || this.organizationId == null) {
      return;
    }
    await Api.get(`/organizationsettings/organization/${this.organizationId}`)
      .then(result => {
        if (result.data.success) {
          if (result.data.length < 1) {
            this.setState({
              openNotification: true, notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.registration.missing.organization.config"})
            });
          }
          infos = result.data.data;
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: "error",
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
          Api.kickoff(result);

        }
      })
      .catch(err => {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: "error",
          notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
        });
        Api.kickoff(err);

      });
    return infos;
  };

  randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  async getCep(cepNumber) {
    return new Promise(function(resolve, reject) {
      
      fetch("https://viacep.com.br/ws/".concat(cepNumber, "/json/")).then(function (response) {
        return response.json();
      }).then(function (data) {
        // console.log(data);
        resolve(data);
      })["catch"](function (err) {
        // console.log(err);
        reject(err);
      })["finally"](function (data) {
        return data;
      });

    });
  }

  async handleInputChangeAddress(e) {
    const name = e.target.name;
    const value = e.target.value;

    if(name == 'zipcode'){
      if(e.target.value === '' || /^\d+$/.test(e.target.value)) { 
        this.setState(prevState => ({
          address: {
            ...prevState.address,
            [name]: value
          }
        }));
        if(value.length == 8){
          this.setState({ loading: true });
          var data = await this.getCep(value);
          this.setState(prevState => ({
            address: {
              ...prevState.address,
              ['street']: data.logradouro,
              ['complement']: data.complemento,
              ['neighborhood']: data.bairro,
              ['city']: data.localidade,
              ['state']: data.uf,
              ['country']: 'BRASIL',
            }
          }));
          this.setState({ loading: false });
        }
      } else { 
          return false; 
      } 
    } else {
      this.setState(prevState => ({
        address: {
          ...prevState.address,
          [name]: value
        }
      }));
    }
  }

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    
    

    this.setState(prevState => ({
      called: {
        ...prevState.called,
        [name]: value
      }
    }));
  };

  handleInputChangeNewUser(e) {
    const name = e.target.name;
    const value = e.target.value;
    var valor = 0;
    
    if (name === 'cpfOfNewUser') {

      if(value.length <= 14){
        valor = this.cpfMask(value)
      }
      else{
        valor = this.CNPJMask(value)
      }
     
      valor.length === 0 ? this.setState(prevState => ({ itemError: { ...prevState.itemError, document: 'Campo obrigatório' } })) : ( (value.length !== 14 && value.length !== 18)  ||  !this.isCPFOrCNPJValid(value)) ? this.setState(prevState => ({ itemError: { ...prevState.itemError, document: 'CPF/CNPJ inválido' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, document: '' } }))
      this.setState(prevState => ({
        createUserCalled: {
          ...prevState.createUserCalled,
          [name]: valor
        }
      }))
    }
    
        
     else{

        this.setState(prevState => ({
          createUserCalled: {
            ...prevState.createUserCalled,
            [name]: value
          }
        }))

     } 
    

  };

   cpfMask = value => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }

   CNPJMask = (valor) => {
    return valor
      .replace(/\D/g, '') 
      .replace(/(\d{2})(\d)/, '$1.$2') 
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  };
  
   validateCpf = value => {
    let sum, rest;
  
    sum = 0
  
    if (value == "00000000000" || value == "000.000.000-00") return false;
  
    value = value.replaceAll(".", "").replaceAll("-", "")
  
    for (let i = 1; i <= 9; i++) sum += parseInt(value.substring(i -1, i)) * (11 - i)
    rest = (sum * 10) % 11
  
    if ((rest == 10) || (rest == 11)) rest = 0
    if (rest != parseInt(value.substring(9, 10))) return false
  
    sum = 0
    for (let i = 1; i <= 10; i++) sum += parseInt(value.substring(i - 1, i)) * (12 - i)
    rest = (sum * 10) % 11
  
    if ((rest == 10) || (rest == 11)) rest = 0
    if (rest != parseInt(value.substring(10, 11))) return false
    return true
  }

  isCPFOrCNPJValid(value) {
    // Remove caracteres não numéricos
    const numericValue = value.replace(/\D/g, '');

    if (numericValue.length === 11) {
      // Validação CPF
      let sum = 0;
      let remainder;

      for (let i = 1; i <= 9; i++) {
        sum += parseInt(numericValue.substring(i - 1, i)) * (11 - i);
      }

      remainder = (sum * 10) % 11;

      if (remainder === 10 || remainder === 11) {
        remainder = 0;
      }

      if (remainder !== parseInt(numericValue.substring(9, 10))) {
        return false;
      }

      sum = 0;
      for (let i = 1; i <= 10; i++) {
        sum += parseInt(numericValue.substring(i - 1, i)) * (12 - i);
      }

      remainder = (sum * 10) % 11;

      if (remainder === 10 || remainder === 11) {
        remainder = 0;
      }

      return remainder === parseInt(numericValue.substring(10, 11));
    } else if (numericValue.length === 14) {
      // Validação CNPJ
      let tamanho = numericValue.length - 2;
      let numeros = numericValue.substring(0, tamanho);
      const digitos = numericValue.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado !== parseInt(digitos.charAt(0))) {
        return false;
      }

      tamanho = tamanho + 1;
      numeros = numericValue.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }
      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

      return resultado === parseInt(digitos.charAt(1));
    }

    return false;
  }
  

  handleChange = async (e) => {

   
    let value = e.target.value

    this.setState(prevState => ({
      called: {
        ...prevState.called,
        addresstype: value,
        AddressId: 0
      }
    }))

    if (value == 2 || value == 3)
      await this.getClientsByOrganizationId()
  }

  handleChangeRadio = async (e) => {

    
    let value = e.target.value
    let name = e.target.name
    

    this.setState(prevState => ({
      called: {
        ...prevState.called,
        [name]: value
      }
    }))

    /*this.setState(prevState => ({
      radioChoice: {
        [name]: value
      }
    }))*/

  }

  handleChangeEditor(value) {
    //this.setState({ description: value });

   

	this.setState(prevState => ({
		called: {
		  ...prevState.called,
		  description: value
		}
	  }));


  }

  showMessageMissing() {

      const intl = this.props.intl;
      this.setState({
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"add.organization.to.called"})
      });
  }
 
  checkSubmit = (description) => {
    const intl = this.props.intl;

    let calledDesc = this.convertHtmlOption(description)
    let convertcalledDescAgain = this.convertHtmlOption(calledDesc)

    if(calledDesc != null){
        calledDesc = calledDesc.trim()
    }
    if(calledDesc == null || (calledDesc != null && calledDesc.length == 0 && description.indexOf('<img') == -1)) {
      this.setState({
        loading: false,
        loadingTwo: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.missing.called.description"})
      });
      return false;
    }

    convertcalledDescAgain = convertcalledDescAgain.trim()
    
    if(convertcalledDescAgain == null || convertcalledDescAgain ==''  ){

      this.setState({
        loading: false,
        loadingTwo: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"called.accompaniment.notification.missing.called.description"})
      });
      return false;

    }

    if(this.state.called.priorityid == null || (this.state.called.priorityid && this.state.called.priorityid == 0)) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"called.registration.missing.priority.field"})
      })
      return false
    }

    PapiroConsole.log("=== canselectorganizationcategoriesbylevels ===")
    PapiroConsole.log(this.state.canselectorganizationcategoriesbylevels)
    if(this.state.canselectorganizationcategoriesbylevels === true && this.state.categoryChildrens && this.state.categoryChildrens.length > 0) {
      let lastItem = this.state.categoryChildrens[this.state.categoryChildrens.length - 1]
      PapiroConsole.log("=== lastItem ===")
      PapiroConsole.log(lastItem)
      if(lastItem && lastItem.cancreatecalled != null && lastItem.cancreatecalled === false) {
        this.setState({
          loading: false,
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: intl.formatMessage({id:"called.registration.create.error.category"})
        })
        return false
      } else if(lastItem && lastItem.id == 0) {
        if(this.state.categoryChildrens[this.state.categoryChildrens.length - 2] !== undefined) {
          lastItem = this.state.categoryChildrens[this.state.categoryChildrens.length - 2]
          PapiroConsole.log("=== lastItem 2 ===")
          PapiroConsole.log(lastItem)
          if(lastItem && lastItem.cancreatecalled != null && lastItem.cancreatecalled === false) {
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"called.registration.create.error.category"})
            })
            return false
          }
        }
      }
    }
    
    if(this.state.called.CategoryId == null || ((this.state.called.CategoryId && this.state.called.CategoryId == 0) || (this.state.called.CategoryId && this.state.called.CategoryId.id && this.state.called.CategoryId.id == 0))) {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({id:"called.registration.missing.category.field"})
      })
      return false
    }

    return true
  }

  handleSubmit = async e => {
    const intl = this.props.intl;

    e.preventDefault();

    this.setState({ loading: true });

    let inputValue = ''
    let calledDescription = document.getElementById('createCalledDescription')
    PapiroConsole.log("=== calledDescription ===")
    PapiroConsole.log(calledDescription)
    if (calledDescription != null) {
      let qlEditor = calledDescription.querySelector('.ql-container .ql-editor')
      if (qlEditor != null) {
        PapiroConsole.log("=== qlEditor ===")
        PapiroConsole.log(qlEditor)
        inputValue = `${qlEditor.innerHTML}`
      }
    }

    let calledTitleValue = ''
    let calledTitle = document.getElementById('title')
    if (calledTitle != null)
      calledTitleValue = calledTitle.value
    
    await this.setState(prevState => ({ called: { ...prevState.called, description: inputValue, title: calledTitleValue } }))

    let data = new FormData();
    if(this.checkSubmit(inputValue)) {
      let addressData = null
      if (this.state.called.addresstype == "3") {
        if(this.state.address.name == null || !this.state.address.name) {
          this.setState({
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: intl.formatMessage({id:"missing.name.field"})
          })
          return false
      }
        
        let addressConfig = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        };
  
        let addressData = new FormData()
        for (let key in this.state.address) {
          if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
            addressData.append(key, moment(this.state.address[key]).format('YYYY/MM/DD HH:mm:ss'));
          } else {
            addressData.append(key, this.state.address[key]);
          }
        }

        addressData.append("organization", this.state.called.organization.id)
  
        await Api.post('/addresses', addressData, addressConfig)
          .then(result => {
            if (result.data.success) {
              addressData = result.data.data
              this.setState(prevState => ({
                called: {
                  ...prevState.called,
                  AddressId: result.data.data,
                },
                address: this.addressInitialState
              }))
            }
          })
          .catch(err => {
            if(err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0]){
              if(!isNaN(err.response.data.errors[0])){
                if(err.response.data.errors[0] == 5){
                  this.setState({
                    loading: false,
                    openDialogNewDisk: true,
                    valueNewDisk: err.response.data.errors[0],
                  });
                }else{
                  this.setState({
                    loading: false,
                    openDialogNewAttendence: true,
                    valueNewAttendence: err.response.data.errors[0],
                  });
                }
                
  
              }else{
                const intl = this.props.intl;
                this.setState({
                  loading: false,
                  openNotification: true,
                  notificationVariant: "error",
                  notificationMessage:
                    err.response && err.response.data && err.response.data.errors
                      ? err.response.data.errors[0]
                      : intl.formatMessage({ id: "process.error" }),
                });
              }
              
            }else if (err.response && err.response.data && err.response.data.errors) {
  
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
              });
            }
            else {
  
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage: intl.formatMessage({ id: "process.error" })
              });
            }
          })
      }

    const clientInfos = await this.getOrganizationSettings();
      PapiroConsole.log("=== clientInfos ===")
      PapiroConsole.log(clientInfos)
    if (clientInfos) {
      if (this.isClient) {
        this.setState(prevState => ({
          called: {
            ...prevState.called,
            CategoryId: clientInfos.category.id,
            originRequest: clientInfos.originrequest,
            status: clientInfos.status.id,
            type: clientInfos.type.id,
            techinicalTeam: clientInfos.techinicalteam ? clientInfos.techinicalteam.id : 0,
            assignedUser: clientInfos.defaulttechinicalid ? clientInfos.defaulttechinicalid : 0,
          },
          techinicalTeamSettings: clientInfos.techinicalteam ? clientInfos.techinicalteam.id : 0,
          defaultTechinicalSettings: clientInfos.defaulttechinicalid ? clientInfos.defaulttechinicalid : 0
        }));
      } else {
        this.setState(prevState => ({
          called: {
            ...prevState.called,
            originRequest: this.state.canselectoriginrequestincalledcreation ? this.state.called.originRequest : clientInfos.originrequest,
            status: clientInfos.status.id,
          }
        }));
      }

      this.state.files.forEach(file => data.append('filesUpload', file));
      let calledOrganizationFieldList = []

      if(this.state.newAttendenceAccept){
        data.append("acceptNewAttendence", true);
        this.setState({ newAttendenceAccept: false });
      }

      if(this.state.newDiskAccept){
        data.append("AcceptNewDisk", true);
        this.setState({ newDiskAccept: false });
      }
      
      if(this.state.knowledgebase != null){
         data.append("KnowledgeBaseId", this.state.knowledgebase.id);
      }

      for (let key in this.state.called) {

        if (key === "ParentCalledId") {
          if (this.state.called.ParentCalledId !== 0) {
            data.append("ParentCalledId", this.state.called.ParentCalledId.id);
          }
        }
        if (key === 'dueDate') {
          data.append(key, moment(this.state.called[key]).format("YYYY/MM/DD HH:mm:ss"));
        }
        else if (key.toString().toLowerCase().indexOf('categoryid') > -1) {
          data.append("OrganizationCategory", this.state.called[key].id);
        } else if (key.toString().toLowerCase().indexOf('departamentid') > -1) {
          let deptVal = this.state.called[key].id
          if (deptVal == null || typeof deptVal === 'undefined')
            deptVal = 0
          data.append("OrganizationDepartamentId", deptVal);
        } else if (key.toString().toLowerCase().indexOf('requestinguser') > -1) {
          if (this.state.called[key] && this.state.called[key].id && this.state.called[key].id > 0) {
            data.append("requestingUser", this.state.called[key].id);
          } else {
            data.append("requestingUser", 0);
          }
        }
        else if (key.toString().toLowerCase().indexOf('assigneduser') > -1) {
          if (this.state.called[key].id) {
            data.append("assignedUser", this.state.called[key].id);
          } else {
            data.append("assignedUser", 0);
          }
        }
        else if (key.toString().toLowerCase().indexOf('observantuser') > -1) {
          if (this.state.called[key].id) {
            data.append("observantUser", this.state.called[key].id);
          } else {
            data.append("observantUser", 0);
          }
        }
        else if (key.toString().toLowerCase().indexOf('techinicalteam') > -1) {
          if (this.state.called[key] && this.state.called[key].id) {
            data.append("techinicalTeamId", this.state.called[key].id);
          } else if (this.state.called[key] && this.state.called[key] > 0) {
            data.append("techinicalTeamId", this.state.called[key]);
          } else {
            data.append("techinicalTeamId", 0);
          }
        }else if (key.toString().toLowerCase().indexOf('originrequest') > -1) {
          if (this.state.called[key] && this.state.called[key].id)
            data.append("originRequest", this.state.called[key].id)
          else if (this.state.called[key] && this.state.called[key] > 0)
            data.append("originRequest", this.state.called[key])
          else
            data.append("originRequest", 0)
        } else if (key.toString().toLowerCase().indexOf('addressid') > -1) {
          PapiroConsole.log("=== addressid ===")
          PapiroConsole.log(this.state.called[key])
          PapiroConsole.log("this.state.called.addresstype =>")
          PapiroConsole.log(this.state.called.addresstype)
          PapiroConsole.log(typeof this.state.called.addresstype)
          PapiroConsole.log("addressData =>")
          PapiroConsole.log(addressData)
          if (this.state.called[key].id) {
            if (this.state.called[key].id > 0)
              data.append("AddressId", this.state.called[key].id);
            else if (this.state.called.addresstype == "3" && addressData != null) {
              data.append("AddressId", addressData.id);
            }
          } else {
            if (this.state.called.addresstype == "3" && addressData != null)
              data.append("AddressId", addressData.id);
            else
              data.append("AddressId", 0);
          }
        }
        else if (key.toString().toLowerCase().indexOf('organization') > -1) {
          if (this.state.called[key].id) {
            data.append("organization", this.state.called[key].id);
          } else {
            data.append("organization", 0);
          }
        }
        else if (key.toString().toLowerCase().indexOf('addresstype') > -1) {
          let addresstypeNumber = parseInt(this.state.called[key])
          if (!isNaN(addresstypeNumber))
            data.append("addresstype", addresstypeNumber);
          else
            data.append("addresstype", 1);
        }
        else {
          if (key.toString().toLowerCase().indexOf("ofield-") > -1) {
            calledOrganizationFieldList.push(this.state.called.key)
          } else
            data.append(key, this.state.called[key]);
        }
      }

      var SecurityPassword = this.randomIntFromInterval(1000, 99999)

      data.append("SecurityPassword", SecurityPassword.toString());

      let list = this.state.called['patrimonyId'];

      if (list != null && list != "") {

        let result = "";

        for (let value in this.state.called['patrimonyId']) {
          result = this.state.called['patrimonyId'][value].id + "," + result
        }

        data.append("calledOrganizationPatrimonyId", result);
      }

      let listKnowledgebaseAttachments = this.state.called.knowledgebaseattachments
      if(listKnowledgebaseAttachments != null && listKnowledgebaseAttachments != "") {
        let knowledgeBaseAttachmentsIds = ""
        listKnowledgebaseAttachments.map(item => {
          knowledgeBaseAttachmentsIds = `${item.id},${knowledgeBaseAttachmentsIds}`
        })

        data.append("knowledgeBaseAttachmentsIds", knowledgeBaseAttachmentsIds)
      }

      data.append("newCategoryDepartament", this.state.newCategoryDepartament)

      let orgFields = ""
      let orgFieldValues = ""
      
      Object.keys(this.state.called).forEach((key) => {
        if (key.includes("ofield-") && this.state.called[key] != undefined) {
            PapiroConsole.log(this.state.called[key])
            PapiroConsole.log(key)
            PapiroConsole.log("vendo campos")
          if(typeof this.state.called[key] == "boolean" ){
            this.state.called[key] = this.state.called[key].toString()
            
          }
          let organizationFieldOptionsValuesId
          let explode = key.split('-')
          let explode2 = this.state.called[key].split('\n')
          let val = explode2[0]
         
          let organizationFieldId = explode[1];
          if(explode2[1] == undefined){
            organizationFieldOptionsValuesId = 0;
          }
          else{
            organizationFieldOptionsValuesId = explode2[1];
          }
          
          
          

          orgFields = `${organizationFieldId},${orgFields}`
          orgFieldValues = `${val},${orgFieldValues}`
          data.append("OrgFieldIdsList", parseInt(organizationFieldId))
          data.append("OrgFieldValuesList", val)
          data.append("OrgFieldOptionsValuesList", organizationFieldOptionsValuesId)
        }
      })
      data.append("orgfields", orgFields)
      data.append("orgfieldvalues", orgFieldValues)

      if(window && window.location &&  window.location.href){
        const currentURL = window.location.href;
        data.append('absoluteOrigin', currentURL)
      }

      let config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };

      PapiroConsole.log("Is Here")
      PapiroConsole.log(this.state.called)

     await Api.post("/calleds", data, config)
        .then(result => {

          PapiroConsole.log("resultado aqui 1")
          PapiroConsole.log(result)
          
          if (result.data.success && result.data.data != null) {
            let clearCalledTitle = document.getElementById('title')
            if (clearCalledTitle != null)
              clearCalledTitle.value = ''
            let calledDescription = document.getElementById('createCalledDescription')
            PapiroConsole.log("=== calledDescription create ===")
            PapiroConsole.log(calledDescription)
            if (calledDescription != null) {
              let qlEditor = calledDescription.querySelector('.ql-container .ql-editor')
              if (qlEditor != null) {
                PapiroConsole.log("=== qlEditor create ===")
                PapiroConsole.log(qlEditor)
                qlEditor.innerHTML = ''
              }
            }
            // Pegar o Id do chamado criado, e perguntar se o técnico quer assumir o chamado
            if (this.isClient === false && this.globalAdm === false && this.AdmOrganization === false && this.isTec === true) {
              if (result.data.data.id != null) {
                this.setState({
                  assignCalledId: result.data.data.id,
                  assignCalledDialogOpen: true
                })
              }
            }
            
            if (!this.state.called.keepcalled) {
              this.setState({
                called: this.initialCalledState,
                knowledgebase : null
              })
              
            }
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "success",
              notificationMessage: intl.formatMessage({ id: "add.success" }),
              notificationLinkMove: "",
              campoTexto : null,
              organizationFieldList : [],     
              openDialogNewAttendence: false,
              openDialogNewDisk: false,
            });
          }
        })
        .catch(err => {
          PapiroConsole.log(err)
          PapiroConsole.log("result")
          PapiroConsole.log("resultado aqui 2")
          PapiroConsole.log(err)

          if(err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0]){
            if(!isNaN(err.response.data.errors[0])){
              if(err.response.data.errors[0] == 5){
                this.setState({
                  loading: false,
                  openDialogNewDisk: true,
                  valueNewDisk: err.response.data.errors[0],
                });
              }else{
                this.setState({
                  loading: false,
                  openDialogNewAttendence: true,
                  valueNewAttendence: err.response.data.errors[0],
                });
              }
              
  
            }else{
              const intl = this.props.intl;
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: "error",
                notificationMessage:
                  err.response && err.response.data && err.response.data.errors
                    ? err.response.data.errors[0]
                    : intl.formatMessage({ id: "process.error" }),
              });
            }
            
          }else if (err.response && err.response.data && err.response.data.errors) {

            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
            });
          }
          else {

            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: "error",
              notificationMessage: intl.formatMessage({ id: "process.error" })
            });
          }
        });
    } else {

      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: intl.formatMessage({ id: "process.error" })
      });
    }
    }
  };



  async updateOrganization(value, isLoading = false) {
    this.setState({ loading: true })
    PapiroConsole.log("VALUE")
    PapiroConsole.log(value)
    if (value != null) {
      if (this.categoryId != null) {
        await this.getTechnicalTeams(value, this.categoryId.id)
        //await this.getTechnicalUsers(this.categoryId.id, value);
      }
      //await this.getRequestingUsers(value);// nada disso deve ser chamado mais pra trazer tudo
      if (this.state.called.addresstype == "1")
        await this.getOrganizationAddresses(value);
      //await this.getOrganizationFields(value);
      //this.getParentCalleds(value); // nada disso deve ser chamado mais pra trazer tudo
      // Voltar aqui
    }

    const clientInfos = await this.getOrganizationSettings();
    if (clientInfos) {
      PapiroConsole.log("=== clientInfos ===")
      PapiroConsole.log(clientInfos)
      this.setState(prevState => ({
        originrequestid : clientInfos.originrequestid,
        called: {
          ...prevState.called,
          //techinicalTeam: clientInfos.techinicalteam != null ? clientInfos.techinicalteam : 0,
        },
        techinicalTeamSettings: clientInfos.techinicalteam ? clientInfos.techinicalteam : 0,
        defaultTechinicalSettings: clientInfos.defaulttechinicalid ? clientInfos.defaulttechinicalid : 0,
        canselectoriginrequestincalledcreation: clientInfos.canselectoriginrequestincalledcreation,
        canassingusercalled: clientInfos.canassingusercalled,
        canselectorganizationcategoriesbylevels: clientInfos.canselectorganizationcategoriesbylevels,
        informativeText: clientInfos.informativetext != null ? clientInfos.informativetext : "",
        informativeTextAccordionExpanded: true
      }));
      this.canselectpatrimonyincalled = clientInfos.canselectpatrimonyincalled;
      this.canselectdepartamentincalled = clientInfos.canselectdepartamentincalled;
      this.canselectparentcalledincalled = clientInfos.canselectparentcalledincalled

      if (value != null) {
        if (clientInfos.canselectorganizationcategoriesbylevels === true)
          await this.getRootCategoriesByOrganization(value);
        else
          await this.getCategoriesByOrganization(value);

        if(clientInfos.canselectoriginrequestincalledcreation){
          await this.getOriginRequestsByFlag();
        }
      }
      
    } else {
      if (value != null)
        await this.getCategoriesByOrganization(value);
    }
    
    if (value != null) {
      await this.getPatrimonios(value);
      await this.getOrganizationFields(this.organizationId)
    }
    if(isLoading)
      this.setState({ loading: false })

      
      
  }

  closeNotification() {
    this.setState({ openNotification: false });
  }

  handleRedirection() {
    this.props.history.push({
      pathname: '/pt/' + this.state.notificationLinkMove
    })
  }

  setErrorMessage = (open, variant, message) => {
    this.setState({
      openNotification: open,
      notificationVariant: variant,
      notificationMessage: message
    });
  }

  changeValuesKnowledgebase = (stateName, value, text = '') => {
    this.setState({ [stateName]: value });
  }

  insertKnowledgebase = (e) => {
    e.preventDefault()
    PapiroConsole.log(this.state.knowledgebase)
    PapiroConsole.log("this.state.knowledgebase")
    if(this.state.knowledgebase != null) {
      if(this.state.knowledgebase.knowledgebaseattachments != null && this.state.knowledgebase.knowledgebaseattachments.length > 0) {
        let attachmentList = []
        this.state.knowledgebase.knowledgebaseattachments.forEach(element => {
          if(element != null && element.attachment != null) {
            attachmentList.push(element.attachment)
          }
        });
        this.setState(prevState => ({
          called: {
            ...prevState.called,
            knowledgebaseattachments: attachmentList
          }
        }))
      }
      if(this.state.knowledgebase.description != null) {
        let convKnowldgbs = this.convertHtmlOption(this.state.knowledgebase.description)
        convKnowldgbs = convKnowldgbs.trim()
        if(convKnowldgbs.length > 0) {
          let calledDescription = document.getElementById('createCalledDescription')
          if (calledDescription != null) {
            let qlEditor = calledDescription.querySelector('.ql-container .ql-editor')
            if (qlEditor != null)
              qlEditor.innerHTML = this.state.knowledgebase.description
          }
          this.setState(prevState => ({
            called: {
              ...prevState.called,
              description: this.state.knowledgebase.description
            }
          }))
        }
      }
    }
    this.closeKnowledgeBaseDialog()
  }

   insertNewUser = async (e) => {
    e.preventDefault()

    //console.log(this.state.createUserCalled)
    PapiroConsole.log(this.state.createUserCalled)
    //console.log(this.validateCpf(this.state.createUserCalled.cpfOfNewUser))
    //console.log("aqui")
    if(this.isCPFOrCNPJValid(this.state.createUserCalled.cpfOfNewUser) == false){
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "O campo CPF/CNPJ não foi validado" 
      });
    }
    
    
    else if(this.state.createUserCalled.nameOfNewUser == null || this.state.createUserCalled.nameOfNewUser == "" ){
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "O campo de nome é obrigatório "
      });
    }

    
    else if(this.state.createUserCalled.cpfOfNewUser == null || this.state.createUserCalled.cpfOfNewUser == "" ){
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "O campo de cpf é obrigatório"
      });
    }
    else if(this.state.createUserCalled.emailOfNewUser == null || this.state.createUserCalled.emailOfNewUser == "" ){
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "O campo de email é obrigatório"
      });
    }
    else if(this.state.createUserCalled.confirmEmailOfNewUser == null || this.state.createUserCalled.confirmEmailOfNewUser == "" ){
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "O campo de confirmação de email é obrigatório"
      });
    }

    else if(this.state.createUserCalled.confirmEmailOfNewUser !== this.state.createUserCalled.emailOfNewUser ){
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "O campo de email e o email de confirmação devem ser iguais"
      });
    }
    else if(this.state.called.organization == null){

      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "informe uma organização antes de criar um novo usuário"
      });
      
    }
    else if(this.state.associateOrganizationOptions =='associateOtherOrganizations' && this.state.OrganizationFilter.length == 0 ){

        this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: "É necessário selecionar ao menos uma organização se a opção Associar solicitante com outras organizações estiver marcada "
      });
    }
    else{

      let data = new FormData();
      for (let key in this.state.createUserCalled) {
        if(key == "nameOfNewUser"){
          data.append("username", this.state.createUserCalled[key])
          PapiroConsole.log("entrei")
        }
        else if(key == "cpfOfNewUser"){
          data.append("userdocument", this.state.createUserCalled[key])
          PapiroConsole.log("entrei")
        }
        else if(key == "emailOfNewUser"){
          data.append("useremail", this.state.createUserCalled[key])
          PapiroConsole.log("entrei")
        }
        else if(key == "confirmEmailOfNewUser"){
          data.append("userconfirmemail", this.state.createUserCalled[key])
          PapiroConsole.log("entrei")
        }
      }
      if(this.state.associateOrganizationOptions =='associateOtherOrganizations'){
        const organizationsIds = this.state.OrganizationFilter.map(item => item.id);
        const organizationsIdsString = organizationsIds.join(',');
        data.append("AssociateOrganizationOption",2)
        data.append("SelectedOrganizations",organizationsIdsString)
        

      }
      else{
        data.append("associateOrganizationOption",1)
        data.append("SelectedOrganizations", '' )
      }
      
      data.append("OrganizationId", this.state.called.organization.id)
      data.append("CreatedAt", this.state.createdAt)
      data.append("CreatedUser", this.state.createdUser)

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      this.setState({ loading: true })
      await Api.post("/users/create-user-called", data, config)
      .then(async result => {
       
        if (result.data.success) {
          //await this.getClientsByOrganizationId() // não precisa chamar mais.. combo agora é assincrono
          var element = result.data.data
          
        
          this.setState(prevState => ({
            called: {
              ...prevState.called,
              ["requestingUser"]: element
            }
          }));

          const intl = this.props.intl;
          var message=intl.formatMessage({ id: "user.created.success" });
          if(result.data.message){
            message=result.data.message;
          }
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "success",
            notificationMessage: message
          });

        } else {
          if (result.data && result.data.errors && result.data.errors[0] && result.data.errors[0] === 'user_registered') {
            this.setState({ loading: false })
            this.closeNewUserBaseDialog()
            this.openAssociateOrganizationUserDialog(result.data && result.data.data ? result.data.data : null)
          }
        }
      })
      .catch(err => {
       
        if (err.response && err.response.data && err.response.data.errors) {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
          });
        }
        else {
          const intl = this.props.intl;
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: "error",
            notificationMessage: intl.formatMessage({ id: "process.error" })
          });
        }
      });
      
      this.closeNewUserBaseDialog()

    }


    
    
  }

  changeValuesCategoriesByLevels = async (stateName, value, text = '') => {
    const intl = this.props.intl;

    this.setState(prevState => ({
      categoriesByLevels: {
        ...prevState.categoriesByLevels,
        [stateName]: value
      }
    }));

    if(value && value.id && value.id > 0) {
      await this.getByParentOrganizationCategoryId(value.id, stateName)
    } else {
      let firstCategoryId = null
      let rootCategoriesList = null
      if(stateName.includes("lcat-")) {
        let stateNameSplit = stateName.split("-")
        let stateNameId = stateNameSplit[1]
        stateNameId = parseInt(stateNameId)

        let listLevelCatArr = []
        let levelCatArr = []
        
        Object.keys(this.state.levelCategories).forEach((key) => {
          if (key.includes("lcatlist-")) {
            let lCatListSplit = key.split("-")
            let lCatListId = lCatListSplit[1]
            lCatListId = parseInt(lCatListId)

            if(lCatListId < stateNameId)
              listLevelCatArr.push(this.state.levelCategories[key])
          } else
            rootCategoriesList = this.state.levelCategories[key]
        })
        Object.keys(this.state.categoriesByLevels).forEach((key) => {
          if (key.includes("lcat-")) {
            let lCatSplit = key.split("-")
            let lCatId = lCatSplit[1]
            lCatId = parseInt(lCatId)

            if(lCatId < stateNameId)
              levelCatArr.push(this.state.categoriesByLevels[key])
          } else
            firstCategoryId = this.state.categoriesByLevels[key]
        })

        /*listLevelCatArr.reduce(function(result, item) {
          var key = Object.keys(item)[0]; //first property: a, b, c
          result[key] = item[key];
          return result;
        }, {})

        levelCatArr.reduce(function(result, item) {
          var key = Object.keys(item)[0]; //first property: a, b, c
          result[key] = item[key];
          return result;
        }, {})*/

        /*let listLevelCatObj = listLevelCatArr
        let levelCatObj = levelCatArr*/

        PapiroConsole.log("=== listLevelCatArr.reduce ===")
        PapiroConsole.log(listLevelCatArr)
        PapiroConsole.log(levelCatArr)

        await this.setState({
          levelCategories: {
            rootCategoriesList: rootCategoriesList && rootCategoriesList.length > 1 ? rootCategoriesList.sort((a, b) => a.name.localeCompare(b.name)) : rootCategoriesList,
            ...listLevelCatArr,
          }
        })
        await this.setState({
          categoriesByLevels: {
            firstCategoryId: firstCategoryId,
            ...levelCatArr,
          }
        })

        let childrens =
          <Grid item xs={11} sm={3}>
            <SimpleAutoCompleteByName
              intl={intl}
              label={intl.formatMessage({ id: "category" })}
              options={this.state.levelCategories.rootCategoriesList}
              stateName='firstCategoryId'
              name='firstCategoryId'
              changeSelect={this.changeValuesCategoriesByLevels}
              selected={this.state.categoriesByLevels.firstCategoryId} required />
          </Grid>
        
        let levelCategoryId = 1

        listLevelCatArr.map((item, key) => {
          let lCatListSplit = key.split("-")
          let lCatListId = lCatListSplit[1]

          PapiroConsole.log("=== listLevelCatArr.map ===")
          PapiroConsole.log(key)
          PapiroConsole.log(levelCategoryId)

          if(parseInt(lCatListId) > levelCategoryId)
            levelCategoryId = lCatListId

          childrens += <Grid item xs={11} sm={3}>
              <SimpleAutoCompleteByName
                intl={intl}
                label={intl.formatMessage({ id: "category" })}
                options={this.state.levelCategories[`lcatlist-${lCatListId}`] || []}
                stateName={`lcat-${lCatListId}`}
                name={`lcat-${lCatListId}`}
                changeSelect={this.changeValuesCategoriesByLevels}
                selected={this.state.categoriesByLevels[`lcat-${lCatListId}`]} required />
            </Grid>
          
          return { levelCategoryId: levelCategoryId }
        })

        let rootElement = document.getElementById("categoriesByLevels")
        rootElement && ReactDOM.render(
          childrens,
          rootElement
        )

        await this.setState({ levelCategoryId: levelCategoryId })
        this.levelCategoryId = levelCategoryId
      } else {
        await this.setState({
          levelCategories: {
            rootCategoriesList: this.state.levelCategories.rootCategoriesList && this.state.levelCategories.rootCategoriesList.length > 1 ? this.state.levelCategories.rootCategoriesList.sort((a, b) => a.name.localeCompare(b.name)) : this.state.levelCategories.rootCategoriesList,
          }
        })
        await this.setState({
          categoriesByLevels: {
            firstCategoryId: this.state.categoriesByLevels.firstCategoryId,
          }
        })

        let childrens =
          <Grid item xs={11} sm={3}>
            <SimpleAutoCompleteByName
              intl={intl}
              label={intl.formatMessage({ id: "category" })}
              options={this.state.levelCategories.rootCategoriesList}
              stateName='firstCategoryId'
              name='firstCategoryId'
              changeSelect={this.changeValuesCategoriesByLevels}
              selected={this.state.categoriesByLevels.firstCategoryId} required />
          </Grid>
        
        let rootElement = document.getElementById("categoriesByLevels")
        rootElement && ReactDOM.render(
          childrens,
          rootElement
        )

        await this.setState({ levelCategoryId: 1 })
        this.levelCategoryId = 1
      }
    }
  }

  createUi = () => {
    const intl = this.props.intl;
    PapiroConsole.log("estou aqui")

    return this.state.categoryChildrens.map((el, i) =>
      <Grid key={i} item xs={4} sm={4}>
        { i > 0 ?
        (<Grid container spacing={4}>
          <Grid item xs={1} sm={1} style={{ display: 'flex', alignItems: 'center', fontSize: '2em' }}>&gt;</Grid>
          <Grid item xs={10} sm={10}>
            <SimpleAutoCompleteByName
              intl={intl}
              label=""
              options={this.state.categoryLists[i] || []}
              stateName={`lcat-${i}`}
              name={`lcat-${i}`}
              changeSelect={this.handleChangeCategoryChildrens.bind(this, i)}
              selected={el}
               />
          </Grid>
        </Grid>)
        :
        (
          <SimpleAutoCompleteByName
            intl={intl}
            label=""
            options={this.state.categoryLists[i] || []}
            stateName={`lcat-${i}`}
            name={`lcat-${i}`}
            changeSelect={this.handleChangeCategoryChildrens.bind(this, i)}
            selected={el}
             />
        )}
      </Grid>
    )
  }

  handleAddChildrenCategory = (listCategory) => {
    this.setState(prevState => ({
      categoryChildrens: [
        ...prevState.categoryChildrens,
        { id: 0, name: '' }
      ],
      categoryLists: [
        ...prevState.categoryLists,
        listCategory
      ]
    }))
  }

  handleRemoveChildrenCategory = async (i) => {
    this.setState({ loading: true })

    let categoryChildrens = [...this.state.categoryChildrens]
    let categoryLists = [...this.state.categoryLists]
    categoryChildrens.splice(i+1)
    categoryLists.splice(i+1)

    await this.getPreviousCategory(i)

    this.setState({ categoryChildrens, categoryLists })
    this.setState({ loading: false })
  }

  getPreviousCategory = async (i) => {
    if(i > 0) {
      if (this.state.categoryChildrens[i-1] !== undefined) {
        if(this.state.categoryChildrens[i-1].cancreatecalled === true) {
          this.categoryId = this.state.categoryChildrens[i-1]
          this.setState(prevState => ({
            called: {
              ...prevState.called,
              categoryId: this.state.categoryChildrens[i-1]
            }
          }))
          await this.categoryCanCreateCalled(this.state.categoryChildrens[i-1])
        } else {
          await this.getPreviousCategory(i-1)
        }
      }
    } else {
      if (this.state.categoryChildrens[i] !== undefined && this.state.categoryChildrens[i].cancreatecalled === true) {
        this.categoryId = this.state.categoryChildrens[i]
        this.setState(prevState => ({
          called: {
            ...prevState.called,
            categoryId: this.state.categoryChildrens[i]
          }
        }))
      } else {
        this.categoryId = null
        this.setState(prevState => ({
          called: {
            ...prevState.called,
            categoryId: null
          }
        }))
      }
    }
  }

  async handleChangeCategoryChildrens(i, stateName, value) {
    PapiroConsole.log(value)
    PapiroConsole.log("value aqui")
    
    this.setState(prevState => ({
      called: {
        ...prevState.called,
        categoryId: value,
      },
      loading: true
    }))
    
    let categoryChildrens = [...this.state.categoryChildrens]
    if (i < categoryChildrens.length -1 )
      this.handleRemoveChildrenCategory(i)
    else {
      categoryChildrens[i] = value
      this.setState({ categoryChildrens })
    }

    if(value && value.id && value.id > 0) {
      
     
      await this.getCategoryChildrenByParentOrganizationCategoryId(value.id, stateName)
      await this.getCategoryFields(value.id);
      if(value.cancreatecalled === true) {
        await this.categoryCanCreateCalled(value)
      }
    }
    this.setState({ loading: false })
  }

  categoryCanCreateCalled = async (value) => {
    this.categoryId = value;
    let data = await CalledService.getTypeById(value.id, this.organizationId);
    if (data.data.typeid == null) {
      data.data.typeid = 0;
    }
    let initDate = moment().endOf('day').add('days', 1).format("YYYY/MM/DD HH:mm:ss")
    let newDate = initDate
    if(value.useslatime == true) {
      if(value.organizationsla && value.organizationsla.solutiontime && value.organizationsla.solutiontime > 0) {
        newDate = moment().add(value.organizationsla.solutiontime, 'minutes').format("YYYY/MM/DD HH:mm:ss")
        this.changeDueDateByOrganizationCagetoryMessage(1, moment().add(value.organizationsla.solutiontime, 'minutes').format("DD/MM/YYYY HH:mm"))
      } else if(value.solutiontime && value.solutiontime > 0) {
        newDate = moment().add(value.solutiontime, 'minutes').format("YYYY/MM/DD HH:mm:ss")
        this.changeDueDateByOrganizationCagetoryMessage(2, moment().add(value.solutiontime, 'minutes').format("DD/MM/YYYY HH:mm"))
      } else
        newDate = initDate
    } else if(value.useslatime == false) {
      if(value.solutiontime && value.solutiontime > 0) {
        newDate = moment().add(value.solutiontime, 'minutes').format("YYYY/MM/DD HH:mm:ss")
        this.changeDueDateByOrganizationCagetoryMessage(2, moment().add(value.solutiontime, 'minutes').format("DD/MM/YYYY HH:mm"))
      } else
        newDate = initDate
    } else
      newDate = initDate

    this.setState(prevState => ({
      called: {
        ...prevState.called,
        ["CategoryId"]: value,
        type: value.typeid && value.typeid > 0 ? value.typeid : this.state.called.type && this.state.called.type > 0 ? this.state.called.type : 0,
        typeDisabled: false,
        priorityid: value.priorityid && value.priorityid > 0 ? value.priorityid : this.state.called.priorityid && this.state.called.priorityid > 0 ? this.state.called.priorityid : 0,
        priorityDisabled: value.priorityid && value.priorityid > 0 ? true : false,
        dueDate: newDate
      }
    }));
    if (this.organizationId != null) {
      await this.getTechnicalTeams(this.organizationId, this.categoryId.id)
      //await this.getTechnicalUsers(this.categoryId.id, this.organizationId);
      await this.getDepartamentsByOrganizationCategoryId(this.categoryId.id)
      PapiroConsole.log("=== this.state.called.techinicalTeam ===")
      PapiroConsole.log(this.state.called.techinicalTeam)
      if (this.state.called.techinicalTeam && this.state.called.techinicalTeam > 0)
        await this.getTechnicalUsersByTechnicalTeam(this.state.called.techinicalTeam)
      else if (this.state.called.techinicalTeam && this.state.called.techinicalTeam.id && this.state.called.techinicalTeam.id > 0)
        await this.getTechnicalUsersByTechnicalTeam(this.state.called.techinicalTeam.id)
    }
  }

  getCategoryChildrenByParentOrganizationCategoryId = async (parentId, stateName = '') => {
    const intl = this.props.intl;
    
    var result = await CalledService.getByParentOrganizationCategoryId(parentId);
    if (result && result.success) {
      if(result.data && result.data.length > 0) {
        this.handleAddChildrenCategory(result.data)
      }
    } else {
      const intl = this.props.intl;
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
      });
      Api.kickoff(result);
    }
  }

  changeDueDateByOrganizationCagetoryMessage = (type, date) => {
    const intl = this.props.intl;

    switch (type) {
      case 1:
        this.setState({
          openNotification: true,
          notificationVariant: "info",
          notificationMessage: intl.formatMessage({id:"called.registration.notification.date.changed.sla.category"})
        });
        break;
      case 2:
        this.setState({
          openNotification: true,
          notificationVariant: "info",
          notificationMessage: intl.formatMessage({id:"called.registration.notification.date.changed.sla.category"})
        });
        break;
      default:
        break;
    }
  }

  async changeValuesCheckbox(stateName, value, text = '') {

    
    

    if(stateName.includes("ofield")){

      this.setState(prevState => ({
        called: {
          ...prevState.called,
          [stateName]: value
        }
      }));
      

    }
   
  };

  async changeValues(stateName, value, text = '') {

    
    
     
    // PapiroConsole.log(value)
    this.setState(prevState => ({
      called: {
        ...prevState.called,
        [stateName]: value
      }
    }));

    if (stateName === "ParentCalledId") {
      this.setState(prevState => ({
        called: {
          ...prevState.called,
          ["ParentCalledText"]: text,
        }
      }));
    }

    if (stateName === "techinicalTeam") {
      if(value && value.id && value.id > 0) {
        await this.getTechnicalUsersByTechnicalTeam(value.id)
      }
    }

    if(stateName.includes("ofield")){

      this.setState(prevState => ({
        called: {
          ...prevState.called,
          [stateName]: value.name+'\n'+value.id
        }
      }));
      

    }
    //PapiroConsole.log(stateName+" : "+value);
    if (stateName === "organization") {
      if(value && value.id && value.id > 0) {
        this.organizationId = value.id;
        PapiroConsole.log("entrei aqui no updateOrg")
        await this.updateOrganization(value.id);
        
        document.getElementsByClassName('MuiAutocomplete-clearIndicator')[1].click()
        document.getElementsByClassName('MuiAutocomplete-clearIndicator')[2].click()
        this.setState({ knowledgeBaseList: [], CategoryId: { id: 0, name: '' },  departamentId : 0 });
        if(this.state.canselectoriginrequestincalledcreation){
          await this.getOriginRequestsByFlag();
        }
        
        await this.getOrganizationDepartaments(value.id)
        
      
      } else {
        this.setState({
          informativeText: "",
          informativeTextAccordionExpanded: true,
          canselectoriginrequestincalledcreation: false,
          canselectorganizationcategoriesbylevels: false,
          canassingusercalled: false,
          levelCategories: this.levelCategoriesInitialState,
        })
        this.canselectpatrimonyincalled = false
        this.canselectdepartamentincalled = false
        this.canselectparentcalledincalled = false
      }
    }
    else if (stateName === "CategoryId") {
      this.categoryId = value;
      PapiroConsole.log(value)
      PapiroConsole.log("value")
      if(value.id != 0){
        await this.getCategoryFields(value.id);
      }
      
      let data = await CalledService.getTypeById(value.id, this.organizationId);
      if (data.data.typeid == null) {
        data.data.typeid = 0;
      }
      PapiroConsole.log(data)
      PapiroConsole.log("entrada aqui")
      let initDate = moment().endOf('day').add('days', 1).format("YYYY/MM/DD HH:mm:ss")
      let newDate = initDate
      if(value) {
        if(value.useslatime == true) {
          if(value.organizationsla && value.organizationsla.solutiontime && value.organizationsla.solutiontime > 0) {
            newDate = moment().add(value.organizationsla.solutiontime, 'minutes').format("YYYY/MM/DD HH:mm:ss")
            this.changeDueDateByOrganizationCagetoryMessage(1, moment().add(value.organizationsla.solutiontime, 'minutes').format("DD/MM/YYYY HH:mm"))
          } else if(value.solutiontime && value.solutiontime > 0) {
            newDate = moment().add(value.solutiontime, 'minutes').format("YYYY/MM/DD HH:mm:ss")
            this.changeDueDateByOrganizationCagetoryMessage(2, moment().add(value.solutiontime, 'minutes').format("DD/MM/YYYY HH:mm"))
          } else
            newDate = initDate
        } else if(value.useslatime == false) {
          if(value.solutiontime && value.solutiontime > 0) {
            newDate = moment().add(value.solutiontime, 'minutes').format("YYYY/MM/DD HH:mm:ss")
            this.changeDueDateByOrganizationCagetoryMessage(2, moment().add(value.solutiontime, 'minutes').format("DD/MM/YYYY HH:mm"))
          } else
            newDate = initDate
        } else
          newDate = initDate
      } else
        newDate = initDate

      this.setState(prevState => ({
        called: {
          ...prevState.called,
          ["categoryText"]: text,
          type: value.typeid && value.typeid > 0 ? value.typeid : this.state.called.type && this.state.called.type > 0 ? this.state.called.type : 0,
          typeDisabled: false,
          priorityid: value.priorityid && value.priorityid > 0 ? value.priorityid : this.state.called.priorityid && this.state.called.priorityid > 0 ? this.state.called.priorityid : 0,
          priorityDisabled: value.priorityid && value.priorityid > 0 ? true : false,
          dueDate: newDate
        }
      }));
      if (this.organizationId != null) {
        await this.getTechnicalTeams(this.organizationId, this.categoryId.id)
        //await this.getTechnicalUsers(this.categoryId.id, this.organizationId);
        await this.getDepartamentsByOrganizationCategoryId(this.categoryId.id)
        PapiroConsole.log("=== this.state.called.techinicalTeam ===")
        PapiroConsole.log(this.state.called.techinicalTeam)
        if (this.state.called.techinicalTeam && this.state.called.techinicalTeam > 0)
          await this.getTechnicalUsersByTechnicalTeam(this.state.called.techinicalTeam);
        else if (this.state.called.techinicalTeam && this.state.called.techinicalTeam.id && this.state.called.techinicalTeam.id > 0)
          await this.getTechnicalUsersByTechnicalTeam(this.state.called.techinicalTeam.id);
        this.setState({ loading: false })
        
      }
    }
    else if (stateName === "AddressId") {
      this.setState(prevState => ({
        called: {
          ...prevState.called,
          requestingUser: null
        }
      }))
      if (this.organizationId != null) {
        this.getRequestingUsers(this.organizationId, value.id);
      }
    } else if(stateName === "priorityid") {
      this.setState(prevState => ({
        called: {
          ...prevState.called,
          ["priorityid"]: value && value.id ? value.id : value
        }
      }));
    } else if (stateName === "requestingUser") {
      if (this.state.called.addresstype == 2 && value && value.id && value.id > 0)
        await this.getUserAddresses(value.id)
    }
    else if (stateName == 'selectOrganizations') {
      if (value == true) {

        this.setState({
          OrganizationFilter : [...this.state.organizationsList]
        })
        
        
        
      } else {
        this.setState({
          OrganizationFilter : []
        })
       
        
      }
    }

    else if (stateName == 'OrganizationFilter') {

      this.setState({
        [stateName] : value
      })
      
    }
  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  handleSubmitAccept = async (e) => {
    await this.setState({ newAttendenceAccept: true });
    this.handleSubmit(e);
  }

  handleSubmitDiskAccept = async (e) => {
    await this.setState({ newDiskAccept: true });
    this.handleSubmit(e);
  }

  getCalledFile(fileName) {
    let fName = fileName
    if(fName.startsWith('/wwwroot/', 0))
      fName = fName.substring(9)
    
    let apiUrl = Api.apiurlbase()
    if (apiUrl.endsWith("/"))
      apiUrl = apiUrl.slice(0, -1)

    window.open(`${apiUrl}/${fName.replace("///", "/").replace("//", "/").replace("/files", "files")}`, '_blank');
  };

  useStyles = () => {
    return withStyles({
        ImageList: {
            width: "100%",
            height: 100,
        },
    });
  }

  mtel(cpf) {
    if(cpf != null){
      cpf=cpf.replace(/\D/g,"")
      cpf=cpf.replace(/(\d{3})(\d)/,"$1.$2")
      cpf=cpf.replace(/(\d{3})(\d)/,"$1.$2")
      cpf=cpf.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
    }
    else{
      cpf="";
    }
      
    return cpf;
  }

  deleteItem = (file) => {
    if(file != null && file.id != null) {
      let newListItem = this.state.called.knowledgebaseattachments.filter(item => item.id != file.id)
      this.setState(prevState => ({
        called: {
          ...prevState.called,
          knowledgebaseattachments: newListItem
        }
      }))
    }
  }

  render() {
    const { classes, headerMenu, actionsHeaderMenu } = this.props;
    const { called, address, categoriesByLevels, levelCategories } = this.state;
    const intl = this.props.intl;
    const { item, itemError } = this.state

    if (Api == null) {
      var l = this.props.match.params.lang;
      document.location.href = `/${l}/unavailable`
    }
    const classes2 = this.useStyles();
    
    

    return (
      <div className={classes.root}>
        <HeaderMenu />
      
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: headerMenu.open,
          }, classes.actionsContent, {
            [classes.actionscontentShift]: actionsHeaderMenu.open,
          })}
        >
          <div className={classes.drawerHeader} />
          <Dialog
            open={this.state.assignCalledDialogOpen}
            onClose={this.closeAssignCalledDialog}
            aria-labelledby="form-delete-filter"
            maxWidth="md"
            fullWidth={false}
            style={{ overflow: 'hidden' }}
            scroll="body" >
            <DialogTitle id="form-delete-filter">{intl.formatMessage({id:"assume.called"})}</DialogTitle>
            <DialogContent style={{minWidth: "400px"}}>
                {intl.formatMessage({id:"called.list.dialog.called.assigned.in.progress"})}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeAssignCalledDialog} color="primary">
                {intl.formatMessage({id:"close"})}
              </Button>
              <Button onClick={this.assignCalled} color="primary">
                {intl.formatMessage({id:"confirm"})}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            maxWidth="md"
            open={this.state.knowledgeBaseDetailsDialogOpen}
            onClose={this.knowledgeBaseDetailsClose}
            scroll={'body'}
            aria-labelledby="knowledgebase-dialog-title">
                        <DialogTitle id="knowledgebase-dialog-title">
                            # {this.state.knowledgeBaseItem.id} | {this.state.knowledgeBaseItem.title}
                            <ClearIcon onClick={this.knowledgeBaseDetailsClose} style={{ float: 'right', cursor: 'pointer' }} />
                        </DialogTitle>
                        <DialogContent className="knowledgebase-dialog-client-details" >
                            <Grid container spacing={0} style={{ marginTop: 15 }}>
                                <Grid item xs={12} style={{ marginBottom: 15 }}>
                                    <h5><FormattedMessage id="description" />:</h5>
                                    <div style={{ padding: '5px 10px', border: '1px solid rgba(0, 0, 0, 0.5)', borderRadius: 10 }} >{ReactHtmlParser(this.state.knowledgeBaseItem.description)}</div>
                                </Grid>
                                { (this.state.knowledgeBaseItem.knowledgebaseknowledgebasekeywords && this.state.knowledgeBaseItem.knowledgebaseknowledgebasekeywords.length > 0) && <Grid item xs={12} style={{ marginBottom: 15 }}>
                                    <h5><FormattedMessage id="keywords" />:</h5>
                                    <div className='kb-keys' style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                                        {this.state.knowledgeBaseItem.knowledgebaseknowledgebasekeywords.map(item => {
                                            return <div className='kb-key' style={{ marginTop: 15 }}>{item.knowledgebasekeyword.word}</div>
                                        })}
                                    </div>
                                </Grid>}
                                {this.state.knowledgeBaseItem.knowledgebaseattachments.length > 0 && (
                                    <Grid item xs={12}>
                                        <div className={classes.attachmentsBox}>
                                            <label>{<FormattedMessage id="attachments" />}</label>
                                            <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                                                <ul>
                                                    {this.state.knowledgeBaseItem.knowledgebaseattachments.map(file => {
                                                        return (<li key={file.attachment.id}>
                                                            <AttachFile /> <Button onClick={() => this.getFile(file.attachment)}>{file.attachment.description}</Button>
                                                            <Tooltip title={<h5>Copiar link de download</h5>}>
                                                                <IconButton aria-label="textevidence"
                                                                    onClick={ () => this.copyFileLink(file.attachment)}>
                                                                    <FileCopyIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </li>)
                                                    })}
                                                </ul>
                                            </ImageList>
                                        </div>
                                    </Grid>
                                )}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container spacing={4} justify="flex-end" style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 15 }}>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        variant='contained'
                                        type="button"
                                        onClick={this.knowledgeBaseDetailsClose}
                                        fullWidth
                                    >
                                        {intl.formatMessage({id:"close"})}
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
          </Dialog>
          <Dialog
              maxWidth="md"
              open={this.state.knowledgeBaseDialogOpen}
              onClose={this.closeKnowledgeBaseDialog}
              aria-labelledby="knowledgebase-dialog-title" >
                <DialogTitle id="knowledgebase-dialog-title">
                  <FormattedMessage id="insert.knowledge.base" />
                  <ClearIcon onClick={this.closeKnowledgeBaseDialog} style={{ float: 'right', cursor: 'pointer' }} />
                </DialogTitle>
                <DialogContent style={{ width: "800px" }} >
                  <Grid container spacing={0} style={{ marginTop: 15 }}>
                    <Grid item xs={10}>
                      <SimpleAutoComplete
                        label={intl.formatMessage({ id: "knowledgebase" })}
                        options={this.state.knowledgeBaseList}
                        stateName='knowledgebase'
                        changeSelect={this.changeValuesKnowledgebase}
                        selected={this.state.knowledgebase} />
                    </Grid>
                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                      {(this.state.knowledgebase != null && this.state.knowledgebase.id != null && this.state.knowledgebase.id > 0) && <Tooltip title={<h5>Detalhes</h5>}>
                        <IconButton aria-label="knowledge-base"
                          onClick={() => this.knowledgeBaseDetails()}
                          style={{
                            marginBottom: '30px',
                            backgroundColor: '#277afe',
                            color: '#FFF'
                          }}>
                          <DescriptionIcon style={{ fontSize: '2rem' }} />
                        </IconButton>
                      </Tooltip>}
                    </Grid>
                  </Grid>
                </DialogContent>
                  <DialogActions>
                  <Button onClick={this.closeKnowledgeBaseDialog} variant="contained" color="secondary" autoFocus >
                    {intl.formatMessage({id:"cancel"})}
                  </Button>
                  <Button variant="contained" onClick={this.insertKnowledgebase} color="primary" >
                    <FormattedMessage id="insert" />
                  </Button>
                </DialogActions>
            </Dialog>

            <Dialog
              maxWidth="md"
              open={this.state.newUserBaseDialogOpen}
              onClose={this.closeNewUserBaseDialog}
              aria-labelledby="newuser-dialog-title" >
                <DialogTitle id="newuser-dialog-title">
                  <FormattedMessage id="insert.newuser.base" />
                  <ClearIcon onClick={this.closeNewUserBaseDialog} style={{ float: 'right', cursor: 'pointer' }} />
                </DialogTitle>
                <DialogContent style={{ width: "800px" }} >
                  <Grid container spacing={1} style={{ marginTop: 15 }}>
                    <Grid item xs={12} sm={6}>
                          <TextField  fullWidth label={`${intl.formatMessage({id:"name"})}*`} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='nameOfNewUser' onChange={this.handleInputChangeNewUser}  />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                          
                    <TextField
                          name="cpfOfNewUser"
                          label="CPF/CNPJ*"
                          fullWidth
                          value={this.state.createUserCalled.cpfOfNewUser}
                          onChange={this.handleInputChangeNewUser}
                          InputLabelProps={{ shrink: true }}
                          error={itemError.document?.length > 0}
                        helperText={itemError.document} />

                        
                       
                    </Grid>
                    <Grid item xs={12} sm={6}>
                          <TextField fullWidth label={`${intl.formatMessage({id:"email"})}*`} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='emailOfNewUser' onChange={this.handleInputChangeNewUser}  />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                          <TextField fullWidth label={`${intl.formatMessage({id:"confirm.email"})}*`} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='confirmEmailOfNewUser' onChange={this.handleInputChangeNewUser}  />
                    </Grid>
                    <Grid item xs={12} style={{marginTop : '8px'}}>
                        <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group">{intl.formatMessage({id:"associate.organizations"})}</FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={this.state.associateOrganizationOptions}
                              onChange={this.handleChangeRadioDialog}
                            >
                              <FormControlLabel value="associateCalledOrganization" control={<Radio />} label={intl.formatMessage({id:"associate.requesting.user.to.called.organization"})} />
                              <FormControlLabel value="associateOtherOrganizations" control={<Radio />} label={intl.formatMessage({id:"associate.requesting.user.to.others.organizations"})} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {this.state.associateOrganizationOptions == 'associateOtherOrganizations' && 
                      <Grid item xs={12} >
                        <SimpleAutoCompleteMultiple label={intl.formatMessage({ id: "organization" })} options={this.state.organizationsList} stateName='OrganizationFilter' changeSelect={this.changeValues} selected={this.state.OrganizationFilter} />
                      </Grid>
                    }
                    {this.state.associateOrganizationOptions == 'associateOtherOrganizations' && 
                      <Grid item xs={12} >
                        <SimpleCheckBox label={intl.formatMessage({ id: "technical.team.edit.select.all.organizations" })} name='selectOrganizations' stateName='selectOrganizations' changeSelect={this.changeValues} selected={this.state.selectOrganizations} />
                      </Grid>
                    }
                   
                  </Grid>
                </DialogContent>
                  <DialogActions>
                  <Button onClick={this.closeNewUserBaseDialog} variant="contained" color="secondary" autoFocus >
                    {intl.formatMessage({id:"cancel"})}
                  </Button>
                  <Button variant="contained" onClick={this.insertNewUser} color="primary" >
                    <FormattedMessage id="insert" />
                  </Button>
                </DialogActions>
            </Dialog>
            <Dialog
              maxWidth="md"
              open={this.state.associateOrganizationUserDialogOpen}
              onClose={this.closeAssociateOrganizationUserDialog}
              aria-labelledby="associate-organization-user-dialog-title" >
                <DialogTitle id="associate-organization-user-dialog-title">
                  <FormattedMessage id="associate.organization.user.dialog.title" />
                  <ClearIcon onClick={this.closeAssociateOrganizationUserDialog} style={{ float: 'right', cursor: 'pointer' }} />
                </DialogTitle>
                <DialogContent style={{ width: "800px" }} >
                  <DialogContentText id="associate-organization-user-dialog-description">
                    <FormattedMessage id="associate.organization.user.dialog.title.text" />
                  </DialogContentText>
                </DialogContent>
                  <DialogActions>
                  <Button onClick={this.closeAssociateOrganizationUserDialog} variant="contained" color="secondary" autoFocus >
                    <FormattedMessage id="no" />
                  </Button>
                  <Button variant="contained" onClick={this.handleConfirmAssociateOrganizationUserDialog} color="primary" >
                    <FormattedMessage id="yes" />
                  </Button>
                </DialogActions>
            </Dialog>
            {(this.state.informativeText != null && this.state.informativeText.length > 0 && this.state.informativeText != "<p><br></p>" && this.state.informativeText != "<p></p>") && <Accordion expanded={this.state.informativeTextAccordionExpanded} onChange={this.handleChangeAccordion("informative-text")} sx={{
                    width: '30%',
                    position: 'fixed',
                    top: '15%',
                    right: '2%',
                    zIndex: '999'
                  }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="informative-text-content"
                  id="informative-text-header">
                  <Typography>Informativo</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Paper
                  elevation={3} >
                          <Grid container spacing={0}>
                            <Grid item xs={1} sm={1} style={{ backgroundColor: '#f7f2a9' }}></Grid>
                            <Grid item xs={11} sm={11} sx={{
                                padding: '25px 25px 25px 20px',
                                overflowY: 'auto',
                                maxHeight: '60vh!important'
                              }}>
                              <Grid container spacing={0}>
                                <Grid item xs={12} sm={12}>
                                  <Typography variant="body1">
                                    <div dangerouslySetInnerHTML={{__html: this.state.informativeText}} />
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                  </Paper>
                </AccordionDetails>
              </Accordion>}
          <Container component="main" maxWidth="md">
            <Dialog
              open={this.state.openDialogNewAttendence}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{intl.formatMessage({id:"dialog.title.warning.max.attendances.reached"})}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {intl.formatMessage({id:"warning.max.attendances.reached.first.half"})} R$ {this.state.valueNewAttendence} {intl.formatMessage({id:"warning.max.attendances.reached.second.half"})}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.openDialogNewAttendence} variant="contained" color="secondary" autoFocus >
                  {intl.formatMessage({id:"cancel"})}
                </Button>
                <Button variant="contained" onClick={this.handleSubmitAccept} color="primary" >
                {intl.formatMessage({id:"accept"})}
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={this.state.openDialogNewDisk}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{intl.formatMessage({id:"dialog.title.warning.max.files.reached"})}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {intl.formatMessage({id:"warning.max.files.reached.first.half"})} R$ {this.state.valueNewDisk} {intl.formatMessage({id:"warning.max.files.reached.second.half"})} 
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.openDialogNewDisk} variant="contained" color="secondary" autoFocus >
                  {intl.formatMessage({id:"cancel"})}
                </Button>
                <Button variant="contained" onClick={this.handleSubmitDiskAccept} color="primary" >
                  {intl.formatMessage({id:"accept"})}
                </Button>
              </DialogActions>
            </Dialog>

            <CssBaseline />
            <div className={classes.paper} style={{ marginTop: 0 }}>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                  <Button variant="outlined" color="primary" onClick={browserHistory.goBack}>{<FormattedMessage id="back" />}</Button>
                </Grid>
                <Grid item xs={12} sm={8} />
              </Grid>
              <Avatar className={classes.avatar}>
                <QueuePlayNext />
              </Avatar>
              <Typography component="h1" variant="h5">
                {<FormattedMessage id="called.new" />}
              </Typography>
              <form name="myForm" className={classes.form} onSubmit={this.handleSubmit} encType="multipart/form-data">
                  <Grid container spacing={2}>
                  {!this.isClient && (<>
                    <Grid item xs={12} sm={4} />
                    <Grid item xs={12} sm={4} />
                    <Grid item xs={12} sm={4}>
                      <SimpleDatePickerHour label={intl.formatMessage({ id: "estimate.resol" })} stateName="dueDate" changeDate={this.changeValues} currentDate={called.dueDate} isRequired />
                    </Grid>
                    <Grid item xs={4} sm={4} style={!this.isClient ? { paddingBottom: 0 } : { display: 'none' } }>
                      <SimpleSelectWithColor label={intl.formatMessage({ id: "priority" })} options={this.state.priorityList} stateName="priorityid" changeSelect={this.changeValues} selected={this.state.called.priorityid} disabled={ called.priorityDisabled } required />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete label={intl.formatMessage({ id: "organization" })} options={this.state.organizationsList} stateName="organization" changeSelect={this.changeValues} selected={called.organization} isRequired required />
                    </Grid>
                    {(this.state.canselectoriginrequestincalledcreation != null && this.state.canselectoriginrequestincalledcreation) && <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete label={intl.formatMessage({ id: "origin.request" })} options={this.state.originRequestList} stateName="originRequest" changeSelect={this.changeValues} selected={called.originRequest} isRequired required />
                    </Grid>}
                    {this.state.canselectorganizationcategoriesbylevels === false ? (<Grid item xs={12} sm={12}>
                        <SimpleAutoComplete label={intl.formatMessage({ id: "category" })} options={this.state.categoriesList} stateName='CategoryId' changeSelect={this.changeValues} selected={called.CategoryId} required />
                      </Grid>)
                    :
                    (
                    <Grid item xs={12} sm={12} style={{ marginBottom: 20, marginTop: 20 }}>
                      <fieldset style={{ padding: '10px 10px', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                        <legend style={{ fontSize: '0.8rem', marginBottom: '0!important' }}><FormattedMessage id="category" />*</legend>
                        <Grid container spacing={2} id="categoriesByLevels" className="categoriesByLevels">
                          {this.createUi()}
                        </Grid>
                      </fieldset>
                    </Grid>
                    )}
                    { this.canselectdepartamentincalled && <Grid item xs={12} sm={12}>
                      <SimpleAutoComplete label={intl.formatMessage({ id: "departament" })} options={this.state.DepartamentList} stateName='departamentId' changeSelect={this.changeValues} selected={called.departamentId} />
                    </Grid>}
                    {this.canselectparentcalledincalled && <Grid item xs={12} sm={12}>
						          <SimpleAutoCompleteSearch label={intl.formatMessage({ id: "keypress.search.instruction" })+" "+intl.formatMessage({ id: "parent.called" })} options={this.state.parentCalledsList} stateName='ParentCalledId' changeSelect={this.changeValues} loadListFunction={this.getParentCalleds} loading={this.state.loadingParentCalleds} selected={called.ParentCalledId} urlapi={Api.apiurlbase()+'api/calleds/search/'+(this.state.called && this.state.called.organization ? this.state.called.organization.id : 0)} numberdigits={4}/>
                    </Grid>}
                    <Grid item xs={12} sm={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">{intl.formatMessage({ id: "location" })}</FormLabel>
                        <RadioGroup row aria-label="addresstype" name="addresstype" defaultValue="1" value={called.addresstype} onChange={this.handleChange}>
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={intl.formatMessage({id:"organization.location"})} />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label={intl.formatMessage({id:"requester.location"})} />
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label={intl.formatMessage({id:"location.new"})} />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    {(called.addresstype != null && called.addresstype === "1") && (<Grid item xs={12} sm={12}>
                      <SimpleAutoComplete label={intl.formatMessage({ id: "location" })} options={this.state.organizationAddressesList} stateName="AddressId" changeSelect={this.changeValues} selected={called.AddressId} required />
                    </Grid>)}
                    {(called.addresstype != null && called.addresstype === "2") && (<Grid item xs={12} sm={12}>
                      <SimpleAutoComplete label={intl.formatMessage({ id: "location" })} options={this.state.userAddressesList} stateName="AddressId" changeSelect={this.changeValues} selected={called.AddressId} required />
                    </Grid>)}
                    {(called.addresstype != null && called.addresstype === "3") && (<Grid item xs={12} sm={12}>
                      <Accordion style={{marginTop: "6px", marginBottom: "10px"}}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className={classes.heading}>{intl.formatMessage({id:"location.new"})}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={4}>
                            <Grid item xs={12} sm={12}>
                              <TextField fullWidth label={`${intl.formatMessage({id:"name"})}*`} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='name' onChange={this.handleInputChangeAddress} value={address.name} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField inputProps={{ maxLength: 100 }} fullWidth label={intl.formatMessage({id:"cep"})} autoComplete='fname' variant='outlined' name='zipcode' onChange={this.handleInputChangeAddress} value={address.zipcode} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField fullWidth label={intl.formatMessage({id:"street"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='street' onChange={this.handleInputChangeAddress} value={address.street} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField fullWidth label={intl.formatMessage({id:"number"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='number' onChange={this.handleInputChangeAddress} value={address.number} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField fullWidth label={intl.formatMessage({id:"complement"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='complement' onChange={this.handleInputChangeAddress} value={address.complement} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField fullWidth label={intl.formatMessage({id:"neighborhood"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='neighborhood' onChange={this.handleInputChangeAddress} value={address.neighborhood} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField fullWidth label={intl.formatMessage({id:"city"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='city' onChange={this.handleInputChangeAddress} value={address.city} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField fullWidth label={intl.formatMessage({id:"state"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='state' onChange={this.handleInputChangeAddress} value={address.state} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField fullWidth label={intl.formatMessage({id:"country"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='country' onChange={this.handleInputChangeAddress} value={address.country}  />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField fullWidth label={intl.formatMessage({id:"longitude"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='longitude' onChange={this.handleInputChangeAddress} value={address.longitude}  />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField fullWidth label={intl.formatMessage({id:"latitude"})} inputProps={{ maxLength: 255 }} autoComplete='fname' variant='outlined' name='latitude' onChange={this.handleInputChangeAddress} value={address.latitude}  />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>)}
                    <Grid item xs={12} sm={6}>
                      {/*<SimpleAutoComplete label={intl.formatMessage({ id: "requestuser" })} options={this.state.clientUsersList} stateName="requestingUser" changeSelect={this.changeValues} selected={called.requestinguser} loading={this.state.loadingRequestingUsers} required />*/}
                      <SimpleAutoCompleteSearch label={ intl.formatMessage({ id: "keypress.search.instruction" })+" "+intl.formatMessage({ id: "requestuser" })+" (min 5 caracteres)"} options={this.state.clientUsersList} stateName="requestingUser" changeSelect={this.changeValues} selected={called.requestingUser} loading={this.state.loadingRequestingUsers} urlapi={Api.apiurlbase()+'api/users/search/'+(this.state.called && this.state.called.organization ? this.state.called.organization.id : 0)+'/'+this.state.called.AddressId.id} required />
                      <Grid item xs={12} sm={12}  container justifyContent="flex-end">
                                    <Typography display="block" gutterBottom variant='caption'>
                                    {<FormattedMessage id="must.organization.and.location.first" />}
                                    </Typography>
                      </Grid>
                      <Link href="#"  underline="none"  onClick={this.openNewUserBaseDialog}>Cadastrar/associar novo solicitante?</Link>
                    </Grid>

                   


                    <Grid item xs={12} sm={6}>
                      {/*<SimpleAutoComplete label={intl.formatMessage({ id: "observer" })} options={this.state.clientUsersList} stateName="observantUser" changeSelect={this.changeValues} loading={this.state.loadingRequestingUsers} selected={called.observantuser} />*/}
                      <SimpleAutoCompleteSearch label={intl.formatMessage({ id: "keypress.search.instruction" })+" "+intl.formatMessage({ id: "observer" })} options={this.state.clientUsersList} stateName="observantUser" changeSelect={this.changeValues} loading={this.state.loadingRequestingUsers} selected={called.observantUser}  urlapi={Api.apiurlbase()+'api/users/search/'+(this.state.called && this.state.called.organization ? this.state.called.organization.id : 0)+'/'+this.state.called.AddressId.id} />
					</Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleAutoComplete label={intl.formatMessage({ id: "attr.to.team" })} options={this.state.techinicalTeamList} stateName="techinicalTeam" changeSelect={this.changeValues} selected={called.techinicalTeam}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SimpleAutoComplete label={intl.formatMessage({ id: "attr.to" })} options={this.state.technicalUsersList} stateName="assignedUser" changeSelect={this.changeValues} selected={called.assignedUser}
                      disabled={!this.state.canassingusercalled}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SimpleSelect label={intl.formatMessage({ id: "type" })} options={this.state.typesList} stateName="type" changeSelect={this.changeValues} selected={called.type} disabled={ called.typeDisabled } required />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        autoComplete="fname"
                        name="title"
                        variant="outlined"
                        fullWidth
                        id="title"
                        label={intl.formatMessage({ id: "title" })}
                        /*value={called.title}
                        onChange={this.handleInputChange}*/
                        inputProps={{ maxLength: 250 }}
                        required
                      />
                    </Grid>
                </>)}

                {this.canselectpatrimonyincalled && (
                    <Grid item xs={12} sm={12}>
                      <SimpleAutoCompleteMultiple label={intl.formatMessage({id:"called.registration.patrimonies.associated"})} options={this.state.patrimonyList} stateName='patrimonyId' changeSelect={this.changeValues} selected={called.patrimonyId} />
                    </Grid>
                )}
                  <Grid item xs={12} sm={12}>
          <Typography>
            <FormattedMessage id="description" />*
          </Typography>
					<ReactQuill name="description" /*value={called.description}*/ style={{ height: 200 }} modules={this.reactQuillModules} formats={this.reactQuillFormats}
					fullWidth
					required
					variant="outlined"
					id="createCalledDescription"
									/*onChange={this.handleChangeEditor}*/ />					  

                  </Grid>
                  <Grid item xs={12} sm={12} justify="flex-end" style={{ display: 'flex', marginTop: 45 }}>
                    <Tooltip title={<h5><FormattedMessage id="insert.knowledge.base" /></h5>}>
                        <IconButton aria-label="insert-knowledge-base"
                            style={{ marginBottom: '30px' }}
                            onClick={this.openKnowledgeBaseDialog}
                            style={{
                                backgroundColor: '#277afe',
                                color: '#FFF'
                            }}>
                            <EmojiObjectsIcon style={{ fontSize: '2rem' }} />
                        </IconButton>
                    </Tooltip>
                  </Grid>

                  <If test={ this.state.organizationFieldList && this.state.organizationFieldList.length > 0} >
                  <Grid item xs={12} sm={12}>
                  <fieldset style={{
                    marginTop: 25,
                    marginBottom: 25,
                    border: '1px solid #c0c0c0',
                    padding: '0 0 15px 0',
                    borderLeft: 'none',
                    borderRight: 'none'
                  }}>
                    <legend style={{
                      paddingTop: 15,
                      fontSize: '1.3rem',
                      border: '1px solid #c0c0c0',
                      borderBottom: 'none',
                      borderLeft: 'none',
                      borderRight: 'none'
                    }}>{intl.formatMessage({ id: "organization.custom.fields" })}</legend>
                    <Grid container spacing={4}>
                      {this.state.campoTexto}
                    </Grid>
                   
                  
                  </fieldset>
                  </Grid>
                </If>

                <Grid item xs={12} sm={12}>
                  <Typography variant="h6" gutterBottom >
                            <FormattedMessage id="attach.your.files" />
                  </Typography>
                  <section style={{ width: '100%', marginTop:'8px'}}>
                    <div className={classes.dropzoneArea} style={{minHeight:'70px',marginTop:'5px '}}>
                      <Dropzone onDrop={acceptedFiles => { this.setState({ files: [ ...acceptedFiles, ...this.state.files ] }) }}>
                        {({ getRootProps, getInputProps, acceptedFiles }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <AttachFileIcon  />
                            <Typography component="span" fontWeight="bold" fontSize={16} color="gray">
                                <FormattedMessage  id="drag.and.drop.files" />
                            </Typography>
                          </div>
                        )}
                      </Dropzone>
                      {this.state.files.map(file => { 
                        return (
                          <div className={classes.dropzoneItem} key={file.name}>
                            {file.name}
                            <DeleteForever className={classes.deleteItem} onClick={() => { this.removeItem(file) }} />
                          </div>
                        )
                      })}
                    </div>
                  </section>
                </Grid>
                {called.knowledgebaseattachments.length > 0  && (
                      <Grid item xs={12}>
                          <div className={classes.attachmentsBox}>
                              <label>{<FormattedMessage id="knowledgebase.attachments" />}</label>
                              <ImageList cellHeight={100} className={classes2.ImageList} cols={1} >
                                  <ul>
                                      {called.knowledgebaseattachments.map(file => {
                                          return (<li key={file.path}>
                                            <DeleteForeverIcon onClick={() => this.deleteItem(file)} style={{ cursor: "pointer" }} />
                                            <AttachFile /> <Button onClick={() => this.getCalledFile(file.path)}>{file.description}</Button>
                                          </li>)
                                      })}
                                  </ul>
                              </ImageList>
                          </div>
                      </Grid>
                )}
                  <Grid item xs={11} sm={11} style={{ textAlign: 'left' }}>
                    <SimpleCheckBox label={intl.formatMessage({ id: "keep.called" })} name='keepcalled'
                      stateName='keepcalled' changeSelect={this.changeValues} />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <SimpleHelp message={intl.formatMessage({id:"called.registration.message.functionality.called.data"})} />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Grid container justify="flex-end" style={{ justifyContent: 'flex-end' }}>
                  <Grid item xs={12} sm={4}    >
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                      disabled={this.state.redirectClientWithNoAccess}

                    >
                      {<FormattedMessage id="save" />}
                    </Button>
                  </Grid>
                  </Grid>
                </Grid>
                </Grid>
              </form>
            </div>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color="secondary" />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
              toMove={this.state.notificationLinkMove}
              handleRedirection={this.handleRedirection}
            />
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}

CalledRegistration.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  actionsHeaderMenu: state.actionsHeaderMenu,
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(CalledRegistration)));
