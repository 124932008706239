import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { CssBaseline, CircularProgress, Container, Button, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../styles/material-styles';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import HeaderMenu from '../../components/header-menu';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Api from '../../services/api';
import MyMaterialTable from '../../components/table';
import { Can } from '../../config/ability-context'
import { AbilityContext } from '../../config/ability-context'
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Search from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import SimpleSelect from '../../components/select/simple-search';
import ClearIcon from '@mui/icons-material/Clear';
import InputIcon from '@mui/icons-material/Input';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FirstElement from "../../components/first-element";
import SimpleAutoComplete from '../../components/auto-complete/autocomplete';

import * as componentActions from '../../redux/actions/organizationchecklists';
import { PapiroConsole } from '../../utils/papiroConsole';
class OrganizationChecklistsContent extends Component {

    constructor(props) {
        super(props);

        this.typingTimeout = null;

        const intl = this.props.intl;
        this.emptyArray = null;

        let filters = [
            { name: intl.formatMessage({ id: "title" }), id: "title" },
            { name: intl.formatMessage({ id: "active" }), id: "active" },
            { name: intl.formatMessage({ id: "category" }), id: "category" }
        ];

        let fromProps = ""
        if(this.props && this.props.from && this.props.from.length > 0 && this.props.from == 'orgEdit')
            fromProps = this.props.from

        //console.log(props);
        const { userSession, organizations } = this.props;
        this.state = {
            organizations: organizations,
            loading: false,
            openNotification: false,
            notificationVariant: 'error',
            notificationMessage: '',
            resultList: [],
            organizationList: [],
            totalPages: 0,
            page: 1,
            pageStart: 0,
            pageEnd: 0,
            count: 0,
            searchValue: '',
            filterSelected: 0,
            filters: filters,
            from: fromProps,
            OrganizationFilter: 0,
            OrganizationFilterItem: null,
            viewType: false
        };

        this.changeValues = this.changeValues.bind(this);
        this.callSearch = this.callSearch.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);

        this.closeNotification = this.closeNotification.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.clearFields = this.clearFields.bind(this);
        this.changeType = this.changeType.bind(this);
    }

    onChangePaginate = (e, page) => {
        this.setState({ page: page });
        localStorage.setItem('organizationChecklistsTablePage', `${page}`)
        this.getItens(page);
    }

    setPagination(count, page) {

        let totalPage = Math.ceil(count / 10);
        this.setState({ totalPages: totalPage })
        if (count > 0) {
            if (page == 1) {
                if (count < 10) {
                    this.setState({ pageStart: 1, pageEnd: count });
                } else {
                    this.setState({ pageStart: 1, pageEnd: 10 });
                }
            } else {
                let pageStart = ((page - 1) * 10) + 1;
                // console.log(count)
                // console.log('pagina')
                // console.log(page * 10)
                if (count >= page * 10) {
                    this.setState({ pageStart: pageStart, pageEnd: page * 10 });
                } else {
                    this.setState({ pageStart: pageStart, pageEnd: count });
                }
            }
        } else {
            this.setState({ totalPages: 0, page: 1, count: 0 });
        }

    }

    onFieldChange(event) {


        this.setState({ [event.target.name]: event.target.value, page: 1 });

    }

    callSearch() {
        if (this.state.filterSelected != 0) {
            localStorage.setItem('organizationChecklistsTableFilter', `${this.state.filterSelected}=${this.state.searchValue}`)
            this.getItens(this.state.page);
        }
    }

    async clearFields() {
        await this.setState({ filterSelected: 0, searchValue: '', page: 1 })
        localStorage.setItem('organizationChecklistsTableFilter', '')
        localStorage.setItem('organizationChecklistsTablePage', '1')
        this.getItens(1);
    }

    async changeValues(stateName, value, text = '') {
        if(stateName === 'OrganizationFilter') {
            this.setState({ [stateName]: value.id })
            this.getItens()
        }else
            this.setState({filterSelected : value})
    }


    deleteItem(data, resolve) {
        let abl = this.context.can('Delete', 'OrganizationChecklist');
        if (abl == false) {
            const intl = this.props.intl;
            this.setState({ loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: intl.formatMessage({ id: "operation.notAllowed" }) });
            resolve(); return false;
        }

        Api.delete(`/organizationchecklists/${data.id}`)

            .then(result => {
                if (result.data.success) {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false,
                        openNotification: true,
                        notificationVariant: 'success',
                        notificationMessage: intl.formatMessage({ id: "delete.sucess" }),
                        resultList: []
                    });

                    this.getItens();
                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
                resolve();
            })
            .catch(err => {
                resolve();
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);
            });


    };


    componentWillReceiveProps(nextProps) {
        if (nextProps.location && nextProps.location.state === "reload") {
            this.componentDidMount();
        }
    }

    async componentDidMount() {
        let sessionFilter = localStorage.getItem('organizationChecklistsTableFilter')
                        if(sessionFilter == null) {
                            sessionFilter = [0, '']
                            localStorage.setItem('organizationChecklistsTableFilter', '')
                        } else {
                            if(sessionFilter && sessionFilter.length > 0) {
                                let sessionFilterSplit = sessionFilter.split("=")
                                if(sessionFilterSplit && sessionFilterSplit.length > 1) {
                                    if(!isNaN(sessionFilterSplit[0]))
                                        sessionFilter = [parseInt(sessionFilterSplit[0]), sessionFilterSplit[1]]
                                    else
                                        sessionFilter = [sessionFilterSplit[0], sessionFilterSplit[1]]
                                }
                            }
                        }
                        if(isNaN(sessionFilter[0])) {
                            let sessionFilterItem = this.state.filters.filter(item => item.id == sessionFilter[0])
                            if(sessionFilterItem && sessionFilterItem.length > 0) {
                                await this.setState({
                                    filterSelected: sessionFilterItem[0]
                                }, () => this.setState({ filterSelected: sessionFilter[0], searchValue: sessionFilter[1] }))
                            }
                        } else {
                            await this.setState({ filterSelected: sessionFilter[0], searchValue: sessionFilter[1] })
                        }

                        let sessionOrgFilter = localStorage.getItem('organizationChecklistsTableOrganizationFilter')
                        if(sessionOrgFilter == null) {
                            sessionOrgFilter = 0
                            localStorage.setItem('organizationChecklistsTableOrganizationFilter', '0')
                        }
                        let orgFilterItem = null
                        if(!isNaN(sessionOrgFilter) && typeof sessionOrgFilter === 'string') {
                            sessionOrgFilter = parseInt(sessionOrgFilter)
                            if(sessionOrgFilter > 0 && this.state.organizationList != null) {
                                orgFilterItem = this.state.organizationList.filter(item => item.id == sessionOrgFilter)
                                if(orgFilterItem && orgFilterItem.length > 0) {
                                    await this.setState({
                                        OrganizationFilterItem: orgFilterItem[0]
                                    })
                                }
                            }
                        }
                        await this.setState({ OrganizationFilter: sessionOrgFilter, OrganizationFilterItem: orgFilterItem })

                        let sessionPage = localStorage.getItem('organizationChecklistsTablePage')
                        if(sessionPage == null) {
                            sessionPage = 1
                            localStorage.setItem('organizationChecklistsTablePage', '1')
                        }
                        if(!isNaN(sessionPage) && typeof sessionPage === 'string')
                            sessionPage = parseInt(sessionPage)
                        await this.setState({ page: sessionPage })
                        await this.getItens(sessionPage)
    }

    getItens(page = 1) {
        this.setState({ loading: true });
        var url = `/organizationchecklists/organization/${this.state.organizations.organization.id}`;
        url = `/organizationchecklists/organization/paginate?page=${page}&id=${this.state.organizations.organization.id}`;
        
        if (this.state.searchValue != '' && this.state.filterSelected != 0) {

            url += `&search=${this.state.searchValue}&field=${this.state.filterSelected}`

        }

        if (this.props.location && this.props.location.pathname === "/organizationchecklists") {
            url = `/organizationchecklists`;

            url = `/organizationchecklists/paginate?page=${page}&org=${this.state.OrganizationFilter && this.state.OrganizationFilter > 0 ? this.state.OrganizationFilter : 0}`;

            if (this.state.searchValue != '' && this.state.filterSelected != 0) {

                url += `&search=${this.state.searchValue}&field=${this.state.filterSelected}`

            }
        }
        Api.get(url)
            .then(result => {
                if (result.data.success) {
                    

                    let count = result.data.count;
                    this.setState({ count: count });
                    this.setPagination(count, page);
                    if(result.data.data.length === 0)
                    {
                        this.emptyArray = true 
                    }
                    else
                    {
                        this.emptyArray = false
                    }

                    let viewType = false
                    const formattedList = result.data.data.map(item => {
                        if (item && item.organization && item.organization.organizationsettings && item.organization.organizationsettings.caninforminitialchecklist != null && item.organization.organizationsettings.caninforminitialchecklist) {
                            viewType = true
                        }

                        let orgCatText = "";
                        let organizationCategories = []

                        if (item && item.organizationchecklistorganizationcategories && item.organizationchecklistorganizationcategories.length > 0) {
                            var showTwo = item.organizationchecklistorganizationcategories.length > 2 ? true : false
                            item.organizationchecklistorganizationcategories.map((itemCheckCat, i) => {

                                if (itemCheckCat && itemCheckCat.organizationcategory && ((itemCheckCat.organizationcategory.name && itemCheckCat.organizationcategory.name.length > 0) || (itemCheckCat.organizationcategory.parentname && itemCheckCat.organizationcategory.parentname.length > 0))) {
                                    if (i == 0)
                                        orgCatText = itemCheckCat.organizationcategory.parentname && itemCheckCat.organizationcategory.parentname.length > 0 ? itemCheckCat.organizationcategory.parentname : itemCheckCat.organizationcategory.name
                                    else {
                                        if (showTwo && i > 1) {

                                        } else {
                                            orgCatText += `, ${itemCheckCat.organizationcategory.parentname && itemCheckCat.organizationcategory.parentname.length > 0 ? itemCheckCat.organizationcategory.parentname : itemCheckCat.organizationcategory.name}`
                                        }
                                    }

                                    organizationCategories.push(itemCheckCat.organizationcategory)
                                }
                            })
                            if (showTwo)
                                orgCatText += ` + ${(item.organizationchecklistorganizationcategories.length - 2)} ` + this.props.intl.formatMessage({id:"category.or.categories"})
                        }

                        return {
                            ...item,
                            OrganizationId: item.organization ? item.organization.id : 0,
                            OrganizationText: item.organization ? item.organization.name : '',
                            OrganizationCategoryId: item.organizationcategory ? item.organizationcategory.id : 0,
                            OrganizationCategories: organizationCategories,
                            OrganizationCategoryText: orgCatText,
                            Typetext: this.changeType(item.type),
                            Active: item.active == true ? this.props.intl.formatMessage({id:"yes"}) : this.props.intl.formatMessage({id:"no"})
                        }
                    });
                    this.setState({ loading: false, resultList: formattedList, viewType: viewType });
                }
                else {
                    const intl = this.props.intl;
                    this.setState({
                        loading: false, openNotification: true, notificationVariant: 'error',
                        notificationMessage: result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                    });
                    Api.kickoff(result);
                }
            })
            .catch(err => {
                const intl = this.props.intl;
                this.setState({
                    loading: false, openNotification: true, notificationVariant: 'error',
                    notificationMessage: err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" })
                });
                Api.kickoff(err);

            });
    };

    changeType(type) {
        let typeStr = ''
        switch (type) {
            case 1:
                typeStr = this.props.intl.formatMessage({id:"initial"})
                break
            case 2:
                typeStr = this.props.intl.formatMessage({id:"final"})
                break
            case 3:
                typeStr = this.props.intl.formatMessage({id:"initial.and.final"})
                break
            default:
                typeStr = ''
                break
        }
        return typeStr
    }


    closeNotification() {
        this.setState({ openNotification: false });
    }
    render() {
        const { classes, headerMenu } = this.props;
        const intl = this.props.intl;
        return (
            <Container component='main' maxWidth='xl' style={{ maxWidth: '100%', paddingLeft:0, marginLeft:0}}>
                {!this.emptyArray ? (
                <div>
                <CssBaseline />
                    <Grid container className={classes.mb20}>
                        <Button
                            variant='contained'
                            color='secondary'
                            size='large'
                            disabled={!this.context.can('Add', 'OrganizationChecklist')}
                            style={{ marginBottom: '20px' }}
                            className={classes.button}
                            startIcon={<AddIcon />}
                            //component={Link} {... { to: '/OrganizationField-registration' }}
                            component={Link} {... {
                                to: {
                                    pathname: `/${this.props.match.params.lang}/organizationchecklist-registration`,
                                    state: {
                                        internal: this.props.internal,
                                        from: this.state.from
                                    }
                                }
                            }}
                        >
                            {<FormattedMessage id="add" />}
                        </Button>
                    </Grid>

                    <Grid container spacing={6}>
                        <Grid item xs={12} sm={12}>


                            <Card className="background-title-search">
                                <CardContent>

                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="h5">
                                                {<FormattedMessage id="organization.custom.checklist" />}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={3}>
                                            {(this.props.location && this.props.location.pathname === `/${this.props.match.params.lang}/organizationchecklists`) && (<SimpleAutoComplete label={intl.formatMessage({id:"organization"})} options={this.state.organizationList} stateName='OrganizationFilter'
                                                selected={this.state.OrganizationFilterItem}
                                                changeSelect={(stateName, value, text = '') => {
                                                                        this.setState({
                                                                            OrganizationFilter: value.id,
                                                                            OrganizationFilterItem: value
                                                                        }, () => {
                                                                            if(value && value.id && value.id > 0)
                                                                                localStorage.setItem('organizationChecklistsTableOrganizationFilter', `${value.id}`)
                                                                            else
                                                                                localStorage.setItem('organizationChecklistsTableOrganizationFilter', '0')
                                                                            this.getItens()
                                                                        })
                                                                    }}/>)}
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <SimpleSelect label={intl.formatMessage({ id: "field" })} options={this.state.filters} stateName='filter' changeSelect={this.changeValues} selected={this.state.filterSelected} />
                                        </Grid>
                                        <Grid item >
                                            <TextField type="text"
                                                name="searchValue"
                                                value={this.state.searchValue}
                                                placeholder={intl.formatMessage({ id: "search" })}
                                                onChange={this.onFieldChange} />

                                            <Button
                                                variant='contained'
                                                color='secondary'
                                                size='large'
                                                className={classes.button}
                                                style={{ marginRight: 4, marginLeft: 8 }}
                                                onClick={this.callSearch}
                                            >
                                                <Search />
                                            </Button>

                                            <Button
                                                variant='contained'
                                                color='primary'
                                                size='large'
                                                onClick={this.clearFields}
                                                className={classes.button}

                                            >
                                                <ClearIcon />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>

                        </Grid>

                    </Grid>
                    <Can I='List' a='OrganizationChecklist'>
                        <MyMaterialTable
                            title=""
                            rowClick={(event, rowData) => {
                                let abl = this.context.can('Edit', 'OrganizationChecklist');
                                if (abl == false) {
                                    this.setState({ loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: intl.formatMessage({ id: "operation.notAllowed" }) });
                                    return false;
                                }
                                this.props.addCurrentOrganization(rowData, this.props.organizations.organization);

                                this.props.history.push({
                                    pathname: `/${this.props.match.params.lang}/organizationchecklist-edit`,
                                    state: {
                                        internal: this.props.internal,
                                        from: this.state.from
                                    }
                                });

                            }}
                            columns={this.state.viewType ? [
                                { title: intl.formatMessage({ id: "title" }), field: 'title' },
                                { title: intl.formatMessage({ id: "type" }), field: 'Typetext' },
                                { title: intl.formatMessage({ id: "active" }), field: 'Active' },
                                { title: intl.formatMessage({ id: "organization" }), field: 'OrganizationText' },
                                { title: intl.formatMessage({ id: "categories" }), field: 'OrganizationCategoryText' }
                            ] : [
                                { title: intl.formatMessage({ id: "title" }), field: 'title' },
                                { title: intl.formatMessage({ id: "active" }), field: 'Active' },
                                { title: intl.formatMessage({ id: "organization" }), field: 'OrganizationText' },
                                { title: intl.formatMessage({ id: "categories" }), field: 'OrganizationCategoryText' }

                            ]}
                            data={this.state.resultList}
                            deleteItem={this.deleteItem}
                        />
                    </Can>

                    <Can I='List' a='OrganizationChecklist'>

                        <Grid container style={{ marginBottom: 12, marginTop: 8 }}>
                            <Grid item xs={12} md={3}>
                                <Pagination count={this.state.totalPages} page={this.state.page} onChange={this.onChangePaginate} />
                            </Grid>
                            <Grid item xs={12} sm={3} style={{ marginTop: 6 }}>
                                {this.state.pageStart} {<FormattedMessage id="to" />} {this.state.pageEnd} {<FormattedMessage id="of" />} {this.state.count} {<FormattedMessage id="register" />}
                            </Grid>
                        </Grid>

                    </Can>

                    {this.state.loading && (
                        <Overlay>
                            <CircularProgress color='secondary' />
                        </Overlay>
                    )}


                    <CustomizedSnackbars
                        variant={this.state.notificationVariant}
                        message={this.state.notificationMessage}
                        isOpen={this.state.openNotification}
                        toClose={this.closeNotification}
                    />
                </div>)
                                        :(
                                            <div>
                                               <FirstElement
                                               title = {this.props.intl.formatMessage({id:"organization.checklist.list.create"})}
                                               subtitle = {this.props.intl.formatMessage({id:"organization.checklist.list.subtitle.create"})}
                                               //buttonText = "Novo Chamado"
                                               buttonText = 
                                               {
                                                <Button
                                                variant='contained'
                                                color='secondary'
                                                size='large'
                                                style={{ marginBottom: '20px' }}
                                                className={classes.button}
                                                startIcon={<AddIcon />}
                                                //component={Link} {... { to: '/OrganizationField-registration' }}
                                                component={Link} {... {
                                                    to: {
                                                        pathname: `/${this.props.match.params.lang}/organizationchecklist-registration`,
                                                        state: {
                                                            internal: this.props.internal,
                                                            from: this.state.from
                                                        }
                                                    }
                                                }}
                                            >
                                                {<FormattedMessage id="add" />}
                                            </Button>
                                               }
                                            
                                               icon = {<CheckBoxIcon style={{fontSize: "45px"}} />}
                                               
                                               />
                                            </div>
                                            )}

                                            {this.state.loading && (
                                                <Overlay>
                                                    <CircularProgress color='secondary' />
                                                </Overlay>
                                            )}
                        

                                            <CustomizedSnackbars
                                                variant={this.state.notificationVariant}
                                                message={this.state.notificationMessage}
                                                isOpen={this.state.openNotification}
                                                toClose={this.closeNotification}
                                            />
                                        
               
            </Container>

        );
    }
}
OrganizationChecklistsContent.propTypes = {
    classes: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    headerMenu: state.headerMenu,
    userSession: state.userSession,
    organizations: state.organizations,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(componentActions, dispatch);
export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(OrganizationChecklistsContent))));
OrganizationChecklistsContent.contextType = AbilityContext;
