import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';
import { PapiroConsole } from '../../utils/papiroConsole';

export default function DialogTextEdit(props) {
    const { open, id, text, handleClose, msgDisagree, msgButton, label } = props

    const [textValue, setValue] = useState(text)

    const handleConfirmation = () => {
        props.handleConfirmation(id, textValue);
    }

    useEffect(() => {
        if (open) {
            setValue(text)
        }
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{props.msg}</DialogTitle>
            <DialogContent style={{ paddingTop: 10 }}>
                <TextField value={textValue} label={label} fullWidth onChange={e => setValue(e.target.value)}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {msgDisagree}
                </Button>
                <Button onClick={handleConfirmation} color="primary" variant='contained' autoFocus>
                    {msgButton}
                </Button>
            </DialogActions>
        </Dialog>
    );
}