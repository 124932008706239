
import Api2Talk from "../api2talk";

const PATH = '/chatbot';

const create = async (input) => { 
  try {
    const response = await Api2Talk.post(PATH, input);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const update = async (input) => { 
  try {
    const response = await Api2Talk.put(PATH, input);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const getAllPaginate = async (page) => {
  try {
    const response = await Api2Talk.get(`${PATH}/paginate?page=${page}`);
    return response.data;
  } catch (err) {
    throw err;
  }
}

const getById = async (id) => {
  try {
    const response = await Api2Talk.get(`${PATH}/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
}

const deleteById = async (id) => {
  try {
    const response = await Api2Talk.delete(`${PATH}/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
}

export {
  create, deleteById, getAllPaginate, getById,
  update
};
