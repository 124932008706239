import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { injectIntl } from 'react-intl';

function AlertDialog(props) {

  const intl = props.intl;
  
  console.log(props)
  console.log("props")

  const [checkAccpted, setCheckAccpted] = React.useState(false);
  
  const handleClickCheck = () => {
    setCheckAccpted(!checkAccpted);
  };

  const handleClickAcceted = () => {
    if(checkAccpted){
      props.handleConfirmation();
    }else{
      props.setMessageUseTerm();
    }
  };

  return (
    <div>
      <Dialog
        open={props.openDialogTerm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{intl.formatMessage({id:"useterm"})}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          <a href="https://dpo.privacytools.com.br/policy-view/nzVRBwDmQ/3/termos-e-condic%CC%A7o%CC%83es-gerais-de-uso-plataforma-mexx/pt_BR" target="_blank" >{intl.formatMessage({id:"mexx.2do.use.conditions.click.here.first.half"})}</a> {intl.formatMessage({id:"mexx.2do.use.conditions.click.here.second.half"})}
          </DialogContentText>
          
          <FormControlLabel
          control={
            <Checkbox
              checked={checkAccpted}
              onChange={handleClickCheck}
              name="checked"
              color="primary"
            />
          }
          label={intl.formatMessage({id:"dialog.term.agree"})}
        />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.openDialogTermFunc()} color="primary" >
            {intl.formatMessage({id:"cancel"})}
          </Button>
          <Button onClick={handleClickAcceted} variant="contained" color="primary" autoFocus>
            {intl.formatMessage({id:"dialog.term.accept"})}
          </Button>
        </DialogActions>
      </Dialog>
      
    </div>
  );
}

export default injectIntl(AlertDialog);