import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import { Styles } from "../../../../styles/material-styles";
import { Grid } from '@mui/material';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { AbilityContext } from '../../../../config/ability-context';
import { injectIntl } from 'react-intl';
import Api2Talk from "../../../../services/api2talk";
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import DialogGeneral from '../../../../components/dialog-general';
import Radio from '@mui/material/Radio';
import CircularProgress from '@mui/material/CircularProgress';
import * as ItemServiceChannel from '../../../../services/2talk/channel'
import { getAllUsers, getAllUsersTech } from "../../../../services/user.service";


class ChannelAdmin extends Component {

  constructor(props) {
    super(props);
    const { channels } = this.props;
    this.selectedUsersArray = [];

    this.state = {
      id: channels.channel.id,
      userList: [],
      autocompleteInputValue: '',
      item: {
        selectedOptions: [],
      },
      DialogRemoveState: false,
      IdToDelete: { channelRegistrationNumberRequestId: 0, channelId: 0 },
      loadingGrid: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.openOrCloseRemoveModal = this.openOrCloseRemoveModal.bind(this);
    this.handleDeleteOption = this.handleDeleteOption.bind(this);
  }

  async componentDidMount() {
    this.setState({ loadingGrid: true });
    await this.getUserList();
    await this.getAdminsAssociations();
    this.setState({ loadingGrid: false });
  }

  async getUserList() {
    const { getNotification, setLoadingState } = this.props;
    const response = await getAllUsersTech();

    setLoadingState(false)

    if (response.success) {
      const users = response.data?.map(user => ({id: user.id, name: user.name}));
      this.setState({ userList: users });
    } else {
      const intl = this.props.intl;
      this.setState({ loadingGrid: false });
      getNotification(false, true, 'error', response.data && response.data.response && response.data.response.data && response.data.response.data.errors && response.data.response.data.errors[0] ? response.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
    }
  }

  async getAdminsAssociations() {
    const { getNotification } = this.props;
    var result = await Api2Talk.get(`/channeladmin/channel/${this.state.id}`);
    if (result.data.success) {
      if (result.data.data && result.data.data.length > 0) {
        this.updateList(result.data.data)
      }
      this.setState({ loadingGrid: false });
    } else {
      const intl = this.props.intl;
      this.setState({ loadingGrid: false });
      getNotification(false, true, 'error', result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { getNotification, setLoadingState } = this.props;
    setLoadingState(true)

    let data = {
      channelid: this.state.id,
      usersid: []
    };

    const usersSelected = this.state.item.selectedOptions?.filter(user => !user.channelAdminId);

    if (usersSelected.length > 0) {

      if (Api2Talk == null) {
        getNotification(false, true, 'error', "Api de mensageria não configurada.")
        return;
      }
      
      usersSelected.forEach((item, index) => {
        data.usersid[index] = item.id;
      });
      
      Api2Talk.post('/channeladmin', data)
        .then((result) => {
          if (result.data.success) {
            this.getAdminsAssociations();
            setLoadingState(false);
            getNotification(false, true, 'success', "Administradores associados com sucesso");

          } else {
            setLoadingState(false)
            const intl = this.props.intl;
            getNotification(false, true, 'error', result.data.errors && result.data.errors[0] ? result.data.errors[0] : intl.formatMessage({ id: "process.error" }))

          }
        })
        .catch((err) => {
          setLoadingState(false)
          if (err.response && err.response.data && err.response.data.errors) {
            const intl = this.props.intl;
            getNotification(false, true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))

          } else {
            const intl = this.props.intl;
            getNotification(false, true, 'error', err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))

          }
        });
    }
  }

  updateList(list) {
    let array = [];
    list.forEach(element => {
      if (element.id && element.userid && element.channelid) {

        let nameUser = element.userid;

        if (this.state.userList.length > 0) {
          let found = this.state.userList.find(user => user.id === element.userid);
          nameUser = found ? found.name : nameUser;
        }

        let object = { channelAdminId: element.id, id: element.userid, name: nameUser }
        array.push(object);
      }
    });
    this.setState((prevState) => ({
      item: {
        ...prevState.item,
        selectedOptions: array,
      },
    }));
  }

  handleAutocompleteChange = (_, newValue) => {
    if (newValue) {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          selectedOptions: [...prevState.item.selectedOptions, newValue],
        },
        autocompleteInputValue: '',
      }));
    }
  };

  async handleDeleteOption() {
    const { getNotification, setLoadingState } = this.props;

    setLoadingState(true)

    if (
      this.state.IdToDelete 
      && this.state.IdToDelete.channelAdminId
      && this.state.IdToDelete.channelAdminId != 0
      && this.state.IdToDelete.userId 
      && this.state.IdToDelete.userId != 0
    ) {

      
      var result = await Api2Talk.delete(`/channeladmin/${this.state.IdToDelete.channelAdminId}`);
      
      if (result.data.success) {

        this.setState((prevState) => ({
          item: {
            ...prevState.item,
            selectedOptions: prevState.item.selectedOptions.filter((option) => option.id !== this.state.IdToDelete.userId),
          },
        }));

        this.openOrCloseRemoveModal()
        setLoadingState(false)
        getNotification(false, true, 'success', "Administrador deletado com sucesso")
        this.setState({ DialogRemoveState: false, IdToDelete: { channelAdminId: 0, channelId: 0, userId: 0 } });

      } else {

        this.openOrCloseRemoveModal()
        setLoadingState(false)
        const intl = this.props.intl;
        getNotification(false, true, 'error', result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0] ? result.data.response.data.errors[0] : intl.formatMessage({ id: "process.error" }))
      }

    }
  }

  handleRemoveOption = (optionToRemove) => {
    if (optionToRemove.channelAdminId) {
      this.setState({ DialogRemoveState: true, IdToDelete: { channelAdminId: optionToRemove.channelAdminId, channelId: this.state.id, userId: optionToRemove.id }, })
    }
    else {
      this.setState((prevState) => ({
        item: {
          ...prevState.item,
          selectedOptions: prevState.item.selectedOptions.filter((option) => option.id !== optionToRemove.id),
        },
      }));
    }
  };

  filterOptions = (options, { inputValue }) => {
    const { selectedOptions } = this.state.item;
    return options.filter(
      (option) =>
        !selectedOptions.find((selected) => selected.id === option.id) &&
        !this.state.item.selectedOptions.find((selected) => selected.id === option.id)
    );
  };

  openOrCloseRemoveModal = () => {
    this.setState({ DialogRemoveState: false })
  }

  render() {

    const { classes } = this.props;
    const { item, userList, autocompleteInputValue, loadingGrid } = this.state;

    return (

      <div>
        <form name='myForm' className={classes.form} encType='multipart/form-data' onSubmit={this.handleSubmit}>
          <Grid container spacing={2} style={{ marginTop: 15 }}>
            <Grid item xs={12}>
              <Autocomplete
                value={null}
                inputValue={autocompleteInputValue}
                options={userList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Usuários" variant="outlined" fullWidth />
                )}
                renderOption={(props, option, state) => (
                  <>
                    <List>
                      <ListItem {...props}>
                        <ListItemText primary={option.name} />
                      </ListItem>
                      {state.index < userList.length - 1 && <Divider />}
                    </List>
                  </>
                )}
                onChange={this.handleAutocompleteChange}
                filterOptions={this.filterOptions}
                onInputChange={(_, newInputValue) => this.setState({ autocompleteInputValue: newInputValue })}
                fullWidth
              />

              <Paper elevation={3} style={{ marginTop: 16 }}>
                {loadingGrid ? ( 
                  <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
                ) : (
                  <TableContainer>
                    <Table>

                      <TableHead>
                        <TableRow>
                          <TableCell style={{ fontWeight: 'bold', color: '#666' }}>Nome</TableCell>
                          <TableCell style={{ fontWeight: 'bold', color: '#666' }} align="center">Excluir</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {item.selectedOptions.map((option, index) => (
                          <TableRow key={index}>
                            <TableCell>{option.name}</TableCell>

                            <TableCell align="center">
                              <IconButton
                                color="secondary"
                                onClick={() => this.handleRemoveOption(option)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>

                    </Table>
                  </TableContainer>
                )}
              </Paper>
            </Grid>

            <Grid container style={{ marginTop: '25px' }}>
              <Grid item xs={4} />
              <Grid item xs={4} />
              <Grid item xs={4} container alignItems="flex-end" justifyContent="flex-end">
                <Button variant="contained" color="primary" type="submit" fullWidth>
                  Salvar
                </Button>
              </Grid>
            </Grid>

          </Grid>
        </form>

        <DialogGeneral
          dialogTitle={"Remover administrador do canal"}
          dialogSubTitle={"Você deseja remover esse administrador ?"}
          open={this.state.DialogRemoveState}
          openOrCloseModalFunction={this.openOrCloseRemoveModal}
          handleConfirmation={this.handleDeleteOption}
        />
      </div>
    );
  }
}


ChannelAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userSession: state.userSession,
  channels: state.channels
});


export default injectIntl(withRouter(connect(mapStateToProps)(withStyles(Styles)(ChannelAdmin))));
ChannelAdmin.contextType = AbilityContext;