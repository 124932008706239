import React, { Component } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../../styles/material-styles';
import { Overlay } from '../../../styles/global';
import CustomizedSnackbars from '../../../components/material-snackbars';
import { Redirect } from 'react-router';
import SimpleSelect from '../../../components/select/simple';
import { QueuePlayNext } from "@mui/icons-material";
import * as Service from "../../../services/apiWebhook";

class WebhookRegistration extends Component {

  constructor(props) {
    super(props);

    this.state = {
      files: [],
      loading: false,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      redirect: false,
      EventList: [],
      item: {
        url: "",
        token: "",
        email: "",
        EventId: 0
      }
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
  }

  async componentDidMount() {
    await this.getEvents();
  };

  async getEvents() {
    this.setState({ loading: true });

    var result = await Service.getEvents();
    if (result.data.success) {
      this.setState({ loading: false, EventList: result.data.data });
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: "error",
        notificationMessage:
          result.response && result.response.data && result.response.data.errors
            ? result.response.data.errors[0]
            : this.props.intl.formatMessage({id:"process.error"}),
      });
    }
  }


  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();

    data.append("urlsubscription", this.state.item['url']);
    data.append("token", this.state.item['token']);
    data.append("email", this.state.item['email']);
    data.append("webhookeventsid", this.state.item['EventId']);

    var result = await Service.ApiPostWebhook(data, "/webhooksubscriptions");
    
    this.setState({ loading: false });

    if(result.data != null){
      if(result.data.success == true && result.data.data != null){
        this.setState({
          openNotification: true,
          notificationVariant: "success",
          notificationMessage: "Cadastrado com sucesso."
        });
      }else{
        this.setState({
          openNotification: true,
          notificationVariant: "error",
          notificationMessage: "Email e/ou Token são inválidos."
        });
      }
    }

  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    })); 
    this.setState({ loading: false });

  };
  
  render() {
    const { classes } = this.props;
    const intl = this.props.intl;

     return (
      <Container component='main' maxWidth='sm'>
        <div style={{textAlign: "center"}}>
          <Avatar style={{marginLeft: "48%"}} className={classes.avatar}>
            <QueuePlayNext />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Cadastrar Inscrição
          </Typography>
        </div>
        <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
          
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12}>
              <TextField fullWidth label={this.props.intl.formatMessage({id:"email"})} autoComplete='fname' variant='outlined' name='email' onChange={this.handleInputChange} required />
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12} sm={12}>
              <TextField fullWidth label="Token" autoComplete='fname' variant='outlined' name='token' onChange={this.handleInputChange} required />
            </Grid>
          </Grid>
          
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12}>
              <SimpleSelect label={intl.formatMessage({id:"event"})} options={this.state.EventList} stateName='EventId' changeSelect={this.changeValues} selected={this.state.item.EventId} />
            </Grid>
          </Grid>
          
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12}>
              <TextField fullWidth label="URL" autoComplete='fname' variant='outlined' name='url' onChange={this.handleInputChange} required />
            </Grid>
          </Grid>


          <Grid container spacing={4}>
            <Grid item xs={12} sm={4} align-items-xs-center={'true'} justify-xs-flex-end={'true'}>
             <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
              >
                {this.props.intl.formatMessage({id:"save"})}
              </Button>
            </Grid>
          </Grid>

        </form>

        {this.state.loading && (
          <Overlay>
            <CircularProgress color='secondary' />
          </Overlay>
        )}

        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification}
        />

      </Container>
    );
  }
}

export default withStyles(Styles)(WebhookRegistration);
