import React, { Component } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar, Button, CssBaseline, TextField, Grid, Typography, CircularProgress, Container } from '@mui/material';
import { QueuePlayNext, DeleteForever } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { Styles } from '../../../styles/material-styles';
import { Overlay } from '../../../styles/global';
import Api from '../../../services/api';
import CustomizedSnackbars from '../../../components/material-snackbars';
import Dropzone from 'react-dropzone';
import HeaderMenu from '../../../components/header-menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SimpleSelect from '../../../components/select/simple';
import SimpleCheckBox from '../../../components/checkbox/check';
import { browserHistory } from '../../../helpers/history';
import { FormattedMessage,injectIntl } from 'react-intl';
import Title from '../../../components/title-name';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import * as Service from '../../../services/organizationsetting.service';
import Footer from '../../../components/footer';

class OrganizationSettingRegistration extends Component {

  constructor(props) {
    super(props);
    const { userSession, organizationid } = this.props;

    this.globalAdm = false;
    this.isClient = true;
    if(userSession == null ||  userSession.user == null || userSession.user.userrole == null){
      Api.logoff(); 
    }

    userSession.user.userrole.map((item, key) => {
            if(item.role.roletype !=3){
                this.isClient = false;
            }
            if(item.role.roletype ==0){
              this.globalAdm =true;
            }
        }
    );

    this.initialState = {
    id: 0,
    createdUser: userSession.user.id,
    createdAt: moment().format('YYYY/MM/DD HH:mm:ss'),
    OrganizationId:  organizationid,
    OrganizationText: '',
    TypeId:  0,
    TypeText: '',
    OriginRequestId:  0,
    OriginRequestText: '',
    StatusId:  0,
    StatusText: '',
    canviewcalledfromothertechnical : false, 
    roleclientcanselectcategory : false, 
    canassingusercalled : false,
    estimatedanwserhours: 24,
    canselectpatrimonyincalled: false,
    canselectdepartamentincalled: false,
    canselectparentcalledincalled: false,
    canselectdisplacementincalled: false,
    canselecthourcostorcalledcostincalled: false,
    canclientselectpatrimonyincalled: false,
    canclientselecttypeincalled: false,
    canclientviewslametricsincalled: false,
    automaticallycreatecalledwhenreadingqr: false,
    allowscreateanonymouscalledsbyemail: false
     };         
    this.state = {
      item: this.initialState,
      files: [],
      loading: true,
      openNotification: false,
      notificationVariant: 'error',
      notificationMessage: '',
      OrganizationList: [],
      TypeList: [],
      OriginRequestList: [],
      StatusList: [],

    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.changeValues = this.changeValues.bind(this);
  }  

    async componentDidMount() {
      this.setState({ loading: true });
        await this.getOrganizations();
        await this.getTypes();
        await this.getOriginRequests();
        await this.getStatuses();
        await this.getDados();
        
        this.setState({ loading: false });
     };
    async getDados(){
      this.setState({ loading: true });
      const { organizationid } = this.props;
      var result = await Service.getOrganizations(organizationid);
        if (result.success) {
          if(result.data.length > 0){
            let resultData = result.data[0];
            this.setState(prevState => ({
              item: {
                ...prevState.item,
                ['id']: resultData.id,
                ['TypeId']:  resultData.type.id,
                ['TypeText']: resultData.type.name,
                ['OriginRequestId']:  resultData.originrequest.id,
                ['OriginRequestText']: resultData.originrequest.name,
                ['StatusId']: resultData.status.id,
                ['canviewcalledfromothertechnical'] : resultData.canviewcalledfromothertechnical, 
                ['roleclientcanselectcategory'] : resultData.roleclientcanselectcategory, 
                ['canassingusercalled'] : resultData.canassingusercalled,
                ['estimatedanwserhours']: resultData.estimatedanwserhours,
                ['canselectpatrimonyincalled']: resultData.canselectpatrimonyincalled,
                ['canselectdepartamentincalled']: resultData.canselectdepartamentincalled,
                ['canselectparentcalledincalled']: resultData.canselectparentcalledincalled,
                ['canselectdisplacementincalled']: resultData.canselectdisplacementincalled,
                ['canselecthourcostorcalledcostincalled']: resultData.canselecthourcostorcalledcostincalled,
                ['canclientselectpatrimonyincalled']: resultData.canclientselectpatrimonyincalled,
                ['canclientselecttypeincalled']: resultData.canclientselecttypeincalled,
                ['canclientviewslametricsincalled']: resultData.canclientviewslametricsincalled,
                ['automaticallycreatecalledwhenreadingqr']: resultData.automaticallycreatecalledwhenreadingqr,
                ['allowscreateanonymouscalledsbyemail']: resultData.allowscreateanonymouscalledsbyemail
              }
            }));
          }
        } else {
          const intl = this.props.intl;
          this.setState({
            loading: false, openNotification: true, notificationVariant: 'error',
            notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
          });
          Api.kickoff(result);
        }
      this.setState({ loading: true });
    }; 
  async getOrganizations(){
    this.setState({ loading: true });
    var result = await Service.getOrganizations();
    if (result.success) {
        this.setState({  OrganizationList: result.data });
      } else {
        const intl = this.props.intl;
        this.setState({
          loading: false, openNotification: true, notificationVariant: 'error',
          notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
        });
        Api.kickoff(result);
      }
    }; 
  async getTypes(){
          this.setState({ loading: true });
          var result = await Service.getTypes();
            if (result.success) {
              this.setState({  TypeList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);
            }
  };     async getOriginRequests(){
          this.setState({ loading: true });
          var result = await Service.getOriginRequests();
            if (result.success) {
              this.setState({  OriginRequestList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);
            }
  };     async getStatuses(){
          this.setState({ loading: true });
          var result = await Service.getStatuses();
            if (result.success) {
              this.setState({ loading: false, StatusList: result.data });
                            
            } else {
              const intl = this.props.intl;
              this.setState({
                loading: false, openNotification: true, notificationVariant: 'error',
                notificationMessage: result.response && result.response.data && result.response.data.errors ? result.response.data.errors[0] : intl.formatMessage({id:"process.error"})
              });
              Api.kickoff(result);
            }
  };

  handleInputChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [name]: value
      }
    }));
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ loading: true });

    let data = new FormData();


      if (this.isClient) {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      } else {
        this.setState(prevState => ({
          item: {
            ...prevState.item,
          }
        }));
      }

      if(!this.state.item.canselectpatrimonyincalled){
        this.setState(prevState => ({
          item: {
            ...prevState.item,
            automaticallycreatecalledwhenreadingqr: false
          }
        }));
      }


      for (let key in this.state.item) {
        if (key.toString().toLowerCase().indexOf('_date') > -1 || key.toString().toLowerCase().indexOf('date_') > -1) {
          data.append(key, moment(this.state.item[key]).format('YYYY/MM/DD HH:mm:ss'));
        } 
        else if (key.toString().toLowerCase().indexOf('roleclientcanselectcategory') > -1) {
          if(this.state.item[key] == true || this.state.item[key] === true){
            data.append(key, 1);
          }
          else{
            data.append(key, 0);
          }
        }
        else if (key.toString().toLowerCase().indexOf('canassingusercalled') > -1) {
          if(this.state.item[key] == true || this.state.item[key] === true){
            data.append(key, true);
          }
          else{
            data.append(key, false);
          }
        }
        else if (key.toString().toLowerCase().indexOf('canviewcalledfromothertechnical') > -1) {
          if(this.state.item[key] == true || this.state.item[key] === true){
            data.append(key, true);
          }
          else{
            data.append(key, false);
          }
        }
        else {
          data.append(key, this.state.item[key]);
        }
      }
      //console.log(this.state.item);
      //this.setState({ loading: false });
      //return false;
      this.state.files.forEach(file => data.append('filesUpload', file));
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

      if(this.state.item.id != 0){
        Api.put('/organizationsettings', data, config)
        .then(result => {
          const intl = this.props.intl;
          if (result.data.success) {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({id:"add.success"}),
                item: this.initialState,
                files: []
              });

              this.props.next();

          }
          else{

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
                            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);

          }
        })
        .catch(err => {
          const intl = this.props.intl;
          if(err.response && err.response.data && err.response.data.errors){
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
                            notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
          }
          Api.kickoff(err);

        });
      }else{
        Api.post('/organizationsettings', data, config)
        .then(result => {
          const intl = this.props.intl;
          if (result.data.success) {
              this.setState({
                loading: false,
                openNotification: true,
                notificationVariant: 'success',
                notificationMessage: intl.formatMessage({id:"add.success"}),
                item: this.initialState,
                files: []
              });

              this.props.next();

          }
          else{

            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
                            notificationMessage: result.data && result.data.errors ? result.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
            Api.kickoff(result);

          }
        })
        .catch(err => {
          const intl = this.props.intl;
          if(err.response && err.response.data && err.response.data.errors){
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
              notificationMessage: err.response && err.response.data && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})
            });
          }
          else{
            const intl = this.props.intl;
            this.setState({
              loading: false,
              openNotification: true,
              notificationVariant: 'error',
                            notificationMessage: err.response && err.response.data.errors ? err.response.data.errors[0] : intl.formatMessage({id:"process.error"})

            });
          }
          Api.kickoff(err);

        });
      }
        
  };

  closeNotification() {
    this.setState({ openNotification: false });
  }

  changeValues(stateName, value, text = '') {
    this.setState({ loading: true });

    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: value
      }
    }));
        if(stateName === 'OrganizationId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['OrganizationText']: text,
            }
          }));
        }
        if(stateName === 'TypeId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['TypeText']: text,
            }
          }));
        }
        if(stateName === 'OriginRequestId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['OriginRequestText']: text,
            }
          }));
        }
        if(stateName === 'StatusId'){
          this.setState(prevState => ({
            item: {
              ...prevState.item,
              ['StatusText']: text,
            }
          }));
        }
 
    this.setState({ loading: false });

  };

  removeItem(file) {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }
    render() {
    const { classes, headerMenu, back } = this.props;
    const { item } = this.state;
    const intl = this.props.intl;
     return (
      <div className={classes.root}>
          <div className={classes.drawerHeader} />

          <Container component='main' maxWidth='md'>
              <form name='myForm' className={classes.form} onSubmit={this.handleSubmit} encType='multipart/form-data'>
                <Grid container spacing={4} style={{display: 'none'}}>
                    <Grid item xs={12} sm={12} style={{textAlign: 'left'}}>
                      <SimpleSelect label={intl.formatMessage({id:"organization"})} options={this.state.OrganizationList} stateName='OrganizationId' changeSelect={this.changeValues} selected={item.OrganizationId} />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} style={{textAlign: 'left'}}>
                      <SimpleSelect label={intl.formatMessage({id:"organization.settings.type.call"})} options={this.state.TypeList} stateName='TypeId' changeSelect={this.changeValues} selected={item.TypeId} />
                    </Grid>
                </Grid>
                    <Grid container spacing={4} style={{textAlign: 'left'}}>
                    <Grid item xs={12} sm={12}>
                      <SimpleSelect label={intl.formatMessage({id:"organization.settings.called.origin"})} options={this.state.OriginRequestList} stateName='OriginRequestId' changeSelect={this.changeValues} selected={item.OriginRequestId} />
                    </Grid>
                </Grid>
                    <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} style={{textAlign: 'left'}}>
                      <SimpleSelect label={intl.formatMessage({id:"default.type"})} options={this.state.StatusList} stateName='StatusId' changeSelect={this.changeValues} selected={item.StatusId} />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                      <TextField id='filled-number' label={intl.formatMessage({id:"organization.settings.expire"})} type='number' variant='filled' InputLabelProps={{shrink: true}} fullWidth name='estimatedanwserhours' onChange={this.handleInputChange} value={item.estimatedanwserhours} required />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} style={{textAlign: 'left'}}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organization.settings.cliente.categoria"})} name='roleclientcanselectcategory' stateName='roleclientcanselectcategory' changeSelect={this.changeValues} selected={item.roleclientcanselectcategory} />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} style={{textAlign: 'left'}}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organization.settings.responsavel.chamado"})} name='canassingusercalled' stateName='canassingusercalled' changeSelect={this.changeValues} selected={item.canassingusercalled} />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} style={{textAlign: 'left'}}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organization.settings.allow.call"})} name='canviewcalledfromothertechnical' stateName='canviewcalledfromothertechnical' changeSelect={this.changeValues} selected={item.canviewcalledfromothertechnical} />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} style={{textAlign: 'left'}}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organization.settings.allow.call.email"})} name='allowscreateanonymouscalledsbyemail' stateName='allowscreateanonymouscalledsbyemail' changeSelect={this.changeValues} selected={item.allowscreateanonymouscalledsbyemail} />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} style={{textAlign: 'left'}}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organization.setting.edit.can.attach.patrimonies.to.called"})} name='canselectpatrimonyincalled' stateName='canselectpatrimonyincalled' changeSelect={this.changeValues} selected={item.canselectpatrimonyincalled} />
                    </Grid>
                </Grid>
                {this.state.item.canselectpatrimonyincalled && (
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} style={{textAlign: 'left'}}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organization.setting.edit.create.called.read.qrcode"})} name='automaticallycreatecalledwhenreadingqr' stateName='automaticallycreatecalledwhenreadingqr' changeSelect={this.changeValues} selected={item.automaticallycreatecalledwhenreadingqr} />
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} style={{textAlign: 'left'}}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organization.setting.edit.can.attach.departament.to.called"})} name='canselectdepartamentincalled' stateName='canselectdepartamentincalled' changeSelect={this.changeValues} selected={item.canselectdepartamentincalled} />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} style={{textAlign: 'left'}}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organization.setting.edit.can.select.parent.called.in.called"})} name='canselectparentcalledincalled' stateName='canselectparentcalledincalled' changeSelect={this.changeValues} selected={item.canselectparentcalledincalled} />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} style={{textAlign: 'left'}}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organization.setting.edit.can.select.displacement.in.called"})} name='canselectdisplacementincalled' stateName='canselectdisplacementincalled' changeSelect={this.changeValues} selected={item.canselectdisplacementincalled} />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} style={{textAlign: 'left'}}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organization.setting.edit.can.select.hour.cost.or.called.cost.in.called"})} name='canselecthourcostorcalledcostincalled' stateName='canselecthourcostorcalledcostincalled' changeSelect={this.changeValues} selected={item.canselecthourcostorcalledcostincalled} />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} style={{textAlign: 'left'}}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organization.setting.edit.can.attach.patrimonies.to.called"})} name='canclientselectpatrimonyincalled' stateName='canclientselectpatrimonyincalled' changeSelect={this.changeValues} selected={item.canclientselectpatrimonyincalled} />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} style={{textAlign: 'left'}}>
                      <SimpleCheckBox label={intl.formatMessage({id:"organization.setting.edit.can.attach.patrimonies.to.called"})} name='canclientselecttypeincalled' stateName='canclientselecttypeincalled' changeSelect={this.changeValues} selected={item.canclientselecttypeincalled} />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} style={{textAlign: 'left'}}>
                      <SimpleCheckBox label={ intl.formatMessage({ id: 'organizations.settings.canclientviewslametricsincalled' }) } name='canclientviewslametricsincalled' stateName='canclientviewslametricsincalled' changeSelect={this.changeValues} selected={item.canclientviewslametricsincalled} />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={4} sm={4} /> 
                  <Grid  xs={4} sm={4} style={{textAlign: 'center'}}>
                    <Button
                      type='submit'
                      color='primary'
                      onClick={back}
                    >
                      {this.props.intl.formatMessage({id:"back"})}
                    </Button>
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                    >
                      {intl.formatMessage({id:"next"})}
                    </Button>
                  </Grid>
                  <Grid item xs={4} sm={4} /> 
                </Grid>

              </form>

            {this.state.loading && (
              <Overlay>
                <CircularProgress color='secondary' />
              </Overlay>
            )}

            <CustomizedSnackbars
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              isOpen={this.state.openNotification}
              toClose={this.closeNotification}
            />
          </Container>              
     </div>
    );
  }
}
OrganizationSettingRegistration.propTypes = {
                    classes: PropTypes.object.isRequired,
                    organizationid: PropTypes.string,
                    back: PropTypes.func,
                    next: PropTypes.func
                };
                const mapStateToProps = state => ({
                    headerMenu: state.headerMenu,
                    userSession: state.userSession,
                });
            export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(OrganizationSettingRegistration)));
