import Api from './api';

export const getData = async () => { 
  let data = []
  await Api.get(`/integrationauth`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};


export const getToken = async (id) => { 
  let data = []
  await Api.get(`/integrationauth/token/${id}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
};


export const addItem = async (formData) => { 
  let data = []
  await Api.post(`/integrationauth`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err.response
    })
  return data
}

export const updateItem = async (formData) => { 
  let data = []
  await Api.put(`/integrationauth`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err.response
    })
  return data
}



export const testFlow = async (formData) => { 
  let data = []
  await Api.post(`/integrationauth/test-flow`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err.response
    })
  return data
}



