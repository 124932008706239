import Api from './api';

export const getAllByCalledIdWithRequestingUserAndStatusInclude = async (calledId) => { 
  let data = []
  await Api.get(`/calledrequestuser/list/called/${calledId}/withtincludes/requestinguser/status`)
      .then(result => {
          data = result.data
       })
       .catch(err => {
          data = err
  })
  return data
}

export const addItem = async (formData) => { 
  let data = []
  await Api.post(`/calledrequestuser`, formData)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}