export default function organizationmaterials(state = [], action) {
    switch (action.type) {
        case 'ADD_CURRENT':
            return {
                ...state,
                organizationmaterial: action.organizationmaterial,
            }
        case 'ADD_CURRENT_ORGANIZATION':
          return {
              ...state,
              organizationmaterial: action.organizationmaterial,
              organization: action.organization,
          }
        default:
            return state;
    }
}
